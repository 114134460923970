import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PersonT } from 'types'
import { getUserName } from 'utils/person'
import { classes } from './styles'

interface IGlobalProps {
  personsWithTasks: PersonT[]
  personsWithoutTasks: PersonT[]
}

const Global: React.FC<IGlobalProps> = ({ personsWithTasks, personsWithoutTasks }) => {
  const { t } = useFavurTranslation()
  return (
    <Card sx={classes.card}>
      <Typography variant="subtitle1" data-testid="global-title">
        {t('requestEmployeeData.global.cardTitle', { numPersons: personsWithoutTasks.length })}
      </Typography>
      <Typography variant="body1" sx={classes.body}>
        {t('requestEmployeeData.global.cardBody')}
      </Typography>
      {personsWithTasks.length > 0 && (
        <>
          <Typography variant="body1" sx={classes.body}>
            {t('requestEmployeeData.global.cardExtraText', { numPersonsWithTasks: personsWithTasks.length })}
          </Typography>
          <Box component="ul" sx={classes.list}>
            {personsWithTasks.map((person) => (
              <Box component="li" key={person.id} data-testid="person-with-tasks">
                <Typography variant="caption">{getUserName(person)}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Card>
  )
}

export default Global
