import React from 'react'
import { Button, DialogActions } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import useFavurTranslation from 'hooks/useFavurTranslation'

const ActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {
  const { t } = useFavurTranslation()
  return (
    <DialogActions>
      <Button onClick={onCancel}>{t('datePicker.button.cancel')}</Button>
      <Button onClick={onAccept}>{t('datePicker.button.ok')}</Button>
    </DialogActions>
  )
}

export default ActionBar
