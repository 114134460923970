import React from 'react'
import { Box, Paper } from '@mui/material'
import Logo from 'components/Logo'
import Page from 'components/Page'
import LanguageSwitcher from './components/LanguageSwitcher'
import { classes } from './styles'

interface Props {
  children?: React.ReactNode
}

const EntryPointWithoutMirusLogo: React.FC<Props> = ({ children }) => {
  const isSmallDevice = window.innerHeight < 700

  const renderSmallScreenHeader = () => (
    <Box sx={classes.headerSmallDevice}>
      <Box sx={classes.actionsNoMargin}>
        <LanguageSwitcher />
      </Box>
      <Box sx={classes.smallImageContainer}>
        <Logo />
      </Box>
      <Box sx={classes.actionsNoMargin} />
    </Box>
  )

  const renderLargeScreenHeader = () => (
    <Box sx={classes.header}>
      <Box sx={classes.actionsNoMargin}>
        <LanguageSwitcher />
      </Box>
      <Box sx={classes.smallImageContainer}>
        <Logo />
      </Box>
    </Box>
  )

  return (
    <Page center>
      <Paper sx={classes.paperContainer}>
        {isSmallDevice ? renderSmallScreenHeader() : renderLargeScreenHeader()}
        {children}
      </Paper>
    </Page>
  )
}

export default EntryPointWithoutMirusLogo
