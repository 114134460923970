import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { taskStates } from 'pages/Tasks/constants'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { taskTypes } from 'shared/constants'
import { PersonT } from 'types'
import { getUserName } from 'utils/person'
import { classes } from './styles'

interface IConfirmationDialogProps {
  dialogOpen: boolean
  personList: PersonT[]
}

const ErrorListDialog: React.FC<IConfirmationDialogProps> = ({ dialogOpen, personList }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const dialogActions = (
    <Box>
      <Button
        variant="text"
        onClick={() => history.push(`${routes.tasks}/${taskStates.pending}?task_type=${taskTypes.shareUserData}`)}
        color="secondary"
      >
        {t('requestEmployeeData.errorList.close')}
      </Button>
    </Box>
  )
  return (
    <Dialog open={dialogOpen} title={t('requestEmployeeData.errorList.title')} actions={dialogActions}>
      <Typography variant="body2" sx={classes.body}>
        {t('requestEmployeeData.errorList.body')}
      </Typography>
      <Box component="ul" sx={classes.list}>
        {personList.map((person) => (
          <li key={person.id} data-testid="person-item">
            <Typography variant="caption">{getUserName(person)}</Typography>
          </li>
        ))}
      </Box>
    </Dialog>
  )
}

export default ErrorListDialog
