import React, { useCallback, useReducer, useEffect, useMemo } from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import ForgottenPin from 'components/ForgottenPin'
import { SimpleLoading } from 'components/LoadingIcon'
import { NumpadWithoutState } from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import Page from 'components/Page'
import WrongPinWarning from 'components/WrongPinWarning'
import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import useDialogs from 'hooks/useDialogs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import usePinStatus from 'hooks/usePinStatus'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import { CloseM } from 'icons'
import { classes } from 'pages/SecuritySetup/Common/PinEnter/styles'
import { pinReducer, pinEntryInitialState } from 'pages/SecuritySetup/hooks'
import { classes as securityClasses } from 'pages/SecuritySetup/styles'
import { EnterPinResponseT, PinEntryT, PinEntryActionT } from 'pages/SecuritySetup/types'
import { queryCache } from 'react-query'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { FF_SECURE_CONN_WORKFLOW } from '../../constants/featureFlags'
import { tutorialNames } from '../../constants/tutorials'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import useTutorials from '../../hooks/useTutorials'
import SecureConnectionDialog from './SecureConnectionDialog'
import { useRedirectIfPinNotSetOrBlocked, useRenderChildOrPin } from './hooks'
import { classes as pinLoginClasses } from './styles'
import usePinKeysStorage from './usePinKeysStorage'

const PinLogin: React.FC<Record<string, unknown>> = ({ children }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { storePinInKeychain } = usePinKeysStorage()
  const { openDialog } = useDialogs()
  const [pinStatus, statusP] = usePinStatus({ queryName: 'pin_status_pin_login' })
  const secureConnWorkflowFF = useFeatureFlag(FF_SECURE_CONN_WORKFLOW) === true

  const [pinState, dispatchPin] = useReducer<(state: PinEntryT, action: PinEntryActionT) => PinEntryT>(
    pinReducer,
    pinEntryInitialState,
  )

  const [enterPin] = useJamesMutation<{ pinCode: string }, EnterPinResponseT>(
    `enterPin(pinCode: "#pinCode") {
      success
      remainRetries
      pinBlocked
    }`,
    {
      onSuccess: async (res) => {
        const {
          data: {
            data: { enterPin: enterPinRes },
          },
        } = res
        if (enterPinRes.success) {
          await storePinInKeychain(pinState.pinValue)

          queryCache.invalidateQueries('session_validity')

          return
        }
        if (enterPinRes.pinBlocked) {
          dispatchPin({ type: 'pinBlocked' })
          return
        }
        dispatchPin({ type: 'pinInvalid', payload: { attemptsLeft: enterPinRes.remainRetries } })
      },
    },
  )

  useEffect(() => {
    dispatchPin({
      type: 'setPinLength',
      payload: { pinLength: pinStatus?.pinLength || PIN_LENGTHS.default },
    })
  }, [pinStatus])

  useEffect(() => {
    if (pinState.pinLength === pinState.pinValue.length) {
      enterPin({ pinCode: pinState.pinValue })
    }
  }, [pinState, enterPin])

  const timeoutAction = useCallback(() => {
    openDialog('securityTimeout')
    history.push(routes.dashboard, { from: { pathname: history.location.pathname } })
  }, [history, openDialog])

  const getPinStatusData = useMemo(() => {
    return {
      pinStatusData: pinStatus,
      status: statusP,
    }
  }, [pinStatus, statusP])
  // redirect to security setup
  const { pinNotSet } = useRedirectIfPinNotSetOrBlocked({
    pinStatus: getPinStatusData,
    dispatchPin,
  })
  // security timeout handler
  const sessionValidity = useSessionTimeout('securityTimeout', timeoutAction)

  // rendering logic, loading | pin | component
  const { loadingActive, componentRendered } = useRenderChildOrPin({ sessionValidity, pinStatus, pinState })

  const startTyping = useCallback((newVal: string) => {
    dispatchPin({ type: 'pinEntry', payload: { pinValue: newVal } })
  }, [])

  const { beenShownBefore, isReady: isTutorialsReady } = useTutorials()

  if (pinNotSet && isTutorialsReady) {
    if (!secureConnWorkflowFF || Boolean(beenShownBefore(tutorialNames.SECURE_CONNECTION))) {
      history.push(routes.securitySetup, { from: { pathname: history.location.pathname } })
    }

    return <SecureConnectionDialog />
  }

  if (loadingActive) {
    return (
      <Page hideNativeNavigation hideWebToolbar noPadding flex columnCenter>
        <SimpleLoading />
      </Page>
    )
  }

  if (componentRendered) return <>{children}</>

  // @ts-ignore
  const blueColor = theme.palette.primary[900]
  return (
    <Page hideNativeNavigation hideWebToolbar noPadding flex>
      <WrongPinWarning pinState={pinState} dispatchPin={dispatchPin} />
      <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
        <Box sx={pinLoginClasses.header}>
          <Box sx={pinLoginClasses.headerBox}>
            <IconButton
              sx={pinLoginClasses.headerClose}
              color="inherit"
              onClick={history.goBack}
              data-testid="close-pin-login-dialog-icon"
              size="large"
            >
              <CloseM fill={blueColor} />
            </IconButton>
          </Box>
          <Typography variant="h2" sx={[securityClasses.title, pinLoginClasses.headerBox, pinLoginClasses.headerTitle]}>
            {t('component.pinLogin.title')}
          </Typography>
          <Box sx={pinLoginClasses.headerBox} />
        </Box>
        <Box sx={classes.pinDisplayContainer}>
          <PinDisplay pinLength={pinState.pinLength} pin={pinState.pinValue} secret />
          <Box sx={pinLoginClasses.pinInfoBox}>
            <Typography variant="caption">{t('component.pinLogin.info')}</Typography>
          </Box>
        </Box>
        <Box>
          <NumpadWithoutState
            numpadType="pinLogin"
            numpadValue={pinState.pinValue}
            setNumpadValue={startTyping}
            outputLength={pinState.pinLength}
          />
          <ForgottenPin />
        </Box>
      </CenteredBox>
    </Page>
  )
}

export default PinLogin
