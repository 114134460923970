import { TimelineItemContentBadgeTypeT } from 'components/Timeline/types'

export const getTimelineMonthlySheetBadgeTextKey = (event: TimelineItemContentBadgeTypeT) => {
  switch (event) {
    case TimelineItemContentBadgeTypeT.workTimeAccepted:
      return 'timeline.badge.workTimeAccepted'
    case TimelineItemContentBadgeTypeT.acceptedForNextMonth:
      return 'timeline.badge.acceptedForNextMonth'
    case TimelineItemContentBadgeTypeT.acceptedForThisMonth:
      return 'timeline.badge.acceptedForThisMonth'
    case TimelineItemContentBadgeTypeT.called:
      return 'timeline.badge.calledAt'
    case TimelineItemContentBadgeTypeT.rejected:
      return 'timeline.badge.rejected'
    case TimelineItemContentBadgeTypeT.reminder:
      return 'timeline.badge.sentAReminder'
    case TimelineItemContentBadgeTypeT.automatedReminder:
      return 'timeline.badge.sentAutomatedReminder'
    case TimelineItemContentBadgeTypeT.wtcChangeRequest:
      return 'timeline.badge.workTimeControlChangeRequest'
    default:
      return ''
  }
}
