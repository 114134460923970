import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import { PinEntryT, PinEntryActionT } from './types'

export const pinEntryInitialState: PinEntryT = {
  pinValue: '',
  pinLength: PIN_LENGTHS.default,
  pinSuccess: false,
  pinInvalid: false,
  pinBlocked: false,
  attemptsLeft: undefined,
}

export const pinReducer = (state: PinEntryT, action: PinEntryActionT) => {
  switch (action.type) {
    case 'pinEntry':
      return { ...state, openWarning: false, pinValue: action?.payload?.pinValue } as PinEntryT
    case 'pinInvalid':
      return { ...state, attemptsLeft: action?.payload?.attemptsLeft, pinInvalid: true, pinValue: '' } as PinEntryT
    case 'pinBlocked':
      return { ...state, attemptsLeft: 0, pinInvalid: false, pinBlocked: true, pinValue: '' } as PinEntryT
    case 'pinValid':
      return { ...state, pinSuccess: true, pinInvalid: false, pinBlocked: false, pinValue: '' } as PinEntryT
    case 'setWarning':
      return { ...state, pinInvalid: action?.payload?.warning } as PinEntryT
    case 'setPinLength':
      return { ...state, pinLength: action?.payload?.pinLength, value: '' } as PinEntryT
    default:
      throw new Error()
  }
}
