/* eslint-disable @typescript-eslint/no-explicit-any */

const sortBy = (key: string) => {
  return (a: { [x: string]: any }, b: { [x: string]: any }) => {
    if (a[key] > b[key]) {
      return 1
    }

    if (b[key] > a[key]) {
      return -1
    }

    return 0
  }
}

export const sortArrayByKey = (array: any[], key: any) => {
  // eslint-disable-next-line fp/no-mutating-methods
  return array.concat().sort(sortBy(key))
}
