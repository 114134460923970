import axios from 'axios'
import { fetchInfo } from 'hooks/useJamesQuery'
import { queryCache } from 'react-query'
import { PersonalDocumentFileTypeT } from '../../PersonalData/types'

const START_UPLOAD_PROGRESS = 10
const GET_LINK_UPLOAD_PROGRESS = 15
export const getLink = (documentType: PersonalDocumentFileTypeT, filename: string) => {
  const query = `query {
    documentUploadLink(document_type: "${documentType}", filename: "${filename}") {
     link
    }
  }`
  // TODO check if we get link from cache or always new one from api
  return queryCache
    .fetchQuery('documentUploadLink', fetchInfo<{ documentUploadLink: { link: string } }>(query))
    .then((response) => {
      if (response && !axios.isCancel(response)) {
        return response.data.data.documentUploadLink.link
      }
      return undefined
    })
}

export const getFilenameFromSignedUrl = (signedUrl: string): string => {
  const fileWithQueryParams = signedUrl.split('/')
  return fileWithQueryParams[fileWithQueryParams.length - 1].split('?')[0]
}

// https://cloud.google.com/storage/docs/xml-api/post-object-forms
// https://stackoverflow.com/questions/61950270/image-upload-issue-using-gcs-upload-signed-url-react-js
// https://github.com/googleapis/google-cloud-node/issues/1695
// https://cloud.google.com/storage/docs/access-control/signed-urls#signing-resumable
export const uploadFile = async (
  documentType: PersonalDocumentFileTypeT,
  file: File,
  filename: string,
  trackProgress?: (percent: number) => void,
) => {
  if (trackProgress) {
    trackProgress(START_UPLOAD_PROGRESS)
  }
  const link = await getLink(documentType, filename)
  if (trackProgress) {
    trackProgress(GET_LINK_UPLOAD_PROGRESS)
  }

  const source = axios.CancelToken.source()
  const cancelToken = source.token

  axios
    .put(`${link}`, file, {
      headers: {
        'Content-Type': file.type,
      },
      cancelToken,
      onUploadProgress(progressEvent) {
        if (trackProgress) {
          const percentCompleted =
            Math.round((progressEvent.loaded * 100) / progressEvent.total) + GET_LINK_UPLOAD_PROGRESS
          trackProgress(Math.min(100, percentCompleted))
        }
      },
    })
    .then(() => trackProgress && trackProgress(100))
  return { source, filename: getFilenameFromSignedUrl(link) }
}
