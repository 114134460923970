import palette from 'utils/theme/palette'

const backgroundClasses = {
  divbackground: {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    background: palette.primary[20],
  },
  opaque: {
    background: 'linear-gradient(150deg, #1f3968 68%, #FF6BB3)',
  },
  menu: {
    zIndex: 3,
    background: palette.primary.main,
  },
}

export default backgroundClasses
