import { alpha } from '@mui/material/styles'
import palette from '../palette'
import shadows from '../shadows'

export default {
  styleOverrides: {
    root: {
      borderRadius: 6,
      lineHeight: 1.25,
      fontSize: 12,
      letterSpacing: 0.7,
      padding: '10px 24px 7px',
      overflow: 'hidden',
      boxShadow: shadows[1],
      backgroundColor: 'white',
      color: palette.grey[600],
      border: '2px solid transparent',
      whiteSpace: 'nowrap',
      '&:before': {
        content: '" "',
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
      },
      '&:hover': {
        backgroundColor: 'white',
        '&:before': {
          backgroundColor: alpha(palette.secondary[400], 0.04),
        },
      },
      '&$focusVisible, &:focus': {
        backgroundColor: 'white',
        '&:before': {
          backgroundColor: alpha(palette.secondary[400], 0.04),
        },
      },
      '&.Mui-disabled': {
        backgroundColor: 'white',
        color: palette.grey[300],
        boxShadow: shadows[0],
      },
      '&.Mui-selected': {
        border: `2px solid ${palette.secondary.main} !important`,
        color: palette.secondary[400],
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
          '&:before': {
            backgroundColor: alpha(palette.secondary[400], 0.04),
          },
        },
        '&$focusVisible, &:focus': {
          backgroundColor: 'white',
          '&:before': {
            backgroundColor: alpha(palette.secondary[400], 0.04),
          },
        },
        '&.Mui-disabled': {
          backgroundColor: 'white',
          border: `2px solid ${palette.grey[300]} !important`,
        },
      },
    },
  },
}
