import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Alert from 'components/Alert'
import Card from 'components/Basics/Card'
import { cardVariants } from 'constants/componentsBasics'
import { format } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightThinS, EmployersM } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

const DATE_FORMAT = 'MMMM yyyy'

interface IEmployerCardProps {
  name: string
  onClick?: () => void
  joiningDate?: Date
  resignationDate?: Date
  hasNotSharedDataAlert?: boolean
  isInvitation?: boolean
  onClickInvitation?: () => void
}

const EmployerCard: React.FC<IEmployerCardProps> = ({
  name,
  onClick,
  joiningDate,
  resignationDate,
  hasNotSharedDataAlert = false,
  isInvitation = false,
  onClickInvitation,
}) => {
  const { t, locale } = useFavurTranslation()
  const fromUntilText = useMemo(() => {
    if (!joiningDate || !resignationDate) return undefined
    return `${format(joiningDate, DATE_FORMAT, { locale })} ${t(
      'page.userSettings.employers.until',
    )} ${format(resignationDate, DATE_FORMAT, { locale })}`
  }, [joiningDate, locale, resignationDate, t])

  const disabled = useMemo(() => isInvitation || fromUntilText, [fromUntilText, isInvitation])

  return (
    <Box sx={classes.outerContainer}>
      <Card onClick={onClick} variant={onClick ? cardVariants.clickable : cardVariants.base}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.contentContainer}>
            <EmployersM fill={disabled ? palette.grey[600] : palette.primary.main} />
            <Box>
              <Typography variant="body1" sx={disabled ? classes.invitationText : []}>
                {name}
              </Typography>
              {fromUntilText && (
                <Typography variant="body2" sx={classes.dateText}>
                  {fromUntilText}
                </Typography>
              )}
            </Box>
          </Box>
          {onClick && <ArrowRightThinS fill={palette.primary.main} />}
        </Box>
      </Card>
      {hasNotSharedDataAlert && <Alert content={t('page.userSettings.employer.notSharedDataAlert')} severity="info" />}
      {isInvitation && (
        <Alert
          content={t('page.userSettings.employer.invitationAlertContent')}
          title={t('page.userSettings.employer.invitationAlertTitle')}
          severity="warning"
          onClick={onClickInvitation}
        />
      )}
    </Box>
  )
}

export default EmployerCard
