import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const Bird36: React.FC<IconPropsT> = () => {
  return (
    <Svg size={36}>
      <path fill="#FF0062" d="M22.857 11.571V6.429H28z" />
      <path fill="#FFC300" d="M22.857 9V6.429h2.572z" />
      <path fill="#1F3867" d="M22.857 6.429L16.429 0 10 6.429V36l12.857-12.857z" />
      <path fill="#0A275C" d="M19.771 34.071L17.971 27H17.2l1.8 7.071h-2.571v.643h7.714v-.643z" />
      <path fill="#8EA1C3" d="M22.857 23.143V9L10 21.857V36z" />
      <path fill="#0A275C" d="M20.286 11.571L10 21.857v10.286l10.286-10.286z" />
      <path fill="#FF0062" d="M19.415 7.754a2.77 2.77 0 1 1-5.541 0 2.77 2.77 0 0 1 5.542 0" />
      <path fill="#191919" d="M18.17 7.754a1.524 1.524 0 1 1-3.046-.002 1.524 1.524 0 0 1 3.045.002" />
      <path fill="#FFF" d="M19.212 6.711L18.17 7.754h-1.524l1.958-1.958c.26.259.468.57.608.915z" />
    </Svg>
  )
}

export default Bird36
