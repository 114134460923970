import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledRecycleAcceptM } from 'icons'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

export const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice
    ? `absenceRequest.deletionConfirmed.tooltipManager`
    : `absenceRequest.deletionConfirmed.tooltipFrontliner`
}

const getTextKey = (isActing: boolean) =>
  isActing ? `timeline.badge.deletionConfirm` : `timeline.badge.deletionConfirmed`

const DeletionConfirmed: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false, isActing = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t(getTextKey(isActing))}
      sx={getClasses(withTooltip)}
      icon={<FilledRecycleAcceptM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="task-status_deletion-confirmed"
    />
  )
}

export default DeletionConfirmed
