import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TaskCenterL } from 'icons'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import palette from 'utils/theme/palette'
import { taskBarStates } from '../../types'
import TabButton from '../TabButton'

const UserTasksButton: React.FC = () => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()

  return (
    <TabButton tabOption={taskBarStates.userTasks} toolTipText={t('absencePlanner.taskbar.toolTip.pendingTasks')}>
      <TaskCenterL fill={taskBarState === taskBarStates.userTasks ? palette.secondary.main : palette.primary[700]} />
    </TabButton>
  )
}

export default UserTasksButton
