import theme from 'utils/theme'

export const classes = {
  body: {
    marginTop: theme.spacing(0.5),
  },
  list: {
    paddingLeft: theme.spacing(1.5),
  },
}
