import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { OwnerPersons, useOwnersOfUser } from 'hooks/useOwnersOfUser'
import { CmsContentStatsT } from 'types/cms'
import CompanyOverview from './CompanyOverview'
import useGetCmsContentStats from './useGetCmsContentStats'

const PageList = () => {
  const { t } = useFavurTranslation()
  const { stats } = useGetCmsContentStats()
  const { owners } = useOwnersOfUser({})

  const defaultStats = (ownerId: number) => {
    const test: CmsContentStatsT = {
      ownerId: ownerId,
      pages: { amount: 0 },
      events: { upcoming: 0 },
      newsletters: { amount: 0, latestSent: undefined },
    }
    return test
  }

  const findOwnerStats = useCallback(
    (ownerId: number) => stats.find((ownerStats) => ownerStats.ownerId === ownerId) || defaultStats(ownerId),
    [stats],
  )

  return (
    <div>
      {owners.map((personOwner: OwnerPersons) => {
        return (
          <CompanyOverview
            key={personOwner.owner.id}
            title={personOwner.owner.name}
            ownerStats={findOwnerStats(personOwner.owner.id)}
            subtitle={t('page.cms.overview.owner.subText')}
          />
        )
      })}
    </div>
  )
}

export default PageList
