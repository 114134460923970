import React, { useCallback, useMemo } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { AbsenceRequestRequestDeletionNotificationT, NotificationT } from 'types'
import { absenceRequestDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'

interface IAbsenceRequestRequestDeletionProps {
  notification: AbsenceRequestRequestDeletionNotificationT
  dismiss: (notification: NotificationT) => void
}

const AbsenceRequestRequestDeletion: React.FC<IAbsenceRequestRequestDeletionProps> = (props) => {
  const { t, locale } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props
  const { period, frontlinerNameShort } = notification.data

  const dateFrom = getFormattedFromDate(
    newDateWithoutTime(notification.data.dateFrom),
    absenceRequestDateFormat,
    locale,
  )
  const dateTo = getFormattedFromDate(newDateWithoutTime(notification.data.dateTo), absenceRequestDateFormat, locale)
  const multiday = dateFrom !== dateTo

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.absenceRequestDetail}/${notification.data.taskUuid}`, ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const absencePeriod = useMemo(() => {
    if (multiday || !period || period.trim() === '') {
      return ''
    }
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return t(absencePeriods[translatedPeriod])
  }, [multiday, period, t])

  const templateProps = {
    ...props,
    title: t('notification.new.absence.deletion.title', {
      frontlinerNameShort: frontlinerNameShort,
    }),
    body: multiday
      ? t('notification.new.absence.dates_multiday.body', {
          dateFrom,
          dateTo,
        })
      : t('notification.new.absence_singleday.body', {
          dateFrom,
          absencePeriod,
        }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default AbsenceRequestRequestDeletion
