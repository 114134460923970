import { useMemo } from 'react'
import { FilterStateT, QuickFilterT } from 'components/Filter/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isEqual } from 'lodash/fp'
import { TaskCountsT } from 'types'
import { taskStates } from '../constants'

interface IUseQuickFilterProps {
  taskCounts: TaskCountsT | undefined
  filters: FilterStateT
  setFilters: (filters: FilterStateT) => void
}

const useQuickFilter = ({ taskCounts, filters, setFilters }: IUseQuickFilterProps): QuickFilterT | undefined => {
  const { t } = useFavurTranslation()
  const quickFilter = useMemo((): QuickFilterT | undefined => {
    if (taskCounts) {
      // eslint-disable-next-line camelcase
      const openFilter = { task_statuses: [taskStates.todo] }
      if (isEqual(openFilter, filters)) {
        return {
          label: `${taskCounts[taskStates.pending]} ${t(`tasks.tab.pending`)}`,

          // eslint-disable-next-line camelcase
          action: () => setFilters({ task_statuses: [taskStates.pending] }),
        }
      }
      // eslint-disable-next-line camelcase
      const pendingFilter = { task_statuses: [taskStates.pending] }
      if (isEqual(pendingFilter, filters)) {
        return {
          label: `${taskCounts[taskStates.todo]} ${t(`tasks.tab.todo`)}`,
          // eslint-disable-next-line camelcase
          action: () => setFilters({ task_statuses: [taskStates.todo] }),
        }
      }
    }
    return undefined
  }, [filters, setFilters, t, taskCounts])
  return quickFilter
}

export default useQuickFilter
