import theme from 'utils/theme'

export const classes = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  contentRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  noOtherMembers: {
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
}
