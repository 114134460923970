import theme from 'utils/theme'

export const headerClasses = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& .MuiTypography-h2': {
      paddingTop: '2px',
    },
  },
  subheaderContainer: {
    marginTop: theme.spacing(1.125),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
}
