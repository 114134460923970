import React, { useCallback, useMemo } from 'react'
import { Box, Drawer } from '@mui/material'
import useRolesViews from 'hooks/useRolesViews'
import { ArrowLeftDoubleS, ArrowRightDoubleS } from 'icons'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useTaskBarStateContext, {
  useTaskBarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import palette from 'utils/theme/palette'
import Separator from '../Grid/Row/Separator'
import Events from './AbsenceRequestTab/Events'
import EventsPage from './AbsenceRequestTab/Events/EventsPage'
import ManagerTasks from './AbsenceRequestTab/ManagerTasks'
import ManagerTasksPage from './AbsenceRequestTab/ManagerTasks/ManagerTasksPage'
import NewAbsenceRequestPage from './AbsenceRequestTab/NewAbsenceRequest/NewAbsenceRequestPage'
import UserTasks from './AbsenceRequestTab/UserTasks'
import UserTasksPage from './AbsenceRequestTab/UserTasks/UserTasksPage'
import Filters from './Filters'
import IconTabs from './IconTabs'
import NativeCTA from './NativeCTA'
import { taskBarClasses } from './styles'
import { TaskBarStateT, taskBarStates } from './types'

const TaskBar: React.FC<{
  taskCountTotal: number
  refetchAll: () => void
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
  // eslint-disable-next-line complexity
}> = ({ taskCountTotal, refetchAll, refetchTask }) => {
  const { persons } = useRolesViews()
  const { isSmallScreen, selectedOwnerId } = useAbsencePlannerStateContext()
  const { taskBarState } = useTaskBarStateContext()

  const setTaskBarState = useTaskBarStateDispatchContext()
  const isAbsenceManager = useMemo(
    () =>
      (persons ?? [])
        .filter((person) => `${person.ownerId}` === `${selectedOwnerId}`)
        .some((person) => person.permissions.workflowAbsences),
    [persons, selectedOwnerId],
  )

  const toggleTaskBar = useCallback(
    (state: TaskBarStateT | null) => {
      if (state) {
        setTaskBarState(null)
        return
      }

      if (isAbsenceManager) {
        setTaskBarState(taskBarStates.managerTasks)
      } else {
        setTaskBarState(taskBarStates.filters)
      }
    },
    [isAbsenceManager, setTaskBarState],
  )

  const taskBarContent = (
    <Box sx={taskBarClasses.openContentContainer(isSmallScreen)} data-testid="taskbar-content">
      {taskBarState === taskBarStates.filters && isSmallScreen && (
        <NativeCTA isAbsenceManager={isAbsenceManager} taskCountTotal={taskCountTotal} />
      )}
      {taskBarState === taskBarStates.filters && <Filters isAbsenceManager={isAbsenceManager} />}
      {taskBarState === taskBarStates.events && <Events />}
      {taskBarState === taskBarStates.managerTasks && (
        <ManagerTasks
          refetchAll={refetchAll}
          refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
        />
      )}
      {taskBarState === taskBarStates.userTasks && (
        <UserTasks
          refetchAll={refetchAll}
          refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
        />
      )}
    </Box>
  )

  if (isSmallScreen) {
    if (taskBarState === taskBarStates.managerTasks) {
      return (
        <ManagerTasksPage
          refetchAll={refetchAll}
          refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
        />
      )
    } else if (taskBarState === taskBarStates.userTasks) {
      return (
        <UserTasksPage
          refetchAll={refetchAll}
          refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
        />
      )
    } else if (taskBarState === taskBarStates.newAbsenceRequest) {
      return <NewAbsenceRequestPage refetchAll={refetchAll} />
    } else if (taskBarState === taskBarStates.events) {
      return <EventsPage />
    }

    return (
      <Drawer
        sx={taskBarClasses.sideMenuContainer}
        PaperProps={{ sx: taskBarClasses.sideMenu }}
        anchor="right"
        open={Boolean(taskBarState)}
        onClose={() => {
          setTaskBarState(null)
        }}
      >
        {taskBarContent}
      </Drawer>
    )
  }

  return (
    <Box sx={taskBarClasses.parentContainer}>
      <Separator />
      <Box
        sx={[
          taskBarClasses.mainContainer,
          ...(taskBarState ? [taskBarClasses.openDrawer] : [taskBarClasses.closedDrawer]),
        ]}
      >
        <Box sx={taskBarClasses.barContainer}>
          <IconTabs isAbsenceManager={isAbsenceManager} taskCountTotal={taskCountTotal} />
          <Box sx={taskBarClasses.toggleButtonContainer} onClick={() => toggleTaskBar(taskBarState)}>
            {taskBarState ? (
              <ArrowRightDoubleS fill={palette.primary.main} />
            ) : (
              <ArrowLeftDoubleS fill={palette.primary.main} />
            )}
          </Box>
        </Box>
        {taskBarContent}
      </Box>
    </Box>
  )
}

export default TaskBar
