export const iconTabsClasses = {
  iconContainer: {
    paddingTop: '44px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  menuIconWrapper: {
    position: 'relative',
    top: '15px',
    right: '3px',
  },
}
