import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const DownloadS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.25 9.25a.75.75 0 0 1 .743.648L14 10v2.7a1.25 1.25 0 0 1-1.122 1.244l-.128.006h-9.5a1.25 1.25 0 0 1-1.244-1.122L2 12.7V10a.75.75 0 0 1 1.493-.102L3.5 10v2.45h9V10a.75.75 0 0 1 .648-.743l.102-.007zM8 2.25a.75.75 0 0 1 .743.648L8.75 3v5.189l1.72-1.72a.75.75 0 0 1 .976-.072l.084.073a.75.75 0 0 1 .073.976l-.073.084-3 3a.606.606 0 0 1-.081.071l.081-.07a.77.77 0 0 1-.182.133l-.026.014a.94.94 0 0 1-.085.034l-.019.006a.776.776 0 0 1-.331.024l-.035-.007c-.025-.005-.05-.01-.073-.018l-.036-.012-.067-.028-.036-.019a.92.92 0 0 1-.074-.046l-.012-.01a.617.617 0 0 1-.084-.072l-3-3a.749.749 0 0 1 .976-1.133l.084.073 1.72 1.719V3A.75.75 0 0 1 8 2.25z"
      />
    </Svg>
  )
}

export default DownloadS
