import {
  createTimelineItemHeaderOnlyText,
  createTimelineItemHeaderWithHrAvatar,
  createTimeLineItem,
  createTimelineItemHeaderWithAvatarIcon,
} from 'components/Timeline/factory'
import { TimelineItemContentBadgeTypeT, TimelineItemComponentT, TimelineItemContentT } from 'components/Timeline/types'
import { Locale } from 'date-fns'
import { TFunction } from 'i18next'
import { monthlySheetStatus } from 'pages/Tasks/MonthlySheet/constants'
import { taskStates } from 'pages/Tasks/constants'
import { MonthlySheetTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { getAvatarFromPerson, getAvatarFromTimeline, getItsMe } from 'pages/Tasks/utils/timeline'
import { PersonT } from 'types'
import { getDateFromISOString, getRelativeDate } from 'utils/date'
import { getUserName } from 'utils/person'

const mayAddPendingElement = (task: TaskT<MonthlySheetTaskT>, t: TFunction, isOffice: boolean) => {
  if (task.statusFe === taskStates.pending) {
    if (isOffice) {
      return [
        createTimeLineItem(
          createTimelineItemHeaderOnlyText(
            'waitingFrontlinerResponse',
            t('workTimeControl.timeline.managerWaitingAcceptOrChange', {
              name: task.person ? getUserName(task.person, true) : '',
            }),
            true,
          ),
        ),
      ]
    }
    return [
      createTimeLineItem(
        createTimelineItemHeaderOnlyText(
          'waitingManagerResponse',
          t('workTimeControl.timeline.frontlinerWaitingResponse'),
          true,
        ),
      ),
    ]
  }

  return []
}

const mayAddClosedElement = (task: TaskT<MonthlySheetTaskT>, t: TFunction) => {
  if (task.statusFe === taskStates.closed) {
    const worktimeAcceptedStatusEnd = createTimeLineItem(
      createTimelineItemHeaderOnlyText(
        'worktimeAcceptedStatusEnd',
        t('workTimeControl.timeline.acceptedAndClosed'),
        true,
      ),
    )
    return [worktimeAcceptedStatusEnd]
  }
  return []
}

const mayAddDefaultClosedAction = (task: TaskT<MonthlySheetTaskT>, t: TFunction, locale: Locale, isOffice: boolean) => {
  if (task.status === monthlySheetStatus.approvedFrontline) {
    const document = task?.taskMonthlySheet?.document
    const date = document?.createdByMirus ? getRelativeDate(getDateFromISOString(document?.createdByMirus), locale) : ''
    // If its default Element, and its frontline view... its you!
    const avatar = getAvatarFromPerson(task?.person as PersonT, t, !isOffice)

    const worktimeAcceptedStatus = createTimeLineItem(
      createTimelineItemHeaderWithAvatarIcon('worktimeAcceptedStatus', date, avatar),
      { badge: TimelineItemContentBadgeTypeT.workTimeAccepted },
    )

    return [worktimeAcceptedStatus]
  }

  return []
}

const getTimelineHeader = (
  timelineItem: TimelineItemT,
  t: TFunction,
  locale: Locale,
  itsMe: boolean,
  isFlashing: boolean,
) => {
  const id = `timelineItemHeader-${timelineItem.id}`
  if (timelineItem.authorIsSystem) {
    const date = timelineItem?.insertedAt ? getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale) : ''
    return createTimelineItemHeaderWithHrAvatar(id, date, t('workTimeControl.timeline.HRManager'), isFlashing)
  }

  const date = timelineItem?.insertedAt ? getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale) : ''
  const avatar = getAvatarFromTimeline(timelineItem, t, itsMe)

  return createTimelineItemHeaderWithAvatarIcon(id, date, avatar, isFlashing)
}

const getTimelineContent = (timelineItem: TimelineItemT, tenantName?: string): TimelineItemContentT => {
  return {
    document: timelineItem.document,
    tenantName: tenantName,
    badge: timelineItem.badge as TimelineItemContentBadgeTypeT,
    text: timelineItem.comment,
    datetime: timelineItem.insertedAt,
  }
}

const getDocumentItem = (task: TaskT<MonthlySheetTaskT>, t: TFunction, locale: Locale) => {
  const document = task?.taskMonthlySheet?.document
  const person = task?.person as PersonT
  const date = document?.createdByMirus ? getRelativeDate(getDateFromISOString(document?.createdByMirus), locale) : ''

  const documentHeader = createTimelineItemHeaderWithHrAvatar(
    'defaultDocumentItem',
    date,
    t('workTimeControl.timeline.HRManager'),
  )
  const documentContent = { document: document, tenantName: person?.tenant?.name || '' }

  return createTimeLineItem(documentHeader, documentContent)
}

const getDefaultTimeline = (
  task: TaskT<MonthlySheetTaskT>,
  t: TFunction,
  locale: Locale,
  isOffice: boolean,
): TimelineItemComponentT[] => {
  return [
    getDocumentItem(task, t, locale),
    ...mayAddPendingElement(task, t, isOffice),
    ...mayAddDefaultClosedAction(task, t, locale, isOffice),
    ...mayAddClosedElement(task, t),
  ] as TimelineItemComponentT[]
}

const getTimeline = (
  task: TaskT<MonthlySheetTaskT>,
  t: TFunction,
  locale: Locale,
  isOffice: boolean,
  personIds: number[],
): TimelineItemComponentT[] => {
  if (!task.timeline) {
    return []
  }

  const timelineSize = task.timeline?.length
  const timeline = task.timeline?.map((timelineItem: TimelineItemT, index) => {
    const isFlashing = index + 1 === timelineSize && task.statusFe === taskStates.todo
    const itsMe = getItsMe(timelineItem.authorId, personIds)
    const timelineHeader = getTimelineHeader(timelineItem, t, locale, itsMe, isFlashing)
    const timelineContent = getTimelineContent(timelineItem, task.person?.tenant?.name)

    return createTimeLineItem(timelineHeader, timelineContent)
  })

  return [...timeline, ...mayAddPendingElement(task, t, isOffice), ...mayAddClosedElement(task, t)]
}

interface IgetTimelineAttributes {
  task: TaskT<MonthlySheetTaskT>
  t: TFunction
  locale: Locale
  isOffice: boolean
  personIds: number[]
}
export const getTimelineForComponent = ({
  task,
  t,
  locale,
  isOffice = false,
  personIds,
}: IgetTimelineAttributes): TimelineItemComponentT[] => {
  if (task.timeline && task.timeline.length > 0) {
    return getTimeline(task, t, locale, isOffice, personIds)
  }

  return getDefaultTimeline(task, t, locale, isOffice)
}
