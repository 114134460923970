import { useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { OwnerPersons } from 'hooks/useOwnersOfUser'
import type { PersonT } from 'types'
import { getOwnerPersonsFromPersonList } from 'utils/person'
import { findAllAbsencePlannerUserPersons } from '../queries'

export type OwnersPropsT = {
  onComplete?: (owners: OwnerPersons[]) => void
}

export const useAbsencePlannerUser = ({ onComplete }: OwnersPropsT) => {
  const [owners, setOwners] = useState<OwnerPersons[]>([])
  const [persons, setPersons] = useState<PersonT[]>([])

  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(findAllAbsencePlannerUserPersons, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const responsePersons = response?.findAllPersons
      setPersons(responsePersons)
      const ownersList = getOwnerPersonsFromPersonList(responsePersons, false, true)
      setOwners(ownersList)

      if (onComplete) {
        onComplete(ownersList)
      }
    },
  })

  return { loading, owners, persons }
}
