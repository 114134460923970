import favurTheme from 'utils/theme'

export const classes = {
  tenantName: {
    marginBottom: favurTheme.spacing(0.25),
  },
  noDataText: {
    marginLeft: favurTheme.spacing(0.25),
  },
  textContainer: {
    marginTop: favurTheme.spacing(1.25),
    color: favurTheme.palette.grey[600],
    display: 'flex',
    alignItems: 'center',
  },
  shiftTypeTypography: {
    lineHeight: '22px !important',
  },
  shiftTimeTypography: {
    lineHeight: '22px !important',
    flex: 1,
    textAlign: 'right',
    fontVariantNumeric: 'tabular-nums',
  },
}
