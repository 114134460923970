import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const SlimCheckMarkS: React.FC<IconPropsT> = () => {
  return (
    <Svg size={ICON_SIZE_S}>
      <path d="m11 1-6.7 9.6a1 1 0 0 1-1.6 0L1 8.3" stroke="#1DAF2A" fill="none" />
    </Svg>
  )
}

export default SlimCheckMarkS
