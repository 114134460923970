import theme from 'utils/theme'

export const classes = {
  card: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  greyContainedElements: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '8px',
  },
}
