import { createContext, Context } from 'react'
import { IBannerMessagesContext } from './types'

const initialState: IBannerMessagesContext = {
  setBannerMessage: (_) => {},
  bannerMessage: undefined,
}

const BannerMessagesContext: Context<IBannerMessagesContext> = createContext(initialState)

export default BannerMessagesContext
