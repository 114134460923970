import React, { useCallback, useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { TimelineItemContentDocumentT } from 'components/Timeline/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useFileDisplay, useFileDownload } from 'hooks/useFileDownload'
import { OfficeFilePdfL } from 'icons'
import FlashMessagesContext from 'services/FlashMessages/context'
import { formatDate, getDocumentDownloadUrl } from 'utils/documents'
import { isNative } from 'utils/platform'
import theme from 'utils/theme'
import TimelineCTA from '../TimelineCTA'
import { classes } from './styles'
import { getDocumentTranslationLabel } from './utils'

const TimeLineItemDocument: React.FC<TimelineItemContentDocumentT> = ({ document, tenantName }) => {
  const { t, locale } = useFavurTranslation()
  const { downloadFile, error: downloadError } = useFileDownload(() => {})
  const { displayFile, error: displayError } = useFileDisplay(() => {})
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const documentLabel = t(getDocumentTranslationLabel(document))
  const date = document.data?.startDate || document.createdByMirus
  const documentDate = formatDate(date, locale, 'MMMM yyyy')

  const viewFile = useCallback(() => {
    const fileObj = {
      url: getDocumentDownloadUrl(document.favurUuid),
      filename: `${documentLabel}-${Date.now()}.pdf`,
    }
    return isNative() ? displayFile(fileObj) : downloadFile(fileObj)
  }, [displayFile, documentLabel, downloadFile, document.favurUuid])

  useEffect(() => {
    removeAll()
    if (downloadError || displayError) {
      setFlashMessage(t('page.documents.download.error'))
    }
  }, [displayError, downloadError, removeAll, setFlashMessage, t])

  return (
    <TimelineCTA
      onClick={viewFile}
      icon={
        <Box sx={classes.icon}>
          <OfficeFilePdfL fill={theme.palette.primary.main} />
        </Box>
      }
      subtitle={tenantName}
      title={documentDate}
    />
  )
}
;<OfficeFilePdfL fill={theme.palette.primary.main} />
export default TimeLineItemDocument
