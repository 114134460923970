import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const tokenClasses = {
  errorAlert: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
  },
  inputContainer: {
    height: theme.spacing(2.5625),
  },
  label: {
    color: theme.palette.text.secondary,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'space-between',
    marginBottom: `calc(100px + var(--ion-safe-area-bottom))`,
  },
  token: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}

export const tenantClasses = {
  label: {
    paddingLeft: '12px',
  },
  name: {
    color: theme.palette.text.primary,
    padding: 0,
    paddingBottom: '3px',
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    width: '260px',
    textAlign: 'left',
    paddingLeft: '12px',
  },
  root: {
    marginTop: '24px',
    marginBottom: '42px',
    width: '272px',
  },
}

export const inputClasses = {
  active: {
    borderBottom: `3px solid ${theme.palette.primary.light}`,
  },
  input: {
    letterSpacing: '-0.4px',
    color: theme.palette.text.primary,
    fontFamily: 'Signika, sans-serif',
    fontSize: theme.spacing(2.0625),
    textAlign: 'center',
    height: theme.spacing(2.5625),
    padding: 0,
    paddingBottom: '8px',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    width: '272px',
  },
  error: {
    borderBottom: `3px solid ${palette.error.main}`,
  },
  errorLabel: { color: palette.error.main },
  labelSuccess: { color: palette.success.main },
  success: {
    borderBottom: `3px solid ${palette.success.main}`,
  },
}
