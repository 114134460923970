import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const EditM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          d="M20.52.037a3.109 3.109 0 0 0-1.26.517c-.178.124-1.082 1.017-5.115 5.049-4.018 4.017-4.909 4.918-4.954 5.016-.046.098-.104.454-.327 2.004-.149 1.037-.27 1.955-.271 2.041 0 .09.017.206.041.275.05.138.236.341.373.406.21.098.212.098 2.246-.19 1.051-.15 1.97-.29 2.043-.312.13-.038.215-.122 4.447-4.351 5.753-5.748 5.6-5.594 5.75-5.824.323-.494.475-.97.5-1.561.079-1.879-1.6-3.364-3.473-3.07Zm.096 1.512c-.314.086-.549.251-.99.699l-.21.213 1.064 1.063 1.063 1.063.32-.329c.515-.53.613-.732.613-1.258 0-.243-.01-.332-.053-.469-.129-.41-.51-.8-.925-.948-.198-.07-.683-.089-.882-.034ZM14.45 7.426 10.576 11.3l-.176 1.23a45.403 45.403 0 0 0-.168 1.238c.004.004.562-.071 1.238-.168l1.23-.176 3.874-3.874c2.13-2.13 3.874-3.885 3.874-3.898 0-.031-2.069-2.1-2.1-2.1-.013 0-1.767 1.743-3.898 3.874ZM2.013 4.514A2.254 2.254 0 0 0 .2 5.824a2.82 2.82 0 0 0-.135.376l-.053.196V22.14l.067.215c.248.79.776 1.318 1.567 1.566l.214.067h15.744l.203-.055a2.28 2.28 0 0 0 1.65-1.733c.04-.193.043-.416.043-4.14 0-3.125-.006-3.957-.03-4.037-.062-.202-.296-.435-.493-.49a.841.841 0 0 0-.53.035.833.833 0 0 0-.383.372l-.052.112L18 17.988l-.012 3.936-.057.127a.826.826 0 0 1-.186.244c-.234.214.611.194-8.037.187l-7.68-.006-.101-.054a.772.772 0 0 1-.332-.322l-.07-.128-.007-7.68c-.007-8.648-.027-7.803.188-8.037a.826.826 0 0 1 .244-.186l.126-.057L6.012 6l3.936-.012.112-.052c.418-.193.571-.762.3-1.116a.92.92 0 0 0-.37-.289c-.071-.024-.89-.031-3.942-.034-2.118-.001-3.934.006-4.035.017Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default EditM
