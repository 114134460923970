import { useCallback, useState } from 'react'
import { SettingsT, TutorialId } from 'types'
import useUserSettings from './useUserSettings'

const useTutorials = () => {
  const [userSettings, setUserSettings] = useState<SettingsT | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const { updateUserSettings, refetch } = useUserSettings({
    onComplete: (newUserSettings) => {
      setUserSettings(newUserSettings)
      setLoading(false)
    },
  })

  const beenShownBefore = useCallback(
    (tutorialId: TutorialId) => {
      if (!userSettings) return undefined
      if (!userSettings.seenTutorials) return false
      return userSettings.seenTutorials.includes(tutorialId)
    },
    [userSettings],
  )

  const markAsSeenList = useCallback(
    (tutorialIds: TutorialId[]): void => {
      const currentTutorials = !userSettings || !userSettings.seenTutorials ? [] : userSettings.seenTutorials

      const notSeenTutorials = tutorialIds.filter((tutorial) => !currentTutorials.includes(tutorial))
      if (notSeenTutorials.length === 0) return

      updateUserSettings({ seenTutorials: [...currentTutorials, ...notSeenTutorials] })

      setLoading(true)
      refetch()
    },
    [refetch, updateUserSettings, userSettings],
  )

  const markAsSeen = useCallback(
    (tutorialId: TutorialId): void => {
      markAsSeenList([tutorialId])
    },
    [markAsSeenList],
  )

  return { beenShownBefore, markAsSeen, isReady: Boolean(userSettings) && !loading, markAsSeenList }
}

export default useTutorials
