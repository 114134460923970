import theme from 'utils/theme'
import { CELL_WIDTH, TEAM_NAME_ROW_HEIGHT } from '../constants'

export const teamNameRowClasses = {
  container: {
    height: `${TEAM_NAME_ROW_HEIGHT}px`,
    // @ts-ignore
    backgroundColor: 'transparent',
    backgroundSize: `${CELL_WIDTH}px`,
    width: '100%',
    boxSizing: 'border-box',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    left: 0,
    flex: 1,
    zIndex: 11,
  },
  contentWrapper: {
    marginLeft: '16px',
    paddingLeft: 0,
  },
  text: {
    fontWeight: 600,
  },
  placeholderRowCell: {
    height: '100%',
    minWidth: `${CELL_WIDTH}px`,
    width: `${CELL_WIDTH}px`,
    boxSizing: 'border-box',
    // @ts-ignore
    borderLeft: `1px solid ${theme.palette.primary[25]}`,
  },
}
