import theme from 'utils/theme'

const classes = {
  infoBoxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
    lineHeight: theme.spacing(1.5),
    '& > Box ': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  infoBoxBody: {
    lineHeight: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75%',
  },
  wrappedInfoBoxBody: {
    lineHeight: theme.spacing(1.5),
    maxWidth: '75%',
  },
  cardTopGrid: {
    marginBottom: '12px',
  },
  noButton: {
    paddingBottom: theme.spacing(0.5),
  },
  callToActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    height: '24px',
  },
  taskCardCTAContainer: {
    display: 'flex',
    height: '24px',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  callToAction: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.7px',
    background: 'none',
    padding: 0,
  },
  taskCardDataText: {
    fontSize: '10px',
    fontWeight: 600,
    color: theme.palette.info.main,
    lineHeight: '13px',
  },
  taskCardBody: {
    marginTop: '12px',
  },
  taskCardBodyTitle: {
    lineHeight: '18px',
    marginBottom: theme.spacing(0.25),
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.4px',
  },
  taskCardBodyContent: {
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(0.25),
  },
  taskCardContentWrapper: {
    marginBottom: '12px',
  },
  taskHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  taskHeaderTitle: {
    flex: 1,
  },
  sideMenuIcon: {
    color: theme.palette.common.white,
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
    position: 'relative',
    top: '12px',
  },
  secondButton: {
    paddingLeft: theme.spacing(0.5),
  },
  emptyHeaderBlock: {
    width: '32px',
  },
  nameTag: {
    // @ts-ignore
    color: theme.palette.primary[700],
    marginBottom: theme.spacing(0.25),
  },
}

export const headerClasses = {
  subHeader: {
    backgroundColor: 'white !important',
    // @ts-ignore
    color: `${theme.palette.primary[900]}  !important`,
    zIndex: 10,
    '&[stuck="true"]': {
      boxShadow: theme.shadows[5],
    },
  },
}

export default classes
