import theme from 'utils/theme'
import { AVATAR_MENU_CLOSED_WIDTH, AVATAR_MENU_OPEN_WIDTH } from '../constants'

export const teamNameRowsClasses = {
  outerContainer: (avatarBarExpanded: boolean, isSmallScreen: boolean) => ({
    position: 'sticky',
    left: `${avatarBarExpanded && !isSmallScreen ? AVATAR_MENU_OPEN_WIDTH : AVATAR_MENU_CLOSED_WIDTH}px`,
    zIndex: 10,
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
    minWidth: isSmallScreen ? `300px` : `500px`,
  }),
  innerContainer: (topPosition: number) => ({
    position: 'relative',
    left: 0,
    top: topPosition + 73,
    width: '100%',
  }),
}
