import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CalendarDayM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <rect width="3" height="3" x="6" y="12" fill="#FF007C" rx="1.5" />
      <path
        fillRule="evenodd"
        d="M8 2.25c.41 0 .75.34.75.75v2h6.5V3a.75.75 0 0 1 1.5 0v2H19a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h2.25V3c0-.41.34-.75.75-.75Zm7.25 4.25V7a.75.75 0 0 0 1.5 0v-.5H19c.28 0 .5.22.5.5v2.5h-15V7c0-.28.22-.5.5-.5h2.25V7a.75.75 0 0 0 1.5 0v-.5h6.5ZM4.5 11v8c0 .28.22.5.5.5h14a.5.5 0 0 0 .5-.5v-8h-15Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default CalendarDayM
