import { getBackgroundColor } from 'components/Badge/helpers'
import { PlannedShiftT } from 'types'
import favurTheme from 'utils/theme'
import theme from 'utils/theme'

export const shiftCardAvatarClasses = {
  avatarLastElem: {
    backgroundColor: `${favurTheme.palette.grey[100]} !important`,
    color: `${favurTheme.palette.grey[600]} !important`,
  },
}

export const classes = {
  card: {
    margin: `${theme.spacing(0.75)} ${theme.spacing(0.75)} ${theme.spacing(1)}`,
  },
  floatingLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tenantName: {
    marginBottom: theme.spacing(0.25),
  },
  shiftContainer: {
    marginTop: theme.spacing(1),
    '&>:not(:last-child):not(.MuiTypography-root)': {
      marginBottom: theme.spacing(1),
    },
  },
  shiftContainerTopMargin: {
    marginTop: '-24px',
  },
  fullMargin: {
    marginTop: theme.spacing(1.25),
  },
  reducedMargin: {
    marginTop: theme.spacing(0.75),
  },
  teamHeader: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  teamHeaderTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.text.primary,
  },
  teamHeaderArrow: {
    paddingTop: theme.spacing(0.25),
  },
  vacationsSectionTitleContainer: {
    marginTop: '12px',
  },
  vacationsSectionTitle: {
    fontWeight: 350,
    fontSize: '12px',
    lineHeight: '15px',
  },
  avatarContainer: {
    marginTop: '3px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  avatarElementContainer: {
    width: '40px',
  },
  avatarLastElemText: {
    position: 'relative',
    bottom: '3px',
    left: '0.5px',
  },
  avatarColorsContainer: {
    display: 'flex',
    marginTop: '2px',
    width: '40px',
    gap: '1px',
  },
  plannedTimesTitle: {
    marginBottom: theme.spacing(-1.25),
  },
  vacationsTitleMargin: {
    marginTop: '0px',
  },
  eventCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '8px',
  },
  eventCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}

export const chipClasses = {
  avatarShiftColor: (shift: PlannedShiftT) => ({
    height: '4px',
    width: '100%',
    backgroundColor: getBackgroundColor(shift, theme, true),
    '& .MuiChip-label': {
      padding: 0,
    },
    border: '0.25px solid rgba(0, 0, 0, 0.08)',
  }),
}
