import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { classes as notificationsClasses } from 'components/Notification/Templates/Notification/styles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from '../../../styles'

const NoNotificationsCard: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Card>
      <Box sx={[notificationsClasses.bodyContainer, classes.noNotificationsBody]}>
        <Typography variant="body2" sx={notificationsClasses.title}>
          {t('page.notifications.list.noNotifications')}
        </Typography>
      </Box>
    </Card>
  )
}

export default NoNotificationsCard
