import { AbsencePlannerBadgeT } from 'pages/AbsencePlanner/types'
import { getManualShiftLabel } from 'utils/shifts'
import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const getBadgeLabel = (badge: AbsencePlannerBadgeT): string => {
  const label = badge.shortDescription && badge.shortDescription.trim() !== '' ? badge.shortDescription : '-'
  return getManualShiftLabel({ isManual: badge.isManual ?? false, text: label })
}

export const getLabelColor = (badge: AbsencePlannerBadgeT, isGreyscale: boolean) => {
  if (badge.isManual) {
    return theme.palette.grey[900]
  }

  const fullColor = badge.hexTextColor && badge.hexTextColor !== '' ? badge.hexTextColor : theme.palette.common.white

  return isGreyscale ? palette.grey[900] : fullColor
}

export const getBackgroundColor = (badge: AbsencePlannerBadgeT, isGreyscale: boolean): string => {
  if (badge.isManual) {
    return theme.palette.common.white
  }

  const fullColor =
    badge.hexBackgroundColor && badge.hexBackgroundColor !== '' ? badge.hexBackgroundColor : theme.palette.primary.main

  return isGreyscale ? palette.grey[300] : fullColor
}

export const getBorder = (badge: AbsencePlannerBadgeT, isGreyscale: boolean) => {
  if (!badge.isManual) {
    return 'none'
  }

  const fullColor =
    badge.hexBackgroundColor && badge.hexBackgroundColor !== '' ? badge.hexBackgroundColor : theme.palette.primary.main

  return `1px solid ${isGreyscale ? palette.grey[300] : fullColor}`
}
