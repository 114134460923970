import { useCallback, useContext, useState } from 'react'
import useManagedTenants, { GetManagedTenantsReturnT } from 'hooks/useManagedTenants'
import FlashMessagesContext from 'services/FlashMessages/context'
import { TenantReminderT, TenantT } from 'types'
import { removeTypenameFromObject } from 'utils/apollo'
import { MutationResult, useMutation } from '@apollo/client'
import { editTenantMutation } from '../pages/Tenant/queries'

type EditTenantInputT = {
  name?: string
  hide_unverified_effective_times?: boolean
  reminders?: TenantReminderT[]
  absences_blocking_period?: number
}

const useTenantPageData = (tenantUuid: string) => {
  const [tenant, setTenant] = useState<TenantT>()
  const [loadingMutations, setLoadingMutation] = useState(false)
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  const onFetchTenantsComplete = useCallback(
    (data: GetManagedTenantsReturnT) => {
      const fetchedTenant = data.getManagedTenants.find((t) => t.uuid === tenantUuid)
      setTenant(fetchedTenant)
    },
    [tenantUuid],
  )

  const { loading, refetch } = useManagedTenants(onFetchTenantsComplete)
  const [editTenant] = useMutation<MutationResult>(editTenantMutation)

  const onEditTenant = useCallback(
    (inputValues: EditTenantInputT) => {
      if (!tenant) return
      removeAll()
      setLoadingMutation(true)
      editTenant({
        variables: {
          tenantId: tenant.id,
          editTenant: inputValues,
        },
      })
        .then(() => {
          refetch()
          setFlashMessage('page.userSettings.tenantReminderList.successMessage')
        })
        .catch((error) => {
          const errorObject = error.graphQLErrors[0]
          setFlashMessage(errorObject.code ?? 'common.error.be.default')
        })
        .finally(() => setLoadingMutation(false))
    },
    [editTenant, refetch, removeAll, setFlashMessage, tenant],
  )

  const onChangeTenantName = useCallback((newName: string) => onEditTenant({ name: newName }), [onEditTenant])

  const onChangeHideUnverifiedEffectiveTimes = useCallback(
    // eslint-disable-next-line camelcase
    (newValue: boolean) => onEditTenant({ hide_unverified_effective_times: newValue }),
    [onEditTenant],
  )

  const onChangeTenantReminders = useCallback(
    (newValue: TenantReminderT[]) =>
      onEditTenant({ reminders: newValue.map((reminder) => removeTypenameFromObject(reminder) as TenantReminderT) }),
    [onEditTenant],
  )

  const onChangeAbsencesBlockingPeriod = useCallback(
    // eslint-disable-next-line camelcase
    (newValue: number) => onEditTenant({ absences_blocking_period: newValue }),
    [onEditTenant],
  )

  return {
    tenant,
    loading,
    loadingMutations,
    onChangeTenantName,
    onChangeHideUnverifiedEffectiveTimes,
    onChangeTenantReminders,
    onChangeAbsencesBlockingPeriod,
  }
}

export default useTenantPageData
