import { authQuery, defaultApiMeFields } from 'shared/queries'

export const logoutMutationParams = `
  mutation {
    logout {
      auth
    }
  }
`

export const logoutDeviceMutationParams = (token: string) => `
  mutation {
    logout(token: "${token}") {
      auth
    }
  }
`

export const RememberMeParams = `
  authRefreshToken{
    ${authQuery}
  }
`

export const getSessionQuery = ({ buildVersionNumber }: { buildVersionNumber: string }) => `query {
  me{
    ${defaultApiMeFields}
    cmsActivated
    phone
    termsAndConditionsAcceptedAt
    user {
      language
    }
  },
  versionNumber(buildVersionNumber: "${buildVersionNumber}")
}`
