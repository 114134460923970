import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const AddS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8.5 1.33a6.67 6.67 0 1 1 0 13.34 6.67 6.67 0 0 1 0-13.34Zm0 1.34a5.33 5.33 0 1 0 0 10.66 5.33 5.33 0 0 0 0-10.66Zm.67 2v2.66h2.66v1.34H9.17v2.66H7.83V8.67H5.17V7.33h2.66V4.67h1.34Z"
      />
    </Svg>
  )
}

export default AddS
