import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flexGrow: '0 !important' as any,
    },
  },
  loading: {
    justifyContent: 'center',
  },
  caption: {
    textTransform: 'none',
    display: 'block',
    marginTop: theme.spacing(0.25),
  },
  displayButton: {
    background: 'none',
    display: 'flex',
    textAlign: 'left',
    boxSizing: 'content-box',
    padding: 0,
    margin: 0,
    '&:hover, &:focus': {
      backgroundColor: 'white',
    },
    fontSize: 'inherit',
    alignItems: 'flex-start',
    width: '100%',
  },
  fillColumn: {
    width: '-moz-available',
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    width: '-webkit-fill-available',
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    width: 'fill-available',
  },
  pdfIcon: {
    width: theme.spacing(2),
    paddingRight: theme.spacing(1.25),
    paddingTop: theme.spacing(0.25),
    marginLeft: theme.spacing(-0.25),
    // @ts-ignore
    color: theme.palette.primary[700],
  },
  notificationIcon: {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.25),
    marginTop: theme.spacing(-0.25),
    borderRadius: '50%',
    // @ts-ignore
    backgroundColor: theme.palette.secondary[400],
  },
  content: {
    // @ts-ignore
    color: theme.palette.grey[900],
    flexGrow: 1,
  },
  download: {
    // @ts-ignore
    color: theme.palette.primary[700],
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  downloadButton: {
    '&:hover, &:focus': {
      backgroundColor: 'white',
    },
    '&>.MuiIconButton-label': {
      flexDirection: 'column',
      '&>*': {
        textAlign: 'right',
      },
    },
  },
  date: {
    // @ts-ignore
    color: theme.palette.grey[900],
    paddingTop: theme.spacing(0.25),
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    position: 'relative',
  },
  headerIcon: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: 0,
  },
  unacceptedMonthlySheet: {
    // @ts-ignore
    color: `${theme.palette.grey[900]} !important`,
    opacity: 0.5,
  },
}
