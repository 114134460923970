import { createContext, Context, Dispatch, SetStateAction } from 'react'

export type EmployeePermissionsParamsT = {
  favurUuid: string | undefined
  authorId: number | undefined
  currentTenantTabIndex: number | undefined
}

type EmployeePermissionsContextT = {
  employeePermissionsParams: EmployeePermissionsParamsT
  setEmployeePermissionsParams: Dispatch<SetStateAction<EmployeePermissionsParamsT>>
}

export const initialState: EmployeePermissionsContextT = {
  employeePermissionsParams: { favurUuid: undefined, authorId: undefined, currentTenantTabIndex: undefined },
  setEmployeePermissionsParams: () => {},
}

const EmployeePermissionsContext: Context<EmployeePermissionsContextT> = createContext(initialState)

export default EmployeePermissionsContext
