import theme from 'utils/theme'

export const classes = {
  tilesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
  },
  taskBox: {
    // @ts-ignore
    color: theme.palette.primary[700],
    cursor: 'pointer',
    flex: 1,
    flexGrow: 1,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    alignItems: 'center',
  },
  taskContent: {
    display: 'flex',
    alignItems: 'center',
  },
  countContainer: {
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
    gap: '4px',
    alignItems: 'inherit',
    '&>svg': {
      marginRight: '4px',
    },
  },
}
