import React from 'react'
import { Button } from '@mui/material'
import { t } from 'i18next'
import { classes } from './styles'

interface IPlanAbsenceButtonProps {
  onClick: () => void
  dataTestId?: string
}

const PlanAbsenceButton: React.FC<IPlanAbsenceButtonProps> = ({ onClick, dataTestId }) => {
  return (
    <Button data-testid={dataTestId} sx={classes.requestAbsenceButton} onClick={onClick}>
      <>{t('absencePlanner.taskbar.absencerequest.button')}</>
    </Button>
  )
}

export default PlanAbsenceButton
