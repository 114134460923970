import React from 'react'
import { Box, Paper } from '@mui/material'
import Logo from 'components/Logo'
import Page from 'components/Page'
import { classes } from './styles'

const LoadingPage: React.FC = () => {
  return (
    <Page center>
      <Box component={Paper} p={2}>
        <Box sx={classes.wrapper}>
          <Box sx={classes.LogoWrapper}>
            <Logo />
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default LoadingPage
