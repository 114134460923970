import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AuthenticationKind } from 'constants/authentication'
import useKeyboardState from 'hooks/useKeyboardState'
import useSessionContext from 'hooks/useSessionContext'
import { TutorialId } from 'types'
import { IonContent as CoreIonContent, IonContent as IonContentType } from '@ionic/core/components/ion-content'
import { IonContent } from '@ionic/react'
import Header from './Header'
import BannerWithContext from './Header/BannerWithContext'
import NavigationApp from './NavigationApp'
import pageClasses from './styles'

interface IPageProps {
  banner?: React.ReactElement | string | null
  center?: boolean
  children?: React.ReactNode
  flex?: boolean
  header?: React.ReactElement | string | null
  sxHeader?: SxProps<Theme>
  footer?: React.ReactElement | string | null
  topContent?: React.ReactElement | string | null
  paddingX?: number
  showHeader?: boolean
  hideNativeNavigation?: boolean
  hideWebToolbar?: boolean
  hideBackground?: boolean
  noPadding?: boolean
  columnCenter?: boolean
  hideHamburger?: boolean
  fitToViewport?: boolean
  growSubheader?: boolean
  growContent?: boolean
  hasFloatingButtons?: boolean
  removeLastMargin?: boolean
  backgroundColor?: string
  tutorialPopoverKey?: TutorialId
  fullContentWidth?: boolean
  fullContentHeight?: boolean
  extraBottomMargin?: number
}

// eslint-disable-next-line complexity
const Page: React.FC<IPageProps> = ({
  children,
  banner,
  header,
  sxHeader,
  footer,
  topContent,
  center = false,
  flex = false,
  paddingX = 0.75,
  showHeader,
  hideNativeNavigation,
  hideWebToolbar,
  hideBackground,
  hideHamburger,
  noPadding,
  columnCenter,
  fitToViewport,
  growSubheader,
  growContent = true,
  hasFloatingButtons,
  removeLastMargin,
  backgroundColor = 'transparent',
  fullContentWidth = false,
  fullContentHeight = false,
  extraBottomMargin = 0,
}: PropsWithChildren<IPageProps>) => {
  const addSafeMargin = !header
  const { isOpen, keyboardHeight } = useKeyboardState()
  const [navigate, setNavigation] = useState(false)
  const showSidePanel = () => setNavigation(true)
  const hideSidePanel = () => setNavigation(false)
  const toggleDrawer = () => setNavigation(!navigate)
  const { auth } = useSessionContext()
  const ionContentRef = useRef(null)

  useEffect(() => {
    if (!ionContentRef?.current || !keyboardHeight) return
    const ionContentElement = ionContentRef.current as IonContentType
    ionContentElement.scrollToPoint(0, keyboardHeight)
  }, [keyboardHeight])

  useEffect(() => {
    // @ts-ignore
    const mainParent = document.getElementById('ionContent') as CoreIonContent

    const subHeader = document.getElementById('pageSubHeader') as HTMLElement
    if (!subHeader) return
    mainParent.getScrollElement().then((scroll) => {
      scroll.addEventListener('scroll', () => {
        subHeader.setAttribute('stuck', `${scroll.scrollTop > 0}`)
      })
    })
  }, [])

  const IonContentStyled = useMemo(() => styled(IonContent)(pageClasses.ionContent(backgroundColor)), [backgroundColor])

  return (
    <>
      <Header
        showHeader={showHeader}
        header={header}
        banner={banner ?? <BannerWithContext />}
        navigate={navigate}
        toggleDrawer={toggleDrawer}
        hideBackground={hideBackground}
        hideWebToolbar={hideWebToolbar}
        hideHamburger={hideHamburger}
        growSubheader={growSubheader}
        sxHeader={sxHeader}
      />
      <IonContentStyled
        slot={auth < AuthenticationKind.AUTHENTICATED ? 'fixed' : undefined}
        no-bounce
        has-bouncing="false"
        forceOverscroll={false}
        id="ionContent"
        ref={ionContentRef}
      >
        {topContent}
        <Box
          sx={
            [
              pageClasses.content({
                paddingX,
                noPadding,
                addSafeMargin,
                hasFloatingButtons,
                removeLastMargin,
                extraBottomMargin: (isOpen ? keyboardHeight ?? 0 : 0) + extraBottomMargin,
                fullContentWidth,
              }),
              center && pageClasses.contentCenter,
              !center && growContent && pageClasses.contentGrow,
              flex && pageClasses.flex,
              footer && pageClasses.contentWithFooter,
              columnCenter && pageClasses.columnCenter,
              fitToViewport && pageClasses.fitToViewport,
              fullContentHeight && pageClasses.fullContentHeight,
            ] as SxProps<Theme>
          }
        >
          {children}
        </Box>
      </IonContentStyled>
      {footer}
      {auth === AuthenticationKind.AUTHENTICATED && !hideNativeNavigation && (
        <NavigationApp isMenuActive={navigate} showSidePanel={showSidePanel} hideSidePanel={hideSidePanel} />
      )}
    </>
  )
}

export default Page
