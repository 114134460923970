import isArray from 'lodash/fp/isArray'
import sortBy from 'lodash/fp/sortBy'
import { capitalize } from 'utils/string'

export const cleanDefaultValue = (value: string | number | undefined | null): string | number => {
  return value === 'undefined' || value === undefined || value === null ? '' : value
}

const employmentFormDataOptions: Record<string, string[]> = {
  kidsNumber: Array.apply(0, Array(11)).map((_, b) => {
    return `${String(b)}`
  }),
}

export const getSelectOptions = (keyId: string): string[] => {
  // eslint-disable-next-line fp/no-mutating-methods
  const last = keyId.split('.').pop() ?? ''
  return employmentFormDataOptions[last] ?? []
}

export const getOptsArray = (options: {
  opts: string[] | { [key: string]: string }
  sortOptions?: boolean
  capitalize?: boolean
}) => {
  const { opts, sortOptions, capitalize: toUpperCase } = options
  const optsAsArr = isArray(opts)
    ? opts.map((o) => ({ value: `${o.toLowerCase()}`, label: toUpperCase ? capitalize(o, true) : o }))
    : Object.entries(opts).map(([key, value]) => ({
        value: `${key}`,
        label: toUpperCase ? capitalize(`${value}`, true) : value,
      }))

  if (sortOptions) {
    return sortBy((o) => o.label, optsAsArr)
  }
  return optsAsArr
}
