import React from 'react'
import { Typography, Button } from '@mui/material'
import Dialog from 'components/Dialog'
import Snackbar from 'components/Snackbar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PinEntryT, PinEntryActionT } from 'pages/SecuritySetup/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { wrongPingWarning } from './styles'

export interface IWrongPinWarning {
  pinState: PinEntryT
  dispatchPin: React.Dispatch<PinEntryActionT>
}

const WrongPinWarning: React.FC<IWrongPinWarning> = ({ pinState, dispatchPin }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <>
      <Snackbar
        open={Boolean(pinState.pinInvalid && pinState.attemptsLeft)}
        setOpen={(val: boolean) => {
          dispatchPin({ type: 'setWarning', payload: { warning: val } })
          return val
        }}
        flashText={{
          title: t('component.pinLogin.flash.title'),
          message: t('component.pinLogin.flash.text', { attemptsLeft: pinState.attemptsLeft }),
        }}
        data-testid="wrong-pin-warning-message"
      />
      <Dialog
        open={pinState.pinBlocked}
        title={t('component.pinLogin.dialog.title')}
        actionSx={wrongPingWarning.dialogActions}
        actions={
          <>
            <div>
              <Button
                onClick={() => history.push(routes.pinReset, { from: { pathname: history.location.pathname } })}
                color="secondary"
                size="large"
                data-testid="pin-reset"
              >
                {t('component.pinLogin.dialog.goToSettings')}
              </Button>
            </div>
            <div>
              <Button
                onClick={() => history.push(routes.dashboard, { from: { pathname: history.location.pathname } })}
                color="secondary"
                size="large"
                data-testid="close-dialog"
              >
                {t('component.pinLogin.dialog.close')}
              </Button>
            </div>
          </>
        }
      >
        <Typography variant="body2">{t('component.pinLogin.dialog.text')}</Typography>
      </Dialog>
    </>
  )
}

export default WrongPinWarning
