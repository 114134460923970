import React from 'react'
import { Chip } from '@mui/material'
import { PlannedShiftT } from 'types'
import { chipClasses } from '../styles'

interface IAvatarShiftChipProps {
  shift: PlannedShiftT
}

const AvatarShiftChip: React.FC<IAvatarShiftChipProps> = ({ shift }) => {
  return <Chip sx={chipClasses.avatarShiftColor(shift)} />
}

export default AvatarShiftChip
