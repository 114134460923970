import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import { FF_CMS_OVERVIEW } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useStateBackLink from 'hooks/useStateBackLink'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import palette from 'utils/theme/palette'
import { styles } from '../styles'
import PageList from './PageList'

const ContentIndexPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const history = useHistory()
  const isFFActive = useFeatureFlag(FF_CMS_OVERVIEW)

  const {
    params: { ownerId },
  } = match
  const owner = parseInt(ownerId)

  const gotoBackLink = useCallback(() => {
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history])

  return (
    <Page
      backgroundColor={palette.primary[15]}
      header={
        isFFActive ? (
          <WithBackwardLink
            onClick={gotoBackLink}
            title={t('page.cms.content.header')}
            iconSx={styles.subHeaderIcon}
            iconThin
          />
        ) : (
          <Box>
            <Typography variant="h2">{t('page.cms.content.header')}</Typography>
          </Box>
        )
      }
      sxHeader={isFFActive ? styles.subHeader : {}}
    >
      <PageList ownerId={owner} />
    </Page>
  )
}

export default ContentIndexPage
