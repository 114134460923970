import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShiftsByTenantCCT } from 'types'
import EffectiveShiftPlaceholder from '../Placeholders/EffectiveShiftPlaceholder'
import ShiftCardEffectiveShift from './ShiftCardEffectiveShift'
import { classes } from './styles'

interface IShiftCardEffectiveShiftProps {
  costcenters?: ShiftsByTenantCCT[] | undefined
  effectiveShiftsError: boolean
}
const EffectiveShiftsContainer: React.FC<IShiftCardEffectiveShiftProps> = ({ costcenters, effectiveShiftsError }) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={classes.columnContainer} data-testid="shift-card__effective-shifts">
      <Box sx={classes.title}>
        <Typography variant="subtitle2">{t('page.shifts.shiftCard.effectiveTime')}</Typography>
      </Box>
      {effectiveShiftsError && (
        <Typography variant="caption" sx={classes.effectiveTimeErrorText}>
          {t('page.shifts.shiftCard.effectiveShiftsError')}
        </Typography>
      )}

      {!costcenters && <EffectiveShiftPlaceholder />}
      {!effectiveShiftsError && costcenters && (
        <>
          {costcenters.length ? (
            <Box sx={classes.effectiveList}>
              {costcenters.map((costcenter) => (
                <ShiftCardEffectiveShift costcenter={costcenter} key={costcenter.costCenterUuid} />
              ))}
            </Box>
          ) : (
            <Box sx={classes.noEffectiveTimes}>
              <Typography variant="caption">{t('page.shifts.shiftCard.noEffectiveShifts')}</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default EffectiveShiftsContainer
