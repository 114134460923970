import { APPLICATION_ENV } from 'env'

const getFavicon = (): HTMLLinkElement => {
  return document.querySelector('link[rel="shortcut icon"]') as HTMLLinkElement
}

const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

export const needToUpdateFavicon = (env: string): boolean => {
  if (env === 'LOCAL' || env === 'WIP' || env === 'STG') {
    return true
  }

  return false
}

/* User uses dark color scheme */
const setDarkModeFavicon = (favicon: HTMLLinkElement) => {
  favicon.href = '/assets/icon/favicon-dark-mode.png'
}

/* istanbul ignore next */
const updateEnvironmentFavicon = (favicon: HTMLLinkElement): void => {
  favicon.href = '/assets/icon/favicon-test.png'
}

const maybeUpdateFavicon = (favicon: HTMLLinkElement): void => {
  if (needToUpdateFavicon(APPLICATION_ENV)) {
    updateEnvironmentFavicon(favicon)
  } else if (darkMode && !needToUpdateFavicon(APPLICATION_ENV)) {
    setDarkModeFavicon(favicon)
  }
}

const favicon = getFavicon()

if (favicon) {
  maybeUpdateFavicon(favicon)
}
