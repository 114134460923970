import { useCallback, useState } from 'react'
import { SavedSideMenuFiltersT } from './components/SideMenu/types'

export const sideMenuFiltersStorageKey = 'SHIFTPLAN_SIDE_MENU_FILTERS'

const useStoredFilters = () => {
  const [storedFilters, setStoredFilters] = useState<SavedSideMenuFiltersT>(
    JSON.parse(localStorage.getItem(sideMenuFiltersStorageKey) ?? '{}') as SavedSideMenuFiltersT,
  )

  const updateStoredFilter = useCallback(
    (key: string, value: unknown) => {
      const newStoredFilters = { ...storedFilters, [key]: value }
      setStoredFilters(newStoredFilters)
      localStorage.setItem(sideMenuFiltersStorageKey, JSON.stringify(newStoredFilters))
    },
    [storedFilters],
  )

  return { storedFilters, updateStoredFilter }
}

export default useStoredFilters
