import theme from 'utils/theme'

export const userDataFormClasses = {
  container: {
    flexGrow: 1,
    padding: theme.spacing(0, 1.75),
    '& h6': {
      textAlign: 'left',
    },
  },
  containerFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  heading: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  headingSecondLine: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  headingExtraMargin: {
    marginBottom: theme.spacing(1.5),
  },
  subHeading: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(1.5),
  },
  text: {
    maxWidth: theme.spacing(20.9375),
    '&:first-of-type': {
      marginBottom: theme.spacing(1.5),
    },
  },
  button: {
    minWidth: theme.spacing(7.5),
    '&:first-of-type': {
      marginBottom: theme.spacing(1.25),
    },
  },
  callToAction: {
    marginBottom: theme.spacing(1),
  },
  commentField: {
    borderRadius: '2px',
    marginBottom: theme.spacing(1),
    '& input': {
      boxShadow: theme.shadows[1],
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  commentFieldContainer: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    marginTop: theme.spacing(2.5),
  },
}
