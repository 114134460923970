import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const ArrowLeftThinS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M10.858 13.849a.5.5 0 0 1-.707.01l-2.694-2.62-2.695-2.621A.867.867 0 0 1 4.5 8a.857.857 0 0 1 .262-.618l5.39-5.24a.5.5 0 1 1 .697.716L5.56 8l5.287 5.142a.5.5 0 0 1 .01.707Z"
      />
    </Svg>
  )
}

export default ArrowLeftThinS
