import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortTeams } from 'pages/AbsencePlanner/utils'
import { TeamT, TeamWithUserPermissions } from 'types'
import { getFullTeamLabel } from 'utils/team'
import FilterContainer from '../FilterContainer'
import FiltersCheckBox from '../FiltersCheckBox'

interface ITeamsManagerProps {
  ownerMemberTeams: TeamT[]
  ownerPublicTeams: TeamT[]
  shownTeams: string[]
  onChange: (team: TeamT, show: boolean) => void
  isMultiTenant?: boolean
}

const TeamsManager: React.FC<ITeamsManagerProps> = ({
  ownerMemberTeams,
  ownerPublicTeams,
  shownTeams,
  onChange,
  isMultiTenant,
}) => {
  const { t, language } = useFavurTranslation()
  const showPublicTeams = ownerPublicTeams && ownerPublicTeams.length > 0

  const teamsWithRights = ownerMemberTeams.filter((team) => {
    const teamWithUserPermissions = team as TeamWithUserPermissions
    return teamWithUserPermissions.userPermissions && teamWithUserPermissions.userPermissions.absenceManager
  })
  const teamsWithoutRights = [
    ...ownerMemberTeams.filter((team) => {
      const teamWithUserPermissions = team as TeamWithUserPermissions
      return !teamWithUserPermissions.userPermissions || !teamWithUserPermissions.userPermissions.absenceManager
    }),
    ...ownerPublicTeams,
  ]

  return (
    <>
      {teamsWithRights && teamsWithRights.length > 0 && (
        <FilterContainer title={t('absencePlanner.taskbar.filter.showTeamsManager')} divideBottom={!showPublicTeams}>
          {sortTeams(teamsWithRights, language).map((team) => {
            return (
              <FiltersCheckBox
                key={team.id}
                dataTestId={`team-${team.id}`}
                checked={shownTeams.includes(team.id)}
                label={getFullTeamLabel(team, language, Boolean(isMultiTenant))}
                onChange={() => {
                  onChange(team, shownTeams.includes(team.id))
                }}
              />
            )
          })}
        </FilterContainer>
      )}
      {teamsWithoutRights && teamsWithoutRights.length > 0 && (
        <FilterContainer title={t('absencePlanner.taskbar.filter.showOtherTeamsManager')}>
          {sortTeams(teamsWithoutRights, language).map((team) => {
            return (
              <FiltersCheckBox
                key={team.id}
                dataTestId={`team-${team.id}`}
                checked={shownTeams.includes(team.id)}
                label={getFullTeamLabel(team, language, Boolean(isMultiTenant))}
                onChange={() => {
                  onChange(team, shownTeams.includes(team.id))
                }}
              />
            )
          })}
        </FilterContainer>
      )}
    </>
  )
}

export default TeamsManager
