import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AlertS } from 'icons'
import { taskStates } from 'pages/Tasks/constants'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { notificationStatuses, ReminderShiftUpdateManualNotificationT } from 'types'
import palette from 'utils/theme/palette'

interface IShiftUpdateManualReminder extends NotificationPropsT {
  notification: ReminderShiftUpdateManualNotificationT
}

const ShiftUpdateManualReminder: React.FC<IShiftUpdateManualReminder> = (props) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { managerNameShort } = notification.data

  const redirectTaskShiftUpdate = useCallback(
    (taskUuid: string) => {
      const status = taskStates.todo
      return history.push(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`)
    },
    [history],
  )

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data

    return redirectTaskShiftUpdate(taskUuid)
  }, [dismiss, notification, redirectTaskShiftUpdate])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.push.headline.manual.reminder', { managerNameShort }),
    body: t('notification.new.shiftchange.manual.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default ShiftUpdateManualReminder
