import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { gridClasses } from '../styles'

const GridBodyContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { avatarBarExpanded } = useAvatarSidebarStateContext()
  return (
    <Box
      sx={[
        gridClasses.gridBodyContainer,
        ...(isSmallScreen ? [gridClasses.smallScreenGridBodyContainer(avatarBarExpanded)] : []),
      ]}
    >
      {children}
    </Box>
  )
}

export default GridBodyContainer
