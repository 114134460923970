import React, { Context, createContext, useContext } from 'react'
import { OwnerPersons } from 'hooks/useOwnersOfUser'
import useAbsencePlannerState from '../hooks/useAbsencePlannerState'

export type AbsencePlannerStateContextT = {
  isSmallScreen: boolean
  owners: OwnerPersons[] | undefined
  setOwners: (data: OwnerPersons[] | undefined) => void
  selectedOwnerId?: number | undefined
  setSelectedOwnerId: (data: number | undefined) => void
}

const initialState: AbsencePlannerStateContextT = {
  isSmallScreen: false,
  owners: undefined,
  setOwners: () => {},
  selectedOwnerId: undefined,
  setSelectedOwnerId: () => {},
}

export const AbsencePlannerStateContext: Context<AbsencePlannerStateContextT> = createContext(initialState)

export const AbsencePlannerStateProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const state = useAbsencePlannerState()

  return <AbsencePlannerStateContext.Provider value={state}>{children}</AbsencePlannerStateContext.Provider>
}

export const useAbsencePlannerStateContext = () => {
  const context = useContext(AbsencePlannerStateContext)

  return { ...context }
}

export default useAbsencePlannerStateContext
