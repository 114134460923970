import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceFullS, AlertS, RequestFullS } from 'icons'
import { AbsenceRequestConflictsT } from 'types'
import theme from 'utils/theme'
import ActionButton from './ActionButton'
import ConflictType from './ConflictType'
import ConflictsSmall from './ConflictsSmall'
import { classes } from './styles'

const getNoConflicts = (vacationsCount?: number, otherLeavesCount?: number, pendingRequestsCount?: number) =>
  !vacationsCount && !otherLeavesCount && !pendingRequestsCount

const getShowDivider = (vacationsCount?: number, otherLeavesCount?: number, pendingRequestsCount?: number) =>
  vacationsCount !== undefined &&
  vacationsCount > 0 &&
  ((otherLeavesCount !== undefined && otherLeavesCount > 0) ||
    (pendingRequestsCount !== undefined && pendingRequestsCount > 0))

interface IAbsenceRequestConflictsProps {
  conflicts: AbsenceRequestConflictsT
  small?: boolean
  onClick?: () => void
}

const AbsenceRequestConflicts: React.FC<IAbsenceRequestConflictsProps> = ({ conflicts, small = false, onClick }) => {
  const { t } = useFavurTranslation()

  const { vacationsCount, otherLeavesCount, pendingRequestsCount } = conflicts

  const noConflicts = useMemo(() => getNoConflicts(vacationsCount, otherLeavesCount, pendingRequestsCount), [
    otherLeavesCount,
    pendingRequestsCount,
    vacationsCount,
  ])

  if (noConflicts) {
    return null
  }

  if (small) {
    return <ConflictsSmall />
  }

  const showDivider = getShowDivider(vacationsCount, otherLeavesCount, pendingRequestsCount)

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.header} variant="caption">
        {t('component.absenceRequestConflicts.header.title')}
      </Typography>

      <ConflictType
        count={vacationsCount}
        icon={<AlertS fill="#FF7A00" />}
        label={
          vacationsCount === 1
            ? 'component.absenceRequestConflicts.conflict.vacations'
            : 'component.absenceRequestConflicts.conflicts.vacations'
        }
        showAvatars
        persons={conflicts.vacationsPersons}
      />

      {showDivider && <Box sx={classes.divider} />}

      <ConflictType
        count={otherLeavesCount}
        icon={<AbsenceFullS />}
        label={
          otherLeavesCount === 1
            ? 'component.absenceRequestConflicts.conflict.otherLeaves'
            : 'component.absenceRequestConflicts.conflicts.otherLeaves'
        }
      />

      <ConflictType
        count={pendingRequestsCount}
        icon={<RequestFullS fill={theme.palette.primary.main} />}
        label={
          pendingRequestsCount === 1
            ? 'component.absenceRequestConflicts.conflict.pendingRequests'
            : 'component.absenceRequestConflicts.conflicts.pendingRequests'
        }
      />

      <ActionButton onClick={onClick} />
    </Box>
  )
}

export default AbsenceRequestConflicts
