import theme from 'utils/theme'

export const classes = {
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  tileGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  rowText: {
    marginLeft: theme.spacing(0.5),
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0.125),
  },
  shiftsContainer: {
    marginTop: '-28px',
  },
  avatar: {
    position: 'relative',
  },
  subtitle: {
    marginBottom: theme.spacing(1.25),
    color: theme.palette.grey[600],
  },
  rowGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '32px',
  },
  absencePlanCta: {
    lineheight: '24px',
    gap: '4px',
    marginTop: '16px',
    width: 'fit-content',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noDataLabelMargin: {
    marginBottom: '16px',
  },
}
