import React, { JSX } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { InfoS } from 'icons'
import { IconPropsT } from 'icons/types'
import theme from 'utils/theme'
import { classes } from './styles'

interface ListItemProps {
  onClick?: () => void
  showDivider?: boolean
  subText?: string
  text: string
  // eslint-disable-next-line no-undef
  icon?: JSX.Element
  warning?: string
  alertIcon?: React.FC<IconPropsT>
  firstItem?: boolean
  checkbox?: boolean
  disabled?: boolean
}

// eslint-disable-next-line complexity
const ListItem: React.FC<ListItemProps> = ({
  onClick = () => {},
  showDivider = true,
  subText,
  text,
  icon,
  warning,
  alertIcon: AlertIcon = InfoS,
  firstItem = true,
  checkbox,
  disabled,
}) => {
  const content = (
    <>
      <Box sx={classes.content}>
        <Typography
          variant="body2"
          data-testid="list-item"
          sx={[classes.text, ...(disabled ? [classes.disabledText] : [])]}
        >
          {text}
        </Typography>
        {(subText || warning) && (
          <Box sx={[classes.subText, ...(showDivider ? [classes.subTextBottom] : [])]}>
            {subText && (
              <Typography sx={[classes.caption, ...(disabled ? [classes.disabledText] : [])]} variant="caption">
                {subText}
              </Typography>
            )}
            {warning && (
              <Box component="span" sx={[classes.warning, ...(subText ? [classes.warningLeft] : [])]}>
                <AlertIcon fill={theme.palette.secondary.main} />
                <Typography sx={classes.warningText} variant="caption">
                  {warning}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {icon}
    </>
  )

  return (
    <>
      <Box sx={[classes.listItem, ...(!firstItem ? [classes.listItemTop] : [])]}>
        {checkbox && content}
        {!checkbox && (
          <IconButton type="button" sx={classes.displayButton} onClick={onClick} disableRipple size="large">
            {content}
          </IconButton>
        )}
      </Box>
      {showDivider && <DivisionLine />}
    </>
  )
}

export default ListItem
