import theme from 'utils/theme'

export const newTutorialChipClasses = {
  newChip: {
    marginLeft: '4px',
    color: 'white !important',
    fontWeight: 600,
    fontSize: '14px !important',
    lineHeight: '22px',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    borderRadius: '6px !important',
    height: '22px !important',
    '& .MuiChip-label': {
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
}
