import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    height: 'fit-content',
  },
  innerContent: {
    display: 'flex',
    gap: '12px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    color: theme.palette.grey[600],
  },
  subtitle: {
    color: theme.palette.grey[900],
  },
}
