import React from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { classes } from '../styles'

interface SubHeaderProps {
  header?: React.ReactElement | string
  growSubheader?: boolean
  sx?: SxProps<Theme>
}

const SubHeader: React.FC<SubHeaderProps> = ({ header, growSubheader, sx }) => {
  return (
    <Box
      sx={[classes.subHeader, ...(growSubheader ? [classes.growSubheader] : []), ...(Array.isArray(sx) ? sx : [sx])]}
      id="pageSubHeader"
    >
      <Box sx={classes.subHeaderContent}>{header}</Box>
    </Box>
  )
}

export default SubHeader
