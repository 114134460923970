import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CalendarNoDataM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 2.25c.41 0 .75.34.75.75v1h4.5V3a.75.75 0 0 1 1.5 0v1h1.98c.33 0 .66.14.9.38.24.25.37.58.37.92v3.9l-1.11 1a1.5 1.5 0 0 1-.39-1v-.38l-11-.06v6.74h3.86c.45 0 .84.2 1.12.5l-1.12 1H5.27c-.33 0-.66-.14-.9-.38A1.32 1.32 0 0 1 4 15.7V5.3c0-.34.13-.67.37-.92.24-.24.57-.38.9-.38h1.98V3c0-.41.34-.75.75-.75Zm5.25 3.25V6a.75.75 0 0 0 1.5 0v-.5h1.75v1.82l-11-.06V5.5h1.75V6a.75.75 0 1 0 1.5 0v-.5h4.5Zm7.25 11a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-4 5.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm-2-6.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"
      />
    </Svg>
  )
}

export default CalendarNoDataM
