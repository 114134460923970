import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { taskBarClasses } from '../styles'
import ColorChanger from './ColorChanger'
import EntryTypes from './EntryTypes'
import Owners from './Owners'
import TeamFilter from './TeamFilter'

const Filters: React.FC<{ isAbsenceManager: boolean }> = ({ isAbsenceManager }) => {
  const { t } = useFavurTranslation()
  const { shownTypes } = useFilterContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()

  const canShowColorChanger = useMemo(() => {
    return shownTypes.includes(shiftTypes.plannedShift)
  }, [shownTypes])

  return (
    <Box sx={[taskBarClasses.filtersContainer, isSmallScreen && taskBarClasses.filtersContainerNative]}>
      <Owners />
      {!isSmallScreen && (
        <Box sx={taskBarClasses.filtersTitleContainer}>
          <Typography variant="subtitle1" sx={taskBarClasses.filtersTitle}>
            {t('absencePlanner.taskbar.filter.title')}
          </Typography>
          <DivisionLine />
        </Box>
      )}
      <EntryTypes />
      {canShowColorChanger && <ColorChanger />}
      <TeamFilter isAbsenceManager={isAbsenceManager} />
    </Box>
  )
}

export default Filters
