import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import ShiftCardAbsence from 'components/ShiftsList/components/ShiftCard/ShiftTypes/ShiftCardAbsence'
import ShiftCardPlannedShift from 'components/ShiftsList/components/ShiftCard/ShiftTypes/ShiftCardPlannedShift'
import { shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortBy } from 'lodash/fp'
import { ShiftUpdatedValues } from 'pages/Tasks/types'
import { PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import { groupShiftsByShortDescription } from 'utils/shifts'
import { classes } from './styles'

type CostCenterT = {
  name: string
  uuid: string
}

const groupShiftsByCostCenter = (shifts: PlannedShiftT[]) => {
  const shiftsByCostCenter = shifts.reduce((acc, shift) => {
    const costCenterId = shift.costCenterUuid as string

    const currentCostCenterAndShifts = !acc[costCenterId]
      ? ({ costCenter: { uuid: shift.costCenterUuid, name: shift.costCenterName }, shifts: [] } as {
          costCenter: CostCenterT
          shifts: PlannedShiftT[]
        })
      : acc[costCenterId]

    const currentShifts = sortBy('from', currentCostCenterAndShifts.shifts)
    const currentCostCenter = currentCostCenterAndShifts.costCenter
    const earliestTime = currentShifts.length > 0 ? currentShifts[0].from : shift.from

    return {
      ...acc,
      [costCenterId]: { costCenter: currentCostCenter, shifts: [...currentShifts, shift], earliestTime },
    }
  }, {} as { [key: string]: { costCenter: CostCenterT; shifts: PlannedShiftT[]; earliestTime: string | null } })

  const costCenterGroupedShifts = Object.values(shiftsByCostCenter).map((costCenterAndShifts) => {
    const groupedShifts = groupShiftsByShortDescription(costCenterAndShifts.shifts as PlannedShiftT[])
    const absences = groupedShifts.filter((shiftGroup) => shiftGroup[0].type === shiftTypes.absence)
    const rest = groupedShifts.filter((shiftGroup) => shiftGroup[0].type !== shiftTypes.absence)
    return {
      ...costCenterAndShifts,
      shifts: [...absences, ...rest],
    }
  })

  return sortBy('earliestTime', costCenterGroupedShifts)
}

interface IShiftsGroupProps {
  shifts?: PlannedShiftT[]
  title: string
  disabled?: boolean
  changeType?: ShiftUpdatedValues
}

const ShiftsGroup: React.FC<IShiftsGroupProps> = ({
  shifts,
  title,
  disabled = false,
  changeType = ShiftUpdatedValues.updated,
}) => {
  const { t } = useFavurTranslation()

  const shiftsByCostCenter = useMemo(() => {
    if (!shifts) return []
    return groupShiftsByCostCenter(shifts)
  }, [shifts])

  return (
    <Box>
      {shiftsByCostCenter.length === 0 && (
        <>
          <Typography sx={[...(disabled ? [classes.disabledText] : []), classes.deletedTitleText]} variant="subtitle2">
            {title}
          </Typography>
          <Typography variant="body2" sx={disabled ? classes.disabledText : []}>
            {t(
              changeType === ShiftUpdatedValues.deleted
                ? 'component.shifthistory.deletedshifts'
                : 'component.shifthistory.updatedagain',
            )}
          </Typography>
        </>
      )}
      {shiftsByCostCenter.map((costCenterAndShifts, i) => {
        const { shifts: costCenterShifts, costCenter } = costCenterAndShifts

        return (
          <Box
            sx={[classes.shiftContainer, ...(i === 0 ? [classes.shiftContainerTopMargin] : [])]}
            key={costCenter.uuid}
          >
            {i === 0 && (
              <Typography sx={disabled ? classes.disabledText : []} variant="subtitle2">
                {title}
              </Typography>
            )}
            <Box sx={[classes.shiftGroup, ...(i === 0 ? [classes.shiftGroupTopMargin] : [])]}>
              {costCenterShifts.map((shiftGroup) => {
                const shiftGroupFirst = shiftGroup[0] as PlannedShiftT
                if (shiftGroupFirst.type === shiftTypes.absence) {
                  return (
                    <ShiftCardAbsence shift={shiftGroupFirst} key={getShiftKey(shiftGroupFirst)} disabled={disabled} />
                  )
                }
                return (
                  <ShiftCardPlannedShift
                    shifts={shiftGroup}
                    costCenterName={costCenter.name}
                    key={getShiftKey(shiftGroupFirst)}
                    disabled={disabled}
                  />
                )
              })}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default ShiftsGroup
