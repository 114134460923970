export const classes = {
  successContainer: {
    marginTop: '104px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: '24px',
    },
  },
}
