export const classes = {
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  content: {
    padding: '16px 12px 24px 12px',
    '& ul': {
      margin: '2px 0',
      paddingLeft: '24px',
    },
  },
  title: {
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: '8px',
  },
}
