import { SxProps } from '@mui/material'
import favurTheme from 'utils/theme'

export const classes = {
  formInput: {
    marginBottom: favurTheme.spacing(2),
  },
  formInputFullWidth: {
    width: '100%',
  },
  formInputMain: {
    width: '100%',
    '& input': {
      '-moz-appearance': 'textfield' /* Firefox */,
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  formInputMainMobile: {
    width: '100%',
    '& input': {
      '-moz-appearance': 'textfield' /* Firefox */,
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      '-webkit-text-fill-color': favurTheme.palette.grey[900],
    },
    '& .MuiFormLabel-root.Mui-error': {
      '-webkit-text-fill-color': '#d0021b',
    },
    '& .MuiFormHelperText-root.Mui-disabled': {
      '-webkit-text-fill-color': favurTheme.palette.grey[900],
    },
    '& .MuiFormHelperText-root.Mui-error': {
      '-webkit-text-fill-color': '#d0021b',
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid #d0021b',
      transform: 'none',
    },
    '& .MuiInput-underline::after': {
      //@ts-ignore
      borderBottom: `2px solid ${favurTheme.palette.primary[300]}`,
      transform: 'none',
    },
    '& .MuiInput-root.Mui-disabled': {
      '-webkit-text-fill-color': favurTheme.palette.grey[900],
    },
  },
  countryCode: {
    '& input': {
      textAlign: 'right',
      minWidth: favurTheme.spacing(2),
      width: favurTheme.spacing(2),
      [favurTheme.breakpoints.down('sm')]: {
        width: favurTheme.spacing(3.5),
      },
    },
  },
}

export const iconClass: SxProps = {
  color: favurTheme.palette.success.main,
  marginRight: favurTheme.spacing(0.75),
}
