import theme from 'utils/theme'

export const classes = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '229px',
  },
  shiftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '375px',
    gap: '12px',
    marginTop: '16px',
    marginLeft: '12px',
  },
  shiftData: {
    paddingRight: '16px',
    paddingLeft: '16px',
  },
  popoverContainer: {
    width: '100%',
    minWidth: '375px',
    maxHeight: '375px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: '8px 0px 24px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  popoverRoot: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 1000,
  },
  content: {
    padding: '12px',
  },
  paperPopover: {
    boxShadow: '0px 4px 32px 0px rgba(84, 109, 153, 0.32)',
  },
}
