import theme from 'utils/theme'

export const classes = {
  confirmDialogTypography: {
    marginTop: theme.spacing(0.25),
    '&:first-of-type': {
      marginTop: theme.spacing(0.75),
    },
    marginBottom: theme.spacing(0.25),
    '&:last-of-type': {
      marginBottom: theme.spacing(0.75),
    },
  },
}
