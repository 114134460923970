import { useState } from 'react'
import { getPublicTeamsQuery } from 'shared/queries'
import { TeamT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const usePublicTeams = () => {
  const [publicTeams, setPublicTeams] = useState<TeamT[]>()

  const { loading } = useJamesApolloQuery<{ getPublicTeams: TeamT[] }>(getPublicTeamsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setPublicTeams(data.getPublicTeams)
    },
  })

  return { publicTeams, loading }
}

export default usePublicTeams
