import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const styles = {
  mobilePage: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'white',
    zIndex: 50,
    boxSizing: 'border-box',
    paddingBottom: 'calc(16px + var(--ion-safe-area-bottom))',
    paddingTop: 'calc(16px + var(--ion-safe-area-top))',
    overflowY: 'auto',
  },
  eventGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  eventDateGroup: {
    color: palette.text.secondary,
  },
  eventsGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  emptyEventContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  noEventTitle: {
    fontWeight: 700,
    size: theme.spacing(1),
    color: '#141A33',
  },
}
