/* eslint-disable fp/no-this */
import { useCallback, useMemo, useState } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isValidDate, daysIncludedInRange } from 'utils/date'
import { dateIsBetweenLastMonth } from '../utils'

export type FormFieldsT = {
  [fieldName: string]: {
    value: string | (Date | null)
    setField: (value: string | (Date | null)) => void
    validation: (field: string, value: string | (Date | null)) => string
  }
}

export type FormErrorsT = { [fieldName: string]: string }

type UseNewAbsenceRequestFormReturnT = {
  formFields: FormFieldsT
  formErrors: FormErrorsT
  validateFields: () => boolean
  setValue: (field: string, value: string | (Date | null)) => void
}

const MAXIMUM_DAY_PERIOD = 90
export const useNewAbsenceRequestForm = (): UseNewAbsenceRequestFormReturnT => {
  const { t } = useFavurTranslation()
  const [formErrors, setFormErrors] = useState<FormErrorsT>({})
  const [personUuid, setPersonUuid] = useState('')
  const [dateRange, setDateRange] = useState('singleDay')
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)
  const [comment, setComment] = useState('')
  const [absencePeriod, setAbsencePeriod] = useState('')

  const requiredValidation = useCallback(
    (field: string, value: string | (Date | null)) => {
      if (!value) {
        return t('personalData.error.requiredField')
      }
      return ''
    },
    [t],
  )

  const validDateValidation = useCallback(
    (field: string, value: Date) => {
      if (!isValidDate(value)) {
        return t('personalData.error.invalidDate')
      }
      return ''
    },
    [t],
  )

  const dateLastMonthValidation = useCallback(
    (field: string, value: Date) => {
      if (!dateIsBetweenLastMonth(value)) {
        return t('personalData.error.tooEarly')
      }
      return ''
    },
    [t],
  )

  const dateLaterValidation = useCallback(
    (field: string, value: Date) => {
      if (fromDate && value < fromDate) {
        return t('absenceRequest.error.dateToMustBeLater')
      }
      return ''
    },
    [fromDate, t],
  )

  const maxPeriodValidation = useCallback(
    (field: string, value: Date) => {
      if (fromDate && daysIncludedInRange(fromDate, value) > MAXIMUM_DAY_PERIOD) {
        return t('absenceRequest.error.maximumPeriodReached')
      }
      return ''
    },
    [fromDate, t],
  )

  const formFields: FormFieldsT = useMemo(
    () => ({
      personUuid: {
        value: personUuid,
        setField: (value) => setPersonUuid(value as string),
        validation: requiredValidation,
      },
      dateRange: {
        value: dateRange,
        setField: (value) => setDateRange(value as string),
        validation: requiredValidation,
      },
      fromDate: {
        value: fromDate,
        setField: (value) => setFromDate(value as Date),
        validation: (field, value) => {
          const requiredError = requiredValidation(field, value)
          if (requiredError) return requiredError
          const validDateError = validDateValidation(field, value as Date)
          if (validDateError) return validDateError
          return dateLastMonthValidation(field, value as Date)
        },
      },
      toDate: {
        value: toDate,
        setField: (value) => setToDate(value as Date),
        validation: (field, value) => {
          if (dateRange !== 'multipleDays') return ''
          const requiredError = requiredValidation(field, value)
          if (requiredError) return requiredError
          const validDateError = validDateValidation(field, value as Date)
          if (validDateError) return validDateError
          const dateLaterError = dateLaterValidation(field, value as Date)
          if (dateLaterError) return dateLaterError
          return maxPeriodValidation(field, value as Date)
        },
      },
      comment: {
        value: comment,
        setField: (value) => setComment(value as string),
        validation: () => '',
      },
      absencePeriod: {
        value: absencePeriod,
        setField: (value) => setAbsencePeriod(value as string),
        validation: (field, value) => (dateRange === 'multipleDays' ? '' : requiredValidation(field, value)),
      },
    }),
    [
      absencePeriod,
      comment,
      dateLastMonthValidation,
      dateLaterValidation,
      dateRange,
      fromDate,
      maxPeriodValidation,
      personUuid,
      requiredValidation,
      toDate,
      validDateValidation,
    ],
  )

  const validateFields = useCallback(() => {
    const fields = Object.entries(formFields)
    const mappedErrors = fields.map(([fieldName, field]) => ({
      fieldName,
      error: field.validation(fieldName, field.value),
    }))
    const errorObject = mappedErrors.reduce((acc, { fieldName, error }) => {
      if (error === '') return acc
      return { [fieldName]: error, ...acc }
    }, {})
    setFormErrors(errorObject)
    return mappedErrors.every((error) => error.error === '')
  }, [formFields])

  const setValue = useCallback(
    (field: string, value: string | (Date | null)) => {
      const formField = formFields[field]
      if (!formField) return
      const { setField } = formField
      setField(value)
      formField.validation(field, value)
    },
    [formFields],
  )

  return { formFields, formErrors, validateFields, setValue }
}
