import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const ProfileXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M24 6a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm-6.364 4.636a9 9 0 1 1 12.728 12.729 9 9 0 0 1-12.728-12.729ZM24 30a20.59 20.59 0 0 0-10.434 2.832 19.942 19.942 0 0 0-7.446 7.693 1 1 0 1 0 1.76.95 17.941 17.941 0 0 1 6.7-6.92A18.59 18.59 0 0 1 24 32c3.322 0 6.58.884 9.42 2.556a17.941 17.941 0 0 1 6.7 6.92 1 1 0 1 0 1.76-.951 19.942 19.942 0 0 0-7.445-7.693A20.59 20.59 0 0 0 24 30Z"
      />
    </Svg>
  )
}

export default ProfileXL
