import { gql } from '@apollo/client'

export const employeeCardDataQuery = gql`
  query employeeCardDataQuery {
    findAllPersons {
      id
      firstName
      lastName
      favurUuid
      status
      employeeNumber
      tenant {
        id
        name
      }
      user {
        id
        firstName
        lastName
        avatarUrl
      }
      costCenter {
        name
      }
    }
  }
`

export const validateEmployeeCardQuery = gql`
  query getPersonValidationStatus($favurUuid: String!) {
    getPersonValidationStatus(favurUuid: $favurUuid) {
      status
    }
  }
`
