import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      lineHeight: 1.25,
      '&$disabled': {
        color: palette.grey[300],
        opacity: 1,
      },
    },
    /* Styles applied to the root element if `disableUnderline={false}`. */
    underline: {
      '&:before': {
        borderBottom: `2px solid ${palette.primary[300]}`,
      },
      '&:hover:not(&.Mui-disabled):before': {
        borderBottom: `2px solid ${palette.primary.main}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderBottom: `2px solid ${palette.primary[300]}`,
        },
      },
      '&.Mui-disabled:before': {
        borderColor: palette.grey[200],
        borderBottomStyle: 'solid',
      },
    },
    formControl: {
      'label + &': {
        marginTop: 15,
      },
      '&.MuiInputBase-adornedEnd svg': {
        minWidth: '16px',
      },
    },
    input: {
      padding: '3px 12px 6px',
    },
    multiline: {
      padding: 0,
    },
  },
}
