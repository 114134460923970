import React from 'react'
import { Box, Paper, PaperProps } from '@mui/material'
import theme from 'utils/theme'

const classes = {
  root: {
    overflow: 'hidden',
    transition: 'height .25s ease-in-out',
    display: 'flex',
    padding: theme.spacing(2, 1),
    paddingTop: theme.spacing(1.25),
  },
  content: {
    alignSelf: 'center',
    flex: 1,
  },
}

const ResizingPaper: React.ComponentType<PaperProps> = ({ children, sx, ...props }) => {
  return (
    <Paper elevation={5} {...props} sx={[classes.root, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box sx={classes.content}>{children}</Box>
    </Paper>
  )
}

export default ResizingPaper
