import type { Theme } from '@mui/material'
import { shiftTypes } from 'constants/shift'
import type { PlannedShiftT } from 'types'
import { getManualShiftLabel } from 'utils/shifts'

export const getBadgeLabel = (shift: PlannedShiftT, mini = false): string => {
  if (shift.type === shiftTypes.absence) {
    if (mini) return ''
    return 'page.shifts.content.absence'
  }

  const label = shift.shortDescription && shift.shortDescription.trim() !== '' ? shift.shortDescription : '-'
  return getManualShiftLabel({ isManual: shift.isManual, text: label })
}

export const getBackgroundColor = (shift: PlannedShiftT, theme: Theme, isEmpty = false): string => {
  if (shift.type === shiftTypes.absence) {
    return theme.palette.grey[50]
  }

  if (shift.isManual && !isEmpty) {
    return theme.palette.common.white
  }

  return shift.hexBackgroundColor && shift.hexBackgroundColor !== ''
    ? shift.hexBackgroundColor
    : theme.palette.primary.main
}
