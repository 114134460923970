import React, { useContext } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShareUserDataFormContext } from '..'

const FillForm: React.FC = () => {
  const { t } = useFavurTranslation()
  const { setShowForm } = useContext(ShareUserDataFormContext)

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => setShowForm(true)}
      data-testid="share-user-data_fill-form"
    >
      {t('shareUserData.buttons.fillForm')}
    </Button>
  )
}

export default FillForm
