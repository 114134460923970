import { useEffect, useState } from 'react'
import { QueryStatus } from 'react-query'
import { personsWithTenantAndPermissions } from 'shared/queries'
import type { TenantNamesT, PersonT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

type TenantsAndPermissionsT = {
  tenantsAndPermissions: TenantNamesT[] | undefined
  status: QueryStatus
}

// do not use this, this is done just for compatibility reasons,
// call directly the endpoint and get an array of persons with permissions if necessary.
const useTenantsAndPermissions = (): TenantsAndPermissionsT => {
  const [status, setStatus] = useState<QueryStatus>(QueryStatus.Loading)

  const [tenantsAndPermissions, setTenantsAndPermissions] = useState<TenantNamesT[] | undefined>(undefined)

  const { data, loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsWithTenantAndPermissions, {
    fetchPolicy: 'cache-and-network',
  })

  const persons = data?.findAllPersons

  useEffect(() => {
    if (!loading && persons) {
      const madeUpObject = persons.reduce((acc: TenantNamesT[], person: PersonT) => {
        const tenantAndPermissionObject: TenantNamesT = {
          tenantName: person.tenant?.name || '',
          tenantAbsencesBlockingPeriod: person.tenant?.absencesBlockingPeriod || null,
          tenantConnectionStatus: person.tenantConnectionStatus || '',
          // @ts-ignore
          personId: person.id || '',
          favurUuid: person.favurUuid || '',
          status: person.status,
          permissions: person.permissions || {
            officeView: false,
          },
        }

        return acc.concat([tenantAndPermissionObject])
      }, [])

      setTenantsAndPermissions(madeUpObject)
      setStatus(QueryStatus.Success)
    }
  }, [loading, persons])

  return { status, tenantsAndPermissions }
}

export default useTenantsAndPermissions
