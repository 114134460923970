import { useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'
import { apiPath, getAPIURL } from 'constants/environment'
import useSessionContext from 'hooks/useSessionContext'
import { QueryConfig, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

const getReplacedQueryWithVariables = (mutationQuery: string, variables?: Record<string, unknown>) => {
  if (!variables) {
    return mutationQuery
  }

  return Object.entries(variables).reduce((query, [key, value]) => {
    // @ts-ignore
    return query.replace(`#${key}`, value)
  }, mutationQuery)
}

const mutationFn = ({ mutationQuery, variables }: { mutationQuery: string; variables?: Record<string, unknown> }) => {
  const query = `mutation {
    ${getReplacedQueryWithVariables(mutationQuery, variables)}
  }
`
  return axios.post(`${getAPIURL()}${apiPath}`, { query }, { withCredentials: true })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useJamesMutation = <T = Record<string, unknown>, R = any>(
  mutationQuery: string,
  options?: QueryConfig<AxiosResponse<{ data: R }>>,
): [(variables?: T) => Promise<R | undefined>, 'error' | 'idle' | 'loading' | 'success', unknown | null] => {
  const { refresh } = useSessionContext()
  const history = useHistory()

  const [mutate, { status, error }] = useMutation(mutationFn, {
    throwOnError: true,
    onError: (err) => {
      // @ts-ignore
      if (err?.message === 'not logged in') {
        refresh()
        history.go(0)
      }
    },
    ...options,
  })

  const execute = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (variables: any) => {
      const response = await mutate({ mutationQuery, variables })
      return response?.data?.data
    },
    [mutate, mutationQuery],
  )

  return [execute, status, error]
}

export default useJamesMutation
