import React, { useEffect } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import { tutorialTypes } from 'constants/tutorials'
import useRolesViews from 'hooks/useRolesViews'
import useStateBackLink from 'hooks/useStateBackLink'
import useTutorials from 'hooks/useTutorials'
import PinLogin from 'pages/PinLogin'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import NewDetailsMontlhySheet from './Details'

const MonthlySheets: React.FC<RouteComponentProps<{ taskUuid: string }>> = ({ match }) => {
  const history = useHistory()
  const { activeView } = useRolesViews()
  const isOffice = activeView === ROLE_VIEWS.office
  const { beenShownBefore, isReady } = useTutorials()
  const { setBackLink } = useStateBackLink()

  const {
    params: { taskUuid },
  } = match

  useEffect(() => {
    if (!isReady) return

    if (isOffice) {
      if (beenShownBefore('workTimeControlManager') === undefined || beenShownBefore('workTimeControlManager')) {
        return
      }
      setBackLink(routes.monthlySheetsWithTask(taskUuid))
      history.push(`${routes.tutorial}/${tutorialTypes.workTimeControlManager}`)
    } else {
      if (beenShownBefore('workTimeControlFrontliner') === undefined || beenShownBefore('workTimeControlFrontliner')) {
        return
      }
      setBackLink(routes.monthlySheetsWithTask(taskUuid))
      history.push(`${routes.tutorial}/${tutorialTypes.workTimeControlFrontliner}`)
    }
  }, [history, isReady, beenShownBefore, isOffice, setBackLink, taskUuid])

  return (
    <PinLogin>
      <NewDetailsMontlhySheet taskUuid={taskUuid} />
    </PinLogin>
  )
}

export default MonthlySheets
