import React, { memo, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { CELL_HEIGHT } from '../constants'
import { absencePlannerBodyClasses as classes } from './styles'

interface IVirtualizedContainerProps {
  children: React.ReactNode
  horizontalPreloadMargin?: number
  verticalPreloadMargin?: number
  placeholderHeight?: string
  placeholderWidth: string
  rootElement?: Element | Document | null
  isLastRow?: boolean
}

const VirtualizedContainer: React.FC<IVirtualizedContainerProps> = ({
  children,
  horizontalPreloadMargin = 1000,
  verticalPreloadMargin = 1000,
  placeholderHeight = `${CELL_HEIGHT}px`,
  placeholderWidth,
  rootElement,
  isLastRow = false,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const rowRef = useRef<HTMLDivElement>(null)

  // Set visibility with intersection observer
  useEffect(() => {
    const ref = rowRef.current
    if (!rowRef.current) {
      return () => {}
    }
    const observer = new IntersectionObserver(
      (entries) => {
        setIsVisible(entries.some((entry) => entry.isIntersecting))
      },
      {
        root: rootElement,
        rootMargin: `${verticalPreloadMargin}px ${horizontalPreloadMargin}px ${verticalPreloadMargin}px ${horizontalPreloadMargin}px`,
      },
    )
    observer.observe(rowRef.current)
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [horizontalPreloadMargin, rootElement, rowRef, verticalPreloadMargin])

  return (
    <Box ref={rowRef} sx={[classes.virtualizedContentContainer, ...(isLastRow ? [classes.lastRow] : [])]}>
      {!isVisible ? <Box height={placeholderHeight} width={placeholderWidth} /> : children}
    </Box>
  )
}

export default memo(VirtualizedContainer)
