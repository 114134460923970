import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import SectionTitle from 'components/SectionTitle'
import { defaultSuccessPageTimeout } from 'components/SuccessGeneric'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import routes from 'services/RoutesProvider/routes'
import { detailConnectionsToDelete } from 'shared/queries'
import { PersonT } from 'types'
import { useMutation } from '@apollo/client'
import { startDeletePersonMutation } from '../mutations'
import DeleteUser from './DeleteUser'
import DetailedEmployersElement from './DetailedEmployersElement'
import SuccessDeleteConnection from './SuccessDeleteConnection'
import { classes } from './styles'

const DELETE_REASON_MIN_LENGTH = 10

type IStartDeletePersonResponse = {
  startDeletePerson: { success: boolean }
}

type IDeleteConnectionInfo = {
  favurUuid: null | string
  tenantName: null | string
}

const ConfirmDeactivateAccount: React.FC = () => {
  const { t } = useFavurTranslation()
  const [mutate] = useMutation<IStartDeletePersonResponse>(startDeletePersonMutation)
  const [showSuccessTenantName, setShowSuccessTenantName] = useState<string | null>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [deleteConnectionInfo, setDeleteConnecctionInfo] = useState<IDeleteConnectionInfo>({
    favurUuid: null,
    tenantName: null,
  })

  const { data, refetch: refetchConnections, loading: loadingConnections } = useJamesApolloQuery<{
    connections: { persons: PersonT[] }
  }>(detailConnectionsToDelete, {
    fetchPolicy: 'cache-and-network',
  })

  const onDeleteConnection = async (favurUuid: string, tenantName: string, reason: string) => {
    const mutationResult = await mutate({ variables: { favurUuid: favurUuid, reason } })
    if (mutationResult?.data?.startDeletePerson?.success) {
      setShowSuccessTenantName(tenantName)
      refetchConnections()
    }
  }

  const openReasonDrawer = (favurUuid: string, tenantName: string) => {
    setDeleteConnecctionInfo({ favurUuid, tenantName })
    setOpenDrawer(true)
  }

  useEffect(() => {
    if (!showSuccessTenantName) return undefined
    const successTimeout = setTimeout(() => {
      setShowSuccessTenantName(null)
    }, defaultSuccessPageTimeout)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccessTenantName])

  if (showSuccessTenantName) {
    return <SuccessDeleteConnection tenantName={showSuccessTenantName} />
  }

  return (
    <Page
      growContent={false}
      header={
        <WithBackwardLink
          route={routes.profileDeactivateAccount}
          title={t('page.userSettings.profile.deactivateAccount')}
          topTitle={t('page.userSettings.profile.manageUserAccount')}
        />
      }
    >
      <DeleteUser allowDelete={!loadingConnections && data?.connections.persons?.length === 0} />

      <Box sx={classes.employersSection}>
        <SectionTitle marginLeft={1.0}>{t('label.deleteConections')}</SectionTitle>
      </Box>

      {loadingConnections ? (
        <BoxedLoading />
      ) : (
        data?.connections.persons?.map((person) => (
          <DetailedEmployersElement person={person} key={person.id} onDelete={openReasonDrawer} />
        ))
      )}

      <TextInputDrawer
        isOpen={openDrawer}
        onCancel={() => {
          setOpenDrawer(false)
        }}
        onSuccess={(reason) => {
          setOpenDrawer(false)
          if (deleteConnectionInfo.favurUuid && deleteConnectionInfo.tenantName) {
            onDeleteConnection(deleteConnectionInfo.favurUuid, deleteConnectionInfo.tenantName, reason)
          }
        }}
        successButtonText={t('deleteConnection.drawer.sendExplanation.successButton')}
        textFieldPlaceHolder={t('deleteConnection.drawer.sendExplanation.inputPlaceholder')}
        testIdName="confirm_person_deletion"
        required
        textFieldMinLength={DELETE_REASON_MIN_LENGTH}
      >
        <Box sx={classes.commentHeaderContainer}>
          <Typography variant="body2" sx={classes.commentTextBody}>
            {t('deleteConnection.drawer.sendExplanation.title')}
          </Typography>
        </Box>
      </TextInputDrawer>
    </Page>
  )
}

export default ConfirmDeactivateAccount
