import React from 'react'
import { Box, InputAdornment } from '@mui/material'
import { InfoS } from 'icons'
import theme from 'utils/theme'
import { classes } from './styles'

export interface IInfoAdornmentProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  type: 'input' | 'title'
  insideSelect?: boolean
}

const InfoAdornment: React.FC<IInfoAdornmentProps> = ({ open, setOpen, type, insideSelect }) => {
  const onClick = () => setOpen(!open)

  return (
    <>
      {type === 'input' ? (
        <InputAdornment
          position="start"
          onClick={onClick}
          sx={[...(insideSelect ? [classes.selectAdornment] : []), classes.clickable]}
        >
          <InfoS fill={theme.palette.primary.main} />
        </InputAdornment>
      ) : (
        <Box role="button" onClick={onClick} tabIndex={0} sx={classes.clickable}>
          <InfoS fill={theme.palette.primary.main} sx={classes.titleAdornment} />
        </Box>
      )}
    </>
  )
}

export default InfoAdornment
