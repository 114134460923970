import theme from 'utils/theme'

export const classes = {
  root: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  date: {
    color: theme.palette.grey[600],
    letterSpacing: 0.3,
  },
  newDot: {
    minHeight: '8px',
    width: '8px',
    height: '8px',
    background: theme.palette.secondary.main,
    borderRadius: '50%',
  },
  dotContainer: {
    minWidth: '8px',
    width: '8px',
  },
  titleContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    height: '24px',
  },
  body: {
    color: theme.palette.grey[600],
    lineHeight: '22px',
  },
  title: {
    lineHeight: '22px',
  },
}
