import React, { useCallback, useMemo } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { DocumentNotificationT, NotificationT } from 'types'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import NotificationTemplate from '../Templates/Notification'

interface IDocumentNotification {
  dismiss: (notification: NotificationT) => void
  notification: DocumentNotificationT
}

const MonthlySheetNotification: React.FC<IDocumentNotification> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { notification, dismiss } = props

  const handleRedirect = useCallback(() => {
    const { taskUuid } = notification.data
    dismiss(notification)
    if (taskUuid) {
      pushWithRole(routes.monthlySheetsWithTask(taskUuid), ROLE_VIEWS.frontliner)
    }
  }, [dismiss, notification, pushWithRole])

  const notificationTitle = useMemo(() => {
    if (notification.data.dateTo !== '' && notification.data.dateFrom !== '') {
      return t('notification.new.worktimecontrol.title')
    }

    return t('notification.new.worktimecontrol.reminder.title')
  }, [notification.data.dateFrom, notification.data.dateTo, t])

  const notificationBody = useMemo(() => {
    if (notification.data.dateTo !== '' && notification.data.dateFrom !== '') {
      const textVariables = {
        dateTo: getFormattedFromISOString(notification.data.dateTo, uiDateFormat, undefined),
        dateFrom: getFormattedFromISOString(notification.data.dateFrom, uiDateFormat, undefined),
      }

      return t('notification.new.worktimecontrol.body', textVariables)
    }

    return t('notification.new.worktimecontrol.reminder.body')
  }, [notification.data.dateFrom, notification.data.dateTo, t])

  const templateProps = {
    ...props,
    title: notificationTitle,
    body: notificationBody,
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t('component.notification.button.verify'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default MonthlySheetNotification
