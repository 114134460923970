import theme from 'utils/theme'
import { AVATAR_MENU_CLOSED_WIDTH, AVATAR_MENU_OPEN_WIDTH } from '../constants'

export const topBoxBlockClasses = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 40,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      top: '48px',
    },
  },
  blockContainer: (avatarBarExpanded: boolean) => ({
    width: `${avatarBarExpanded ? AVATAR_MENU_OPEN_WIDTH : AVATAR_MENU_CLOSED_WIDTH}px`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}
