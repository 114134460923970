import { useEffect, useState } from 'react'
import { TeamplanCostCenterT } from 'components/ShiftsList/components/types'
import usePublicTeams from 'hooks/usePublicTeams'
import useTeams from 'hooks/useTeams'
import { TeamT } from 'types'
import useStoredFilters from './useStoredFilters'

const useSideMenuData = () => {
  const [publicTeams, setPublicTeams] = useState<TeamT[]>([])
  const [costCenters, setCostCenters] = useState<TeamplanCostCenterT[]>([])

  const [selectedTeams, setSelectedTeams] = useState<string[]>([])
  const [selectedPublicTeams, setSelectedPublicTeams] = useState<string[]>([])

  const { teams, loading: teamsLoading, isMultiTenant } = useTeams()
  const { publicTeams: loadedPublicTeams, loading: publicTeamsLoading } = usePublicTeams()
  const { storedFilters } = useStoredFilters()

  const [showTopCalendarShifts, setShowTopCalendarShifts] = useState<boolean>(
    storedFilters.showTopCalendarShifts ?? false,
  )

  useEffect(() => {
    if (!teams || teamsLoading || !loadedPublicTeams || publicTeamsLoading) {
      return
    }

    const newTeamsIds = teams?.map((team) => team.id) ?? []

    const newSelectedTeams: string[] | undefined = (() => {
      if (storedFilters.selectedTeams) {
        if (storedFilters.selectedTeams.every((teamId) => newTeamsIds.includes(teamId))) {
          return storedFilters.selectedTeams
        }
      }

      return teams.length > 0 ? newTeamsIds : undefined
    })()

    setSelectedTeams(newSelectedTeams ?? [])
    const newPublicTeams = (loadedPublicTeams ?? []).filter((team) => !newTeamsIds.includes(team.id))
    setPublicTeams(newPublicTeams)

    setSelectedPublicTeams(storedFilters.selectedPublicTeams ?? [])
  }, [
    loadedPublicTeams,
    publicTeamsLoading,
    storedFilters.selectedPublicTeams,
    storedFilters.selectedTeams,
    teams,
    teamsLoading,
  ])

  return {
    teams: teams ?? [],
    publicTeams: publicTeams ?? [],
    selectedTeams,
    setSelectedTeams,
    selectedPublicTeams,
    setSelectedPublicTeams,
    showTopCalendarShifts,
    setShowTopCalendarShifts,
    isMultiTenant,
    costCenters,
    setCostCenters,
    loading: teamsLoading || publicTeamsLoading,
  }
}

export default useSideMenuData
