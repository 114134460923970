import React, { memo, useMemo } from 'react'
import { Box } from '@mui/material'
import { addMonths, eachDayOfInterval, endOfMonth, startOfMonth } from 'date-fns'
import { getFormattedFromDate } from 'utils/date'
import MonthlyBarDay from './MonthlyBarDay'
import { monthlyBarClasses } from './styles'

interface IMonthlyBarProps {
  baseDate: Date
  index: number
  selectedTaskUuid: string | null
  selectedDays: Date[]
}

const MonthlyBar: React.FC<IMonthlyBarProps> = ({ baseDate, index, selectedDays, selectedTaskUuid }) => {
  const monthDates = useMemo(() => {
    const integerIndex = Math.round(index)
    const start = startOfMonth(addMonths(baseDate, integerIndex))
    const end = endOfMonth(start)
    return eachDayOfInterval({ start, end })
  }, [baseDate, index])

  return (
    <Box sx={[monthlyBarClasses.container]}>
      {monthDates.map((date) => (
        <MonthlyBarDay
          date={date}
          selectedTaskUuid={selectedTaskUuid}
          selectedDays={selectedDays}
          key={getFormattedFromDate(date)}
        />
      ))}
    </Box>
  )
}

export default memo(MonthlyBar)
