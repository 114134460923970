import React from 'react'
import { Box, Typography } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FallbackProps } from 'react-error-boundary'
import routes from 'services/RoutesProvider/routes'
import CollapsibleErrorSection from './CollapsibleErrorSection'
import { classes } from './styles'

const ErrorPage: React.FC<FallbackProps> = ({ error }) => {
  const { t } = useFavurTranslation()

  return (
    <Page hideNativeNavigation flex showHeader>
      <Box sx={classes.content}>
        <img src="/assets/images/ErrorPage/AppCrash.png" alt="Favur app crash" />
        <Typography variant="h1" sx={classes.title}>
          {t('errorPage.title')}
        </Typography>
        <Typography variant="body1" sx={[classes.text, classes.topText]}>
          {t('errorPage.topText')}
        </Typography>
        <Typography variant="body1" sx={classes.text}>
          {t('errorPage.bottomText')}
        </Typography>
        <CollapsibleErrorSection error={error} />

        <Box component="a" href={routes.dashboard} sx={classes.button}>
          {t('errorPage.button')}
        </Box>
      </Box>
    </Page>
  )
}

export default ErrorPage
