import { useCallback, useContext, useEffect, useState } from 'react'
import FlashMessagesContext from './context'

const useTemporayFlashMessage = (temporaryTimeInSeconds = 5) => {
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      removeAll()
    }, temporaryTimeInSeconds * 1000)
    return () => {
      setShowMessage(false)
      clearTimeout(timer)
    }
  }, [removeAll, showMessage, temporaryTimeInSeconds])

  const showFlashMessage = useCallback(
    (message: string) => {
      setFlashMessage(message)
      setShowMessage(true)
    },
    [setFlashMessage],
  )

  return { showFlashMessage }
}

export default useTemporayFlashMessage
