import { balanceQuery, estimationsQuery } from 'shared/queries'
import { newDateWithoutTime } from 'utils/date'

export const getPastBalance = (endDate: string) => {
  return `query{
    balances(endDate: "${endDate}"){
      ${balanceQuery}
    }
  }`
}

export const getBalanceWithEstimationsQuery = () => {
  const estimateFromDay = newDateWithoutTime()
  return `query{
    improvedBalances(estimateFromDay: "${estimateFromDay.toISOString()}"){
      ${balanceQuery}
      ${estimationsQuery}
    }
  }`
}
