import React from 'react'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { LinkContentBlockT } from 'types'
import { linkBlockStyles } from '../styles'

interface ILinkBlockProps {
  data: LinkContentBlockT
}

const LinkBlock: React.FC<ILinkBlockProps> = ({ data }) => {
  const history = useHistory()

  return (
    <Button
      variant={data.variant}
      color="primary"
      onClick={() => {
        history.push(data.route)
      }}
      sx={[linkBlockStyles.button, ...(data.variant === 'contained' ? [linkBlockStyles.containedButton] : [])]}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      {data.text}
    </Button>
  )
}

export default LinkBlock
