import theme from 'utils/theme'

export const classes = {
  paper: {
    height: 'calc(100% - 30px - var(--ion-safe-area-top))',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '24px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  textBody: {
    color: theme.palette.grey[600],
  },
  innerDeleteButton: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  moreInfoButton: {
    display: 'flex',
    gap: '4px',
    width: 'fit-content',
  },
}
