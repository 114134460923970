import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const SideMenuL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        style={{ transform: 'translate(10px, 0)' }}
        d="M16 2a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM8 2a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM0 2a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      />
    </Svg>
  )
}

export default SideMenuL
