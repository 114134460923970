import React, { useMemo, useState } from 'react'
import { Button } from '@mui/material'
import { DateView, MobileDatePicker } from '@mui/x-date-pickers'
import { format, Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowDownS } from 'icons'
import { uiDateFormat } from 'utils/constants'
import { getDateFormatByLocale, getShortMonthFormat } from 'utils/date'
import { capitalize } from 'utils/string'
import theme from 'utils/theme'
import { datePickerClasses } from '../styles'

const getFormattedDatePickerDate = (date: Date, locale: Locale) => {
  const formatString = getDateFormatByLocale(locale, { withYear: false, withDayOfWeek: true })
  const formattedString = format(date, formatString, { locale })

  return capitalize(formattedString)
}

interface ITeamplanDatePickerProps {
  value: Date
  onChange: (date: Date) => void
  onAccept: (date: Date) => void
  variant?: 'month' | 'day'
  dataTestId?: string
}

const TeamplanDatePicker: React.FC<ITeamplanDatePickerProps> = ({
  value,
  onChange,
  onAccept,
  variant = 'day',
  dataTestId,
}) => {
  const { locale } = useFavurTranslation()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const { views, formattedDate } = useMemo((): { views: DateView[]; formattedDate: string } => {
    if (variant === 'day') {
      return { views: ['year', 'month', 'day'], formattedDate: getFormattedDatePickerDate(value, locale as Locale) }
    }
    return {
      views: ['year', 'month'],
      formattedDate: format(value, getShortMonthFormat(locale as Locale), { locale }),
    }
  }, [locale, value, variant])

  return (
    <MobileDatePicker
      slots={{
        field: () => (
          <Button
            variant="text"
            sx={datePickerClasses.datePickerButton}
            onClick={() => setOpenDatePicker(true)}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            data-testid={dataTestId}
          >
            <span>{formattedDate}</span>
            <ArrowDownS fill={theme.palette.secondary.main} />
          </Button>
        ),
      }}
      open={openDatePicker}
      format={uiDateFormat}
      value={value}
      onChange={(date) => date && onChange(date)}
      onAccept={(date) => date && onAccept(date)}
      onOpen={() => setOpenDatePicker(true)}
      onClose={() => setOpenDatePicker(false)}
      slotProps={{
        toolbar: {
          hidden: true,
        },

        dialog: {
          sx: { ...datePickerClasses.monthSwitcherIcons, ...datePickerClasses.baseDialog },
        },
      }}
      views={views}
    />
  )
}

export default TeamplanDatePicker
