import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const LanguageSwitcherM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12 21c-1.178 0-2.197-2.036-2.688-4.999h5.376C14.197 18.964 13.178 21 12 21zm8.064-4.999c-1.065 2.142-2.959 3.8-5.261 4.554.668-1.14 1.144-2.716 1.419-4.554h3.842zm-11.04 4.245l.17.308c-2.301-.754-4.193-2.412-5.258-4.553H7.78c.25 1.679.667 3.137 1.244 4.245zm-1.522-8.567v.653c.009.745.043 1.47.103 2.169H3.352C3.122 13.707 3 12.868 3 12c0-.867.123-1.706.352-2.5h4.251c-.06.702-.094 1.43-.101 2.18zM15 12c0 .868-.04 1.707-.117 2.5H9.117c-.067-.694-.106-1.424-.115-2.177L9 12c0-.867.04-1.706.117-2.5h5.766c.076.794.117 1.633.117 2.5zm5.648-2.5c.23.794.352 1.633.352 2.5 0 .868-.123 1.707-.352 2.5h-4.251c.068-.801.103-1.639.103-2.5s-.035-1.698-.103-2.5h4.251zM7.778 8H3.936C5 5.857 6.894 4.199 9.197 3.445c-.668 1.14-1.144 2.717-1.42 4.556zM12 3c1.178 0 2.197 2.037 2.688 5H9.312c.476-2.875 1.45-4.877 2.583-4.995L12 3zm2.803.445C17.106 4.199 19 5.857 20.064 8h-3.842c-.263-1.757-.71-3.275-1.332-4.402l-.087-.153z"
      />
    </Svg>
  )
}

export default LanguageSwitcherM
