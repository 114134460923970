/* eslint-disable no-console */
import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AbsentTab from './Tabs/Absent'
import AtendantTab from './Tabs/Atendant'
import { classes } from './styles'
import { TeamplanDetailT } from './types'

interface ITeamplanDetailPageContentProps {
  teamplanDetail: TeamplanDetailT
  hideCostCenter: boolean
}

const TeamplanDetailPageContent: React.FC<ITeamplanDetailPageContentProps> = ({ teamplanDetail, hideCostCenter }) => {
  const { t } = useFavurTranslation()
  const { attendances, vacationAbsences, otherAbsences } = teamplanDetail

  return (
    <Box sx={classes.contentContainer}>
      <Typography variant="h3" sx={classes.sectionTitle}>
        {t('component.teamplanDetailPage.tab.attendant')}
      </Typography>
      <AtendantTab attendances={attendances} hideCostCenter={hideCostCenter} />
      <Typography variant="h3" sx={classes.sectionTitle}>
        {t('component.teamplanDetailPage.tab.absent')}
      </Typography>
      <AbsentTab vacationAbsences={vacationAbsences} otherAbsences={otherAbsences} />
    </Box>
  )
}

export default TeamplanDetailPageContent
