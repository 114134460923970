export const classes = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: '8px 0px calc(24px + var(--ion-safe-area-bottom))',
    overflow: 'hidden',
  },
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  paperNative: {
    maxWidth: '600px',
  },
  iconButtonClose: {
    marginRight: '16px',
    alignSelf: 'flex-end',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
    },
  },
}
