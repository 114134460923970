import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AlertS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { notificationStatuses, ReminderShareUserDataManualNotificationT } from 'types'
import palette from 'utils/theme/palette'

interface IShareUserDataManualReminder extends NotificationPropsT {
  notification: ReminderShareUserDataManualNotificationT
}

const ShareUserDataManualReminder: React.FC<IShareUserDataManualReminder> = (props) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { managerNameShort } = notification.data

  const redirectTaskShareUserData = useCallback(
    (taskUuid: string) => {
      return history.push(`${routes.userDataDetails}/${taskUuid}`)
    },
    [history],
  )

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data

    return redirectTaskShareUserData(taskUuid)
  }, [dismiss, notification, redirectTaskShareUserData])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.push.headline.manual.reminder', { managerNameShort }),
    body: t('notification.sharedata.requested.manual.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default ShareUserDataManualReminder
