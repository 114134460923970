import React, { useMemo } from 'react'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import AcceptedAbsenceRequest from './components/AcceptedAbsenceRequest'
import PendingAbsenceRequest from './components/PendingAbsenceRequest'

interface IAbsenceRequestDataProps {
  absenceRequests: TaskT<AbsenceRequestTaskT>[]
  date?: string
  onClickLink?: (task: TaskT<AbsenceRequestTaskT>) => void
}

const AbsenceRequestData: React.FC<IAbsenceRequestDataProps> = ({ absenceRequests, date, onClickLink }) => {
  const pendingAbsenceRequests = useMemo(
    () =>
      absenceRequests.filter((request) =>
        [absenceRequestStatus.reviewOfficeSeen, absenceRequestStatus.reviewOfficeUnseen].includes(request.status),
      ),
    [absenceRequests],
  )

  const acceptedAbsenceRequests = useMemo(
    () =>
      absenceRequests.filter((request) =>
        [absenceRequestStatus.approvedOffice, absenceRequestStatus.rejectedDeletionRequest].includes(request.status),
      ),
    [absenceRequests],
  )

  return (
    <>
      {pendingAbsenceRequests.map((absenceRequest) => (
        <PendingAbsenceRequest
          date={date as string}
          absenceRequest={absenceRequest}
          key={`absence-request-${absenceRequest.favurUuid}`}
          onClick={onClickLink}
        />
      ))}
      {acceptedAbsenceRequests.map((absenceRequest) => (
        <AcceptedAbsenceRequest
          date={date as string}
          absenceRequest={absenceRequest}
          key={`absence-request-${absenceRequest.favurUuid}`}
          onClick={onClickLink}
        />
      ))}
    </>
  )
}

export default AbsenceRequestData
