import { gql } from '@apollo/client'

export const personalDataQuery = gql`
  query personalData {
    me {
      user {
        firstName
        lastName
        birthDate
        phoneNumber
      }
    }
  }
`
