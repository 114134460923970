import React from 'react'
import useFavurNavigation from 'hooks/useFavurNavigation'
import NavigationGroup from '../NavigationGroup'

interface PrimaryNavigationProps {
  toggleDrawer: () => void
}

const PrimaryNavigation: React.FC<PrimaryNavigationProps> = ({ toggleDrawer }) => {
  const { primaryNavigation: navPoints } = useFavurNavigation()

  return <NavigationGroup navPoints={navPoints} toggleDrawer={toggleDrawer} />
}

export default PrimaryNavigation
