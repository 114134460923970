import React from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import { PIN_STATUSES } from 'constants/highSecurityConnection'
import usePinStatus from 'hooks/usePinStatus'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { setupSteps, pinExistSteps } from '../constants'
import { InitialSetupStepName } from '../types'
import SecurityProcess from './SecurityProcess'

const SecuritySetup: React.FC<RouteComponentProps<{ step?: InitialSetupStepName }>> = ({ match }) => {
  const { step: urlStep } = match.params
  const history = useHistory()
  const [pinStatusData, statusP] = usePinStatus({ queryName: 'pin_status_security_setup' })

  if (statusP === 'loading') {
    return (
      <Page hideNativeNavigation hideBackground columnCenter>
        <SimpleLoading />
      </Page>
    )
  }

  if ((statusP === 'error' || statusP === 'success') && !pinStatusData) {
    history.push(routes.dashboard)
    return null
  }

  const pinStatus = pinStatusData?.pinStatus || ''
  const getSteps = () => {
    if (pinStatus === PIN_STATUSES.notSet) {
      return setupSteps
    }
    if (pinStatus === PIN_STATUSES.set) {
      return pinExistSteps
    }

    return setupSteps
  }

  return <SecurityProcess steps={getSteps()} urlStep={urlStep} />
}

export default SecuritySetup
