import theme from 'utils/theme'
import { diagonal1, diagonal2, side1, side2, top1, top2 } from './keyframes'

export const DELAY_ANIMATION_MS = 150
export const DURATION_ANIMATION_S = 2

export const loadingClasses = {
  imageContainer: {
    height: '186px',
    margin: '0 auto',
  },
  box: {
    marginBottom: theme.spacing(0.5),
  },
  big: {
    width: '60px',
    height: '60px',
  },
  small: {
    width: '44px',
    height: '44px',
  },
  triangleContainer: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '10px',
    background: 'transparent',
    '& [data-name="Top1"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running  ${top1}`,
      width: '5px',
      opacity: 1,
    },
    '& [data-name="Side1"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running ${side1}`,
      width: '5px',
      opacity: 0,
    },
    '& [data-name="Diagonal1"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running ${diagonal1}`,
      width: '5px',
      opacity: 0,
    },
    '& [data-name="Top2"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running ${top2}`,
      width: '58px',
      opacity: 0,
    },
    '& [data-name="Side2"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running ${side2}`,
      width: '58px',
      opacity: 0,
    },
    '& [data-name="Diagonal2"]': {
      animation: `${DURATION_ANIMATION_S}s linear ${DELAY_ANIMATION_MS}ms infinite normal forwards running ${diagonal2}`,
      width: '79.88px',
      opacity: 0,
    },
    '& [data-name="svgContainer"]': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
  bigTriangleContainer: {
    height: '70px',
  },
  hideAnimation: {
    opacity: 0,
  },
}
