import React, { useState, useCallback } from 'react'
import pick from 'lodash/fp/pick'
import DialogContext from './context'
import { IDialogInstance, DialogPathsT } from './types'

const DialogsService = ({ children }: { children?: React.ReactNode }) => {
  const [dialogs, setDialogs] = useState<IDialogInstance | undefined>()
  const addDialog = useCallback(
    (dialog: IDialogInstance | string) => {
      const normalised = typeof dialog === 'string' ? { [dialog]: { isOpen: true } } : dialog
      setDialogs({ ...dialogs, ...normalised })
    },
    [dialogs],
  )

  // https://github.com/lodash/lodash/issues/2930
  // not using omit
  const removeDialog = useCallback(
    (dialogPath: string) => {
      setDialogs(
        pick(
          Object.keys(dialogs || {}).filter((v) => v !== dialogPath),
          dialogs || {},
        ),
      )
    },
    [dialogs],
  )

  const openDialog = useCallback(
    (dialogPath: DialogPathsT) => {
      setDialogs({
        ...dialogs,
        [dialogPath]: {
          isOpen: true,
        },
      })
    },
    [dialogs],
  )

  const closeDialog = useCallback(
    (dialogPath: DialogPathsT) => {
      setDialogs({
        ...dialogs,
        [dialogPath]: {
          isOpen: false,
        },
      })
    },
    [dialogs],
  )

  const isOpen = useCallback((dialogPath: DialogPathsT) => dialogs?.[dialogPath]?.isOpen || false, [dialogs])

  return (
    <DialogContext.Provider value={{ dialogs, addDialog, removeDialog, openDialog, closeDialog, isOpen }}>
      {children}
    </DialogContext.Provider>
  )
}

export default DialogsService
