export const classes = {
  searchTextField: {
    borderRadius: '2px',
    backgroundColor: 'white',
    marginBottom: '26px',
    height: '36px',
    '& .MuiInputBase-root': {
      marginTop: '0px',
    },
    '& > label + .MuiInput-formControl': {
      marginTop: '4px',
    },
    '& label': {
      position: 'absolute',
      top: '-10px',
    },
    '& > .MuiInputBase-adornedEnd': {
      height: '36px',
    },
    '& input': {
      position: 'relative',
      top: '2px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiInput-underline:before': {
      borderRadius: '2px',
    },
  },
  icon: {
    position: 'relative',
    right: '5px',
  },
  iconClose: {
    position: 'relative',
    bottom: '0px',
  },
  iconSearch: {
    position: 'relative',
    top: '5px',
  },
}
