import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getRelatedTutorials, tutorialNames } from 'constants/tutorials'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Page from '../../components/Page'
import useFavurTranslation from '../../hooks/useFavurTranslation'
import useStateBackLink from '../../hooks/useStateBackLink'
import useTutorials from '../../hooks/useTutorials'
import useUsername from '../../hooks/useUsername'
import TutorialAbsenceRequestFrontliner from './AbsenceRequestFrontliner'
import TutorialAbsenceRequestManager from './AbsenceRequestManager'
import TutorialAbsenceplanFrontliner from './AbsenceplanFrontliner'
import TutorialAbsenceplanManager from './AbsenceplanManager'
import Resignation from './Resignation'
import TutorialSecureConnection from './SecureConnection'
import TutorialShiftplan from './Shiftplan'
import TutorialWorkTimeControlFrontliner from './WorkTimeControlFrontliner'
import TutorialWorkTimeControlManager from './WorkTimeControlManager'

const Tutorial: React.FC<RouteComponentProps<{ tutorialName: string }>> = ({ match, location }) => {
  const queryParams = new URLSearchParams(location.search)
  const taskId = queryParams.get('taskUuid') ?? undefined
  const { tutorialName: tutorialNameParam } = match.params
  const [tutorialName, ...relatedTutorials] = getRelatedTutorials(tutorialNameParam)

  const [step, setStep] = useState(0)

  const { t } = useFavurTranslation()
  const history = useHistory()
  const { markAsSeenList, isReady, beenShownBefore } = useTutorials()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { avatarUrl } = useUsername({ skip: tutorialName !== tutorialNames.SHIFT_PLAN })

  const isTutorialSeen = useMemo(() => isReady && !beenShownBefore(tutorialName), [
    beenShownBefore,
    isReady,
    tutorialName,
  ])

  const goBack = useCallback(
    (route: string) => {
      pushBackLinkOrDefault(route)
    },
    [pushBackLinkOrDefault],
  )

  useEffect(() => {
    if (!isTutorialSeen) return
    markAsSeenList([tutorialName, ...relatedTutorials])
  }, [isTutorialSeen, markAsSeenList, tutorialName, relatedTutorials])

  const getTutorialComponent = useCallback(() => {
    const props = { tutorialName, t, step, setStep, goBack, history }

    switch (tutorialName) {
      case tutorialNames.SHIFT_PLAN:
        return <TutorialShiftplan hasUserAvatar={Boolean(avatarUrl)} {...props} />
      case tutorialNames.ABSENCE_REQUEST_FRONTLINER:
        return <TutorialAbsenceRequestFrontliner {...props} />
      case tutorialNames.ABSENCE_REQUEST_MANAGER:
        return <TutorialAbsenceRequestManager {...props} />
      case tutorialNames.WORK_TIME_CONTROL_FRONTLINER:
        return <TutorialWorkTimeControlFrontliner {...props} />
      case tutorialNames.WORK_TIME_CONTROL_MANAGER:
        return <TutorialWorkTimeControlManager {...props} />
      case tutorialNames.ABSENCE_PLAN_MANAGER:
        return <TutorialAbsenceplanManager {...props} />
      case tutorialNames.ABSENCE_PLAN_FRONTLINER:
        return <TutorialAbsenceplanFrontliner {...props} />
      case tutorialNames.RESIGNATION:
        return <Resignation {...props} />
      case tutorialNames.SECURE_CONNECTION:
        return <TutorialSecureConnection taskId={taskId} {...props} />

      default:
        return <></>
    }
  }, [tutorialName, t, step, goBack, history, avatarUrl, taskId])

  return (
    <Page hideNativeNavigation hideHamburger noPadding hideWebToolbar removeLastMargin>
      {getTutorialComponent()}
    </Page>
  )
}

export default Tutorial
