import React from 'react'
import { Box, Typography } from '@mui/material'
import useUsername from 'hooks/useUsername'
import { BirdNegativeL } from 'icons'
import { headerClasses } from './styles'

const Header: React.FC = () => {
  const { firstName } = useUsername()

  return (
    <Box sx={headerClasses.root}>
      <Box sx={headerClasses.content}>
        <Box sx={headerClasses.birdyAndName}>
          <Typography variant="h2" noWrap>
            {firstName}
          </Typography>
        </Box>
        <Box sx={headerClasses.cage}>
          <BirdNegativeL />
        </Box>
      </Box>
    </Box>
  )
}

export default Header
