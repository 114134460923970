import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const SecurityPinInfoL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M28.75 10c.65 0 1.18.5 1.24 1.12l.01.13v9.5c0 .65-.5 1.18-1.12 1.24l-.13.01H3.25c-.65 0-1.18-.5-1.24-1.12L2 20.75v-9.5c0-.65.5-1.18 1.12-1.24l.13-.01h25.5zm-.25 1.5h-25v9h25v-9zm-10 2a.5.5 0 0 1 .48.41L19 14v1.07l.9-.58a.5.5 0 0 1 .62.78l-.07.05-1.04.68 1.03.7a.5.5 0 0 1-.47.87l-.08-.04-.89-.6V18a.5.5 0 0 1-.99.09V16.93l-.9.59a.5.5 0 0 1-.62-.79l.07-.05 1.04-.68-1.04-.7a.5.5 0 0 1 .48-.87l.08.04.88.6V14c0-.28.23-.5.5-.5zm5.99 0a.5.5 0 0 1 .49.41V15.07l.9-.58a.5.5 0 0 1 .7.14c.13.2.1.47-.08.64l-.07.05-1.04.68 1.04.7c.23.15.29.46.13.69a.5.5 0 0 1-.6.18l-.09-.04-.89-.6V18a.5.5 0 0 1-.99.09V16.93l-.9.59a.5.5 0 1 1-.62-.78l.07-.06 1.04-.68-1.04-.7a.5.5 0 0 1 .48-.87l.08.04.88.6V14c0-.28.23-.5.5-.5zm-12 0a.5.5 0 0 1 .5.41V15.07l.9-.58a.5.5 0 0 1 .62.78l-.07.05-1.04.68 1.04.7a.5.5 0 0 1-.48.87l-.08-.04-.89-.6V18a.5.5 0 0 1-.99.09V16.93l-.9.59a.5.5 0 0 1-.62-.79l.07-.05 1.04-.68-1.04-.7a.5.5 0 0 1 .48-.87l.08.04.88.6V14c0-.28.23-.5.5-.5zm-5.99 0a.5.5 0 0 1 .5.41V15.07l.9-.58a.5.5 0 0 1 .62.78l-.07.05-1.04.68 1.03.7a.5.5 0 0 1-.47.87l-.08-.04-.89-.6V18a.5.5 0 0 1-.99.09V16.93l-.9.59a.5.5 0 0 1-.63-.79l.07-.05L5.6 16l-1.04-.7a.5.5 0 0 1 .48-.87l.08.04.88.6V14c0-.28.23-.5.5-.5z"
      />
    </Svg>
  )
}

export default SecurityPinInfoL
