import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const NoInfoM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12 4.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15ZM3 12c0-4.969 4.03-9 9-9s9 4.031 9 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9Zm9.439-3.957a2.25 2.25 0 0 0-2.31.957 2.25 2.25 0 0 0-.379 1.25.75.75 0 0 1-1.5 0 3.75 3.75 0 0 1 2.315-3.465 3.75 3.75 0 0 1 5.113 2.733 3.75 3.75 0 0 1-2.928 4.406v.326a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 0 2.079-1.389 2.25 2.25 0 0 0-.488-2.452 2.25 2.25 0 0 0-1.152-.616ZM12 17.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default NoInfoM
