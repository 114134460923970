export type CapacitorPlatformT = 'android' | 'ios' | 'web'

export const capacitorPlatformTypes: Record<CapacitorPlatformT, CapacitorPlatformT> = {
  android: 'android',
  ios: 'ios',
  web: 'web',
}

export type NativeVersionObjectT = {
  platform: CapacitorPlatformT
  version: number
}

export type NativeVersionRequirementQueryReturnT = {
  nativeVersionRequirement: {
    requiredVersion: number
    requirementMet: boolean
  }
}
