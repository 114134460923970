import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonBack: {
    alignSelf: 'flex-start',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    alignSelf: 'flex-start',
    marginLeft: theme.spacing(1.5),
  },
  image: {
    width: 'auto',
    maxHeight: '60vh',
    alignSelf: 'center',
    marginBottom: theme.spacing(1.25),
  },
  text: {
    color: theme.palette.grey[600],
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  },
  buttons: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonStart: {
    minWidth: theme.spacing(7.5),
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}
