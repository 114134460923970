import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const CalendarAppointmentS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M7 9H5V7h2v2zm5.5 5h-9a1 1 0 01-1-1V4.5a1 1 0 011-1H5V2h1.5v1.5h3V2H11v1.5h1.5a1 1 0 011 1V13a1 1 0 01-1 1zm-.5-2V6.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5V12a.5.5 0 00.5.5h7a.5.5 0 00.5-.5z"
      />
    </Svg>
  )
}

export default CalendarAppointmentS
