import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NoEventsXL } from 'icons'
import { styles } from '../styles'
import { NO_EVENTS_ICON_SIZE } from './constants'

const NoEvents: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={styles.noContentContainer}>
      <Box sx={styles.noContentIconText}>
        <NoEventsXL width={NO_EVENTS_ICON_SIZE} height={NO_EVENTS_ICON_SIZE} />
        <Typography sx={styles.noContentText}>{t('page.cms.event.noEvents')}</Typography>
      </Box>
    </Box>
  )
}

export default NoEvents
