import React from 'react'
import { Typography, Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface ISelectedInfo {
  labelText?: string
  numSelected: number
}

const SelectedInfo: React.FC<ISelectedInfo> = ({ labelText, numSelected }) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={classes.layout}>
      <Typography variant="body2" sx={classes.text}>
        {labelText} {numSelected > 0 ? <strong>{numSelected}</strong> : t('page.teams.selectedPersons.none')}
      </Typography>
    </Box>
  )
}

export default SelectedInfo
