import React from 'react'
import { Box, Fab, SxProps, Theme } from '@mui/material'
import { isNative } from 'utils/platform'
import { classes } from './styles'

interface FloatingBottomButtonProps {
  Icon?: React.FC
  label: string
  onClick: () => void
  testId: string
  nativeBottomMargin?: boolean
  sx?: SxProps<Theme>
}

const FloatingBottomButton: React.FC<FloatingBottomButtonProps> = ({
  Icon,
  label,
  onClick,
  testId,
  nativeBottomMargin = false,
  sx,
}) => {
  return (
    <Fab
      variant="extended"
      size="small"
      onClick={onClick}
      sx={[
        classes.floatingButtonBase,
        ...(nativeBottomMargin && isNative() ? [classes.floatingButtonNativeMargin] : []),
        ...(!Icon ? [classes.textWithNoIcon] : []),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-testid={testId}
    >
      {Icon && (
        <Box mr={0.25} ml={0.375} display="flex" alignItems="center">
          <Icon />
        </Box>
      )}
      <Box mr={0.375} display="flex" alignItems="center">
        {label}
      </Box>
    </Fab>
  )
}

export default FloatingBottomButton
