import React, { useMemo } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import ComponentBadge from 'components/ComponentBadge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useNotificationCounts from 'hooks/useNotificationCounts'
import useRolesViews from 'hooks/useRolesViews'
import { BellL } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { headerClasses } from './styles'

const BADGE_MAX_VALUE = 1000

const DashboardHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasActiveAndResignedPersons, hasOfficeView } = useRolesViews()
  const {
    counts: { manager, personal },
  } = useNotificationCounts()
  const history = useHistory()

  const totalNotifications = useMemo(() => (hasOfficeView ? manager + personal : personal), [
    hasOfficeView,
    manager,
    personal,
  ])

  return (
    <Box sx={hasActiveAndResignedPersons ? headerClasses.flexContainer : headerClasses.flexContainerOneChild}>
      <Box sx={hasActiveAndResignedPersons ? headerClasses.fillerBox : []} />
      <Box>
        <Typography variant="h2">{t('page.dashboard.header.title')}</Typography>
      </Box>
      {hasActiveAndResignedPersons && (
        <IconButton
          onClick={() => {
            history.push(routes.notifications)
          }}
          sx={headerClasses.iconButton}
          data-testid="show-all-notifications-icon"
        >
          <ComponentBadge max={BADGE_MAX_VALUE} badgeContent={totalNotifications} sx={headerClasses.badge}>
            <BellL fill={theme.palette.common.white} />
          </ComponentBadge>
        </IconButton>
      )}
    </Box>
  )
}

export default DashboardHeader
