import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'

interface IDeleteDialogProps {
  open: boolean
  actionCancel: () => void
  actionDelete: () => void
}

const DeleteDialog: React.FC<IDeleteDialogProps> = ({ open, actionCancel, actionDelete }) => {
  const { t } = useFavurTranslation()
  return (
    <Dialog
      open={open}
      actions={
        <>
          <Button onClick={actionCancel} color="secondary" size="large" data-testid="delete-dialog-cancel-button">
            {t('common.cancel')}
          </Button>
          <Button onClick={actionDelete} color="secondary" size="large" data-testid="delete-dialog-delete-button">
            {t('common.delete')}
          </Button>
        </>
      }
    >
      <Typography variant="body2">{t('teams.settings.deleteDialog')}</Typography>
    </Dialog>
  )
}

export default DeleteDialog
