import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const TeamsL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M11.5 5.75a5.125 5.125 0 1 0 0 10.25 5.125 5.125 0 0 0 0-10.25ZM9.29 8.665a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42Zm-1.856 12.52a5.75 5.75 0 0 1 9.816 4.065 1 1 0 1 0 2 0 7.75 7.75 0 1 0-15.5 0 1 1 0 1 0 2 0 5.75 5.75 0 0 1 1.684-4.066Zm11.2-11.154a4.375 4.375 0 1 1 6.187 6.188 4.375 4.375 0 0 1-6.188-6.188Zm3.093.719a2.375 2.375 0 1 0 0 4.75 2.375 2.375 0 0 0 0-4.75Zm-1.568 10.256a4.526 4.526 0 0 1 6.091 4.245 1 1 0 0 0 2-.002 6.526 6.526 0 0 0-8.783-6.12 1 1 0 0 0 .692 1.877Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default TeamsL
