import favurTheme from 'utils/theme'

export const classes = {
  line: {
    width: '100%',
    height: '2px',
    padding: 0,
    border: 0,
    // @ts-ignore
    background: favurTheme.palette.primary[50],
  },
  negativeSign: {
    width: '7px',
  },
  value: {
    width: favurTheme.spacing(2.8125),
    textAlign: 'left',
  },
  balanceCell: {
    border: 'none',
    padding: '0px',
    marginBottom: '2px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexGrow: 1,
    flexBasis: '100%',
    fontSize: '12px',
    lineHeight: 1.4,
    margin: favurTheme.spacing(0.75, 0),
    '&.MuiTableCell-head': {
      marginTop: '0px',
    },
    '&.MuiTableCell-head:first-of-type': {
      textTransform: 'capitalize',
    },
  },
  currentMonthData: {
    display: 'flex',
    flexGrow: 1,
  },
  estimationsData: {
    display: 'flex',
    flexGrow: 1,
  },
  date: {
    paddingLeft: '7px',
  },
  balanceRow: {
    display: 'flex',
    justifyContent: 'left',
    padding: favurTheme.spacing(0, 0.5),
  },
}
