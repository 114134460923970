import React from 'react'
import { PIN_STATUSES } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PhoneM } from 'icons'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'

interface IPhoneNumber {
  pinStatus: string
}

const PhoneNumber: React.FC<IPhoneNumber> = ({ pinStatus }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <UserSettingsSectionCTA
      title={t('page.userSettings.profile.changePhoneNumber.title')}
      icon={<PhoneM fill={palette.primary.main} />}
      onClick={() => history.push(routes.phoneChange)}
      disabled={pinStatus !== PIN_STATUSES.set}
      data-testid="user-settings-security-change-phone"
    />
  )
}

export default PhoneNumber
