import theme from 'utils/theme'

export const classes = {
  formContent: {
    paddingBottom: 'calc(168px + var(--ion-safe-area-bottom))',
  },
  formContentFullscreen: {
    paddingBottom: 'calc(84px + var(--ion-safe-area-bottom))',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '17px',
  },
  absenceType: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
  },
  summary: {
    marginTop: '5px',
  },
  loadingBox: {
    display: 'flex',
    width: '100%',
    height: `calc(100vh - ${theme.spacing(10.0)} - var(--ion-safe-area-bottom) - var(--ion-safe-area-top))`,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
