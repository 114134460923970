import { performEventToTask } from 'pages/Tasks/queries'
import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResult } from 'types'
import { useMutation } from '@apollo/client'

const usePerformEventToTask = (favurUuid: string, onCompleted = () => {}) => {
  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResult }>(
    performEventToTask,
    {
      onCompleted,
    },
  )

  const accept = () =>
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.monthlySheetAccept,
      },
    })

  const sendCommentToManager = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.monthlySheetSendCommentToManager,
        comment,
      },
    })
  }

  const sendCommentToFrontline = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.monthlySheetSendCommentToFrontline,
        comment,
      },
    })
  }

  const acceptForThisMonth = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.monthlySheetAcceptThisMonth,
        comment,
      },
    })
  }

  const acceptForNextMonth = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.monthlySheetAcceptNextMonth,
        comment,
      },
    })
  }

  return {
    loading,
    accept,
    sendCommentToManager,
    sendCommentToFrontline,
    acceptForThisMonth,
    acceptForNextMonth,
  }
}

export default usePerformEventToTask
