import { alpha } from '@mui/material/styles'
import theme from 'utils/theme'

export const classes = {
  componentContainer: {
    width: '100%',
  },
  numpadRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconButton: {
    width: '33%',
    padding: theme.spacing(0.75, 0),
    paddingBottom: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.primary[700],
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[400], 0.04),
    },
  },
  numpadConfirmButton: {
    width: '33%',
    height: '100%',
    padding: theme.spacing(0.25, 1.25),
    alignItems: 'center',
  },
  confirmButtonIcon: {
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    padding: '6px',
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.common.white,
  },
}
