import theme from 'utils/theme'

export const sendCommentBodyClasses = {
  body: {
    margin: '24px 0',
  },
  title: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
  textBody: {
    marginTop: '8px',
  },
}
