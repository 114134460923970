import React from 'react'
import { Box } from '@mui/material'
import Alert from 'components/Alert'
import Timeline from 'components/Timeline'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { SecureConnectionTaskT, TaskT } from 'pages/Tasks/types'
import ActionButtons from './Details/ActionButtons'
import { styles } from './styles'
import { getTimelineForComponent } from './utils'

interface IContentProps {
  isOffice: boolean
  refetch: () => void
  task: TaskT<SecureConnectionTaskT>
  goToTutorial: () => void
}

const Content: React.FC<IContentProps> = ({ isOffice, task, refetch, goToTutorial }) => {
  const { t, locale } = useFavurTranslation()
  const { personIds } = useRolesViews()

  const timelineItems = getTimelineForComponent({ task, t, locale: locale as Locale, isOffice, personIds })

  return (
    <Box sx={styles.timelineContainer}>
      {!isOffice && (
        <Box sx={styles.secureConnectionAlert}>
          <Alert
            content={t('page.secureConnectionDetails.alert.content')}
            severity="info"
            title={t('page.secureConnectionDetails.alert.title')}
            onClick={goToTutorial}
            isWholeClickable
          />
        </Box>
      )}
      <Timeline items={timelineItems} />
      <ActionButtons
        task={task}
        isOffice={isOffice}
        onAction={() => {
          refetch()
        }}
      />
    </Box>
  )
}

export default Content
