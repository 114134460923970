import React from 'react'
import type { ReminderNotificationT } from 'types'
import EmptyNotification from '../EmptyNotification'
import { ReminderTypes } from '../constants'
import type { NotificationPropsT } from '../types'
import MonthlySheetReminder from './MonthlySheet'

interface IReminderNotification extends NotificationPropsT {
  notification: ReminderNotificationT
}

const ReminderNotification: React.FC<IReminderNotification> = (props) => {
  const {
    notification: {
      data: { reminderType },
    },
  } = props

  switch (reminderType) {
    case ReminderTypes.monthlySheetApproval:
      return <MonthlySheetReminder {...props} />
    default:
      return <EmptyNotification />
  }
}

export default ReminderNotification
