import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResult } from 'types'
import { useMutation } from '@apollo/client'
import { performEventToTask } from '../queries'

const usePerformEventToTask = (favurUuid: string) => {
  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResult }>(
    performEventToTask,
  )

  const sendReminderToFrontline = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToFrontline,
      },
    })
  }

  const sendReminderToManager = () => {
    return performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.sendReminderToManager,
      },
    })
  }

  const sendEventWithComment = (event: EventsOfTask, comment: string, onCompleted: () => void) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event,
        comment,
      },
      onCompleted: () => {
        if (onCompleted) onCompleted()
        else window.location.reload()
      },
    })
  }

  return {
    loading,
    sendReminderToFrontline,
    sendReminderToManager,
    sendEventWithComment,
  }
}

export default usePerformEventToTask
