import React, { Context, Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import useAbsencePlannerStateContext from './AbsencePlannerStateContext'

export type AvatarSidebarStateContextT = {
  avatarBarExpanded: boolean
}

export type AvatarSidebarStateDispatchContextT = Dispatch<SetStateAction<boolean>>

const taskBarInitialState: AvatarSidebarStateContextT = {
  avatarBarExpanded: true,
}

const taskBarDispatchInitialState: AvatarSidebarStateDispatchContextT = () => {}

export const AvatarSidebarStateContext: Context<AvatarSidebarStateContextT> = createContext(taskBarInitialState)
export const AvatarSidebarStateDispatchContext: Context<AvatarSidebarStateDispatchContextT> = createContext(
  taskBarDispatchInitialState,
)

export const AvatarSidebarStateProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const [avatarBarExpanded, setAvatarBarExpanded] = useState(!isSmallScreen)

  return (
    <AvatarSidebarStateContext.Provider value={{ avatarBarExpanded }}>
      <AvatarSidebarStateDispatchContext.Provider value={setAvatarBarExpanded}>
        {children}
      </AvatarSidebarStateDispatchContext.Provider>
    </AvatarSidebarStateContext.Provider>
  )
}

export const useAvatarSidebarStateContext = () => {
  const context = useContext(AvatarSidebarStateContext)

  return { ...context }
}

export const useAvatarSidebarStateDispatchContext = () => {
  const setAvatarSidebarState = useContext(AvatarSidebarStateDispatchContext)

  return setAvatarSidebarState
}

export default useAvatarSidebarStateContext
