import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const InfoM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M3 12c0-4.969 4.03-9 9-9s9 4.031 9 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.579 8.397a1.579 1.579 0 1 0-3.158 0 1.579 1.579 0 0 0 3.158 0Zm.075 8.421c.25 0 .451-.202.451-.45v-.903a.451.451 0 0 0-.45-.451h-.452v-3.76a.451.451 0 0 0-.451-.45h-2.406a.451.451 0 0 0-.451.45v.903c0 .249.202.45.45.45h.452v2.407h-.451a.451.451 0 0 0-.451.451v.902c0 .25.202.451.45.451h3.31Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default InfoM
