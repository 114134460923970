import { useCallback } from 'react'
import { AxiosResponse } from 'axios'

export const USER_CANCEL = 'Cancelled by user'
export const NETWORK_ERROR = 'Network Error'

const useRejectGraphql = () => {
  const onSucess = useCallback((response: AxiosResponse) => {
    if (response.data?.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
      /* Consider in the future include data.success === false, as rejected promise */
      const error = response.data.errors[0]
      return Promise.reject(error)
    }

    return response
  }, [])

  const getResponseDataFromblobToJson = (error: { response: { data: Blob } }) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsText(error.response.data)

      reader.onload = () => {
        // @ts-ignore
        resolve(JSON.parse(reader.result))
      }

      reader.onerror = () => {
        reject(error)
      }
    })
  }

  // This is used for rest calls when getting pdf's
  const getResponseData = useCallback(
    (error: { message: string; request: { responseType: string }; response: AxiosResponse }) => {
      if (error.message === NETWORK_ERROR) {
        return { error: { message: error.message, code: 'common.error.network' } }
      }

      if (
        error?.request?.responseType === 'blob' &&
        error?.response?.data instanceof Blob &&
        error?.response?.data?.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
      ) {
        return getResponseDataFromblobToJson(error)
      }

      // For not blob rest errors, this will return the error data as it is. If graphql this will be undefined (only when backend crashes or returns 4xx)
      return error?.response?.data
    },
    [],
  )

  // If you want a rest call to return an error code, use this structure:
  // error: %{
  //  code: "xxx.xxxx.xxx",
  //  message: "A string that describes the error"
  // }
  // It will be then returned as error.response.data.error{code: xxxx, message: "string"}
  const onReject = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any,
    ) => {
      if (error?.message === USER_CANCEL) {
        return Promise.resolve(error)
      }
      const responseData = await getResponseData(error)

      return Promise.reject(responseData?.error)
    },
    [getResponseData],
  )

  return [onSucess, onReject]
}

export default useRejectGraphql
