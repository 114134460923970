import { useEffect, useState } from 'react'
import { isNative } from 'utils/platform'
import { Capacitor } from '@capacitor/core'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'

const useKeyboardState = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  useEffect(() => {
    if (!isNative() || !Capacitor.isPluginAvailable('Keyboard')) return () => {}

    const showCallback = (ki: KeyboardInfo) => {
      const newHeight = ki.keyboardHeight
      setIsOpen(true)
      setKeyboardHeight(newHeight)
    }

    const hideCallback = () => {
      setIsOpen(false)
      setKeyboardHeight(0)
    }
    Keyboard.addListener('keyboardDidShow', showCallback)
    Keyboard.addListener('keyboardDidHide', hideCallback)

    return () => {
      Keyboard.removeAllListeners()
    }
  }, [setIsOpen, setKeyboardHeight])

  return {
    isOpen,
    keyboardHeight,
  }
}

export default useKeyboardState
