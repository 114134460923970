import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import TabContent from 'components/TabContent'
import useNotificationCounts from 'hooks/useNotificationCounts'
import usePackage from 'hooks/usePackage'
import useRolesViews from 'hooks/useRolesViews'
import NotificationsList from './components/NotificationsList'
import NotificationTabs from './components/Tabs'
import { classes } from './styles'

const NotificationsContent: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const { hasOfficeView: isManager } = useRolesViews()
  const { hasClassicLightAccess } = usePackage()
  const handleChange = (newValue: number) => {
    setTabIndex(newValue)
  }
  const { counts, refetch: refetchCounts, loading } = useNotificationCounts()

  useEffect(() => {
    if (!isManager) return
    if (counts.personal && !counts.manager) setTabIndex(1)
  }, [isManager, counts.personal, counts.manager])

  if (loading) <SimpleLoading />

  if (!isManager || !hasClassicLightAccess) {
    return (
      <Box sx={classes.container}>
        <NotificationsList showAcknowledgeAll={Boolean(counts.personal)} refetchCounts={refetchCounts} />
      </Box>
    )
  }

  return (
    <Box sx={classes.container}>
      <NotificationTabs currentTabIndex={tabIndex} handleChange={handleChange} counts={counts}>
        {isManager && (
          <TabContent index={0} currentTabIndex={tabIndex}>
            <NotificationsList
              inManagerTab
              showAcknowledgeAll={Boolean(counts.manager)}
              refetchCounts={refetchCounts}
            />
          </TabContent>
        )}
        <TabContent index={isManager ? 1 : 0} currentTabIndex={tabIndex}>
          <NotificationsList showAcknowledgeAll={Boolean(counts.personal)} refetchCounts={refetchCounts} />
        </TabContent>
      </NotificationTabs>
    </Box>
  )
}

export default NotificationsContent
