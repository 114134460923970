import { useCallback, useEffect, useState } from 'react'
import { throttledRefreshHighSecSession } from 'utils/throttledRefreshHighSecSession'

const useBlockingPeriod = (initialValue?: number | null) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [editedValue, setEditedValue] = useState(initialValue ?? 0)

  const onChange = useCallback((newValue: number) => setEditedValue(newValue), [])

  useEffect(() => {
    setEditedValue(initialValue ?? 0)
  }, [drawerOpen, initialValue])

  return {
    drawerOpen,
    onOpen: () => {
      throttledRefreshHighSecSession()
      setDrawerOpen(true)
    },
    onClose: () => setDrawerOpen(false),
    editedValue,
    onChange,
  }
}

export default useBlockingPeriod
