import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { DocumentNotificationT, NotificationT } from 'types'
import NotificationTemplate from '../Templates/Notification'
import { getBodyTranslation } from './utils'

interface IDocumentNotification {
  notification: DocumentNotificationT
  dismiss: (notification: NotificationT) => void
}

const DocumentNotification: React.FC<IDocumentNotification> = (props) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { notification, dismiss } = props

  const handleRedirect = useCallback(() => {
    dismiss(notification)
    history.push(routes.documents)
  }, [dismiss, history, notification])

  const templateProps = {
    ...props,
    title: t(`notification.new.document.title`),
    body: getBodyTranslation(t, notification.data?.documentType),
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default DocumentNotification
