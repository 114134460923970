import { cardVariants } from 'constants/componentsBasics'
import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const variantClasses = {
  [cardVariants.highlight]: {
    backgroundColor: palette.secondary[10],
  },
  [cardVariants.office]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [cardVariants.clickable]: {
    cursor: 'pointer',
  },
  [cardVariants.disabled]: {
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[50],
    cursor: 'not-allowed',
  },
}

export const baseClass = {
  padding: '16px',
  boxShadow: 'none',
  outline: '1px solid',
  borderRadius: '8px',
  outlineColor: palette.primary[100],
  backgroundColor: theme.palette.common.white,
}
