import theme from 'utils/theme'

export const classes = {
  selectorIcon: {
    padding: `0px ${theme.spacing(0.5)}`,
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  monthText: {
    textAlign: 'center',
    width: '72px',
  },
  selector: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
}
