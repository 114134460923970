import React from 'react'
import { Box, Typography } from '@mui/material'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTeamplanDetailContext from 'pages/Shifts/TeamplanDetailPage/context'
import { IonContent } from '@ionic/core/components/ion-content'
import { classes } from './styles'

const ShiftsPageHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  const { selectedTeamData, setSelectedTeamData } = useTeamplanDetailContext()

  return !selectedTeamData ? (
    <Box sx={classes.shiftsHeader}>
      <Typography sx={classes.shiftsHeaderTitle} variant="h2" data-testid="header__title">
        {t('page.shifts.content.shiftsTitle')}
      </Typography>
    </Box>
  ) : (
    <WithBackwardLink
      closeIcon={false}
      onClick={() => {
        setSelectedTeamData(undefined)
        const ionContent = document.getElementById('ionContent') as IonContent
        if (ionContent) {
          ionContent.scrollToTop()
        }
      }}
      title={selectedTeamData.headerTitle}
      topTitle={selectedTeamData.headerTopTitle}
      multipleLines={false}
      dataTestId="teamplan-detail__title"
    />
  )
}

export default ShiftsPageHeader
