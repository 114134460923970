import { useMemo, useState } from 'react'
import { getTeamsQuery, GetTeamsQueryReturnT, getTeamsQueryWithMembers } from 'shared/queries'
import { TeamT, TeamWithUserPermissions, WorkflowsT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'
import useSessionContext from './useSessionContext'

const addUserPermissions = (
  userPersonIds: number[] | undefined,
  teams: TeamT[] | undefined,
): TeamWithUserPermissions[] => {
  if (!userPersonIds || !teams) return []

  return teams.map((team) => {
    const userMember = team.teamPermissions?.find((tp) => userPersonIds.includes(Number(tp.person?.id)))
    return {
      ...team,
      userPermissions: userMember,
    } as TeamWithUserPermissions
  })
}

interface TeamsParams {
  onlyManager?: boolean
  queryForUsers?: boolean
  memberPermissions?: WorkflowsT[]
}

const useTeams = ({ onlyManager = false, queryForUsers = false, memberPermissions = [] }: TeamsParams = {}) => {
  const [teams, setTeams] = useState<TeamT[]>()
  const [publicTeams, setPublicTeams] = useState<TeamT[]>()
  const { personIds } = useSessionContext()

  const { loading: loading } = useJamesApolloQuery<{ getTeams: GetTeamsQueryReturnT }>(
    queryForUsers ? getTeamsQueryWithMembers : getTeamsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        isManager: onlyManager,
        memberPermissions: memberPermissions,
        removeSupervisors: true,
        personIds: personIds ?? [],
      },
      onCompleted(data) {
        setTeams(queryForUsers ? addUserPermissions(personIds, data.getTeams.member) : data.getTeams.member)
        setPublicTeams(data.getTeams.public)
      },
    },
  )

  const isMultiTenant = useMemo(() => {
    const numPersons = personIds?.length
    return numPersons ? numPersons > 1 : false
  }, [personIds?.length])

  return { teams, isMultiTenant, loading, publicTeams }
}

export default useTeams
