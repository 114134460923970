import { balanceQuery, estimationsQuery } from 'shared/queries'
import { gql } from '@apollo/client'

export const balanceOfficeOverviewQuery = gql`
    query balanceOfficeOverviewQuery($estimateFromDay: utctimestamp!, $taskFavurUuid: String!){
        balanceOfficeOverview(estimateFromDay: $estimateFromDay, taskFavurUuid: $taskFavurUuid) {
            ${balanceQuery}
            ${estimationsQuery}
        }
    }`

export const improvedBalancesQuery = gql`
    query improvedBalancesQuery($estimateFromDay: utctimestamp!, $personIds: [ID]) {
        improvedBalances(estimateFromDay: $estimateFromDay, personIds: $personIds){
            ${balanceQuery}
            ${estimationsQuery}
        }
    }`
