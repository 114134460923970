import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import useUsername from 'hooks/useUsername'
import { AddAvatar, ArrowRightThinS } from 'icons'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { teaserClasses } from './styles'

const TeaserToAvatar: React.FC<{ isAbsencePlanner?: boolean }> = ({ isAbsencePlanner = false }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { avatarUrl, loading } = useUsername({ useCache: true })
  const { setBackLink } = useStateBackLink()

  const showAddAvatar = useMemo(() => !avatarUrl && !loading, [avatarUrl, loading])

  return showAddAvatar ? (
    <Box
      sx={teaserClasses.teaser}
      onClick={() => {
        setBackLink(isAbsencePlanner ? routes.absencePlanner : routes.shifts)
        history.push(routes.profile)
      }}
    >
      <Box sx={teaserClasses.icon}>
        <AddAvatar />
      </Box>
      <Typography variant="caption" sx={teaserClasses.text}>
        {t('page.shifts.sideMenu.teaserToAvatar')}
      </Typography>
      <Box sx={teaserClasses.arrow}>
        <ArrowRightThinS />
      </Box>
    </Box>
  ) : (
    <></>
  )
}

export default TeaserToAvatar
