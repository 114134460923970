import theme from 'utils/theme'

export const taskContainerClasses = {
  taskContainer: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  divider: {
    margin: '10px 0 10px 0',
    width: '100%',
  },
  titleTask: {
    color: theme.palette.grey[600],
    textAlign: 'right',
  },
}
