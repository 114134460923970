import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import SendComment from '../SendComment'
import AcceptThisMonthHeader from '../SendComment/AcceptThisMonthHeader'
import { classes } from './styles'

interface IAcceptThisMonthProps {
  taskUuid: string
  onAction: () => void
}

const AcceptThisMonth: React.FC<IAcceptThisMonthProps> = ({ taskUuid, onAction }) => {
  const { t } = useFavurTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const onCompleted = useCallback(() => {
    setIsOpen(false)
    onAction()
  }, [onAction])
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
        data-testid="monthly-sheet-accept-this-month"
        sx={classes.regularButton}
      >
        {t('workTimeControl.buttons.acceptThisMonth')}
      </Button>
      <SendComment
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        taskUuid={taskUuid}
        eventCompleted={onCompleted}
        eventOnSuccess={EventsOfTask.monthlySheetAcceptThisMonth}
      >
        <AcceptThisMonthHeader />
      </SendComment>
    </>
  )
}

export default AcceptThisMonth
