export const contactDataQueryName = 'contactDataForm'
export const contactDataQuery = `
  query{
    contactData {
      email
      addresses {
        type
        firstName
        lastName
        address
        additionalAddress
        zipCode
        city
        country
        phoneNumber
      }
    }
  }
  `
