import { Theme } from '@mui/material'

const classes = (theme: Theme) => ({
  padding: 0,
  display: 'flex',
  overflow: 'visible',
  width: theme.spacing(3),
  height: theme.spacing(1.5),
  '& .MuiSwitch-switchBase': {
    color: theme.palette.common.white,
    padding: theme.spacing(0.25),
    '&.Mui-checked': {
      color: theme.palette.common.white,
      transform: `translateX(${theme.spacing(1.5)})`,
      '+ .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    color: theme.palette.common.white,
    height: theme.spacing(1),
    width: theme.spacing(1),
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    // @ts-ignore
    border: `none`,
    borderRadius: theme.spacing(0.75),
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    height: 'unset',
  },
  '& .Mui-disabled': {
    color: theme.palette.common.white,
    '&.Mui-checked': {
      color: theme.palette.common.white,
      '+ .MuiSwitch-track': {
        cursor: 'not-allowed',
        // @ts-ignore
        backgroundColor: theme.palette.secondary[200],
      },
    },
    '+ .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'not-allowed',
      opacity: 1,
    },
  },
})

export default classes
