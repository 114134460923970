import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const UsersL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18c5 0 9 3.6 9 8H3c0-4.4 4-8 9-8Zm0 2a7 7 0 0 0-6.5 3.8l-.1.2h13.2a7 7 0 0 0-6.3-4H12Zm10.5-3c3.6 0 6.5 3.1 6.5 7h-6.3c-.7-2.2-2.3-4-4.4-5.3 1.1-1 2.6-1.7 4.2-1.7Zm0 2a4 4 0 0 0-.5 0 12 12 0 0 1 1.9 2.7l.2.3h2.5v-.2a4.5 4.5 0 0 0-3.9-2.8h-.2ZM12 6a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm10.5 3a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7ZM12 8a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm10.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      />
    </Svg>
  )
}

export default UsersL
