/* eslint-disable camelcase */
export const civilStatusWithPartnerKeys = ['married', 'registered_partnership']

export const civilStatusFieldsTrigger = [
  'civilStatusSince',
  'partnerFirstName',
  'partnerLastName',
  'partnerBirthDate',
  'partnerGender',
  'partnerSocialInsuranceNumber',
]

export const civilStatusWithPartner = {
  married: 'personalData.civilStatus.married',
  registered_partnership: 'personalData.civilStatus.registeredPartnership',
}

export const civilStatusValues = {
  single: 'personalData.civilStatus.single',
  ...civilStatusWithPartner,
  divorced: 'personalData.civilStatus.divorced',
  widowed: 'personalData.civilStatus.widowed',
  separated: 'personalData.civilStatus.separated',
  partnership_dissolved_by_court: 'personalData.civilStatus.partnershipDissolvedByCourt',
  partnership_dissolved_by_death: 'personalData.civilStatus.partnershipDissolvedByDeath',
  partnership_dissolved_by_declaration_of_disappearance:
    'personalData.civilStatus.partnershipDissolvedByDeclarationOfDisappearance',
}

export const defaultPersonValues = {
  firstName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  socialInsuranceNumber: '',
  nationality: '',
}

export const partnerInputKeys = {
  firstname: 'FirstName',
  lastname: 'LastName',
  gender: 'Gender',
  birthdate: 'BirthDate',
  insurance: 'SocialInsuranceNumber',
}

export const kidInputKeys = {
  firstname: 'firstName',
  lastname: 'lastName',
  gender: 'gender',
  birthdate: 'birthDate',
  insurance: 'socialInsuranceNumber',
}
