import theme from 'utils/theme'

export const tertiaryClasses = {
  container: (isNative: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: isNative ? '83px' : 0,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  }),
  elementsGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'start',
  },
  element: {
    flex: 1,
    padding: 0,
    color: theme.palette.common.white,
    minWidth: 'unset',
    paddingRight: theme.spacing(2),
    height: '100%',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
    textTransform: 'none',
  },
}
