import React, { useContext } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { DetailsContext } from '../DetailsPage'

const Accept: React.FC = () => {
  const { t } = useFavurTranslation()
  const { setIsOpenAccept } = useContext(DetailsContext)

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setIsOpenAccept(true)
        }}
        data-testid="absence-request_action-accept"
      >
        {t('absenceRequest.buttons.accept')}
      </Button>
    </>
  )
}

export default Accept
