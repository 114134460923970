import { Theme } from '@mui/material'

const classes = {
  button: (theme: Theme) => ({
    width: '33%',
    padding: theme.spacing(0.75, 3.125),
    paddingBottom: theme.spacing(1),
    '&:hover:before': {
      backgroundColor: 'transparent !important',
    },
  }),
  label: (theme: Theme) => ({
    fontFamily: 'FiraSans, sans-serif',
    fontWeight: 600,
    fontSize: 23,
    lineHeight: 1.25,
    letterSpacing: 0.2,
    color: theme.palette.text.primary,
  }),
}

export default classes
