import React from 'react'
import PinLogin from 'pages/PinLogin'
import EmployersSelection from './EmployersSelection'

const EmployersSelectionPage: React.FC = () => {
  return (
    <PinLogin>
      <EmployersSelection />
    </PinLogin>
  )
}

export default EmployersSelectionPage
