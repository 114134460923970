import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'

const Logo: React.FC<IconPropsT> = ({ width, height, fill = theme.palette.common.white }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 169 47" fill={fill}>
      <path d="M45.81 9.328c10.36 0 14.786 4.104 14.9 12.186l.001.32v12.572c0 2.59.67 3.599 2.204 4.147.472.167.883.214 1.228.2l.144-.01L57.5 45.53c-2.588-.652-4.443-1.954-5.73-4.385-2.528 3.76-6.417 5.053-10.432 5.053-6.869 0-11.274-4.469-11.274-10.818 0-7.482 5.614-11.681 15.969-11.792l.422-.002h2.983v-1.167c0-3.758-1.427-4.794-5.379-4.794-1.666 0-4.12.399-6.748 1.125l-.466.131-6.248-6.249c3.838-1.869 11.038-3.303 15.212-3.303zm72.28 1.144v23.326c0 3.11 1.04 3.954 2.724 3.954 1.619 0 3.303-1.168 4.665-3.437V10.472h11.532v34.535h-10.107l-.454-4.082c-2.396 3.565-5.83 5.25-10.301 5.25-6.546 0-9.59-4.277-9.59-10.887V10.472h11.532zm-39.516.023l5.185 26.049 5.573-26.05h11.726l-10.56 34.538H76.826L66.07 10.495h12.503zM11.988 8.527v10.78h12.7v8.49h-12.7v17.235H0V20.513L11.988 8.527zm153.464.906c1.296 0 2.267.196 3.434.584l-1.815 11.144c-1.102-.323-2.008-.519-3.175-.519-4.146 0-5.767 3.113-6.804 7.97v16.394h-11.534V10.471h10.11l.97 6.544c1.425-4.6 4.925-7.582 8.814-7.582zM49.439 29.74h-1.944c-4.146 0-6.092 1.49-6.092 4.535 0 2.332 1.232 3.823 3.436 3.823 1.858 0 3.422-1.008 4.461-2.511l.139-.208V29.74zM31.706 0l-8.489 8.488h-11.19L20.516 0h11.189z" />
      <path fill="#FF0062" d="M0 14V0h14z" />
    </Svg>
  )
}

export default Logo
