import theme from 'utils/theme'

export const classes = {
  cross: {
    color: theme.palette.error.main,
    marginBottom: `${theme.spacing(0.75)} !important`,
  },
  title: {
    //@ts-ignore
    color: theme.palette.primary[700],
    marginBottom: `${theme.spacing(0.75)} !important`,
  },
  terms: {
    color: theme.palette.grey[900],
    marginBottom: `${theme.spacing(1.5)} !important`,
    textAlign: 'center',
  },
  failureContainerPaper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: `${theme.spacing(0.75)} !important`,
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryButton: {
    marginBottom: `${theme.spacing(1)} !important`,
  },
}
