import theme from 'utils/theme'

const navigationAppClasses = {
  navigation: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    paddingBottom: `max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)})`,
    paddingTop: theme.spacing(0.75),
    height: 'auto',
  },
  navigationWrapper: {
    width: '100%',
    boxShadow: theme.shadows[11],
    background: theme.palette.common.white,
    zIndex: 10,
  },
  menuActive: {
    // @ts-ignore
    background: theme.palette.primary[900],
  },
  action: {
    color: theme.palette.primary.main,
    boxSizing: 'border-box',
    minWidth: 'unset',
    padding: 'unset',
    '&.Mui-selected, &.active': {
      color: theme.palette.secondary.main,
      padding: 'unset',
    },
    '&.MuiBottomNavigationAction-iconOnly': {
      padding: 'unset',
    },
    '& .MuiBottomNavigationAction-label': {
      fontSize: '10px !important',
      lineHeight: '15px !important',
    },
  },
  dashboard: {
    '&.Mui-selected': {
      paddingTop: 10,
      paddingBottom: 3,
    },
    '&.MuiBottomNavigationAction-iconOnly': {
      paddingTop: 10,
      paddingBottom: 3,
    },
  },
  label: {
    '&.MuiBottomNavigationAction-label': {
      marginTop: theme.spacing(0.25),
      fontSize: '10px',
      opacity: '1 !important',
      '&.Mui-selected': {
        fontSize: '10px',
      },
    },
  },
  typographyLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'FiraSans, sans-serif',
    letterSpacing: 0,
    fontSize: '10px',
    lineHeight: '15px',
  },
  actionActive: {
    color: theme.palette.common.white,
  },
}

export default navigationAppClasses
