import theme from 'utils/theme'

export const classes = {
  container: {
    '& > div': {
      marginBottom: theme.spacing(1),
    },
  },
  headerContainer: {
    position: 'relative',
  },
  headerIcon: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: 0,
  },
  tabsBadge: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    '.MuiBadge-badge': {
      transform: 'none',
      height: '18px',
      minWidth: '18px',
      position: 'relative',
    },
  },
  acknowledgeAllButton: {
    marginBottom: '10px',
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  noNotificationsBody: {
    alignItems: 'center',
    width: '100%',
  },
}
