import { createContext, Dispatch, SetStateAction, Context } from 'react'

export interface IPushNotificationsContext {
  pushActiveOnDevice: boolean
  setPushActiveOnDevice: Dispatch<SetStateAction<boolean>>
  pushGenericCallbacksRegistered: boolean
  setPushGenericCallbacksRegistered: Dispatch<SetStateAction<boolean>>
}

const initialState: IPushNotificationsContext = {
  pushActiveOnDevice: false,
  setPushActiveOnDevice: () => {},
  pushGenericCallbacksRegistered: false,
  setPushGenericCallbacksRegistered: () => {},
}

const SessionContext: Context<IPushNotificationsContext> = createContext(initialState)

export default SessionContext
