import React from 'react'
import { Box, IconButton } from '@mui/material'
import Stepper from 'components/Stepper'
import { CloseThinM } from 'icons'
import { StepT } from 'types'
import { classes } from './styles'

interface IStepperHeaderProps {
  onClickIcon?: () => void
  activeStep: number
  steps: StepT[]
}

const StepperHeader: React.FC<IStepperHeaderProps> = ({ onClickIcon, activeStep, steps }) => {
  return (
    <Box sx={classes.container}>
      <IconButton onClick={onClickIcon} sx={classes.icon} size="large">
        <CloseThinM />
      </IconButton>
      <Box sx={classes.stepperContainer}>
        <Stepper activeStep={activeStep} steps={steps} />
      </Box>
    </Box>
  )
}

export default StepperHeader
