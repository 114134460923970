export type ValidationStatusT = 'active' | 'inactive' | 'notValidated' | 'noInfo'
export const validationStatuses: { [key: string]: ValidationStatusT } = {
  active: 'active',
  inactive: 'inactive',
  notValidated: 'notValidated',
  // eslint-disable-next-line camelcase
  no_info: 'noInfo',
}

export type ValidationStatusReturnT = {
  getPersonValidationStatus: {
    status: ValidationStatusT
  }
}
