/* eslint-disable camelcase */
export const absenceTypes: { [key: string]: string } = {
  free: 'absenceRequest.type.free',
  vacation: 'absenceRequest.type.vacation',
  bank_holiday: 'absenceRequest.type.bank_holiday',
  accident: 'absenceRequest.type.accident',
  sickness: 'absenceRequest.type.sickness',
  military: 'absenceRequest.type.military',
  civil_service: 'absenceRequest.type.civil_service',
  school: 'absenceRequest.type.school',
  unpaid_vacation: 'absenceRequest.type.unpaid_vacation',
  education: 'absenceRequest.type.education',
  motherhood: 'absenceRequest.type.motherhood',
  short_time: 'absenceRequest.type.short_time',
  free_compensation: 'absenceRequest.type.free_compensation',
  additional_free: 'absenceRequest.type.additional_free',
  compensation_day: 'absenceRequest.type.compensation_day',
}

export const absenceRequestStatus = {
  reviewOfficeUnseen: 'review_office_unseen',
  reviewOfficeSeen: 'review_office_seen',
  approvedOffice: 'approved_office',
  rejectedOffice: 'rejected_office',
  deletionRequested: 'deletion_requested',
  acceptedDeletionRequest: 'accepted_deletion_request',
  rejectedDeletionRequest: 'rejected_deletion_request',
}

export const dateRangeOptionsKeys = {
  singleDay: 'singleDay',
  multipleDays: 'multipleDays',
}

export const dateRangeOptionsValues = {
  singleDay: 'absenceRequest.new.singleDay',
  multipleDays: 'absenceRequest.new.multipleDays',
}

export const inputDateFormat = 'EEEE, dd. MMM yyyy'
