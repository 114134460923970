import React, { useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowDownS, ArrowUpS } from 'icons'
import { classes } from './styles'

interface ICollapsibleErrorSectionProps {
  error: Error
}

const CollapsibleErrorSection: React.FC<ICollapsibleErrorSectionProps> = ({ error }) => {
  const { t } = useFavurTranslation()
  const [openMoreDetails, setOpenMoreDetails] = useState(false)
  // @ts-ignore
  const { name, message, cause, stack } = error

  return (
    <Box sx={classes.errorMessageBlock}>
      <Button
        variant="text"
        color="primary"
        onClick={() => setOpenMoreDetails(!openMoreDetails)}
        sx={classes.openMoreDetailsButton}
        disableFocusRipple
        disableRipple
      >
        {openMoreDetails ? (
          <>
            {t('page.shifts.shiftCard.lessDetails')}
            <Box sx={classes.arrowIconContainer}>
              <ArrowUpS />
            </Box>
          </>
        ) : (
          <>
            {t('page.shifts.shiftCard.moreDetails')}
            <Box sx={classes.arrowIconContainer}>
              <ArrowDownS />
            </Box>
          </>
        )}
      </Button>
      <Collapse in={openMoreDetails}>
        <Box sx={classes.errorTextRoot}>
          <Box sx={classes.errorTextContainer}>
            <Typography variant="overline">Name: </Typography>
            <Typography variant="caption">{name}</Typography>
            <Typography variant="overline">Message: </Typography>
            <Typography variant="caption">{message}</Typography>
            <Typography variant="overline">Cause: </Typography>
            {/* @ts-ignore */}
            <Typography variant="caption">{cause}</Typography>
            <Typography variant="overline">Stack: </Typography>
            <Typography variant="caption">{stack}</Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default CollapsibleErrorSection
