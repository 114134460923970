import theme from 'utils/theme'

export const classes = {
  headerContainer: { display: 'flex', flexDirection: 'column', marginTop: '-1px' },
  headerText: { color: theme.palette.grey[600] },
  avatarRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
  },
  hrAvatar: {
    backgroundColor: 'white',
  },
  avatarName: {
    position: 'relative',
    left: '8px',
    top: '7px',
  },
}
