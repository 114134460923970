import theme from 'utils/theme'

export const contactClasses = {
  root: {
    padding: theme.spacing(1),
    '& .MuiDialogContent-root': {
      padding: '0',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
}
