import favurTheme from 'utils/theme'
import palette from 'utils/theme/palette'

export const teamsHeaderClasses = {
  selectBox: {
    color: 'white',
    width: favurTheme.spacing(10),
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  selectIcon: {
    right: favurTheme.spacing(1.25),
    width: favurTheme.spacing(1.25),
  },
}

export const classes = {
  editTeamMembersForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  settingsNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  settingsButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '4px',
  },
  team: {
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: favurTheme.spacing(1.125),
    paddingLeft: favurTheme.spacing(1),
    paddingRight: favurTheme.spacing(0.75),
    height: favurTheme.spacing(5),
    '&:last-child': {
      flexGrow: '0 !important',
    },
  },
  displayButton: {
    background: 'none',
    display: 'flex',
    textAlign: 'left',
    boxSizing: 'content-box',
    padding: 0,
    margin: 0,
    '&:hover, &:focus': {
      backgroundColor: favurTheme.palette.common.white,
    },
    fontSize: 'inherit',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    // @ts-ignore
    color: favurTheme.palette.grey[900],
    flexGrow: 1,
  },
  noTeamText: {
    color: palette.text.disabled,
    fontWeight: 'bold',
  },
  caption: {
    textTransform: 'none',
    display: 'block',
    marginBottom: favurTheme.spacing(0.25),
    marginTop: favurTheme.spacing(0.25),
  },

  checkbox: {
    padding: 0,
  },
  person: {
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: favurTheme.spacing(1.125),
    paddingLeft: favurTheme.spacing(1),
    paddingRight: favurTheme.spacing(0.75),
    height: favurTheme.spacing(5),
    '&:last-child': {
      flexGrow: '0 !important',
    },
  },
  noPersonsText: {
    color: palette.text.disabled,
    fontWeight: 'bold',
  },

  personTeamInfo: {
    paddingTop: favurTheme.spacing(0.25),
  },
  personTeamInfoText: {
    color: favurTheme.palette.secondary.main,
    position: 'relative',
    bottom: favurTheme.spacing(0.25),
  },
  workflowPersonsList: {
    marginTop: '0px',
    marginBottom: '0px',
    listStyleType: 'disc',
    position: 'relative',
    right: favurTheme.spacing(1.8),
    '& > li::marker': {
      fontSize: '0.6em',
    },
    bottom: '1.6px',
  },
  workflowPersonListItemText: {
    position: 'relative',
    top: favurTheme.spacing(0.1),
  },
  noPersonInWorkflow: {
    position: 'relative',
  },
  noPersonInWorkflowText: {
    color: favurTheme.palette.secondary.main,
    position: 'relative',
    bottom: favurTheme.spacing(0.25),
  },
  noPersonSupervisorText: {
    position: 'relative',
    bottom: favurTheme.spacing(0.25),
  },
  teamPermissionEditButton: {
    textAlign: 'right',
  },
  supervisorEditButton: {
    textAlign: 'right',
  },
  supervisorCardTitle: {
    fontWeight: 500,
    lineHeight: '20px',
  },
  supervisorCardBodyText: {
    fontWeight: 350,
    lineHeight: '22px',
  },
  settingsCardTitle: {
    fontWeight: 500,
    lineHeight: '20px',
  },
  toggleTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  settingsCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: favurTheme.spacing(0.5),
  },
  nativeBottomMargin: {
    marginBottom: favurTheme.spacing(1.25),
  },
  alertCaption: {
    color: favurTheme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
  },
}
