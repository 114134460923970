import React, { useCallback, useEffect, useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import FlashMessagesContext from 'services/FlashMessages/context'
import { useMutation } from '@apollo/client'
import EntryPointWithMirusLogo from '../../EntryPointWithMirusLogo'
import { classes } from '../../styles'
import { IInvitationStepProps } from '../types'
import { initRegisterQuery } from './queries'

type InitRegisterResponse = {
  initRegister: { success: boolean }
}

const Invite = ({ setStep, invitation }: IInvitationStepProps) => {
  const { t } = useFavurTranslation()

  const { phone, updatePhoneNumber } = usePhoneNumber(invitation?.phoneNumber || '')

  const { setFlashMessage } = useContext(FlashMessagesContext)

  useEffect(() => {
    updatePhoneNumber(invitation?.phoneNumber || '')
  }, [invitation, updatePhoneNumber])

  const [initRegisterMutation, { loading }] = useMutation<InitRegisterResponse>(initRegisterQuery)

  const submitSmsValidation = useCallback(() => {
    initRegisterMutation({ variables: { invitationCode: invitation.code } }).then((data) => {
      if (data.data?.initRegister.success) {
        setStep('SMS_VALIDATION')
      } else {
        setFlashMessage('common.error.be.default')
      }
    })
  }, [initRegisterMutation, invitation.code, setFlashMessage, setStep])

  if (loading) {
    return (
      <EntryPointWithMirusLogo>
        <SimpleLoading />
      </EntryPointWithMirusLogo>
    )
  }

  return (
    <EntryPointWithMirusLogo>
      <form>
        <Box sx={classes.heading}>
          <Typography variant="h2" color="primary">
            {t('login.start.content.invitation.title')}
          </Typography>
          <Typography variant="h2" color="primary">
            {invitation.tenantName}
          </Typography>
          <Typography component="p" sx={classes.text}>
            {t('login.start.content.invitation.description')}
          </Typography>
        </Box>
        <PhoneNumberInput phone={phone} label={t('login.start.content.phoneNumber')} disabled />
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={submitSmsValidation}
          data-testid="submit-phone"
        >
          {t('login.start.content.invitation.callToAction')}
        </Button>

        <br />
        <Button
          size="large"
          variant="text"
          color="primary"
          onClick={() => window.open(t('page.url.intro'), '_blank', 'noopener,noreferrer')}
          sx={classes.intro}
        >
          {t('login.start.content.invitation.intro')}
        </Button>
      </form>
    </EntryPointWithMirusLogo>
  )
}

export default Invite
