import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const AlertS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12zm1-5.5v-4a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zM8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </Svg>
  )
}

export default AlertS
