import { shiftDateFormat } from 'constants/dateFormats'
import { eachDayOfInterval, isWithinInterval } from 'date-fns'
import { Audience, CmsContentT } from 'types'
import { getFormattedFromDate } from './date'

export const getEventsInDate = (events: CmsContentT[], date: Date) => {
  return events.filter((event) => {
    if (!event.startDate || !event.endDate) return false
    return isWithinInterval(date, { start: event.startDate, end: event.endDate })
  })
}

export const getEventsInDateRange = (events: CmsContentT[], range: { start: string; end: string }) => {
  const nextDaysRange = eachDayOfInterval({ start: range.start, end: range.end })

  return Array.from(nextDaysRange).reduce((acc, date) => {
    const eventsInDate = getEventsInDate(events, date)
    const keyDate = getFormattedFromDate(date, shiftDateFormat)

    return acc.set(keyDate, eventsInDate)
  }, new Map<string, CmsContentT[]>())
}

const isAudienceInTenant = (audience: Audience, { ownerId, tenantId }: { ownerId: string; tenantId: string }) => {
  return audience.tenantId === tenantId || audience.ownerId === ownerId
}

export const getEventsFromTenantAndOwner = (
  events: CmsContentT[],
  { tenantId, ownerId }: { ownerId?: string; tenantId?: string },
) => {
  if (!ownerId || !tenantId) return []
  return events.filter((event) =>
    event.audiences?.some((audience) => isAudienceInTenant(audience, { tenantId, ownerId })),
  )
}
