import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import { NumpadWithoutState } from 'components/Numpad'
import Snackbar from 'components/Snackbar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AssetCheckmark from 'icons/AssetCheckmarkSvg'
import { getFirstErrorCode } from 'utils/apollo'
import { isNativeNoOverride } from 'utils/platform'
import { escapeNonNumericalKeys } from 'utils/string'
import { MutationResult, useMutation } from '@apollo/client'
import { validateHRPinQuery } from '../../queries'
import { classes as forgotPhoneClasses } from '../../styles'
import { classes } from './styles'

interface IHRPinProps {
  goToStep: (stepName: string) => void
  oldPhone?: string
}

const HRPin: React.FC<IHRPinProps> = ({ goToStep, oldPhone }) => {
  const { t } = useFavurTranslation()
  const [serverError, setServerError] = useState(false)
  const [pin, setPin] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const defaultSnackbarText = useMemo(
    () => ({
      title: t('forgotPhone.HRPin.snackbar.title'),
      message: t('forgotPhone.HRPin.snackbar.body'),
    }),
    [t],
  )
  const [snackbarText, setSnackbarText] = useState(defaultSnackbarText)
  const [validateHRPinMutation] = useMutation<MutationResult>(validateHRPinQuery)

  const onSubmit = useCallback(
    () =>
      validateHRPinMutation({ variables: { token: pin } })
        .then(() => setShowSuccess(true))
        .catch((e) => {
          const errorCode = getFirstErrorCode(e)
          setSnackbarText(
            t(errorCode) ? { title: t('common.error.label.errorTitle'), message: t(errorCode) } : defaultSnackbarText,
          )
          setServerError(true)
        }),

    [defaultSnackbarText, pin, t, validateHRPinMutation],
  )

  useEffect(() => {
    if (!showSuccess) return undefined

    const successTimeout = setTimeout(() => goToStep('NEW_SIM'), 2100)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [goToStep, showSuccess])

  return (
    <CenteredBox sx={forgotPhoneClasses.root}>
      <Snackbar open={serverError} setOpen={setServerError} flashText={snackbarText} />
      <Box sx={forgotPhoneClasses.textContainer}>
        <Typography variant="h2" color="primary">
          {t(showSuccess ? 'forgotPhone.HRPin.success' : 'forgotPhone.HRPin.title')}
        </Typography>
        {!showSuccess && (
          <Typography variant="body1" data-testid="old-phone-text" sx={forgotPhoneClasses.bodyText}>
            {oldPhone && t('forgotPhone.HRPin.oldPhone', { oldPhone })}
          </Typography>
        )}
        {showSuccess && (
          <>
            <Box sx={forgotPhoneClasses.checkMark}>
              <AssetCheckmark />
            </Box>
          </>
        )}
      </Box>
      {!showSuccess && (
        <>
          <Box sx={forgotPhoneClasses.inputContainer}>
            <TextField
              variant="standard"
              autoComplete="one-time-code"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={!isNativeNoOverride()}
              sx={classes.hrCodeInput}
              InputLabelProps={{ shrink: true, sx: classes.hrCodeInputLabel }}
              value={pin}
              color="primary"
              inputProps={{ 'data-testid': 'hrpin-input', readOnly: isNativeNoOverride() }}
              onChange={(e) => setPin(e.target.value)}
              inputMode="numeric"
              label={t('forgotPhone.HRPin.input')}
              name={t('forgotPhone.HRPin.input')}
              onKeyPress={escapeNonNumericalKeys}
            />
          </Box>
          <Box sx={forgotPhoneClasses.buttonContainer}>
            <NumpadWithoutState
              numpadType="pin"
              numpadValue={pin}
              setNumpadValue={setPin}
              onConfirm={onSubmit}
              usedWithPhoneNumberValidation
            />
            <Button
              size="large"
              sx={forgotPhoneClasses.bottomButtons}
              type="submit"
              variant="text"
              color="secondary"
              onClick={() => goToStep('OLD_SIM')}
              data-testid="go-to-start"
            >
              {t('forgotPhone.oldSim.back')}
            </Button>
          </Box>{' '}
        </>
      )}
    </CenteredBox>
  )
}

export default HRPin
