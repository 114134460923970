import theme from 'utils/theme'

export const teaserClasses = {
  teaser: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    padding: theme.spacing(0.5),
    marginTop: '16px',
    marginBottom: '21px',
    cursor: 'pointer',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.grey[900],
    lineHeight: 1.5,
    marginRight: theme.spacing(1),
  },
  arrow: {
    width: '16px',
  },
}
