import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import palette from 'utils/theme/palette'
import { DELAY_ANIMATION_MS, loadingClasses } from './styles'

const TriangleAnimation: React.FC<{ small?: boolean; smallContainer?: boolean }> = ({
  small = false,
  smallContainer = false,
}) => {
  const [hide, setHide] = useState(true)

  useEffect(() => {
    // Implemented this solution to avoid the "react cannot update the state an unmounted component":
    // https://bobbyhadz.com/blog/react-cant-perform-react-state-update-on-unmounted-component
    // eslint-disable-next-line fp/no-let
    let isMounted = true
    const timer = setTimeout(() => isMounted && setHide(false), DELAY_ANIMATION_MS)
    return () => {
      isMounted = false
      clearTimeout(timer)
    }
  }, [])

  return (
    <Box
      sx={[
        loadingClasses.triangleContainer,
        ...(small ? [loadingClasses.small] : [loadingClasses.big]),
        ...(hide ? [loadingClasses.hideAnimation] : []),
        ...(!smallContainer ? [loadingClasses.bigTriangleContainer] : []),
      ]}
    >
      <svg viewBox="0 0 62 62" data-name="svgContainer">
        <g clipPath="url(#clip_0_339)" width="62" height="62" data-name="Frame 1">
          <rect width="62" height="62" fill="transparent" data-name="Frame 1" />
          <g transform="translate(2 1)" width="59.02" height="59" data-name="1">
            <rect
              width="5"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Top1"
              transform="translate(0 1)"
            />
            <rect
              width="5"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Side1"
              transform="translate(0.04 59) rotate(-90)"
            />
            <rect
              width="5"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Diagonal1"
              transform="translate(59.02 3.54) rotate(135)"
            />
          </g>
          <g transform="translate(1 1)" width="60.02" height="60.02" data-name="2">
            <rect
              width="58"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Top2"
              transform="translate(59 6) rotate(180)"
            />
            <rect
              width="58"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Side2"
              transform="translate(6.04 1) rotate(90)"
            />
            <rect
              width="79.88"
              height="5"
              rx="2.5"
              fill={palette.secondary[400]}
              data-name="Diagonal2"
              transform="translate(0 56.48) rotate(-45)"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip_0_339">
            <rect width="62" height="62" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default TriangleAnimation
