import palette from 'utils/theme/palette'

export const classes = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    alignSelf: 'stretch',
  },
  dayInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    width: '133px',
    paddingBottom: '23px',
  },
  dayLabel: {
    fontSize: '12px',
    fontWeight: 400,
    color: palette.grey[600],
  },
  dateValue: {
    letterSpacing: 0.3,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px' /* 125% */,
  },
  selectContainer: {
    flex: '1 0 0',
  },
}
