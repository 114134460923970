import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import { ArrowUpThinM } from 'icons'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'
import { getNamedDaysByLanguage } from 'utils/date'
import { isNative } from 'utils/platform'
import theme from 'utils/theme'
import ShiftsMonthlyCalendar from './ShiftsMonthlyCalendar'
import { classes, swipableViewsContainerStyles, swipableViewsStyles } from './styles'

interface IMonthlyCalendarSliderProps {
  closeCalendar: () => void
  referenceDay: Date
  onClickDay: (day: Date) => void
  handleSwipeAction: (value: number) => void
  swipeIndex: number
}

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const MonthlyCalendarSlider: React.FC<IMonthlyCalendarSliderProps> = ({
  closeCalendar,
  referenceDay,
  onClickDay,
  handleSwipeAction,
  swipeIndex,
}) => {
  const { language } = useFavurTranslation()

  const { personIds } = useSessionContext()
  const weekDays = getNamedDaysByLanguage(language)

  return (
    <Box sx={classes.calendarContainer}>
      <Box sx={classes.weekDaysContainer}>
        {weekDays.map((day) => (
          <Typography variant="subtitle2" key={day} sx={classes.weekDay}>
            {day}
          </Typography>
        ))}
      </Box>
      <VirtualizeSwipeableViews
        enableMouseEvents={isNative()}
        index={swipeIndex}
        onChangeIndex={(newIndex, indexLatest) => {
          handleSwipeAction(newIndex < indexLatest ? -1 : 1)
        }}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
        slideRenderer={(params) => (
          <ShiftsMonthlyCalendar
            referenceDay={referenceDay}
            key={params.index}
            index={params.index}
            personIds={personIds as number[]}
            closeCalendar={closeCalendar}
            onClickDay={onClickDay}
          />
        )}
        style={swipableViewsStyles}
        containerStyle={swipableViewsContainerStyles}
      />
      <Button variant="text" sx={classes.closeButton} disableRipple onClick={closeCalendar}>
        <ArrowUpThinM fill={theme.palette.primary.dark} />
      </Button>
    </Box>
  )
}

export default MonthlyCalendarSlider
