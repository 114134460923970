import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import useFilterUtils from 'components/Filter/useFilterUtils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory, useParams } from 'react-router-dom'
import { UserDataUrlParamsT } from '../../types'
import { userDataFormClasses as classes } from './styles'

const Intro: React.FC<{ goToStep: (stepName: string, additionalUrlParams?: string[]) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setLoadFilterTasks } = useFilterUtils()
  const { favurUuid } = useParams<UserDataUrlParamsT>()

  const onClickCancel = useCallback(() => {
    setLoadFilterTasks()
    history.goBack()
  }, [history, setLoadFilterTasks])

  return (
    <CenteredBox sx={[classes.container, classes.containerFlex]}>
      <Box>
        <Typography variant="h2" sx={[classes.heading, classes.headingExtraMargin]}>
          {t('userData.intro.title')}
        </Typography>
        <Typography variant="body1" sx={classes.text}>
          {t('userData.intro.text1')}
        </Typography>
        <Typography variant="body1" sx={classes.text}>
          {t('userData.intro.text2')}
        </Typography>
      </Box>
      <Box>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => goToStep('personal', [favurUuid])}
          sx={classes.button}
        >
          {t('userData.intro.callToAction')}
        </Button>
        <br />
        <Button size="large" onClick={onClickCancel} sx={classes.button}>
          {t('common.cancel')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default Intro
