export type TaskBarStateT = 'managerTasks' | 'userTasks' | 'filters' | 'newAbsenceRequest' | 'events'
export const taskBarStates: Record<TaskBarStateT, TaskBarStateT> = {
  managerTasks: 'managerTasks',
  userTasks: 'userTasks',
  filters: 'filters',
  newAbsenceRequest: 'newAbsenceRequest',
  events: 'events',
}

export type ColorModesT = 'colorful' | 'greyscale'
export const colorModes: Record<ColorModesT, ColorModesT> = {
  colorful: 'colorful',
  greyscale: 'greyscale',
}

export type AbsenceRequestTabStateT = 'list' | 'detail' | 'new'
export const absenceRequestTabStates: Record<AbsenceRequestTabStateT, AbsenceRequestTabStateT> = {
  list: 'list',
  detail: 'detail',
  new: 'new',
}

export type EventTabStateT = 'list' | 'detail'
export const eventTabStates: Record<EventTabStateT, EventTabStateT> = {
  list: 'list',
  detail: 'detail',
}
