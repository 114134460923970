import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const DropdownUpS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12.146 8.15l.708-.706a.502.502 0 000-.708l-4.5-4.5a.502.502 0 00-.708 0l-4.5 4.5a.502.502 0 000 .708l.708.707a.5.5 0 00.637.058l.07-.058L8 4.71l3.44 3.44a.5.5 0 00.637.058l.07-.058zm0 5.5l.708-.706a.502.502 0 000-.708l-4.5-4.5a.502.502 0 00-.708 0l-4.5 4.5a.502.502 0 000 .708l.708.707a.5.5 0 00.637.058l.07-.058L8 10.21l3.44 3.44a.5.5 0 00.637.058l.07-.058z"
      />
    </Svg>
  )
}

export default DropdownUpS
