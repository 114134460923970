import theme from 'utils/theme'

export const backwardLinkClasses = {
  icon: {
    // @ts-ignore
    color: theme.palette.primary[900],
    position: 'absolute',
    left: 0,
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
}
