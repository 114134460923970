import React from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import { BoxedLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePackage from 'hooks/usePackage'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getActiveConnections } from 'shared/queries'
import { PersonT } from 'types'
import { profileClasses } from './styles'

const EmployersList: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { hasClassicLightAccess } = usePackage()

  const { data, loading } = useJamesApolloQuery<{ connections: { persons: PersonT[] } }>(getActiveConnections, {
    fetchPolicy: 'cache-and-network',
  })

  if (loading) {
    return <BoxedLoading />
  }

  const numPersons = data?.connections.persons?.length ?? 0
  if (numPersons === 0) {
    return null
  }

  return (
    <Card onClick={() => history.push(routes.employers)}>
      <Box sx={[profileClasses.listElement, profileClasses.employersCard]}>
        {data?.connections.persons?.map((person, index, list) => (
          <Box key={person.id}>
            <Box
              sx={[
                profileClasses.employersRow,
                ...(index < list.length - 1 ? [profileClasses.employersSectionBottom] : []),
              ]}
            >
              <Typography variant="subtitle1" sx={profileClasses.employersTitleSection}>
                {person.tenant?.name || '-'}
              </Typography>

              {hasClassicLightAccess && (
                <Typography component="span" variant="body2" sx={profileClasses.employersLabelSection}>
                  {t('page.userSettings.profile.settingsSection.deleteUserAccount.numTeams', {
                    numTeams: person.teamPermissions?.length ?? 0,
                  })}
                </Typography>
              )}
            </Box>
            {index < list.length - 1 && <DivisionLine />}
          </Box>
        ))}
      </Box>
    </Card>
  )
}

export default EmployersList
