import React from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import { classes } from './styles'

const EffectiveShiftPlaceholder: React.FC = () => {
  return (
    <Box sx={[classes.relative, classes.effectiveShiftContainer]}>
      <Box sx={classes.column}>
        <Box sx={[classes.row, classes.spaceBetween, classes.effectiveShift]}>
          <Box sx={classes.effectiveShiftPlaceHolderSmall} />
          <Box sx={classes.effectiveShiftPlaceHolderBig} />
        </Box>
        <Box sx={[classes.row, classes.spaceBetween]}>
          <Box sx={classes.effectiveShiftPlaceHolderSmall} />
          <Box sx={classes.effectiveShiftPlaceHolderBig} />
        </Box>
      </Box>
      <Box sx={classes.loadingAnimation}>
        <SimpleLoading small smallContainer />
      </Box>
    </Box>
  )
}

export default EffectiveShiftPlaceholder
