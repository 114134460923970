import { cmsBlockFields } from 'shared/queries'
import { gql } from '@apollo/client'

export const getCmsContentQuery = gql`
  query cmsContentGet($contentUuid: ID!) {
    cmsContentGet(uuid: $contentUuid) {
      uuid
      contentType
      contentStatusType
      createdAt
      startDate
      endDate
      sentAt
      isAbsenceRestricted
      isWholeDay
      restrictAbsenceComment
      history {
        insertedAt
        updatedAt
        userFirstName
        userLastName
        avatarImageUuid
        modificationType
      }
      audiences {
        audienceType
        nameDe
        nameEn
        nameFr
        nameIt
        uuid
      }
      metadatas {
        title
        shortDescription
        language
      }
      ${cmsBlockFields}
    }
  }
`
