import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const FormL: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_L} stroke={stroke || theme.palette.grey[500]} fill="none">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.125 12.125h9M8.5 17.125h6M8.5 22.125h3.375M12.875 27.75H6.75A1.75 1.75 0 0 1 5 26V6.75A1.75 1.75 0 0 1 6.75 5h12.4005a1.7501 1.7501 0 0 1 1.2367.5122l3.3506 3.3506c.3279.3281.5121.7729.5122 1.2367v1.5255"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 17a2.9996 2.9996 0 0 0 1.8519 2.7716 2.9996 2.9996 0 0 0 3.2694-.6503A2.9993 2.9993 0 0 0 25 17a3 3 0 0 0-6 0ZM17 27a4.9997 4.9997 0 0 1 5-5 4.9997 4.9997 0 0 1 5 5"
      />
    </Svg>
  )
}

export default FormL
