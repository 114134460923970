import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CardToggle from 'pages/UserSettings/components/CardToggle'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import { TenantT } from 'types'

interface IEmployeeTimeManagementProps {
  tenant: TenantT
  onChangeHideUnverifiedEffectiveTimes: (newValue: boolean) => void
  loadingMutations: boolean
}

const EmployeeTimeManagement: React.FC<IEmployeeTimeManagementProps> = ({
  tenant,
  onChangeHideUnverifiedEffectiveTimes,
  loadingMutations,
}) => {
  const { t } = useFavurTranslation()

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (loadingMutations) return
      onChangeHideUnverifiedEffectiveTimes(e.target.checked)
    },
    [loadingMutations, onChangeHideUnverifiedEffectiveTimes],
  )

  return (
    <UserSettingsSection title={t('page.userSettings.tenant.employeeTimeManagement')}>
      <CardToggle
        checked={Boolean(tenant.hideUnverifiedEffectiveTimes)}
        onChange={onChange}
        title={t('page.userSettings.tenant.hideUnverifiedEffectiveTimes')}
        subtitle={t('page.userSettings.tenant.hideUnverifiedEffectiveTimesExplanation')}
      />
    </UserSettingsSection>
  )
}

export default EmployeeTimeManagement
