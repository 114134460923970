import React, { useState } from 'react'
import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import PinContext, { IPinState } from './context'

const initialState: IPinState = {
  pinLength: PIN_LENGTHS.default,
}

interface Props {
  children?: React.ReactNode
}

const PinService: React.FC<Props> = ({ children }) => {
  const [state, setPinContext] = useState(initialState)

  return <PinContext.Provider value={{ ...state, setPinContext }}>{children}</PinContext.Provider>
}

export default PinService
