import theme from 'utils/theme'

export const classes = {
  noTeam: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: theme.spacing(1.125),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.75),
    height: theme.spacing(5),
    '&:last-child': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flexGrow: '0 !important' as any,
    },
  },
  noTeamText: {
    color: theme.palette.text.disabled,
    fontWeight: 'bold',
  },
}
