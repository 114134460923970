import React, { memo, useCallback } from 'react'
import { Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { ArrowLeftDoubleS, ArrowRightDoubleS } from 'icons'
import useAvatarSidebarStateContext, {
  useAvatarSidebarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import palette from 'utils/theme/palette'
import { avatarSideBarClasses } from './styles'

interface IToggleSideBarButtonProps {
  isSmallScreen: boolean
  isAtBottom: boolean
  hasOverflow: boolean
}

const ToggleSideBarButton: React.FC<IToggleSideBarButtonProps> = ({ isSmallScreen, isAtBottom, hasOverflow }) => {
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const { avatarBarExpanded: open } = useAvatarSidebarStateContext()

  const toggleOpen = useCallback(() => {
    if (!isSmallScreen) {
      setAvatarBarExpanded((currentState) => !currentState)
      return
    }
    setAvatarBarExpanded(true)
  }, [isSmallScreen, setAvatarBarExpanded])

  return (
    <Box display="flex">
      <Box
        sx={avatarSideBarClasses.toggleButtonContainer(hasOverflow, isAtBottom, open)}
        onClick={() => toggleOpen()}
        data-testid="absence-planner-toggle_avatar_sidebar"
      >
        <Box sx={avatarSideBarClasses.bottomDivisionLineContainer}>
          <DivisionLine />
        </Box>
        <Box sx={avatarSideBarClasses.toggleButtonWrapper(open)}>
          <Box sx={avatarSideBarClasses.toggleButton}>
            {open ? (
              <ArrowLeftDoubleS fill={palette.primary.main} />
            ) : (
              <ArrowRightDoubleS fill={palette.primary.main} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(ToggleSideBarButton)
