import theme from 'utils/theme'

export const classes = {
  backIcon: {
    color: theme.palette.common.white,
    justifySelf: 'start',
    alignSelf: 'end',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiTypography-h2': {
      paddingTop: '2px',
    },
  },
  auxBox: {
    width: '24px',
  },
}
