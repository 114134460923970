import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Button, ButtonProps, SxProps, Theme } from '@mui/material'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePerformEventToTask from 'pages/Tasks/hooks/usePerformEventToTask'
import { EventsOfTask } from 'pages/Tasks/types'
import { PlusContext } from './Plus'
import { classes } from './styles'
import { getWorkflowSpecificProps } from './utils'

interface IAddCommentProps {
  taskUuid: string
  event: EventsOfTask
  onAction: () => void
  sendCommentHeader?: React.ReactNode
  variant?: 'text' | 'outlined'
  required?: boolean
  drawerSx?: SxProps<Theme>
  warningText?: string
  onChangeInput?: (newValue: string) => void
}

const MAX_LENGTH_COMMENT = 2000

const SendComment: React.FC<IAddCommentProps> = ({
  taskUuid,
  event,
  onAction,
  sendCommentHeader,
  variant = 'outlined',
  required,
  drawerSx,
  warningText,
  onChangeInput,
}) => {
  const { t } = useFavurTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { setIsOpenDrawer: setPlusDrawerOpen } = useContext(PlusContext)

  const onCompleted = useCallback(() => {
    setOpenDrawer(false)
    setPlusDrawerOpen && setPlusDrawerOpen(false)
    onAction()
  }, [onAction, setPlusDrawerOpen])

  const { loading, sendEventWithComment } = usePerformEventToTask(taskUuid)
  const workflowSpecificProps = useMemo(() => {
    return getWorkflowSpecificProps(event, t)
  }, [event, t])

  const buttonVariantProps = variant === 'outlined' ? { variant: 'outlined' } : { variant: 'text', color: 'primary' }

  const onRequestChange = useCallback(
    (comment: string) => {
      sendEventWithComment(event, comment, onCompleted)
    },
    [event, onCompleted, sendEventWithComment],
  )

  return (
    <>
      <Button
        {...(buttonVariantProps as ButtonProps)}
        {...workflowSpecificProps.buttonProps}
        variant="contained"
        sx={classes.regularButton}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        onClick={() => setOpenDrawer(true)}
      >
        {workflowSpecificProps.buttonText}
      </Button>
      <TextInputDrawer
        isOpen={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        onSuccess={onRequestChange}
        textFieldMaxLength={MAX_LENGTH_COMMENT}
        successButtonDisabled={loading}
        required={required}
        paperSx={drawerSx}
        warningText={warningText}
        onChangeInput={onChangeInput}
        {...workflowSpecificProps.inputProps}
      >
        {sendCommentHeader && sendCommentHeader}
      </TextInputDrawer>
    </>
  )
}

export default SendComment
