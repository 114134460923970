import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FeedbackM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.25 19a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1 0-1.5h2.5zM12 7a5 5 0 0 1 3 9v1a1 1 0 0 1-.88 1H10a1 1 0 0 1-1-.88V16a5 5 0 0 1 3-9zm0 1.5a3.5 3.5 0 0 0-2.1 6.3c.34.25.55.63.6 1.04v.66h3V16c0-.42.17-.82.48-1.1l.12-.1A3.5 3.5 0 0 0 12 8.5zm8.25 2.75a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1 0-1.5h1.5zm-15 0a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1 0-1.5h1.5zm13.11-5.61c.3.29.3.76 0 1.06L17.3 7.76a.75.75 0 0 1-1.06-1.06l1.06-1.06c.3-.3.77-.3 1.06 0zm-11.66 0L7.76 6.7A.75.75 0 0 1 6.7 7.76L5.64 6.7A.75.75 0 0 1 6.7 5.64zM12 3c.41 0 .75.34.75.75v1.5a.75.75 0 0 1-1.5 0v-1.5c0-.41.34-.75.75-.75z"
      />
    </Svg>
  )
}

export default FeedbackM
