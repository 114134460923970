import React, { useCallback } from 'react'
import { Box, Button } from '@mui/material'
import LoadMoreButton from 'components/LoadMoreButton'
import { SimpleLoading } from 'components/LoadingIcon'
import Notification from 'components/Notification'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useNotificationsPaginated from 'hooks/useNotificationsPaginated'
import { acknowledgeAllMutation, acknowledgeOneMutation } from 'pages/Notifications/queries'
import { classes } from 'pages/Notifications/styles'
import { MutationResult, NotificationT } from 'types'
import { useMutation } from '@apollo/client'
import NoNotificationsCard from './NoNotificationsCard'

interface INotificationsListProps {
  showAcknowledgeAll: boolean
  inManagerTab?: boolean
  refetchCounts: () => void
}

const NotificationsList: React.FC<INotificationsListProps> = ({ inManagerTab, showAcknowledgeAll, refetchCounts }) => {
  const { t } = useFavurTranslation()
  const { notifications, loading: notificationLoading, hasMoreElements, loadMore, reload } = useNotificationsPaginated(
    Boolean(inManagerTab),
  )
  const [acknowledgeAll, { loading: mutationLoading }] = useMutation<MutationResult>(acknowledgeAllMutation)
  const [acknowledgeOne] = useMutation<MutationResult>(acknowledgeOneMutation)

  const onAcknowledgeAll = useCallback(
    () =>
      acknowledgeAll({ variables: { isManager: Boolean(inManagerTab) } }).then(() => {
        reload()
        refetchCounts()
      }),
    [acknowledgeAll, inManagerTab, reload, refetchCounts],
  )

  const loading = notificationLoading || mutationLoading

  return (
    <>
      {showAcknowledgeAll && (
        <Button variant="text" onClick={onAcknowledgeAll} sx={classes.acknowledgeAllButton}>
          {t('page.notifications.list.acknowledgeAll')}
        </Button>
      )}
      {!loading && notifications.length === 0 && <NoNotificationsCard />}
      {notifications.length > 0 && (
        <Box sx={classes.cardList}>
          {notifications.map((notification) => (
            <Notification
              key={`notification_${notification.id}`}
              notification={notification}
              dismiss={(dismissedNotification: NotificationT) => {
                acknowledgeOne({ variables: { notificationId: dismissedNotification.id } })
              }}
            />
          ))}
          {loading && <SimpleLoading />}
          {hasMoreElements && <LoadMoreButton onClick={loadMore} />}
        </Box>
      )}
    </>
  )
}

export default NotificationsList
