import React, { useCallback, useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { BlockingAbsenceM, ReminderM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import UserSettingsSectionEditCTA from 'pages/UserSettings/components/SectionEditCTA'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { TenantT } from 'types'
import palette from 'utils/theme/palette'
import ChangeBlockingPeriodDrawer from './ChangeBlockingPeriodDrawer'
import useBlockingPeriod from './useBlockingPeriod'

interface IWorkflowSettingsProps {
  tenant: TenantT
  onChangeAbsencesBlockingPeriod: (newValue: number) => void
  loadingMutations: boolean
}

const WorkflowSettings: React.FC<IWorkflowSettingsProps> = ({
  tenant,
  onChangeAbsencesBlockingPeriod,
  loadingMutations,
}) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { editedValue, onChange, drawerOpen, onOpen, onClose } = useBlockingPeriod(tenant.absencesBlockingPeriod)
  const onBlockingPeriodSuccess = useCallback(() => {
    onChangeAbsencesBlockingPeriod(editedValue)
    onClose()
  }, [editedValue, onChangeAbsencesBlockingPeriod, onClose])

  const blockingAbsenceSubtitle = useMemo(
    () =>
      tenant.absencesBlockingPeriod !== null
        ? t('page.userSettings.tenantReminderList.nDays', { days: tenant.absencesBlockingPeriod })
        : t('page.userSettings.tenant.blockingAbsenceNotSet'),
    [t, tenant.absencesBlockingPeriod],
  )

  return (
    <UserSettingsSection title={t('page.userSettings.tenant.workflowSettings')}>
      <UserSettingsSectionCTA
        onClick={() => {
          history.push(routes.tenantReminders(tenant.uuid as string))
        }}
        icon={<ReminderM stroke={palette.primary.main} />}
        title={t('page.userSettings.tenant.automaticRemindersSetup')}
        subtitle={t('page.userSettings.tenant.automaticRemindersSetupExplanation')}
      />
      <UserSettingsSectionEditCTA
        title={t('page.userSettings.tenant.blockingAbsenceTitle')}
        subtitle={blockingAbsenceSubtitle}
        icon={<BlockingAbsenceM fill={palette.primary.main} />}
        annotation={t('page.userSettings.tenant.blockingAbsenceAnnotation')}
        onClick={onOpen}
      />
      <ChangeBlockingPeriodDrawer
        value={editedValue}
        onSuccess={onBlockingPeriodSuccess}
        onChange={onChange}
        onClose={onClose}
        open={drawerOpen}
        successButtonDisabled={loadingMutations}
      />
    </UserSettingsSection>
  )
}

export default WorkflowSettings
