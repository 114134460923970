import React, { memo } from 'react'
import { Box, Typography } from '@mui/material'
import { classes } from 'components/ShiftsList/components/ShiftCard/Placeholders/styles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TeamT } from 'types'
import { getFullTeamLabel } from 'utils/team'
import { teamNameRowClasses } from './styles'

interface ITeamNameRownProps {
  team?: TeamT
  teamsLoading: boolean
  isMultiTenant?: boolean
  isSmallScreen?: boolean
}

const TeamNameRow: React.FC<ITeamNameRownProps> = ({ team, teamsLoading, isMultiTenant }) => {
  const { language } = useFavurTranslation()
  return (
    <Box sx={teamNameRowClasses.container}>
      <Box sx={teamNameRowClasses.contentWrapper}>
        {teamsLoading || !team ? (
          <Box sx={classes.teamTitlePlaceholder} />
        ) : (
          <Typography variant="body2" sx={teamNameRowClasses.text} data-testid={`title-${team.id}`}>
            {getFullTeamLabel(team, language, Boolean(isMultiTenant))}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default memo(TeamNameRow)
