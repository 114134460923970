// eslint-disable-next-line import/no-extraneous-dependencies
import { APPLICATION_ENV } from 'env'
import info from 'info.json'
import { datadogRum } from '@datadog/browser-rum'

if (APPLICATION_ENV === 'LIVE') {
  datadogRum.init({
    // The RUM application ID.
    applicationId: '3a7330ba-c418-4b31-ba63-4b08fd8cda1a',
    // A Datadog client token.
    clientToken: 'pub770d4ec15d3983df0c41e748abc6add3',
    // The Datadog site parameter of your organization.
    site: 'datadoghq.eu',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask-user-input',
    service: 'steve-rum',
    env: APPLICATION_ENV.toLowerCase(),
    version: info.version,
  })
}

export default datadogRum
