import theme from 'utils/theme'

export const classes = {
  headerContainer: {
    paddingBottom: '24px',
    boxShadow: theme.shadows[9],
    // We want shadow only at the bottom, hence the clipPath
    // top is equivalent to
    // boxShadow: '0px -4px 16px 0px rgba(84, 109, 153, 0.18)',
    clipPath: 'inset(0px -16px -16px -16px)',
    zIndex: '50',
    position: 'sticky',
  },
  header: {
    display: 'flex',
    gap: '16px',
    paddingLeft: '24px',
  },
  closeIcon: {
    color: 'white',
    zIndex: 9999,
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  headerText: {
    color: theme.palette.primary.main,
    position: 'relative',
  },
}
