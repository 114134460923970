export const detailClasses = {
  drawer: {
    width: '400px',
    marginRight: 0,
  },
  drawerFullScreen: {
    marginRight: 0,
  },
  container: {
    padding: '8px 12px',
    overflowY: 'auto',
    overscrollBehavior: 'none',
  },
}
