import { shiftFields, teamFields } from 'shared/queries'
import { gql } from '@apollo/client'

export const teamplayWithTeamsQuery = gql`
query teamplanWithTeams ($startDate: String!, $endDate: String!, $teamIds: [String]){
  teamplanWithTeams(endDate: $endDate, startDate: $startDate, teamIds: $teamIds){
    date
    tenants {
      id
      name
      costCenters {
        uuid
        name
        persons {
          id
          firstName
          lastName
          isUser
          avatarImageUuid
          ${shiftFields}
          personTeamPermissions {
            supervisor
            team {
              ${teamFields}
              tenant {
                id
                name
              }
              owner {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
`
