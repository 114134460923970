import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const HourS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 1c3.867 0 7 3.133 7 7s-3.133 7-7 7-7-3.133-7-7 3.133-7 7-7zm0 1.5C4.961 2.5 2.5 4.961 2.5 8s2.461 5.5 5.5 5.5 5.5-2.461 5.5-5.5S11.039 2.5 8 2.5zM8.25 4a.5.5 0 01.5.5v3.189l1.427 1.427a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L7.25 8.31V4.5a.5.5 0 01.5-.5h.5z"
      />
    </Svg>
  )
}

export default HourS
