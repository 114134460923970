import React from 'react'
import { Switch as MuiSwitch } from '@mui/material'
import classes from './styles'

interface ISwitchProps {
  checked: boolean
  name: string
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch: React.FC<ISwitchProps> = ({ checked, name, onChange, disabled }) => {
  return (
    <MuiSwitch
      checked={checked}
      name={name}
      onChange={onChange}
      disabled={Boolean(disabled)}
      sx={classes}
      inputProps={{
        'aria-label': 'checkbox',
        // @ts-ignore
        'data-testid': `switch-${name}`,
      }}
    />
  )
}

export default Switch
