import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const SuccessSealM: React.FC<IconPropsT> = ({ stroke = '#1DAF2A' }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none">
      <path
        d="m15 10-3.1848 3.7863c-.0516.0612-.1174.1119-.1928.1485-.0755.0367-.1589.0585-.2446.0639-.0857.0054-.1717-.0057-.2522-.0325-.0805-.0268-.1536-.0687-.2144-.1229L9 12.1399"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9844 3.48659c.1221-.1519.2768-.27446.4525-.35867C11.6126 3.04371 11.805 3 11.9998 3c.1949 0 .3873.04371.563.12792.1757.08421.3304.20677.4525.35867l1.2089 1.50479c.137.17072.3147.30419.5167.38808.2021.08389.422.1155.6395.09192l1.919-.2088c.1943-.02159.3909.00081.5753.06554.1844.06474.352.17014.4902.30842.1382.13828.2435.30589.3082.49042.0647.18453.0871.38126.0655.57562l-.2087 1.91999c-.0235.21762.0081.43767.0919.63984.0839.20218.2172.37997.3879.51696l1.504 1.20963c.1518.1222.2743.2769.3584.4527.0842.1758.1279.3683.1279.5633 0 .1949-.0437.3874-.1279.5632-.0841.1758-.2066.3306-.3584.4528l-1.5088 1.204c-.1706.1372-.3039.3151-.3878.5174-.0838.2023-.1154.4224-.092.6402l.2087 1.9199c.0218.1944-.0005.3912-.0652.5758-.0646.1846-.17.3523-.3082.4906-.1382.1383-.3058.2437-.4903.3084-.1845.0647-.3812.087-.5755.0652l-1.919-.2088c-.2175-.0236-.4374.008-.6395.0919-.2021.0839-.3798.2174-.5167.3881l-1.2041 1.4992c-.1221.1521-.2767.2747-.4524.359-.1758.0843-.3682.1281-.5631.1281-.1949 0-.3873-.0438-.563-.1281-.1758-.0843-.3304-.2069-.4524-.359l-1.20817-1.504c-.13709-.1707-.31493-.3041-.51712-.388-.2022-.0839-.42224-.1155-.63986-.092l-1.91897.2088c-.19432.0219-.39107-.0002-.57565-.0649-.18457-.0646-.35222-.17-.4905-.3083-.13827-.1384-.24362-.3061-.3082-.4908-.06459-.1847-.08677-.3815-.06488-.576l.20869-1.9199c.02356-.2178-.00804-.4379-.09188-.6402-.08384-.2023-.21722-.3803-.38786-.5174l-1.50319-1.2088c-.15195-.1222-.27457-.2768-.35882-.4527C3.04374 12.3829 3 12.1904 3 11.9954s.04374-.3876.12799-.5634c.08425-.1758.20687-.3305.35882-.4526L4.99 9.76977c.17068-.13695.30412-.31473.38797-.51691.08385-.20219.11541-.42227.09177-.63989l-.20869-1.91999c-.02173-.19439.00055-.39119.06519-.57579.06465-.1846.16999-.35228.30823-.49059.13824-.13831.30583-.24371.49033-.30839.1845-.06468.38119-.08697.57548-.06523l1.91897.2088c.21761.0235.43763-.00815.63981-.09203.20218-.08388.38003-.2173.51717-.38797l1.20817-1.49519Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SuccessSealM
