import React, { useCallback, useEffect, useMemo } from 'react'
import { BottomNavigation, BottomNavigationAction, Box, Typography } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurNavigation from 'hooks/useFavurNavigation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { MenuBurgerL, CloseL } from 'icons'
import { useLocation } from 'react-router-dom'
import { RoleViewT } from 'types'
import { isNative } from 'utils/platform'
import NavigationItemWrapper from './components/NavigationItemWrapper'
import navigationAppClasses from './styles'
import type { AppNavigationProps } from './types'

const NavigationApp: React.FC<AppNavigationProps> = ({ isMenuActive, showSidePanel, hideSidePanel }) => {
  const { auth } = useSessionContext()
  const { t } = useFavurTranslation()
  const { pathname } = useLocation()
  const { pushWithRole } = useHistoryUtils()
  const { activeView, setActiveView } = useRolesViews()
  const { nativeNavigation: mainNavigation, getDashboardIcon } = useFavurNavigation()
  const currentValue = useMemo(
    () =>
      mainNavigation.findIndex((navItem) => {
        const isActive = RegExp(`^${navItem.to}(/.*)?$`).test(pathname)
        // if navItem has no role defined, we expect the role to be correct regardless of view
        const isRoleCorrect = !navItem.role || navItem.role === activeView

        return isActive && isRoleCorrect
      }),
    [activeView, mainNavigation, pathname],
  )
  const DashBoardIcon = getDashboardIcon(pathname)

  const [value, setValue] = React.useState(currentValue)

  useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const onNavItemClick = useCallback(
    (newValue: number, to: string, role?: RoleViewT) => {
      setValue(newValue)
      if (to !== pathname) {
        pushWithRole(to, role)
        return
      }

      hideSidePanel()
      if (role && activeView !== role) {
        setActiveView(role ?? ROLE_VIEWS.frontliner)
      }
    },
    [activeView, hideSidePanel, pathname, pushWithRole, setActiveView],
  )

  if (auth < AuthenticationKind.AUTHENTICATED || !isNative()) {
    return null
  }

  // If the menu is active, bottom navigation dissapears
  if (isMenuActive) {
    return null
  }

  return (
    <Box sx={[navigationAppClasses.navigationWrapper, isMenuActive && navigationAppClasses.menuActive]}>
      <BottomNavigation
        value={value}
        sx={[navigationAppClasses.navigation, isMenuActive && navigationAppClasses.menuActive]}
        showLabels
      >
        {mainNavigation.map(({ icon: Icon, nativeIcon: NativeIcon, label, labelShort, to, role }, index) => (
          <BottomNavigationAction
            key={label}
            icon={(() => {
              if (NativeIcon) return <NativeIcon fill="currentColor" />
              if (Icon) return <Icon fill="currentColor" />
              return <DashBoardIcon />
            })()}
            component={NavigationItemWrapper}
            sx={[
              navigationAppClasses.action,
              navigationAppClasses.label,
              isMenuActive && navigationAppClasses.actionActive,
            ]}
            tabIndex={index}
            data-testid={`native-navigation-${label}`}
            onClick={() => onNavItemClick(index, to, role)}
            label={
              <Typography variant="overline" sx={navigationAppClasses.typographyLabel}>
                {t(labelShort || label)}
              </Typography>
            }
            disableRipple
          />
        ))}
        <BottomNavigationAction
          icon={!isMenuActive ? <MenuBurgerL /> : <CloseL />}
          onClick={showSidePanel}
          sx={[navigationAppClasses.action, navigationAppClasses.label]}
          tabIndex={mainNavigation.length}
          data-testid="native-navigation-close-button"
          label={
            <Typography variant="overline" sx={navigationAppClasses.typographyLabel}>
              Menu
            </Typography>
          }
        />
      </BottomNavigation>
    </Box>
  )
}

export default NavigationApp
