import React from 'react'
import { BLOCK_TYPE } from './constants'

export type TabT = {
  id: string | number
  label: React.ReactNode
  disabled?: boolean
}

export enum EventFilterTabs {
  past,
  upcoming,
}

type BlockTypesAvailableForApi = typeof BLOCK_TYPE['document' | 'image']

export type GetBlockSrc = {
  filename: string
  type: BlockTypesAvailableForApi
}
