import React from 'react'
import Svg from 'components/Svg'

const MirusLogo: React.FC = () => {
  return (
    <Svg role="img" data-testid="mirus-logo" fill="#fff" strokeWidth={0} viewBox="0 0 133 31" width={105}>
      <path d="M29.97 29.97h-6.89V13.13l-6.59 8.82h-2.92l-6.64-8.87v16.89H0L.05.25h5.6l9.36 12.83L24.38.25h5.55l.05 29.73ZM43.06 29.97h-6.99V.25h6.99v29.73ZM62.13.25c6.84 0 11.44 4.16 11.44 10.01a9.32 9.32 0 0 1-5.6 8.87v.5l6.74 9.86v.5h-7.88l-6.29-9.76h-4.46v9.76h-6.99V.25h13.03Zm-.44 5.99h-5.6v8.08h5.7c2.63 0 4.61-1.39 4.61-4.06s-1.93-4.01-4.71-4.01ZM78.34 16.94V.25h6.99v16.7c0 3.81 2.03 6.74 6.14 6.74s6.14-2.92 6.14-6.74V.25h6.94v16.7c0 7.68-4.91 13.48-13.08 13.48s-13.13-5.8-13.13-13.48ZM112.08 21.11s4.21 2.87 8.32 2.87c2.43 0 3.52-.89 3.52-2.48s-.99-2.33-5.25-3.62c-6.19-1.83-9.61-4.41-9.61-9.07 0-5.35 3.62-8.82 11.2-8.82 8.03 0 11.1 3.17 11.1 3.17l-3.72 5.65s-3.07-2.48-7.38-2.48c-2.23 0-3.27.74-3.27 2.08s1.29 2.08 4.71 3.07c6.69 1.88 10.35 4.46 10.35 9.26 0 5.35-3.62 9.61-11.4 9.61-8.17 0-12.34-3.91-12.34-3.91l3.77-5.35Z" />
    </Svg>
  )
}

export default MirusLogo
