import React from 'react'
import Tutorial from 'components/Tutorial'
import IntroPage from 'components/Tutorial/IntroPage'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import routes from 'services/RoutesProvider/routes'
import { TutorialProps } from '../types'

const WorkTimeControlFrontliner: React.FC<TutorialProps> = ({
  tutorialName,
  t,
  step,
  setStep,
  goBack,
}: TutorialProps) => {
  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.workTimeControlFrontliner.step1.titleText`),
      mainText: t(`tutorial.workTimeControlFrontliner.step1.text`),
    },
    {
      key: 'step2',
      titleText: t(`tutorial.workTimeControlFrontliner.step2.titleText`),
      extension: 'gif',
    },
    {
      key: 'step3',
      titleText: t(`tutorial.workTimeControlFrontliner.step3.titleText`),
    },
    {
      key: 'step4',
      close: () => goBack(routes.tasks),
      titleText: t(`tutorial.workTimeControlFrontliner.step4.titleText`),
      mainText: t(`tutorial.workTimeControlFrontliner.step4.text`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  const introPage = <IntroPage tutorialName={tutorialName} stepName="intro" close={() => goBack(routes.tasks)} />

  return (
    <Tutorial
      introPage={introPage}
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => goBack(routes.tasks)}
    />
  )
}

export default WorkTimeControlFrontliner
