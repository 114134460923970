import theme from 'utils/theme'

export const classes = {
  spaceBetween: {
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(0.25),
  },
  subtitle: {
    marginBottom: theme.spacing(1.25),
  },
  avatarLine: {
    marginBottom: theme.spacing(0.75),
    alignItems: 'flex-end',
  },
  teamsTitle: {
    marginBottom: theme.spacing(0.375),
    marginTop: theme.spacing(1.25),
  },
  avatarRow: {
    marginBottom: theme.spacing(0.625),
  },
  effectiveShift: {
    marginBottom: theme.spacing(0.5),
  },
  relative: {
    position: 'relative',
  },
  loadingAnimation: {
    position: 'absolute',
    left: 'calc(50% - 22px)',
    top: 0,
    '&>div>div': {
      top: 0,
    },
  },
  effectiveShiftContainer: {
    marginTop: '12px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  titlePlaceholder: {
    width: '228px',
    height: '24px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
  subtitlePlaceholder: {
    width: '105px',
    height: '15px',
    background: '#f2f2f2',
    borderRadius: '20px',
  },
  avatarPlaceholder: {
    width: '40px',
    height: '40px',
    background: '#e6e6e6',
    borderRadius: '50%',
  },
  badgePlaceholder: {
    width: '92px',
    height: '24px',
    background: '#f2f2f2',
    borderRadius: '30px',
  },
  absenceTextPlaceholder: {
    width: '101px',
    height: '24px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
  absenceTypePlaceholder: {
    width: '164px',
    height: '22px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
  teamTitlePlaceholder: {
    width: '144px',
    height: '22px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
  miniAvatarPlaceholder: {
    width: '28px',
    height: '28px',
    background: '#f2f2f2',
    borderRadius: '50%',
  },
  effectiveShiftPlaceHolderSmall: {
    width: '101px',
    height: '24px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
  effectiveShiftPlaceHolderBig: {
    width: '164px',
    height: '24px',
    background: '#e6e6e6',
    borderRadius: '20px',
  },
}
