import React, { useContext, useState } from 'react'
import { Button, SxProps, Theme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { PlusContext } from 'pages/Tasks/components/ActionButtons/Plus'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { BalanceObject } from 'types/balances'
import { newDateWithoutTime } from 'utils/date'
import { getUserName } from 'utils/person'
import EmployeeSaldoDrawer from '../../components/EmployeeSaldoDrawer'
import { balanceOfficeOverviewQuery } from '../../queries'
import { classes } from './styles'

interface ICheckEmployeeSaldo {
  task: TaskT<AbsenceRequestTaskT>
  drawerSx?: SxProps<Theme>
}

const CheckEmployeeSaldo: React.FC<ICheckEmployeeSaldo> = ({ task, drawerSx }) => {
  const { t } = useFavurTranslation()
  const { setIsOpenDrawer } = useContext(PlusContext)
  const [isOpen, setIsOpen] = useState(false)
  const [estimateFromDay, setEstimateFromDay] = useState<Date>(newDateWithoutTime())

  const { loading, data } = useJamesApolloQuery<{ balanceOfficeOverview: BalanceObject[] }>(
    balanceOfficeOverviewQuery,
    {
      variables: {
        estimateFromDay: estimateFromDay.toISOString(),
        taskFavurUuid: task.favurUuid,
      },
      skip: !isOpen,
    },
  )

  return (
    <>
      <Button
        variant="contained"
        sx={classes.regularButton}
        onClick={() => {
          setIsOpenDrawer(false)
          setIsOpen(true)
          setEstimateFromDay(newDateWithoutTime())
        }}
      >
        {t('absenceRequest.buttons.checkEmployeeSaldo')}
      </Button>
      <EmployeeSaldoDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        loading={loading}
        balances={data?.balanceOfficeOverview}
        frontlinerFullName={task.person && getUserName(task.person, true)}
        drawerSx={drawerSx}
      />
    </>
  )
}

export default CheckEmployeeSaldo
