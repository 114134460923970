import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowLeftThinM } from 'icons'
import { Trans } from 'react-i18next'
import { getTutorialImageUrl } from '../utils'
import { classes } from './styles'

interface IIntroPageProps {
  tutorialName: string
  stepName: string
  close: () => void
  titleText?: string
}

const IntroPage: React.FC<IIntroPageProps> = ({ tutorialName, stepName, close, titleText }) => {
  const { t, locale } = useFavurTranslation()

  return (
    <Box sx={classes.container}>
      <Box sx={classes.innerContainer}>
        {close && (
          <IconButton onClick={close} sx={classes.buttonBack} size="large">
            <ArrowLeftThinM />
          </IconButton>
        )}
        <Typography variant="h1" sx={classes.headerTitle}>
          {titleText ?? t(`tutorial.${tutorialName}.${stepName}.title`)}
        </Typography>
        <Typography variant="body1" sx={classes.text}>
          <Trans i18nKey={`tutorial.${tutorialName}.${stepName}.text`} components={{ bold: <strong /> }} />
        </Typography>
        <Box
          component="img"
          src={getTutorialImageUrl(tutorialName, stepName, locale?.code)}
          alt={`${stepName}`}
          sx={classes.image}
        />
      </Box>
    </Box>
  )
}

export default IntroPage
