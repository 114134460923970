import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CalendarAbsenceM: React.FC<IconPropsT & { fillContent?: string }> = ({ fill, fillContent }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill ?? theme.palette.primary.main}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25C8.41421 1.25 8.75 1.58579 8.75 2V4.25H15.25V2C15.25 1.58579 15.5858 1.25 16 1.25C16.4142 1.25 16.75 1.58579 16.75 2V4.25H19.8C20.9363 4.25 21.75 5.23324 21.75 6.30769V10.5V20.6923C21.75 21.7668 20.9363 22.75 19.8 22.75H4.2C3.06367 22.75 2.25 21.7668 2.25 20.6923V10.5V6.30769C2.25 5.23324 3.06367 4.25 4.2 4.25H7.25V2C7.25 1.58579 7.58579 1.25 8 1.25ZM15.25 5.75V6.5C15.25 6.91421 15.5858 7.25 16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5V5.75H19.8C19.9892 5.75 20.25 5.93771 20.25 6.30769V9.75H3.75V6.30769C3.75 5.93771 4.01085 5.75 4.2 5.75H7.25V6.5C7.25 6.91421 7.58579 7.25 8 7.25C8.41421 7.25 8.75 6.91421 8.75 6.5V5.75H15.25ZM20.25 11.25V20.6923C20.25 21.0623 19.9892 21.25 19.8 21.25H4.2C4.01085 21.25 3.75 21.0623 3.75 20.6923V11.25H20.25Z"
        fill={fill ?? theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.75C14.4853 20.75 16.5 18.7353 16.5 16.25C16.5 13.7647 14.4853 11.75 12 11.75C9.51472 11.75 7.5 13.7647 7.5 16.25C7.5 18.7353 9.51472 20.75 12 20.75ZM13.3911 19.0473C14.0426 18.6725 14.5358 18.0738 14.779 17.3626C14.8081 17.2775 14.7751 17.1835 14.6991 17.1353C14.6232 17.0871 14.5241 17.0973 14.4595 17.1599C14.2327 17.3794 13.9589 17.5444 13.6589 17.6423C13.3588 17.7402 13.0404 17.7683 12.7278 17.7246C12.4152 17.6809 12.1167 17.5665 11.855 17.3901C11.5933 17.2137 11.3752 16.98 11.2174 16.7066C11.0596 16.4333 10.9662 16.1276 10.9443 15.8127C10.9224 15.4978 10.9725 15.1821 11.091 14.8896C11.2094 14.597 11.393 14.3353 11.6278 14.1244C11.8626 13.9135 12.1424 13.7588 12.4459 13.6723C12.5325 13.6476 12.5908 13.5668 12.587 13.4768C12.5831 13.3869 12.5181 13.3113 12.4298 13.2941C11.9361 13.1979 11.4264 13.2227 10.9444 13.3664C10.4624 13.5101 10.0224 13.7684 9.66204 14.1193C9.30164 14.4701 9.0316 14.903 8.875 15.381C8.7184 15.859 8.67991 16.3678 8.76282 16.8639C8.84573 17.3599 9.04758 17.8286 9.35109 18.2296C9.6546 18.6307 10.0507 18.9523 10.5056 19.1669C10.9605 19.3814 11.4606 19.4826 11.9632 19.4618C12.4657 19.441 12.9556 19.2987 13.3911 19.0473Z"
        fill={fillContent ?? theme.palette.secondary.main}
      />
    </Svg>
  )
}

export default CalendarAbsenceM
