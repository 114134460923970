import theme from 'utils/theme'

export const classes = {
  sideMenu: {
    width: '320px',
    borderRadius: `${theme.spacing(0.5)} 0 0 0`,
  },
  menuContainer: {
    paddingTop: `calc(${theme.spacing(3)} + var(--ion-safe-area-top))`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  caption: {
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  sideMenuButton: {
    // @ts-ignore
    color: theme.palette.primary[700],
    marginTop: theme.spacing(1),
  },
  sideMenuButtonIcon: {
    marginRight: theme.spacing(1.25),
  },
  sideMenuButtonText: {
    textAlign: 'left',
    fontWeight: 600,
  },
}
