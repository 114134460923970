import { useState } from 'react'
import { fullPersonsQuery } from 'shared/queries'
import { PersonT } from 'types'
import { personIsInactive } from 'utils/person'
import { useJamesApolloQuery } from './useJamesApolloQuery'

interface IPersonsOfUserProps {
  hideInactive?: boolean
  skip?: boolean
}

const usePersonsOfUser = ({ hideInactive = false, skip = false }: IPersonsOfUserProps = {}) => {
  const [persons, setPersons] = useState<PersonT[] | undefined>()

  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(fullPersonsQuery, {
    skip: skip,
    onCompleted(data) {
      const userPersons = data.findAllPersons.filter((person) => !hideInactive || !personIsInactive(person))
      setPersons(userPersons)
    },
  })

  return { persons, loading }
}

export default usePersonsOfUser
