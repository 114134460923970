import theme from 'utils/theme'

export const classes = {
  card: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.625),
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
  },
  title: {
    display: 'flex',
    marginBottom: '6px',
  },
  icon: {
    position: 'relative',
    top: '2px',
    paddingRight: theme.spacing(0.5),
  },
  titleText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grey[800],
  },
  bodyText: {
    color: theme.palette.grey[800],
    lineHeight: '15px',
    fontWeight: 350,
  },
  sendButton: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: '12px',
  },
  sendButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: '2px',
  },
}
