import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const SecurityXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M25.1 6.2h.2L39 12c1.3.5 2.1 1.7 2.1 3.1 0 12.3-6 20.3-11.5 24.3l-.5.4-.4.3-.5.3-.4.2-.5.3-.4.2-.5.3-.4.2h-.2l-.4.2c-.8.4-1.8.4-2.6 0C15 38.6 7 29 7 15c0-1.3.7-2.5 2-3l.1-.1 13.6-5.6c.8-.4 1.6-.4 2.4-.1zm-.8 33.2C30.4 37 35.8 30.6 37.7 22H24v17.5h.3zm-.5-30.9h-.1L10 14.3c-.4.1-.6.5-.6.8 0 2.4.3 4.8.8 7H24V8.5h-.2z"
      />
    </Svg>
  )
}

export default SecurityXL
