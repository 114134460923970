/* eslint-disable camelcase */
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { tasksPaginatedQuery } from 'pages/Tasks/queries'
import { ShiftUpdateTaskT, TasksPaginated, TaskT } from 'pages/Tasks/types'

interface IUseShiftUpdateParams {
  taskIds: number[]
}

const useShiftUpdates = ({ taskIds }: IUseShiftUpdateParams) => {
  const { data, loading } = useJamesApolloQuery<{ tasksPaginated: TasksPaginated }>(tasksPaginatedQuery, {
    skip: taskIds.length === 0,
    variables: {
      filters: { task_ids: taskIds },
      office: false,
    },
    fetchPolicy: 'no-cache',
  })

  return { tasks: (data?.tasksPaginated.list ?? []) as TaskT<ShiftUpdateTaskT>[], loading }
}

export default useShiftUpdates
