import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledDeniedM } from 'icons'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

export const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice ? `absenceRequest.absenceRejected.tooltipManager` : `absenceRequest.absenceRejected.tooltipFrontliner`
}

const AbsenceRejected: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t('timeline.badge.absenceRejected')}
      sx={getClasses(withTooltip)}
      icon={<FilledDeniedM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="task-status_absence-rejected"
    />
  )
}

export default AbsenceRejected
