import { createContext, useContext, Context } from 'react'
import { SideMenuContextT } from './types'

const initialState: SideMenuContextT = {
  teams: [],
  publicTeams: [],
  selectedTeams: [],
  setSelectedTeams: () => {},
  selectedPublicTeams: [],
  setSelectedPublicTeams: () => {},
  showTopCalendarShifts: true,
  setShowTopCalendarShifts: () => {},
  isMultiTenant: false,
  costCenters: [],
  setCostCenters: () => {},
  loading: false,
}

export const SideMenuContext: Context<SideMenuContextT> = createContext(initialState)

export const useSideMenuContext = () => {
  return useContext(SideMenuContext)
}

export default useSideMenuContext
