import theme from 'utils/theme'

export const classes = {
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  smsCodeInput: {
    marginTop: theme.spacing(3),
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontSize: '33px',
      textAlign: 'center',
      width: '279px',
    },
  },
  textContainerSuccess: {
    marginTop: theme.spacing(4.25),
  },
}
