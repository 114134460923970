import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { TeamsM, TenantsM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'

const CompanySettings: React.FC = () => {
  const { t } = useFavurTranslation()
  const { canManageTenants, canManageTeams } = useRolesViews()
  const history = useHistory()
  return (
    <UserSettingsSection
      title={t('page.userSettings.companySettings.title')}
      subtitle={t('page.userSettings.companySettings.subtitle')}
    >
      {canManageTenants && (
        <UserSettingsSectionCTA
          title={t('page.userSettings.tenants.title')}
          subtitle={t('page.userSettings.tenants.subtitle')}
          onClick={() => {
            history.push(routes.tenants)
          }}
          icon={<TenantsM fill={palette.primary.main} />}
        />
      )}
      {canManageTeams && (
        <UserSettingsSectionCTA
          title={t('page.userSettings.teams.title')}
          subtitle={t('page.userSettings.teams.subtitle')}
          onClick={() => {
            history.push(routes.teams)
          }}
          icon={<TeamsM fill={palette.primary.main} />}
        />
      )}
    </UserSettingsSection>
  )
}

export default CompanySettings
