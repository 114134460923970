import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface IHowToDialogProps {
  onClose: () => void
}

const HowToDialog: React.FC<IHowToDialogProps> = ({ onClose }) => {
  const { t } = useFavurTranslation()

  return (
    <Dialog
      open
      onClose={onClose}
      title={t('tasks.shareUserData.office.open.dialog.title')}
      actions={
        <>
          <Button onClick={onClose} color="secondary" size="large">
            {t('tasks.shareUserData.office.open.dialog.close')}
          </Button>
        </>
      }
    >
      <Typography variant="body2" sx={classes.confirmDialogTypography}>
        {t('tasks.shareUserData.office.open.dialog.content')}
      </Typography>
    </Dialog>
  )
}

export default HowToDialog
