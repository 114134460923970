import { AuthenticationKind } from 'constants/authentication'
import { INVITATION_STATUSES, INVITATION_CAN_ACCEPT_FROM } from 'constants/invitation'
import { InvitationT } from 'types'

export enum InvitationRedirect {
  REDIRECT_HOME,
  INVITATION_VALID,
  REDIRECT_SUCCESS,
}

export const getInvitationRedirect = (
  auth: AuthenticationKind,
  invitation: InvitationT | undefined,
  userPhone: string | undefined,
) => {
  if (
    (auth === AuthenticationKind.ANONYMOUS && invitation?.userId) ||
    (auth === AuthenticationKind.AUTHENTICATED && invitation?.phoneNumber !== userPhone)
  ) {
    return InvitationRedirect.REDIRECT_HOME
  }

  if (INVITATION_CAN_ACCEPT_FROM.includes(invitation?.status ?? -1)) {
    return InvitationRedirect.INVITATION_VALID
  }
  if (invitation?.status === INVITATION_STATUSES.success) {
    return InvitationRedirect.REDIRECT_SUCCESS
  }
  return InvitationRedirect.REDIRECT_HOME
}
