import theme from 'utils/theme'

export const checkboxClasses = {
  disabled: {
    '& .MuiIconButton-label': {
      color: `${theme.palette.text.disabled} !important`,
    },
  },
  checkbox: {
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.33)',
    },
    '& .MuiIconButton-label': {
      overflow: 'hidden',
      color: theme.palette.primary.light,
    },
    '& .MuiTouchRipple-root': {
      color: theme.palette.primary.main,
    },
    '&.Mui-checked': {
      '& .MuiIconButton-label': {
        borderRadius: '3px',
      },
    },
  },
}
