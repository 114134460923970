import React from 'react'
import Box from '@mui/material/Box'

export const StatusBallInactive: React.FC = () => {
  return (
    <Box
      sx={{
        width: '8px',
        height: '8px',
        marginLeft: '3px',
        marginRight: '3px',
        backgroundColor: '#666666',
        borderRadius: '50%',
      }}
    ></Box>
  )
}

export const StatusBallHighlight: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
      <g fill="#FF007C" filter="url(#a)">
        <circle cx="15" cy="15" r="4" />
        <circle cx="15" cy="15" r="6" opacity=".08" />
        <circle cx="15" cy="15" r="7" opacity=".02" />
      </g>
      <defs>
        <filter id="a" width="30" height="30" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.486275 0 0 0 0.48 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_367_15581" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_367_15581" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export const StatusBallActive: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
      <circle cx="7" cy="7" r="4" fill="#FF007C" />
      <circle cx="7" cy="7" r="6" fill="#FF007C" opacity=".08" />
      <circle cx="7" cy="7" r="7" fill="#FF007C" opacity=".04" />
    </svg>
  )
}
