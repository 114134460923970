import { useEffect, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useSessionContext from 'hooks/useSessionContext'
import { ownerPersonsQuery, tenantPersonsQuery } from 'pages/Teams/queries'
import { useHistory } from 'react-router-dom'
import { PersonStatusType } from 'shared/constants'
import { OwnerT, PersonT, TeamT, TenantT } from 'types'

export const useEditTeamMembers = (team: TeamT | undefined): { loading: boolean; members: PersonT[] } => {
  const [members, setMembers] = useState<PersonT[]>([])

  const ownerId = team?.ownerId
  const tenantId = team?.tenantId

  const history = useHistory()
  const { refresh } = useSessionContext()
  const { data: dataOwner, loading: loadingOwner } = useJamesApolloQuery<{
    getOwner: OwnerT
  }>(ownerPersonsQuery, {
    fetchPolicy: 'no-cache',
    skip: !ownerId || Boolean(tenantId),
    variables: {
      ownerId,
      notStatusIn: [PersonStatusType.inactive],
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          if (message === 'not logged in') {
            refresh()
            history.go(0)
          }
        })
      }
    },
  })

  const { data: dataTenant, loading: loadingTenant } = useJamesApolloQuery<{
    getTenant: TenantT
  }>(tenantPersonsQuery, {
    fetchPolicy: 'no-cache',
    skip: !tenantId,
    variables: {
      tenantId,
      notStatusIn: [PersonStatusType.inactive],
    },
  })

  const loading = loadingOwner || loadingTenant

  useEffect(() => {
    if (loading) return
    if (tenantId && dataTenant?.getTenant.persons) {
      setMembers(dataTenant?.getTenant.persons)
    } else if (dataOwner?.getOwner?.persons) {
      setMembers(dataOwner?.getOwner?.persons)
    }
  }, [tenantId, dataOwner, dataTenant, loading])

  return {
    loading,
    members,
  }
}
