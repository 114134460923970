import theme from 'utils/theme'

export const pinEnterClasses = {
  pinDisplayContainer: {
    paddingTop: theme.spacing(7),
  },
  changePinLengthButton: {
    marginTop: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  numpadContainer: {
    marginBottom: theme.spacing(3.5),
  },
}
