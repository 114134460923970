import theme from 'utils/theme'

export const classes = {
  cardWrapper: {
    padding: '0px',
    minWidth: 'calc(50% - 10px)',
    maxWidth: 'calc(50% - 10px)',
  },
  button: {
    display: 'flex',
    padding: '0px',
    width: '100%',
    justifyContent: 'flex-start',

    gap: '8px',
    '&:last-of-type': {
      // Reintroduce spacing to the right of the last element to align with container right padding
      marginRight: '12px',
    },
    '&:hover, &:focus, &:hover:before': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  carousel: {
    display: 'flex',
    gap: '8px',
    // Adding padding of the main container to have overflow effect while keeping scrolling
    width: 'calc(100% + 12px)',
    overflowX: 'scroll',
    padding: '4px',
    transform: 'translate(-4px, -4px)',
  },
  iconBox: {
    minWidth: '24px',
  },
  textContainer: {
    justifyContent: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'none',
  },
  fullWidth: {
    minWidth: '100%',
    maxWidth: '100%',
  },
}
