import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
}
