import React, { useCallback } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import type { NotificationT, OfficeMonthlySheetApprovedNotificationT } from 'types'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import NotificationTemplate from '../Templates/Notification'

interface IOfficeMonthlySheetApprovedNotification {
  notification: OfficeMonthlySheetApprovedNotificationT
  dismiss: (notification: NotificationT) => void
}

const OfficeMonthlySheetApprovedNotification: React.FC<IOfficeMonthlySheetApprovedNotification> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { notification, dismiss } = props
  const handleRedirect = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.monthlySheets}/${notification.data.taskUuid}`, ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.new.timeapproval.accepted.title', {
      frontlinerNameShort: notification.data.userNameShort ?? getShortNameFromName(notification.data.userName),
    }),
    body: t('notification.new.timeapproval.accepted.body', {
      dateFrom: getFormattedFromISOString(notification.data.dateFrom, uiDateFormat, undefined),
      dateTo: getFormattedFromISOString(notification.data.dateTo, uiDateFormat, undefined),
    }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default OfficeMonthlySheetApprovedNotification
