import React from 'react'
import Card from 'components/Basics/Card'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import { SettingsPushNotificationsOptionsT } from 'types'
import { SwitchStatesT } from '../../types'
import NotificationSwitch from '../NotificationSwitch'

interface INotificationsSettingsSectionProps {
  title: string
  settings: SettingsPushNotificationsOptionsT[]
  handlePushChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  pushSwitchStates: SwitchStatesT
  handleInAppChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  inAppSwitchStates?: SwitchStatesT
  changeInApp?: boolean
}

const NotificationsSettingsSection: React.FC<INotificationsSettingsSectionProps> = ({ title, settings, ...props }) => {
  return (
    <UserSettingsSection title={title}>
      {settings.map((setting) => (
        <Card key={setting}>
          <NotificationSwitch setting={setting} {...props} />
        </Card>
      ))}
    </UserSettingsSection>
  )
}

export default NotificationsSettingsSection
