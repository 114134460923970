import palette from '../palette'

export default {
  styleOverrides: {
    icon: {
      color: palette.secondary.main,
      top: 8,
      right: 12,
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
}
