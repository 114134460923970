import { gql } from '@apollo/client'

export const createAbsenceRequestMutation = gql`
  mutation(
    $comment: String!
    $data: NewTaskWorkflowAbsences!
    $personUuid: String!
    $taskType: String!
    $triggeredByPersonUuid: String!
  ) {
    createAbsence(
      comment: $comment
      data: $data
      personUuid: $personUuid
      taskType: $taskType
      triggeredByPersonUuid: $triggeredByPersonUuid
    ) {
      success
      taskUuid
    }
  }
`
