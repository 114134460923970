import theme from 'utils/theme'

export const classes = {
  card: {
    display: 'grid',
    width: '100%',
    cursor: 'pointer',
  },
  dayOfWeek: {
    marginBottom: theme.spacing(0.25),
  },
  dayOfMonth: {
    marginBottom: theme.spacing(0.5),
  },
  tenantName: {
    marginBottom: theme.spacing(0.25),
  },
  badgesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tenantAndShiftsBox: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  tenantAndShiftsBoxList: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  noData: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.grey[600],
    '& > svg': {
      minWidth: '24px',
    },
  },
  noDataTextContainer: {
    display: 'flex',
  },
  noDataText: {
    marginLeft: theme.spacing(0.25),
  },
  footnote: {
    marginTop: theme.spacing(0.25),
  },
  hiddenShift: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(0.25),
  },
  unacknowledgedChip: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    height: '24px',
    marginRight: '4px',
    marginBottom: '4px',
    cursor: 'pointer',
  },
  hiddenShiftContainer: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
  },
  badge: {
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },
  eventCountContainer: {
    marginLeft: '12px',
    marginTop: '8px',
  },
}
