import React, { useCallback, useContext, useMemo } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PlusContext } from 'pages/Tasks/components/ActionButtons/Plus'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import useAbsenceRequestContext from '../../AbsenceRequestContext'
import { classes } from './styles'

interface IShowAbsencePlanProps {
  task: TaskT<AbsenceRequestTaskT>
}

const ShowAbsencePlan: React.FC<IShowAbsencePlanProps> = ({ task }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setShowAbsencePlannerDrawer } = useAbsenceRequestContext()
  const { setIsOpenDrawer } = useContext(PlusContext)
  const { startDate, endDate } = useMemo(
    () => ({
      startDate: task.taskData.dateFrom,
      endDate: task.taskData.dateTo,
    }),
    [task.taskData.dateFrom, task.taskData.dateTo],
  )

  const onClick = useCallback(() => {
    if (isNative()) {
      setIsOpenDrawer(false)
      setShowAbsencePlannerDrawer(true)
    } else {
      history.push(`${routes.absencePlanner}?startDate=${startDate}&endDate=${endDate}`)
    }
  }, [endDate, history, setIsOpenDrawer, setShowAbsencePlannerDrawer, startDate])

  return (
    <>
      <Button variant="contained" sx={classes.regularButton} onClick={onClick}>
        {t('absenceRequest.buttons.showAbsencePlan')}
      </Button>
    </>
  )
}

export default ShowAbsencePlan
