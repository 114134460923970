import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledCheckmarkM } from 'icons'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

export const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice ? `absenceRequest.absenceAccepted.tooltipManager` : `absenceRequest.absenceAccepted.tooltipFrontliner`
}

const AbsenceAccepted: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t('timeline.badge.absenceAccepted')}
      sx={getClasses(withTooltip)}
      icon={<FilledCheckmarkM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="task-status_absence-accepted"
    />
  )
}

export default AbsenceAccepted
