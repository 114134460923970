import React, { useMemo } from 'react'
import * as Icons from 'icons'
import { ICON_SIZE_XXL } from 'icons/constants'
import { IconContentBlockT } from 'types'
import palette from 'utils/theme/palette'

interface IIconBlockProps {
  data: IconContentBlockT
}

const IconBlock: React.FC<IIconBlockProps> = ({ data }) => {
  const Icon = useMemo(() => Icons[data.variant as keyof typeof Icons] || Icons.Announcement, [data.variant])

  return Icon && <Icon size={ICON_SIZE_XXL} fill={palette.primary.main} stroke={palette.primary.main} />
}

export default IconBlock
