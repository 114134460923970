import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const LogoutXL: React.FC<IconPropsT> = ({ stroke = theme.palette.common.white }) => {
  return (
    <Svg size={ICON_SIZE_XL} viewBox="0 0 32 32" fill="none">
      <path
        d="M12 16h14M23 20l4-4-4-4M18.999 20.941v4.614c.016.389-.11.769-.348 1.057-.238.288-.571.461-.925.48H6.273a1.296 1.296 0 0 1-.924-.48A1.563 1.563 0 0 1 5 25.555V7.1c-.016-.389.109-.77.347-1.057.239-.289.571-.461.925-.48h11.453c.354.019.687.191.925.48.239.288.364.668.348 1.057V11"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default LogoutXL
