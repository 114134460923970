import React from 'react'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { headerClasses } from 'pages/Tasks/styles'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import AcceptContent from './AcceptContent'

interface IAcceptProps {
  task: TaskT<AbsenceRequestTaskT>
  onClickBackIcon: () => void
  onAccept: () => void
}

const Accept: React.FC<IAcceptProps> = ({ onClickBackIcon, task, onAccept }) => {
  const { t } = useFavurTranslation()

  return (
    <Page
      header={<BackwardLink onClickIcon={onClickBackIcon} title={t('absenceRequest.accept.title')} />}
      hideNativeNavigation
      hasFloatingButtons
      hideHamburger
      hideBackground
      removeLastMargin
      showHeader={false}
      sxHeader={headerClasses.subHeader}
    >
      <AcceptContent task={task} onAccept={onAccept} fullScreen />
    </Page>
  )
}

export default Accept
