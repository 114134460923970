import React, { useState } from 'react'
import { Button, Collapse, Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowDownS, ArrowUpS } from 'icons'
import CommentSection from './CommentSection'
import { classes } from './styles'

interface ICollapsibleCommentSectionProps {
  comment: string
  disabled?: boolean
  skipTitle?: boolean
}

const CollapsibleCommentSection: React.FC<ICollapsibleCommentSectionProps> = ({
  comment,
  disabled = false,
  skipTitle = false,
}) => {
  const { t } = useFavurTranslation()
  const [openMoreDetails, setOpenMoreDetails] = useState(false)

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => setOpenMoreDetails(!openMoreDetails)}
        sx={classes.openMoreDetailsButton}
        disableFocusRipple
        disableRipple
      >
        {openMoreDetails ? (
          <>
            {t('page.shifts.shiftCard.lessDetails')}
            <Box sx={classes.arrowIconContainer}>
              <ArrowUpS />
            </Box>
          </>
        ) : (
          <>
            {t('page.shifts.shiftCard.moreDetails')}
            <Box sx={classes.arrowIconContainer}>
              <ArrowDownS />
            </Box>
          </>
        )}
      </Button>
      <Collapse in={openMoreDetails}>
        <CommentSection comment={comment} disabled={disabled} skipTitle={skipTitle} />
      </Collapse>
    </>
  )
}

export default CollapsibleCommentSection
