import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledCallM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        d="M10.39 17.53a2.941 2.941 0 0 1-3.691-.406l-.415-.406a.996.996 0 0 1 0-1.393l1.761-1.743a.987.987 0 0 1 1.384 0 .996.996 0 0 0 1.393 0l2.767-2.767a.98.98 0 0 0 0-1.393.987.987 0 0 1 0-1.384l1.754-1.754a.996.996 0 0 1 1.393 0l.406.415a2.943 2.943 0 0 1 .406 3.69 26.697 26.697 0 0 1-7.159 7.14Z"
      />
    </Svg>
  )
}

export default FilledCallM
