import theme from 'utils/theme'

export const classes = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    gap: '12px',
    alignItems: 'start',
  },
  clickable: {
    cursor: 'pointer',
  },
  dateText: {
    color: theme.palette.grey[600],
    marginTop: '8px',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  invitationText: {
    color: theme.palette.grey[600],
  },
}
