import theme from 'utils/theme'

export const tokenClasses = {
  token: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    width: theme.spacing(10),
    height: theme.spacing(2.5625),
  },
  cancelButton: {
    fontSize: theme.spacing(0.875),
    padding: theme.spacing(0, 0.75),
    paddingTop: '9px',
    paddingBottom: '9px',
  },
}

export const inputClasses = {
  input: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.0625),
    textAlign: 'center',
    height: theme.spacing(2.5625),
    padding: 0,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
}
