import React, { memo, useCallback } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { TeamT } from 'types'
import TeamNameRow from '../TeamNameRow'
import { CELL_HEIGHT } from '../constants'
import { teamNameRowsClasses } from './styles'

interface ITeamNameRowsProps {
  teams: TeamT[]
  teamsLoading: boolean
  personIds: number[] | undefined
  isMultiTenant?: boolean
}

const TeamNameRows: React.FC<ITeamNameRowsProps> = ({ teams, teamsLoading, personIds, isMultiTenant }) => {
  const { avatarBarExpanded } = useAvatarSidebarStateContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()

  const getTopPosition = useCallback(
    (teamI: number) => {
      const totalPersons = teams
        .slice(0, teamI)
        .flatMap((team) =>
          (team.teamPermissions ?? []).filter(
            (person) => !(personIds ?? []).includes(parseInt(`${person?.person?.id}`)),
          ),
        ).length

      return (totalPersons + 1) * CELL_HEIGHT
    },
    [personIds, teams],
  )

  if (teamsLoading) {
    return (
      <Box sx={teamNameRowsClasses.outerContainer(avatarBarExpanded, isSmallScreen)}>
        <Box sx={teamNameRowsClasses.innerContainer(CELL_HEIGHT)} key="placeholder-team_name-row">
          <TeamNameRow teamsLoading={teamsLoading} isSmallScreen={isSmallScreen} isMultiTenant={isMultiTenant} />
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={teamNameRowsClasses.outerContainer(avatarBarExpanded, isSmallScreen)}>
      {teams.map((team, teamI) => (
        <Box sx={teamNameRowsClasses.innerContainer(getTopPosition(teamI))} key={`${team.id}_name-row`}>
          <TeamNameRow
            team={team}
            teamsLoading={teamsLoading}
            isSmallScreen={isSmallScreen}
            isMultiTenant={isMultiTenant}
          />
        </Box>
      ))}
    </Box>
  )
}

export default memo(TeamNameRows)
