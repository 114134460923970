import React from 'react'
import { SxProps } from '@mui/material'
import Svg from 'components/Svg'

const Cross: React.FC<{ sx?: SxProps }> = ({ sx }) => {
  return (
    <Svg viewBox="0 0 80 80" size={80} data-testid="logo" sx={sx}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.768 14.268l18.23 18.23 18.234-18.23c.901-.901 2.32-.97 3.3-.208l.236.208 3.964 3.964c.901.901.97 2.32.208 3.3l-.208.236-18.234 18.23 18.234 18.234c.977.977.977 2.56 0 3.536l-3.964 3.964c-.977.977-2.56.977-3.536 0L39.998 47.498l-18.23 18.234c-.901.901-2.32.97-3.3.208l-.236-.208-3.964-3.964c-.901-.901-.97-2.32-.208-3.3l.208-.236 18.23-18.234-18.23-18.23c-.977-.977-.977-2.56 0-3.536l3.964-3.964c.977-.977 2.56-.977 3.536 0z"
      />
    </Svg>
  )
}

export default Cross
