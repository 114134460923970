import { StateT, StateValueT } from './types'

export type ActionT = {
  type: 'clear' | 'clearAll' | 'set'
  payload?: { key: string; value?: StateValueT }
}

export const reducer = (state: StateT, action: ActionT) => {
  switch (action.type) {
    case 'clear': {
      const name = action.payload?.key as string
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { [name]: _attrValue, ...otherFields } = state
      return otherFields
    }
    case 'clearAll': {
      return {}
    }
    case 'set': {
      const key = action.payload?.key as string
      const value = action.payload?.value as StateValueT
      return { ...state, [key]: value }
    }
    default:
      throw new Error()
  }
}
