import theme from 'utils/theme'

export const classes = {
  tilesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
  },
}
