import theme from 'utils/theme'

export const classes = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    lineHeight: '25px',
  },
}
