// TBD migrate to import NotificationTypes from 'types'
export const notificationTypes = {
  absenceRequestCommentForFrontliner: 'absence_request_comment_for_frontliner',
  absenceRequestCommentForManager: 'absence_request_comment_for_manager',
  absenceRequestRequestDeletion: 'absence_request_request_deletion',
  document: 'document',
  documentMonthlySheetApproval: 'document_monthly_sheet_approval',
  invitation: 'invitation',
  linkMessage: 'link_message',
  monthlySheetCommentForFrontliner: 'monthly_sheet_comment_for_frontliner',
  monthlySheetCommentForManager: 'monthly_sheet_comment_for_manager',
  officeAbsenceRequest: 'office_absence_request',
  officeMonthlySheetApproved: 'office_monthly_sheet_approved',
  receivedUserData: 'received_user_data',
  reminder: 'reminder',
  reminderAbsenceRequestManualForManager: 'reminder_absence_request_manual_for_manager',
  reminderMonthlySheetManual: 'reminder_monthly_sheet_manual',
  reminderMonthlySheetManualForManager: 'reminder_monthly_sheet_manual_for_manager',
  reminderSecureConnection: 'reminder_secure_connection',
  reminderSecureConnectionManualForFrontliner: 'reminder_secure_connection_manual_for_frontliner',
  reminderShareUserData: 'reminder_share_user_data',
  reminderShareUserDataManual: 'reminder_share_user_data_manual',
  reminderShareUserDataManualForManager: 'reminder_share_user_data_manual_for_manager',
  reminderShiftUpdate: 'reminder_shift_update',
  reminderShiftUpdateManual: 'reminder_shift_update_manual',
  resolvedAbsenceRequest: 'resolved_absence_request',
  resolvedAbsenceRequestRequestDeletion: 'resolved_absence_request_request_deletion',
  secureConnection: 'secure_connection',
  secureConnectionCommentForFrontliner: 'secure_connection_comment_for_frontliner',
  secureConnectionCommentForManager: 'secure_connection_comment_for_manager',
  secureConnectionCompleted: 'secure_connection_completed',
  shareUserData: 'share_user_data',
  shareUserDataAccepted: 'share_user_data_accepted',
  shareUserDataCommentForFrontliner: 'share_user_data_comment_for_frontliner',
  shareUserDataCommentForManager: 'share_user_data_comment_for_manager',
  shift: 'shift',
  shiftInserted: 'shift_inserted',
  shiftUpdateSeen: 'shift_update_seen',
  shiftUpdated: 'shift_updated',
  cmsNewContent: 'cms_new_content',
  cmsUpdateContent: 'cms_update_content',
}

export const frontlinerNotificationTypes = [
  notificationTypes.absenceRequestCommentForFrontliner,
  notificationTypes.resolvedAbsenceRequestRequestDeletion,
  notificationTypes.document,
  notificationTypes.invitation,
  notificationTypes.linkMessage,
  notificationTypes.monthlySheetCommentForFrontliner,
  notificationTypes.reminder,
  notificationTypes.reminderShiftUpdate,
  notificationTypes.reminderShiftUpdateManual,
  notificationTypes.reminderShareUserDataManual,
  notificationTypes.reminderMonthlySheetManual,
  notificationTypes.resolvedAbsenceRequest,
  notificationTypes.shareUserData,
  notificationTypes.shareUserDataAccepted,
  notificationTypes.shift,
  notificationTypes.cmsNewContent,
  notificationTypes.cmsUpdateContent,
  notificationTypes.secureConnection,
  notificationTypes.secureConnectionCommentForFrontliner,
  notificationTypes.reminderSecureConnection,
  notificationTypes.reminderSecureConnectionManualForFrontliner,
]
export const officeViewNotificationTypes = [
  notificationTypes.absenceRequestCommentForManager,
  notificationTypes.absenceRequestRequestDeletion,
  notificationTypes.monthlySheetCommentForManager,
  notificationTypes.officeAbsenceRequest,
  notificationTypes.officeMonthlySheetApproved,
  notificationTypes.receivedUserData,
  notificationTypes.reminderAbsenceRequestManualForManager,
  notificationTypes.reminderMonthlySheetManualForManager,
  notificationTypes.shiftUpdateSeen,
  notificationTypes.secureConnectionCommentForManager,
  notificationTypes.secureConnectionCompleted,
]
