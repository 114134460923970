import theme from 'utils/theme'
import utilsTheme from 'utils/theme'

export const classes = {
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& div:last-child': {
      marginBottom: 0,
    },
  },
  badgeLine: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(0.75),
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typeLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  commentTitle: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.5),
  },
  commentContainer: {
    borderRadius: '6px',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0.5),
  },
  openMoreDetailsButton: {
    justifyContent: 'flex-start',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.7px',
    paddingLeft: 0,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    marginBottom: theme.spacing(0.75),
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  arrowIconContainer: { marginLeft: theme.spacing(0.25) },
  title: { marginTop: theme.spacing(0.75) },
  noEffectiveTimes: { marginTop: theme.spacing(0.5) },
  effectiveList: {
    marginTop: theme.spacing(-1.125),
  },
  validatedText: {
    minHeight: '18px',
    lineHeight: '18px',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
  validatedColor: {
    color: theme.palette.success.main,
  },
  pendingColor: {
    color: theme.palette.grey[600],
  },
  disabledText: {
    color: theme.palette.text.secondary,
  },
  hiddenShiftLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: '22px',
  },

  hiddenShiftContainer: {
    marginTop: theme.spacing(-1.5),
  },
  unacknowledgedTitle: {
    fontWeight: 600,
  },
  ctaBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  showChangesButton: {
    textAlign: 'right',
    paddingRight: 0,
    fontSize: '12px',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  oldBadgeLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.75),
    alignItems: 'center',
  },
  openOldShiftsButton: {
    marginBottom: 0,
  },
  effectiveTimeErrorText: {
    paddingTop: theme.spacing(0.5),
  },
  unacknowledgedChip: {
    // @ts-ignore
    background: utilsTheme.palette.primary[25],
    height: '24px',
    cursor: 'pointer',
  },
  hiddenShiftChipContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    cursor: 'pointer',
  },
}
