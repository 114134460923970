import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const AbsenceHalfMorningS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path fillRule="evenodd" d="M8 12a4 4 0 0 1 0-8v8Z" fill="#B2B2B2" />
      <path d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z" stroke="#666" fill="none" />
    </Svg>
  )
}

export default AbsenceHalfMorningS
