import React, { useCallback, useContext, useState } from 'react'
import { PlusContext } from 'pages/Tasks/components/ActionButtons/Plus'
import SendComment from 'pages/Tasks/components/ActionButtons/SendComment'
import { EventsOfTask } from 'pages/Tasks/types'
import SuccessDrawer from '../SendComment/SuccessDrawer'

interface IAddCommentProps {
  taskUuid: string
  event: EventsOfTask.shareUserDataSendCommentToManager | EventsOfTask.shareUserDataSendCommentToFrontline
  onAction?: () => void
  showSuccessDrawer?: boolean
}

const AddComment: React.FC<IAddCommentProps> = ({ event, taskUuid, onAction, showSuccessDrawer = false }) => {
  const { setIsOpenDrawer: setPlusDrawerOpen } = useContext(PlusContext)

  const [openSuccessDrawer, setOpenSuccessDrawer] = useState(false)

  const onCompleted = useCallback(() => {
    setOpenSuccessDrawer(false)
    setPlusDrawerOpen && setPlusDrawerOpen(false)
    onAction && onAction()
  }, [onAction, setPlusDrawerOpen])

  return (
    <>
      <SendComment
        event={event}
        taskUuid={taskUuid}
        onAction={() => (showSuccessDrawer ? setOpenSuccessDrawer(true) : onCompleted())}
      />
      <SuccessDrawer open={openSuccessDrawer} onClose={onCompleted} />
    </>
  )
}

export default AddComment
