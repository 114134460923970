export const classes = {
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '598px',
    gap: '12px',
    marginTop: '16px',
    marginLeft: '12px',
  },
  container: {
    padding: '0 6px',
    marginRight: '12px',
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '229px',
  },
  table: {
    marginTop: '16px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
    paddingRight: '16px',
  },
  rowHeader: {
    marginBottom: '26px',
  },
  column: {
    flex: 1,
  },
  columnEndOfYear: {
    maxWidth: '100px',
  },
  titleText: {
    width: '100%',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
  },
  subtitleText: {
    marginTop: '8px',
    lineHeight: '22px',
  },
}
