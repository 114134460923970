import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

const CommentSection: React.FC<{ comment: string; disabled?: boolean; skipTitle?: boolean }> = ({
  comment,
  disabled = false,
  skipTitle = false,
}) => {
  const { t } = useFavurTranslation()
  return (
    <>
      {!skipTitle && (
        <Box sx={classes.commentTitle}>
          <Typography variant="subtitle2" sx={disabled ? classes.disabledText : undefined}>
            {t('page.shifts.shiftCard.comment')}
          </Typography>
        </Box>
      )}
      <Box sx={[classes.commentContainer, ...(disabled ? [classes.disabledText] : [])]}>
        <Typography variant="caption">{comment}</Typography>
      </Box>
    </>
  )
}

export default CommentSection
