import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const SecureConnection: React.FC<IconPropsT> = ({ stroke, size = ICON_SIZE_M }) => {
  return (
    <Svg size={size} stroke={stroke} fill="none" viewBox="0 0 24 24">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.25 3.923v7.614A11.907 11.907 0 0 0 9.882 22.65l1.041.4a3 3 0 0 0 2.154 0l1.041-.4a11.907 11.907 0 0 0 7.632-11.113V3.923a1.488 1.488 0 0 0-.868-1.362A21.7 21.7 0 0 0 12 .75a21.7 21.7 0 0 0-8.882 1.811 1.487 1.487 0 0 0-.868 1.362Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 9.75H9a1.5 1.5 0 0 0-1.5 1.5v4.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5ZM12 5.25a3 3 0 0 0-3 3v1.5h6v-1.5a3 3 0 0 0-3-3Z"
      />
      <path strokeWidth="1.5" d="M12 13.904a.375.375 0 0 1 0-.75M12 13.904a.375.375 0 0 0 0-.75" />
    </Svg>
  )
}

export default SecureConnection
