import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import OwnerFilter from './ownerFilter'
import { headerClasses } from './styles'

const AbsencePlannerPageHeader: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={headerClasses.absencePlannerHeader}>
      <Typography sx={headerClasses.absencePlannerHeaderTitle} variant="h2" data-testid="header__title">
        {t('page.absencePlanner.title')}
      </Typography>

      <OwnerFilter />
    </Box>
  )
}

export default AbsencePlannerPageHeader
