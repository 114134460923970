import theme from 'utils/theme'

export const navigationClasses = {
  root: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  item: {
    padding: 0,
    margin: 0,
    height: theme.spacing(2),
    minHeight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: theme.spacing(2),
    minWidth: theme.spacing(2),
    marginRight: theme.spacing(0.75),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    margin: 0,
  },
}
