import React from 'react'
import { Box } from '@mui/material'
import { ArrowRightThinS } from 'icons'
import { successClasses } from './styles'

interface IContentClickProps {
  children: React.ReactNode
  dataTestId: string
  onClick: () => void
}
const ContentLink: React.FC<IContentClickProps> = ({ children, dataTestId, onClick }) => {
  return (
    <Box onClick={onClick} sx={successClasses.contentLinkBox} data-testid={dataTestId}>
      <Box sx={successClasses.linkNameIcon}>{children}</Box>
      <ArrowRightThinS />
    </Box>
  )
}

export default ContentLink
