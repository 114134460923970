import theme from 'utils/theme'

export const pastMonthReminderClasses = {
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
  },
  textRow: {
    display: 'flex',
    gap: '8px',
    marginBottom: '4px',
    alignItems: 'center',
  },
  iconContainer: {
    minWidth: '40px',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainText: {
    lineHeight: 1.5,
    letterSpacing: 0.3,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'end',
  },
}
