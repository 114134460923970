import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { ArrowRightThinS } from 'icons'
import { classes } from './styles'

interface TimelineCTAT {
  title: string
  subtitle?: string
  icon: React.JSX.Element
  onClick?: () => void
}

const TimelineCTA: React.FC<TimelineCTAT> = ({ title, subtitle, icon, onClick }) => {
  return (
    <Card onClick={onClick}>
      <IconButton sx={classes.iconButton} disableRipple>
        <Box sx={classes.iconContainer}>
          <Box sx={classes.icon}>{icon}</Box>
        </Box>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.textContainer}>
            <Typography variant="subtitle1" sx={classes.title}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="caption" sx={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {onClick && (
            <div>
              <ArrowRightThinS />
            </div>
          )}
        </Box>
      </IconButton>
    </Card>
  )
}

export default TimelineCTA
