import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowLeftM } from 'icons'
import { requestEmployeeDataModes, RequestEmployeeDataModeT } from 'pages/RequestEmployeeData/types'
import { headerClasses } from 'pages/Shifts/styles'
import { useHistory } from 'react-router-dom'
import { TenantT } from 'types'
import TenantsList from '../TenantsList'
import { classes } from './styles'

interface IRequestEmployeeDataHeaderProps {
  tenants: TenantT[]
  onChange: (tenantid: string) => void
  mode: RequestEmployeeDataModeT
}

const RequestEmployeeDataHeader: React.FC<IRequestEmployeeDataHeaderProps> = ({ tenants, onChange, mode }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  return (
    <>
      <Box sx={classes.headerContainer}>
        <IconButton sx={classes.backIcon} onClick={() => history.goBack()} size="large">
          <ArrowLeftM />
        </IconButton>
        <Box>
          <Typography variant="overline">{t('requestEmployeeData.common.overTitle')}</Typography>
          <Typography variant="h2">
            {t(
              mode === requestEmployeeDataModes.global
                ? 'requestEmployeeData.global.title'
                : 'requestEmployeeData.single.title',
            )}
          </Typography>
        </Box>
        <Box sx={classes.auxBox} />
      </Box>
      {tenants.length > 1 ? (
        <Box sx={headerClasses.subheaderContainer}>
          <TenantsList tenants={tenants} onChange={onChange} />
        </Box>
      ) : null}
    </>
  )
}

export default RequestEmployeeDataHeader
