import React, { useCallback, useState } from 'react'

export type ScrollStateT = {
  isScrolled: boolean
  isAtBottom: boolean
  hasOverflow: boolean
  checkIsScrolled: () => void
}

const getHasOverflow = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return false
  }

  const container = ref.current as HTMLDivElement
  return container.scrollHeight > container.clientHeight
}

const useScrolledState = (ref: React.RefObject<HTMLDivElement>): ScrollStateT => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isAtBottom, setIsAtBottom] = useState(false)
  const [hasOverflow, setHasOverflow] = useState(getHasOverflow(ref))

  const checkIsScrolled = useCallback(() => {
    if (!ref.current) {
      return
    }

    const container = ref.current as HTMLDivElement
    setHasOverflow(getHasOverflow(ref))
    setIsScrolled(container.scrollTop > 0)
    setIsAtBottom(container.scrollTop + container.clientHeight >= container.scrollHeight)
  }, [ref])

  return { isScrolled, isAtBottom, hasOverflow, checkIsScrolled }
}

export default useScrolledState
