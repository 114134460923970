import { createContext, useContext, Context } from 'react'
import { TeamT } from 'types'
import { ColorModesT, colorModes } from '../components/TaskBar/types'
import { AbsencePlannerBadgeTypesT } from '../types'

export type FilterContextT = {
  isMultiTenant: boolean
  loading: boolean
  teamsLoading: boolean
  ownerMemberTeams: TeamT[]
  ownerPublicTeams: TeamT[]
  shownTypes: AbsencePlannerBadgeTypesT[]
  setShownTypes: (data: AbsencePlannerBadgeTypesT[]) => void
  greyscaleMode: ColorModesT
  setGreyscaleMode: (data: ColorModesT) => void
  shownTeams: string[]
  setShownTeams: (data: string[]) => void
}

const initialState: FilterContextT = {
  isMultiTenant: false,
  loading: true,
  teamsLoading: true,
  ownerMemberTeams: [],
  ownerPublicTeams: [],
  shownTypes: [],
  setShownTypes: () => {},
  greyscaleMode: colorModes.colorful,
  setGreyscaleMode: () => {},
  shownTeams: [],
  setShownTeams: () => {},
}

export const FilterContext: Context<FilterContextT> = createContext(initialState)

export const useFilterContext = () => {
  const context = useContext(FilterContext)

  return { ...context }
}

export default useFilterContext
