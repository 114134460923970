import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const RemoveS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 1.33a6.67 6.67 0 1 1 0 13.34A6.67 6.67 0 0 1 8 1.33Zm0 1.34a5.33 5.33 0 1 0 0 10.66A5.33 5.33 0 0 0 8 2.67Zm3.33 4.66v1.34H4.67V7.33h6.66Z"
      />
    </Svg>
  )
}

export default RemoveS
