import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { NotificationT, SecureConnectionCompletedNotificationT } from 'types'

interface ISecureConnectionCompletedProps {
  notification: SecureConnectionCompletedNotificationT
  dismiss: (notification: NotificationT) => void
}

const SecureConnectionCompleted: React.FC<ISecureConnectionCompletedProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(routes.secureConnectionWithTask(notification.data.taskUuid), ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.new.secureconnection.completed', {
      frontlinerNameShort: notification.data.frontlinerNameShort,
    }),
    body: t('notification.new.secureconnection.tenant', { tenantName: notification.data.tenantName }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }
  return <NotificationTemplate {...templateProps} />
}

export default SecureConnectionCompleted
