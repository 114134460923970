import { ICON_SIZE_L } from 'icons/constants'
import theme from 'utils/theme'

export const absenceButtonClasses = {
  AbsenceButtonBase: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
  },
  AbsenceButtonNativeMargin: {
    bottom: `calc(max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)}) + ${ICON_SIZE_L}px + ${theme.spacing(1.5)})`,
  },
}
