import React from 'react'
import { Box, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AssetCheckmark from 'icons/AssetCheckmarkSvg'
import { classes } from './styles'

export const defaultSuccessPageTimeout = 3000

interface ISuccessProps {
  translationKey: string
  dataTestId: string
}

// deprecated, check SuccessGeneric
const Success: React.FC<ISuccessProps> = ({ translationKey, dataTestId }) => {
  const { t } = useFavurTranslation()

  return (
    <CenteredBox sx={[classes.root, classes.spaceBetween]}>
      <Box sx={classes.successContainer}>
        <Typography variant="h1" sx={classes.successTitle} data-testid={dataTestId}>
          {t(translationKey)}
        </Typography>
        <AssetCheckmark />
      </Box>
    </CenteredBox>
  )
}

export default Success
