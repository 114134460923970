import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const ChangeAlertS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 2 1 14h14L8 2Zm0 1.985L2.741 13h10.518L8 3.985Zm-.708 2.764a.71.71 0 1 1 1.416 0L8.528 10a.529.529 0 0 1-1.056 0l-.18-3.252ZM8 12.5A.75.75 0 1 0 8 11a.75.75 0 0 0 0 1.5Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default ChangeAlertS
