import React, { ReactNode } from 'react'
import { Box, Button, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { classes } from './styles'

interface ICarouselCardProps {
  label: string
  onClick: () => void
  testid?: string
  icon?: ReactNode
  fullWidth?: boolean
}

const CarouselCard: React.FC<ICarouselCardProps> = ({ label, onClick, testid, icon, fullWidth = false }) => {
  return (
    <Box sx={[classes.cardWrapper, ...(fullWidth ? [classes.fullWidth] : [])]}>
      <Card>
        <Button
          sx={[classes.button]}
          onClick={onClick}
          disableRipple
          disableFocusRipple
          data-testid={`carousel-card_${testid}`}
        >
          {icon && <Box sx={classes.iconBox}>{icon}</Box>}
          <Typography sx={classes.textContainer} variant="body2">
            {label}
          </Typography>
        </Button>
      </Card>
    </Box>
  )
}
export default CarouselCard
