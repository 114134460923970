import { useState } from 'react'
import { PersonTeamPermissionsQueryReturnT, personTeamPermissionsQuery } from 'shared/queries'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const usePersonsWithTeamPermissions = () => {
  const [personTeamPermissions, setPersonTeamPermissions] = useState<PersonTeamPermissionsQueryReturnT>()

  const { loading } = useJamesApolloQuery<{
    findAllPersons: PersonTeamPermissionsQueryReturnT
  }>(personTeamPermissionsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(personsWithPermissions) {
      const personsWithTeamPermissions = personsWithPermissions.findAllPersons
      setPersonTeamPermissions(personsWithTeamPermissions)
    },
  })

  return { personTeamPermissions, loading }
}

export default usePersonsWithTeamPermissions
