import theme from 'utils/theme'

export const classes = {
  title: (textColor: string, gutter: number, marginLeft: number) => ({
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.39,
    letterSpacing: '0.3px',
    color: textColor,
    marginBottom: theme.spacing(gutter || 0),
    marginLeft: theme.spacing(marginLeft || 0),
  }),
}
