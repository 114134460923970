import React from 'react'
import { Box, Paper } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { actionCardButtonsClasses } from './styles'

interface IActionCardButtonsProps {
  children: React.ReactNode
  fullScreen?: boolean
  hasOverflow?: boolean
  isAtBottom?: boolean
}

const ActionCardButtons: React.FC<IActionCardButtonsProps> = ({
  children,
  fullScreen = true,
  hasOverflow = false,
  isAtBottom = true,
}) => {
  return (
    <Paper
      sx={fullScreen ? actionCardButtonsClasses.paper : actionCardButtonsClasses.smallPaper(hasOverflow, isAtBottom)}
    >
      {!fullScreen && (
        <Box sx={actionCardButtonsClasses.bottomDivisionLineContainer}>
          <DivisionLine />
        </Box>
      )}
      <Box sx={actionCardButtonsClasses.container}>
        <Box sx={actionCardButtonsClasses.rowButtons}>{children}</Box>
      </Box>
    </Paper>
  )
}

export default ActionCardButtons
