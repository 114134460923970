import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const DashboardApp: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={43} data-testid="dashboard-icon">
      <path
        fill={fill} /* House border fill */
        d="M25.16.85a2 2 0 0 1 2.6-.06l.13.12 11.8 12.47a1.5 1.5 0 0 1-.84 2.61l-.16.01H35v12a2 2 0 0 1-1.85 2H21v-2h12V14h4.53L26.5 2.34l-9.24 8.18-1.32-1.5L25.16.85z"
      />
      <path fill={fill} d="M12 9l-6 6v16l12 12V15z" /> {/* Bird fill */}
      <path fill="#FF0062" d="M9 16a3 3 0 1 0 6 0 3 3 0 0 0-6 0" />
      <path fill="#191919" d="M10.5 16a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0" />
      <path fill="#FFF" d="M9.27 14.77L10.5 16H12l-2.12-2.12c-.26.25-.47.55-.62.89z" />
      <path fill="#FF0062" d="M6 21v-6H0z" />
      <path fill="#FFC300" d="M6 18v-3H3z" />
      <path fill="#546D99" d="M9 21l9 9v10l-9-9z" />
    </Svg>
  )
}

export default DashboardApp
