import React from 'react'
import { Box } from '@mui/material'
import { classes } from './styles'

const ShiftPlaceholder: React.FC = () => {
  return (
    <Box sx={classes.column}>
      <Box sx={[classes.row, classes.title]}>
        <Box sx={classes.titlePlaceholder} />
      </Box>
      <Box sx={[classes.row, classes.subtitle]}>
        <Box sx={classes.subtitlePlaceholder} />
      </Box>
      <Box sx={[classes.row, classes.spaceBetween, classes.avatarLine]}>
        <Box sx={classes.avatarPlaceholder} />
        <Box sx={classes.badgePlaceholder} />
      </Box>
      <Box sx={[classes.row, classes.spaceBetween, classes.subtitle]}>
        <Box sx={classes.absenceTextPlaceholder} />
        <Box sx={classes.absenceTypePlaceholder} />
      </Box>
    </Box>
  )
}

export default ShiftPlaceholder
