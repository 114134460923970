const palette = {
  alert: {
    info: {
      icon: '#117bce',
      text: '#141a33',
      background: '#e6f4fa',
    },
    error: {
      icon: '#d0021b',
      text: '#521313',
      background: '#fbeae9',
    },
    warning: {
      icon: '#ff7a00',
      text: '#5f2b00',
      background: '#fdf0e6',
    },
    success: {
      icon: '#1daf2a',
      text: '#345233',
      background: '#ebf1eb',
    },
  },
  action: {
    hoverOpacity: 0,
    disabled: '#b2b2b2',
  },
  error: {
    25: '#ffeeec',
    light: '#ff5144',
    main: '#d0021b',
    dark: '#960000',
  },
  grey: {
    25: '#f8f8f8',
    50: '#f2f2f2',
    100: '#e6e6e6',
    200: '#cccccc',
    300: '#b2b2b2',
    400: '#999999',
    500: '#7f7f7f',
    600: '#666666',
    700: '#4c4c4c',
    800: '#333333',
    900: '#191919',
  },
  info: {
    light: '#8fa2c4',
    main: '#1f3968',
    dark: '#001746',
  },
  primary: {
    15: '#fbfcff',
    20: '#f5f6fa',
    25: '#f2f4f9',
    50: '#e6eaf4',
    100: '#d0d9e9',
    200: '#afbdd7',
    300: '#8fa2c4',
    light: '#8fa2c4',
    400: '#7187af',
    500: '#546d99',
    600: '#395381',
    700: '#1f3968',
    main: '#1f3968',
    800: '#0a275c',
    900: '#001746',
    dark: '#001746',
  },
  secondary: {
    10: '#fff9fb',
    25: '#fff1f6',
    50: '#ffe3ed',
    100: '#ffb7d2',
    light: '#ffb7d2',
    200: '#ff87b4',
    300: '#ff4f95',
    400: '#ff007c',
    main: '#ff007c',
    500: '#ff0062',
    600: '#ed0060',
    700: '#d7005c',
    800: '#c30059',
    dark: '#c30059',
    900: '#9c0055',
  },
  success: {
    100: '#e5f9e4',
    200: '#afedad',
    light: '#61e25b',
    main: '#1daf2a',
    dark: '#007e00',
  },
  text: {
    primary: '#191919',
    secondary: '#666666',
    disabled: '#b2b2b2',
    hint: '#b2b2b2',
  },
  warning: {
    light: '#fffc50',
    main: '#ffc900',
    dark: '#c79900',
  },
}

export default palette
