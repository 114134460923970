export const classes = {
  snackbar: {
    textAlign: 'left',
  },
  content: {
    display: 'block',
  },
  action: {
    paddingLeft: 0,
    display: 'block',
    textAlign: 'right',
  },
}
