import React from 'react'
import Announcement from 'components/Announcements'
import { useUserAnnouncements } from './useUserAnnouncements'

interface IAnnouncementsServiceProps {
  children?: React.ReactNode
}

const AnnouncementsService: React.FC<IAnnouncementsServiceProps> = ({ children }) => {
  const { routeAnnouncement, showAnnouncement, setShowAnnouncement, fetch } = useUserAnnouncements()

  return (
    <>
      <Announcement
        blocks={routeAnnouncement?.announcement?.blocks ?? []}
        open={showAnnouncement}
        onClose={() => {
          setShowAnnouncement(false)
          fetch()
        }}
      />
      {children}
    </>
  )
}

export default AnnouncementsService
