import { Dispatch } from 'react'
import { PlannedShiftT, ShiftsByTenantCCT } from 'types'

export type ShiftsByTenant = [string, PlannedShiftT[]]
export type ShiftsByDateT = {
  date: string
  tenants: ShiftsByTenantCCT[]
}

export type Shifts = [string, ShiftsByTenant[]][]

export interface IShiftListProps {
  displayFull: boolean
  isMultitenant?: boolean
  shifts: ShiftsByDateT[]
}

export interface IEffectiveDayProps {
  date: string
  isMultitenant: boolean
  personIds: number[]
  shifts: ShiftsByTenantCCT[]
}

export interface ISlideRendererProps {
  baseDate: string
  displayFull: boolean
  params: {
    index: number
    key: number
  }
  personIds?: number[] | null
  showAllDispatch: Dispatch<{ type: string }>
  showFromToday: boolean
}

export enum ShiftUpdateStatus {
  reviewFrontlineUnseen = 'review_frontline_unseen',
  reviewFrontlineSeen = 'review_frontline_seen',
  outdated = 'outdated',
}
