import { FRENCH, GERMAN, ITALIAN } from 'constants/i18n'
import type { FormsAccessPointT } from '../types'
import type { PersonalDocumentFileTypeT, PersonalDataDocumentValuesT } from './types'

export const personalDocumentFileTypes: Record<keyof PersonalDataDocumentValuesT, PersonalDocumentFileTypeT> = {
  passportFront: 'passport_front',
  passportBack: 'passport_back',
  residencePermitFront: 'residence_permit_front',
  residencePermitBack: 'residence_permit_back',
}

export const accessPoints: Record<FormsAccessPointT, FormsAccessPointT> = {
  shareUserDataWorkflow: 'shareUserDataWorkflow',
  userSettings: 'userSettings',
}

export const genders = {
  male: 'personalData.gender.male',
  female: 'personalData.gender.female',
}

export const residenceCategoryTypes = {
  'shortTerm-L': 'L',
  'annual-B': 'B',
  'settled-C': 'C',
  'crossBorder-G': 'G',
  'asylumSeeker-N': 'N',
  'needForProtection-S': 'S',
  othersNotSwiss: 'personalData.residenceCategoryTypes.other',
}

export const contactLanguages = {
  de: GERMAN.label,
  fr: FRENCH.label,
  it: ITALIAN.label,
}
