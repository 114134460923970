import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import CenteredBox from 'components/CenteredBox'
import Numpad from 'components/Numpad'
import Select from 'components/Select'
import { TOKEN_LENGTH, TOKEN_STATUSES } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinContext from 'hooks/usePinContext'
import { AlertInfoS, AlertSuccessS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { ExtendedStepProps, VerifyStepProps } from 'types'
import palette from 'utils/theme/palette'
import { classes as securityClasses } from '../../styles'
import { PersonData } from '../../types'
import { classes as secureConnectionClasses } from '../styles'
import Verify from './ActionButtons/Verify'
import { inputClasses, tenantClasses, tokenClasses as classes } from './styles'

interface IVerifyTokenProps {
  goToStep: (stepName: string, additionalUrlParams?: string[]) => void
  additionalProperties?: ExtendedStepProps
}

const VerifyToken: React.FC<IVerifyTokenProps> = ({ goToStep, additionalProperties }) => {
  const props = additionalProperties as VerifyStepProps
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setPinContext } = usePinContext()
  const [token, setToken] = useState('')
  const [nextPage, setNextPage] = useState<string | undefined>(undefined)
  const [personData, setPersonData] = useState<PersonData>({ personId: '', tenantName: '' })
  const [showSuccess, setShowSuccess] = useState<boolean | undefined>(undefined)

  const { lowSecTenants, taskUuid, nextAction, onVerifyToken: onVerify } = props

  useEffect(() => {
    if (lowSecTenants?.length === 1) {
      setPersonData({
        personId: lowSecTenants[0].personId,
        tenantName: lowSecTenants[0].tenantName,
      })
    }
  }, [lowSecTenants, taskUuid])

  const isSingleTenant = useMemo(() => {
    return lowSecTenants?.length === 1
  }, [lowSecTenants])

  useEffect(() => {
    // Redirection to the next step after 2 seconds
    if (!showSuccess) return undefined

    const successTimeout = setTimeout(() => {
      if (nextPage === 'pin') {
        goToStep('SET_PIN', [`?taskUuid=${taskUuid}`])
      }
      if (nextPage === 'success') {
        history.push(`${routes.secureConnectionSuccess}/`)
      }
    }, 2000)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, nextPage, history, goToStep, taskUuid])

  const borderBottomStyle = useMemo(() => {
    if (showSuccess === true) {
      return inputClasses.success
    }
    if (showSuccess === false) {
      return inputClasses.error
    }

    if (token.length > 0) {
      return inputClasses.active
    }
    return {}
  }, [showSuccess, token])

  const handleOnVerify = useCallback(async () => {
    if (onVerify) {
      const response = await onVerify(Number(personData.personId), token)
      if (response.establishSecureConnection.status === TOKEN_STATUSES.connectionEstablished) {
        setNextPage(nextAction)
        setShowSuccess(true)
      }
      if (response.establishSecureConnection.status === TOKEN_STATUSES.successGoToPin) {
        setNextPage(nextAction)
        setShowSuccess(true)
        setPinContext({ securitySetupTokenConfirmed: true })
      }
      if (response.establishSecureConnection.status === TOKEN_STATUSES.error) {
        setShowSuccess(false)
      }
    }
  }, [onVerify, personData.personId, token, nextAction, setPinContext])

  const handleTenantChange = useCallback(
    (value: string) => {
      const pair = lowSecTenants?.find((e: PersonData) => `${e.personId}` === `${value}`)
      if (!pair) {
        setPersonData({ personId: '', tenantName: '' })
        return
      }
      setPersonData({ personId: pair.personId, tenantName: pair.tenantName })
    },
    [lowSecTenants, setPersonData],
  )

  if (lowSecTenants === undefined) {
    return null
  }

  return (
    <CenteredBox sx={secureConnectionClasses.root}>
      <Typography variant="h2" sx={securityClasses.title}>
        {t('secureConnection.verifyToken.content.title')}
      </Typography>

      {isSingleTenant ? (
        <Box sx={tenantClasses.root} data-testid="verify-token-tenant-name">
          <Typography variant="caption" sx={[classes.label, tenantClasses.label]}>
            {t('secureConnection.verifyToken.tenant.label')}
          </Typography>
          <Typography variant="body1" sx={tenantClasses.name}>
            {personData.tenantName}
          </Typography>
        </Box>
      ) : (
        <Select
          fullWidth
          data-testid="verify-token-select-tenant"
          sx={tenantClasses.root}
          label={t('securitySetup.token.select.label')}
          value={personData.personId}
          onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            handleTenantChange(event.target.value as string)
          }}
          disabled={isSingleTenant}
        >
          <option value="-1" />
          {lowSecTenants?.map((option: PersonData) => (
            <option key={option.personId} value={option.personId}>
              {option.tenantName}
            </option>
          ))}
        </Select>
      )}

      <Box sx={classes.token}>
        <Typography
          variant="caption"
          sx={{
            ...classes.label,
            ...(showSuccess === true && inputClasses.labelSuccess),
          }}
        >
          {t('secureConnection.verifyToken.content.inputLabel')}
        </Typography>
        <Box sx={classes.inputContainer} data-testid="token">
          <Typography variant="h3" sx={[inputClasses.input, ...[borderBottomStyle]]} data-testid="verify-token-input">
            {token} {showSuccess && <AlertSuccessS fill={palette.alert.success.icon} />}
          </Typography>
          {showSuccess === false && (
            <Box sx={classes.errorAlert}>
              <AlertInfoS fill={palette.alert.error.icon} />
              <Typography variant="caption" sx={inputClasses.errorLabel}>
                {t('secureConnection.verifyToken.content.errorLabel')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={securityClasses.numpad}>
        <Numpad outputLength={6} onNumpadValueChange={(string) => setToken(string)} numpadType="pinLogin" />
      </Box>
      <ActionCardButtons>
        <Verify onClick={handleOnVerify} disabled={token.length !== TOKEN_LENGTH} />
      </ActionCardButtons>
    </CenteredBox>
  )
}

export default VerifyToken
