import theme from 'utils/theme'

export const teamListClasses = {
  text: {
    position: 'relative',
    top: theme.spacing(0.5),
  },
  card: {
    flexDirection: 'column',
    marginBottom: theme.spacing(1.0),
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1.0),
    paddingRight: theme.spacing(1.375),
  },
}
