import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const NoReminderM: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none" stroke={stroke} strokeWidth="1.5">
      <path d="M11.25 6.75a6 6 0 1 0 12 0 6 6 0 0 0-12 0ZM15.38 8.62l3.75-3.75M19.13 8.62l-3.75-3.75" />
      <path d="M17.64 16.4a7 7 0 0 0 1.11 2.35h-18s1.5-2.33 1.5-8.25A7.5 7.5 0 0 1 8 3.2M7.75 21.75a2.08 2.08 0 0 0 4 0" />
    </Svg>
  )
}

export default NoReminderM
