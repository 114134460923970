import { pickersLayoutClasses, pickersMonthClasses } from '@mui/x-date-pickers'
import theme from 'utils/theme'

export const classes = {
  openPickerIcon: {
    position: 'relative',
    marginRight: theme.spacing(0.75),
  },
}

export const datePickerClasses = {
  monthSwitcherIcons: {
    '& .MuiPickersArrowSwitcher-button': {
      background: 'none',
    },
  },
  baseDialog: {
    '& .MuiPickersLayout-root': {
      display: 'block',
      [`.${pickersMonthClasses.monthButton}`]: {
        cursor: 'auto',
      },
    },
    '& .MuiDayCalendar-weekContainer': {
      gap: '4px',
    },
  },
  iconCalendar: {
    marginRight: '16px',
  },
  datePickerButton: {
    textTransform: 'none',
    letterSpacing: '0.9px',
    gap: '4px',
    '&:hover, &:focus, &:hover:before': {
      background: 'transparent',
    },
  },
  layout: {
    [`.${pickersLayoutClasses.contentWrapper}`]: {
      gridColumn: 1,
      gridRow: 1,
    },
  },
}
