import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CheckMarkCircleM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path d="M12 4.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15ZM3 12c0-4.969 4.03-9 9-9s9 4.031 9 9a9 9 0 0 1-9 9 9 9 0 0 1-9-9Z" />
      <path d="M15.43 8.386a.75.75 0 0 1 .184 1.044l-4.023 5.747v.001a1.337 1.337 0 0 1-2.168.036v-.001L8.4 13.85a.75.75 0 0 1 1.2-.9l.89 1.185 3.896-5.565a.75.75 0 0 1 1.044-.184Zm-4.805 5.93Z" />
    </Svg>
  )
}

export default CheckMarkCircleM
