import theme from 'utils/theme'
import { CELL_HEIGHT } from '../Grid/constants'

export const avatarClasses = {
  container: {
    height: `${CELL_HEIGHT}px`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: '11px',
    paddingRight: '12px',
    cursor: 'pointer',
    background: theme.palette.common.white,
  },
  avatarSelected: {
    // @ts-ignore
    background: theme.palette.primary[50],
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
    paddingLeft: '9px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginLeft: '10px',
  },
  taskText: {
    // @ts-ignore
    color: theme.palette.primary[300],
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  },
  collapsableContent: (show: boolean) => ({
    width: show ? '181px' : '0px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
  tooltipText: {
    fontWeight: 600,
  },
  tooltipButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatarPlaceholder: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    background: theme.palette.grey[100],
  },
}
