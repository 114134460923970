import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const EventCircleM: React.FC<IconPropsT> = ({ fill = '#1F3968' }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <rect width={ICON_SIZE_M} height={ICON_SIZE_M} fill={fill} rx="12" />
      <g>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.781 4.022a1.12 1.12 0 0 0-.602.372c-.062.075-.308.554-1.595 3.108l-.626 1.24-2.004.197a115.71 115.71 0 0 0-2.125.22 1.058 1.058 0 0 0-.808.798c-.064.307.022.652.219.88.038.044.816.821 1.73 1.727 1.15 1.14 1.657 1.654 1.652 1.672-.053.181-1.226 4.465-1.24 4.526a1.08 1.08 0 0 0 .096.664c.25.493.85.704 1.359.479.04-.018.99-.486 2.11-1.042a259.84 259.84 0 0 1 2.05-1.01c.006 0 .95.465 2.098 1.034 1.147.568 2.13 1.047 2.182 1.066.215.074.538.052.756-.051.288-.136.514-.419.579-.726.024-.114.025-.328.002-.432-.016-.07-1.224-4.471-1.24-4.516-.003-.01.746-.762 1.666-1.672a97.25 97.25 0 0 0 1.744-1.75.979.979 0 0 0 .19-.402c.04-.155.054-.375.023-.375-.009 0-.016-.016-.016-.036 0-.074-.066-.244-.143-.372a1.228 1.228 0 0 0-.3-.304.89.89 0 0 0-.311-.142c-.082-.02-4.042-.425-4.16-.426-.02 0-.214-.37-.703-1.34-1.221-2.424-1.485-2.938-1.55-3.016a1.099 1.099 0 0 0-.614-.372 1.016 1.016 0 0 0-.419.001Zm-.939 3.22c-1.06 2.106-1.13 2.24-1.213 2.325a.419.419 0 0 1-.168.116c-.054.017-.797.097-2.262.242-1.2.12-2.185.216-2.19.216-.046 0 .26.312 1.799 1.838 1.511 1.498 1.801 1.793 1.837 1.866.087.177.118.038-.614 2.694a202.961 202.961 0 0 0-.658 2.42c0 .018.011.03.026.03s.989-.476 2.164-1.058a489.635 489.635 0 0 1 2.226-1.098.44.44 0 0 1 .21-.04l.121-.002.479.235c.263.128 1.27.626 2.238 1.106 1.382.685 1.764.868 1.78.852.015-.015-.122-.53-.645-2.427-.622-2.255-.664-2.415-.66-2.52.01-.218-.097-.1 1.884-2.062.986-.976 1.793-1.785 1.793-1.797a.034.034 0 0 0-.02-.03c-.011-.004-.996-.104-2.188-.222a119.37 119.37 0 0 1-2.228-.233.566.566 0 0 1-.237-.176 124.5 124.5 0 0 1-1.167-2.284c-.868-1.722-1.13-2.228-1.152-2.228-.022 0-.284.507-1.155 2.236Zm-6.84 2.931c0 .075.003.106.007.068a.916.916 0 0 0 0-.136c-.004-.037-.006-.006-.006.068Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h16v16H4z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default EventCircleM
