import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import FormsButtons from 'components/Forms/components/Buttons'
import InfoAdornment from 'components/Forms/components/InfoAdornment'
import InfoDialog from 'components/Forms/components/InfoDialog'
import Grid from 'components/Grid'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isEqual } from 'lodash/fp'
import SendShareUserDataCard from '../components/SendShareUserDataCard'
import { useFormsContext } from '../context'
import type { DataFormT, IDefaultFormProps } from '../types'
import { isBicRequired } from './helpers'
import type { BankDataValuesT } from './types'

const BankData: React.FC<IDefaultFormProps> = ({
  changeTabTrigger,
  onCancel,
  setChangeTabTrigger,
  setIsFormDirty,
  accessPoint,
  tabChangeCallback,
}) => {
  const [openIbanDialog, setOpenIbanDialog] = useState(false)
  const [openBicDialog, setOpenBicDialog] = useState(false)
  const { t } = useFavurTranslation()
  const { getBankDataForm } = useFormsContext()
  const { favurForm, formHandlers, formReady } = getBankDataForm() as DataFormT<BankDataValuesT, BankDataValuesT>
  const { onChange: onChangeIban, ...fh } = formHandlers('iban')
  const { onChange: onChangeBic, ...fhBic } = formHandlers('bic')

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(favurForm.formState.isDirty)
    }
  }, [favurForm.formState.isDirty, setIsFormDirty])

  useEffect(() => {
    if (changeTabTrigger && setChangeTabTrigger) {
      favurForm.handleSubmit()
      setChangeTabTrigger(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTabTrigger])

  useEffect(() => {
    favurForm.reset({}, { keepValues: true, keepDirty: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (favurForm.formState.isSubmitted && isEqual(favurForm.formState.errors, {})) {
      tabChangeCallback && tabChangeCallback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favurForm.formState.isSubmitted])

  if (!formReady) {
    return <SimpleLoading />
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const bicRequired = Boolean(isBicRequired(favurForm.watch('iban')))
  return (
    <>
      <Grid container>
        <Grid col={4}>
          <TextField
            variant="standard"
            required
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              onChangeIban(e)
              favurForm.trigger('bic')
            }}
            fullWidth
            {...fh}
            InputProps={{
              endAdornment: <InfoAdornment type="input" open={openIbanDialog} setOpen={setOpenIbanDialog} />,
            }}
            inputProps={{ 'data-testid': 'bank-data_iban' }}
          />
          <InfoDialog
            open={openIbanDialog}
            action={() => setOpenIbanDialog(false)}
            text={t('personalData.iban.dialog')}
          />
        </Grid>
        <Grid col={4}>
          <TextField
            variant="standard"
            required={bicRequired}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              onChangeBic(e)
            }}
            fullWidth
            {...fhBic}
            InputProps={{
              endAdornment: <InfoAdornment type="input" open={openBicDialog} setOpen={setOpenBicDialog} />,
            }}
            inputProps={{ 'data-testid': 'bank-data_bic' }}
          />
          <InfoDialog open={openBicDialog} action={() => setOpenBicDialog(false)} text={t('personalData.bic.dialog')} />
        </Grid>
      </Grid>
      {accessPoint === 'userSettings' && <SendShareUserDataCard />}
      {accessPoint !== 'shareUserDataWorkflow' && (
        <FormsButtons onCancel={onCancel} onSubmit={favurForm.handleSubmit} accessPoint={accessPoint} />
      )}
    </>
  )
}

export default BankData
