import theme from 'utils/theme'

export const classes = {
  centralImage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  boldBody: {
    marginTop: theme.spacing(1),
    fontWeight: 600,
    letterSpacing: 0.3,
  },
}
