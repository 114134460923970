import { PackageBackendT, PackageFrontendT } from 'types'

export const PACKAGES: Record<PackageFrontendT, PackageBackendT> = {
  basic: 'basic',
  classic: 'classic',
  classicLight: 'classic_light',
  betaWorkflowAbsencesClassic: 'beta_workflow_absences_classic',
  development: 'development',
  favurPublisher: 'favur_publisher',
}
