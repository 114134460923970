import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: 0,

      '&$disabled': {
        color: palette.grey[300],
      },
    },
  },
}
