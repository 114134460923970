import React from 'react'
import Svg from 'components/Svg'

const Logo: React.FC = () => {
  return (
    <Svg viewBox="0 0 165 109" role="img" data-testid="logo" size={92}>
      <defs>
        <path id="a" d="M0 .49h24.582v36.35H0z" />
      </defs>
      <path
        fill="#0A275C"
        d="M88.118 107.84H74.505l-10.71-34.388h12.452l5.161 25.936 5.55-25.936h11.677zm45.32-.025h-10.064l-.452-4.065c-2.387 3.548-5.807 5.225-10.26 5.225-6.516 0-9.548-4.257-9.548-10.839V73.427h11.484v23.227c0 3.096 1.032 3.934 2.71 3.934 1.613 0 3.29-1.162 4.646-3.419V73.427h11.484v34.388zM48.23 98.227c-1.032 1.612-2.645 2.709-4.58 2.709-2.193 0-3.42-1.484-3.42-3.806 0-3.032 1.936-4.516 6.064-4.516h1.936v5.613zm13.419 3.16c-1.526-.544-2.193-1.548-2.193-4.13V84.744c0-8.258-4.387-12.453-14.84-12.453-4.153 0-11.323 1.427-15.144 3.289l6.22 6.22c2.789-.805 5.427-1.251 7.184-1.251 3.935 0 5.354 1.033 5.354 4.775v1.162h-2.968c-10.581 0-16.323 4.193-16.323 11.742 0 6.323 4.388 10.773 11.228 10.773 3.999 0 7.87-1.29 10.385-5.032 1.282 2.42 3.129 3.719 5.708 4.366l6.755-6.754c-.372.032-.828 0-1.366-.193zm102.53-28.412l-1.806 11.098c-1.096-.324-2-.518-3.162-.518-4.13 0-5.742 3.098-6.774 7.937v16.323h-11.485V73.427h10.065l.969 6.516c1.418-4.58 4.902-7.55 8.773-7.55 1.29 0 2.259.195 3.42.582M31.569 63H20.425l-8.451 8.452h11.143z"
      />
      <g transform="translate(0 71)">
        <path fill="#0A275C" d="M11.936.49L0 12.426V36.84h11.936V19.677h12.646v-8.452H11.936z" mask="url(#b)" />
      </g>
      <path fill="#FF0062" d="M0 77.335V63h14.335zM88 23V12h11z" />
      <path fill="#FFC300" d="M88 17v-5h5z" />
      <path fill="#1F3867" d="M88 12L76 0 64 12v53l24-24z" />
      <path fill="#0A275C" d="M81.52 62l-3.339-12.46-.967.259L80.485 62h-4.486v1H90v-1z" />
      <path fill="#8EA1C3" d="M88 41V17L64 41v24z" />
      <path fill="#0A275C" d="M84 21L64 41v18l20-20z" />
      <path fill="#FF0062" d="M81 14c0 2.762-2.238 5-5 5s-5-2.238-5-5 2.238-5 5-5 5 2.238 5 5" />
      <path fill="#191919" d="M78.75 14a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0" />
      <path fill="#FFF" d="M80.634 12.117L78.75 14H76l3.536-3.536c.468.469.844 1.03 1.098 1.653z" />
    </Svg>
  )
}

export default Logo
