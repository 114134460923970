import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { SecureConnectionNotificationT } from 'types'

interface ISecureConnection extends NotificationPropsT {
  notification: SecureConnectionNotificationT
}

const SecureConnection: React.FC<ISecureConnection> = (props) => {
  const { pushWithRole } = useHistoryUtils()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { tenantName, taskUuid } = notification.data

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole, taskUuid])

  const templateProps = {
    ...props,
    title: t('notification.new.secureconnection.title'),
    body: t('notification.new.secureconnection.tenant', { tenantName }),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
  }
  return <NotificationTemplate {...templateProps} />
}

export default SecureConnection
