import { createContext, Context, Dispatch, SetStateAction } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import { RoleViewT } from 'types'

export type OfficeViewContextT = {
  activeView: RoleViewT
  setActiveView: Dispatch<SetStateAction<RoleViewT>>
}

const OfficeViewContext: Context<OfficeViewContextT> = createContext({
  activeView: ROLE_VIEWS.frontliner,
  setActiveView: () => {},
} as OfficeViewContextT)

export default OfficeViewContext
