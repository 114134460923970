import theme from 'utils/theme'
import { AVATAR_MENU_CLOSED_WIDTH, AVATAR_MENU_OPEN_WIDTH, TEAM_NAME_ROW_HEIGHT } from '../Grid/constants'

export const avatarSideBarClasses = {
  container: (isSmallScreen: boolean, avatarBarExpanded: boolean, isPreview: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    left: 0,
    marginTop: '73px',
    zIndex: avatarBarExpanded ? 35 : 30,
    background: theme.palette.common.white,
    boxShadow: isSmallScreen && avatarBarExpanded ? theme.shadows[10] : 'none',
    '&>:last-child': {
      flex: 1,
      background: theme.palette.common.white,
      '&>:last-child': {
        paddingBottom: isSmallScreen ? '32px' : 0,
      },
    },
    ...(isPreview ? { height: 'fit-content', minHeight: 'calc(100% - 73px)' } : {}),
  }),
  avatarContainer: {
    display: 'flex',
    background: theme.palette.common.white,
  },
  toggleButtonContainer: (hasOverflow: boolean, isAtBottom: boolean, avatarBarExpanded: boolean) => ({
    cursor: 'pointer',
    bottom: 0,
    zIndex: 1,
    background: theme.palette.common.white,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 12,
      right: 12,
      content: '""',
      zIndex: -1,
      boxShadow: hasOverflow && !isAtBottom ? theme.shadows[9] : 'none',
    },
    borderRight: 'none',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: `${avatarBarExpanded ? AVATAR_MENU_OPEN_WIDTH : AVATAR_MENU_CLOSED_WIDTH}px`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
  backdrop: {
    zIndex: -1,
  },
  toggleButtonWrapper: (avatarBarExpanded: boolean) => ({
    width: 'calc(100% - 24px)',
    padding: '8px 12px 12px 12px',
    display: 'flex',
    background: theme.palette.common.white,
    justifyContent: avatarBarExpanded ? 'end' : 'center',
  }),
  toggleButton: {
    padding: '4px',
  },
  topBox: (isScrolled: boolean) => ({
    height: '122px',
    borderRight: 'none',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
    zIndex: 2,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: 30,
      bottom: 0,
      left: 12,
      right: 12,
      content: '""',
      zIndex: -1,
      boxShadow: isScrolled ? theme.shadows[10] : 'none',
    },
    [theme.breakpoints.down('sm')]: {
      height: '73px',
      borderRight: 'none',
    },
  }),
  coverBox: (avatarBarExpanded: boolean) => ({
    display: 'flex',
    justifyContent: avatarBarExpanded ? 'end' : 'center',
    position: 'absolute',
    padding: '0 16px',
    top: 0,
    left: 0,
    height: '100%',
    width: 'calc(100% - 32px)',
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
    transition: theme.transitions.create(['justify-content'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
  divisionLineContainer: {
    padding: '0 16px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: `${TEAM_NAME_ROW_HEIGHT}px`,
    borderColor: theme.palette.grey[200],
    backgroundColor: '#fff',
  },
  bottomDivisionLineContainer: {
    margin: '0 12px',
  },
  borderContainer: {
    borderRight: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  stickyContainer: {
    position: 'sticky',
    top: '73px',
    zIndex: 1,
    '&::before': {
      position: 'absolute',
      top: 30,
      bottom: 0,
      left: 0,
      right: 0,
      content: '""',
      zIndex: -1,
      boxShadow: theme.shadows[10],
    },
  },
  iconButton: {
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
}
