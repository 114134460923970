// eslint-disable-next-line import/no-extraneous-dependencies
import useUsername from 'hooks/useUsername'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export const useSetDatadogUser = () => {
  const { userId } = useUsername()
  const user = {
    id: userId ? `${userId}` : undefined,
  }
  datadogRum.setUser(user)
  datadogLogs.setUser(user)
}
