import theme from 'utils/theme'

export const newAbsenceRequestClasses = {
  balanceButton: {
    color: theme.palette.primary.main,
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    '&:hover, &:focus': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
      // @ts-ignore
      borderColor: theme.palette.primary[50],
    },
    '&.Mui-disabled': {
      color: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[50],
    },
  },
  extraBottomPadding: {
    paddingBottom: '15px',
  },
  radioGroup: {
    width: '100%',
    '&> *': {
      flexGrow: 1,
    },
    '& [data-testid="RadioButtonCheckedIcon"]': {
      fill: theme.palette.secondary.main,
    },
  },
  numDaysLabel: {
    '& span:first-of-type': {
      fontWeight: 600,
    },
  },
  datePickerDialog: {
    '&>.MuiDialog-container': {
      justifyContent: 'end',
      marginRight: '28px',
    },
  },
  formContainer: {
    paddingBottom: 'calc(100px + var(--ion-safe-area-bottom))',
  },
}

export const newAbsenceRequestSuccessClasses = {
  successTitle: {
    // @ts-ignore
    color: theme.palette.primary[900],
    fontSize: '23px',
    fontWeight: 600,
    lineHeight: 1.26,
    letterSpacing: '0.2px',
    marginBottom: theme.spacing(1),
    '&:last-of-type': {
      marginBottom: theme.spacing(4.875),
    },
  },
  successContainer: {
    marginTop: theme.spacing(5),
  },
}
