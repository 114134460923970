import { useCallback, useState, useMemo, useEffect } from 'react'
import { absenceRequest, cmsEvent, shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import useTeams from 'hooks/useTeams'
import { uniqBy } from 'lodash/fp'
import { StateValueT } from 'services/AppStates/types'
import { TeamWithUserPermissions } from 'types'
import { ColorModesT, colorModes } from '../components/TaskBar/types'
import useAbsencePlannerStateContext from '../contexts/AbsencePlannerStateContext'
import { AbsencePlannerBadgeTypesT } from '../types'
import { getOwnerMemberTeams, prepareTeamList } from '../utils'
import { useAbsencePlannerUser } from './useAbsencePlannerUser'

const getFiltersNameKey = (name: string) => `absence_planner_filters_${name}`
const SHOWN_TYPES_FILTER_NAME = getFiltersNameKey('ShownTypes')
const SHOWN_TEAMS_FILTER_NAME = getFiltersNameKey('ShownTeams')
const GRESCL_MODE_FILTER_NAME = getFiltersNameKey('GreyscaleMode')
export const SELEC_OWNER_FILTER_NAME = getFiltersNameKey('SelectedOwnerId')

export const useFilter = () => {
  const { language } = useFavurTranslation()
  const set = useCallback((key: string, value: StateValueT) => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [])

  const get = useCallback((key: string) => {
    const serializedValue = localStorage.getItem(key)
    return serializedValue ? JSON.parse(serializedValue) : null
  }, [])

  const [shownTypes, setShownTypes] = useState<AbsencePlannerBadgeTypesT[]>(
    get(SHOWN_TYPES_FILTER_NAME) ?? [shiftTypes.absence, absenceRequest],
  )
  const [shownTeams, setShownTeams] = useState<string[]>(get(SHOWN_TEAMS_FILTER_NAME) ?? [])
  const [greyscaleMode, setGreyscaleMode] = useState<ColorModesT>(get(GRESCL_MODE_FILTER_NAME) ?? colorModes.colorful)
  const { setOwners, selectedOwnerId, setSelectedOwnerId } = useAbsencePlannerStateContext()
  const { owners, persons, loading: loadingUser } = useAbsencePlannerUser({
    onComplete: (ownerData) => {
      const storedOwnerId = get(SELEC_OWNER_FILTER_NAME) ? Number(get(SELEC_OWNER_FILTER_NAME)) : undefined
      const fetchedOwnerId = ownerData.length > 0 ? ownerData[0].owner.id : undefined
      const validStoredOwner = ownerData.some((owner) => owner.owner.id === fetchedOwnerId)
      setSelectedOwnerId(storedOwnerId && validStoredOwner ? storedOwnerId : fetchedOwnerId)
    },
  })

  const { canManageAbsences } = useRolesViews()

  const { teams, loading: loadingTeams, isMultiTenant, publicTeams } = useTeams({
    queryForUsers: true,
  })

  const ownerMemberTeams = useMemo(() => getOwnerMemberTeams(language, teams, selectedOwnerId), [
    language,
    selectedOwnerId,
    teams,
  ])
  const ownerPublicTeams = useMemo(
    () => (!publicTeams ? undefined : prepareTeamList(publicTeams, language, selectedOwnerId)),
    [language, publicTeams, selectedOwnerId],
  )

  const storeAndSetShownTypes = useCallback(
    (value: AbsencePlannerBadgeTypesT[]) => {
      set(SHOWN_TYPES_FILTER_NAME, value)
      setShownTypes(value)
    },
    [set],
  )

  const storeAndSetShownTeams = useCallback(
    (value: string[]) => {
      set(SHOWN_TEAMS_FILTER_NAME, value)
      setShownTeams(value)
    },
    [set],
  )

  const storeAndSetGreyscaleMode = useCallback(
    (value: ColorModesT) => {
      set(GRESCL_MODE_FILTER_NAME, value)
      setGreyscaleMode(value)
    },
    [set],
  )

  const allTeams = useMemo(() => uniqBy('id', [...(ownerMemberTeams ?? []), ...(ownerPublicTeams ?? [])]), [
    ownerMemberTeams,
    ownerPublicTeams,
  ])

  useEffect(() => {
    const allTeamIds = allTeams.map((team) => team.id)
    const defaultSelectedTeams =
      canManageAbsences && ownerMemberTeams
        ? ownerMemberTeams.filter((team) => {
            const teamWithUserPermissions = team as TeamWithUserPermissions
            return teamWithUserPermissions.userPermissions && teamWithUserPermissions.userPermissions.absenceManager
          })
        : allTeams
    const defaultSelectedTeamsIds = defaultSelectedTeams.map((team) => team.id)
    const storedShowTeams = (get(SHOWN_TEAMS_FILTER_NAME) as string[]) ?? []
    const validStoredTeams = storedShowTeams.every((teamId) => allTeamIds.includes(teamId))

    setShownTeams(
      storedShowTeams && storedShowTeams.length !== 0 && validStoredTeams ? storedShowTeams : defaultSelectedTeamsIds,
    )
  }, [allTeams, canManageAbsences, get, ownerMemberTeams, ownerPublicTeams, storeAndSetShownTeams])

  useEffect(() => {
    if (!owners) return
    setOwners(owners)
  }, [owners, setOwners])

  useEffect(() => {
    if (!get(SHOWN_TYPES_FILTER_NAME)) {
      const selectedTypes = [shiftTypes.absence, absenceRequest, shiftTypes.plannedShift, cmsEvent]

      storeAndSetShownTypes(selectedTypes as AbsencePlannerBadgeTypesT[])
    }
  }, [get, storeAndSetShownTypes])

  return {
    greyscaleMode,
    isMultiTenant,
    loading: loadingUser || loadingTeams,
    persons,
    allTeams: allTeams ?? [],
    ownerMemberTeams: ownerMemberTeams ?? [],
    ownerPublicTeams: ownerPublicTeams ?? [],
    shownTeams,
    shownTypes,
    setGreyscaleMode: storeAndSetGreyscaleMode,
    setShownTeams: storeAndSetShownTeams,
    setShownTypes: storeAndSetShownTypes,
    teamsLoading: loadingTeams,
  }
}
