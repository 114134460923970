import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePackage from 'hooks/usePackage'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import useUserSettings from 'hooks/useUserSettings'
import { isNative } from 'utils/platform'
import SectionHeader from '../SectionHeader'
import { classes } from '../styles'
import EmployeeCardCarousel from './EmployeeCardCarousel'
import TenantLinkCarousel from './TenantLinkCarousel'
import WebCardGrid from './WebCardGrid'

const EmployeeCardsSection: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasClassicAccess } = usePackage()
  const { settings, loading: settingsLoading } = useUserSettings()
  const { persons, loading: personsLoading } = usePersonsOfUser()

  const personTenantList = useMemo(() => {
    if (settingsLoading || personsLoading) return []

    return (persons ?? [])
      .filter(({ favurUuid }) => favurUuid && (settings?.dashboardEmployeeCards ?? []).includes(favurUuid))
      .map(({ favurUuid, tenant }) => ({ tenantName: tenant?.name ?? '', personFavurUuid: favurUuid ?? '' }))
  }, [personsLoading, persons, settings?.dashboardEmployeeCards, settingsLoading])

  const tenantLinkList = useMemo(() => {
    if (personsLoading) return []
    return (persons ?? []).flatMap((person) => person.tenant?.links ?? [])
  }, [persons, personsLoading])

  const showEmployeeCard = useMemo(() => hasClassicAccess && personTenantList.length > 0, [
    hasClassicAccess,
    personTenantList.length,
  ])

  const showLinkList = useMemo(() => tenantLinkList.length > 0 && hasClassicAccess, [
    hasClassicAccess,
    tenantLinkList.length,
  ])

  if (settingsLoading || personsLoading) return <SimpleLoading dataTestid="carousels-loading" />

  if (!showEmployeeCard && !showLinkList) return null

  return (
    <Box sx={classes.section}>
      <SectionHeader
        title={showLinkList ? t('page.dashboard.content.quickAccess') : t('page.dashboard.content.employeeCards')}
      />
      {isNative() ? (
        <>
          {showEmployeeCard && <EmployeeCardCarousel personTenantList={personTenantList} />}
          {showLinkList && <TenantLinkCarousel linkList={tenantLinkList} />}
        </>
      ) : (
        <WebCardGrid
          personTenantList={showEmployeeCard ? personTenantList : []}
          tenantLinkList={showLinkList ? tenantLinkList : []}
        />
      )}
    </Box>
  )
}

export default EmployeeCardsSection
