import React, { useContext, useEffect } from 'react'
import { Typography, Box, Paper, Button } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePSN from 'hooks/usePushNotifications'
import useSessionContext from 'hooks/useSessionContext'
import { useHistory } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { isNativeNoOverride } from 'utils/platform'
import { PushNotifications } from '@capacitor/push-notifications'
import { IInvitationStepProps } from '../types'
import Checkmark from './Assets/checkmark'
import { classes } from './styles'

const Success = ({ invitation }: IInvitationStepProps) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { refresh: refreshSession } = useSessionContext()
  const { closeLatest } = useContext(FlashMessagesContext)
  const { pushActiveOnDevice } = usePSN({ refresh: false })

  const redirectToAppTeaserOrDashboard = () => {
    if (isNativeNoOverride()) {
      history.push('/')
    } else {
      history.push(routes.appTeaser)
    }
  }

  closeLatest()

  useEffect(() => {
    if (isNativeNoOverride() && pushActiveOnDevice) {
      PushNotifications.removeAllDeliveredNotifications()
    }
  }, [pushActiveOnDevice])

  useEffect(() => {
    refreshSession()
  }, [refreshSession])

  return (
    <Page
      showHeader
      header={
        <>
          <Typography variant="h2">{t('register.content.title')}</Typography>
          <Typography variant="h2">{invitation?.tenantName}</Typography>
        </>
      }
    >
      <Box component={Paper} p={1} sx={classes.paper}>
        <Box sx={classes.contentWrapper}>
          <Checkmark sx={classes.assetCheckmark} />
          <Typography sx={classes.connected} variant="h2" data-testid="invitation-success-title">
            {t('invitation.success.content.connected')}
          </Typography>
          <Typography sx={classes.description} variant="body2" data-testid="invitation-success-description">
            {t('invitation.success.content.description')}
          </Typography>
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
            onClick={redirectToAppTeaserOrDashboard}
            sx={classes.closeButton}
            data-testid="invitation-success-next"
          >
            {t('invitation.success.button.close')}
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

export default Success
