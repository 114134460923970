import React, { memo, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { CmsContentT, PlannedShiftT } from 'types'
import { prepareShowingShifts } from '../utils'
import CalendarDayShifts from './CalendarDayShifts'
import { classes } from './styles'

interface ICalendarDayProps {
  day: Date
  isCurrentMonth: boolean
  isToday: boolean
  shifts: PlannedShiftT[]
  onClick: () => void
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
  events?: CmsContentT[]
}

const CalendarDay: React.FC<ICalendarDayProps> = ({
  day,
  isCurrentMonth,
  isToday,
  shifts,
  onClick,
  shiftUpdates,
  events,
}) => {
  const sortedShifts = prepareShowingShifts(shifts)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Box sx={[classes.dayContainer, ...(!isCurrentMonth ? [classes.differentMonth] : [])]} ref={containerRef}>
      <Button disableRipple onClick={onClick} variant="text" sx={classes.calendarDayButton}>
        <Box sx={classes.insideButton}>
          <Typography variant="body2" sx={[classes.dayNumber, ...(isToday ? [classes.referenceDay] : [])]}>
            {day.getDate()}
          </Typography>
          {containerRef.current && (
            <CalendarDayShifts
              sortedShifts={sortedShifts}
              shiftUpdates={shiftUpdates}
              events={events}
              containerRef={containerRef}
            />
          )}
        </Box>
      </Button>
    </Box>
  )
}

export default memo(CalendarDay)
