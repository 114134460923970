import { createContext, Context } from 'react'
import { IFlashMessagesContext, FlashMessageType, FlashMessageCloseType } from './types'

const initialState: IFlashMessagesContext = {
  setFlashMessage: (
    _message: string,
    _timeout?: number,
    _type?: FlashMessageType,
    _closeType?: FlashMessageCloseType,
  ) => {},
  closeLatest: () => {},
  removeAll: () => {},
}

const FlashMessagesContext: Context<IFlashMessagesContext> = createContext(initialState)

export default FlashMessagesContext
