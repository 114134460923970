import theme from 'utils/theme'

export const classes = {
  indicator: {
    top: 0,
    height: '32px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '40px',
  },
  tabs: {
    minHeight: 'unset',
    minWidth: 'unset',
    display: 'flex',
    borderRadius: '20px',
    background: theme.palette.grey[50],
    padding: '4px',
    alignItems: 'center',
    color: theme.palette.common.white,
    '& .MuiTabs-flexContainer': {
      position: 'relative',
      zIndex: 10,
      gap: '16px',
    },
  },
  tab: {
    color: theme.palette.primary.main,
    flexGrow: 1,
    padding: 0,
    minHeight: 'unset',
    minWidth: 'unset',
    height: '32px',
    '&.Mui-selected': {
      color: `${theme.palette.common.white}`,
      transition: theme.transitions.create('color'),
    },
  },
}
