import { alpha } from '@mui/material/styles'
import palette from '../palette'
import shadows from '../shadows'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    plus: true
  }
}

export default {
  styleOverrides: {
    root: {
      borderRadius: 100,
      lineHeight: 1.25,
      letterSpacing: 0.9,
      overflow: 'hidden',
    },
    sizeLarge: {
      fontSize: 14,
    },
    sizeSmall: {
      fontSize: 12,
      letterSpacing: 0.7,
    },
    outlined: {
      border: `2px solid ${palette.grey[900]}`,
      padding: '9px 24px 9px',
      '&:before': {
        content: '" "',
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
      },
      '&:hover': {
        borderWidth: 2,
        '&:before': {
          backgroundColor: alpha(palette.grey[400], 0.04),
        },
      },
      '&.Mui-disabled': {
        border: `2px solid ${palette.grey[200]}`,
      },
    },
    outlinedSizeLarge: {
      padding: '10px 24px 9px',
    },
    outlinedSizeSmall: {
      padding: '6px 24px 3px',
    },
    outlinedPrimary: {
      border: `2px solid ${palette.primary.main}`,
      '&:hover': {
        borderWidth: 2,
        '&:before': {
          backgroundColor: alpha(palette.primary[400], 0.04),
        },
      },
    },
    outlinedSecondary: {
      border: `2px solid ${palette.secondary.main}`,
      '&:hover': {
        borderWidth: 2,
        '&:before': {
          backgroundColor: alpha(palette.secondary[400], 0.04),
        },
      },
    },
    contained: {
      border: '1px solid transparent',
      boxShadow: shadows[1],
      '&:before': {
        content: '" "',
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
      },
      '&:hover': {
        backgroundColor: palette.grey[300],
        boxShadow: shadows[1],
        '&:before': {
          backgroundColor: alpha(palette.grey[900], 0.12),
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: shadows[1],
        },
      },
      '&$focusVisible, &:focus': {
        border: '1px solid white',
        boxShadow: shadows[1],
        '&:before': {
          backgroundColor: alpha(palette.grey[900], 0.24),
        },
      },
      '&:active': {
        boxShadow: shadows[1],
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
        backgroundColor: 'white',
      },
    },
    containedSizeLarge: {
      padding: '9px 24px 8px',
    },
    containedSizeSmall: {
      padding: '5px 24px 2px',
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: palette.primary.main,
        '&:before': {
          backgroundColor: alpha(palette.primary[900], 0.12),
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.primary.main,
        },
      },
      '&$focusVisible, &:focus': {
        '&:before': {
          backgroundColor: alpha(palette.primary[900], 0.24),
        },
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: palette.secondary.main,
        '&:before': {
          backgroundColor: alpha(palette.secondary[900], 0.12),
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.secondary.main,
        },
      },
      '&$focusVisible, &:focus': {
        '&:before': {
          backgroundColor: alpha(palette.secondary[900], 0.24),
        },
      },
    },
    text: {
      borderRadius: 6,
      '&:before': {
        content: '" "',
        position: 'absolute',
        zIndex: 1,
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
      },
      '&:hover:before': {
        backgroundColor: alpha(palette.grey[400], 0.04),
      },
    },
    textSizeLarge: {
      padding: '10px 12px 9px',
    },
    textSizeSmall: {
      padding: '6px 8px 3px',
    },
    textPrimary: {
      '&:hover': {
        '&:before': {
          backgroundColor: alpha(palette.primary[400], 0.04),
        },
      },
    },
    textSecondary: {
      '&:hover': {
        '&:before': {
          backgroundColor: alpha(palette.secondary[400], 0.04),
        },
      },
    },
    label: {
      position: 'relative' as const,
      zIndex: 2,
    },
    iconSizeSmall: {
      marginTop: -1,
      '& > *:first-of-type': {
        fontSize: 16,
      },
    },
    iconSizeLarge: {
      marginTop: -4,
      marginBottom: -3,
      '& > *:first-of-type': {
        fontSize: 24,
      },
    },
    plus: {
      background: palette.primary[50],
      color: palette.primary.main,
      padding: '6px 24px 6px 24px',
      borderRadius: '18px',
    },
  },
}
