import { useCallback, useState } from 'react'
import { FilterStateT } from 'components/Filter/types'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { tasksPaginatedQuery, tasksPaginatedQueryMinData } from '../pages/Tasks/queries'
import { TaskDataT, TasksPaginated, TaskT } from '../pages/Tasks/types'

interface IUseTasksQueryPaginatedParams {
  initialFilters: FilterStateT
  initialOffice?: boolean
  limit?: number
  skip?: boolean
  reducedFields?: boolean
}

const useTasksQueryPaginated = ({
  initialFilters,
  initialOffice = false,
  limit,
  skip,
  reducedFields = false,
}: IUseTasksQueryPaginatedParams) => {
  const [offset, setOffset] = useState<number>(0)
  const [filters, setFilters] = useState<FilterStateT>(initialFilters)
  const [office, setOffice] = useState(initialOffice)
  const [tasks, setTasks] = useState<TaskT<TaskDataT>[]>([])
  const [tasksLoading, setTasksLoading] = useState(true)

  const { data, loading, refetch } = useJamesApolloQuery<{ tasksPaginated: TasksPaginated }>(
    reducedFields ? tasksPaginatedQueryMinData : tasksPaginatedQuery,
    {
      variables: {
        filters,
        offset,
        limit,
        office,
      },
      skip: Boolean(skip),
      fetchPolicy: 'no-cache',
      onCompleted(responseData) {
        const newTasks = responseData.tasksPaginated.list
        if (limit) setTasks([...tasks, ...newTasks])
        else setTasks(newTasks)
        setTasksLoading(false)
      },
    },
  )

  const totalCount = data?.tasksPaginated.totalCount
  const hasMoreElements = Boolean(totalCount && tasks && totalCount > tasks?.length)

  const loadMore = useCallback(() => {
    setOffset(tasks.length)
  }, [tasks?.length])

  const updateFilters = useCallback((filtersUpdate: FilterStateT) => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    setFilters(filtersUpdate)
  }, [])

  const updateOffice = useCallback((officeUpdate: boolean) => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    setOffice(officeUpdate)
  }, [])

  const handleRefetch = () => {
    refetch()
  }

  const reload = () => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    refetch()
  }

  return {
    list: tasks,
    hasMoreElements,
    handleRefetch,
    loadMore,
    updateFilters,
    updateOffice,
    reload,
    loading: loading || tasksLoading,
    totalCount,
  }
}

export default useTasksQueryPaginated
