import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledThumbUpM } from 'icons'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice
    ? `workTimeControl.acceptedForThisMonth.tooltipManager`
    : `workTimeControl.acceptedForThisMonth.tooltipFrontliner`
}

const getTextKey = (isActing: boolean) =>
  isActing ? `timeline.badge.acceptingForThisMonth` : `timeline.badge.acceptedForThisMonth`

const AcceptedForThisMonth: React.FC<ITaskBadgeProps> = ({
  withTooltip = false,
  isOffice = false,
  isActing = false,
}) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t(getTextKey(isActing))}
      sx={getClasses(withTooltip)}
      icon={<FilledThumbUpM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="task-status_claim-accepted-this-month"
    />
  )
}

export default AcceptedForThisMonth
