import { endOfMonth } from 'date-fns'
import { QueryDates, ShiftTypeT } from 'types'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { shiftDateFormat } from './dateFormats'

export const daysInMonthWhenLastMonthCanBeViewed = 5
export const numberOfShiftsOnDashboard = 3

const nowDate = newDateWithoutTime()
export const defaultShiftDates: QueryDates = {
  start: getFormattedFromDate(nowDate, shiftDateFormat),
  end: getFormattedFromDate(endOfMonth(nowDate), shiftDateFormat),
}

export const defaultDailyShiftDates: QueryDates = {
  start: getFormattedFromDate(nowDate, shiftDateFormat),
  end: getFormattedFromDate(nowDate, shiftDateFormat),
}

export const shiftUpdateTypes = {
  inserted: 'inserted',
  updated: 'updated',
}

export const shiftTypes: Record<'plannedShift' | 'absence' | 'effectiveShift', ShiftTypeT> = {
  plannedShift: 'shift',
  absence: 'absence',
  effectiveShift: 'effectiveShift',
}

export const absenceRequest = 'absenceRequest'

export const cmsEvent = 'cmsEvent'

type TimeTypeT = 'work' | 'pause' | 'vacation'
export const timeTypes: Record<TimeTypeT, TimeTypeT> = {
  work: 'work',
  pause: 'pause',
  vacation: 'vacation',
}

export const shiftUpdateFields = `
  costCenterName
  costCenterUuid
  date
  description
  from
  insertedAt
  percent
  personId
  shortDescription
  tenantId
  tenantName
  timeType
  type
  until
  note
  hexBackgroundColor
  hexTextColor
  isManual
`

export const DASHBOARD_NEXT_SHIFT_DATE = 'dashboardNextShiftDate'
