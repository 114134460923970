export type RequestEmployeeDataModeT = 'single' | 'global'

export const requestEmployeeDataModes: Record<string, RequestEmployeeDataModeT> = {
  single: 'single',
  global: 'global',
}

export type CreateShareUserDataResult = {
  createShareUserDataRequest?: {
    personsWithErrors: number[]
    success: boolean
  }
}
