import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'

interface IAvatarDeleteDialogProps {
  onCancel: () => void
  onDelete: () => void
}

const AvatarDeleteDialog: React.FC<IAvatarDeleteDialogProps> = ({ onCancel, onDelete }) => {
  const { t } = useFavurTranslation()
  return (
    <Dialog
      open
      title={t('page.userSettings.profile.settingsSection.deleteAvatarPopup.headline')}
      actions={
        <>
          <Button onClick={onCancel} color="secondary" size="large">
            {t('page.userSettings.profile.settingsSection.deleteAvatarPopup.cta.cancel')}
          </Button>
          <Button onClick={onDelete} color="secondary" size="large" data-testid="confirm-delete">
            {t('page.userSettings.profile.settingsSection.deleteAvatarPopup.cta.delete')}
          </Button>
        </>
      }
    >
      <Typography variant="body2">{t('page.userSettings.profile.settingsSection.deleteAvatarPopup.text')}</Typography>
    </Dialog>
  )
}

export default AvatarDeleteDialog
