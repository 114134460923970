import React from 'react'
import { Box, Typography } from '@mui/material'
import Alert from 'components/Alert'
import Card from 'components/Basics/Card'
import { ArrowRightThinS } from 'icons'
import { classes } from './styles'
import { ISectionCTA } from './types'

const SectionCTA: React.FC<ISectionCTA> = ({ onClick, title, subtitle, icon, alerts, disabled, ...props }) => {
  return (
    <Box sx={classes.root}>
      <Card onClick={onClick} disabled={disabled || undefined} {...props}>
        <Box sx={classes.content}>
          <Box sx={classes.innerContent}>
            {icon}
            <Box sx={classes.textContainer}>
              <Typography variant="body1">{title}</Typography>
              {subtitle && (
                <Typography variant="body2" sx={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={classes.alignSelfStart}>
            <ArrowRightThinS />
          </Box>
        </Box>
      </Card>
      {alerts && alerts.map((alert) => <Alert severity="info" key={alert} content={alert} />)}
    </Box>
  )
}

export default SectionCTA
