import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from '@mui/material'

const CenteredBox: React.FC<BoxProps & PropsWithChildren & { dataTestId?: string }> = ({
  children,
  dataTestId,
  ...otherProps
}) => {
  return (
    <Box textAlign="center" data-testid={dataTestId} {...otherProps}>
      {children}
    </Box>
  )
}

export default CenteredBox
