import theme from 'utils/theme'

export const headerClasses = {
  subheaderContainer: {
    position: 'relative',
    minHeight: '20px',
  },
  secondHeader: {
    marginTop: theme.spacing(1.125),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    position: 'absolute',
    left: 0,
  },
  iconWithTopTitle: {
    top: theme.spacing(1.1),
  },
}
