import React from 'react'
import { Box } from '@mui/material'
import { classes } from './styles'

const TeamsPlaceholder: React.FC = () => {
  return (
    <Box sx={classes.column}>
      <Box sx={[classes.row, classes.teamsTitle]}>
        <Box sx={classes.teamTitlePlaceholder} />
      </Box>
      {Array.from({ length: 2 }, (_r, i) => (
        <Box key={`row${i}`} sx={[classes.spaceBetween, classes.avatarRow, classes.row]}>
          {Array.from({ length: 10 }, (_c, j) => (
            <Box sx={classes.miniAvatarPlaceholder} key={`avatar-${i}-${j}`} />
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default TeamsPlaceholder
