import { ImageSizeT } from 'utils/avatar'
import theme from 'utils/theme'

const isPortrait = (imageSize: ImageSizeT | undefined) => imageSize && imageSize.height > imageSize.width

export const calcCropAreaHeight = (imageSize: ImageSizeT | undefined) => {
  const maxWidth = 375
  const maxHeight = 510
  if (!imageSize) return 252
  const { height: imgHeight, width: imgWidth } = imageSize
  if (isPortrait(imageSize)) {
    const heightRatio = maxHeight / imgHeight
    const scaledWidth = heightRatio * imgWidth
    if (scaledWidth > maxWidth) {
      const widthRatio = maxWidth / scaledWidth
      return maxHeight * widthRatio
    }
  }
  const widthRatio = maxWidth / imgWidth
  return imgHeight * widthRatio
}

export const classes = {
  mainContainer: {
    backgroundColor: theme.palette.common.black,
    height: '100%',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .reactEasyCrop_Container': {
      position: 'relative',
    },
    position: 'absolute',
  },
  cropperContainer: (bgImage: string) => ({
    width: '100%',
    height: '510px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '510px',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      filter: 'blur(10px)',
    },
  }),
  cropContainer: (imageSize?: ImageSizeT) => ({
    height: calcCropAreaHeight(imageSize),
    maxHeight: '510px',
  }),
  title: {
    color: theme.palette.common.white,
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0.375),
    paddingRight: theme.spacing(0.375),
    '& .MuiButton-root': { color: theme.palette.common.white },
  },
  loadingContainer: {
    position: 'absolute',
    top: 'calc(50% - 35px)',
    left: 'calc(50% - 30px)',
    zIndex: 10,
    alignItems: 'center',
  },
  buttonCancel: {
    color: 'white',
  },
  buttonSave: {
    color: 'white',
  },
}
