import React from 'react'
import { Box, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import { classes } from './styles'

export interface IPinDigit {
  number?: string
  selected?: boolean
  secret?: boolean
}

const PinDigit: React.FC<IPinDigit> = ({ number, selected, secret }) => {
  const unselectedAndFilled = number && !selected

  const renderUnderline = () => {
    if (selected) {
      return <Box aria-label="selected digit" sx={[classes.pinDigitUnderline, classes.pinDigitSelected]} />
    }
    if (unselectedAndFilled) {
      return <Box aria-label="unselected, filled digit" sx={[classes.pinDigitUnderline, classes.pinDigitFilled]} />
    }
    return <Box aria-label="unselected, unfilled digit" sx={[classes.pinDigitUnderline, classes.pinDigitEmpty]} />
  }

  return (
    <CenteredBox sx={classes.root}>
      <Typography variant="h1" sx={classes.digit}>
        {number && secret ? '*' : number}
      </Typography>
      {renderUnderline()}
    </CenteredBox>
  )
}

export default PinDigit
