export const classes = {
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    // to adjust the scroll with the action buttons
    paddingBottom: '48px',
  },
  dialog: {
    margin: '16px',
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  firstDialogButton: {
    marginBottom: '12px',
  },
}
