import { SecurityContactL, SecurityKeyL, SecurityPinEnterL, SecurityPinInfoL, SecurityPinRepeatL } from 'icons'
import { StepT } from 'types'
import InitialSetupIntro from './InitialSetup/Intro'
import InitialSetupPinEnter from './InitialSetup/PinEnter'
import PinInfo from './InitialSetup/PinInfo'
import InitialSetupPinRepeat from './InitialSetup/PinRepeat'
import Token from './InitialSetup/Token'
import CurrentPin from './PinChange/CurrentPin'
import PinChangePinEnter from './PinChange/PinEnter'
import PinChangePinRepeat from './PinChange/PinRepeat'
import PinResetIntro from './PinReset/Intro'
import PinResetPinEnter from './PinReset/PinEnter'
import PinResetPinInfo from './PinReset/PinInfo'
import PinResetPinRepeat from './PinReset/PinRepeat'
import PinResetToken from './PinReset/Token'
import SetPin from './SecureConnection/SetPin'
import VerifyToken from './SecureConnection/VerifyToken'

export const setupSteps: StepT[] = [
  { name: 'INTRO', index: 0, icon: SecurityContactL, component: InitialSetupIntro },
  { name: 'TOKEN', index: 1, icon: SecurityKeyL, component: Token },
  { name: 'PIN_INFO', index: 2, icon: SecurityPinInfoL, component: PinInfo },
  { name: 'PIN_ENTER', index: 3, icon: SecurityPinEnterL, component: InitialSetupPinEnter },
  { name: 'PIN_REPEAT', index: 4, icon: SecurityPinRepeatL, component: InitialSetupPinRepeat },
]

export const pinExistSteps: StepT[] = [
  { name: 'INTRO', index: 0, icon: SecurityContactL, component: InitialSetupIntro },
  { name: 'TOKEN', index: 1, icon: SecurityKeyL, component: Token },
]

export const pinResetSteps: StepT[] = [
  { name: 'INTRO', index: 0, icon: SecurityContactL, component: PinResetIntro },
  { name: 'TOKEN', index: 1, icon: SecurityKeyL, component: PinResetToken },
  { name: 'PIN_INFO', index: 2, icon: SecurityPinInfoL, component: PinResetPinInfo },
  { name: 'PIN_ENTER', index: 3, icon: SecurityPinEnterL, component: PinResetPinEnter },
  { name: 'PIN_REPEAT', index: 4, icon: SecurityPinRepeatL, component: PinResetPinRepeat },
]

export const pinChangeSteps: StepT[] = [
  { name: 'CURRENT_PIN', index: 0, icon: SecurityPinInfoL, component: CurrentPin },
  { name: 'PIN_ENTER', index: 1, icon: SecurityPinEnterL, component: PinChangePinEnter },
  { name: 'PIN_REPEAT', index: 2, icon: SecurityPinRepeatL, component: PinChangePinRepeat },
]

export const existentPinSecureConnectionSteps: StepT[] = [
  { name: 'TOKEN', index: 0, icon: SecurityKeyL, component: VerifyToken },
]

export const nonExistentPinSecureConnectionSteps: StepT[] = [
  { name: 'TOKEN', index: 0, icon: SecurityKeyL, component: VerifyToken },
  { name: 'SET_PIN', index: 1, icon: SecurityPinEnterL, component: SetPin },
]
