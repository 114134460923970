import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledDeniedM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        d="M12 13.5c2.62 0 4.04-.04 4.75-.08.51-.03 1.06-.25 1.17-.71.05-.18.08-.42.08-.71 0-.3-.03-.53-.08-.7-.11-.47-.66-.7-1.17-.72-.7-.04-2.13-.08-4.75-.08s-4.04.04-4.75.08c-.51.03-1.06.25-1.17.71-.05.18-.08.42-.08.71 0 .3.03.53.08.7.11.47.66.7 1.17.72.7.04 2.13.08 4.75.08Z"
      />
    </Svg>
  )
}

export default FilledDeniedM
