import React, { Context, createContext } from 'react'
import PinLogin from 'pages/PinLogin'
import { RouteComponentProps } from 'react-router-dom'
import UserDataDetailsContent from './ShareUserDataPageContent'

export type FilterContextT = {
  setShowForm: (value: boolean) => void
  refetch: () => void
  favurUuid: string
}

export const ShareUserDataFormContext: Context<FilterContextT> = createContext({
  setShowForm: (_value: boolean) => {},
  refetch: () => {},
  favurUuid: '',
})

const UserDataDetails: React.FC<RouteComponentProps<{ favurUuid: string }>> = ({ match }) => {
  const {
    params: { favurUuid },
  } = match

  return (
    <PinLogin>
      <UserDataDetailsContent favurUuid={favurUuid} />
    </PinLogin>
  )
}

export default UserDataDetails
