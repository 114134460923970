import React from 'react'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import Nbsp from 'components/Nbsp'
import { Variant } from 'pages/Balances/TimeBalance/types'
import { BalanceItem } from 'types/balances'
import { classes } from './styles'

interface ITimeBalanceRowProps {
  isCurrent: boolean
  firstColumn: {
    variant?: Variant
    text?: string
    unit?: string
  }
  secondColumn: {
    variant: Variant
    balance: BalanceItem
  }
  thirdColumn: {
    variant?: Variant
    balance?: BalanceItem
  }
}

const TimeBalanceRow: React.FC<ITimeBalanceRowProps> = ({ isCurrent, firstColumn, secondColumn, thirdColumn }) => {
  return (
    <TableRow sx={classes.balanceRow}>
      <TableCell sx={classes.balanceCell}>
        <Typography variant={firstColumn.variant}>{firstColumn.text}</Typography>
        <Typography variant="caption">
          <Nbsp />
          {firstColumn.unit}
        </Typography>
      </TableCell>
      <TableCell sx={classes.balanceCell}>
        <Box sx={classes.currentMonthData}>
          <Box sx={classes.negativeSign}>
            <Typography variant={secondColumn.variant}>
              <strong>{secondColumn.balance.value && secondColumn.balance.value < 0 ? '-' : ''}</strong>
            </Typography>
          </Box>
          <Box sx={classes.value}>
            <Typography variant={secondColumn.variant}>
              <strong>{secondColumn.balance.value !== null ? Math.abs(secondColumn.balance.value) : ''}</strong>
            </Typography>
          </Box>
        </Box>
        {isCurrent && (
          <Box sx={classes.estimationsData}>
            {thirdColumn.balance && (
              <>
                <Box sx={classes.negativeSign}>
                  <Typography variant={thirdColumn.variant}>
                    <strong>{thirdColumn.balance.value && thirdColumn.balance.value < 0 ? '-' : ''}</strong>
                  </Typography>
                </Box>
                <Box sx={classes.value}>
                  <Typography variant={thirdColumn.variant}>
                    <strong>{thirdColumn.balance.value !== null ? Math.abs(thirdColumn.balance.value) : ''}</strong>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}

export default TimeBalanceRow
