import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const ArrowLeftDoubleS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3584 13.8486C8.16589 14.0466 7.84933 14.051 7.65136 13.8585L4.95673 11.2381L2.2621 8.61782C2.18006 8.53809 2.11394 8.44242 2.06872 8.33636C2.02344 8.23017 1.99994 8.11582 1.99994 8C1.99994 7.88418 2.02344 7.76983 2.06872 7.66364C2.11394 7.55758 2.1798 7.46216 2.26184 7.38243L7.65136 2.14154C7.84933 1.94903 8.16589 1.95345 8.3584 2.15143C8.55091 2.3494 8.54649 2.66595 8.34851 2.85846L3.06116 8L8.34851 13.1415C8.54649 13.3341 8.55091 13.6506 8.3584 13.8486Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3584 13.8486C13.1659 14.0466 12.8493 14.051 12.6514 13.8585L9.95673 11.2381L7.2621 8.61782C7.18006 8.53809 7.11394 8.44242 7.06872 8.33636C7.02344 8.23017 6.99994 8.11582 6.99994 8C6.99994 7.88418 7.02344 7.76983 7.06872 7.66364C7.11394 7.55758 7.1798 7.46216 7.26184 7.38243L12.6514 2.14154C12.8493 1.94903 13.1659 1.95345 13.3584 2.15143C13.5509 2.3494 13.5465 2.66595 13.3485 2.85846L8.06116 8L13.3485 13.1415C13.5465 13.3341 13.5509 13.6506 13.3584 13.8486Z"
      />
    </Svg>
  )
}

export default ArrowLeftDoubleS
