import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { SUPPORTED_LANGUAGES } from 'constants/i18n'
import i18next from 'i18next'
import { isMobileWeb } from 'utils/platform'
import { classes } from '../styles'

interface ITeaserImage {
  appStoreLink: string
}

const deImageL = 'assets/images/AppTeaserImage/de/TeaserL.png'
const deImageM = 'assets/images/AppTeaserImage/de/TeaserM.png'
const deImageS = 'assets/images/AppTeaserImage/de/TeaserS.png'
const enImageL = 'assets/images/AppTeaserImage/en/TeaserL.png'
const enImageM = 'assets/images/AppTeaserImage/en/TeaserM.png'
const enImageS = 'assets/images/AppTeaserImage/en/TeaserS.png'
const frImageL = 'assets/images/AppTeaserImage/fr/TeaserL.png'
const frImageM = 'assets/images/AppTeaserImage/fr/TeaserM.png'
const frImageS = 'assets/images/AppTeaserImage/fr/TeaserS.png'
const itImageL = 'assets/images/AppTeaserImage/it/TeaserL.png'
const itImageM = 'assets/images/AppTeaserImage/it/TeaserM.png'
const itImageS = 'assets/images/AppTeaserImage/it/TeaserS.png'

const images = [
  [enImageL, enImageM, enImageS],
  [frImageL, frImageM, frImageS],
  [deImageL, deImageM, deImageS],
  [itImageL, itImageM, itImageS],
]

const getLanguageIndex = () => {
  if (SUPPORTED_LANGUAGES.includes(i18next.languages[0])) {
    return SUPPORTED_LANGUAGES.indexOf(i18next.languages[0])
  }
  return 0
}

const getImageSizeIndex = (isLarge: boolean, isMedium: boolean) => {
  if (isLarge) {
    return 0
  }
  if (isMedium) {
    return 1
  }
  return 2
}

const TeaserImage: React.FC<ITeaserImage> = ({ appStoreLink }) => {
  const isLarge = useMediaQuery('(min-height:1027px)')
  const isMedium = useMediaQuery('(min-height:667px)') && !isLarge
  const languageIndex = getLanguageIndex()
  const imageSizeIndex = getImageSizeIndex(isLarge, isMedium)

  return (
    <>
      {isMobileWeb() ? (
        <Box component="a" href={appStoreLink} target="_blank" rel="noopener noreferrer" sx={classes.imageLink}>
          <Box component="img" src={images[languageIndex][imageSizeIndex]} alt="Native App Teaser" sx={classes.image} />
        </Box>
      ) : (
        <Box component="img" src={images[languageIndex][imageSizeIndex]} alt="Native App Teaser" sx={classes.image} />
      )}
    </>
  )
}

export default TeaserImage
