import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const HourglassM: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_M}>
      <path
        fill="none"
        stroke={stroke || theme.palette.grey[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.01 7.26A5.66 5.66 0 0 0 12 12a5.66 5.66 0 0 0 3.99-4.74 1.18 1.18 0 0 0-.23-.88c-.1-.12-.22-.22-.35-.28A.94.94 0 0 0 15 6H9.01a.94.94 0 0 0-.42.1c-.13.06-.25.16-.35.28a1.18 1.18 0 0 0-.23.88ZM6 3h12M9 18a.94.94 0 0 1-.41-.1 1.02 1.02 0 0 1-.35-.28c-.1-.12-.16-.26-.2-.4a1.18 1.18 0 0 1-.03-.48A5.66 5.66 0 0 1 12 12a5.66 5.66 0 0 1 3.99 4.74 1.18 1.18 0 0 1-.23.88c-.1.12-.22.22-.35.28a.94.94 0 0 1-.42.1H9.01ZM6 21h12"
      />
    </Svg>
  )
}

export default HourglassM
