import React from 'react'
import { shiftDateFormat } from 'constants/dateFormats'
import { startOfMonth, endOfMonth, addMonths } from 'date-fns'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import TimeBalance from './TimeBalanceContainer'
import { IBalacesSwipeableProps, ISlideRendererParams } from './types'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

function slideRenderer({ params, resetDates, swipeIndex }: ISlideRendererParams) {
  const { index, key } = params
  const month = addMonths(startOfMonth(newDateWithoutTime()), index)

  return (
    <TimeBalance
      key={key}
      resetDates={resetDates}
      swipeIndex={swipeIndex}
      dates={{
        start: getFormattedFromDate(startOfMonth(month), shiftDateFormat),
        end: getFormattedFromDate(endOfMonth(month), shiftDateFormat),
      }}
    />
  )
}

const BalancesSwipeable = ({ resetDates, handleSwipeAction, swipeIndex }: IBalacesSwipeableProps) => {
  return (
    <VirtualizeSwipeableViews
      index={swipeIndex}
      overscanSlideAfter={swipeIndex < 0 ? 1 : 0}
      overscanSlideBefore={1}
      onChangeIndex={(newIndex, indexLatest) => {
        if (newIndex > 0) {
          return
        }

        handleSwipeAction(newIndex < indexLatest ? -1 : 1)
      }}
      slideRenderer={(params) => slideRenderer({ params, resetDates, swipeIndex })}
      enableMouseEvents
    />
  )
}

export default BalancesSwipeable
