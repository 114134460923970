import React from 'react'
import PinLogin from 'pages/PinLogin'
import TenantReminderListPageContent from './TenantReminderListContent'

const TenantReminderListPage: React.FC = () => {
  return (
    <PinLogin>
      <TenantReminderListPageContent />
    </PinLogin>
  )
}

export default TenantReminderListPage
