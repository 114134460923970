import React, { useMemo } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import AbsencePlannerDrawer from 'components/AbsencePlannerDrawer'
import AbsenceRequestConflicts from 'components/AbsenceRequestConflicts'
import { useAbsenceRequestConflicts } from 'hooks/useAbsenceRequestConflicts'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import theme from 'utils/theme'
import { classes } from './styles'
import { getPersonConflicts } from './utils'

interface IAsenceRequestConflictsCardProps {
  startDate: string
  endDate: string
  isManager?: boolean
  small?: boolean
  noPadding?: boolean
  onClick?: () => void
  showAbsencePlannerPreview?: boolean
  previewPerson?: PersonT
  showAbsencePlannerDrawer: boolean
  setShowAbsencePlannerDrawer: (value: boolean) => void
}

const AbsenceRequestConflictsCard: React.FC<IAsenceRequestConflictsCardProps> = ({
  startDate,
  endDate,
  isManager = false,
  small = false,
  noPadding = false,
  onClick,
  showAbsencePlannerPreview = false,
  previewPerson,
  showAbsencePlannerDrawer = false,
  setShowAbsencePlannerDrawer,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const previewTeams: string[] = (previewPerson?.teamPermissions ?? []).flatMap(
    (teamPermission) => teamPermission?.teamId ?? [],
  )

  const { conflicts: allConflicts, loading } = useAbsenceRequestConflicts({
    startDate: startDate,
    endDate: endDate,
    isManager: isManager,
    teamIds: previewTeams,
  })

  const history = useHistory()

  const handleClick = useMemo(() => {
    if (!showAbsencePlannerPreview) return onClick

    return () => {
      if (isSmallScreen) {
        setShowAbsencePlannerDrawer(true)
      } else {
        history.push(`${routes.absencePlanner}?startDate=${startDate}&endDate=${endDate}`)
      }
      onClick && onClick()
    }
  }, [endDate, history, isSmallScreen, onClick, setShowAbsencePlannerDrawer, showAbsencePlannerPreview, startDate])

  const conflicts = useMemo(() => {
    if (!allConflicts || !previewPerson) return allConflicts
    return getPersonConflicts(allConflicts, previewPerson.id as number)
  }, [allConflicts, previewPerson])

  if (loading || !conflicts) {
    return null
  }

  return (
    <Box sx={[!noPadding && classes.containerCard]}>
      {showAbsencePlannerPreview && (
        <AbsencePlannerDrawer
          isOpen={showAbsencePlannerDrawer}
          onCancel={() => setShowAbsencePlannerDrawer(false)}
          previewPerson={previewPerson}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <AbsenceRequestConflicts conflicts={conflicts} small={small} onClick={handleClick} />
    </Box>
  )
}

export default AbsenceRequestConflictsCard
