import theme from 'utils/theme'

export const classes = {
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: 0,
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  circle: {
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    minWidth: 0,
  },
  validateText: {
    letterSpacing: '0.3px',
    fontWeight: 600,
    fontStyle: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  spinning: {
    '@keyframes spinning': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(-360deg)' },
    },
    animation: 'spinning 1s infinite linear',
  },
}
