import React, { useState, useEffect } from 'react'
import { AuthenticationKind } from 'constants/authentication'
import useSessionContext from 'hooks/useSessionContext'
import { RouteComponentProps } from 'react-router-dom'
import EntryPointWithMirusLogo from '../EntryPointWithMirusLogo'
import EntryPointWithoutMirusLogo from '../EntryPointWithoutMirusLogo'
import Splash from './Splash'
import Start from './Start'
import Validate from './Validate'
import { ILoginStepProps, Step } from './types'

const getStepComponent = (step: Step): React.FC<ILoginStepProps> => {
  switch (step) {
    case 'START':
      return Start
    case 'VALIDATE':
      return Validate
    default:
      return Splash
  }
}

const LoginPage: React.FC<RouteComponentProps<{ step?: Step }>> = ({ match }) => {
  const { auth, phone } = useSessionContext()
  const { step: urlStep } = match.params
  const getInitialStep = () => {
    if (urlStep?.length) {
      return urlStep.toUpperCase() as Step
    }
    if (auth === AuthenticationKind.ANONYMOUS && phone) {
      return 'VALIDATE'
    }
    return 'SPLASH'
  }
  const [step, setStep] = useState<Step>(getInitialStep)
  const StepComponent = getStepComponent(step)

  useEffect(() => {
    auth === AuthenticationKind.ANONYMOUS && phone && setStep('VALIDATE')
  }, [auth, phone])

  return step === 'SPLASH' ? (
    <EntryPointWithMirusLogo>
      <StepComponent setStep={setStep} />
    </EntryPointWithMirusLogo>
  ) : (
    <EntryPointWithoutMirusLogo>
      <StepComponent setStep={setStep} />
    </EntryPointWithoutMirusLogo>
  )
}

export default LoginPage
