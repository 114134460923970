import React from 'react'
import useFavurNavigation from 'hooks/useFavurNavigation'
import NavigationGroup from '../NavigationGroup'

interface SecondaryNavigationProps {
  toggleDrawer: () => void
}

const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({ toggleDrawer }) => {
  const { secondaryNavigation: navPoints } = useFavurNavigation()

  return <NavigationGroup toggleDrawer={toggleDrawer} navPoints={navPoints} />
}

export default SecondaryNavigation
