import { DocumentTypes } from 'constants/documents'
import { TFunction } from 'i18next'

export const getBodyTranslation = (t: TFunction, documentType: string | undefined) => {
  switch (documentType) {
    case DocumentTypes.monthlySheet:
      return t(`notification.new.document.body`, {
        documentType: t('page.documents.documentType.monthly_sheet'),
      })
    case DocumentTypes.payslip:
      return t(`notification.new.document.body`, {
        documentType: t('page.documents.documentType.payslip'),
      })
    case DocumentTypes.salaryStatement:
      return t(`notification.new.document.body`, {
        documentType: t('page.documents.documentType.salary_statement'),
      })
    default:
      return t('notification.new.document.body_other_document_type')
  }
}
