import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip, Typography } from '@mui/material'
import Badge from 'components/Badge'
import EventCount from 'components/Cms/EventCount'
import { ChangeAlertS } from 'icons'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { CmsContentT, PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import theme from 'utils/theme'
import { hasAcknowledgedShiftUpdate } from '../utils'
import { classes } from './styles'

interface IShiftContentProps {
  sortedShifts: PlannedShiftT[]
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
  events?: CmsContentT[]
  containerRef: React.RefObject<HTMLDivElement>
}

const getMaxChips = (containerHeight: number | null) => {
  const chipHeight = 18
  const labelHeigth = 25
  if (!containerHeight) return 0
  return Math.floor((containerHeight - labelHeigth) / chipHeight)
}

const CalendarDayShifts: React.FC<IShiftContentProps> = ({ sortedShifts, shiftUpdates, events, containerRef }) => {
  const [maxChips, setMaxChips] = useState(0)

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  const eventsChip = useMemo(() => {
    if (!events || events.length === 0) return null

    return <Chip key="events" label={<EventCount numberOfEvents={events.length} />} sx={classes.eventsChip} />
  }, [events])

  useEffect(() => {
    const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
    setMaxChips(maxChipsNum)

    const callback = () => setMaxChips(maxChipsNum)

    window.removeEventListener('resize', callback)
    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [containerRef, sortedShifts])

  const numEvents = eventsChip !== undefined ? 1 : 0
  const hasOverflow = useMemo(() => sortedShifts.length + numEvents > maxChips, [
    maxChips,
    numEvents,
    sortedShifts.length,
  ])
  const chipsToShow = useMemo(() => (hasOverflow ? maxChips + numEvents - 1 : maxChips), [
    hasOverflow,
    maxChips,
    numEvents,
  ])

  const badges = useMemo(() => {
    const numShifts = sortedShifts.length
    const shifts = sortedShifts.map((shift, i) => {
      if (i < chipsToShow) {
        return hasAcknowledgedTask(shift) ? (
          <Badge shift={shift} key={getShiftKey(shift)} sx={classes.badge} mini />
        ) : (
          <Chip
            key={getShiftKey(shift)}
            label={<ChangeAlertS fill={theme.palette.primary.main} />}
            sx={classes.unacknowledgedChip}
          />
        )
      }
      return null
    })

    if (numEvents > 0) {
      if (numShifts < chipsToShow - 1) {
        return [...shifts, eventsChip]
      }
      return [...shifts.slice(0, chipsToShow - 1), eventsChip]
    }
    return shifts
  }, [chipsToShow, eventsChip, hasAcknowledgedTask, numEvents, sortedShifts])

  return (
    <>
      {badges}
      {hasOverflow && <Typography variant="body2">...</Typography>}
    </>
  )
}

export default CalendarDayShifts
