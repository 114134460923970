import theme from 'utils/theme'

export const classes = {
  registerContainerPaper: {
    flexGrow: 1,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(0.75),
    display: 'flex',
  },
  cancelButton: {
    flex: 1,
    marginRight: theme.spacing(0.5),
  },
  connectButton: {
    flex: 1,
    marginLeft: theme.spacing(0.5),
  },
}
