import React from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { SideMenuL } from 'icons'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import palette from 'utils/theme/palette'
import { taskBarStates } from '../../types'
import TabButton from '../TabButton'
import { iconTabsClasses } from '../styles'

const FiltersButton: React.FC = () => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()

  return (
    <TabButton tabOption={taskBarStates.filters} toolTipText={t('absencePlanner.taskbar.toolTip.filters')}>
      <Box sx={iconTabsClasses.menuIconWrapper}>
        <SideMenuL fill={taskBarState === taskBarStates.filters ? palette.secondary.main : palette.primary[700]} />
      </Box>
    </TabButton>
  )
}

export default FiltersButton
