import React, { useCallback, useMemo, useState } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useUserSettings from 'hooks/useUserSettings'
import CardToggle from 'pages/UserSettings/components/CardToggle'

interface IDashboardCardToggleProps {
  personUuid?: string
}

const DashboardCardToggle: React.FC<IDashboardCardToggleProps> = ({ personUuid = '' }) => {
  const { t } = useFavurTranslation()
  const [dashboardEmployeeCards, setDashboardEmployeeCards] = useState<string[] | undefined>(undefined)
  const { updateUserSettings, loading, refetch } = useUserSettings({
    onComplete: (newUserSettings) => {
      setDashboardEmployeeCards(newUserSettings?.dashboardEmployeeCards)
    },
  })

  const isChecked = useMemo(() => (dashboardEmployeeCards ?? []).includes(personUuid), [
    dashboardEmployeeCards,
    personUuid,
  ])
  const handleChange = useCallback<(event: React.ChangeEvent<HTMLInputElement>) => void>(
    async (evt) => {
      const updatedCards = evt.target.checked
        ? [...(dashboardEmployeeCards ?? []), personUuid]
        : (dashboardEmployeeCards ?? []).filter((cardId) => personUuid !== cardId)
      await updateUserSettings({ dashboardEmployeeCards: updatedCards })
      refetch()
    },
    [dashboardEmployeeCards, personUuid, refetch, updateUserSettings],
  )

  return (
    <CardToggle
      title={t('page.userSettings.employers.dashboardCardToggleTitle')}
      subtitle={t('page.userSettings.employers.dashboardCardToggleBody')}
      name={`${personUuid}-dashboard-card-toggle`}
      disabled={loading}
      checked={isChecked}
      onChange={handleChange}
    />
  )
}

export default DashboardCardToggle
