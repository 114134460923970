import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const UserDataShareL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M20.3 4.3l5.4 5.4a1 1 0 0 1 .3.71v5.84a.75.75 0 1 1-1.5 0v-5.63L19.38 5.5H7.5v21h5.75a.75.75 0 1 1 0 1.5H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h12.59a1 1 0 0 1 .7.3zM22.5 20a.5.5 0 0 1 .76-.43l.07.05 4 3.5a.5.5 0 0 1 .07.68l-.07.08-4 3.5a.5.5 0 0 1-.82-.3L22.5 27v-1.5h-1.36a6.5 6.5 0 0 0-1.39.15l-.34.08-.33.1-1.92.64a.5.5 0 0 1-.64-.61l.03-.08.62-1.24a5.5 5.5 0 0 1 4.68-3.03l.24-.01h.41V20zm1 1.1v.9a.5.5 0 0 1-.41.5h-1a4.5 4.5 0 0 0-3.92 2.3l-.1.19-.08.15.77-.25a7.5 7.5 0 0 1 1.69-.36l.34-.02.35-.01H23a.5.5 0 0 1 .5.41v.99l2.74-2.4-2.74-2.4zM15 19.25a.75.75 0 0 1 .1 1.5H10a.75.75 0 0 1-.1-1.5H15zm6-5a.75.75 0 0 1 .1 1.5H10a.75.75 0 0 1-.1-1.5H21zm0-5a.75.75 0 0 1 .1 1.5H10a.75.75 0 0 1-.1-1.5H21z"
      />
    </Svg>
  )
}

export default UserDataShareL
