import React, { forwardRef } from 'react'
import { Box } from '@mui/material'

interface INavigationItemWrapperProps {
  children: React.ReactNode
  onClick: () => void
}

const NavigationItemWrapper = forwardRef<HTMLDivElement, INavigationItemWrapperProps>(
  ({ children, onClick, ...props }, ref) => (
    <Box ref={ref} {...props} onClick={onClick}>
      {children}
    </Box>
  ),
)

export default NavigationItemWrapper
