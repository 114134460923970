import { useCallback } from 'react'
import { useAppStatesContext } from 'services/AppStates'
import { FilterStateT } from './types'

const getFiltersNameKey = (name: string) => `filters_${name}`
const getLoadFilterKey = (name: string) => `loadFilter_${name}`

const useFilterUtils = () => {
  const { clear, get, set } = useAppStatesContext()
  const storeState = useCallback(
    (name: string, state: FilterStateT) => {
      set(getFiltersNameKey(name), state)
    },
    [set],
  )

  const setLoadFilterTasks = useCallback(() => {
    set(getLoadFilterKey('tasks'), true)
  }, [set])

  const getStoredFilters = useCallback(
    (name: string | undefined): FilterStateT | null => {
      if (!name) {
        return null
      }

      const loadFilterItem = get(getLoadFilterKey(name))
      return loadFilterItem ? get(getFiltersNameKey(name)) : null
    },
    [get],
  )

  const clearStoredFilters = useCallback(
    (name: string) => {
      clear(getLoadFilterKey(name))
      clear(getFiltersNameKey(name))
    },
    [clear],
  )

  return {
    storeState,
    setLoadFilterTasks,
    getStoredFilters,
    clearStoredFilters,
  }
}

export default useFilterUtils
