import React, { useCallback, useContext, useState } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import { MutationResult, useMutation } from '@apollo/client'
import { deleteAvatarMutation } from '../queries'

const useProfilePicture = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const [selectedImageBase64, setSelectedImageBase64] = useState<string>()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const [deleteAvatar] = useMutation<MutationResult>(deleteAvatarMutation)

  const onFileChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target
      const fileToUpload = files?.item(0)
      if (fileToUpload) {
        const reader = new FileReader()
        reader.readAsDataURL(fileToUpload as Blob)
        reader.onload = () => {
          setSelectedImageBase64(reader.result as string)
        }
      }
    },
    [setSelectedImageBase64],
  )

  const onDeleteAvatar = useCallback(() => {
    deleteAvatar()
      .then(() => history.go(0))
      .catch(() => setFlashMessage(t('common.error.be.default')))
  }, [deleteAvatar, history, setFlashMessage, t])

  return { selectedImageBase64, setSelectedImageBase64, onFileChanged, onDeleteAvatar }
}

export default useProfilePicture
