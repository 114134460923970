import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { SideMenuL } from 'icons'
import classes from '../styles'
import SideMenu from './SideMenu'

interface ITaskHeaderProps {
  titleTrans: string
  setSideMenuOpen: (value: boolean) => void
  sideMenuOpen: boolean
  showSideMenuButton?: boolean
}

const TasksHeader: React.FC<ITaskHeaderProps> = ({ titleTrans, showSideMenuButton, setSideMenuOpen, sideMenuOpen }) => {
  return (
    <Box sx={classes.taskHeader}>
      <Box sx={classes.emptyHeaderBlock}>&nbsp;</Box>
      <Typography sx={classes.taskHeaderTitle} variant="h2">
        {titleTrans}
      </Typography>
      <Box sx={classes.emptyHeaderBlock}>
        {showSideMenuButton && (
          <>
            <IconButton
              sx={classes.sideMenuIcon}
              onClick={() => setSideMenuOpen(true)}
              data-testid="tasks-open-sidemenu"
              disableRipple
              size="large"
            >
              <SideMenuL />
            </IconButton>
            <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
          </>
        )}
      </Box>
    </Box>
  )
}

export default TasksHeader
