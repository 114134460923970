/* eslint-disable fp/no-this */
import React, { useCallback, useEffect, useState } from 'react'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import { GERMAN } from 'constants/i18n'
import useFavurForm from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTenantsAndPermissions from 'hooks/useTenantsAndPermissions'
import { SubmitHandler } from 'react-hook-form'
import { isValidDate, newDateWithoutTime, stringToFavurDate } from 'utils/date'
import * as yup from 'yup'
import { domesticFieldAndEmpty, nonDomesticFieldAndEmpty, validSocialInsuranceNumber, isDomestic } from '../helpers'
import { personalDocumentFileTypes } from './constants'
import type {
  FileTypeT,
  PersonalDataValuesT,
  PersonalDataApiValuesT,
  PersonalDocumentValuesKeysT,
  PersonalDataValuesKeysT,
} from './types'

export const usePersonalDataForm = ({
  validateDocuments,
  apiData,
  isFetching,
  onSubmit,
}: {
  validateDocuments: boolean
  apiData?: PersonalDataApiValuesT
  isFetching?: boolean
  onSubmit: (data: PersonalDataValuesT, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  const { t } = useFavurTranslation()
  const requiredFieldText = t('personalData.error.requiredField')
  const [formReady, setFormReady] = useState(false)
  const [defaultValues, setDefaultValues] = useState<PersonalDataValuesT>()
  const { tenantsAndPermissions } = useTenantsAndPermissions()

  const documentsValidation = useCallback(
    (field: string, nationality: string) => {
      if (!validateDocuments) {
        return true
      }
      // If we need to validate the documents and you are Swiss - The files are not required
      if (isDomestic(nationality)) {
        return true
      }
      // If we need to validate the documents - The files are required
      return Boolean(field)
    },
    [validateDocuments],
  )

  const hasOneHighSecurityConnection = tenantsAndPermissions?.some(
    (tenant) => tenant.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high,
  )

  const isDocument = (
    name: PersonalDataValuesKeysT | PersonalDocumentValuesKeysT,
  ): name is PersonalDocumentValuesKeysT => {
    const fileTypes = Object.keys(personalDocumentFileTypes)
    return fileTypes.includes(name as PersonalDocumentValuesKeysT)
  }

  const getFilesFromApi = (apiFileData: FileTypeT | null) => {
    if (apiFileData) {
      return { file: undefined, filename: apiFileData.filename, isExistingFile: true, createdAt: apiFileData.createdAt }
    }
    return null
  }

  const getInitValueDates = useCallback(
    (name: keyof PersonalDataValuesT) => {
      const val = apiData?.[name] ?? ''
      if (name === 'birthDate') {
        return stringToFavurDate(val.toString())
      }

      return val ? newDateWithoutTime(val.toString()) : ''
    },
    [apiData],
  )

  const getInitValue = useCallback(
    (key: keyof PersonalDataApiValuesT) => {
      const dates = ['birthDate', 'residenceCategoryValidUntil']

      if (key === 'contactLanguage') {
        return apiData?.[key] ?? GERMAN.code
      }

      if (dates.includes(key) && !isDocument(key)) {
        return getInitValueDates(key)
      }

      if (isDocument(key)) {
        return apiData ? getFilesFromApi(apiData[key]) : null
      }

      return apiData?.[key] ?? ''
    },
    [apiData, getInitValueDates],
  )

  const [formFields] = useState([
    {
      name: 'firstName',
      init: getInitValue('firstName'),
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'lastName',
      init: getInitValue('lastName'),
      validation: yup.string().required(requiredFieldText),
    },
    { name: 'nickname', init: getInitValue('nickname'), validation: yup.string().nullable() },
    { name: 'birthDate', init: getInitValue('birthDate'), validation: yup.string() },
    {
      name: 'gender',
      init: getInitValue('gender'),
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'nationality',
      init: getInitValue('nationality'),
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'contactLanguage',
      init: getInitValue('contactLanguage'),
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'placeOfOrigin',
      init: getInitValue('placeOfOrigin'),
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'socialInsuranceNumber',
      init: getInitValue('socialInsuranceNumber'),
      validation: yup
        .mixed()
        .test({
          name: 'socialInsuranceNumberRequired',
          test: function test() {
            const { parent } = this
            return !domesticFieldAndEmpty(parent.nationality, parent.socialInsuranceNumber)
          },
          message: requiredFieldText,
        })
        .test({
          name: 'socialInsuranceNumberValid',
          test: function test() {
            const { socialInsuranceNumber } = this.parent
            if (socialInsuranceNumber) {
              const errorKey = validSocialInsuranceNumber(socialInsuranceNumber)
              return errorKey ? this.createError({ message: t(errorKey), path: 'socialInsuranceNumber' }) : true
            }
            return true
          },
        }),
    },
    {
      name: 'healthInsurance',
      init: getInitValue('healthInsurance'),
      validation: yup.mixed().test({
        name: 'healthInsuranceRequired',
        test: function test() {
          const { parent } = this
          return !domesticFieldAndEmpty(parent.nationality, parent.healthInsurance)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'residenceCategoryType',
      init: getInitValue('residenceCategoryType'),
      validation: yup.mixed().test({
        name: 'residenceCategoryType',
        test: function test() {
          const { parent } = this
          return !nonDomesticFieldAndEmpty(parent.nationality, parent.residenceCategoryType)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'residenceCategoryValidUntil',
      init: getInitValue('residenceCategoryValidUntil'),
      validation: yup
        .mixed()
        .test({
          name: 'residenceCategoryValidUntil',
          test: function test() {
            const { parent } = this
            return !nonDomesticFieldAndEmpty(parent.nationality, parent.residenceCategoryValidUntil)
          },
          message: requiredFieldText,
        })
        .test({
          name: 'validResidenceCategoryDate',
          test: function test() {
            const { parent } = this
            return (
              domesticFieldAndEmpty(parent.nationality, parent.residenceCategoryValidUntil) ||
              isValidDate(parent.residenceCategoryValidUntil)
            )
          },
          message: t('personalData.error.dateInvalid'),
        }),
    },
    {
      name: 'residenceCategoryZemisNumber',
      init: getInitValue('residenceCategoryZemisNumber'),
      validation: yup.mixed().test({
        name: 'residenceCategoryZemisNumber',
        test: function test() {
          const { parent } = this
          return !nonDomesticFieldAndEmpty(parent.nationality, parent.residenceCategoryZemisNumber)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'residencePermitFront',
      init: getInitValue('residencePermitFront'),
      validation: yup.mixed().test({
        name: 'residencePermitFront',
        test: function test() {
          const { parent } = this
          return documentsValidation(parent?.residencePermitFront?.filename || '', parent.nationality)
        },
        message: requiredFieldText,
      }),
    },
    { name: 'residencePermitBack', init: getInitValue('residencePermitBack'), validation: undefined },
    {
      name: 'passportFront',
      init: getInitValue('passportFront'),
      validation: yup.mixed().test({
        name: 'passportFront',
        test: function test() {
          const { parent } = this
          return documentsValidation(parent?.passportFront?.filename || '', parent.nationality)
        },
        message: requiredFieldText,
      }),
    },
    { name: 'passportBack', init: getInitValue('passportBack'), validation: undefined },
  ])

  const { favurForm, getFormHandlers } = useFavurForm<PersonalDataApiValuesT>({
    context: 'personalData',
    prolongHighSecuritySession: hasOneHighSecurityConnection,
    formFields,
    onSubmit: onSubmit as SubmitHandler<PersonalDataValuesT>,
    // eslint-disable-next-line no-console
    onError: console.error,
  })

  const formHandlers = (name: keyof PersonalDataValuesT) => {
    const handlers = getFormHandlers(name)
    return {
      ...handlers,
      id: `user-employment-data_personal_${String(name)}`,
    }
  }

  const { reset } = favurForm

  useEffect(() => {
    if (!isFetching) {
      const withDefault = formFields.reduce((acc, field) => {
        return { ...acc, [field.name]: getInitValue(field.name as keyof PersonalDataValuesT) }
      }, {}) as PersonalDataValuesT

      reset(withDefault)
      setDefaultValues(withDefault)
      setFormReady(true)
    }
  }, [isFetching, formFields, getInitValue, reset])

  return { favurForm, formHandlers, formReady, defaultValues }
}
