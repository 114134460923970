import { useCallback } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useJamesMutation from 'hooks/useJamesMutation'
import {
  lowSecurityTenantNames,
  establishSecureConnectionMutation,
  taskByUuid,
} from 'pages/SecuritySetup/SecureConnection/queries'
import { SecureConnectionTaskT, TaskT } from 'pages/Tasks/types'
import { SecureConnectionResultT, TenantNamesT } from 'types'

const useVerifyToken = (taskUuid: string | null) => {
  const { data: lowSecurityTenantData, loading: lowSecLoading } = useJamesApolloQuery<{
    lowSecurityTenantNames: TenantNamesT[]
  }>(lowSecurityTenantNames)

  const { data: taskData, loading: taskLoading } = useJamesApolloQuery<{ task: TaskT<SecureConnectionTaskT> }>(
    taskByUuid,
    {
      skip: taskUuid === null,
      variables: { favurUuid: taskUuid },
    },
  )

  const loading = lowSecLoading || taskLoading

  const [establishSecureConnection] = useJamesMutation(establishSecureConnectionMutation)

  const verifyToken = useCallback(
    async (personId: number, token: string) => {
      const response = await establishSecureConnection({ personId, token })
      return response as { establishSecureConnection: SecureConnectionResultT }
    },
    [establishSecureConnection],
  )

  return {
    lowSecurityTenantsData: lowSecurityTenantData?.lowSecurityTenantNames,
    taskData: taskData?.task,
    onVerifyToken: verifyToken,
    loading,
  }
}

export default useVerifyToken
