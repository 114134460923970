import theme from 'utils/theme'

export const sendCommentBodyClasses = {
  body: {
    margin: '24px 0',
  },
  title: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
  textBody: {
    marginTop: '8px',
  },
}

export const successDrawerClasses = {
  bodyContainer: {
    padding: '16px 12px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    maxWidth: '600px',
    margin: '0 auto',
  },
}
