import React, { Context, createContext, useContext } from 'react'
import { BankDataValuesT } from './Bank/types'
import { ContactFormValuesT } from './Contact/types'
import { FamilyFormValuesT } from './Family/types'
import { PersonalDataApiValuesT, PersonalDataValuesT } from './PersonalData/types'
import { DataFormT } from './types'

export interface IFormsContext {
  getBankDataForm: () => DataFormT<BankDataValuesT, BankDataValuesT> | undefined
  getContactDataForm: () => DataFormT<ContactFormValuesT, ContactFormValuesT> | undefined
  getFamilyDataForm: () => DataFormT<FamilyFormValuesT, FamilyFormValuesT> | undefined
  getPersonalDataForm: () => DataFormT<PersonalDataApiValuesT, PersonalDataValuesT> | undefined
  onPersonalDataSubmit: () => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  getAllFormsValid: () => Promise<string | boolean>
  getIncludeFiles: () => boolean
  getCurrentStepSubmit: () => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>) | (() => void)
}

const initialState: IFormsContext = {
  getBankDataForm: () => undefined,
  getContactDataForm: () => undefined,
  getFamilyDataForm: () => undefined,
  getPersonalDataForm: () => undefined,
  onPersonalDataSubmit: () => () => {},
  getAllFormsValid: () => new Promise((resolve, _reject) => resolve(false)),
  getIncludeFiles: () => false,
  getCurrentStepSubmit: () => () => {},
}

export const FormsContext: Context<IFormsContext> = createContext(initialState)

export const useFormsContext = () => {
  return useContext(FormsContext)
}
