import theme from 'utils/theme'

export const classes = {
  requestAbsenceButton: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    borderRadius: '18px',
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    padding: '9px 22px',
    width: '100%',
    position: 'relative',
  },
  nativeEntryPointsContainer: {
    padding: '64px 24px 0 24px',
  },
  buttonAction: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '0px',
  },
  buttonActionBadgeAndText: {
    display: 'flex',
    gap: '12px',
  },
  buttonActionText: {
    position: 'relative',
    textAlign: 'left',
    top: '5px',
  },
  buttonActionArrow: {
    position: 'relative',
    top: '2px',
  },
}
