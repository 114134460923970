import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import useTaskBarStateContext, {
  useTaskBarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import useSelectedStatesContext, {
  useSelectedStatesActionsContext,
} from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { TaskBarStateT } from '../../types'
import { tabButtonClasses } from './styles'

interface TabButtonProps {
  children?: React.ReactNode
  tabOption: TaskBarStateT
  toolTipText: string
}

const TabButton: React.FC<TabButtonProps> = ({ children, tabOption, toolTipText }) => {
  const { taskBarState } = useTaskBarStateContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { setSelectedTaskUuid, setSelectedEvent } = useSelectedStatesActionsContext()
  const { selectedTaskUuid, selectedEvent } = useSelectedStatesContext()

  return (
    <Tooltip
      arrow
      title={
        <Typography variant="body2" sx={tabButtonClasses.toolTipTitle}>
          {toolTipText}
        </Typography>
      }
      placement="left"
      slotProps={{
        tooltip: {
          sx: tabButtonClasses.toolTipBody,
        },
        arrow: {
          sx: tabButtonClasses.toolTipArrow,
        },
      }}
    >
      <Box
        sx={[
          tabButtonClasses.iconWrapper,
          ...(taskBarState === tabOption ? [tabButtonClasses.selectedTabBackground] : []),
        ]}
        onClick={() => {
          if (selectedTaskUuid) setSelectedTaskUuid(null)
          if (selectedEvent) setSelectedEvent(null)
          setTaskBarState(taskBarState === tabOption ? null : tabOption)
        }}
        data-testid={`absence-planner-taskbar-tab_${tabOption}`}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

export default TabButton
