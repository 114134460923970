import React, { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import BottomDrawer from 'components/Basics/BottomDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface IAcceptMultipleWtcDrawerProps {
  acceptCurrent: () => void
  acceptBoth: () => void
  drawerOpen: boolean
  setDrawerOpen: (value: boolean) => void
  loading: { previous: boolean; current: boolean }
}

const AcceptMultipleWtcDrawer: React.FC<IAcceptMultipleWtcDrawerProps> = ({
  acceptCurrent,
  acceptBoth,
  drawerOpen,
  setDrawerOpen,
  loading,
}) => {
  const [isAcceptingBoth, setIsAcceptingBoth] = useState(false)
  const { t } = useFavurTranslation()
  const acceptBothText = useMemo(() => {
    if (!isAcceptingBoth || (!loading.current && !loading.previous)) {
      return t('monthlySheet.acceptMultipleWtcDrawer.acceptBoth')
    }
    if (loading.previous) return t('monthlySheet.acceptMultipleWtcDrawer.acceptingMultiple', { num: 1 })
    return t('monthlySheet.acceptMultipleWtcDrawer.acceptingMultiple', { num: 2 })
  }, [isAcceptingBoth, loading, t])
  return (
    <BottomDrawer
      isOpen={drawerOpen}
      onClose={() => {
        setDrawerOpen(false)
      }}
    >
      <Box sx={classes.container}>
        <Typography variant="body1">{t('monthlySheet.acceptMultipleWtcDrawer.text')}</Typography>
        <Box sx={classes.buttonContainer}>
          <Button
            sx={classes.button}
            onClick={() => {
              setIsAcceptingBoth(true)
              acceptBoth()
            }}
            variant="contained"
            disableRipple
            disabled={loading.current || loading.previous}
          >
            {acceptBothText}
          </Button>
          <Button
            sx={classes.button}
            onClick={() => acceptCurrent()}
            variant="contained"
            disableRipple
            disabled={loading.current || loading.previous}
          >
            {t(
              loading.current && !isAcceptingBoth
                ? 'monthlySheet.acceptMultipleWtcDrawer.accepting'
                : 'monthlySheet.acceptMultipleWtcDrawer.acceptCurrent',
            )}
          </Button>
        </Box>
      </Box>
    </BottomDrawer>
  )
}

export default AcceptMultipleWtcDrawer
