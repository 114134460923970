import React from 'react'
import { Box, Typography } from '@mui/material'
import { AcceptedForNextMonth } from 'components/TaskBadge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sendCommentBodyClasses } from '../styles'

const AcceptNextMonthHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={sendCommentBodyClasses.body}>
      <AcceptedForNextMonth isActing />
      <Typography variant="body2" sx={sendCommentBodyClasses.textBody}>
        {t('workTimeControl.acceptNextMonth.body')}
      </Typography>
    </Box>
  )
}

export default AcceptNextMonthHeader
