import theme from 'utils/theme'

export const classes = {
  heading: {
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(7.4375),
  },
  buttonContainer: {
    maxWidth: theme.spacing(10.5),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  registerButton: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1.25),
  },
}
