import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const TaskCenterM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M22.317 2.541a.75.75 0 0 0-1.133-.983l-9.157 10.553a.542.542 0 0 1-.196.142.59.59 0 0 1-.502-.018.53.53 0 0 1-.182-.153L8.352 8.325a.75.75 0 0 0-1.204.895l2.795 3.757c.184.248.424.449.697.59a2.087 2.087 0 0 0 2.52-.473l9.157-10.553Zm-5.436.918a9.75 9.75 0 1 0 3.735 4.233.75.75 0 0 0-1.364.625 8.25 8.25 0 1 1-3.16-3.582.75.75 0 0 0 .789-1.276Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default TaskCenterM
