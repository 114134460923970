import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import Numpad from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import useDialogs from 'hooks/useDialogs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinContext from 'hooks/usePinContext'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { classes as securityClasses } from '../../styles'
import { SecuritySetupProcessT } from '../../types'
import { classes } from './styles'

const PinEnter: React.FC<{ goToStep: (stepName: string) => void; securityProcessType: SecuritySetupProcessT }> = ({
  goToStep,
  securityProcessType,
}) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { securitySetupTokenConfirmed, pinChangePinConfirmed, setPinContext } = usePinContext()
  const [firstPin, setFirstPin] = useState('')
  const [pinLength, setPinLength] = useState(PIN_LENGTHS.default)
  const { openDialog } = useDialogs()

  const timeoutAction = useCallback(() => {
    openDialog(securityProcessType)
    history.push(routes.dashboard)
  }, [history, openDialog, securityProcessType])

  // security timeout handler
  useSessionTimeout(securityProcessType, timeoutAction)

  const getPinChangeLength = () => {
    return pinLength === PIN_LENGTHS.long ? PIN_LENGTHS.short : PIN_LENGTHS.long
  }
  const changePinLength = () => {
    setPinLength(getPinChangeLength())
  }
  const onConfirm = () => {
    if (firstPin.length === pinLength) {
      setPinContext({ pin: firstPin, pinLength, securitySetupTokenConfirmed, pinChangePinConfirmed })
      goToStep('PIN_REPEAT')
    }
    return null
  }

  useEffect(() => {
    if (!securitySetupTokenConfirmed && ['securitySetup', 'pinReset'].includes(securityProcessType)) {
      timeoutAction()
      return
    }
    if (!pinChangePinConfirmed && securityProcessType === 'pinChange') {
      timeoutAction()
    }
  }, [securitySetupTokenConfirmed, pinChangePinConfirmed, timeoutAction, securityProcessType])

  return (
    <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
      <Box>
        <Typography variant="h2" sx={securityClasses.title}>
          {t('securitySetup.pinEnter.title')}
        </Typography>
      </Box>
      <Box sx={classes.pinDisplayContainer}>
        <PinDisplay pinLength={pinLength} pin={firstPin} secret />
        <Button sx={classes.changePinLengthButton} onClick={changePinLength} data-testid="change-pin-length">
          {t('securitySetup.pinEnter.changePinLength', { pinLength: getPinChangeLength() })}
        </Button>
      </Box>
      <Box sx={classes.numpadContainer}>
        <Numpad numpadType="pin" onNumpadValueChange={setFirstPin} onConfirm={onConfirm} outputLength={pinLength} />
      </Box>
    </CenteredBox>
  )
}

export default PinEnter
