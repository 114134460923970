import { useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { employersConnectionsQuery } from 'shared/queries'
import { ConnectionResponseT, InvitationT, PersonT } from 'types'

const useConnections = () => {
  const [persons, setPersons] = useState<PersonT[]>([])
  const [invitations, setInvitations] = useState<InvitationT[]>([])
  const { loading } = useJamesApolloQuery<ConnectionResponseT>(employersConnectionsQuery, {
    onCompleted(data) {
      setPersons(data.connections.persons ?? [])
      setInvitations(data.connections.invitations ?? [])
    },
  })

  return { loading, persons, invitations }
}

export default useConnections
