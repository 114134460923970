import palette from 'utils/theme/palette'

export const styles = {
  contentCard: {
    display: 'flex',
    padding: '16px',
    borderColor: palette.primary[100],
    backgroundColor: 'white',
    borderRadius: '8px',
    justifyContent: 'space-between',
    ':hover': {
      cursor: 'pointer',
    },
  },
  dateRangeText: {
    color: palette.grey[600],
  },
  titleText: {
    color: palette.grey[900],
  },
  contentCardText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconText: {
    display: 'flex',
    gap: '12px',
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  contentCardLink: {
    textDecoration: 'none',
  },
}
