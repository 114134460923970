import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import UserSettingsSection from '../../../../components/Section'
import DeleteUser from './DeleteUser'

const OtherSettings: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <UserSettingsSection title={t('page.userSettings.profile.otherSettings')}>
      <DeleteUser />
    </UserSettingsSection>
  )
}

export default OtherSettings
