import React from 'react'
import ComponentBadge from 'components/ComponentBadge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ManagerTasksL } from 'icons'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import palette from 'utils/theme/palette'
import { taskBarStates } from '../../types'
import TabButton from '../TabButton'

const ManagerTasksButton: React.FC<{ taskCountTotal: number }> = ({ taskCountTotal }) => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()

  return (
    <TabButton tabOption={taskBarStates.managerTasks} toolTipText={t('absencePlanner.taskbar.toolTip.managerTasks')}>
      <ComponentBadge badgeContent={taskCountTotal} max={999}>
        <ManagerTasksL
          fill={taskBarState === taskBarStates.managerTasks ? palette.secondary.main : palette.primary[700]}
        />
      </ComponentBadge>
    </TabButton>
  )
}

export default ManagerTasksButton
