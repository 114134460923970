import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const MonatsblattReadXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill} data-testid="monthly-sheet-icon_status-seen">
      <path
        fillRule="evenodd"
        d="M34 8c.552 0 1 .448 1 1v13.444l5.386-4.189c.436-.339 1.064-.26 1.403.176.137.175.211.391.211.614V39c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1V19.045c0-.553.448-1 1-1 .222 0 .438.074.614.21L13 22.444V9c0-.552.448-1 1-1h20zm6 13.088L26.352 31.705c-1.333 1.036-3.154 1.08-4.527.13l-.177-.13L8 21.089V38h32V21.088zM33 10H15v14l7.876 6.126c.624.485 1.46.517 2.111.097l.137-.097L33 23.999V10z"
        transform="translate(-24 -121) translate(24 121)"
      />
    </Svg>
  )
}

export default MonatsblattReadXL
