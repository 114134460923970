import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledRecycleAcceptM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.518 6.564a1.83 1.83 0 0 0-.8.449c-.214.205-.37.459-.457.737h3.478a1.74 1.74 0 0 0-.483-.763 1.835 1.835 0 0 0-.81-.433 1.88 1.88 0 0 0-.928.01Zm-1.71-.781A3.222 3.222 0 0 0 8.67 7.75H5.778a.803.803 0 0 0-.55.22A.749.749 0 0 0 5 8.5a.727.727 0 0 0 .228.53.78.78 0 0 0 .55.22h1.037v8.25c0 .398.164.78.455 1.06.292.282.688.44 1.1.44h7.26c.412 0 .808-.158 1.1-.44.291-.28.455-.662.455-1.06V9.25h1.037a.793.793 0 0 0 .55-.22A.736.736 0 0 0 19 8.5a.737.737 0 0 0-.228-.53.793.793 0 0 0-.55-.22H15.33a3.222 3.222 0 0 0-1.139-1.967A3.445 3.445 0 0 0 12 5.002c-.804 0-1.58.277-2.191.78Zm6.142 6.276a.75.75 0 0 0-1.01-1.11l-4.44 4.037-1.44-1.31a.75.75 0 0 0-1.01 1.11l1.777 1.616a1 1 0 0 0 1.346 0l4.777-4.343Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default FilledRecycleAcceptM
