import theme from 'utils/theme'

export const classes = {
  employerNameContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'start',
  },
  gridData: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  gridDataRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridDataRowTitle: {
    color: theme.palette.grey[600],
  },
}
