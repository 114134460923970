import React from 'react'
import { Box } from '@mui/material'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export default TabPanel
