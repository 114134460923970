import React from 'react'
import { Box, Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NavLink } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

const ForgottenPin: React.FC<Record<string, unknown>> = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.forgottenBox}>
      <Button color="primary" component={NavLink} size="small" to={routes.pinReset} data-testid="forgotten-pin">
        {t('component.pinLogin.forgotten')}
      </Button>
    </Box>
  )
}

export default ForgottenPin
