import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { pinChangeSteps } from '../constants'
import { PinChangeStepName } from '../types'
import PinChangeProcess from './PinChangeProcess'

const PinChange: React.FC<RouteComponentProps<{ step?: PinChangeStepName }>> = ({ match }) => {
  const { step: urlStep } = match.params

  return <PinChangeProcess steps={pinChangeSteps} urlStep={urlStep} />
}

export default PinChange
