import React from 'react'
import { IconButton } from '@mui/material'
import NumpadDeleteS from 'icons/NumpadDeleteS'
import { classes } from '../styles'

export interface INumpadDelete {
  onClick: () => void
}

const NumpadDelete: React.FC<INumpadDelete> = ({ onClick }) => {
  return (
    <IconButton
      aria-label="Numpad delete"
      sx={classes.iconButton}
      onClick={onClick}
      data-testid="numpad-delete"
      size="large"
    >
      <NumpadDeleteS />
    </IconButton>
  )
}

export default NumpadDelete
