import theme from 'utils/theme'

export const absenceRequestListClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
    position: 'relative',
  },
  header: (isScrolled: boolean) => ({
    position: 'relative',
    zIndex: 5,
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 12,
      right: 12,
      content: '""',
      zIndex: -1,
      boxShadow: isScrolled ? theme.shadows[10] : 'none',
    },
  }),
  headerContainer: {
    padding: '0px 24px 0 24px',
    background: theme.palette.common.white,
  },
  headerText: {
    margin: '16px 0',
    // @ts-ignore
    color: theme.palette.primary[700],
  },
  title: {
    fontWeight: 500,
    lineHeight: '24px',
  },
  subtitle: {
    lineHeight: '24px',
  },
  taskListContainer: {
    padding: '16px 24px 32px 24px',
    flex: 1,
    overflowY: 'scroll',
  },
  footer: (hasOverflow: boolean, isAtBottom: boolean) => ({
    position: 'relative',
    zIndex: 5,
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 12,
      right: 12,
      content: '""',
      zIndex: -1,
      boxShadow: hasOverflow && !isAtBottom ? theme.shadows[9] : 'none',
    },
  }),
  bottomDivisionLineContainer: {
    margin: '0 24px',
  },
  viewAllTasksButton: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContainer: {
    background: theme.palette.common.white,
    padding: '16px 24px',
    paddingBottom: 'calc(24px + var(--ion-safe-area-bottom))',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  allTasksButtonIconWrapper: {
    marginLeft: '4px',
  },
  loadMore: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerFlex: {
    display: 'flex',
    justifyContent: 'start',
    gap: '16px',
  },
  iconButton: {
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  formContainer: {
    padding: '24px',
  },
  avoidButtons: {
    '&>div': {
      marginBottom: 'calc(108px + var(--ion-safe-area-bottom))',
    },
  },
  page: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'white',
    zIndex: 50,
    boxSizing: 'border-box',
    paddingBottom: 'calc(16px + var(--ion-safe-area-bottom))',
    paddingTop: 'calc(16px + var(--ion-safe-area-top))',
  },
}
