import theme from 'utils/theme'

export const classes = {
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  cardWithAlert: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  lastSharedContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '& :first-child': {
      color: theme.palette.grey[600],
    },
  },
  neverShared: {
    color: theme.palette.error.main,
  },
}
