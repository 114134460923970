import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const SuccessSealS: React.FC<IconPropsT> = ({ stroke = '#1DAF2A' }) => {
  return (
    <Svg size={ICON_SIZE_S} fill="none">
      <path
        d="M10 6.5 7.8768 9.33971c-.0344.04594-.07824.08394-.12855.11143-.0503.02748-.10591.0438-.16305.04786-.05714.00406-.11449-.00425-.16815-.02434-.05367-.0201-.10241-.05153-.14292-.09216L6 8.10496"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32292 2.32439c.08143-.10126.18451-.18297.30167-.23911C7.74175 2.02914 7.86999 2 7.99989 2c.1299 0 .25815.02914.3753.08528.11716.05614.22025.13785.30167.23911l.80597 1.0032c.09128.11381.20974.20279.34445.25871.13472.05593.28132.07701.42632.06129l1.2793-.1392c.1295-.0144.2606.00054.3836.04369.1229.04316.2346.11343.3267.20561.0922.09219.1624.20393.2055.32695.0432.12302.0581.25417.0437.38375l-.1391 1.27999c-.0157.14508.0053.29178.0612.42656s.1449.25331.2586.34464l1.0027.8064c.1012.08147.1829.18461.239.30183.0561.11721.0852.24553.0852.3755s-.0291.25828-.0852.3755c-.0561.11722-.1378.22036-.239.30183l-1.0059.80266c-.1137.09148-.2026.21011-.2585.34497-.0559.13486-.077.28163-.0613.42673l.1391 1.28c.0145.1296-.0003.2608-.0434.3839-.0431.1231-.1134.2348-.2055.327-.0922.0923-.2039.1625-.3269.2056-.123.0432-.2541.058-.3837.0435l-1.2793-.1392c-.145-.0157-.29162.0053-.42634.0612-.13472.056-.25318.145-.34443.2588l-.80277.9995c-.08137.1013-.18443.1831-.3016.2393-.11716.0562-.24544.0854-.37537.0854s-.2582-.0292-.37537-.0854c-.11716-.0562-.22023-.138-.3016-.2393l-.80543-1.0027c-.0914-.1138-.20996-.2028-.34475-.2587-.1348-.0559-.2815-.077-.42657-.0613l-1.27932.1392c-.12954.0146-.26071-.0002-.38376-.0432-.12305-.0431-.23482-.1134-.327-.2056-.09218-.0923-.16241-.2041-.20547-.3272-.04306-.1231-.05784-.2544-.04325-.384l.13912-1.28c.01571-.1451-.00536-.29189-.06125-.42676-.05589-.13487-.14482-.25349-.25857-.34494l-1.00213-.80586c-.1013-.08141-.18305-.18453-.23921-.30176C2.02916 8.25525 2 8.12691 2 7.99691s.02916-.25835.08533-.37557c.05616-.11723.13791-.22035.23921-.30176l1.00213-.8064c.11379-.0913.20274-.20982.25864-.34461.0559-.13479.07695-.28151.06118-.42659l-.13912-1.27999c-.01449-.1296.00036-.26079.04346-.38386.04309-.12307.11333-.23485.20549-.32706.09216-.09221.20388-.16248.32688-.2056.123-.04312.25413-.05798.38365-.04348l1.27932.1392c.14507.01566.29175-.00544.42654-.06136.13479-.05592.25335-.14486.34478-.25864l.80543-.9968Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
export default SuccessSealS
