import React, { useCallback } from 'react'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import usePerformEventToTask from '../ActionButtons/usePerformEventToTask'
import DefaultHeader from './DefaultHeader'

interface ISendCommentProps {
  isOpen: boolean
  onCancel: () => void
  taskUuid: string
  eventOnSuccess: EventsOfTask
  eventCompleted: () => void
  children?: React.ReactNode
  required?: boolean
}

const MAX_LENGTH_COMMENT = 2000

const SendComment: React.FC<ISendCommentProps> = ({
  isOpen,
  onCancel,
  taskUuid,
  eventOnSuccess,
  eventCompleted,
  children,
  required = false,
}) => {
  const { t } = useFavurTranslation()
  const {
    loading,
    sendCommentToManager,
    sendCommentToFrontline,
    acceptForThisMonth,
    acceptForNextMonth,
  } = usePerformEventToTask(taskUuid, eventCompleted)

  const onRequestChange = useCallback(
    (comment: string) => {
      switch (eventOnSuccess) {
        case EventsOfTask.monthlySheetSendCommentToManager:
          sendCommentToManager(comment)
          break
        case EventsOfTask.monthlySheetSendCommentToFrontline:
          sendCommentToFrontline(comment)
          break
        case EventsOfTask.monthlySheetAcceptThisMonth:
          acceptForThisMonth(comment)
          break
        case EventsOfTask.monthlySheetAcceptNextMonth:
          acceptForNextMonth(comment)
          break
        default:
          return
      }
    },
    [eventOnSuccess, sendCommentToManager, sendCommentToFrontline, acceptForThisMonth, acceptForNextMonth],
  )

  return (
    <TextInputDrawer
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onRequestChange}
      successButtonText={t('workTimeControl.requestChange.successButton')}
      textFieldMaxLength={MAX_LENGTH_COMMENT}
      textFieldPlaceHolder={t('workTimeControl.requestChange.inputPlaceholder')}
      successButtonDisabled={loading}
      required={required}
      testIdName="monthly-sheet-detail_comment"
    >
      {children ? children : <DefaultHeader />}
    </TextInputDrawer>
  )
}

export default SendComment
