import React from 'react'
import { Box } from '@mui/material'
import LoadMoreButton from 'components/LoadMoreButton'
import { SimpleLoading } from 'components/LoadingIcon'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import { CmsContentT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { styles } from '../styles'
import ContentCard from './ContentCard'
import NoPages from './NoPages'

const PageList: React.FC<{ ownerId?: number }> = ({ ownerId }) => {
  const { list, loading, hasMoreElements, loadMore } = useListCmsPaginatedContents({
    contentType: CONTENT_TYPES.page,
    ownerId,
  })

  if (loading) {
    return <SimpleLoading />
  }

  if (!loading && list?.length === 0) {
    return <NoPages />
  }

  return (
    <>
      <Box sx={styles.cardContainer}>
        {list?.map((cardContent: CmsContentT) => (
          <ContentCard key={cardContent.uuid} content={cardContent} />
        ))}
      </Box>
      {loading && <SimpleLoading />}
      {!loading && hasMoreElements && <LoadMoreButton onClick={loadMore} />}
    </>
  )
}

export default PageList
