import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const DashboardAppActive: React.FC<IconPropsT & { negative?: boolean }> = ({ negative }) => {
  const birdColor = negative ? '#FFF' : '#1F3968'
  return (
    <Svg size={43} data-testid="dashboard-icon-active">
      <path
        fill="#FF007C"
        d="M25.16.85a2 2 0 0 1 2.6-.06l.13.12 11.8 12.47a1.5 1.5 0 0 1-.84 2.61l-.16.01H35v12a2 2 0 0 1-1.85 2H21v-2h12V14h4.53L26.5 2.34l-9.24 8.18-1.32-1.5L25.16.85z"
      />
      <path fill={birdColor} d="M13 9l6 6v16L7 43V15z" />
      <path fill="#FF0062" d="M16 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
      <path fill="#191919" d="M14.5 16a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
      <path fill="#FFF" d="M15.73 14.77L14.5 16H13l2.12-2.12c.26.25.47.55.62.89z" />
      <path fill="#FF0062" d="M19 21v-6h6z" />
      <path fill="#FFC300" d="M19 18v-3h3z" />
      <path fill="#546D99" d="M16 21l-9 9v10l9-9z" />
    </Svg>
  )
}

export default DashboardAppActive
