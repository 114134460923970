import { StepT } from 'types'

export const getStep = (stepName: string | undefined, steps: StepT[], extraDefaultStep?: StepT): StepT => {
  const defaultStep = extraDefaultStep ?? steps[0]

  if (stepName?.length) {
    const searchStep = steps.find((e) => e.name === stepName.toUpperCase())
    return searchStep || defaultStep
  }

  return defaultStep
}
