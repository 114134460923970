export const PERSON_STATUS = {
  ACTIVE: 'active',
  APPLICANT: 'applicant',
  INACTIVE: 'inactive',
  DISCONNECTED: 'disconnected',
}

// status considered as active persons
export const ACTIVE_PERSON_STATUS = [PERSON_STATUS.ACTIVE, PERSON_STATUS.APPLICANT]

// status considered as inactive persons
export const INACTIVE_PERSON_STATUS = [PERSON_STATUS.INACTIVE, PERSON_STATUS.DISCONNECTED]

export const PERSONS_LIST_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}
