import React, { useState } from 'react'
import { Box, Button, FormControlLabel, List, ListItem, Paper, Typography } from '@mui/material'
import Page from 'components/Page'
import Select from 'components/Select'
import Switch from 'components/Switch'
import featureFlags, { FeatureFlagType } from 'constants/featureFlags'
import { APPLICATION_ENV } from 'env'
import useFeatureFlagContext from 'hooks/useFeatureFlagContext'
import { classes } from './styles'

const SettingsPage: React.FC = () => {
  const { settings } = useFeatureFlagContext()
  const [throwError, setThrowError] = useState(false)

  if (throwError) {
    throw new Error('Break the app on purpose to see the error page')
  }

  return (
    <Page>
      <Box component={Paper} p={1}>
        <List>
          {Object.entries(featureFlags).map((featureFlag) => {
            const name = featureFlag[0] as FeatureFlagType
            const type = typeof featureFlag[1] === 'object' ? 'select' : 'checkbox'
            const flagLabel = typeof featureFlag[1] === 'object' ? featureFlag[1].label : featureFlag[1]
            const options = typeof featureFlag[1] === 'object' ? featureFlag[1].options : []

            return (
              <ListItem key={name}>
                <FormControlLabel
                  sx={classes.formControlLabel}
                  control={
                    type === 'select' ? (
                      <Select
                        value={settings(name)}
                        onChange={(evt) => settings(name as FeatureFlagType, evt.target.value)}
                        name={name}
                      >
                        {options.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Switch
                        // @ts-ignore
                        checked={settings(name) ?? false}
                        onChange={(evt) => settings(name as FeatureFlagType, evt.target.checked)}
                        name={name}
                      />
                    )
                  }
                  label={flagLabel}
                  labelPlacement="start"
                />
              </ListItem>
            )
          })}
          {APPLICATION_ENV !== 'LIVE' && (
            <>
              <ListItem>
                <Typography variant="caption">
                  * Those Feature Flags will only change the FE. The BE may fail depending on the actions performed
                  because of security middlewares. If you want to use the features fully you need to change the
                  tenant&apos;s package in Carmen.
                </Typography>
              </ListItem>
              <Button
                sx={classes.breakAppButton}
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => setThrowError(true)}
              >
                Throw new error (break the app)
              </Button>
            </>
          )}
        </List>
      </Box>
    </Page>
  )
}

export default SettingsPage
