import theme from 'utils/theme'

export const classes = {
  stepper: {
    '&.MuiStepper-horizontal': {
      padding: 0,
    },
  },
  step: {
    '&.MuiStep-root': {
      flexGrow: 1,
      padding: theme.spacing(1, 0),
      marginRight: theme.spacing(0.25),
      color: theme.palette.primary.light,
      borderBottom: `4px solid ${theme.palette.primary.light}`,
      '&:last-child': {
        marginRight: 0,
      },
    },
    '&.Mui-completed': {
      color: theme.palette.secondary.light,
      borderBottom: `4px solid ${theme.palette.secondary.light}`,
    },
  },
  stepLabel: {
    '&.MuiStepLabel-root': {
      display: 'block',
    },
    '& .MuiStepLabel-iconContainer': {
      height: theme.spacing(2),
      width: theme.spacing(2),
      padding: 0,
      display: 'block',
      margin: '0 auto',
    },
  },
  active: {
    '&.MuiStep-root': {
      color: theme.palette.secondary.main,
      borderBottom: `4px solid ${theme.palette.secondary.main}`,
    },
  },
}
