import React from 'react'
import { Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import EmployeeList from 'components/EmployeeList'
import useFavurTranslation from 'hooks/useFavurTranslation'
import EditM from 'icons/EditM'
import { sortBy } from 'lodash/fp'
import { classes } from 'pages/Teams/styles'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import type { TeamT } from 'types'
import { getSortingLastName } from 'utils/person'
import { isNative } from 'utils/platform'
import FloatingBottomButton from '../../../../../components/FloatingBottomButton'
import { getTeamMembers } from '../../useTeam'

export interface MemberTabPropsT {
  team: TeamT
  ownerHasMultipleTenants?: boolean
}

const MembersTab: React.FC<MemberTabPropsT> = ({ team, ownerHasMultipleTenants = false }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const persons = getTeamMembers(team)

  return (
    <>
      {persons.length === 0 ? (
        <Card>
          <Typography variant="body1" sx={classes.noPersonsText} data-testid="no-persons">
            {t('page.teams.noPersonsInTeam')}
          </Typography>
        </Card>
      ) : (
        <EmployeeList
          employees={sortBy((p) => getSortingLastName(p), persons)}
          onClickItem={(employee) =>
            history.push(`${routes.teams}/${team.id}/${routes.teamDetailMember}/${employee.favurUuid}`)
          }
          checkValidInvitation
          showTenant={ownerHasMultipleTenants}
          checkResignationDate
        />
      )}
      <FloatingBottomButton
        Icon={EditM}
        label={t('page.teams.editMembersListButton')}
        onClick={() => history.push(`${routes.teams}/${team.id}/${routes.teamEditMembers}`)}
        testId="edit-members"
        sx={isNative() ? classes.nativeBottomMargin : []}
      />
    </>
  )
}

export default MembersTab
