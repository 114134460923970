import React from 'react'
import { Chip, Typography, IconButton, Box } from '@mui/material'
import { CloseS } from 'icons'
import { isNative } from 'utils/platform'
import useFilterContext from '../context'
import { classes } from '../styles'

const ChipList: React.FC = () => {
  const { getChips, removeChip, openFilterPage } = useFilterContext()

  return (
    <Box component="ul" sx={[classes.allChipsContainer, ...(isNative() ? [classes.scrollingAllChipsContainer] : [])]}>
      {getChips().map((data) => {
        return (
          <li key={data.filterName}>
            <Chip
              size="small"
              label={
                <Typography variant="button" sx={classes.label}>
                  {data.label}
                </Typography>
              }
              onClick={openFilterPage}
              onDelete={() => removeChip(data)}
              deleteIcon={
                <IconButton sx={classes.deleteIconButton} size="large">
                  <CloseS fill="white" />
                </IconButton>
              }
              // added class to not change the bg color onhover on native, seems like a bug of mui that leaves hover state hanged
              sx={[classes.chip, ...(isNative() ? [classes.chipNative] : [])]}
            />
          </li>
        )
      })}
    </Box>
  )
}

export default ChipList
