import React, { PropsWithChildren } from 'react'
import { SwipeableDrawer, SxProps, Theme } from '@mui/material'
import { isNative } from 'utils/platform'
import Puller from './Puller'
import { classes } from './styles'

interface IBaseDrawerProps {
  open: boolean
  onClose: () => void
  testIdName?: string
  paperSx?: SxProps<Theme>
  focusingTextField?: boolean
  fullHeight?: boolean
  disableBackdrop?: boolean
}

const BaseDrawer: React.FC<PropsWithChildren<IBaseDrawerProps>> = ({
  open,
  onClose,
  children,
  paperSx,
  testIdName,
  focusingTextField,
  disableBackdrop = false,
  fullHeight = false,
}) => {
  const handleClose = (event: React.SyntheticEvent) => {
    // Prevents the drawer closes when backdrop is clicked if disableBackdop
    if (event && disableBackdrop && (event.target as HTMLElement).classList.contains('MuiBackdrop-root')) {
      return
    }
    onClose()
  }

  return (
    <SwipeableDrawer
      open={open}
      onClose={handleClose}
      anchor="bottom"
      onOpen={() => {}}
      sx={classes.drawer}
      PaperProps={{
        sx: [
          classes.paper,
          ...(fullHeight ? [classes.fullHeightPaper] : []),
          ...(Array.isArray(paperSx) ? paperSx : [paperSx]),
        ],
      }}
      disableDiscovery
      disableAutoFocus
      onTouchStart={(event) => {
        if (focusingTextField) {
          // @ts-ignore
          event.nativeEvent.defaultMuiPrevented = true
        }
      }}
      swipeAreaWidth={0}
      data-testid={testIdName}
    >
      {isNative() && <Puller />}
      {children}
    </SwipeableDrawer>
  )
}

export default BaseDrawer
