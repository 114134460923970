import React from 'react'
import { Box, Fab } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { AddM } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import { absenceButtonClasses } from './styles'

const AbsenceButton: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setBackLink } = useStateBackLink()

  return (
    <Fab
      variant="extended"
      size="small"
      onClick={() => {
        setBackLink(routes.balances)
        history.push(routes.newAbsenceRequest)
      }}
      sx={[
        absenceButtonClasses.AbsenceButtonBase,
        ...(isNative() ? [absenceButtonClasses.AbsenceButtonNativeMargin] : []),
      ]}
      data-testid="new-absence-request"
    >
      <Box mr={0.25} ml={0.375} display="flex" alignItems="center">
        <AddM />
      </Box>
      <Box mr={0.375} display="flex" alignItems="center">
        {t('page.shifts.content.absence')}
      </Box>
    </Fab>
  )
}

export default AbsenceButton
