import { useCallback } from 'react'
import { ISecuritySetupMutationResult, SecuritySetupProcessT } from 'pages/SecuritySetup/types'
import useSecuritySetupMutation from 'pages/SecuritySetup/useSecuritySetupMutation'

const useSetPin = (securityProcessType: SecuritySetupProcessT, pin: string | undefined) => {
  const [setPinAndEstablishSecureConnection] = useSecuritySetupMutation(securityProcessType)

  const setPinAndSecConnection = useCallback(async () => {
    const response = await setPinAndEstablishSecureConnection({ pinCode: pin })
    return response as ISecuritySetupMutationResult
  }, [pin, setPinAndEstablishSecureConnection])

  return { setPinAndSecConnection }
}

export default useSetPin
