import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const NumpadDeleteM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M21 6c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1H8.828c-.53 0-1.039-.21-1.414-.586l-5.06-5.06c-.196-.196-.196-.512 0-.708l5.06-5.06C7.79 6.21 8.298 6 8.828 6H21zm-8.185 2.765c-.195-.135-.465-.115-.638.058l-.354.354-.058.069c-.135.195-.115.464.058.638L13.94 12l-2.117 2.116c-.195.195-.195.512 0 .707l.354.354.069.058c.195.135.464.115.638-.058L15 13.06l2.116 2.117.07.058c.194.135.464.115.637-.058l.354-.354.058-.069c.135-.195.115-.464-.058-.638L16.06 12l2.117-2.116.058-.07c.135-.194.115-.464-.058-.637l-.354-.354c-.195-.195-.512-.195-.707 0L15 10.94l-2.116-2.117z"
      />
    </Svg>
  )
}

export default NumpadDeleteM
