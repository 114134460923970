import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NoEventsXL } from 'icons'
import { NO_EVENTS_ICON_SIZE } from 'pages/Cms/EventsList/constants'
import { styles } from './styles'

const EmptyEvents = () => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={styles.emptyEventContainer}>
      <NoEventsXL width={NO_EVENTS_ICON_SIZE} height={NO_EVENTS_ICON_SIZE} />
      <Typography sx={styles.noEventTitle}> {t('absencePlanner.taskbar.noEvents.title')}</Typography>
    </Box>
  )
}

export default EmptyEvents
