import React from 'react'
import { Box } from '@mui/material'
import { IShiftState } from 'components/ShiftsList/reducer'
import { isNative } from 'utils/platform'
import WeeklyCalendarBottom from './WeeklyCalendarBottom'
import WeeklyCalendarTop from './WeeklyCalendarTop'
import { classes } from './styles'

interface IShiftsWeeklyCalendar {
  state: IShiftState
  goToDay: (date: Date) => void
  goToToday: () => void
  closeCalendar: () => void
  toggleCalendarOpen: () => void
  handleIndexWeekChange: (value: number) => void
}

const ShiftsWeeklyCalendar: React.FC<IShiftsWeeklyCalendar> = ({
  state,
  goToDay,
  goToToday,
  closeCalendar,
  toggleCalendarOpen,
  handleIndexWeekChange,
}) => {
  return (
    <Box sx={classes.stickyContainer(false)}>
      <Box sx={!state.calendarOpen && !isNative() ? classes.bottomShadow : undefined}>
        <WeeklyCalendarTop
          referenceDay={state.calendarTitleDay}
          onClickToday={() => {
            goToToday()
            closeCalendar()
          }}
          toggleCalendarOpen={toggleCalendarOpen}
          isCalendarOpen={state.calendarOpen}
        />
      </Box>
      {!state.calendarOpen && isNative() && (
        <Box sx={classes.bottomShadow}>
          <WeeklyCalendarBottom
            currentDay={state.currentDay}
            weekIndex={state.weekIndex}
            onChangeWeek={handleIndexWeekChange}
            onClickDay={(day: Date) => goToDay(day)}
            showDelay={state.showDelay}
          />
        </Box>
      )}
    </Box>
  )
}

export default ShiftsWeeklyCalendar
