import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const OldSimL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3C8.20979 3 6.4929 3.71116 5.22703 4.97703C3.96116 6.2429 3.25 7.95979 3.25 9.75C3.25 11.5402 3.96116 13.2571 5.22703 14.523C6.4929 15.7888 8.20979 16.5 10 16.5C11.7902 16.5 13.5071 15.7888 14.773 14.523C16.0388 13.2571 16.75 11.5402 16.75 9.75C16.75 7.95979 16.0388 6.2429 14.773 4.97703C13.5071 3.71116 11.7902 3 10 3ZM6.28769 6.03769C7.27226 5.05312 8.60761 4.5 10 4.5C11.3924 4.5 12.7277 5.05312 13.7123 6.03769C14.6969 7.02225 15.25 8.35761 15.25 9.75C15.25 11.1424 14.6969 12.4777 13.7123 13.4623C12.7277 14.4469 11.3924 15 10 15C8.60761 15 7.27226 14.4469 6.28769 13.4623C5.30312 12.4777 4.75 11.1424 4.75 9.75C4.75 8.35761 5.30312 7.02226 6.28769 6.03769ZM17 14.5C16.1716 14.5 15.5 15.1716 15.5 16V18.5V19.5V23C15.5 23.8284 16.1716 24.5 17 24.5H21H23C23.8284 24.5 24.5 23.8284 24.5 23V16C24.5 15.1716 23.8284 14.5 23 14.5H21H17ZM23 23.5H21.5V21.5H23.5V23C23.5 23.2761 23.2761 23.5 23 23.5ZM23.5 20.5H21.5V19.5H23.5V20.5ZM23.5 18.5H21.5V17.5H23.5V18.5ZM23.5 16.5H21.5V15.5H23C23.2761 15.5 23.5 15.7239 23.5 16V16.5ZM19 23.5H20.5V15.5H17C16.7239 15.5 16.5 15.7239 16.5 16V18.5H17.5C18.3284 18.5 19 19.1716 19 20V21V22V23.5ZM16.5 19.5V21H18V20C18 19.7239 17.7761 19.5 17.5 19.5H16.5ZM16.5 23V22H18V23.5H17C16.7239 23.5 16.5 23.2761 16.5 23ZM6.25 10C6.25 9.58579 6.58579 9.25 7 9.25L13 9.25C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75L7 10.75C6.58579 10.75 6.25 10.4142 6.25 10ZM19 5.25C18.5858 5.25 18.25 5.58579 18.25 6C18.25 6.41421 18.5858 6.75 19 6.75H21.6893L26.25 11.3107V25.0022C26.25 25.6915 25.6914 26.25 25 26.25H15C14.3096 26.25 13.75 25.6904 13.75 25V19C13.75 18.5858 13.4142 18.25 13 18.25C12.5858 18.25 12.25 18.5858 12.25 19V25C12.25 26.5188 13.4812 27.75 15 27.75H25C26.5177 27.75 27.75 26.522 27.75 25.0022V10.6893L22.3107 5.25H19Z"
      />
    </Svg>
  )
}

export default OldSimL
