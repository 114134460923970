import theme from 'utils/theme'

export const tabButtonClasses = {
  iconWrapper: {
    cursor: 'pointer',
    padding: '12px 16px',
    marginLeft: '3px',
    borderRadius: '8px 0 0 8px',
  },
  selectedTabBackground: {
    // @ts-ignore
    backgroundColor: theme.palette.secondary[25],
  },
  toolTipTitle: {
    fontWeight: 'bold',
  },
  toolTipBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '9px 12px',
    color: theme.palette.grey[900],
    backgroundColor: 'white',
    transform: 'translate(12px, 0) !important',
    filter: 'drop-shadow(0px 4px 16px rgba(84, 109, 153, 0.24))',
  },
  toolTipArrow: {
    color: 'white',
  },
}
