import React, { useEffect, useState } from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import { FF_PACKAGE } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePackage from 'hooks/usePackage'
import useSessionContext from 'hooks/useSessionContext'
import { sortBy } from 'lodash/fp'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'
import { personsOnlyTenant } from 'shared/queries'
import { PersonPackageT, PersonT } from 'types'
import { isNative } from 'utils/platform'
import DailySlideRenderer from './components/DailySlideRenderer'
import { classes } from './styles'

export interface IDailyShiftListSwipeableProps {
  baseDate: string
  displayFull: boolean
  handleSwipeAction: (amount: number) => void
  swipeIndex: number
  swipeAnimation: boolean
  loadingGoToDay: boolean
  finishLoadingGoToDay: () => void
}

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const DailyShiftsList: React.FC<IDailyShiftListSwipeableProps> = ({
  baseDate,
  displayFull,
  handleSwipeAction,
  swipeIndex,
  swipeAnimation,
  loadingGoToDay,
  finishLoadingGoToDay,
}) => {
  const { checkHasClassicAccess } = usePackage()
  const { personIds } = useSessionContext()
  const [personsWithTenant, setPersonsWithTenant] = useState<PersonT[]>()
  const packageFF = useFeatureFlag(FF_PACKAGE)

  //Note: we should be using hasClassicAccess, and not do directly the query
  // If we need to refresh the packages of the person, we should solve it at the session level
  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsOnlyTenant, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const personsWithClassicAccess = data.findAllPersons.filter((person) => {
        if (!person.tenant || !person.tenant?.name) return false
        const { tenant } = person
        const personPackages = {
          [person.id as number]: tenant.package as PersonPackageT,
        } as PersonPackageT

        return checkHasClassicAccess({ packageFF, personPackages })
      })

      setPersonsWithTenant(sortBy('', personsWithClassicAccess) as PersonT[])
    },
  })

  useEffect(() => {
    if (loadingGoToDay) {
      finishLoadingGoToDay()
    }
  }, [finishLoadingGoToDay, loadingGoToDay])

  if (loading || loadingGoToDay) {
    return <SimpleLoading />
  }

  return (
    <VirtualizeSwipeableViews
      animateTransitions={swipeAnimation}
      enableMouseEvents={isNative()}
      index={swipeIndex}
      onChangeIndex={(newIndex, indexLatest) => {
        handleSwipeAction(newIndex < indexLatest ? -1 : 1)
      }}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
      style={classes.fullWitdh}
      slideRenderer={(params) => (
        <DailySlideRenderer
          baseDate={baseDate}
          displayFull={displayFull}
          key={params.index}
          params={params}
          personIds={personIds}
          personsWithTenant={personsWithTenant}
        />
      )}
    />
  )
}

export default DailyShiftsList
