import { gql } from '@apollo/client'

export const absencePlannerQuery = gql`
  query absencePlanner(
    $endDate: String!
    $shiftTypes: [String]
    $showAbsences: Boolean
    $startDate: String!
    $teamIds: [String]
    $showShiftUpdates: Boolean
    $showCmsEvents: Boolean
  ) {
    absencePlanner(
      endDate: $endDate
      startDate: $startDate
      showAbsences: $showAbsences
      shiftTypes: $shiftTypes
      teamIds: $teamIds
      showShiftUpdates: $showShiftUpdates
      showCmsEvents: $showCmsEvents
    ) {
      personId
      dayBadges {
        date
        personId
        badges {
          badgeId
          date
          type
          timeOfDay
          hexTextColor
          hexBackgroundColor
          shortDescription
          isManual
          taskUuid
          daysRequested
          personId
          shiftUpdateUuid
          numberOfEvents
        }
      }
    }
  }
`

export const findAllAbsencePlannerUserPersons = gql`
  query listAllAbsencePlannerUserPersons {
    findAllPersons {
      id
      firstName
      lastName
      favurUuid
      status
      owner {
        id
        name
      }
      tenant {
        id
        name
      }
      roles {
        role
      }
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`
