import { useState } from 'react'
import { notificationsCountQuery } from 'pages/Notifications/queries'
import { NotificationCountReturnT, NotificationCountsT } from 'pages/Notifications/types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const useNotificationCounts = () => {
  const [counts, setCount] = useState<NotificationCountsT>({ manager: 0, personal: 0 })
  const { loading, refetch } = useJamesApolloQuery<NotificationCountReturnT>(notificationsCountQuery, {
    onCompleted(data) {
      setCount(data.notificationCounts)
    },
    fetchPolicy: 'no-cache',
  })
  return { loading, counts, refetch }
}

export default useNotificationCounts
