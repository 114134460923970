import { AxiosResponse } from 'axios'
import useJamesQuery from 'hooks/useJamesQuery'
import { PinStatusT } from 'pages/SecuritySetup/types'
import { QueryConfig, QueryStatus } from 'react-query'

export type PinStatusParamT = {
  queryName: string
  opts?: QueryConfig<AxiosResponse>
}

const usePinStatus = ({ queryName, opts }: PinStatusParamT): [PinStatusT | undefined, QueryStatus] => {
  const { data: pinStatusData, status } = useJamesQuery<{
    pinStatus: PinStatusT
  }>(
    queryName,
    `query{
      pinStatus {
        pinStatus
        pinLength
      }
    }`,
    { refetchOnMount: 'always', cacheTime: 10000, ...(opts || {}) },
  )

  return [pinStatusData?.pinStatus, status]
}

export default usePinStatus
