import React from 'react'
import { Drawer } from '@mui/material'
import DrawerContent from './DrawerContent'
import { classes } from './styles'

interface ISideMenuProps {
  open: boolean
  onClose: () => void
}

const SideMenu: React.FC<ISideMenuProps> = ({ open, onClose }) => {
  return (
    <Drawer
      PaperProps={{ sx: classes.sideMenu }}
      anchor="right"
      open={open}
      onClose={onClose}
      data-testid="side-menu__container"
    >
      <DrawerContent />
    </Drawer>
  )
}

export default SideMenu
