import theme from 'utils/theme'

export const avatarIconClasses = {
  avatarWithBorder: {
    // @ts-ignore
    border: `2px solid ${theme.palette.secondary[400]}`,
  },
  sideMargin: {
    margin: '0 2px',
  },
}
