import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightThinS } from 'icons'
import { formatTitle, formatDateFromString, DATE_YEAR_FORMAT } from 'pages/Cms/EventsList/EventCard/utils'
import { Link } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getMetadata } from '../../helper'
import { INewsletterCardProps } from '../types'
import { styles } from './styles'

const NewsletterCard: React.FC<INewsletterCardProps> = ({ content }) => {
  const { language, locale } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])
  const dateText = useMemo(
    () =>
      content.sentAt ? formatDateFromString({ dateString: content.sentAt, format: DATE_YEAR_FORMAT, locale }) : '-',
    [content.sentAt, locale],
  )

  if (!metadata) return null

  return (
    <Box component={Link} to={`${routes.cmsContentView}/${content.uuid}`} sx={styles.contentCardLink}>
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.iconText}>
          <Box sx={styles.texts}>
            <Typography variant="body2" sx={styles.dateRangeText}>
              {dateText}
            </Typography>
            <Typography variant="body1" sx={styles.titleText}>
              {formatTitle(metadata.title ?? '')}
            </Typography>
          </Box>
        </Box>
        <ArrowRightThinS />
      </Card>
    </Box>
  )
}

export default NewsletterCard
