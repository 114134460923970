import { UserDataAddressL, UserDataBankL, UserDataFamilyL, UserDataPersonalL, UserDataShareL } from 'icons'
import type { UserDataStepT } from 'pages/Tasks/types'
import type { StepT } from 'types'
import Bank from './Bank'
import Contact from './Contact'
import Family from './Family'
import Intro from './Intro'
import Personal from './Personal'
import Sharing from './Sharing'

export const userDataStepNames = {
  personal: 'PERSONAL',
  family: 'FAMILY',
  contact: 'CONTACT',
  bank: 'BANK',
  sharing: 'SHARING',
}

export const userDataSteps: StepT[] = [
  { name: userDataStepNames.personal, index: 0, icon: UserDataPersonalL, component: Personal },
  { name: userDataStepNames.family, index: 1, icon: UserDataFamilyL, component: Family },
  { name: userDataStepNames.contact, index: 2, icon: UserDataAddressL, component: Contact },
  { name: userDataStepNames.bank, index: 3, icon: UserDataBankL, component: Bank },
  { name: userDataStepNames.sharing, index: 4, icon: UserDataShareL, component: Sharing },
]
export const userDataIntroStep: StepT = { name: 'INTRO', index: -1, icon: undefined, component: Intro }
export const userDataStepslabels: UserDataStepT[] = userDataSteps.map((step) =>
  step.name.toLowerCase(),
) as UserDataStepT[]
export const defaultUserDataStep = userDataIntroStep.name as UserDataStepT
