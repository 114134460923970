import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useFormsContext } from 'components/Forms/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePackage from 'hooks/usePackage'
import { AlertS } from 'icons'
import { useHistory } from 'react-router-dom'
import useTemporayFlashMessage from 'services/FlashMessages/useTemporaryFlashMessage'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { classes } from './styles'

const SendShareUserDataCard: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { hasClassicAccess } = usePackage()
  const { getAllFormsValid } = useFormsContext()
  const { showFlashMessage } = useTemporayFlashMessage()

  if (!hasClassicAccess) return null

  return (
    <>
      <Box sx={classes.card}>
        <Box sx={classes.title}>
          <Box component="span" sx={classes.icon}>
            <AlertS fill={theme.palette.secondary.main} />
          </Box>
          <Typography sx={classes.titleText} variant="subtitle1">
            {t('personalData.shareUserDataCard.title')}
          </Typography>
        </Box>
        <Typography sx={classes.bodyText} variant="caption">
          {t('personalData.shareUserDataCard.body')}
        </Typography>
        <Box sx={classes.sendButtonContainer}>
          <Button
            sx={classes.sendButton}
            onClick={() => {
              getAllFormsValid().then((formValidationResult) => {
                if (formValidationResult === true) {
                  history.push(routes.employmentDataShare)
                } else {
                  showFlashMessage(formValidationResult as string)
                }
              })
            }}
          >
            {t('personalData.shareUserDataCard.sendButton')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default SendShareUserDataCard
