import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CheckMarkCircleM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import { TeamPermissionT } from 'types'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IPermissionsSectionProps {
  teamPermissions: TeamPermissionT
}

const PermissionsSection: React.FC<IPermissionsSectionProps> = ({ teamPermissions }) => {
  const { t } = useFavurTranslation()
  const permissionsGlossaryKeys = useMemo(
    () => [
      ...(teamPermissions.absenceManager ? ['page.userSettings.team.absenceManager'] : []),
      ...(teamPermissions.monthlySheetManager ? ['page.userSettings.team.monthlySheetManager'] : []),
      ...(teamPermissions.shareUserDataManager ? ['page.userSettings.team.shareUserDataManager'] : []),
      ...(teamPermissions.shiftUpdateManager ? ['page.userSettings.team.shiftUpdateManager'] : []),
    ],
    [
      teamPermissions.absenceManager,
      teamPermissions.monthlySheetManager,
      teamPermissions.shareUserDataManager,
      teamPermissions.shiftUpdateManager,
    ],
  )
  if (permissionsGlossaryKeys.length === 0) return null

  return (
    <UserSettingsSection title={t('page.userSettings.team.permissions')}>
      <Card>
        <Box sx={classes.contentContainer}>
          {permissionsGlossaryKeys.map((key, i) => (
            <>
              <Box sx={classes.contentRow} key={key}>
                <CheckMarkCircleM fill={palette.success.main} />
                <Typography variant="body1">{t(key)}</Typography>
              </Box>
              {i < permissionsGlossaryKeys.length - 1 && <DivisionLine key={`${key}-division`} />}
            </>
          ))}
        </Box>
      </Card>
    </UserSettingsSection>
  )
}

export default PermissionsSection
