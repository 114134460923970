import theme from 'utils/theme'
import favurTheme from 'utils/theme'
import utilsTheme from 'utils/theme'

export const classes = {
  card: {
    position: 'relative',
    margin: `${theme.spacing(0.75)} ${theme.spacing(0.75)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(1.0),
    overflow: 'initial',
    '&:last-child': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flexGrow: '0 !important' as any,
      paddingBottom: '18px',
    },
  },
  tenantName: {
    marginBottom: theme.spacing(0.25),
  },
  date: {
    marginBottom: theme.spacing(0.875),
    color: theme.palette.text.secondary,
    weight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0.125),
  },
  secondRow: {
    marginBottom: theme.spacing(0.5),
  },
  typeLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  badge: {
    marginLeft: 'auto',
  },
  groups: {
    '& > :first-of-type': {
      marginBottom: theme.spacing(1),
    },
  },
  shiftContainer: {
    marginTop: theme.spacing(1),
    '&>:not(:last-child):not(.MuiTypography-root)': {
      marginBottom: theme.spacing(1),
    },
  },
  shiftContainerTopMargin: {
    marginTop: '0px',
  },
  shiftGroup: {
    '& > :not(:first-of-type)': {
      marginTop: theme.spacing(0.5),
    },
  },
  shiftGroupTopMargin: {
    marginTop: '-24px',
  },
  disabledText: {
    color: theme.palette.text.secondary,
  },
  dateShiftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  dateShiftElement: {
    paddingBottom: theme.spacing(1),
  },

  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hiddenLabel: {
    position: 'relative',
    marginBottom: '0px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
  },

  hiddenShiftContainer: {
    paddingTop: '0px',
    paddingBottom: theme.spacing(0.75),
  },
  hiddenShiftContainerFirst: {
    paddingTop: '2px',
  },
  hiddenShiftContainerLast: {
    paddingBottom: '0px',
  },
  seenLabel: {
    position: 'relative',
    bottom: '4px',
    left: '24px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  marginTopCardSeenLabel: {
    marginTop: '9px',
  },
  deletedTitleText: {
    marginBottom: '12px',
  },
  unacknowledgedChip: {
    // @ts-ignore
    backgroundColor: utilsTheme.palette.primary[25],
    height: '24px',
    cursor: 'pointer',
  },
  hiddenContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
  },
  button: {
    backgroundColor: favurTheme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.16)',
      backgroundColor: favurTheme.palette.secondary.main,
    },
    '&.MuiFab-extended.MuiFab-sizeSmall': {
      width: '120px',
    },
    '&:last-child': {
      marginBottom: '26px',
    },
  },
}
