import theme from 'utils/theme'

export const classes = {
  body1: {
    marginTop: theme.spacing(0.5),
  },
  body2: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
}
