import theme from 'utils/theme'

const calculateContentPadding = (
  noPadding: boolean | undefined,
  hasFloatingButtons: boolean | undefined,
  addSafeMargin: boolean | undefined,
  paddingX: number,
  extraBottomMargin: number,
) => {
  if (noPadding) {
    return '0'
  }
  // we divide extraBottomMargin by 16 to use theme.spacing correctly
  const extraBotMargin = extraBottomMargin / 16.0

  const padX = `${theme.spacing(paddingX)}`
  const padTop = addSafeMargin ? `calc(${theme.spacing(1)} + var(--ion-safe-area-top))` : `${theme.spacing(1)}`
  const padBot = hasFloatingButtons
    ? `calc(${theme.spacing(3.5 + extraBotMargin)} + var(--ion-safe-area-bottom))`
    : `${theme.spacing(extraBotMargin)}`
  return `${padTop} ${padX} ${padBot}`
}

type PageContentStyleProps = {
  paddingX: number
  noPadding: boolean | undefined
  addSafeMargin: boolean | undefined
  hasFloatingButtons: boolean | undefined
  removeLastMargin: boolean | undefined
  extraBottomMargin: number
  fullContentWidth: boolean
}

const pageClasses = {
  tutorialPopoverCard: {
    padding: '12px',
  },
  content: ({
    paddingX,
    noPadding,
    addSafeMargin,
    hasFloatingButtons,
    removeLastMargin,
    extraBottomMargin,
    fullContentWidth,
  }: PageContentStyleProps) => ({
    margin: '0 auto',
    maxWidth: fullContentWidth ? '100%' : theme.breakpoints.values.sm,
    padding: calculateContentPadding(noPadding, hasFloatingButtons, addSafeMargin, paddingX, extraBottomMargin),
    boxSizing: 'border-box',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :last-child': { marginBottom: removeLastMargin ? 0 : theme.spacing(2) },
  }),
  ionContent: (backgroundColor: string) => ({
    '--ion-background-color': backgroundColor ?? 'transparent',
  }),
  contentWithFooter: {
    padding: theme.spacing(0.75),
  },
  contentGrow: {
    '& > .MuiPaper-root:last-child': { flexGrow: 1 },
  },
  contentCenter: {
    justifyContent: 'center',
  },
  flex: {
    display: 'flex',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fitToViewport: {
    maxHeight: '100vh',
    height: 'calc(var(--vh, 1vh) * 100)',
  },
  hasFloatingButtons: {
    paddingBottom: theme.spacing(3.5),
  },
  fullContentHeight: {
    height: '100%',
  },
}

export default pageClasses
