import React from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import { generateOauthAuthorizeUrl } from 'services/RoutesProvider/utils'

const RedirectCmsPage: React.FC = () => {
  window.location.href = generateOauthAuthorizeUrl(new URLSearchParams(location.search))

  return <SimpleLoading />
}

export default RedirectCmsPage
