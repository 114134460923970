import React from 'react'
import { Box } from '@mui/material'
import { classes } from 'components/ShiftsList/components/ShiftCard/Placeholders/styles'
import { avatarClasses } from '../styles'

interface IAbsencePlannerAvatarProps {
  isExpanded: boolean
}

const LoadingAbsencePlannerAvatar: React.FC<IAbsencePlannerAvatarProps> = ({ isExpanded }) => {
  return (
    <Box sx={avatarClasses.container}>
      <Box sx={avatarClasses.avatarPlaceholder} />
      <Box sx={avatarClasses.collapsableContent(isExpanded)}>
        <Box sx={avatarClasses.textContainer}>
          <Box sx={classes.teamTitlePlaceholder} />
        </Box>
      </Box>
    </Box>
  )
}

export default LoadingAbsencePlannerAvatar
