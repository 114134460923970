import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { AlertS } from 'icons'
import routes from 'services/RoutesProvider/routes'
import { notificationStatuses, ReminderSecureConnectionNotificationT } from 'types'
import palette from 'utils/theme/palette'

interface ISecureConnectionReminder extends NotificationPropsT {
  notification: ReminderSecureConnectionNotificationT
}

const SecureConnectionReminder: React.FC<ISecureConnectionReminder> = (props) => {
  const { pushWithRole } = useHistoryUtils()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { taskUuid } = notification.data

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole, taskUuid])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.secureconnection.reminder.title'),
    body: t('notification.secureconnection.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('notification.secureconnection.reminder.body'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default SecureConnectionReminder
