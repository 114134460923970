import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContent: {
    display: 'flex',
    gap: '12px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  subtitle: {
    color: theme.palette.grey[600],
  },
  alignSelfStart: {
    alignSelf: 'start',
  },
}
