import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FilterContainer from 'pages/AbsencePlanner/components/TaskBar/Filters/FilterContainer'
import FiltersCheckBox from 'pages/AbsencePlanner/components/TaskBar/Filters/FiltersCheckBox'
import useStoredFilters from 'pages/Shifts/useStoredFilters'
import { isNative } from 'utils/platform'
import { useSideMenuContext } from '../context'

const SettingsBlock: React.FC = () => {
  const { t } = useFavurTranslation()
  const { showTopCalendarShifts, setShowTopCalendarShifts } = useSideMenuContext()
  const { updateStoredFilter } = useStoredFilters()

  const onCheckChange = useCallback(
    (key: string, checked: boolean) => {
      updateStoredFilter(key, checked)
      setShowTopCalendarShifts(checked)
    },
    [updateStoredFilter, setShowTopCalendarShifts],
  )

  return (
    <FilterContainer title={t('page.shifts.sideMenu.settings')}>
      {isNative() && (
        <Box alignItems="center" display="flex">
          <FiltersCheckBox
            key={t('page.shifts.sideMenu.showShiftChips')}
            label={t('page.shifts.sideMenu.showShiftChips')}
            checked={showTopCalendarShifts}
            onChange={(event) => {
              const { checked } = event.target
              onCheckChange('showTopCalendarShifts', checked)
            }}
          />
        </Box>
      )}
    </FilterContainer>
  )
}

export default SettingsBlock
