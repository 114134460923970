import { useState } from 'react'
import { ShiftsByDateT } from 'components/ShiftsList/types'
import { personDayShiftsQuery } from 'shared/queries'
import { ShiftTypeT, ShiftsByTenantCCT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

export type PersonDayShiftParamsT = {
  date: string
  personId: number | undefined
  types: ShiftTypeT[]
}

export const usePersonDayShifts = (
  { date, personId, types }: PersonDayShiftParamsT,
  onComplete?: (shifts: ShiftsByTenantCCT[]) => void,
) => {
  const [shifts, setShifts] = useState<ShiftsByTenantCCT[]>()

  const { loading } = useJamesApolloQuery<{ getPersonDayShifts: ShiftsByDateT[] }>(personDayShiftsQuery, {
    variables: {
      date,
      personId,
      types,
    },
    skip: !personId,
    onCompleted(data) {
      const { getPersonDayShifts } = data
      const newShifts = getPersonDayShifts[0]?.tenants ?? []

      setShifts(newShifts)
      onComplete && onComplete(newShifts)
    },
  })

  return { shifts, loading }
}
