import theme from 'utils/theme'

export const classes = {
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
  content: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0 12px 24px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  title: {
    fontWeight: '600',
  },
  button: {
    padding: '9px 24px',
  },
  lightButton: {
    color: theme.palette.primary.main,
    //@ts-ignore
    background: theme.palette.primary[50],
  },
}
