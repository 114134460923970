import theme from 'utils/theme'

export const classes = {
  dashboardCardToggleBody: {
    color: theme.palette.grey[600],
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  innerContent: {
    display: 'flex',
    gap: '12px',
  },
}
