import React, { useState } from 'react'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { getPersonQuery } from 'shared/queries'
import { PersonT } from 'types'
import { getUserName } from 'utils/person'
import AddToTeams from './AddToTeams'
import DetailDataContent from './DetailDataContent'

interface IEmployeeDetailProps {
  backwardLink: string
  favurUuid: string
  topTitle?: string
}

const EmployeeDetail: React.FC<IEmployeeDetailProps> = ({ backwardLink, favurUuid, topTitle }) => {
  const { t } = useFavurTranslation()

  const [showAddToTeams, setShowAddToTeams] = useState(false)
  const { data, loading, refetch } = useJamesApolloQuery<{ getPerson: PersonT }>(getPersonQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: favurUuid,
    },
  })
  const openAddToTeams = () => setShowAddToTeams(true)
  const closeAddToTeams = () => {
    refetch()
    setShowAddToTeams(false)
  }

  const person = data?.getPerson

  if (!loading && person && showAddToTeams) {
    return <AddToTeams person={person} goBack={closeAddToTeams} />
  }

  return (
    <Page
      header={
        <WithBackwardLink
          route={backwardLink}
          title={person ? getUserName(person) : '-'}
          topTitle={topTitle ?? t('teams.tabLabel.members')}
        />
      }
      hideNativeNavigation
    >
      {loading || !person ? (
        <BoxedLoading />
      ) : (
        <DetailDataContent person={person} onRemoveFromTeam={() => refetch()} openAddToTeams={openAddToTeams} />
      )}
    </Page>
  )
}

export default EmployeeDetail
