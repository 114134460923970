import React from 'react'
import PinLogin from 'pages/PinLogin'
import TenantsPageContent from './TenantsPageContent'

const TenantsPage: React.FC = () => {
  return (
    <PinLogin>
      <TenantsPageContent />
    </PinLogin>
  )
}

export default TenantsPage
