import React from 'react'
import BankData from '../Forms/Bank'
import ContactData from '../Forms/Contact'
import FamilyData from '../Forms/Family'
import PersonalData from '../Forms/PersonalData'
import { IEmploymentFormProps } from '../types'

export type EmploymentDataTabsName = 'PERSON' | 'FAMILY' | 'CONTACT' | 'BANK'
type TabT = {
  name: string
  index: number
  label: string
  component: React.FC<IEmploymentFormProps>
}

export const tabs: TabT[] = [
  { name: 'PERSON', index: 0, label: 'page.personalData.tab.person', component: PersonalData },
  { name: 'FAMILY', index: 1, label: 'page.personalData.tab.family', component: FamilyData },
  { name: 'CONTACT', index: 2, label: 'page.personalData.tab.contact', component: ContactData },
  { name: 'BANK', index: 3, label: 'page.personalData.tab.bank', component: BankData },
]

export const getTab = (tabName: string | undefined): TabT => {
  if (tabName?.length) {
    const searchTab = tabs.find((e) => e.name === tabName.toUpperCase())
    return searchTab || tabs[0]
  }
  return tabs[0]
}
