import React, { useState } from 'react'
import Stepper, { IStepperProps } from 'components/Stepper'
import { useHistory } from 'react-router-dom'
import type { StepT } from 'types'
import { getStep } from 'utils/stepper'

interface IStepperHookProps {
  route: string
  step: string | undefined
  steps: StepT[]
  extraDefaultStep?: StepT
  checkAdditionalParams?: boolean
}

const useStepper = ({
  route,
  step,
  steps,
  extraDefaultStep,
  checkAdditionalParams = true,
}: IStepperHookProps): {
  activeStep: StepT
  goToStep: (stepName: string, additionalUrlParams?: string[]) => void
  Stepper: React.FC<IStepperProps>
} => {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState<StepT>(getStep(step, steps, extraDefaultStep))
  const StepperComponent: React.FC = () => <Stepper activeStep={activeStep.index} steps={steps} />
  const goToStep = (stepName: string, additionalUrlParams?: string[]) => {
    const newStep = getStep(stepName, steps, extraDefaultStep)
    if (checkAdditionalParams) {
      const url = additionalUrlParams
        ? `${route}/${newStep.name.toLowerCase()}/${additionalUrlParams?.join('/').toLocaleLowerCase()}`
        : `${route}/${newStep.name.toLowerCase()}`
      history.push(url)
    }
    setActiveStep(newStep)
  }

  window.onpopstate = () => {
    setActiveStep(getStep(step, steps, extraDefaultStep))
  }

  return { activeStep, goToStep, Stepper: StepperComponent }
}

export default useStepper
