import theme from 'utils/theme'

export const classes = {
  singleBalanceTitle: {
    textTransform: 'uppercase',
    padding: theme.spacing(0, 0.5),
    marginBottom: theme.spacing(0.75),
  },
  multiBalancesTitle: {
    marginLeft: theme.spacing(1.5),
  },
  multiBalancesBox: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  multiBalancesSubtitle: {
    paddingLeft: theme.spacing(0.5),
  },
  multiBalancesErrorTenant: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
}
