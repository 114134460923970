import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useScrolledState from 'hooks/useScrolledState'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useSelectedStatesContext, {
  useSelectedStatesActionsContext,
} from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import AcceptContent from 'pages/Tasks/AbsenceRequest/Details/Accept/AcceptContent'
import Content from 'pages/Tasks/AbsenceRequest/Details/Content'
import { DetailsContext } from 'pages/Tasks/AbsenceRequest/Details/DetailsPage'
import { taskStates } from 'pages/Tasks/constants'
import useDismissTaskNotifications from 'pages/Tasks/hooks/useDismissTaskNotifications'
import { taskQueryAbsences } from 'pages/Tasks/queries'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { PersonT } from 'types'
import AbsenceRequestListHeader from '../Header'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import { absenceRequestListClasses } from '../styles'
import { detailClasses } from './styles'

interface IAbsenceRequestDetailProps {
  title: string
  subtitle: string
  office?: boolean
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
  refetchAll: () => void
  reloadTaskList: () => void
}

const AbsenceRequestDetail: React.FC<IAbsenceRequestDetailProps> = ({
  title,
  subtitle,
  office,
  refetchTask,
  reloadTaskList,
}) => {
  const { t } = useFavurTranslation()
  const [showAcceptPage, setShowAcceptPage] = useState(false)
  const { selectedTaskUuid, selectedPerson } = useSelectedStatesContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { deselectDays, setSelectedPerson } = useSelectedStatesActionsContext()
  const [task, setTask] = useState<TaskT<AbsenceRequestTaskT>>()
  const [hadPersonSelected, setHadPersonSelected] = useState(true)
  const contentRef = useRef(null)
  const { checkIsScrolled, isAtBottom, hasOverflow } = useScrolledState(contentRef)
  const { loading: dismissLoading } = useDismissTaskNotifications({
    taskUuid: selectedTaskUuid,
    isManager: Boolean(office),
  })
  const { refetch } = useJamesApolloQuery<{ task: TaskT<AbsenceRequestTaskT> }>(taskQueryAbsences, {
    variables: { favurUuid: selectedTaskUuid },
    fetchPolicy: 'no-cache',
    skip: selectedTaskUuid === null,
    onCompleted(data) {
      const fetchedTask = data.task
      setTask(fetchedTask)
      if (!selectedPerson) {
        setSelectedPerson(fetchedTask?.person as PersonT)
        setHadPersonSelected(false)
      }
    },
  })

  const refetchTaskFunc = useCallback(
    (taskToRefetch: TaskT<AbsenceRequestTaskT>) => {
      refetch()
      if (taskToRefetch?.person?.id) {
        refetchTask(taskToRefetch.person?.id.toString(), taskToRefetch.taskData.dateFrom, taskToRefetch.taskData.dateTo)
      }
    },
    [refetch, refetchTask],
  )

  const pageTitle = useMemo(
    () => (!task || task.statusFe !== taskStates.closed ? title : t('absencePlanner.taskbar.managerTasks.titleClosed')),
    [t, task, title],
  )

  const goBack = useCallback(() => {
    if (showAcceptPage) setShowAcceptPage(false)
    else {
      reloadTaskList()
      deselectDays()
      if (!hadPersonSelected && task?.statusFe === taskStates.closed) {
        setSelectedPerson(null)
      }
    }
  }, [deselectDays, hadPersonSelected, reloadTaskList, setSelectedPerson, showAcceptPage, task?.statusFe])

  return (
    <DetailsContext.Provider value={{ setIsOpenAccept: setShowAcceptPage }}>
      <Box sx={[absenceRequestListClasses.container]}>
        {isSmallScreen ? (
          <SmallScreenPageHeader title={pageTitle} subtitle={subtitle} onClick={goBack} />
        ) : (
          <Box sx={absenceRequestListClasses.header(false)}>
            <AbsenceRequestListHeader title={pageTitle} subtitle={subtitle} goBack={goBack} />
          </Box>
        )}
        <Box sx={detailClasses.container} ref={contentRef} onScroll={checkIsScrolled}>
          {!task || dismissLoading ? (
            <div data-testid="loading-absence-planner-task-detail">
              <SimpleLoading />
            </div>
          ) : (
            <>
              {showAcceptPage ? (
                <AcceptContent
                  task={task}
                  onAccept={() => {
                    setShowAcceptPage(false)
                    refetchTaskFunc(task)
                  }}
                  isSmallScreen={isSmallScreen}
                />
              ) : (
                <>
                  <Content
                    isAtBottom={isAtBottom}
                    hasOverflow={hasOverflow}
                    task={task}
                    refetchTask={() => refetchTaskFunc(task)}
                    onDelete={() => {
                      deselectDays()
                      if (task?.person?.id) {
                        refetchTask(task.person?.id.toString(), task.taskData.dateFrom, task.taskData.dateTo)
                      }
                      reloadTaskList()
                    }}
                    isOffice={Boolean(office)}
                    isSmallScreen={isSmallScreen}
                    drawerSx={isSmallScreen ? detailClasses.drawerFullScreen : detailClasses.drawer}
                    showConflicts={task.statusFe !== taskStates.closed}
                    showAbsencePlannerPreview={isSmallScreen}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </DetailsContext.Provider>
  )
}

export default AbsenceRequestDetail
