import React from 'react'
import { FF_SECURE_CONN_WORKFLOW } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import type { TeamT } from 'types'
import { PersonByPermissionsT } from '../../../types'
import TeamPermission from './TeamPermission'

interface IRightsTabProps {
  team: TeamT
  personsByPermission: PersonByPermissionsT
}

const RightsTab: React.FC<IRightsTabProps> = ({ team, personsByPermission }) => {
  const secureConnWorkflowFF = useFeatureFlag(FF_SECURE_CONN_WORKFLOW) === true

  return (
    <>
      <TeamPermission
        permissionName="absenceManager"
        persons={personsByPermission.get('absenceManager') ?? []}
        teamUuid={team.id}
      />

      <TeamPermission
        permissionName="shiftUpdateManager"
        persons={personsByPermission.get('shiftUpdateManager') ?? []}
        teamUuid={team.id}
      />

      <TeamPermission
        permissionName="monthlySheetManager"
        persons={personsByPermission.get('monthlySheetManager') ?? []}
        teamUuid={team.id}
      />

      <TeamPermission
        permissionName="shareUserDataManager"
        persons={personsByPermission.get('shareUserDataManager') ?? []}
        teamUuid={team.id}
      />

      {secureConnWorkflowFF && (
        <TeamPermission
          permissionName="secureConnectionManager"
          persons={personsByPermission.get('secureConnectionManager') ?? []}
          teamUuid={team.id}
        />
      )}
    </>
  )
}

export default RightsTab
