import React, { useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { datePickerClasses } from 'components/DatePickers/styles'
import { format } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { UseFormReturn } from 'react-hook-form'
import { uiDateFormat } from 'utils/constants'
import { newDateWithoutTime } from 'utils/date'

export type TBirthDateInputs = {
  birthDate: string
}

const BirthDateInput: React.FC<UseFormReturn<TBirthDateInputs> & { label: string; disabled?: boolean }> = ({
  formState: { errors },
  register,
  watch,
  clearErrors,
  setValue,
  label,
  disabled = false,
}) => {
  const fieldName = 'birthDate'
  const { t } = useFavurTranslation()
  const birthDateValue = watch(fieldName)

  useEffect(() => {
    register(fieldName)
  }, [register])

  const birthDateHandleChange = (value: Date | null) => {
    const stringDate = value ? format(value, uiDateFormat) : null
    clearErrors(fieldName)
    setValue(fieldName, stringDate as string)
  }

  return (
    <DatePicker
      name={fieldName}
      label={label}
      onChange={birthDateHandleChange}
      value={newDateWithoutTime(birthDateValue, uiDateFormat)}
      defaultValue={newDateWithoutTime(birthDateValue, uiDateFormat)}
      disabled={disabled}
      format={uiDateFormat}
      slotProps={{
        toolbar: { hidden: true },
        inputAdornment: {
          sx: { ...datePickerClasses.iconCalendar },
        },
        textField: {
          variant: 'standard',
          id: 'user-personal-data_birth-date',
          fullWidth: true,
          error: Boolean(errors.birthDate),
          helperText: errors.birthDate?.message && t(errors.birthDate?.message),
          inputProps: {
            //@ts-ignore
            'data-testid': 'user-personal-data_birth-date',
          },
        },
        dialog: {
          sx: { ...datePickerClasses.baseDialog },
        },
      }}
      views={['year', 'month', 'day']}
    />
  )
}

export default BirthDateInput
