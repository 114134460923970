import { Theme } from '@mui/material'

const styles = {
  toolbar: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1.5, 0, 1.5),
  }),
  table: {
    tableLayout: 'fixed',
  },
  invisibleLink: {
    color: 'white',
  },
  row: (theme: Theme) => ({
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.75),
    },
    '&:first-of-type .MuiTableCell-root': {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    '& .MuiTableCell-root:first-of-type': {
      paddingLeft: 0,
    },
    '& .MuiTableCell-root:last-of-type': {
      paddingRight: 0,
      wordBreak: 'break-word',
    },
  }),
}

export default styles
