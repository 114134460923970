import theme from 'utils/theme'

export const headerClasses = {
  absencePlannerHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  absencePlannerHeaderTitle: {
    maxWidth: 'fit-content',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'start',
    },
  },
  ownerFilterContainer: {
    marginTop: '2px',
  },
  selectBox: {
    color: 'white',
    width: theme.spacing(10),
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      paddingRight: '8px !important',
      paddingBottom: '2px',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.3px',
    },
  },
  selectIcon: {
    right: theme.spacing(1.25),
    width: theme.spacing(1.25),
  },
}
