import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledCallM } from 'icons'
import { getFormattedFromISOString } from 'utils/date'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice ? `workTimeControl.calledAt.tooltipManager` : `workTimeControl.calledAt.tooltipFrontliner`
}

const TIME_FORMAT = 'HH:mm'

interface ICalledAtProps {
  datetime: string
}

const CalledAt: React.FC<ITaskBadgeProps & ICalledAtProps> = ({ datetime, withTooltip = false, isOffice = false }) => {
  const { t, locale } = useFavurTranslation()
  const timeFormatted = getFormattedFromISOString(datetime, TIME_FORMAT, locale)
  const tooltipKey = getTooltipKey(withTooltip, isOffice)

  return (
    <TaskBadge
      text={t('timeline.badge.calledAt', { time: timeFormatted })}
      sx={getClasses(withTooltip)}
      icon={<FilledCallM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
    />
  )
}

export default CalledAt
