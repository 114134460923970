import { Locale } from 'date-fns'
import { de, enGB, fr, it } from 'date-fns/locale'

export const ENGLISH = { code: 'en', label: 'i18n.switcher.button.englishLanguage' }
export const FRENCH = { code: 'fr', label: 'i18n.switcher.button.frenchLanguage' }
export const GERMAN = { code: 'de', label: 'i18n.switcher.button.germanLanguage' }
export const ITALIAN = { code: 'it', label: 'i18n.switcher.button.italianLanguage' }

export const LANGUAGES = [ENGLISH, FRENCH, GERMAN, ITALIAN]
export const FALLBACK_LANGUAGE = ENGLISH.code
export const SUPPORTED_LANGUAGES: Language[] = LANGUAGES.map((lng) => lng.code)

export type Language = string

const locales: Map<string, Locale> = new Map([
  [ENGLISH.code, enGB],
  [FRENCH.code, fr],
  [GERMAN.code, de],
  [ITALIAN.code, it],
])

export const getLocale = (language: string) => {
  return locales.get(language) ?? locales.get(FALLBACK_LANGUAGE)
}

export const defaultPhoneNumberCountryCode = 'CH'
