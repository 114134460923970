import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useConnections from 'pages/UserSettings/hooks/useConnections'
import { headerClasses } from 'pages/UserSettings/styles'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import EmployerCardList from './components/EmployerCardList'
import { classes } from './styles'

const EmployersPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { persons, invitations, loading } = useConnections()

  const currentPersons = useMemo(() => {
    if (loading || !persons) return []
    const currentIsoDate = dateToISOStringWithoutTime(newDateWithoutTime())
    return persons.filter((person) => !person.workWithdrawal || person.workWithdrawal > currentIsoDate)
  }, [loading, persons])

  const historicalPersons = useMemo(() => {
    if (loading || !persons) return []
    const currentIsoDate = dateToISOStringWithoutTime(newDateWithoutTime())
    return persons.filter((person) => person.workWithdrawal && person.workWithdrawal <= currentIsoDate)
  }, [loading, persons])

  const redirectCondition = useMemo(
    () => !loading && currentPersons.length === 1 && !historicalPersons.length && !invitations.length,
    [currentPersons.length, historicalPersons.length, invitations.length, loading],
  )

  if (redirectCondition) {
    history.push(`${routes.employer}/${currentPersons[0].favurUuid}`, { origin: `${routes.userSettings}` })
  }

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => history.push(routes.userSettings)}
          title={t('page.userSettings.employers.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
    >
      {(loading || !persons) && <SimpleLoading />}
      <Box sx={classes.listsContainer}>
        {(currentPersons.length > 0 || invitations.length > 0) && (
          <EmployerCardList persons={currentPersons} invitations={invitations} />
        )}
        {historicalPersons.length > 0 && (
          <EmployerCardList
            persons={historicalPersons}
            title={t('page.userSettings.employer.historicalEmployers')}
            historical
          />
        )}
      </Box>
    </Page>
  )
}

export default EmployersPage
