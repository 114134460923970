import React from 'react'
import { Box } from '@mui/material'
import { pullerClasses } from './styles'

const Puller: React.FC = () => {
  return (
    <Box sx={pullerClasses.pullerContainer}>
      <Box sx={pullerClasses.puller} />
    </Box>
  )
}

export default Puller
