import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { BoxedLoading } from 'components/LoadingIcon'
import SectionTitle from 'components/SectionTitle'
import TimeBalanceTable from 'components/TimeBalanceTable'
import { isThisMonth } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesQuery from 'hooks/useJamesQuery'
import { TFunction } from 'i18next'
import { BalanceObject } from 'types/balances'
import { newDateWithoutTime } from 'utils/date'
import { getBalanceWithEstimationsQuery, getPastBalance } from './queries'
import { classes } from './styles'
import { IDatesProps } from './types'

const getTitle = (isCurrent: boolean, t: TFunction): string => {
  return isCurrent ? t('page.balance.title.current') : t('page.balance.title.past')
}

const TimeBalance: React.FC<IDatesProps> = ({ dates, resetDates, swipeIndex }) => {
  const { t } = useFavurTranslation()
  const isCurrent = isThisMonth(newDateWithoutTime(dates.end))
  const titleText = getTitle(isCurrent, t)
  const queryPastBalance = getPastBalance(dates.end)
  const { data, status } = useJamesQuery<{ improvedBalances?: BalanceObject[]; balances?: BalanceObject[] }>(
    `balance:${dates.start}-${dates.end}`,
    isCurrent ? getBalanceWithEstimationsQuery() : queryPastBalance,
  )
  const balances = data?.improvedBalances ?? data?.balances

  useEffect(() => {
    if (swipeIndex > 0) {
      resetDates()
    }
  }, [swipeIndex, resetDates])

  return (
    <div data-testid="balances-content">
      <Box paddingBottom={0.25}>
        <Box sx={classes.multiBalancesTitle}>
          <SectionTitle>{titleText}</SectionTitle>
        </Box>
        {status === 'loading' || !balances ? (
          <BoxedLoading gap={0.75} />
        ) : (
          (() => {
            switch (balances.length) {
              case 0:
                return (
                  <Card mx={0.75} textAlign="center">
                    <Typography component="p">{t('page.dashboard.content.noBalances')}</Typography>
                  </Card>
                )
              case 1:
                return (
                  <Card mx={0.75}>
                    <Typography variant="h3" sx={classes.singleBalanceTitle}>
                      {balances[0].tenantName}
                    </Typography>
                    <TimeBalanceTable isCurrent={isCurrent} dates={dates} balance={balances[0]} />
                  </Card>
                )
              default:
                return (
                  <>
                    {Object.entries(balances).map(([balanceKey, balance]) => (
                      <Card key={balanceKey} mx={0.75} marginBottom={1}>
                        <Typography variant="subtitle2" sx={classes.multiBalancesSubtitle}>
                          {balance.tenantName}
                        </Typography>

                        {balance.withErrors && (
                          <Typography variant="body1" sx={classes.multiBalancesErrorTenant}>
                            {t('page.multiBalances.errorTenant')}
                          </Typography>
                        )}
                        {!balance.withErrors && (
                          <TimeBalanceTable isCurrent={isCurrent} dates={dates} balance={balance} />
                        )}
                      </Card>
                    ))}
                  </>
                )
            }
          })()
        )}
      </Box>
    </div>
  )
}

export default TimeBalance
