export const classes = {
  selectMenu: {
    opacity: '0',
    height: '0px',
  },
  containerLanguage: {
    display: 'flex',
    flexDirection: 'column',
  },
}
