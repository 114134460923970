import React from 'react'
import { Box, Button as ButtonComponent } from '@mui/material'
import { ArrowRightThinS } from 'icons'
import { classes } from '../styles'
import { INativeCTAButtonProps } from './types'

const Button: React.FC<INativeCTAButtonProps> = ({ icon, label, onClick }) => (
  <ButtonComponent sx={classes.buttonAction} variant="text" color="primary" onClick={onClick}>
    <Box sx={classes.buttonActionBadgeAndText}>
      {icon}
      <Box sx={classes.buttonActionText}>{label}</Box>
    </Box>
    <Box sx={classes.buttonActionArrow}>
      <ArrowRightThinS />
    </Box>
  </ButtonComponent>
)

export default Button
