export const selectClasses = {
  root: {
    width: '100%',
    '& .MuiNativeSelect-select, & .MuiSelect-select': {
      textAlign: 'left',
      '&:hover, &:focus, &:hover:before': {
        backgroundColor: 'transparent',
      },
    },
  },
}
