import { APPLICATION_ENV } from 'env'
import { PACKAGES } from './packages'
import { ROLE_VIEWS } from './roles'

/* eslint-disable @typescript-eslint/naming-convention */
export const FF_PLATFORM = 'FF_PLATFORM'
export const FF_SECURITY_TIMEOUT_FE__BE__ = 'FF_SECURITY_TIMEOUT_FE__BE__'
export const FF_MIRUS_API_MOCK_FE__BE__ = 'FF_MIRUS_API_MOCK_FE__BE__'
export const FF_VIEW_ROLE = 'FF_VIEW_ROLE'
export const FF_PACKAGE = 'FF_PACKAGE'
export const FF_APP_VERSION = 'FF_APP_VERSION'
export const FF_SHARE_USER_DATA_COMMENT_FRONTLINER = 'FF_SHARE_USER_DATA_COMMENT_FRONTLINER'
export const FF_BIO_PIN_ADDITIONAL_OPTIONS = 'FF_BIO_PIN_ADDITIONAL_OPTIONS'
export const FF_SECURE_CONN_WORKFLOW = 'FF_SECURE_CONN_WORKFLOW'
export const FF_CMS_OVERVIEW = 'FF_CMS_OVERVIEW'

export const appVersions = {
  current: 'current',
  beforeRecommended: 'before_recommended',
  beforeRequired: 'before_required',
}

const getPackagesAndRolesFF = () => {
  if (APPLICATION_ENV !== 'LIVE') {
    return {
      [FF_PACKAGE]: {
        options: [
          { value: '', label: '' },
          { value: PACKAGES.basic, label: 'Basic' },
          { value: PACKAGES.classic, label: 'Classic' },
          { value: PACKAGES.classicLight, label: 'Classic Light' },
          { value: PACKAGES.betaWorkflowAbsencesClassic, label: 'Beta Absence Workflow' },
        ],
        label: 'Simulate package (BE may fail)*',
      },
      [FF_VIEW_ROLE]: {
        options: [
          { value: '', label: '' },
          { value: ROLE_VIEWS.frontliner, label: 'Frontliner' },
          { value: ROLE_VIEWS.office, label: 'Office' },
        ],
        label: 'Simulate role view (BE may fail)*',
      },
      [FF_APP_VERSION]: {
        options: [
          { value: appVersions.current, label: appVersions.current },
          { value: appVersions.beforeRecommended, label: appVersions.beforeRecommended },
          { value: appVersions.beforeRequired, label: appVersions.beforeRequired },
        ],
        label: 'Set application version',
      },
    }
  }

  return {}
}

const featureFlags = {
  [FF_PLATFORM]: {
    options: [
      { value: '', label: '' },
      { value: 'native', label: 'Native' },
      { value: 'web', label: 'Web' },
    ],
    label: 'Simulate platform',
  },
  [FF_SECURITY_TIMEOUT_FE__BE__]: 'Set security timeout to 5s for E2E tests',
  [FF_MIRUS_API_MOCK_FE__BE__]: 'Use the Mirus API mock service',
  [FF_SHARE_USER_DATA_COMMENT_FRONTLINER]: 'Show new share user data comment field for Frontliner',
  [FF_BIO_PIN_ADDITIONAL_OPTIONS]: 'Show additional options for biometric pin',
  [FF_SECURE_CONN_WORKFLOW]: 'Show new secure connection workflow',
  [FF_CMS_OVERVIEW]: 'Show cms overview page',
  ...getPackagesAndRolesFF(),
}

export type FeatureFlagType = keyof typeof featureFlags

export type FeatureFlagDefinitionType =
  | string
  | {
      label: string
      options: Array<{ value: string; label: string }>
    }

export type FeatureFlagsDefinitionType = {
  [key in FeatureFlagType]: FeatureFlagDefinitionType
}

export default featureFlags as FeatureFlagsDefinitionType
