import theme from 'utils/theme'

export const pinPointableTaskCardClasses = {
  container: { position: 'relative' },
  pinPointButton: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    padding: '4px',
    borderRadius: '4px',
    '&:hover, &:focus, &:hover:before': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
    },
    position: 'absolute',
    right: '16px',
    top: '75px',
  },
}
