import React from 'react'
import { Step as MuiStep, StepLabel, Stepper as MuiStepper } from '@mui/material'
import { StepT } from 'types'
import { classes } from './styles'

export interface IStepperProps {
  activeStep: number
  steps: StepT[]
}

const Stepper: React.FC<IStepperProps> = ({ activeStep, steps }) => {
  return (
    <MuiStepper activeStep={activeStep} connector={<></>} sx={classes.stepper}>
      {steps.map((step) => {
        return (
          <MuiStep key={step.index} sx={[classes.step, ...(activeStep === step.index ? [classes.active] : [])]}>
            <StepLabel StepIconComponent={step.icon} sx={classes.stepLabel} />
          </MuiStep>
        )
      })}
    </MuiStepper>
  )
}

export default Stepper
