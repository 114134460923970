import theme from 'utils/theme'

export const classes = {
  image: {
    marginTop: theme.spacing(4.625),
    marginBottom: theme.spacing(2.375),
  },
  text: {
    // @ts-ignore
    color: theme.palette.grey[900],
    '&:first-of-type': {
      paddingBottom: theme.spacing(0.75),
    },
  },
}
