import { useEffect, useState } from 'react'

const useDelay = (currentDay: Date, weekIndex: number, showDelay: boolean, delay = 500) => {
  const [delayedCurrentDay, setDelayedCurrentDay] = useState<Date>(currentDay)
  const [delayedWeekIndex, setDelayedWeekIndex] = useState<number>(weekIndex)

  useEffect(() => {
    // eslint-disable-next-line fp/no-let, no-undef
    let timeout: NodeJS.Timeout
    if (delayedWeekIndex !== weekIndex && showDelay) {
      timeout = setTimeout(() => {
        setDelayedCurrentDay(currentDay)
        setDelayedWeekIndex(weekIndex)
      }, delay)
    } else {
      setDelayedCurrentDay(currentDay)
      setDelayedWeekIndex(weekIndex)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [delayedWeekIndex, weekIndex, currentDay, showDelay, delay])

  return { delayedCurrentDay, delayedWeekIndex }
}

export default useDelay
