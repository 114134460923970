import React, { useEffect } from 'react'
import { AppBar, Box, Grow, Paper, Tab, Tabs as MuiTabs } from '@mui/material'
import type { TabT } from 'types'
import { a11yProps } from './helpers'
import { tabsClasses, tabItemClasses, classes } from './styles'

export interface ITabsProps {
  children?: React.ReactNode
  currentTabIndex: number
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  tabs: TabT[]
  removeTopContentPadding?: boolean
}

const Tabs: React.FC<ITabsProps> = ({
  children,
  currentTabIndex,
  handleChange,
  tabs,
  removeTopContentPadding = false,
}) => {
  useEffect(() => {
    // forcing the tabs indicator to update to get in place at start
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])

  return (
    <Box sx={classes.tabsContainer}>
      <Box component={Paper} p={1} boxShadow="0" sx={classes.tabsAppBarContainer}>
        <AppBar position="static" color="default" sx={classes.tabsAppBar}>
          <Grow in>
            <MuiTabs
              value={currentTabIndex}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={[classes.styledTabs, tabsClasses]}
            >
              {tabs.map((tab, index) => (
                <Tab
                  disabled={tab.disabled ?? false}
                  key={tab.id}
                  label={tab.label}
                  {...a11yProps(index)}
                  sx={tabItemClasses}
                  data-testid={`scrollable-auto-tab_${tab.id}`}
                />
              ))}
            </MuiTabs>
          </Grow>
        </AppBar>
      </Box>
      <Box sx={[classes.contentContainer, ...(removeTopContentPadding ? [classes.noPaddingTop] : [])]}>{children}</Box>
    </Box>
  )
}

export default Tabs
