import theme from '../../../utils/theme'

export const classes = {
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.main,
  },
}
