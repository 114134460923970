import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { User } from 'types'
import UserSettingsSection from '../../../../components/Section'
import Language from './Language'
import ProfilePicture from './ProfilePicture'

interface IProfileSettings {
  onFileChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickDeleteAvatar: () => void
  user: User
}

const ProfileSettings: React.FC<IProfileSettings> = ({ ...props }) => {
  const { t } = useFavurTranslation()

  return (
    <UserSettingsSection title={t('page.userSettings.profile.profileSettings')}>
      <ProfilePicture {...props} />
      <Language />
    </UserSettingsSection>
  )
}

export default ProfileSettings
