import theme from 'utils/theme'

export const classes = {
  icon: {
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  container: {
    display: 'flex',
    gap: '28px',
    alignItems: 'center',
  },
  stepperContainer: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 'calc(32px + var(--ion-safe-area-bottom))',
  },
  paddingWithActionButtons: {
    paddingBottom: 'calc(100px + var(--ion-safe-area-bottom))',
  },
  actionButtonArrowContainer: {
    display: 'flex',
    gap: '16px',
  },
  actionButton: {
    //@ts-ignore
    backgroundColor: theme.palette.primary[50],
    maxWidth: '48px',
    minWidth: '48px',
    '&:hover, &:focus': {
      //@ts-ignore
      backgroundColor: theme.palette.primary[50],
    },
    '&:hover:before, &:before': {
      background: 'transparent',
    },
    '&:disabled': {
      //@ts-ignore
      backgroundColor: theme.palette.primary[50],
      opacity: '40%',
    },
  },
}
