import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getShortName } from 'utils/person'
import { taskStates } from '../constants'
import classes from '../styles'
import { SecureConnectionTaskT, TaskCardDataT } from '../types'

const SecureConnectionBody: React.FC<TaskCardDataT<SecureConnectionTaskT>> = ({ office, task }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { storeState } = useFilterContext()
  const { setBackLink } = useStateBackLink()
  const taskState = task.statusFe

  const toDetailPage = useCallback(() => {
    setBackLink(routes.tasks)
    storeState()
    history.push(routes.secureConnectionWithTask(task.favurUuid))
  }, [setBackLink, storeState, history, task.favurUuid])

  const titleKey = useMemo(() => {
    switch (taskState) {
      case taskStates.todo:
        return 'tasks.new.secureconnection.frontliner.open.headline'
      case taskStates.pending:
        return 'tasks.new.secureconnection.manager.pending.headline'

      default:
        return office
          ? 'tasks.new.secureconnection.manager.closed.headline'
          : 'tasks.new.secureconnection.frontliner.closed.headline'
    }
  }, [taskState, office])

  const commentOrAction = useMemo(() => {
    if (!task.timeline || task.timeline.length === 0) return ''
    const lastTimelineItem = task.timeline[task.timeline.length - 1]
    const comment = lastTimelineItem.comment
    if (comment) return comment
    return undefined
  }, [task.timeline])

  return (
    <Box sx={classes.taskCardBody} data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-monthly-sheet-body`}>
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle1">
        {t(titleKey, { frontlinerNameShort: task.person ? getShortName(task.person) : '-' })}
      </Typography>
      <Box sx={classes.taskCardContentWrapper}>
        <Typography sx={classes.taskCardBodyContent} variant="body2">
          <Trans
            i18nKey="tasks.new.secureconnection.tenant"
            components={{ b: <b /> }}
            values={{ tenantName: task.tenantName }}
          />
        </Typography>
        {commentOrAction && (
          <Typography sx={classes.taskCardBodyContent} variant="body2">
            <Trans
              i18nKey="tasks.new.secureconnection.comment"
              components={{ b: <b /> }}
              values={{
                commentShort: commentOrAction,
              }}
            />
          </Typography>
        )}
      </Box>
      <Box sx={classes.taskCardCTAContainer}>
        <Typography
          variant="button"
          component="button"
          sx={classes.callToAction}
          onClick={toDetailPage}
          data-testid="tasks-secure-connection-details-button"
        >
          {t('tasks.absence_request.details')}
        </Typography>
      </Box>
    </Box>
  )
}

export default SecureConnectionBody
