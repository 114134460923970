import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const NewCalendarM: React.FC<IconPropsT> = ({ fill = '#fff' }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8.06 2.81c.42 0 .75.34.75.75v1.5h6.38v-1.5a.75.75 0 1 1 1.5 0v1.5h2.62c1.04 0 1.88.84 1.88 1.88V19.3c0 1.04-.84 1.88-1.88 1.88H4.7A1.88 1.88 0 0 1 2.8 19.3V6.94c0-1.04.84-1.88 1.88-1.88H7.3v-1.5c0-.41.34-.75.75-.75Zm7.13 3.75v.94a.75.75 0 1 0 1.5 0v-.94h2.62c.21 0 .38.17.38.38v2.62H4.3V6.94c0-.21.17-.38.38-.38H7.3v.94a.75.75 0 0 0 1.5 0v-.94h6.38Zm4.5 4.5v8.25c0 .21-.17.38-.38.38H4.7a.38.38 0 0 1-.38-.38v-8.25H19.7Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default NewCalendarM
