import React, { useCallback, useMemo, useState } from 'react'
import { Box, ClickAwayListener, Popover, PopoverOrigin } from '@mui/material'
import BottomDrawer from 'components/Basics/BottomDrawer'
import { SimpleLoading } from 'components/LoadingIcon'
import useAbsenceRequests from 'hooks/useAbsenceRequests'
import { PersonDayShiftParamsT, usePersonDayShifts } from 'hooks/usePersonDayShifts'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { AbsencePlannerBadgeTypesT, SelectedShiftDataT } from 'pages/AbsencePlanner/types'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { PlannedShiftT, ShiftTypeT, ShiftsByTenantCCT } from 'types'
import { newDateWithoutTime } from 'utils/date'
import { taskBarStates } from '../../TaskBar/types'
import ShiftContent from './ShiftContent'
import { classes } from './styles'
import { getPopoverOrigin } from './utils'

const getTenantShiftsUpdateTaskIds = (tenantShifts?: ShiftsByTenantCCT[]) => {
  const taskIdSet = (tenantShifts ?? []).reduce((acc, tenant) => {
    const shifts = tenant.shifts as PlannedShiftT[]
    shifts.forEach((shift) => {
      if (shift.updateTaskId) {
        acc.add(shift.updateTaskId)
      }
    })

    return acc
  }, new Set<number>())
  return [...taskIdSet]
}

const getPersonDayShiftParams = (
  data: SelectedShiftDataT | null,
  shownTypes: AbsencePlannerBadgeTypesT[],
): PersonDayShiftParamsT => {
  const shiftTypes = shownTypes.filter((type) => type !== 'absenceRequest').map((type) => type as ShiftTypeT)
  return {
    date: data ? data.date : newDateWithoutTime().toISOString(),
    personId: data ? Number(data.person.id) : undefined,
    types: shiftTypes,
  }
}

interface IShiftDialogProps {
  selectedShift: SelectedShiftDataT | null
  dialogChildNode?: HTMLElement | null
  shownTypes: AbsencePlannerBadgeTypesT[]
  onClose: () => void
  cursorClickPosition: { x: number; y: number }
  isUser: boolean
  userIsAbsenceManager: boolean
}

const ShiftDialog: React.FC<IShiftDialogProps> = ({
  dialogChildNode,
  selectedShift,
  shownTypes,
  onClose,
  cursorClickPosition,
  isUser,
  userIsAbsenceManager,
}) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { selectTaskFromGrid } = useSelectedStatesActionsContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { date, personId, types } = useMemo(() => getPersonDayShiftParams(selectedShift, shownTypes), [
    selectedShift,
    shownTypes,
  ])
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const [popoverOrigin, setPopoverOrigin] = useState<{
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
  }>({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  })
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const { shifts, loading: shiftsLoading } = usePersonDayShifts({ date, personId, types }, (_newShits) => {
    if (isSmallScreen) {
      setOpenDrawer(true)
    } else {
      setOpenPopover(true)
      setPopoverOrigin(getPopoverOrigin(cursorClickPosition))
    }
  })

  const shiftUpdateIds = useMemo(() => (isUser ? getTenantShiftsUpdateTaskIds(shifts) : []), [isUser, shifts])
  const { tasks: shiftUpdates, loading: shiftUpdatesLoading } = useShiftUpdates({ taskIds: shiftUpdateIds })

  const { tasks: absenceRequests } = useAbsenceRequests({
    skip: !personId || !userIsAbsenceManager,
    personIds: [personId as number],
    date,
    statuses: [absenceRequestStatus.approvedOffice, absenceRequestStatus.rejectedDeletionRequest],
  })

  const onCloseDialog = useCallback(() => {
    setOpenDrawer(false)
    setOpenPopover(false)
    onClose()
  }, [onClose])

  const onClickLink = useCallback(
    (task: TaskT<AbsenceRequestTaskT>) => {
      if (!selectedShift) return
      const { person } = selectedShift
      const { favurUuid, taskData } = task
      const dates = taskData.dates.map((taskDate) => newDateWithoutTime(taskDate.date))
      selectTaskFromGrid(favurUuid, person, dates)
      setTaskBarState(isUser ? taskBarStates.userTasks : taskBarStates.managerTasks)
      onCloseDialog()
    },
    [isUser, onCloseDialog, selectTaskFromGrid, selectedShift, setTaskBarState],
  )

  const content =
    shiftsLoading || shiftUpdatesLoading ? (
      <SimpleLoading />
    ) : (
      <ShiftContent
        shifts={shifts}
        selectedShift={selectedShift}
        absenceRequests={absenceRequests}
        date={date}
        onClickLink={onClickLink}
        userIsAbsenceManager={userIsAbsenceManager}
        shiftUpdates={shiftUpdates}
      />
    )

  return (
    <>
      {selectedShift && (
        <>
          {isSmallScreen ? (
            <BottomDrawer isOpen={openDrawer} onClose={onCloseDialog}>
              {content}
            </BottomDrawer>
          ) : (
            <Popover
              open={openPopover}
              anchorEl={dialogChildNode}
              onClose={onCloseDialog}
              transformOrigin={popoverOrigin.transformOrigin}
              anchorOrigin={popoverOrigin.anchorOrigin}
              hideBackdrop
              slotProps={{
                paper: {
                  sx: classes.paperPopover,
                },
              }}
            >
              <ClickAwayListener onClickAway={onCloseDialog}>
                <Box sx={classes.content}>
                  <Box sx={classes.popoverContainer}>{content}</Box>
                </Box>
              </ClickAwayListener>
            </Popover>
          )}
        </>
      )}
    </>
  )
}

export default ShiftDialog
