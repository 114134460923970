import React, { useEffect } from 'react'
import { tutorialTypes } from 'constants/tutorials'
import useTutorials from 'hooks/useTutorials'
import PinLogin from 'pages/PinLogin'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import New from './New'

const NewPage: React.FC = () => {
  const history = useHistory()
  const { beenShownBefore, isReady } = useTutorials()

  useEffect(() => {
    if (!isReady || Boolean(beenShownBefore('absenceRequestFrontliner'))) return

    history.push(`${routes.tutorial}/${tutorialTypes.absenceRequestFrontliner}`)
  }, [history, isReady, beenShownBefore])
  return (
    <PinLogin>
      <New />
    </PinLogin>
  )
}

export default NewPage
