import React from 'react'
import { Box, Typography } from '@mui/material'
import Avatar from 'components/Avatar'
import { TeamMemberT } from 'components/ShiftsList/components/ShiftCard/TeamsSection/types'
import { classes } from './styles'

interface IAvatarNameRow {
  teamMember: TeamMemberT
}

const AvatarNameRow: React.FC<IAvatarNameRow> = ({ teamMember }) => {
  return (
    <Box sx={classes.firstRow}>
      <Avatar avatar={teamMember}>
        <Box sx={classes.avatar}>{teamMember.avatarName}</Box>
      </Avatar>
      <Typography variant="subtitle2" sx={classes.rowText}>
        <Box>
          {teamMember.firstName} {teamMember.lastName}
        </Box>
      </Typography>
    </Box>
  )
}

export default AvatarNameRow
