import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const PersonS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 9c3.2 0 5.9 2.1 6 4.8v.2H2c0-2.8 2.7-5 6-5zm0 1.5c-1.8 0-3.3.8-4 2h8c-.7-1.1-2-2-3.8-2H8zM8 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
      />
    </Svg>
  )
}

export default PersonS
