import palette from 'utils/theme/palette'

const commonBadgeProps = {
  maxHeight: '24px',
  minHeight: '14px',
  height: '100%',
  maxWidth: '38px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center',
}

export const badgeClasses = {
  badgeFullWidth: {
    width: '38px',
  },
  badgeHalfWidth: {
    width: '50%',
    maxWidth: '18px',
  },
  afternoonBadge: {
    alignSelf: 'end',
  },
  absenceBadge: {
    ...commonBadgeProps,
    backgroundColor: palette.grey[100],
  },
  requestBadge: (selected: boolean) => ({
    ...commonBadgeProps,
    backgroundColor: selected ? palette.primary[200] : palette.primary[100],
    cursor: 'pointer',
  }),
  shiftBadge: {
    ...commonBadgeProps,
  },
  placeholderBadge: {
    ...commonBadgeProps,
    backgroundColor: palette.grey[50],
  },
  hiddenBadge: {
    ...commonBadgeProps,
    backgroundColor: palette.primary[25],
  },
}
