import React, { useMemo } from 'react'
import EmployeeList from 'components/EmployeeList'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import { PERSONS_LIST_STATUS } from 'constants/person'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortBy } from 'lodash/fp'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getSortingLastName } from 'utils/person'
import PersonListHeader from './PersonListHeader'
import { usePersonsOwner } from './usePersonsOwner'

const PersonList: React.FC<RouteComponentProps<{ ownerId: string; status: string }>> = ({ match }) => {
  const { ownerId } = match.params
  const { status } = match.params

  const { t } = useFavurTranslation()
  const history = useHistory()

  const { activePersons, inactivePersons, loading, hasMultipleTenants } = usePersonsOwner(parseInt(ownerId))

  const { title, personsList, teamWarning, notTeamWarning } = useMemo(() => {
    return status === PERSONS_LIST_STATUS.ACTIVE
      ? {
          title: t('page.teams.ownerActiveMembers'),
          personsList: activePersons,
          teamWarning: false,
          notTeamWarning: true,
        }
      : {
          title: t('page.teams.ownerInactiveMembers'),
          personsList: inactivePersons,
          teamWarning: true,
          notTeamWarning: false,
        }
  }, [activePersons, inactivePersons, status, t])

  if (loading || !activePersons || !inactivePersons) {
    return (
      <Page
        growSubheader
        hideNativeNavigation
        header={<PersonListHeader ownerId={ownerId} title={title} subtitle={t('page.teams.ownerMembersTotal')} />}
      >
        <SimpleLoading />
      </Page>
    )
  }

  return (
    <Page
      growSubheader
      hideNativeNavigation
      header={
        <PersonListHeader
          ownerId={ownerId}
          title={title}
          subtitle={`${t('page.teams.ownerMembersTotal')}: ${personsList.length}`}
        />
      }
    >
      <EmployeeList
        employees={sortBy((p) => getSortingLastName(p), personsList)}
        onClickItem={(employee) =>
          history.push(`${routes.teamOwnerMemberDetail(ownerId, employee.favurUuid as string)}?status=${status}`)
        }
        showTeamAssignedWarning={teamWarning}
        showNotTeamAssignedWarning={notTeamWarning}
        checkValidInvitation
        search
        showTenant={hasMultipleTenants}
      />
    </Page>
  )
}

export default PersonList
