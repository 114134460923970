import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const RefreshM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M9.683 4.153a8.19 8.19 0 0 1 5.47.29.75.75 0 1 0 .576-1.386A9.69 9.69 0 0 0 3.985 17.435H1.688a.75.75 0 1 0 0 1.5h3.799a.757.757 0 0 0 .133 0h.192a.75.75 0 0 0 .75-.75V14.06a.75.75 0 1 0-1.5 0v2.281a8.19 8.19 0 0 1 4.62-12.188Zm9.255 5.782V7.651a8.19 8.19 0 0 1-10.09 11.906.75.75 0 1 0-.578 1.385A9.69 9.69 0 0 0 20.016 6.56h2.297a.75.75 0 0 0 0-1.5h-3.806a.753.753 0 0 0-.122 0h-.198a.75.75 0 0 0-.75.75v4.125a.75.75 0 1 0 1.5 0Z"
      />
    </Svg>
  )
}

export default RefreshM
