import { gql } from '@apollo/client'

export const shareOwnUserDataQuery = gql`
  mutation shareOwnUserData($personIds: [ID]!, $userComment: String) {
    shareOwnUserData(personIds: $personIds, userComment: $userComment) {
      success
      personsWithErrors
    }
  }
`
