import { useCallback, useContext } from 'react'
import { useHistory } from 'react-router'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { ApolloError, OperationVariables, QueryHookOptions } from '@apollo/client'
import useFavurTranslation from './useFavurTranslation'
import useSessionContext from './useSessionContext'

const useJamesApolloError = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  options?: QueryHookOptions<TData, TVariables>,
) => {
  const { refresh } = useSessionContext()
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  const onError = useCallback(
    (error: ApolloError) => {
      if (options?.onError) {
        options.onError(error)
      }

      const { graphQLErrors } = error
      if (graphQLErrors) {
        if (graphQLErrors.some(({ message }) => message === 'not logged in')) {
          refresh()
          history.go(0)
        }

        //@ts-ignore
        const errorKey = graphQLErrors[0].code
        if (errorKey === 'common.error.be.termsAndConditions') {
          history.push(routes.termsAndConditionsUpdate)
        }

        const errorText = t(errorKey)
        // If both are equal means the key is not on the translations file
        if (errorKey !== errorText) {
          removeAll()
          setFlashMessage(errorText)
        }
      }
    },
    [history, options, refresh, removeAll, setFlashMessage, t],
  )

  return onError
}

export default useJamesApolloError
