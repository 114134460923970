import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const AllEmployeesM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M3.5 5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 2 18.5v-13A1.5 1.5 0 0 1 3.5 4h7.086a1.5 1.5 0 0 1 1.06.439l3.915 3.915A1.5 1.5 0 0 1 16 9.414V10.5a.5.5 0 0 1-1 0V10h-3.5A1.5 1.5 0 0 1 10 8.5V5H3.5ZM11 7.683V5.207L14.793 9H11.5a.5.5 0 0 1-.5-.5v-.817Zm7 3.817c-.527 0-1.044.092-1.53.268a.5.5 0 0 0-.136-.066 4.502 4.502 0 0 0-2.864.066.5.5 0 0 0-.136-.066A4.502 4.502 0 0 0 7.5 16a4.5 4.5 0 0 0 5.784 4.313.499.499 0 0 0 .168-.088 4.498 4.498 0 0 0 2.832.088.499.499 0 0 0 .168-.088A4.498 4.498 0 0 0 22.5 16a4.5 4.5 0 0 0-4.5-4.5Zm-5.833 7.996A4.507 4.507 0 0 1 10.5 16a4.5 4.5 0 0 1 1.667-3.496 3.5 3.5 0 1 0 0 6.992ZM15 12.5c.056 0 .111.001.167.004a4.5 4.5 0 0 0 0 6.992A3.5 3.5 0 1 1 15 12.5Zm.525 1.025a3.5 3.5 0 1 1 4.95 4.95l-.003.003c-.443-.809-1.4-1.368-2.511-1.368-1.087 0-2.028.536-2.483 1.317a3.5 3.5 0 0 1 .047-4.902ZM16.333 15a1.667 1.667 0 1 0 3.334 0 1.667 1.667 0 0 0-3.334 0Z"
      />
    </Svg>
  )
}

export default AllEmployeesM
