import theme from 'utils/theme'

export const classes = {
  slide: {
    padding: '16px 12px 0',
  },
  slider: {
    flex: 1,
    display: 'flex',
    // We use "flex-direction" and not "flexDirection" because we are using the "style" property on the SwipeableViews, that lacks "sx"
    'flex-direction': 'column',
  },
  slideContainer: {
    flex: 1,
  },
}
//@ts-ignore
export const backgroundColor = theme.palette.primary[15]
export const headerClasses = {
  subHeader: {
    //@ts-ignore
    backgroundColor: `${backgroundColor} !important`,
    color: `${theme.palette.primary.main}  !important`,
  },
}
