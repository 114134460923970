import { ContactFieldT } from './types'

export const contactDataMutation = `
  contactData(
    email: "#email"
    addresses: #addresses
  )
  {
    success
  }
`

export const getAddressData = (data: ContactFieldT): string => {
  return `{
    firstName: "${data?.firstName || ''}",
    lastName: "${data?.lastName || ''}",
    phoneNumber: "${data?.phoneNumber || ''}",
    address: "${data?.address || ''}",
    additionalAddress: "${data?.additionalAddress || ''}",
    zipCode: "${data?.zipCode || ''}",
    city: "${data?.city || ''}",
    country: "${data?.country || ''}",
    type: "${data.type}",
  }`
}
