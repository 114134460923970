import {
  createTimeLineItem,
  createTimelineItemHeaderOnlyText,
  createTimelineItemHeaderWithAvatarIcon,
  createTimelineItemHeaderWithHrAvatar,
} from 'components/Timeline/factory'
import { TimelineItemComponentT, TimelineItemContentBadgeTypeT, TimelineItemContentT } from 'components/Timeline/types'
import { Locale } from 'date-fns'
import { taskStates } from 'pages/Tasks/constants'
import { ShareUserDataTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { getAvatarFromPerson, getAvatarFromTimeline, getItsMe } from 'pages/Tasks/utils/timeline'
import { TFunction } from 'react-i18next'
import { PersonT } from 'types'
import { getDateFromISOString, getRelativeDate } from 'utils/date'
import { shareUserDataStatus } from '../constants'

const mayAddSharedDataAction = (task: TaskT<ShareUserDataTaskT>, t: TFunction, locale: Locale, isOffice: boolean) => {
  if (task.taskData?.sentDataOn) {
    const date = getRelativeDate(getDateFromISOString(task.taskData.sentDataOn), locale)
    const avatar = getAvatarFromPerson(task?.person as PersonT, t, !isOffice)

    const sharedDataStatus = createTimeLineItem(
      createTimelineItemHeaderWithAvatarIcon('sharedDataStatus', date, avatar),
      { badge: TimelineItemContentBadgeTypeT.sharedPersonalData, text: task.taskData ? task.taskData.userComment : '' },
    )

    return [sharedDataStatus]
  }
  return []
}

const mayAddDefaultClosedAction = (task: TaskT<ShareUserDataTaskT>, t: TFunction, locale: Locale) => {
  if (task.status === shareUserDataStatus.resolvedOffice) {
    const date = getRelativeDate(getDateFromISOString(task.updatedAt), locale)

    const dataValidatedStatus = createTimeLineItem(
      createTimelineItemHeaderWithHrAvatar('dataValidatedStatus', date, t('shareUserData.timeline.HRManager')),
      {
        badge: TimelineItemContentBadgeTypeT.dataValidatedAndAccepted,
        text: task.taskData ? task.taskData.reviewerComment : '',
      },
    )

    return [dataValidatedStatus]
  }

  return []
}

const mayAddClosedElement = (task: TaskT<ShareUserDataTaskT>, t: TFunction) => {
  if (task.statusFe === taskStates.closed) {
    const shareUserDataClosed = createTimeLineItem(
      createTimelineItemHeaderOnlyText('shareUserDataStatusEnd', t('shareUserData.timeline.taskClosed'), true),
    )
    return [shareUserDataClosed]
  }
  return []
}

const getFormItem = (task: TaskT<ShareUserDataTaskT>, t: TFunction, locale: Locale, isOffice: boolean) => {
  const person = task?.person as PersonT
  const date = getRelativeDate(getDateFromISOString(task.insertedAt), locale)

  const formHeader = createTimelineItemHeaderWithHrAvatar(
    'defaultFormItem',
    date,
    t('shareUserData.timeline.HRManager'),
  )
  const formContent = {
    formTaskUuid: task.favurUuid,
    tenantName: person?.tenant?.name || '',
    clickable: task.status === shareUserDataStatus.reviewFrontlineUnseen && !isOffice,
  }

  return createTimeLineItem(formHeader, formContent)
}

const getDefaultTimeline = (task: TaskT<ShareUserDataTaskT>, t: TFunction, locale: Locale, isOffice: boolean) =>
  [
    getFormItem(task, t, locale, isOffice),
    ...mayAddSharedDataAction(task, t, locale, isOffice),
    ...mayAddDefaultClosedAction(task, t, locale),
    ...mayAddClosedElement(task, t),
  ] as TimelineItemComponentT[]

const getTimelineHeader = (
  timelineItem: TimelineItemT,
  t: TFunction,
  locale: Locale,
  itsMe: boolean,
  isFlashing: boolean,
) => {
  const id = `timelineItemHeader-${timelineItem.id}`
  if (timelineItem.authorIsSystem) {
    const date = getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale)
    return createTimelineItemHeaderWithHrAvatar(id, date, t('workTimeControl.timeline.HRManager'), isFlashing)
  }

  const date = getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale)
  const avatar = getAvatarFromTimeline(timelineItem, t, itsMe)

  return createTimelineItemHeaderWithAvatarIcon(id, date, avatar, isFlashing)
}

const getTimelineContent = (
  timelineItem: TimelineItemT,
  task: TaskT<ShareUserDataTaskT>,
  isOffice: boolean,
): TimelineItemContentT => {
  return {
    badge: timelineItem.badge as TimelineItemContentBadgeTypeT,
    text: timelineItem.comment,
    datetime: timelineItem.insertedAt,
    formTaskUuid: timelineItem.isDataForm ? task.favurUuid : undefined,
    tenantName: timelineItem.tenantName,
    clickable: task.status === shareUserDataStatus.reviewFrontlineUnseen && !isOffice,
  }
}

const getTimeline = (
  task: TaskT<ShareUserDataTaskT>,
  t: TFunction,
  locale: Locale,
  personIds: number[],
  isOffice: boolean,
): TimelineItemComponentT[] => {
  if (!task.timeline) {
    return []
  }

  const timelineSize = task.timeline?.length
  const timeline = task.timeline?.map((timelineItem: TimelineItemT, index) => {
    const isFlashing = index + 1 === timelineSize && task.statusFe !== taskStates.closed
    const itsMe = getItsMe(timelineItem.authorId, personIds)
    const timelineHeader = getTimelineHeader(timelineItem, t, locale, itsMe, isFlashing)
    const timelineContent = getTimelineContent(timelineItem, task, isOffice)

    return createTimeLineItem(timelineHeader, timelineContent)
  })

  return [...timeline, ...mayAddClosedElement(task, t)]
}

interface IgetTimelineAttributes {
  task: TaskT<ShareUserDataTaskT>
  t: TFunction
  locale: Locale
  isOffice: boolean
  personIds: number[]
}
export const getTimelineForComponent = ({
  task,
  t,
  locale,
  isOffice = false,
  personIds,
}: IgetTimelineAttributes): TimelineItemComponentT[] => {
  if (task.timeline && task.timeline.length > 0) {
    return getTimeline(task, t, locale, personIds, isOffice)
  }
  return getDefaultTimeline(task, t, locale, isOffice)
}
