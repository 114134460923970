import React from 'react'
import { ReminderTypes } from 'components/Notification/constants'
import { TeamplanCostCenterT } from 'components/ShiftsList/components/types'
import { AuthenticationKind } from 'constants/authentication'
import { ISecuritySetupMutationResult } from 'pages/SecuritySetup/types'
import { MonthlySheetTaskT, TaskT } from 'pages/Tasks/types'
import { TaskTypeT } from 'shared/constants'
import { tutorialNames } from '../constants/tutorials'
import { DeadlinesT } from './utils'

export type GraphQLResponse = {
  me?: MeObject
  invitations?: InvitationsList
  notifications?: NotificationT[]
}

export type AuthPermissions = {
  officeView: boolean
  workflowAbsences: boolean
  workflowMonthlySheets: boolean
  workflowShareUserData: boolean
  workflowShiftUpdate: boolean
  workflowSecureConnection: boolean
}

export type PersonRole = {
  role: RoleT
  tenantId: string
  tenant?: TenantT
}

export type AuthPersonIdAndPermissions = {
  id: number
  status?: string
  ownerId?: number
  tenantId?: number
  workWithdrawal?: string
  permissions: AuthPermissions
  roles?: PersonRole[]
}

export type MeObject = {
  auth: AuthenticationKind
  cmsActivated: boolean
  personIds: number[]
  personPackages: PersonPackageT
  phone: string
  termsAndConditionsAcceptedAt: string
  user: User
}

export type AuthStartResponseT = MeObject & {
  invitationCode?: string
}

export type AuthValidateResponse = {
  authValidate?: MeObject
}

export type PackageBackendT =
  | 'basic'
  | 'classic'
  | 'beta_workflow_absences_classic'
  | 'development'
  | 'classic_light'
  | 'favur_publisher'

export type PackageFrontendT =
  | 'basic'
  | 'classic'
  | 'betaWorkflowAbsencesClassic'
  | 'development'
  | 'classicLight'
  | 'favurPublisher'

export type PackageUtilT = {
  packageFF: string | boolean | void
  personPackages?: PersonPackageT
}

export type PermissionT = 'officeView'

export type PersonPackageT = Record<number, PackageBackendT>

export type ShiftTimeOfDayT = 'whole' | 'am' | 'pm'

export const shiftTimesOfDay: { [key: string]: ShiftTimeOfDayT } = {
  whole: 'whole',
  am: 'am',
  pm: 'pm',
}

export type ShiftTypeT = 'shift' | 'absence' | 'effectiveShift' | 'cmsEvent'

export type ShiftT = {
  id: number
  from: string | null
  until: string | null
  timeType: string
  personId: number
  costCenterUuid?: string
  costCenterName?: string
  percent?: number
  // This type is used when the BE returns the grouped result. Normal shifts result have this type in 'shiftAbs'
  type?: ShiftTypeT
  timeOfDay?: ShiftTimeOfDayT
  person?: PersonT
  shiftAbs?: ShiftTypeT
}

export type PlannedShiftT = ShiftT & {
  date: string
  description: string
  shortDescription: string
  tenantId: string
  tenantName: string
  updated: number
  note: string
  hexBackgroundColor: string
  hexTextColor: string
  isManual: boolean
  updateTaskId?: number
}

export type EffectiveShiftT = ShiftT & {
  validated: boolean
}

export type ShiftsByTenantCCT = {
  shifts: (PlannedShiftT | EffectiveShiftT)[]
  tenantId: string
  tenantName: string
  costCenterName: string
  costCenterUuid: string
  hideUnverifiedEffectiveTimes?: boolean
  ownerId?: string
}

export type QueryDates = {
  start: string
  end: string
}

export type UserObject = {
  user: User
}

export type User = {
  userId?: number
  id?: number
  firstName: string
  lastName: string
  gender: string
  phoneNumber: string
  birthDate: string
  settings: SettingsT
  userDataCollectionLastUpdate?: string
  avatarUrl: string | null
  language?: string | null
}

export type TutorialId = typeof tutorialNames[keyof typeof tutorialNames]

export type SettingsT = {
  pushNotifications: SettingsPushNotificationsT
  inAppNotifications: SettingsInAppNotificationsT
  seenTutorials?: TutorialId[]
  dashboardEmployeeCards?: string[]
}

export type SettingsPushNotificationsT = {
  absenceRequest: boolean
  documents: boolean
  frontlinerAbsenceRequest: boolean
  invitations: boolean
  monthlySheetReminder: boolean
  newShifts: boolean
  officeMonthlySheetApproved: boolean
  receivedUserData: boolean
  shareUserData: boolean
  shiftsUpdate: boolean
  shiftUpdateSeen: boolean
  secureConnectionFrontliner: boolean
  secureConnectionManager: boolean
}

export type SettingsPushNotificationsOptionsT =
  | 'absenceRequest'
  | 'documents'
  | 'frontlinerAbsenceRequest'
  | 'invitations'
  | 'monthlySheetReminder'
  | 'newShifts'
  | 'officeMonthlySheetApproved'
  | 'receivedUserData'
  | 'shareUserData'
  | 'shiftsUpdate'
  | 'shiftUpdateSeen'
  | 'cmsPage'
  | 'cmsEvent'
  | 'cmsNewsletter'
  | 'secureConnectionFrontliner'
  | 'secureConnectionManager'

export type SettingsInAppNotificationsT = {
  absenceRequest: boolean
  officeMonthlySheetApproved: boolean
  receivedUserData: boolean
  shiftUpdateSeen: boolean
}

export type SettingsInAppNotificationsOptionsT =
  | 'absenceRequest'
  | 'officeMonthlySheetApproved'
  | 'receivedUserData'
  | 'shiftUpdateSeen'

export type InvitationsList = {
  invitationsList: InvitationT[]
}

export type InvitationT = {
  code: string
  tenantName: string
  status: number
  updatedAt: string
  phoneNumber: string
  userId?: number
}

export type NotificationStatusT = 'new' | 'acknowledged' | 'outdated'
export const notificationStatuses: { [key: string]: NotificationStatusT } = {
  new: 'new',
  acknowledged: 'acknowledged',
  outdated: 'outdated',
}

type NotificationBaseT = {
  id: number
  updatedAt: string
  status: NotificationStatusT
  invitationCode?: string
  dataType?: string
}

export enum NotificationTypes {
  absenceRequestCommentForFrontliner = 'absence_request_comment_for_frontliner',
  absenceRequestCommentForManager = 'absence_request_comment_for_manager',
  absenceRequestRequestDeletion = 'absence_request_request_deletion',
  document = 'document',
  documentMonthlySheetApproval = 'document_monthly_sheet_approval',
  invitation = 'invitation',
  linkMessage = 'link_message',
  monthlySheetCommentForFrontliner = 'monthly_sheet_comment_for_frontliner',
  monthlySheetCommentForManager = 'monthly_sheet_comment_for_manager',
  officeAbsenceRequest = 'office_absence_request',
  officeMonthlySheetApproved = 'office_monthly_sheet_approved',
  receivedUserData = 'received_user_data',
  reminder = 'reminder',
  reminderAbsenceRequestManualForManager = 'reminder_absence_request_manual_for_manager',
  reminderMonthlySheetManual = 'reminder_monthly_sheet_manual',
  reminderMonthlySheetManualForManager = 'reminder_monthly_sheet_manual_for_manager',
  reminderSecureConnection = 'reminder_secure_connection',
  reminderSecureConnectionManualForFrontliner = 'reminder_secure_connection_manual_for_frontliner',
  reminderShareUserData = 'reminder_share_user_data',
  reminderShareUserDataManual = 'reminder_share_user_data_manual',
  reminderShareUserDataManualForManager = 'reminder_share_user_data_manual_for_manager',
  reminderShiftUpdate = 'reminder_shift_update',
  reminderShiftUpdateManual = 'reminder_shift_update_manual',
  resolvedAbsenceRequest = 'resolved_absence_request',
  resolvedAbsenceRequestRequestDeletion = 'resolved_absence_request_request_deletion',
  secureConnection = 'secure_connection',
  secureConnectionCommentForFrontliner = 'secure_connection_comment_for_frontliner',
  secureConnectionCommentForManager = 'secure_connection_comment_for_manager',
  secureConnectionCompleted = 'secure_connection_completed',
  shareUserData = 'share_user_data',
  shareUserDataAccepted = 'share_user_data_accepted',
  shareUserDataCommentForFrontliner = 'share_user_data_comment_for_frontliner',
  shareUserDataCommentForManager = 'share_user_data_comment_for_manager',
  shift = 'shift',
  shiftInserted = 'inserted',
  shiftUpdateSeen = 'shift_update_seen',
  shiftUpdated = 'updated',
  resignationInfo = 'resignation_info',
  cmsNewContent = 'cms_new_content',
  cmsUpdateContent = 'cms_update_content',
}

export type InvitationNotificationT = NotificationBaseT & {
  type: NotificationTypes.invitation
  data: {
    code: string
    tenantName: string
  }
}

export type ShiftNotificationT = NotificationBaseT & {
  personId: number
  type: NotificationTypes.shift
  data: {
    dateTo: string
    dateFrom: string
    count: number
    dates: { [key: string]: { status: string } }
    taskUuid?: string
  }
}

export type ShiftUpdateSeenNotificationT = NotificationBaseT & {
  personId: number
  type: NotificationTypes.shiftUpdateSeen
  data: {
    dateTo: string
    dateFrom: string
    count: number
    taskUuid?: string
    userName: string
    userNameShort: string
  }
}

export type DocumentNotificationT = NotificationBaseT & {
  type: NotificationTypes.document
  data: {
    documentType: string
    documentId?: number
    dateFrom: string
    dateTo: string
    taskUuid?: string
  }
}

export type OfficeAbsenceRequestNotificationT = NotificationBaseT & {
  type: NotificationTypes.officeAbsenceRequest
  data: {
    dateFrom: string
    dateTo: string
    period: string
    comment: string
    taskUuid: string
    userName: string
    userNameShort: string
  }
}

export type ResolvedAbsenceRequestNotificationT = NotificationBaseT & {
  type: NotificationTypes.resolvedAbsenceRequest
  data: {
    dateFrom: string
    dateTo: string
    period: string
    comment: string
    taskUuid: string
    state: string
    managerName: string
    managerNameShort: string
  }
}

export type ShareUserDataNotificationT = NotificationBaseT & {
  type: NotificationTypes.shareUserData
  data: {
    taskUuid: string
    tenantName: string
  }
}

export type ShareUserDataCommentForFrontlinerNotificationT = NotificationBaseT & {
  type: NotificationTypes.shareUserDataCommentForFrontliner
  data: {
    taskUuid: string
    managerNameShort: string
    commentShort: string
    badge: string
  }
}

export type ShareUserDataAcceptedNotificationT = NotificationBaseT & {
  type: NotificationTypes.shareUserDataAccepted
  data: {
    taskUuid: string
    tenantName: string
  }
}

export type ReceivedUserDataNotificationT = NotificationBaseT & {
  type: NotificationTypes.receivedUserData
  data: {
    taskUuid: string
    userName: string
    userNameShort: string
    tenantName: string
  }
}

export type LinkMessageNotificationT = NotificationBaseT & {
  type: NotificationTypes.linkMessage
  data: {
    titleKey: string
    bodyKey: string
    linkButtonKey: string
    linkKey: string
  }
}

export type OfficeMonthlySheetApprovedNotificationT = NotificationBaseT & {
  type: NotificationTypes.officeMonthlySheetApproved
  data: {
    dateFrom: string
    dateTo: string
    documentId: number
    personId: number
    taskUuid: string
    userName: string
    userNameShort: string
  }
}

export type ReminderMonthlySheetNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminder
  data: {
    dateFrom: string
    dateTo: string
    documentId: number
    reminderDeadline: DeadlinesT
    reminderType: ReminderTypes.monthlySheetApproval
    taskUuid?: string
  }
}

export type ReminderShiftUpdateNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderShiftUpdate
  data: {
    taskUuid: string
  }
}

export type ReminderShiftUpdateManualNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderShiftUpdateManual
  data: {
    taskUuid: string
    managerNameShort: string
  }
}

export type ReminderMonthlySheetManualNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderMonthlySheetManual
  data: {
    taskUuid: string
    managerNameShort: string
    dateFrom: string
    dateTo: string
    documentId: number
  }
}

export type ReminderMonthlySheetManualForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderMonthlySheetManualForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
    documentId: number
  }
}

export type ReminderAbsenceRequestManualForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderAbsenceRequestManualForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
  }
}

export type ReminderShareUserDataNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderShareUserData
  data: {
    taskUuid: string
  }
}

export type ReminderShareUserDataManualNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderShareUserDataManual
  data: {
    taskUuid: string
    managerNameShort: string
  }
}

export type ReminderShareUserDataManualNotificationForManagerT = NotificationBaseT & {
  type: NotificationTypes.reminderShareUserDataManualForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
  }
}

export type ReminderNotificationT = ReminderMonthlySheetNotificationT

export type MonthlySheetCommentForFrontlinerNotificationT = NotificationBaseT & {
  type: NotificationTypes.monthlySheetCommentForFrontliner
  data: {
    taskUuid: string
    managerNameShort: string
    commentShort: string
    badge: string
  }
}

export type ShareUserDataCommentForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.shareUserDataCommentForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
    commentShort: string
    badge: string
  }
}

export type MonthlySheetCommentForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.monthlySheetCommentForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
    commentShort: string
    badge: string
  }
}

export type AbsenceRequestCommentForFrontlinerNotificationT = NotificationBaseT & {
  type: NotificationTypes.absenceRequestCommentForFrontliner
  data: {
    taskUuid: string
    managerNameShort: string
    commentShort: string
    badge: string
  }
}

export type AbsenceRequestCommentForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.absenceRequestCommentForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
    commentShort: string
    badge: string
  }
}

export type AbsenceRequestRequestDeletionNotificationT = NotificationBaseT & {
  type: NotificationTypes.absenceRequestRequestDeletion
  data: {
    taskUuid: string
    frontlinerNameShort: string
    dateFrom: string
    dateTo: string
    period: string
  }
}

export type ResolvedAbsenceRequestRequestDeletionNotificationT = NotificationBaseT & {
  type: NotificationTypes.resolvedAbsenceRequestRequestDeletion
  data: {
    taskUuid: string
    managerNameShort: string
    dateFrom: string
    dateTo: string
    period: string
    state: string
  }
}

export type ResignationInfoNotificationT = NotificationBaseT & {
  type: NotificationTypes.resignationInfo
  data: {
    tenantName: string
    resignationDate: string
  }
}

export type SecureConnectionNotificationT = NotificationBaseT & {
  type: NotificationTypes.secureConnection
  data: {
    taskUuid: string
    tenantName: string
  }
}

export type ReminderSecureConnectionNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderSecureConnection
  data: {
    taskUuid: string
  }
}

export type SecureConnectionCommentForFrontlinerNotificationT = NotificationBaseT & {
  type: NotificationTypes.secureConnectionCommentForFrontliner
  data: {
    taskUuid: string
    managerNameShort: string
    commentShort: string
    badge: string
  }
}

export type SecureConnectionCommentForManagerNotificationT = NotificationBaseT & {
  type: NotificationTypes.secureConnectionCommentForManager
  data: {
    taskUuid: string
    frontlinerNameShort: string
    commentShort: string
    badge: string
  }
}

export type ReminderSecureConnectionManualForFrontlinerNotificationT = NotificationBaseT & {
  type: NotificationTypes.reminderSecureConnectionManualForFrontliner
  data: {
    taskUuid: string
    managerNameShort: string
  }
}

export type SecureConnectionCompletedNotificationT = NotificationBaseT & {
  type: NotificationTypes.secureConnectionCompleted
  data: {
    taskUuid: string
    frontlinerNameShort: string
    tenantName: string
  }
}

type CmsContentNotificationBaseT = {
  data: {
    contentUuid: string
    notificationUuid: string
    type: string
    title: string
    startDate?: string | null
    endDate?: string | null
    isWholeDay?: boolean
  }
}

export type CmsNewContentNotificationT = NotificationBaseT &
  CmsContentNotificationBaseT & {
    type: NotificationTypes.cmsNewContent
  }

export type CmsUpdateContentNotificationT = NotificationBaseT &
  CmsContentNotificationBaseT & {
    type: NotificationTypes.cmsUpdateContent
  }

export type NotificationT =
  | DocumentNotificationT
  | InvitationNotificationT
  | LinkMessageNotificationT
  | OfficeAbsenceRequestNotificationT
  | OfficeMonthlySheetApprovedNotificationT
  | ReceivedUserDataNotificationT
  | ReminderNotificationT
  | ReminderShiftUpdateNotificationT
  | ResolvedAbsenceRequestNotificationT
  | ShareUserDataNotificationT
  | ShareUserDataCommentForFrontlinerNotificationT
  | ShareUserDataAcceptedNotificationT
  | ShareUserDataCommentForManagerNotificationT
  | ShiftNotificationT
  | ShiftUpdateSeenNotificationT
  | ReminderMonthlySheetManualNotificationT
  | ReminderMonthlySheetManualForManagerNotificationT
  | ReminderAbsenceRequestManualForManagerNotificationT
  | ReminderShareUserDataNotificationT
  | ReminderShareUserDataManualNotificationT
  | ReminderShareUserDataManualNotificationForManagerT
  | ReminderShiftUpdateManualNotificationT
  | MonthlySheetCommentForFrontlinerNotificationT
  | MonthlySheetCommentForManagerNotificationT
  | AbsenceRequestCommentForFrontlinerNotificationT
  | AbsenceRequestCommentForManagerNotificationT
  | AbsenceRequestRequestDeletionNotificationT
  | ResolvedAbsenceRequestRequestDeletionNotificationT
  | ResignationInfoNotificationT
  | CmsNewContentNotificationT
  | CmsUpdateContentNotificationT
  | SecureConnectionNotificationT
  | ReminderSecureConnectionNotificationT
  | SecureConnectionCommentForFrontlinerNotificationT
  | SecureConnectionCommentForManagerNotificationT
  | ReminderSecureConnectionManualForFrontlinerNotificationT
  | SecureConnectionCompletedNotificationT

export type SessionValidityT = {
  highSecSessionValidUntil: string
  highSecSessionValidNow: boolean
  securitySetupValidUntil: string
  securitySetupValidNow: boolean
  pinResetValidUntil: string
  pinResetValidNow: boolean
  timestamp: string
}

export type TaskMonthlySheetT = {
  document?: DocumentT
  documentId: number
  taskId: number
  task?: TaskT<MonthlySheetTaskT>
}

export type DocumentT = {
  createdByMirus: string
  data: DocumentDataT
  documentType: string
  documentUuid: string
  favurUuid: string
  id: number
  lang: string
  person?: PersonT
  status: string
  tenant: { name: string; package: string }
  taskMonthlySheet?: TaskMonthlySheetT
}

export type DocumentDataT = {
  acceptedAt?: string
  endDate?: string
  startDate?: string
}

export type MutationResult = { success: boolean }

export type CreateAbsenceRequestResultT = {
  createAbsence: { success: boolean; taskUuid: string }
}

export type ApproveAbsenceRequestResultT = {
  updateAbsenceStatus: MutationResult
}

export type UpdateUserSettingsResultT = {
  updateUserSettings: MutationResult
}

export type AcceptTermsConditionsResultT = {
  acceptTermsConditions: MutationResult
}

export type ResendSMSResult = {
  resendSMS: MutationResult
}

export type SecureConnectionResultT = {
  status: string
  validUntil: string
  tenantName: string
  errorCode: string
}

export type PersonData = {
  personId: number | string
  tenantName: string
}

export type VerifyStepProps = {
  lowSecTenants: PersonData[] | undefined
  onVerifyToken: (personId: number, token: string) => Promise<{ establishSecureConnection: SecureConnectionResultT }>
  taskUuid: string | null
  nextAction: string
}

export type SetPinStepProps = {
  taskUuid: string | null
  pin: string | undefined
  firstPin: string
  setFirstPin: (pin: string | ((prevPin: string) => string)) => void
  secondPin: string
  setSecondPin: (pin: string | ((prevPin: string) => string)) => void
  onConfirmPin: () => Promise<ISecuritySetupMutationResult>
}

export type ExtendedStepProps = VerifyStepProps | SetPinStepProps

export type AdditionalStepsProps = {
  [key: string]: ExtendedStepProps
}

export type StepT = {
  name: string
  index: number
  icon: React.FC<{ fill?: string | undefined }> | undefined
  component: React.FC<{
    goToStep: (stepName: string, additionalUrlParams?: string[]) => void
    hideStepper: (hide: boolean) => void
    oldPhone?: string
    setOldPhone?: (oldPhone: string) => void
    additionalProperties?: ExtendedStepProps
  }>
}

export type ConnectionResponseT = {
  connections: {
    invitations: InvitationT[]
    persons: PersonT[]
  }
}

export type ActiveConnectionResponseT = {
  connections: {
    persons: PersonT[]
  }
}

export type PermissionsT = {
  officeView: boolean
  permissionsChangedAt?: string
}

export type PersonT = {
  favurUuid?: string
  firstName: string
  id?: number
  invitation?: InvitationT
  lastName: string
  lastSharedData?: string
  owner?: OwnerT
  permissions?: PermissionsT
  teamPermissions?: TeamPermissionT[]
  tenant?: TenantT
  tenantName?: string
  tenantConnectionEstablished?: string
  tenantConnectionStatus?: 'low' | 'high'
  updatedAt?: string
  user?: User
  status?: string
  numberOfDocuments?: number
  deleteAfter?: string | null
  roles?: PersonRole[]
  employeeNumber?: string
  costCenter?: TeamplanCostCenterT
  workWithdrawal?: string
  workEntry?: string
}

export type OwnerT = {
  id: number
  name: string
  persons?: PersonT[]
  tenants?: TenantT[]
}

export type TenantT = {
  uuid?: string
  id: string
  name: string
  persons?: PersonT[]
  package?: PersonPackageT
  absencesBlockingPeriod?: number | null
  links?: TenantLinkT[]
  hideUnverifiedEffectiveTimes?: boolean
  reminders?: TenantReminderT[]
}

export type TenantLinkT = {
  id: string
  url: string
  labelEn: string | null
  labelDe: string | null
  labelIt: string | null
  labelFr: string | null
}

export type TenantReminderT = {
  type: TaskTypeT
  first: number
  second: number
  third: number
}

export enum WorkflowsT {
  absenceManager = 'absenceManager',
  monthlySheetManager = 'monthlySheetManager',
  secureConnectionManager = 'secureConnectionManager',
  shareUserDataManager = 'shareUserDataManager',
  shiftUpdateManager = 'shiftUpdateManager',
}

export enum RolesT {
  supervisor = 'supervisor',
}

export type WorkflowPermissionsT = {
  [WorkflowsT.absenceManager]?: boolean
  [WorkflowsT.monthlySheetManager]?: boolean
  [WorkflowsT.secureConnectionManager]?: boolean
  [WorkflowsT.shareUserDataManager]?: boolean
  [WorkflowsT.shiftUpdateManager]?: boolean
}

export type RolesPermissionsT = {
  [RolesT.supervisor]?: boolean
}

export type TeamPermissionT = WorkflowPermissionsT &
  RolesPermissionsT & {
    teamId?: string
    team?: TeamT
    teamPermissions?: string
  }
export type PersonTeamPermissionT = Required<WorkflowPermissionsT & RolesPermissionsT> & {
  personId: number
  teamId: string
  person?: PersonT
  team?: TeamT
}

export type TeamDataT = {
  id?: string
  ownerId?: number
  tenantId?: number
  nameDe?: string
  nameEn?: string
  nameFr?: string
  nameIt?: string
  public?: boolean
  showTeamplanCostCenters?: boolean
}

export type TeamT = Required<TeamDataT> & {
  teamPermissions?: PersonTeamPermissionT[]
  tenant?: TenantT
  owner?: OwnerT
}

export type TeamWithUserPermissions = TeamT & { userPermissions: WorkflowPermissionsT & RolesPermissionsT }

export type TenantNamesT = {
  tenantName: string
  tenantConnectionStatus: string
  personId: string
  favurUuid: string
  status?: string
  tenantAbsencesBlockingPeriod?: number | null
  permissions: PermissionsT
}

export type TabT = {
  id: string | number
  label: React.ReactNode
  disabled?: boolean
}

export type TaskCountsT = {
  todo: number
  pending: number
  closed: number
  secondView: number
}

// see elixir/apps/favur/lib/favur/shift/updated_status.ex
export enum ShiftUpdateType {
  notUpdated,
  updated,
  acknowledged,
  inserted,
  deleted,
}

export type RoleViewT = 'frontliner' | 'office'

export type RoleT = 'global_tenant_settings' | 'manage_teams' | 'manage_global_teams' | 'tenant_settings'
export type RoleKeyT = 'globalTenantSettings' | 'manageTeams' | 'manageGlobalTeams' | 'tenantSettings'
export const ROLES: Record<RoleKeyT, RoleT> = {
  globalTenantSettings: 'global_tenant_settings',
  manageTeams: 'manage_teams',
  manageGlobalTeams: 'manage_global_teams',
  tenantSettings: 'tenant_settings',
}
export interface IDefaultComponentWIthStepperProps {
  steps: StepT[]
  urlStep: string | undefined
}

export type TeamPermissionTypeT =
  | 'absence_request_manager'
  | 'share_user_data_manager'
  | 'monthly_sheet_manager'
  | 'shift_update_manager'
export const teamPermissionTypes: Record<string, TeamPermissionTypeT> = {
  absenceRequestManager: 'absence_request_manager',
  shareUserDataManager: 'share_user_data_manager',
  monthlySheetManager: 'monthly_sheet_manager',
  shiftUpdateManager: 'shift_update_manager',
}

export type AvatarDataT = {
  avatarName: string
  avatarUrl?: string
  avatarUserName?: string
}

export type AbsenceRequestConflictsT = {
  vacationsCount?: number
  vacationsPersons?: PersonT[]
  otherLeavesCount?: number
  otherLeavesPersons?: PersonT[]
  pendingRequestsCount?: number
  pendingRequestsPersons?: PersonT[]
}

export type CmsContentMetadataT = {
  language?: string
  title?: string
  shortDescription?: string
  slug?: string
}

export type ImageContentBlockT = CmsContentBlockDataT & {
  filename: string
  altText: string
  src: string
}

export type SubcontentBlockT = CmsContentBlockDataT & {
  previewImageFilename?: string
  previewText?: string
  subcontentUuid: string
  src?: string
}

export type DocumentContentBlockT = CmsContentBlockDataT & {
  filename: string
  filesize: string
  src: string
}

export type TextContentBlockT = CmsContentBlockDataT & {
  html: string
}

export type IconContentBlockT = CmsContentBlockDataT & {
  variant: string
}

export type LinkContentBlockT = CmsContentBlockDataT & {
  route: string
  text: string
  variant?: 'text' | 'contained' | 'outlined'
}

export type CmsContentBlockDataT = unknown

export type CmsContentBlocksT = {
  language?: string
  order?: number
  type?: string
  data?: CmsContentBlockDataT
  blockCss?: string
}

export const AUDIENCE_TYPE = {
  tenant: 'tenant',
  owner: 'owner',
  team: 'team',
} as const

export type Audience = {
  uuid: string
  audienceType: keyof typeof AUDIENCE_TYPE
  ownerId?: string
  tenantId?: string
  teamId?: string
  nameDe?: string
  nameEn?: string
  nameFr?: string
  nameIt?: string
}

export type HistoryModificationTypeT =
  | 'create_content'
  | 'update_content'
  | 'update_content_status_published'
  | 'update_content_status_unpublished'
  | 'update_content_status_draft'
  | 'send_notification'

export const historyModificationTypes: Record<string, HistoryModificationTypeT> = {
  createContent: 'create_content',
  updateContent: 'update_content',
  updateContentStatusPublished: 'update_content_status_published',
  updateContentStatusUnublished: 'update_content_status_unpublished',
  updateContentStatusDraft: 'update_content_status_draft',
  sendNotification: 'send_notification',
}

export type History = {
  insertedAt?: string
  updatedAt?: string
  userFirstName?: string
  userLastName?: string
  avatarImageUuid?: string
  modificationType?: HistoryModificationTypeT
}

export type CmsContentT = {
  audiences?: Audience[]
  createdAt?: string
  history?: History[]
  uuid?: string
  contentType?: string
  contentStatusType?: string
  metadatas?: CmsContentMetadataT[]
  blocks?: CmsContentBlocksT[]
  startDate?: string
  sentAt?: string
  endDate?: string
  isWholeDay?: boolean
  isAbsenceRestricted?: boolean
  restrictAbsenceComment?: string
}

export type CmsPaginatedContentT = {
  pageNumber?: number
  pageSize?: number
  totalPages?: number
  totalEntries?: number
  entries?: CmsContentT[]
}

export type GetLastUpdatedDateReturnT = {
  getLastUpdatedDate: {
    lastUpdatedDate: string
  }
}

export type AnnouncementT = {
  blocks: CmsContentBlocksT[]
  id: string
  route: string
}

export type UserAnnouncementT = {
  id: string
  announcementId: string
  userId: number
  announcement: AnnouncementT
}
