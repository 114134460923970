import React, { useCallback, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { getShortName } from 'utils/person'
import HowToDialog from '../components/HowToDialog'
import { taskStates } from '../constants'
import classes from '../styles'
import { ShareUserDataTaskT, TaskCardDataT } from '../types'

const ShareUserDataBody: React.FC<TaskCardDataT<ShareUserDataTaskT>> = ({ office, task }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { storeState } = useFilterContext()
  const taskState = task.statusFe

  const [showDialog, setShowDialog] = useState(false)

  const titleTranslation = useMemo(() => {
    if (office) {
      if (taskState === taskStates.todo) {
        return 'tasks.new.sharedata.manager.open.headline'
      }
      if (taskState === taskStates.pending) {
        return 'tasks.new.sharedata.manager.pending.headline'
      }
      if (taskState === taskStates.closed) {
        return 'tasks.new.sharedata.manager.closed.headline'
      }
    }
    if (taskState === taskStates.todo) {
      return 'tasks.new.sharedata.frontliner.open.headline'
    }
    if (taskState === taskStates.pending) {
      return 'tasks.new.sharedata.frontliner.pending.headline'
    }
    return 'tasks.new.sharedata.frontliner.closed.headline'
  }, [office, taskState])

  const contentComponents = useMemo(() => {
    const tenant = (
      <Typography sx={classes.taskCardBodyContent} variant="body2">
        <b>{t('tasks.new.sharedata.tenant')}</b> {task.tenantName}
      </Typography>
    )

    const explanation = (
      <Typography sx={classes.taskCardBodyContent} variant="body2">
        {t('tasks.new.sharedata.explanation')}
      </Typography>
    )

    const commentFrontliner =
      // We don't get comments like this anymore, check other tasks. This is legacy, from when we didn't have timelines
      task.taskData && task.taskData.userComment ? (
        <Typography sx={classes.taskCardBodyContent} variant="body2">
          <b>{t('tasks.new.sharedata.comment_frontliner')}</b> {task.taskData.userComment}
        </Typography>
      ) : null

    const commentManager =
      task.taskData && task.taskData.reviewerComment ? (
        <Typography sx={classes.taskCardBodyContent} variant="body2">
          <b>{t('tasks.new.sharedata.comment_manager')}</b> {task.taskData.reviewerComment}
        </Typography>
      ) : null

    if (office) {
      if (taskState === taskStates.pending) return []
      if (taskState === taskStates.todo) return [commentFrontliner, explanation]
      return [commentFrontliner, commentManager]
    }
    if ([taskStates.todo, taskStates.pending].includes(taskState)) return [tenant]
    return [tenant, commentManager]
  }, [office, t, task, taskState])

  const detailsCallback = useCallback(() => {
    storeState()
    history.push(`${routes.userDataDetails}/${task.favurUuid}`)
  }, [history, storeState, task.favurUuid])

  return (
    <Box
      sx={classes.taskCardBody}
      data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-share-user-data-body`}
    >
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle1">
        {t(titleTranslation, { frontlinerNameShort: task.person ? getShortName(task.person) : '-' })}
      </Typography>
      <Box sx={[classes.taskCardContentWrapper]}>{contentComponents}</Box>
      <Box sx={classes.taskCardCTAContainer}>
        <Typography sx={classes.callToAction} variant="button" component="button" onClick={detailsCallback}>
          {t('tasks.absence_request.details')}
        </Typography>
      </Box>
      {showDialog && <HowToDialog onClose={() => setShowDialog(false)} />}
    </Box>
  )
}

export default ShareUserDataBody
