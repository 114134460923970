import theme from 'utils/theme'

export const absencePlannerDrawerClasses = {
  drawer: {
    height: `calc(100vh - var(--ion-safe-area-top))`,
  },
  paper: {
    height: 'calc(100% - var(--ion-safe-area-top))',
    maxWidth: '600px',
    margin: '0 auto',
    zIndex: 1400,
  },
  content: {
    top: '-1px',
    display: 'flex',
    alignItems: 'center',
  },
  topContent: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.primary[50]}`,
    minHeight: '48px',
    width: '100%',
  },
  closeButton: {
    width: '68px',
  },

  whiteBlock: {
    position: 'absolute',
    left: 0,
    zIndex: 35,
    display: 'flex',
    top: '48.5px',
    background: '#fff',
    width: '67px',
    height: '74px',
    borderRight: '1px solid #cccccc',
  },
}
