import { createContext, Context } from 'react'
import { IDialogContext, IDialogInstance } from './types'

const initialState: IDialogContext = {
  addDialog: (_dialog: IDialogInstance | string) => {},
  removeDialog: (_path: string) => {},
  openDialog: (_path: string) => {},
  closeDialog: (_path: string) => {},
  isOpen: (_path: string) => false,
  dialogs: {},
}

const DialogContext: Context<IDialogContext> = createContext(initialState)

export default DialogContext
