import theme from 'utils/theme'
import favurTheme from 'utils/theme'

export const classes = {
  noticeBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  deleteAccountText: {
    paddingTop: '8px',
  },
  deactivateButtonBlcok: {
    textAlign: 'right',
    paddingTop: '24px',
  },
  deactivateButton: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
    letterSpacing: '0.9px',
  },
  employersSection: {
    marginTop: '16px',
  },
  successTitle: {
    fontFamily: 'Signika',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3.0),
    fontSize: '23px',
    fontWeight: 600,
    lineHeight: '29px',
    letterSpacing: '0.2px',
  },
  titleTop: {
    paddingTop: '46px',
  },
  subtitle: {
    whiteSpace: 'pre-wrap',
    color: theme.palette.primary.main,
    lineHeight: '20px',
  },
  successContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successTexts: {
    width: '296px',
    textAlign: 'center',
  },
  successContainerButton: {
    width: '168px',
  },
  sucessDeleteConnectionContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    width: '296px',
    textAlign: 'center',
  },

  commentHeaderContainer: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  commentTextBody: {
    marginTop: '20px',
  },
  deleteUserDialog: {
    padding: favurTheme.spacing(1.0),
  },
  deleteUserDialogButtons: {
    paddingTop: '3px',
  },
}
