import React, { useState } from 'react'
import EmployeePermissionsContext, { EmployeePermissionsParamsT, initialState } from './context'

interface IEmployeePermissionsProviderProps {
  children?: React.ReactNode
}

const EmployeePermissionsService: React.FC<IEmployeePermissionsProviderProps> = ({ children }) => {
  const [employeePermissionsParams, setEmployeePermissionsParams] = useState<EmployeePermissionsParamsT>(
    initialState.employeePermissionsParams,
  )

  return (
    <EmployeePermissionsContext.Provider value={{ employeePermissionsParams, setEmployeePermissionsParams }}>
      {children}
    </EmployeePermissionsContext.Provider>
  )
}

export default EmployeePermissionsService
