import theme from 'utils/theme'

export const classes = {
  componentContainer: {
    padding: theme.spacing(0.75, 1.25),
    paddingBottom: theme.spacing(1),
    marginBottom: '0px !important',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
}
