import React, { Context, Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import { TaskBarStateT } from '../components/TaskBar/types'

export type TaskBarStateContextT = {
  taskBarState: TaskBarStateT | null
}

export type TaskBarStateDispatchContextT = Dispatch<SetStateAction<TaskBarStateT | null>>

const taskBarInitialState: TaskBarStateContextT = {
  taskBarState: null,
}

const taskBarDispatchInitialState: TaskBarStateDispatchContextT = () => {}

export const TaskBarStateContext: Context<TaskBarStateContextT> = createContext(taskBarInitialState)
export const TaskBarStateDispatchContext: Context<TaskBarStateDispatchContextT> = createContext(
  taskBarDispatchInitialState,
)

export const TaskBarStateProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [taskBarState, setTaskBarState] = useState<TaskBarStateT | null>(null)

  return (
    <TaskBarStateContext.Provider value={{ taskBarState }}>
      <TaskBarStateDispatchContext.Provider value={setTaskBarState}>{children}</TaskBarStateDispatchContext.Provider>
    </TaskBarStateContext.Provider>
  )
}

export const useTaskBarStateContext = () => {
  const context = useContext(TaskBarStateContext)

  return { ...context }
}

export const useTaskBarStateDispatchContext = () => {
  const setTaskBarState = useContext(TaskBarStateDispatchContext)

  return setTaskBarState
}

export default useTaskBarStateContext
