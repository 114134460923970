import axios, { AxiosResponse, CancelToken } from 'axios'
import { apiPath, getAPIURL } from 'constants/environment'
import useSessionContext from 'hooks/useSessionContext'
import { useQuery, QueryKey, QueryConfig } from 'react-query'
import { useHistory } from 'react-router-dom'

export const fetchInfo = <T>(query: string, token?: CancelToken) => async () => {
  return (
    axios
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .post<any, AxiosResponse<{ data: T }>>(
        `${getAPIURL()}${apiPath}`,
        { query },
        { withCredentials: true, cancelToken: token },
      )
      .catch((thrown) => {
        // if (axios.isCancel(thrown)) {
        // Request cancelled by user (ex: tab change employment form)
        // console.log('Request canceled', thrown.message)
        // }
        return thrown
      })
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useJamesQuery = <T = any>(
  queryKey: QueryKey | string | false | null | undefined,
  query: string,
  options?: QueryConfig<AxiosResponse<{ data: T }>>,
) => {
  const { refresh } = useSessionContext()
  const history = useHistory()

  const { data, status, error, isFetching, refetch } = useQuery(queryKey as QueryKey, fetchInfo<T>(query), {
    retry: 1,
    onError: (err) => {
      // @ts-ignore
      if (err?.code === 'common.error.be.notAuthenticated' && err?.message === 'not logged in') {
        refresh()
        history.go(0)
      }
    },
    ...options,
  })

  return { data: data?.data?.data, status, error, isFetching, refetch }
}

export default useJamesQuery
