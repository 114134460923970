import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  successContainer: {
    marginTop: theme.spacing(5),
    width: theme.spacing(15.7),
  },
  successTitle: {
    // @ts-ignore
    color: theme.palette.primary[900],
    marginBottom: theme.spacing(4.875),
    fontSize: '23px',
    fontWeight: 600,
    lineHeight: 1.26,
    letterSpacing: '0.2px',
  },
}
