import React from 'react'
import { Box, Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface ILoadMoreProps {
  onClick: () => void
  testId?: string
}

const LoadMoreButton: React.FC<ILoadMoreProps> = ({ onClick, testId }) => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.loadMore}>
      <Button variant="outlined" color="secondary" onClick={onClick} data-testid={testId}>
        {t('page.tasks.content.loadMore')}
      </Button>
    </Box>
  )
}

export default LoadMoreButton
