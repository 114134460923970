import { timelineItemClasses } from '@mui/lab/TimelineItem'
import theme from 'utils/theme'

export const timelineClasses = {
  root: {
    marginTop: 0,
    padding: '8px 12px',
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
  },
  content: {
    padding: '0 0 6px 8px',
    marginBottom: '28px',
    maxWidth: 'calc(100% - 16px)',
  },
  connector: {
    width: 0,
    backgroundColor: 'initial',
    border: `1px dashed ${theme.palette.grey[300]}`,
  },
  item: {
    minHeight: 0,
  },
  lastContent: {
    marginBottom: '32px',
  },
  noBottomSpacing: {
    marginBottom: '0px',
  },
}
