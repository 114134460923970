import { NestedValue } from 'react-hook-form'

export type AddressT = 'main' | 'foreign' | 'emergency'

export const addressTypes: Record<string, AddressT> = {
  main: 'main',
  foreign: 'foreign',
  emergency: 'emergency',
}

export type ContactFieldT = {
  firstName: string
  lastName: string
  phoneNumber: string
  address: string
  additionalAddress: string
  zipCode: string
  city: string
  country: string
  type: 'main' | 'foreign' | 'emergency'
}

export type ContactFormValuesT = {
  email: string
  phoneNumber: string
  addresses: NestedValue<ContactFieldT[]>
}

export type ContactDataResponseT = {
  email: string
  addresses: NestedValue<ContactFieldT[]>
}

export type ContactKeysT = {
  firstname: string
  lastname: string
  phonenumber: string
  address: string
  additionaladdress: string
  zipcode: string
  city: string
  country: string
  type: string
}

export type ContactFormValuesKeysT = keyof ContactFormValuesT

export type ContactFieldKeysT = keyof ContactFieldT
