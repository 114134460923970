import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import Block from 'components/Cms/Block'
import useFavurTranslation from 'hooks/useFavurTranslation'
import sortBy from 'lodash/fp/sortBy'
import { CmsContentBlocksT } from 'types'
import { styles } from './styles'

const AnnouncementContent: React.FC<{ blocks: CmsContentBlocksT[] }> = ({ blocks }) => {
  const { language } = useFavurTranslation()

  // TBD: remove filter when BE endpoint is ready. Maybe sort too if BE is in charge to order
  const orderedBlocks = useMemo(
    () =>
      blocks
        ? sortBy(
            'order',
            blocks.filter((block) => block.language === language),
          )
        : [],
    [blocks, language],
  )
  if (!orderedBlocks) return null

  return (
    <Box sx={styles.contentBlock}>
      {orderedBlocks.map((block) => (
        <Block block={block} key={block.order} />
      ))}
    </Box>
  )
}

export default AnnouncementContent
