import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const FingerPrintIosL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path d="M19.1912 2.44419c-2.111-.52655-4.3322-.60179-6.4936-.13519-.167.03606-.2732.2007-.2371.36774.036.16703.2007.27321.3677.23715 2.0699-.44685 4.2021-.37317 6.2303.13515 4.8501 1.21563 8.8873 4.86227 10.4249 9.62576.5947 1.8422.8473 3.8038.8979 5.8596.0042.1708.1461.3059.3169.3017.1708-.0042.3059-.1461.3017-.3169-.0513-2.084-.3068-4.0961-.9162-5.9991-1.5968-4.98701-5.8163-8.8097-10.8925-10.07591ZM11.1274 3.38033c.1648-.04544.2615-.21582.216-.38055-.0454-.16473-.2158-.26143-.3805-.21599-2.26039.62357-5.44005 2.77822-7.40511 5.65665-.00076.00112-.00152.00225-.00227.00338C1.83007 11.0441.748456 14.3118 1.05044 17.4826c.00029.003.00062.006.00099.009.07238.579.19387 1.1569.31551 1.7355.2562 1.2186.51303 2.4402.31285 3.6813-.02722.1687.08748.3275.25619.3547.1687.0272.32752-.0875.35473-.2562.20926-1.2972-.05057-2.5626-.31046-3.8282-.12019-.5853-.24039-1.1707-.3142-1.7593-.28403-2.9962.739-6.1219 2.40398-8.63173 1.8903-2.76796 4.9513-4.82632 7.05737-5.40734Z" />
      <path d="M15.1877 4.07163c3.8847-.23722 7.7686 1.34816 10.3769 4.38547.1113.12964.0965.32498-.0332.43631-.1296.11134-.3249.09649-.4363-.03315-2.4804-2.88835-6.1762-4.39708-9.8715-4.17084-.0014.00008-.0027.00015-.004.00022-4.6731.22612-9.04956 3.39631-10.65929 7.83856-.05822.1606-.23566.2437-.39632.1855-.16066-.0583-.2437-.2357-.18549-.3964 1.6958-4.67967 6.2913-8.00687 11.2092-8.24567ZM26.5072 9.80884c-.0952-.14186-.2875-.17963-.4293-.08436-.1419.09527-.1797.28752-.0844.42932 1.446 2.1531 2.0439 4.4961 2.2802 7.2765.0145.1702.1643.2965.3345.2821.1703-.0145.2966-.1643.2821-.3345-.2415-2.8412-.8581-5.2983-2.3831-7.56906ZM28.6835 18.6915c.1705-.0106.3174.119.3281.2895.1166 1.8658.1177 3.9767-.0887 5.5367-.0224.1694-.1779.2885-.3473.2661-.1695-.0224-.2886-.1779-.2662-.3473.1989-1.5035.2-3.5697.0846-5.4169-.0107-.1705.1189-.3174.2895-.3281ZM4.09306 14.0268c.04011-.1661-.06204-.3333-.22815-.3734-.16611-.0401-.33328.0621-.37339.2282-.2271.9406-.34499 1.9068-.34966 2.874-.00482.9968.18105 1.9699.367 2.9433.1521.7963.30426 1.5928.35212 2.4028.06287 1.0641-.16094 2.1297-.54769 3.1439-.06089.1596.01919.3384.17886.3993.15966.0609.33846-.0192.39935-.1788.4088-1.0721.6566-2.2268.58723-3.4009-.04754-.8044-.19968-1.5964-.35176-2.3879-.18558-.966-.37107-1.9314-.3663-2.9187.00444-.919.11648-1.8375.33239-2.7318Z" />
      <path d="M12.2119 7.15102c.0637.15857-.0132.33875-.1718.40243-3.20773 1.28819-5.61656 4.19515-6.10797 7.62315-.2053 1.4321.01651 2.8508.23825 4.269.12488.7987.24974 1.5973.29828 2.3981.10526 1.7364-.09252 3.6437-1.2226 5.3791-.09325.1432-.28493.1837-.42812.0904-.1432-.0932-.18369-.2849-.09044-.4281 1.02961-1.5811 1.22451-3.3373 1.12347-5.004-.04925-.8125-.17333-1.6203-.29733-2.4276-.22248-1.4485-.44471-2.8953-.23407-4.3647.52469-3.6602 3.09013-6.74428 6.48993-8.1096.1586-.06368.3388.01324.4024.17182ZM23.0741 8.9126c-2.5306-2.3702-6.1984-3.19744-9.5318-2.4663-.1669.03661-.2725.2016-.2359.36851.0366.16692.2016.27255.3685.23594 3.1565-.69233 6.6102.09744 8.9762 2.31351 1.9943 1.86784 3.1523 4.58574 3.4555 7.55104.0173.17.1692.2937.3392.2763.17-.0174.2937-.1693.2764-.3393-.3148-3.079-1.5217-5.9481-3.6481-7.9397ZM26.6574 18.3259c.17-.0175.322.1062.3394.2762.2939 2.8611.1672 5.9514-.2764 8.9409-.0251.169-.1824.2857-.3515.2606-.169-.0251-.2857-.1824-.2606-.3514.4374-2.948.5606-5.9858.2729-8.7869-.0174-.17.1062-.322.2762-.3394Z" />
      <path d="M15.8701 8.28824c-4.7413 0-8.59375 3.78266-8.59375 8.45946 0 .4995.04433.989.12884 1.465.02988.1682.19049.2804.35874.2505.16825-.0299.28043-.1905.25056-.3587-.07824-.4407-.11931-.894-.11931-1.3568 0-4.3255 3.56572-7.84064 7.97492-7.84064 1.9926 0 3.813.71816 5.2104 1.90484.1302.1106.3255.0947.4361-.0356.1106-.1302.0947-.3255-.0356-.4361-1.5057-1.27876-3.4672-2.05196-5.6109-2.05196ZM22.1743 11.4938c.1233-.1183.3192-.1141.4375.0092 1.3655 1.4239 1.8026 3.6619 2.0918 5.5401.3257 2.1156.3755 4.1795.3755 5.1364 0 .1709-.1385.3095-.3094.3095s-.3094-.1386-.3094-.3095c0-.9414-.0493-2.9704-.3683-5.0422-.2685-1.7437-.6556-3.8803-1.9269-5.206-.1183-.1233-.1142-.3192.0092-.4375ZM25.0005 23.9515c.0136-.1703-.1135-.3194-.2838-.333-.1704-.0136-.3195.1135-.333.2839-.152 1.9059-.3506 3.8097-.7534 5.5684-.0382.1665.0659.3325.2325.3706.1665.0382.3325-.0659.3707-.2325.4135-1.805.6147-3.7464.767-5.6574ZM7.90323 19.3515c.16767-.0331.33035.0761.36337.2438.65421 3.3222.20226 6.3402-1.5302 8.9838-.09367.1429-.28547.1828-.42839.0892-.14293-.0937-.18286-.2855-.0892-.4284 1.63007-2.4874 2.06877-5.3352 1.44062-8.5251-.03301-.1676.07614-.3303.2438-.3633Z" />
      <path d="M15.8701 10.3014c-3.0778 0-6.09709 2.3825-6.40527 5.3767-.13492 1.3109.05461 2.6021.24422 3.8939.16556 1.1279.33115 2.2563.28089 3.3984-.11508 2.614-.88204 4.7717-2.19988 6.5489-.10179.1373-.07303.3311.06423.4328.13727.1018.33105.0731.43284-.0642 1.39773-1.8849 2.20097-4.1641 2.32107-6.8903.05-1.137-.1161-2.2617-.2821-3.3857-.1899-1.2854-.37956-2.5698-.2457-3.8704.2707-2.6304 2.9782-4.8212 5.7897-4.8212 1.3114 0 4.7042.71 5.7835 4.2258.6763 2.2032.8608 5.185.697 8.0817-.1638 2.8964-.6734 5.6667-1.3585 7.4431-.0615.1594.0179.3385.1773.4.1594.0615.3385-.0179.4-.1773.7169-1.8585 1.2332-4.6989 1.399-7.6308.1658-2.9316-.0164-5.9958-.7232-8.2983-1.198-3.9027-4.9535-4.6631-6.3751-4.6631Z" />
      <path d="M11.1085 28.5796c.1431.0933.1835.285.0901.4282-.1918.2941-.3606.6019-.5294.9097-.1688.3078-.3376.6157-.5295.9099-.0934.1431-.28508.1835-.42821.0901-.14313-.0933-.18348-.285-.09013-.4282.19187-.2941.36066-.6019.52944-.9097.1688-.3078.3376-.6157.5295-.9099.0934-.1431.2851-.1835.4282-.0901ZM13.7579 13.5133c.1421-.095.1802-.2872.0852-.4292-.095-.1421-.2872-.1802-.4292-.0852-1.2253.8195-1.977 1.9464-1.977 3.5973 0 .983.1742 1.7865.3528 2.6106.2673 1.2333.4486 2.4639.416 3.7306-.0396 1.5382-.3405 2.9437-.919 4.2827-.0678.1569.0044.339.1613.4068.1568.0678.3389-.0045.4067-.1613.6117-1.4158.9281-2.8998.9696-4.5123.0339-1.3163-.1524-2.5956-.4297-3.8773-.179-.8277-.3388-1.5667-.3388-2.4798 0-1.4145.6222-2.3606 1.7021-3.0829ZM14.9656 12.4261c1.2839-.2617 2.6656.0617 3.6825.8849 1.8166 1.4702 2.0582 4.1516 2.2539 6.3243l.01.1107c.253 2.8008-.0366 5.5212-.6199 8.2532-.0357.1671-.2001.2737-.3672.238-.1671-.0357-.2737-.2001-.238-.3672.5746-2.691.8546-5.3467.6088-8.0683-.0081-.0901-.0162-.1815-.0244-.274l-.0001-.0013c-.1739-1.9635-.3917-4.4227-2.0124-5.7344-.8694-.7038-2.0619-.9853-3.1696-.7595-.1675.0341-.3309-.0739-.365-.2414-.0341-.1674.0739-.3308.2414-.365ZM19.8548 29.5388c.0421-.1656-.0579-.334-.2235-.3762-.1656-.0421-.3341.058-.3762.2236-.1871.7347-.4753 1.3979-.8408 2.0085-.0877.1466-.04.3366.1066.4244.1466.0877.3366.04.4244-.1066.3939-.658.7065-1.3765.9095-2.1737ZM18.3417 25.0487c.1697.0201.291.1739.2709.3436-.2922 2.4695-.9519 4.6719-2.0105 6.5821-.0828.1494-.2712.2035-.4206.1206-.1495-.0828-.2035-.2711-.1207-.4206 1.0131-1.8281 1.6528-3.9505 1.9373-6.3548.0201-.1697.1739-.291.3436-.2709Z" />
      <path d="M18.278 16.4339c-.2146-1.1462-1.1983-2.0229-2.3926-2.0229-1.6171 0-2.7518 1.6301-2.3519 3.1467.6765 2.677.7876 5.2571.3296 7.7421l-.0004.0018c-.3974 2.2312-1.1606 4.2383-2.2017 5.9391-.0892.1458-.0434.3363.1024.4255.1457.0892.3362.0434.4254-.1024 1.0837-1.7704 1.8728-3.8507 2.283-6.1527.4755-2.5809.3577-5.2515-.3388-8.0067l-.0008-.0032c-.2999-1.1345.536-2.3714 1.7532-2.3714.8785 0 1.6201.6462 1.7839 1.5159.4504 2.6123.6849 5.0348.5179 7.1205-.0136.1704.1134.3195.2837.3332.1704.0136.3195-.1134.3332-.2838.1731-2.1619-.0713-4.644-.5254-7.2773-.0002-.0015-.0005-.0029-.0007-.0044ZM14.9622 29.8348c.152.0781.2119.2646.1338.4166l-.8199 1.5955c-.0781.152-.2646.2119-.4166.1338-.152-.0781-.2119-.2646-.1338-.4166l.8199-1.5955c.0781-.152.2646-.2119.4166-.1338Z" />
      <path d="M16.1156 16.7323c-.0391-.1664-.2057-.2696-.372-.2305-.1664.0391-.2695.2056-.2305.372 1.0435 4.4416.9002 8.3418-.3708 11.6642-.061.1596.0188.3385.1785.3996.1596.061.3384-.0189.3995-.1785 1.3252-3.4642 1.4599-7.495.3953-12.0268Z" />
    </Svg>
  )
}

export default FingerPrintIosL
