import React from 'react'
import Svg from 'components/Svg'
import palette from 'utils/theme/palette'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const NoEventsXL: React.FC<IconPropsT> = ({ width = ICON_SIZE_XL, height = ICON_SIZE_XL }) => {
  return (
    <Svg size={ICON_SIZE_XL} width={width} height={height}>
      <circle cx="38" cy="37.273" r="37.273" fill={palette.primary[20]} />
      <path
        fill="#DADEF2"
        d="M59.432 27.023v27.954a1.864 1.864 0 0 1-1.864 1.864H18.432a1.864 1.864 0 0 1-1.864-1.864V27.023h42.864Z"
      />
      <path fill="#fff" d="M16.568 27.023v27.954a1.83 1.83 0 0 0 .73 1.44l29.396-29.394H16.568Z" />
      <path
        fill="#607BFF"
        d="M16.568 27.023v-7.455a1.864 1.864 0 0 1 1.864-1.863h39.136a1.864 1.864 0 0 1 1.864 1.863v7.455H16.568Z"
      />
      <path
        fill="#141A33"
        fillRule="evenodd"
        d="M16.068 27.023c0-.258.209-.466.466-.466h42.864c.257 0 .466.209.466.466v27.954a2.33 2.33 0 0 1-2.33 2.33H18.398a2.33 2.33 0 0 1-2.33-2.33V27.023Zm.932.466v27.488a1.398 1.398 0 0 0 1.398 1.398h39.136a1.398 1.398 0 0 0 1.398-1.398V27.49H17Z"
        clipRule="evenodd"
      />
      <path
        fill="#141A33"
        fillRule="evenodd"
        d="M51.512 17.704c0-.257.208-.466.466-.466h5.59a2.33 2.33 0 0 1 2.33 2.33v7.455a.466.466 0 0 1-.932 0v-7.455a1.398 1.398 0 0 0-1.398-1.398h-5.59a.466.466 0 0 1-.466-.466ZM18.432 18.17a1.398 1.398 0 0 0-1.398 1.398v7.455a.466.466 0 0 1-.931 0v-7.455a2.33 2.33 0 0 1 2.33-2.33h5.59a.466.466 0 0 1 0 .932h-5.59Z"
        clipRule="evenodd"
      />
      <path fill="#fff" d="M27.75 15.84a1.864 1.864 0 1 0-3.727 0v3.728a1.864 1.864 0 0 0 3.728 0v-3.727Z" />
      <path
        fill="#141A33"
        fillRule="evenodd"
        d="M24.239 14.194a2.33 2.33 0 0 1 3.977 1.647v3.727a2.33 2.33 0 1 1-4.66 0v-3.727c0-.618.246-1.21.683-1.647Zm1.647.25a1.398 1.398 0 0 0-1.398 1.397v3.727a1.398 1.398 0 1 0 2.796 0v-3.727a1.397 1.397 0 0 0-1.398-1.398Z"
        clipRule="evenodd"
      />
      <path fill="#fff" d="M51.977 15.84a1.864 1.864 0 0 0-3.727 0v3.728a1.864 1.864 0 1 0 3.727 0v-3.727Z" />
      <path
        fill="#141A33"
        fillRule="evenodd"
        d="M48.467 14.194a2.33 2.33 0 0 1 3.976 1.647v3.727a2.33 2.33 0 0 1-4.659 0v-3.727c0-.618.246-1.21.683-1.647Zm1.647.25a1.398 1.398 0 0 0-1.398 1.397v3.727a1.398 1.398 0 0 0 2.795 0v-3.727a1.398 1.398 0 0 0-1.397-1.398Z"
        clipRule="evenodd"
      />
      <path
        fill="#141A33"
        fillRule="evenodd"
        d="M27.284 17.704c0-.257.209-.466.466-.466h20.5a.466.466 0 0 1 0 .932h-20.5a.466.466 0 0 1-.466-.466Z"
        clipRule="evenodd"
      />
      <path
        stroke="#141A33"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M28.683 36a.529.529 0 1 1 0 1.057.529.529 0 0 1 0-1.057ZM47.636 36a.528.528 0 1 1 0 1.057.528.528 0 0 1 0-1.057ZM30.068 46.56a9.504 9.504 0 0 1 15.81 0"
      />
    </Svg>
  )
}

export default NoEventsXL
