import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { getBadgeGlossaryKey } from 'components/Notification/utils'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { SecureConnectionCommentForManagerNotificationT, NotificationT } from 'types'

interface ISecureConnectionCommentManagerProps {
  notification: SecureConnectionCommentForManagerNotificationT
  dismiss: (notification: NotificationT) => void
}

const SecureConnectionCommentManager: React.FC<ISecureConnectionCommentManagerProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()

  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(routes.secureConnectionWithTask(notification.data.taskUuid), ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.secureconnection.manager.comment.title', {
      frontlinerNameShort: notification.data.frontlinerNameShort,
    }),
    body: `${t('notification.comment.body')} ${
      notification.data.commentShort ?? t(getBadgeGlossaryKey(notification.data.badge))
    }`,
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }
  return <NotificationTemplate {...templateProps} />
}

export default SecureConnectionCommentManager
