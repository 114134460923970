import React, { RefObject, useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { absenceRequest, cmsEvent } from 'constants/shift'
import { isFirstDayOfMonth, isToday } from 'date-fns'
import { isMonday } from 'date-fns'
import { orderBy } from 'lodash/fp'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { AbsencePlannerBadgeT, AbsencePlannerGridBadgesT } from 'pages/AbsencePlanner/types'
import { PersonT } from 'types'
import { getDateFromISOString } from 'utils/date'
import { colorModes } from '../../TaskBar/types'
import VirtualizedContainer from '../Body/VirtualizedContainer'
import AbsencePlannerGridCell from '../Cell'
import { CELL_WIDTH } from '../constants'
import { gridClasses } from '../styles'

const getCellId = (day: string, teamId: string, personId: string) => `${personId}_${day}_${teamId}`

const sortBadges = (badges: AbsencePlannerBadgeT[]) => {
  if (!badges?.length) return []

  const divided = badges.reduce((acc, badge) => {
    if (badge.type === absenceRequest) {
      const requests = acc.get('absenceRequests') ?? []

      return acc.set('absenceRequests', [...requests, badge])
    }

    if (badge.type === cmsEvent) {
      return acc.set('cmsEvents', [badge])
    }

    const shifts = acc.get('shifts') ?? []

    return acc.set('shifts', [...shifts, badge])
  }, new Map<string, AbsencePlannerBadgeT[]>())

  const requests = divided.get('absenceRequests') ?? []
  const shifts = divided.get('shifts') ?? []
  const cmsEvents = divided.get('cmsEvents') ?? []

  return [...requests, ...orderBy(['shiftUpdateUuid'], 'asc', shifts), ...cmsEvents]
}

interface IAbsencePlannerGridRowProps {
  chunkedShownDays: string[][]
  person: PersonT
  gridBadges?: AbsencePlannerGridBadgesT | null
  teamId?: string
  isLastRow?: boolean
  rowIndex?: number
  userRow?: boolean
  userIsAbsenceManager?: boolean
  isPreview?: boolean
  scrollContainerRef: RefObject<HTMLDivElement>
  isAlone?: boolean
}

const AbsencePlannerGridRow: React.FC<IAbsencePlannerGridRowProps> = ({
  chunkedShownDays,
  person,
  gridBadges,
  teamId,
  isLastRow,
  userRow = false,
  userIsAbsenceManager = false,
  isPreview,
  scrollContainerRef,
  isAlone,
}) => {
  const { shownTypes, greyscaleMode } = useFilterContext()
  const { selectedPerson, selectedDays, selectedTaskUuid } = useSelectedStatesContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const selected = useMemo(() => person && person.id === selectedPerson?.id, [person, selectedPerson])
  const isGreyscale = greyscaleMode === colorModes.greyscale
  const screenWidth = useMemo(() => window.innerWidth, [])
  const screenHeight = useMemo(() => window.innerHeight, [])

  const filterBadges = useCallback(
    (day: string) => {
      if (!gridBadges) {
        return []
      }

      const filteredBadges = (gridBadges.get(day) ?? []).filter((badge) => {
        if (!userIsAbsenceManager && badge.type === absenceRequest) return false
        return shownTypes.includes(badge.type)
      })

      return sortBadges(filteredBadges)
    },
    [gridBadges, shownTypes, userIsAbsenceManager],
  )
  return (
    <Box sx={[gridClasses.gridRow, ...(isAlone ? [gridClasses.aloneRow] : [])]}>
      {chunkedShownDays.map((chunk) => {
        return (
          <VirtualizedContainer
            placeholderWidth={`${chunk.length * CELL_WIDTH}px`}
            horizontalPreloadMargin={screenWidth}
            verticalPreloadMargin={screenHeight}
            rootElement={scrollContainerRef.current}
            key={`${teamId ?? ''}_${person?.id}_day-chunk-${chunk[0]}`}
          >
            {(chunk ?? []).map((day) => {
              const selectedColumn = selectedDays.some((d) => {
                return d.getTime() === getDateFromISOString(day).getTime()
              })
              return (
                <AbsencePlannerGridCell
                  key={`${teamId ?? ''}_${person?.id}_${day}`}
                  badges={filterBadges(day)}
                  selectedRow={selected}
                  selectedColumn={selectedColumn}
                  isLastRow={isLastRow}
                  userRow={userRow}
                  person={person}
                  shownTypes={shownTypes}
                  setTaskBarState={setTaskBarState}
                  id={getCellId(day, teamId ?? '', `${person?.id}`)}
                  isTaskSelected={selected && selectedColumn && Boolean(selectedTaskUuid)}
                  isGreyscale={isGreyscale}
                  isToday={isToday(Date.parse(day))}
                  isFirstDayOfMonth={isFirstDayOfMonth(Date.parse(day))}
                  isMonday={isMonday(Date.parse(day))}
                  userIsAbsenceManager={userIsAbsenceManager}
                  isPreview={isPreview}
                />
              )
            })}
          </VirtualizedContainer>
        )
      })}
    </Box>
  )
}

export default AbsencePlannerGridRow
