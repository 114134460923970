export const classes = {
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardContainer: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  textContainer: {
    flexGrow: 1,
  },
}
