import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    // @ts-ignore
    background: theme.palette.primary[25],
  },
  containerSmall: {
    display: 'flex',
    padding: '16px',
    // justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '8px',
    // @ts-ignore
    background: theme.palette.primary[25],
  },
  header: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
  },
  headerSmall: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    position: 'relative',
    top: '4px',
  },
  conflictTextRow: {
    display: 'flex',
    gap: '4px',
  },
  vacationsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatarGroup: (size: number) => {
    const fontSize = Math.floor(size * 0.15 + 8)
    return {
      paddingBottom: '8px',
      justifyContent: 'start',
      '& .MuiAvatarGroup-avatar': {
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}px`,
        lineHeight: '22px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
        backgroundColor: 'white',
      },
    }
  },
  divider: {
    height: '1px',
    background: '#D9D9D9',
    flexShrink: 0,
    // margin: '10px 0 10px 0',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonDesktop: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
  },
  pinPointButton: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    padding: '4px',
    borderRadius: '4px',
    '&:hover, &:focus, &:hover:before': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
    },
  },
  containerCard: {
    padding: '0 12px',
    marginBottom: '24px',
  },
  captionText: {
    lineHeight: '18px',
  },
}
