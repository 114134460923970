import React, { useCallback, useMemo } from 'react'
import { Box, Chip } from '@mui/material'
import { isToday } from 'date-fns'
import useSideMenuContext from 'pages/Shifts/components/SideMenu/context'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import { hasAcknowledgedShiftUpdate } from '../utils'
import WeekShiftChip from './WeekShiftChip'
import { classes } from './styles'

interface IDayNumberContentProps {
  day: Date
  isCurrentDay: boolean
  onClickDay: (day: Date) => void
  shifts: PlannedShiftT[]
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
  loadingShifts: boolean
}

const getDayClasses = (isCurrentDay: boolean, day: Date) => [
  classes.weekDay,
  ...(isCurrentDay ? [classes.weekDayAnimation] : []),
  ...(isCurrentDay && isToday(day) ? [classes.weekDaySelectedToday] : []),
  ...(isCurrentDay && !isToday(day) ? [classes.weekDaySelectedNotToday] : []),
  ...(!isCurrentDay && isToday(day) ? [classes.weekDayNotSelectedToday] : []),
  ...(!isCurrentDay && !isToday(day) ? [classes.weekDayNotSelectedNotToday] : []),
]

const DayNumberContent: React.FC<IDayNumberContentProps> = ({
  day,
  isCurrentDay,
  onClickDay,
  shifts,
  shiftUpdates,
  loadingShifts,
}) => {
  const { showTopCalendarShifts } = useSideMenuContext()

  const showShiftBars = useMemo(() => !isCurrentDay && shifts.length > 0 && showTopCalendarShifts && !loadingShifts, [
    isCurrentDay,
    loadingShifts,
    shifts.length,
    showTopCalendarShifts,
  ])

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  return (
    <Box sx={[classes.dayOfWeekContainer, classes.dayNumberContainer]}>
      <Chip
        sx={getDayClasses(isCurrentDay, day)}
        label={day.getDate()}
        onClick={() => {
          if (!isCurrentDay) {
            onClickDay(day)
          }
        }}
      />
      {showShiftBars && (
        <Box sx={classes.bottomChipsContainer}>
          {shifts.map((shift) =>
            hasAcknowledgedTask(shift) ? <WeekShiftChip shift={shift} key={getShiftKey(shift)} /> : null,
          )}
        </Box>
      )}
    </Box>
  )
}

export default DayNumberContent
