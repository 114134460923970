import React from 'react'
import { IconButton } from '@mui/material'
import { FaceIdAndroidL, FaceIdIosL } from 'icons'
import { isAndroidNative } from 'utils/platform'
import { classes } from '../../styles'

export interface INumpadInputFaceId {
  onClick: () => void
}

const NumpadInputFaceId: React.FC<INumpadInputFaceId> = ({ onClick }) => {
  return (
    <IconButton
      aria-label="Numpad FaceId"
      sx={classes.iconButton}
      onClick={onClick}
      data-testid="numpad-faceid"
      size="large"
    >
      {isAndroidNative() ? <FaceIdAndroidL /> : <FaceIdIosL />}
    </IconButton>
  )
}

export default NumpadInputFaceId
