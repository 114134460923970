import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NoReminderM } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

const NoReminderBanner: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.noReminderBox}>
      <NoReminderM stroke={palette.grey[600]} />
      <Typography variant="body2">{t('page.userSettings.tenantReminderList.noReminder')}</Typography>
    </Box>
  )
}

export default NoReminderBanner
