import { useContext } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import FlashMessagesContext from 'services/FlashMessages/context'
import { CmsContentStatsT } from 'types/cms'
import { ApolloError } from '@apollo/client'
import { getCmsContentStatsQuery } from './queries'

const useGetCmsContent = () => {
  const { setFlashMessage } = useContext(FlashMessagesContext)

  const onError = (error: ApolloError) => {
    setFlashMessage(error.message)
  }

  const { data: data, loading: loading } = useJamesApolloQuery<{ cmsContentStats: CmsContentStatsT[] }>(
    getCmsContentStatsQuery,
    {
      fetchPolicy: 'cache-and-network',
      onError: onError,
    },
  )

  return {
    stats: data?.cmsContentStats ? data.cmsContentStats : [],
    loading,
  }
}

export default useGetCmsContent
