import theme from 'utils/theme'

export const classes = {
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  explanation: {
    color: theme.palette.grey[600],
  },
}
