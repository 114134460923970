import { ENGLISH, FRENCH, GERMAN, ITALIAN, defaultPhoneNumberCountryCode } from 'constants/i18n'
import { AddressT } from './types'

export const genders = {
  male: 'personalData.gender.male',
  female: 'personalData.gender.female',
}

export const residenceCategoryTypes = {
  'shortTerm-L': 'L',
  'annual-B': 'B',
  'settled-C': 'C',
  'crossBorder-G': 'G',
  'asylumSeeker-N': 'N',
  'needForProtection-S': 'S',
  othersNotSwiss: 'personalData.residenceCategoryTypes.other',
}

export const contactLanguages = {
  de: GERMAN.label,
  en: ENGLISH.label,
  fr: FRENCH.label,
  it: ITALIAN.label,
}

export const addressInputKeys = {
  firstname: 'firstName',
  lastname: 'lastName',
  phonenumber: 'phoneNumber',
  address: 'address',
  additionaladdress: 'additionalAddress',
  zipcode: 'zipCode',
  city: 'city',
  country: 'country',
  type: 'type',
}

export const defaultAddressValues = (addressType: AddressT) => {
  return {
    firstName: '',
    lastName: '',
    address: '',
    additionalAddress: '',
    zipCode: '',
    country: defaultPhoneNumberCountryCode,
    phoneNumber: '',
    city: '',
    type: addressType,
  }
}
