import React, { useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import SectionTitle from 'components/SectionTitle'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AddS } from 'icons'
import { Trans } from 'react-i18next'
import { PersonT, TeamPermissionT, TeamT, WorkflowsT } from 'types'
import { getUserName } from 'utils/person'
import theme from 'utils/theme'
import TeamData from './TeamData'
import TenantData from './TenantData'
import { classes } from './styles'

const getActivePermissionNames = (tp: TeamPermissionT) => {
  return Object.values(WorkflowsT)
    .map((workflowName) => (tp[workflowName] ? workflowName : ''))
    .filter((workflowName) => workflowName !== '')
}

const getTeamPermissionDataByTeamId = (teamPermissions?: TeamPermissionT[]) => {
  const permissionsByTeam = new Map<
    string,
    {
      team: TeamT
      activePermissionNames: string[]
    }
  >()
  if (!teamPermissions) {
    return permissionsByTeam
  }

  return Array.from(teamPermissions).reduce((teamData, tp) => {
    return teamData.set(tp.teamId as string, {
      team: tp.team as TeamT,
      activePermissionNames: getActivePermissionNames(tp),
    })
  }, permissionsByTeam)
}

interface IDetailDataContentProps {
  onRemoveFromTeam: () => void
  person: PersonT
  openAddToTeams: () => void
}

const DetailDataContent: React.FC<IDetailDataContentProps> = ({ onRemoveFromTeam, person, openAddToTeams }) => {
  const { t } = useFavurTranslation()
  const permissionsByTeam = useMemo(() => getTeamPermissionDataByTeamId(person.teamPermissions), [person])
  const teamsAndPermissions = Array.from(permissionsByTeam.values())

  return (
    <>
      <SectionTitle textColor={theme.palette.primary.dark} gutter={0.25} marginLeft={1.0}>
        {t('component.employeeDetail.tenantHeader')}
      </SectionTitle>

      <TenantData person={person} />

      <SectionTitle textColor={theme.palette.primary.dark} gutter={0.25} marginLeft={1.0}>
        {t('component.employeeDetail.teamsHeader')}
      </SectionTitle>

      {teamsAndPermissions.length === 0 ? (
        <Card marginBottom={1.125}>
          <Box sx={classes.cardInsides}>
            <Typography variant="body2" data-testid="no-team" sx={classes.userNoTeamText}>
              <Trans
                i18nKey="component.employeeDetail.noTeam"
                components={{ b: <b /> }}
                values={{ name: getUserName(person) }}
              />
            </Typography>
            <DivisionLine />
            <Button
              variant="text"
              color="primary"
              sx={[classes.addToTeamButton, classes.addToTeamButtonMargin]}
              onClick={openAddToTeams}
            >
              <AddS />
              <Box component="span" sx={classes.addText}>
                {t('component.employeeDetail.addToTeams')}
              </Box>
            </Button>
          </Box>
        </Card>
      ) : (
        <>
          {teamsAndPermissions.map((teamAndPermissions) => (
            <TeamData
              key={teamAndPermissions.team.id}
              person={person}
              teamAndPermissions={teamAndPermissions}
              onRemoveFromTeam={onRemoveFromTeam}
            />
          ))}

          <Card marginBottom={1.125}>
            <Box sx={classes.cardInsides}>
              <Button variant="text" color="primary" sx={classes.addToTeamButton} onClick={openAddToTeams}>
                <AddS />
                <Box component="span" sx={classes.addText}>
                  {t('component.employeeDetail.addToMoreTeams')}
                </Box>
              </Button>
            </Box>
          </Card>
        </>
      )}
    </>
  )
}

export default DetailDataContent
