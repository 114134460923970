import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const IdCardM: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        stroke={stroke || theme.palette.grey[500]}
        d="M10.007 6H4.329c-.353 0-.69.144-.94.4A1.38 1.38 0 0 0 3 7.362v11.274c0 .362.14.708.39.964.248.255.586.399.939.399H19.67c.353 0 .69-.144.94-.4.249-.255.389-.601.389-.963V7.363c0-.362-.14-.708-.39-.964-.248-.255-.586-.399-.939-.399h-5.678"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        stroke={stroke || theme.palette.grey[500]}
        d="M14 5.5c0-.398-.21-.78-.586-1.06C13.04 4.157 12.53 4 12 4c-.53 0-1.04.158-1.414.44-.375.28-.586.662-.586 1.06v2c0 .133.07.26.195.354a.793.793 0 0 0 .472.146h2.666a.793.793 0 0 0 .472-.146A.447.447 0 0 0 14 7.5v-2ZM6.156 12.719a2.406 2.406 0 1 0 4.812 0 2.406 2.406 0 0 0-4.812 0ZM4.438 19.938a4.125 4.125 0 0 1 8.25 0h-8.25ZM14 12h3M14 15h4"
      />
    </Svg>
  )
}

export default IdCardM
