import { createContext, useContext, Context } from 'react'

const initialState: AbsenceRequestContextT = {
  showAbsencePlannerDrawer: false,
  setShowAbsencePlannerDrawer: (_show: boolean) => {},
}

export const AbsenceRequestContext: Context<AbsenceRequestContextT> = createContext(initialState)

export const useAbsenceRequestContext = () => {
  return useContext(AbsenceRequestContext)
}

export type AbsenceRequestContextT = {
  showAbsencePlannerDrawer: boolean
  setShowAbsencePlannerDrawer: (show: boolean) => void
}

export default useAbsenceRequestContext
