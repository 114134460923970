import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { IdCardM } from 'icons'
import { INITIAL_PERSON_PARAM_NAME } from 'pages/EmployeeCard'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import palette from 'utils/theme/palette'
import DashboardCardToggle from './DashboardCardToggle'

interface IBasicInfoProps {
  person: PersonT
}

const EmployeeCardSection: React.FC<IBasicInfoProps> = ({ person }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <UserSettingsSection title={t('page.userSettings.employer.employeeCard')}>
      <UserSettingsSectionCTA
        title={t('page.userSettings.employers.employeeCard.entrypoint')}
        onClick={() => {
          history.push(`${routes.employeeCard}?${INITIAL_PERSON_PARAM_NAME}=${person.favurUuid}`)
        }}
        icon={<IdCardM stroke={palette.primary.main} />}
      />
      <DashboardCardToggle personUuid={person.favurUuid} />
    </UserSettingsSection>
  )
}

export default EmployeeCardSection
