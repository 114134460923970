// this methods are copied from the documentation on https://github.com/ValentinH/react-easy-crop
import axios from 'axios'
import { getAPIURL } from 'constants/environment'
import { AvatarIndexT, avatarSizes } from 'pages/UserSettings/pages/Profile/components/CropImage/types'
import { Area } from 'react-easy-crop'
import { AvatarDataT, History, PersonT } from 'types'
import { getAvatarName, getUserName } from './person'

export type ImageSizeT = {
  width: number
  height: number
}

const createImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = src
  })

const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180
}

const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation)

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return canvas.toDataURL('image/jpeg')
}

export const resizeImage = async (imageSrc: string, width: number, height: number) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  canvas.width = width
  canvas.height = height

  ctx.drawImage(image, 0, 0, width, height)

  return canvas.toDataURL('image/jpeg')
}

export const base64ToBlob = (src: string, contentType = 'image/jpeg') => {
  const byteCharacters = Array.from(atob(src.split(',')[1]))
  const byteArrays = [new Uint8Array(byteCharacters.map((char) => char.charCodeAt(0)))]

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const getFullAvatarUrl = (avatarUrl: string, size: AvatarIndexT = 'avatar128') =>
  `${getAPIURL()}/avatar-proxy/${avatarUrl}?suffix=${size}`

export const getImageSize = (imageSrc: string): Promise<ImageSizeT> =>
  createImage(imageSrc).then((image) => ({ width: image.width, height: image.height }))

export const getAvatarDataFromPerson = (person: PersonT, size?: AvatarIndexT) => {
  return {
    avatarName: getAvatarName(person?.firstName as string, person?.lastName as string),
    avatarUrl: person?.user?.avatarUrl ? getFullAvatarUrl(person?.user?.avatarUrl, size) : undefined,
    avatarUserName: getUserName(person as PersonT, true),
  } as AvatarDataT
}

export const getAvatarDataFromHistory = (history: History, size: AvatarIndexT = 'avatar128') => {
  return {
    avatarName:
      history.userFirstName && history.userLastName && getAvatarName(history.userFirstName, history.userLastName),
    avatarUrl: history.avatarImageUuid ? getFullAvatarUrl(history.avatarImageUuid, size) : undefined,
    avatarUserName: history.userFirstName + ' ' + history.userLastName,
  } as AvatarDataT
}

export const avatarFields = Object.values(avatarSizes)

const avatarSizesInt = [32, 48, 64, 96, 128, 252, 504, 1008]

const resizeToBlob = (src: string, size: number) =>
  resizeImage(src as string, size, size).then((resizedImage) => base64ToBlob(resizedImage as string))

export const getAllImageSizes = (src: string) =>
  Promise.all(Object.values(avatarSizesInt).map((size) => resizeToBlob(src, size))).then((blobs) => ({
    avatar32: blobs[0],
    avatar48: blobs[1],
    avatar64: blobs[2],
    avatar96: blobs[3],
    avatar128: blobs[4],
    avatar252: blobs[5],
    avatar504: blobs[6],
    profile: blobs[7],
  }))

export const uploadImage = (image: Blob, link: string) => {
  const source = axios.CancelToken.source()
  const cancelToken = source.token
  return axios.put(`${link}`, image, {
    headers: {
      'Content-Type': image.type,
    },
    cancelToken,
  })
}
