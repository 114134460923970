import React, { useMemo, useState } from 'react'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { absenceRequestTabStates } from '../types'
import AbsenceRequestDetail from './AbsenceRequestDetail'
import AbsenceRequestList from './AbsenceRequestList'
import NewAbsenceRequest from './NewAbsenceRequest'

interface IAbsenceRequestTabProps {
  tasks: TaskT<AbsenceRequestTaskT>[]
  title: string
  subtitle: string
  office?: boolean
  loading?: boolean
  loadMore?: () => void
  hasMoreElements?: boolean
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
  refetchAll: () => void
  reloadTaskList: () => void
  hideTitle?: boolean
}

const AbsenceRequestTab: React.FC<IAbsenceRequestTabProps> = (props) => {
  const { selectedTaskUuid } = useSelectedStatesContext()
  const [creatingAbsenceRequest, setCreatingAbsenceRequest] = useState(false)

  const tabState = useMemo(() => {
    if (selectedTaskUuid) return absenceRequestTabStates.detail

    if (creatingAbsenceRequest) return absenceRequestTabStates.new
    return absenceRequestTabStates.list
  }, [creatingAbsenceRequest, selectedTaskUuid])

  return (
    <>
      {tabState === absenceRequestTabStates.detail && <AbsenceRequestDetail {...props} />}
      {tabState === absenceRequestTabStates.new && (
        <NewAbsenceRequest {...props} goBack={() => setCreatingAbsenceRequest(false)} />
      )}
      {tabState === absenceRequestTabStates.list && (
        <AbsenceRequestList {...props} onNew={() => setCreatingAbsenceRequest(true)} />
      )}
    </>
  )
}

export default AbsenceRequestTab
