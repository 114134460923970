import React from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import Switch from 'components/Switch'
import useFavurTranslation from 'hooks/useFavurTranslation'
import type { SettingsPushNotificationsOptionsT } from 'types'
import { SwitchStatesT } from '../../types'
import { classes } from './styles'

interface INotificationSwitchProps {
  handlePushChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setting: SettingsPushNotificationsOptionsT
  pushSwitchStates: SwitchStatesT
  changeInApp?: boolean
  handleInAppChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  inAppSwitchStates?: SwitchStatesT
}

const NotificationSwitch: React.FC<INotificationSwitchProps> = ({
  handlePushChange,
  setting,
  pushSwitchStates,
  handleInAppChange,
  inAppSwitchStates,
  changeInApp = false,
}) => {
  const { t } = useFavurTranslation()
  const pushChecked = Boolean(pushSwitchStates[setting])
  const hasInApp = handleInAppChange !== undefined && inAppSwitchStates !== undefined && changeInApp

  return (
    <>
      <Box key={setting} sx={classes.settingContainer}>
        <Typography
          variant="body1"
          sx={[classes.settingTitle, ...(hasInApp && !pushChecked ? [classes.bolderBody] : [])]}
          data-testid="notification-switch_heading"
        >
          {t(`page.notificationsSettings.notification.${setting}`)}
        </Typography>
        <Switch
          checked={pushChecked}
          name={setting}
          onChange={handlePushChange}
          data-testid="notification-switch_push_checkbox"
        />
      </Box>
      {handleInAppChange && inAppSwitchStates && changeInApp && (
        <Collapse in={!pushChecked}>
          <Box sx={[classes.settingContainer, classes.inAppContainer]}>
            <Typography variant="body1" sx={classes.settingTitle} data-testid="notification-switch_heading">
              {t(`page.notificationsSettings.notification.inApp`)}
            </Typography>
            <Switch checked={Boolean(inAppSwitchStates[setting])} name={setting} onChange={handleInAppChange} />
          </Box>
        </Collapse>
      )}
    </>
  )
}

export default NotificationSwitch
