import React from 'react'
import { Box, Typography } from '@mui/material'
import { RequestedChange } from 'components/TaskBadge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sendCommentBodyClasses } from '../styles'

const RequestChangeHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={sendCommentBodyClasses.body}>
      <RequestedChange isActing />
      <Typography variant="body2" sx={sendCommentBodyClasses.textBody}>
        {t('workTimeControl.requestChange.body')}
      </Typography>
    </Box>
  )
}

export default RequestChangeHeader
