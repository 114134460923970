import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import { NumpadWithoutState } from 'components/Numpad'
import Snackbar from 'components/Snackbar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import { getFirstErrorCode } from 'utils/apollo'
import { isNativeNoOverride } from 'utils/platform'
import { MutationResult, useMutation } from '@apollo/client'
import { sendNewSimQuery } from '../../queries'
import { classes as forgotPhoneClasses } from '../../styles'

interface INewSimProps {
  goToStep: (stepName: string) => void
}

const NewSim: React.FC<INewSimProps> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const { updatePhoneNumber, phone, setPhone, getFullPhoneNumber } = usePhoneNumber('')
  const [error, setError] = useState(false)
  const getHelperText = () => {
    return error ? t('login.start.error.phoneInvalid') : ''
  }
  const [sendNewSimMutation] = useMutation<MutationResult>(sendNewSimQuery)
  const [serverError, setServerError] = useState(false)
  const [snackbarText, setSnackbarText] = useState({
    title: t('common.error.be.default'),
    message: t('forgotPhone.errors.generic'),
  })

  const onSubmit = useCallback(() => {
    if (!phone.isValidNumber) {
      setError(true)
      return
    }
    sendNewSimMutation({ variables: { newPhone: getFullPhoneNumber(false) } })
      .then(() => goToStep('SMS_PIN'))
      .catch((e) => {
        const errorCode = getFirstErrorCode(e)
        setSnackbarText({
          title: t('common.error.label.errorTitle'),
          message: t(t(errorCode) ? errorCode : 'forgotPhone.errors.generic'),
        })
        setServerError(true)
      })
  }, [getFullPhoneNumber, goToStep, phone.isValidNumber, sendNewSimMutation, t])

  return (
    <CenteredBox sx={forgotPhoneClasses.root}>
      <Snackbar open={serverError} setOpen={setServerError} flashText={snackbarText} />
      <Box sx={forgotPhoneClasses.textContainer}>
        <Typography variant="h2" color="primary">
          {t('forgotPhone.newSim.title')}
        </Typography>
      </Box>
      <Box sx={forgotPhoneClasses.inputContainer}>
        <PhoneNumberInput
          label={t('forgotPhone.newSim.label')}
          phone={phone}
          onChange={(e) => updatePhoneNumber(e.target.value)}
          setPhone={setPhone}
          error={error}
          helperText={getHelperText()}
          readOnly={isNativeNoOverride()}
        />
      </Box>
      <Box sx={forgotPhoneClasses.buttonContainer}>
        <NumpadWithoutState
          numpadType="pin"
          numpadValue={phone.phoneNumber}
          setNumpadValue={updatePhoneNumber}
          onConfirm={onSubmit}
          usedWithPhoneNumberValidation
        />
        <Button
          size="large"
          sx={forgotPhoneClasses.bottomButtons}
          type="submit"
          variant="text"
          color="secondary"
          onClick={() => goToStep('START')}
          data-testid="go-to-start"
        >
          {t('forgotPhone.oldSim.back')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default NewSim
