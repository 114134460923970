import React, { useState } from 'react'
import PSNContext from './context'

interface Props {
  children?: React.ReactNode
}

const PushNotificationService: React.FC<Props> = ({ children }) => {
  const [pushActiveOnDevice, setPushActiveOnDevice] = useState(false)
  const [pushGenericCallbacksRegistered, setPushGenericCallbacksRegistered] = useState(false)

  return (
    <PSNContext.Provider
      value={{
        pushActiveOnDevice,
        setPushActiveOnDevice,
        pushGenericCallbacksRegistered,
        setPushGenericCallbacksRegistered,
      }}
    >
      {children}
    </PSNContext.Provider>
  )
}

export default PushNotificationService
