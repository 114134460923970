import theme from 'utils/theme'

export const classes = {
  listItem: {
    minHeight: '44px',
    paddingTop: theme.spacing(1.5),
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  displayButton: {
    background: 'none',
    display: 'flex',
    textAlign: 'left',
    boxSizing: 'content-box',
    padding: 0,
    margin: 0,
    justifyContent: 'unset',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    fontSize: 'inherit',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: theme.spacing(0.5),
  },
  nameLine: {
    display: 'flex',
  },
  mainText: {
    // @ts-ignore
    color: theme.palette.grey[900],
    flex: 1,
  },
  mainTextTypography: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
  },
  mainTextDisabled: {
    color: theme.palette.text.disabled,
  },
  mainIcon: {
    // @ts-ignore
    color: theme.palette.primary[700],
  },
  caption: {
    textTransform: 'none',
    display: '',
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    color: theme.palette.grey[900],
    lineHeight: '1.5',
    fontSize: '14px',
  },
  subText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginBottom: '12px',
  },
  warning: {
    position: 'relative',
  },
  warningText: {
    color: theme.palette.secondary.main,
    position: 'relative',
    marginLeft: theme.spacing(0.25),
    bottom: theme.spacing(0.25),
  },
  info: {
    position: 'relative',
  },
  infoText: {
    color: theme.palette.grey[900],
    fontWeight: 350,
    lineHeight: '15px',
    fontSize: '12px',
  },
  infoTextDisabled: {
    color: theme.palette.text.disabled,
  },
  infoPadding: {
    paddingTop: theme.spacing(1.0),
    paddingBottom: theme.spacing(0.5),
  },
}
