import React from 'react'
import { Chip } from '@mui/material'
import { PlannedShiftT } from 'types'
import { chipClasses } from './styles'

interface IWeekShiftChipProps {
  shift: PlannedShiftT
}

const WeekShiftChip: React.FC<IWeekShiftChipProps> = ({ shift }) => {
  return <Chip sx={chipClasses.avatarShiftColor(shift)} />
}

export default WeekShiftChip
