import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  text: {
    // @ts-ignore
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(1.3125),
  },
  title: {
    // @ts-ignore
    color: theme.palette.primary[900],
    lineHeight: 1.26,
    fontWeight: 600,
    marginBottom: theme.spacing(0.75),
  },
  imageLink: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    objectFit: 'contain',
    overflow: 'hidden',
    marginBottom: theme.spacing(1.8125),
  },
  downloadLink: {
    textDecoration: 'none',
  },
  downloadButton: {
    marginBottom: theme.spacing(1),
  },
}
