import palette from 'utils/theme/palette'

export const classes = {
  container: {
    padding: '8px',
  },
  section: {
    fontWeight: 600,
    fontSize: '14px',
    marginRight: '8px',
  },
  value: {
    fontSize: '14px',
    fontWeight: 400,
  },
  greyVariant: {
    color: palette.grey[600],
  },
  noBottomSpacingRoot: {
    //safe space + some margin
    paddingBottom: 'calc(24px + var(--ion-safe-area-bottom))',
  },
  bottomSpacingRoot: {
    //Size of ActionCardButtons
    paddingBottom: '84px',
  },
}
