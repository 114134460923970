export enum DocumentStatuses {
  seen = 'seen',
  received = 'received',
  accepted = 'accepted',
  failed = 'failed',
  noFavurAccount = 'no_favur_account',
  claimInProcess = 'claim_in_process',
  claimAcceptedThisMonth = 'claim_accepted_this_month',
  claimAcceptedNextMonth = 'claim_accepted_next_month',
}

export enum DocumentTypes {
  monthlySheet = 'monthly_sheet',
  payslip = 'payslip',
  salaryStatement = 'salary_statement',
}
