import { taskCountsQuery } from 'shared/queries'
import { TaskCountsT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

interface IUseTaskCountsProps {
  office?: boolean
}

const useTaskCounts = ({ office }: IUseTaskCountsProps = { office: false }) => {
  const { refetch, data, loading } = useJamesApolloQuery<{ taskCounts: TaskCountsT }>(taskCountsQuery, {
    variables: { office },
    fetchPolicy: 'cache-and-network',
  })

  return { refetch, taskCounts: data?.taskCounts, loading }
}

export default useTaskCounts
