import React from 'react'
import { Badge, BadgeProps } from '@mui/material'

const ComponentBadge: React.FC<BadgeProps> = ({
  children,
  overlap = 'circular',
  color = 'secondary',
  sx,
  ...props
}) => {
  return (
    <Badge overlap={overlap} color={color} sx={{ '.MuiBadge-badge': { border: '1px solid white' }, ...sx }} {...props}>
      {children}
    </Badge>
  )
}

export default ComponentBadge
