import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const CloseThinS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14962 2.14347C2.95436 2.33874 2.94959 2.65994 3.13898 2.86091L7.79238 7.79885L3.43182 12.4261C3.24243 12.627 3.2472 12.9482 3.44246 13.1435C3.63772 13.3388 3.94954 13.3341 4.13892 13.1332L8.47911 8.52758L12.6638 12.9681C12.8532 13.1691 13.165 13.1737 13.3602 12.9785C13.5555 12.7832 13.5603 12.462 13.3709 12.261L9.16584 7.79885L13.6637 3.02593C13.8531 2.82496 13.8483 2.50376 13.6531 2.30849C13.4578 2.11323 13.146 2.11786 12.9566 2.31882L8.47911 7.07013L3.84609 2.1538C3.6567 1.95284 3.34488 1.94821 3.14962 2.14347Z"
      />
    </Svg>
  )
}

export default CloseThinS
