import theme from 'utils/theme'

export const profileClasses = {
  employersCard: {
    marginBottom: '0px',
    cursor: 'pointer',
  },
  noticeBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  noticeLabel: {
    fontWeight: 500,
    lineHeight: '20px',
  },
  employersSection: {
    marginTop: '16px',
  },
  deactivateButtonBlcok: {
    textAlign: 'right',
    paddingTop: '24px',
  },
  deleteAccountText: {
    paddingTop: '8px',
  },
  deactivateButton: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
    letterSpacing: '0.9px',
  },
  employersSectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  employersTitleText: {
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  employersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  employersTitleSection: {
    lineHeight: '24px',
    height: '24px',
    fontWeight: 600,
    paddingLeft: theme.spacing(0.5),
    paddingTop: '4px',
  },
  employersLabelSection: {
    lineHeight: '22px',
  },
  employersSectionBottom: {
    paddingBottom: theme.spacing(0.5),
  },
  listElement: {
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1.0),
    '&:last-child': {
      flexGrow: '0 !important',
    },
  },
}
