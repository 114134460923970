import { PopoverOrigin } from '@mui/material'

export const getPopoverOrigin = (cursorPosition: {
  x: number
  y: number
}): {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
} => {
  const { innerWidth: width, innerHeight: height } = window
  const belowHalfY = cursorPosition.y < height / 2
  const belowHalfX = cursorPosition.x < width / 2

  return {
    anchorOrigin: {
      vertical: belowHalfY ? 'bottom' : 'top',
      horizontal: belowHalfX ? 'right' : 'left',
    },
    transformOrigin: {
      vertical: belowHalfY ? 'top' : 'bottom',
      horizontal: belowHalfX ? 'left' : 'right',
    },
  }
}
