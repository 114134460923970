import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PlusS } from 'icons'
import { newAbsenceButtonClasses } from './styles'

const NewAbsenceButton: React.FC<{ onNew: () => void }> = ({ onNew }) => {
  const { t } = useFavurTranslation()

  return (
    <Button
      onClick={onNew}
      variant="plus"
      sx={newAbsenceButtonClasses.requestButton}
      data-testid="absence-planner-new_absence_request"
    >
      <PlusS />
      {t('absencePlanner.taskbar.absencerequest.button')}
    </Button>
  )
}

export default NewAbsenceButton
