import { alertClasses } from '@mui/material'
import palette from '../palette'

export default {
  styleOverrides: {
    icon: {
      padding: '10px 0',
    },
    action: {
      maxHeight: '38px',
      padding: '0 8px',
      alignItems: 'center',
    },
    standardInfo: {
      backgroundColor: palette.alert.info.background,
      color: palette.alert.info.text,
      [`.${alertClasses.icon}`]: {
        color: palette.alert.info.icon,
      },
      [`.${alertClasses.action}`]: {
        color: palette.alert.info.text,
      },
    },
    standardError: {
      backgroundColor: palette.alert.error.background,
      color: palette.alert.error.text,
      [`.${alertClasses.icon}`]: {
        color: palette.alert.error.icon,
      },
      [`.${alertClasses.action}`]: {
        color: palette.alert.error.text,
      },
    },
    standardWarning: {
      backgroundColor: palette.alert.warning.background,
      color: palette.alert.warning.text,
      [`.${alertClasses.icon}`]: {
        color: palette.alert.warning.icon,
      },
      [`.${alertClasses.action}`]: {
        color: palette.alert.warning.text,
      },
    },
    standardSuccess: {
      backgroundColor: palette.alert.success.background,
      color: palette.alert.success.text,
      [`.${alertClasses.icon}`]: {
        color: palette.alert.success.icon,
      },
      [`.${alertClasses.action}`]: {
        color: palette.alert.success.text,
      },
    },
  },
}
