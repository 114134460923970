import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const AddAvatar: React.FC<IconPropsT> = () => {
  return (
    <Svg width="42" height="43" fill="none" viewBox="0 0 42 43">
      <circle cx="20" cy="20.5" r="20" fill="#fff" />
      <circle cx="34" cy="35" r="7.5" fill="#FF007C" stroke="#fff" />
      <path fill="#fff" fillRule="evenodd" d="M35 30v4h4v2h-4v4h-2v-4h-4v-2h4v-4h2Z" clipRule="evenodd" />
      <path
        stroke="#1F3968"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20 19.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM14 28.5c0-1.857.632-3.637 1.757-4.95C16.883 22.238 18.41 21.5 20 21.5c1.591 0 3.117.738 4.243 2.05C25.368 24.863 26 26.643 26 28.5H14Z"
      />
    </Svg>
  )
}

export default AddAvatar
