import { getManagedTenantsQuery } from 'shared/queries'
import { TenantT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

export type GetManagedTenantsReturnT = {
  getManagedTenants: TenantT[]
}

const useManagedTenants = (onCompleted?: (data: GetManagedTenantsReturnT) => void) => {
  const { data, loading, refetch } = useJamesApolloQuery<GetManagedTenantsReturnT>(getManagedTenantsQuery, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return { tenants: data?.getManagedTenants, loading, refetch }
}

export default useManagedTenants
