import mainTheme from 'utils/theme'

export const headerClasses = {
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexContainerOneChild: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconButton: {
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  badge: {
    '.MuiBadge-badge': {
      border: `1px solid ${mainTheme.palette.primary.main}`,
      backgroundColor: mainTheme.palette.secondary.main,
    },
  },
  fillerBox: {
    width: '32px',
  },
  headerContainer: {
    display: 'flex',
    textTransform: 'uppercase',
    height: mainTheme.spacing(1.5625),
    color: mainTheme.palette.primary.dark,
    marginBottom: mainTheme.spacing(1),
  },
}

export const classes = {
  section: {
    marginBottom: mainTheme.spacing(1.5),
  },
}
