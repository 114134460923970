import React from 'react'
import { Box } from '@mui/material'
import Card from 'components/Basics/Card'
import BankForm from 'components/Forms/Bank'
import { formClasses } from 'pages/UserSettings/styles'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import type { IEmploymentFormProps } from '../types'

const BankData: React.FC<IEmploymentFormProps> = ({
  changeTabTrigger,
  setChangeTabTrigger,
  tabChangeCallback,
  setIsFormDirty,
}) => {
  const history = useHistory()

  return (
    <Box component="form" sx={formClasses.container}>
      <Card>
        <BankForm
          changeTabTrigger={changeTabTrigger}
          onCancel={() => history.push(routes.userSettings)}
          setChangeTabTrigger={setChangeTabTrigger}
          setIsFormDirty={setIsFormDirty}
          accessPoint="userSettings"
          tabChangeCallback={tabChangeCallback}
        />
      </Card>
    </Box>
  )
}

export default BankData
