// eslint-disable-next-line complexity
export const getBadgeGlossaryKey = (badge: string) => {
  switch (badge) {
    case 'work_time_accepted':
      return 'timeline.badge.workTimeAccepted'
    case 'wtc_change_request':
      return 'timeline.badge.workTimeControlChangeRequest'
    case 'accepted_for_this_month':
      return 'timeline.badge.acceptedForThisMonth'
    case 'accepted_for_next_month':
      return 'timeline.badge.acceptedForNextMonth'
    case 'rejected':
      return 'timeline.badge.rejected'
    case 'called':
      return 'timeline.badge.called'
    case 'reminder':
      return 'timeline.badge.reminder'
    case 'absence_requested':
      return 'timeline.badge.absence_requested'
    case 'absence_request_accepted':
      return 'timeline.badge.absence_request_accepted'
    case 'absence_request_rejected':
      return 'timeline.badge.absence_request_rejected'
    case 'deletion_request':
      return 'timeline.badge.deletion_request'
    case 'deletion_confirmed':
      return 'timeline.badge.deletion_confirmed'
    case 'deletion_denied':
      return 'timeline.badge.deletion_denied'
    default:
      return '-'
  }
}
