import theme from 'utils/theme'

export const classes = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: theme.spacing(1.5),
  },
  headerBox: {
    flexGrow: 1,
    flexBasis: 0,
    marginTop: 0,
  },
  headerTitle: {
    flexGrow: 3,
    paddingTop: 0,
  },
  headerClose: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    '& .MuiIconButton-label': {
      textAlign: 'left',
      justifyContent: 'left',
    },
  },
  pinInfoBox: {
    // @ts-ignore
    color: theme.palette.grey[600],
    marginTop: theme.spacing(0.25),
  },
}
