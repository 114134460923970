import { ContactFieldT, AddressT, addressTypes } from '../../Contact/types'

export const getAddressIndex = (addresses: ContactFieldT[], addressType: AddressT) => {
  return addresses.findIndex((address) => address.type === addressType)
}

export const getAddressSubtitleKey = (addressType: AddressT) => {
  if (addressType === addressTypes.foreign) {
    return 'personalData.foreignAddress.title'
  }
  if (addressType === addressTypes.emergency) {
    return 'personalData.emergencyContact.title'
  }
  return 'personalData.mainAddress.title'
}

export const getAddressDeleteButtonKey = (addressType: AddressT) => {
  if (addressType === addressTypes.foreign) {
    return 'personalData.address.remove'
  }
  return 'personalData.address.remove'
}

export const getDialogText = (type: string) => {
  switch (type) {
    case 'main':
      return 'personalData.mainAddress.dialog'

    case 'foreign':
      return 'personalData.foreignAddress.dialog'

    case 'emergency':
      return 'personalData.emergencyContact.dialog'

    default:
      return `personalData.${type}.dialog`
  }
}
