import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { whiteButtonClasses } from './styles'

const WhiteButton = ({ children, ...props }: React.ComponentProps<typeof MuiButton>) => {
  return (
    <MuiButton sx={whiteButtonClasses.root} disableTouchRipple color="secondary" {...props}>
      {children}
    </MuiButton>
  )
}

export default WhiteButton
