import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AlertS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { notificationStatuses, ReminderSecureConnectionManualForFrontlinerNotificationT } from 'types'
import palette from 'utils/theme/palette'

interface ISecureConnectionManualReminder extends NotificationPropsT {
  notification: ReminderSecureConnectionManualForFrontlinerNotificationT
}

const SecureConnectionManualReminder: React.FC<ISecureConnectionManualReminder> = (props) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { managerNameShort } = notification.data

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data

    history.push(routes.secureConnectionWithTask(taskUuid))
  }, [dismiss, history, notification])

  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.push.headline.manual.reminder', { managerNameShort }),
    body: t('notification.secureconnection.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default SecureConnectionManualReminder
