import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const FutureS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 2a6 6 0 1 0 3.77 10.67.58.58 0 0 0 .04-.86l-.27-.27a.58.58 0 0 0-.77-.05 4.45 4.45 0 1 1 .28-6.73L9.82 5.98a.39.39 0 0 0 .28.66h3.51c.22 0 .39-.17.39-.38V2.74a.39.39 0 0 0-.66-.27l-1.2 1.2A5.98 5.98 0 0 0 8 2zm.2 2.9c.28 0 .52.21.57.49v2.62l.99.76c.22.18.29.49.16.73l-.06.09-.24.3a.58.58 0 0 1-.72.16L8.8 10 7.24 8.77V5.48c0-.29.2-.52.48-.57h.48z"
      />
    </Svg>
  )
}

export default FutureS
