import { useEffect, useCallback } from 'react'

export const useKeyPress = (allowedKeys: string[], ignoredKeys: string[], onKeyPress: (key: string) => void) => {
  const filterKeys = useCallback(
    function localFilterKeys(this: Window, ev: KeyboardEvent) {
      const { key } = ev
      if (!ignoredKeys.some((a) => a === key)) {
        ev.preventDefault()
      }
      if (allowedKeys.some((a) => a === key)) {
        onKeyPress(key)
      }
    },
    [allowedKeys, onKeyPress, ignoredKeys],
  )

  useEffect(() => {
    window.addEventListener('keydown', filterKeys)
    return () => {
      window.removeEventListener('keydown', filterKeys)
    }
  }, [filterKeys])
}
