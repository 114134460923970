import React from 'react'
import { Box, IconButton, Typography, SxProps, Theme } from '@mui/material'
import { ArrowLeftM, ArrowLeftThinM, CloseM, CloseThinM } from 'icons'
import { NavLink } from 'react-router-dom'
import theme from 'utils/theme'
import { headerClasses } from './styles'

interface ISubheaderProps {
  children?: React.ReactNode
  closeIcon?: boolean
  dataTestId?: string
  iconThin?: boolean
  onClick?: () => void
  route?: string
  title: string
  topTitle?: string
  iconSx?: SxProps<Theme>
}
interface IWithSubheader extends ISubheaderProps {
  subheader?: string
}

interface IWithSubheaderAndLines extends IWithSubheader {
  multipleLines?: boolean
}

const getCloseIcon = (thin: boolean) =>
  thin ? <CloseThinM fill={theme.palette.common.white} /> : <CloseM fill={theme.palette.common.white} />
const getArrowLeftIcon = (thin: boolean) => (thin ? <ArrowLeftThinM /> : <ArrowLeftM />)
const getSubHeaderIcon = (close: boolean, thin: boolean) => {
  if (close) {
    return getCloseIcon(thin)
  }
  return getArrowLeftIcon(thin)
}

const MainTitleWithLink: React.FC<ISubheaderProps> = ({
  children,
  closeIcon = false,
  dataTestId,
  iconThin = false,
  onClick,
  route,
  title,
  topTitle,
  iconSx,
}) => {
  const icon = getSubHeaderIcon(closeIcon, iconThin)

  return (
    <Box data-testid={dataTestId} sx={headerClasses.subheaderContainer}>
      {topTitle && <Typography variant="caption">{topTitle}</Typography>}
      {onClick ? (
        <IconButton
          onClick={onClick}
          sx={[
            headerClasses.icon,
            ...(topTitle ? [headerClasses.iconWithTopTitle] : []),
            ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
          ]}
          data-testid="main-title-link"
          size="large"
        >
          {icon}
        </IconButton>
      ) : (
        <IconButton
          component={NavLink}
          to={route as string}
          sx={[headerClasses.icon, ...(topTitle ? [headerClasses.iconWithTopTitle] : [])]}
          data-testid="main-title-link"
          size="large"
        >
          {icon}
        </IconButton>
      )}

      <Typography variant="h2">{title}</Typography>
      {children}
    </Box>
  )
}

const WithBackwardLinkSingle: React.FC<ISubheaderProps> = ({
  children,
  closeIcon,
  onClick,
  route,
  title,
  topTitle,
  dataTestId,
  iconThin,
  iconSx,
}) => {
  return (
    <MainTitleWithLink
      closeIcon={closeIcon}
      onClick={onClick}
      route={route}
      title={title}
      topTitle={topTitle}
      dataTestId={dataTestId}
      iconThin={iconThin}
      iconSx={iconSx}
    >
      {children}
    </MainTitleWithLink>
  )
}

const WithBackwardLinkMultiple: React.FC<IWithSubheader> = ({
  children,
  closeIcon,
  onClick,
  route,
  title,
  topTitle,
  subheader,
  dataTestId,
  iconThin,
  iconSx,
}) => {
  return (
    <>
      <MainTitleWithLink
        closeIcon={closeIcon}
        onClick={onClick}
        route={route}
        title={title}
        topTitle={topTitle}
        dataTestId={dataTestId}
        iconThin={iconThin}
        iconSx={iconSx}
      />
      <Box sx={headerClasses.secondHeader}>
        <Typography variant="subtitle1">{subheader}</Typography>
      </Box>
      {children}
    </>
  )
}

const WithBackwardLink: React.FC<IWithSubheaderAndLines> = ({
  children,
  closeIcon,
  multipleLines,
  onClick,
  route,
  topTitle,
  title,
  subheader,
  dataTestId,
  iconThin,
  iconSx,
}) => {
  return multipleLines ? (
    <WithBackwardLinkMultiple
      closeIcon={closeIcon}
      onClick={onClick}
      route={route}
      title={title}
      topTitle={topTitle}
      subheader={subheader}
      dataTestId={dataTestId}
      iconThin={iconThin}
      iconSx={iconSx}
    >
      {children}
    </WithBackwardLinkMultiple>
  ) : (
    <WithBackwardLinkSingle
      closeIcon={closeIcon}
      onClick={onClick}
      route={route}
      title={title}
      topTitle={topTitle}
      dataTestId={dataTestId}
      iconThin={iconThin}
      iconSx={iconSx}
    >
      {children}
    </WithBackwardLinkSingle>
  )
}

export default WithBackwardLink
