import React, { useMemo } from 'react'
import { Box, BoxProps } from '@mui/material'
import { cardVariants, CardVariantT } from 'constants/componentsBasics'
import { baseClass, variantClasses } from './styles'

type CardPropsT = Omit<BoxProps, 'sx'> & { variant?: CardVariantT; disabled?: boolean }

const Card: React.FC<CardPropsT> = ({ children, variant = cardVariants.base, disabled, ...props }) => {
  const variantClass = useMemo(() => {
    if (disabled) {
      return variantClasses[cardVariants.disabled]
    }

    return variantClasses[variant] ?? {}
  }, [variant, disabled])

  const { onClick, ...otherProps } = props

  return (
    <Box sx={[baseClass, variantClass]} onClick={disabled ? undefined : onClick} {...otherProps}>
      {children}
    </Box>
  )
}
export default Card
