export const familyDataMutation = `
  familyData(familyData: {
    civilStatus: "#civilStatus"
    civilStatusSince: "#civilStatusSince"
    partner: #partner
    children: #children
  })
  {
    success
  }
`
