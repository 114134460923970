import { AbsenceRequestConflictsT } from 'types'

export const getPersonConflicts = (allConflicts: AbsenceRequestConflictsT, personId: number) => {
  const otherLeavesPersons =
    allConflicts?.otherLeavesPersons?.filter((conflictPerson) => conflictPerson.id !== personId) ?? []

  const otherLeavesCount = otherLeavesPersons.length

  const pendingRequestsPersons =
    allConflicts?.pendingRequestsPersons?.filter((conflictPerson) => conflictPerson.id !== personId) ?? []

  const pendingRequestsCount = pendingRequestsPersons.length

  const vacationsPersons =
    allConflicts?.vacationsPersons?.filter((conflictPerson) => conflictPerson.id !== personId) ?? []

  const vacationsCount = vacationsPersons.length

  return {
    otherLeavesCount: otherLeavesCount,
    otherLeavesPersons: otherLeavesPersons,
    pendingRequestsCount: pendingRequestsCount,
    pendingRequestsPersons: pendingRequestsPersons,
    vacationsCount: vacationsCount,
    vacationsPersons: vacationsPersons,
  }
}
