import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ReminderM } from 'icons'
import UserSettingsSectionEditCTA from 'pages/UserSettings/components/SectionEditCTA'
import { TenantReminderT } from 'types'
import palette from 'utils/theme/palette'

const getDaysHours = (hours: number) => ({ days: Math.floor(hours / 24), hours: hours % 24 })

interface IReminderListProps {
  reminderRow: TenantReminderT
  onClickEdit: (reminderNumber: number) => void
}

const ReminderList: React.FC<IReminderListProps> = ({ reminderRow, onClickEdit }) => {
  const { t } = useFavurTranslation()

  const getAnnotationText = useCallback(
    (reminderTime: number, prevReminderTime: number) => {
      const difference = reminderTime - prevReminderTime
      if (!difference) return undefined
      const { days, hours } = getDaysHours(difference)

      if (!days) return t('page.userSettings.tenantReminderList.annotationHours', { hours })
      if (!hours) return t('page.userSettings.tenantReminderList.annotationDays', { days })
      return t('page.userSettings.tenantReminderList.annotationDaysHours', { days, hours })
    },
    [t],
  )

  const getContentText = useCallback(
    (reminderTime: number) => {
      const { days, hours } = getDaysHours(reminderTime)

      if (!days) return t('page.userSettings.tenantReminderList.timeHours', { hours })
      if (!hours) return t('page.userSettings.tenantReminderList.timeDays', { days })
      return t('page.userSettings.tenantReminderList.timeDaysHours', { days, hours })
    },
    [t],
  )

  return (
    <>
      {reminderRow.first > 0 && (
        <UserSettingsSectionEditCTA
          title={t('page.userSettings.tenantReminderList.reminderNumber', { reminderNumber: '1' })}
          subtitle={getContentText(reminderRow.first)}
          icon={<ReminderM stroke={palette.primary.main} />}
          onClick={() => onClickEdit(1)}
        />
      )}
      {reminderRow.second > 0 && (
        <UserSettingsSectionEditCTA
          title={t('page.userSettings.tenantReminderList.reminderNumber', { reminderNumber: '2' })}
          subtitle={getContentText(reminderRow.second)}
          icon={<ReminderM stroke={palette.primary.main} />}
          annotation={getAnnotationText(reminderRow.second, reminderRow.first)}
          onClick={() => onClickEdit(2)}
        />
      )}
      {reminderRow.third > 0 && (
        <UserSettingsSectionEditCTA
          title={t('page.userSettings.tenantReminderList.reminderNumber', { reminderNumber: '3' })}
          subtitle={getContentText(reminderRow.third)}
          icon={<ReminderM stroke={palette.primary.main} />}
          annotation={getAnnotationText(reminderRow.third, reminderRow.second)}
          onClick={() => onClickEdit(3)}
        />
      )}
    </>
  )
}

export default ReminderList
