import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'

const Start: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <Button variant="contained" color="secondary" onClick={() => {}}>
      {t('secureConnection.buttons.start')}
    </Button>
  )
}

export default Start
