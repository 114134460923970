import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { InvitationNotificationT, NotificationT } from 'types'
import NotificationTemplate from '../Templates/Notification'

interface IInvitationNotification {
  notification: InvitationNotificationT
  dismiss: (notification: NotificationT) => void
}

const InvitationNotification: React.FC<IInvitationNotification> = (props) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { notification, dismiss } = props

  const handleRedirect = useCallback(() => {
    dismiss(notification)
    history.push(`${routes.invite}/${notification.invitationCode}`)
  }, [dismiss, history, notification])

  const templateProps = {
    ...props,
    title: t('notification.new.invitation.title'),
    body: t('notification.new.invitation.body', { tenantName: notification.data.tenantName }),
    details: handleRedirect,
    detailsText: t('notification.new.invitation.reviewLink'),
    // we are passing dismiss this way because it's a unique case this notification, and putting it as optional creates a lot of problems in the component
    dismiss: () => {},
  }

  return <NotificationTemplate {...templateProps} />
}

export default InvitationNotification
