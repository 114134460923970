import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import FilterContainer from 'pages/AbsencePlanner/components/TaskBar/Filters/FilterContainer'
import PlanAbsenceButton from 'pages/AbsencePlanner/components/TaskBar/NativeCTA/PlanAbsenceButton'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'

const AbsenceRequestBlock: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setBackLink } = useStateBackLink()

  return (
    <FilterContainer title={t('page.shifts.sideMenu.createTask')}>
      <PlanAbsenceButton
        dataTestId="sidebar-absence-request__button"
        onClick={() => {
          setBackLink(routes.shifts)
          history.push(routes.newAbsenceRequest)
        }}
      />
    </FilterContainer>
  )
}

export default AbsenceRequestBlock
