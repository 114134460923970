import React from 'react'
import Checkbox from 'components/Checkbox'
import { entryTypesClasses } from './styles'

interface IFiltersCheckBoxProps {
  key: string
  checked: boolean
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

const FiltersCheckBox: React.FC<IFiltersCheckBoxProps> = ({ key, dataTestId, checked, label, onChange }) => {
  return (
    <Checkbox
      key={key}
      checked={checked}
      label={label}
      sx={entryTypesClasses.checkBox}
      onChange={onChange}
      dataTestId={dataTestId}
    />
  )
}

export default FiltersCheckBox
