import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const UserDataXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99 7.01A3.393 3.393 0 0 1 8.406 6h7.197c.909 0 1.777.365 2.414 1.01a3.44 3.44 0 0 1 .99 2.418v1.429h18.189c.908 0 1.776.366 2.413 1.01a3.44 3.44 0 0 1 .991 2.419v4.01a3.395 3.395 0 0 1 1.701 1.193 3.44 3.44 0 0 1 .593 2.934L39.379 39.4a3.43 3.43 0 0 1-1.223 1.886 3.39 3.39 0 0 1-2.081.714l-26.95-.06a3.716 3.716 0 0 1-3.19-1.243A3.768 3.768 0 0 1 5 38.26V9.428c0-.903.354-1.773.99-2.418Zm3.005 32.93-.549-.001a1.703 1.703 0 0 1-.996-.558 1.774 1.774 0 0 1-.439-1.141V9.428c0-.384.151-.75.414-1.016S8.041 8 8.405 8h7.197c.364 0 .716.146.98.412.263.267.414.632.414 1.016v2.429c0 .552.45 1 1.005 1h19.194c.363 0 .716.146.979.412.263.267.414.632.414 1.017v3.857h-21.18a3.39 3.39 0 0 0-2.054.694 3.43 3.43 0 0 0-1.236 1.858l-3.754 18.023a1.749 1.749 0 0 1-.704.96 1.69 1.69 0 0 1-.665.262Zm3.03.007 24.05.053c.303 0 .6-.1.843-.29a1.43 1.43 0 0 0 .497-.743l3.513-16.97.012-.053a1.462 1.462 0 0 0-.245-1.25 1.403 1.403 0 0 0-.49-.407c-.191-.095-.4-.144-.61-.144H17.407c-.298 0-.59.098-.83.281a1.429 1.429 0 0 0-.499.716l-3.753 18.02a3.765 3.765 0 0 1-.3.787Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.318 23.318a4.5 4.5 0 1 1 6.364 6.364 4.5 4.5 0 0 1-6.364-6.364ZM27.5 24a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM25.644 31.726a7.73 7.73 0 0 1 4.677.307c1.482.584 2.73 1.617 3.535 2.95a1 1 0 1 1-1.712 1.034c-.568-.941-1.464-1.692-2.556-2.123a5.73 5.73 0 0 0-3.464-.227 5.359 5.359 0 0 0-2.862 1.777 4.88 4.88 0 0 0-.406.573 1 1 0 1 1-1.712-1.034c.17-.28.36-.55.572-.808.992-1.209 2.377-2.066 3.928-2.45Z"
      />
    </Svg>
  )
}

export default UserDataXL
