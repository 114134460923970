import theme from 'utils/theme'

const bannerClasses = {
  banner: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.primary[700]}`,
    zIndex: 0,
    position: 'relative',
  },
  content: {
    margin: '0 auto',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(1, 1.25, 0.75),
    boxSizing: 'border-box',
  },
  actions: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}

export default bannerClasses
