import { AbsencePlannerBadgeT } from 'pages/AbsencePlanner/types'
import { scrollerDivId } from '../Grid'
import { TASKBAR_WIDTH } from '../TaskBar/styles'

export const isBadgeVisible = (elementId: string) => {
  const scrollableContainer = document.getElementById(scrollerDivId)
  const targetElement = document.getElementById(elementId)
  if (!scrollableContainer || !targetElement) return undefined
  const containerRight = scrollableContainer.getBoundingClientRect().right
  const elementRight = targetElement.getBoundingClientRect().right

  return elementRight <= containerRight - TASKBAR_WIDTH
}

export const getBadgeKey = (badge: AbsencePlannerBadgeT) => `${badge.type}_${badge.badgeId}_${badge.date}`
