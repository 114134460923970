import React, { PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'
import { classes } from './styles'
import { ISectionProps } from './types'

const Section: React.FC<PropsWithChildren<ISectionProps>> = ({ title, subtitle, children }) => {
  return (
    <Box sx={classes.root}>
      {(title || subtitle) && (
        <Box sx={classes.titleContainer}>
          {title && (
            <Typography variant="h3" sx={classes.title}>
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography variant="body2" sx={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Box>
  )
}

export default Section
