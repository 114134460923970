import { Locale, isSameDay } from 'date-fns'
import { TFunction } from 'react-i18next'
import { CmsNewContentNotificationT, CmsUpdateContentNotificationT } from 'types'
import { formatDate } from 'utils/documents'

export const contentTranslationPropsFromNotification = (
  notification: CmsNewContentNotificationT | CmsUpdateContentNotificationT,
  t: TFunction<'translation', undefined>,
  locale: Locale | undefined,
) => ({
  title: notification.data.title,
  ...(notification.data.startDate && notification.data.endDate
    ? {
        date: `${formatDate(notification.data.startDate, locale)} ${
          isSameDay(notification.data.startDate, notification.data.endDate)
            ? ``
            : `- ${formatDate(notification.data.endDate, locale)}`
        }`,
      }
    : {}),
})
