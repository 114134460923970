import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  title: {
    // @ts-ignore
    color: theme.palette.primary[900],
    lineHeight: 1.26,
    fontWeight: 600,
    paddingTop: theme.spacing(0.75),
  },
  bodyText: {
    marginTop: theme.spacing(1),
  },
  captionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1.25),
  },
  timer: {
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    margin: theme.spacing(1, 0),
    marginBottom: 0,
    padding: theme.spacing(0.5625, 0.75),
    // @ts-ignore
    color: theme.palette.primary[700],
  },
  resendSMSDialogButton: {
    color: theme.palette.secondary.main,
  },
}
