import React, { PropsWithChildren, useMemo } from 'react'
import ComponentBadge from 'components/ComponentBadge'
import Tabs from 'components/Tabs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from 'pages/Notifications/styles'
import { NotificationCountsT } from 'pages/Notifications/types'
import { TabT } from 'types'

const BADGE_MAX_VALUE = 1000

interface INotificationTabsProps {
  currentTabIndex: number
  handleChange: (newValue: number) => void
  counts: NotificationCountsT
}

const NotificationTabs: React.FC<PropsWithChildren<INotificationTabsProps>> = ({
  currentTabIndex,
  handleChange,
  children,
  counts: { manager, personal },
}) => {
  const { t } = useFavurTranslation()

  const managerLabel = useMemo(() => {
    const label = <span>{t('page.notifications.tabs.manager')}</span>
    if (!manager) return label
    return (
      <ComponentBadge max={BADGE_MAX_VALUE} badgeContent={manager} overlap="rectangular" sx={classes.tabsBadge}>
        {label}
      </ComponentBadge>
    )
  }, [manager, t])

  const frontlinerLabel = useMemo(() => {
    const label = <span>{t('page.notifications.tabs.personal')}</span>
    if (!personal) return label
    return (
      <ComponentBadge max={BADGE_MAX_VALUE} badgeContent={personal} overlap="rectangular" sx={classes.tabsBadge}>
        {label}
      </ComponentBadge>
    )
  }, [personal, t])

  const tabs: TabT[] = [
    { id: 0, label: managerLabel },
    { id: 1, label: frontlinerLabel },
  ]

  return (
    <Tabs
      currentTabIndex={currentTabIndex}
      handleChange={(_event, newValue) => handleChange(newValue)}
      tabs={tabs}
      removeTopContentPadding
    >
      {children}
    </Tabs>
  )
}

export default NotificationTabs
