import React from 'react'
import PinRepeat from '../../Common/PinRepeat'

const SecuritySetupPinRepeat: React.FC<{
  goToStep: (stepName: string) => void
  hideStepper: (hide: boolean) => void
}> = ({ goToStep, hideStepper }) => {
  return <PinRepeat goToStep={goToStep} hideStepper={hideStepper} securityProcessType="pinReset" />
}

export default SecuritySetupPinRepeat
