import { isNative } from 'utils/platform'
import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    borderRadius: '6px',
    padding: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > :last-child': {
      height: '24px',
    },
  },
  text: {
    lineHeight: '24px',
  },
  tooltipTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    boxShadow: theme.shadows[2],
    maxWidth: isNative() ? 'auto' : '600px',
    // we need this important due to MUI tooltip's implementation
    marginBottom: '8px !important',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.2px',
    padding: '12px',
  },
  tooltipArrow: {
    '&::before': {
      boxShadow: theme.shadows[2],
    },
    color: theme.palette.common.white,
  },
  tooltipButton: {
    width: '100%',
    padding: 0,
    margin: 0,
    textTransform: 'none',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
    textAlign: 'start',
  },
  badge: {
    // @ts-ignore
    color: theme.palette.primary[700],
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
  },
  fullWidth: {
    width: '100%',
  },
}

export const getClasses = (withTooltip: boolean) => (withTooltip ? [classes.badge, classes.fullWidth] : classes.badge)
