import React from 'react'
import { Button, Typography } from '@mui/material'
import classes from './styles'

export interface INumpadInput {
  input: string
  onClick: (pin: string) => void
}

const NumpadInput: React.FC<INumpadInput> = ({ input, onClick }) => {
  return (
    <Button aria-label="Numpad input" sx={classes.button} onClick={() => onClick(input)} data-testid="numpad-input">
      <Typography sx={classes.label} variant="body1">
        {input}
      </Typography>
    </Button>
  )
}

export default NumpadInput
