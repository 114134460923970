import theme from 'utils/theme'

export const pullerClasses = {
  pullerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  puller: {
    width: '32px',
    height: '4px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey[300],
  },
}
