import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const AccountSettingsM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M2.792 1.03c-.728.133-1.276.574-1.582 1.272-.084.192-.156.48-.186.745-.032.29-.032 17.647 0 17.982.063.646.347 1.16.846 1.534.18.133.533.298.796.37l.24.067h17.236l.216-.055c.901-.23 1.503-.969 1.617-1.987.033-.29.033-17.647 0-17.982-.062-.646-.346-1.161-.845-1.534a2.839 2.839 0 0 0-.796-.37l-.24-.067L11.536 1c-7.267-.003-8.586.001-8.744.03Zm.14 1.524c-.216.085-.339.26-.397.567-.02.104-.025 1.78-.02 9.002.007 8.513.009 8.863.051 8.973.053.137.2.275.367.343.116.047.179.048 8.554.054 7.72.006 8.448.003 8.555-.033.227-.075.363-.261.423-.576.02-.104.025-1.78.02-9.002-.007-9.514-.009-8.863-.051-8.973-.053-.137-.2-.275-.367-.343-.116-.047-.177-.048-8.567-.053-8.282-.005-8.453-.004-8.568.041Zm8.017 1.692A4.97 4.97 0 0 0 8.432 5.31a4.918 4.918 0 0 0-1.643 2.586 5.118 5.118 0 0 0 0 2.378 4.861 4.861 0 0 0 6.233 3.432 4.897 4.897 0 0 0 3.187-3.426c.189-.753.19-1.633.002-2.384a4.873 4.873 0 0 0-4.189-3.65 6.54 6.54 0 0 0-1.073.001Zm.024 1.522c-.415.07-.86.233-1.199.439-.512.31-.952.776-1.232 1.305a3.37 3.37 0 0 0 .437 3.778 3.321 3.321 0 0 0 2.521 1.144c.684 0 1.28-.181 1.858-.564a3.36 3.36 0 0 0 1.1-4.358 3.356 3.356 0 0 0-2.442-1.744 4.307 4.307 0 0 0-1.043 0Zm-.328 8.481a8.196 8.196 0 0 0-3.748 1.345 8.152 8.152 0 0 0-2.656 2.825c-.2.348-.232.435-.231.622a.74.74 0 0 0 .683.739c.354.025.587-.128.81-.532a6.875 6.875 0 0 1 4.34-3.33 7.092 7.092 0 0 1 2.796-.106c2.016.322 3.854 1.62 4.853 3.429.227.41.46.564.815.539.283-.02.494-.174.62-.45a.752.752 0 0 0 .021-.55c-.104-.256-.527-.932-.829-1.325-1.255-1.633-3.183-2.793-5.208-3.132a9.796 9.796 0 0 0-2.266-.074Z"
      />{' '}
    </Svg>
  )
}

export default AccountSettingsM
