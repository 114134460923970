import { createContext, Dispatch, SetStateAction, Context } from 'react'

export interface IPinState {
  pin?: string
  pinLength?: number
  securityTimeout?: boolean
  securitySetupTokenConfirmed?: boolean
  pinChangePinConfirmed?: boolean
}

export interface IPinContext {
  pin?: string
  pinLength?: number
  securityTimeout?: boolean
  securitySetupTokenConfirmed?: boolean
  pinChangePinConfirmed?: boolean
  setPinContext: Dispatch<SetStateAction<IPinState>>
}

const initialState: IPinContext = {
  setPinContext: () => {},
}

const PinContext: Context<IPinContext> = createContext(initialState)

export default PinContext
