import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const SecurityPinRepeatL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M26.87 17.62c.14 0 .25.12.25.25v1c0 .12-.08.22-.2.25h-.9a5.63 5.63 0 0 1-4.15 9.12h-.25v-1.5h.23a4.13 4.13 0 0 0 2.89-6.82l-.12-.13v1.58c0 .12-.08.22-.2.25h-1.05a.25.25 0 0 1-.25-.25v-3.75h3.75zM21.62 17v1.5a4.11 4.11 0 0 0-3.12 6.82l.12.13v-1.83h1.5v4h-4v-1.5h1.1a5.63 5.63 0 0 1 4.15-9.11l.25-.01zM6 14a2 2 0 0 1 .35 3.97l-.18.02L6 18a2 2 0 0 1-1.97-1.65l-.02-.17L4 16a2 2 0 0 1 1.65-1.97l.18-.02L6 14zm5 0a2 2 0 0 1 2 1.83v.35a2 2 0 0 1-1.65 1.79l-.18.02L11 18a2 2 0 0 1-1.97-1.65l-.02-.17L9 16a2 2 0 0 1 1.65-1.97l.18-.02L11 14zm-5 1h-.1a1 1 0 0 0-.9.86v.24a1 1 0 0 0 .85.9h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H6zm5 0h-.1a1 1 0 0 0-.9.86v.24a1 1 0 0 0 .85.9h.25a1 1 0 0 0 .9-.87v-.26a.99.99 0 0 0-.85-.86L11 15zM6 9a2 2 0 0 1 .35 3.97l-.18.02L6 13a2 2 0 0 1-1.97-1.65l-.02-.17L4 11a2 2 0 0 1 1.65-1.97l.18-.02L6 9zm5 0a2 2 0 0 1 .35 3.97l-.18.02L11 13a2 2 0 0 1-1.97-1.65l-.02-.17L9 11a2 2 0 0 1 1.65-1.97l.18-.02L11 9zm5 0a2 2 0 0 1 .35 3.97l-.18.02L16 13a2 2 0 0 1-1.97-1.65l-.02-.18L14 11a2 2 0 0 1 1.65-1.97l.18-.02L16 9zM6 10h-.1a1 1 0 0 0-.9.86v.24a1 1 0 0 0 .85.9h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H6zm5 0h-.1a1 1 0 0 0-.9.86v.24a1 1 0 0 0 .85.9h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H11zm5 0h-.1a1 1 0 0 0-.9.85v.25c.03.23.13.44.3.6a1 1 0 0 0 .55.3h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H16zM6 4a2 2 0 0 1 .35 3.97l-.18.02L6 8a2 2 0 0 1-1.97-1.65l-.02-.17L4 6a2 2 0 0 1 1.65-1.97l.18-.02L6 4zm5 0a2 2 0 0 1 .35 3.97l-.18.02L11 8a2 2 0 0 1-1.97-1.65l-.02-.17L9 6a2 2 0 0 1 1.65-1.97l.18-.02L11 4zm5 0a2 2 0 0 1 .35 3.97l-.18.02L16 8a2 2 0 0 1-1.97-1.65l-.02-.18L14 6a2 2 0 0 1 1.65-1.97l.18-.02L16 4zM6 5h-.1a1 1 0 0 0-.9.86L5 5.98v.12a1 1 0 0 0 .86.9h.24a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H6zm5 0h-.1a1 1 0 0 0-.9.86l-.01.12v.12a1 1 0 0 0 .85.9h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H11zm5 0h-.1a1 1 0 0 0-.9.85l-.01.13v.12c.03.23.13.44.3.6a1 1 0 0 0 .55.3h.25a1 1 0 0 0 .6-1.7 1 1 0 0 0-.56-.3H16z"
      />
    </Svg>
  )
}

export default SecurityPinRepeatL
