export const personalDataMutation = `
  PersonalData(personal_data: {
    firstName: "#firstName"
    lastName: "#lastName"
    nickname: "#nickname"
    gender: "#gender"
    nationality: "#nationality"
    contactLanguage: "#contactLanguage"
    placeOfOrigin: "#placeOfOrigin"
    socialInsuranceNumber: "#socialInsuranceNumber"
    healthInsurance: "#healthInsurance"
    residenceCategoryValidUntil: "#residenceCategoryValidUntil"
    residenceCategoryZemisNumber: "#residenceCategoryZemisNumber"
    residenceCategoryType: "#residenceCategoryType"
  }){
    success
  }
`
