import theme from 'utils/theme'

export const teamDialogClasses = {
  input: {
    marginTop: theme.spacing(0.5),
  },
  select: {
    width: `calc(100% - ${theme.spacing(0.5)})`,
    marginTop: theme.spacing(1),
    '& .MuiSelect-select': {
      textAlign: 'left',
    },
  },
  checkbox: {
    paddingLeft: 0,
  },
}
