export const ownersClasses = {
  radioGroupContainer: {
    width: '100%',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.2px',
    },
  },
}
