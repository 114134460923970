import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const UserDataPersonalL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M28.09 5.08v22a1 1 0 0 1-1 1h-22a1 1 0 0 1-1-1v-22a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1zm-1.5.5h-21v21h1.54C7.88 22.65 11.56 19 16 19c4.44 0 8.13 3.65 8.87 7.58h1.72v-21zM16 20.5c-3.4 0-6.52 2.76-7.3 5.95l-.02.05h14.64l-.01-.05c-.78-3.12-3.77-5.82-7.08-5.95H16zM16 8a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
      />
    </Svg>
  )
}

export default UserDataPersonalL
