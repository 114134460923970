import React from 'react'
import { Box } from '@mui/material'
import backgroundClasses from './styles'

interface IBackgroundProps {
  menu?: boolean
  opaque?: boolean
}

const Background: React.FC<IBackgroundProps> = ({ menu, opaque }) => {
  return (
    <Box
      sx={[
        backgroundClasses.divbackground,
        ...(opaque ? [backgroundClasses.opaque] : []),
        ...(menu ? [backgroundClasses.menu] : []),
      ]}
    />
  )
}

export default Background
