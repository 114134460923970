import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { ArrowLeftThinM } from 'icons'
import theme from 'utils/theme'
import { absenceRequestListClasses } from '../styles'

interface IAbsenceRequestListHeaderProps {
  title: string
  subtitle: string
  goBack?: () => void
}

const AbsenceRequestListHeader: React.FC<IAbsenceRequestListHeaderProps> = ({ title, subtitle, goBack }) => {
  return (
    <Box sx={absenceRequestListClasses.headerContainer}>
      <Box sx={absenceRequestListClasses.headerFlex}>
        {goBack && (
          <IconButton
            onClick={goBack}
            sx={absenceRequestListClasses.iconButton}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <ArrowLeftThinM fill={theme.palette.primary.main} />
          </IconButton>
        )}
        <Box sx={absenceRequestListClasses.headerText}>
          <Typography variant="subtitle1" sx={absenceRequestListClasses.title}>
            {title}
          </Typography>
          <Typography variant="body1" sx={absenceRequestListClasses.subtitle}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <DivisionLine />
    </Box>
  )
}

export default AbsenceRequestListHeader
