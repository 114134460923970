import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { groupEvents } from 'pages/Cms/EventsList/utils'
import { CONTENT_TYPES, EVENT_TIMELINE_FILTERS } from 'types/cms'
import { taskBarClasses } from '../../styles'
import { eventTabStates } from '../../types'
import EmptyEvents from './EmptyEvents'
import EventDetails from './EventDetails'
import EventGroup from './EventGroup'

const Events: React.FC = () => {
  const { t, locale } = useFavurTranslation()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { list, loading } = useListCmsPaginatedContents({
    contentType: CONTENT_TYPES.event,
    eventTimelineFilter: EVENT_TIMELINE_FILTERS.UPCOMING,
  })
  const groupedEvents = useMemo(
    () => groupEvents({ contentList: list, eventTimelineFilter: EVENT_TIMELINE_FILTERS.UPCOMING, locale }),
    [list, locale],
  )
  const isEventListEmpty = !groupedEvents.length && !loading
  const { selectedEvent } = useSelectedStatesContext()

  const tabState = useMemo(() => {
    if (selectedEvent) return eventTabStates.detail

    return eventTabStates.list
  }, [selectedEvent])

  return (
    <Box sx={[taskBarClasses.eventsContainer, isSmallScreen && taskBarClasses.eventsContainerNative]}>
      {tabState === eventTabStates.detail && selectedEvent && <EventDetails eventId={selectedEvent}></EventDetails>}
      {tabState === eventTabStates.list && (
        <Box sx={taskBarClasses.eventsContentContainer}>
          {!isSmallScreen && (
            <Box>
              <Typography variant="subtitle1" sx={taskBarClasses.eventsTitle}>
                {t('absencePlanner.taskbar.events.title')}
              </Typography>
              <DivisionLine />
            </Box>
          )}
          {!isEventListEmpty ? (
            <Box sx={taskBarClasses.eventGroup}>
              {groupedEvents.map((group) => (
                <EventGroup key={group.dateLabel} dateLabel={group.dateLabel} contents={group.contents} />
              ))}
            </Box>
          ) : (
            <EmptyEvents />
          )}
        </Box>
      )}
    </Box>
  )
}

export default Events
