import theme from 'utils/theme'

export const classes = {
  pinConfirmTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5),
  },
  titleGutter: {
    display: 'flex',
    flex: 1,
    justifyContent: 'start',
  },
  timer: {
    marginTop: `${theme.spacing(0.5)} !important`,
    display: 'inline-flex',
    color: theme.palette.text.secondary,
  },
  pinDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'auto',
    flex: 1,
  },
  confirmButton: {
    marginTop: theme.spacing(1.5),
  },
  validationButton: {
    marginTop: theme.spacing(1),
  },
  backButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.primary.main,
  },
  headingText: {
    marginBottom: theme.spacing(4.125),
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  numpadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resendSMSDialogButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.75),
  },
  smsCodeInput: {
    marginTop: theme.spacing(3),
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontSize: '33px',
      textAlign: 'center',
      width: '279px',
    },
  },
}
