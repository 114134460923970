import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { HourglassS, SlimCheckMarkS } from 'icons'
import { EffectiveShiftT, ShiftsByTenantCCT } from 'types'
import { getFromUntil } from 'utils/shifts'
import { classes } from './styles'

interface IShiftCardEffectiveShiftProps {
  costcenter: ShiftsByTenantCCT
}
const ShiftCardEffectiveShift: React.FC<IShiftCardEffectiveShiftProps> = ({ costcenter }) => {
  const { t } = useFavurTranslation()
  const shifts = costcenter.shifts as EffectiveShiftT[]
  const hideUnverifiedShifts = costcenter.hideUnverifiedEffectiveTimes
  const isValidated = shifts.length > 0 && shifts[0].validated
  const hideTimes = hideUnverifiedShifts && !isValidated

  return (
    <>
      <Box sx={[classes.validatedText, ...(isValidated ? [classes.validatedColor] : [classes.pendingColor])]}>
        {isValidated && (
          <>
            <SlimCheckMarkS />
            <Typography variant="caption">{t('page.shifts.shiftCard.validated')}</Typography>
          </>
        )}
        {hideTimes && (
          <>
            <HourglassS />
            <Typography variant="caption">{t('page.shifts.shiftCard.pending')}</Typography>
          </>
        )}
      </Box>
      {shifts.map((shift) => (
        <Box sx={classes.typeLine} key={shift.id}>
          <Typography variant="body2">{t(`page.shifts.content.${shift.timeType}`)}</Typography>
          <Typography variant="body2">{hideTimes ? '-' : getFromUntil(shift, t, true)}</Typography>
        </Box>
      ))}
      <Box sx={classes.typeLine}>
        <Typography variant="body2">{t('component.shifthistory.costcenter')}</Typography>
        <Typography variant="body2">{hideTimes ? '-' : costcenter.costCenterName}</Typography>
      </Box>
    </>
  )
}

export default ShiftCardEffectiveShift
