import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { getPersonTeamWithMembersQuery } from 'shared/queries'
import { PersonT } from 'types'

const usePersonTeams = (favurUuid: string) => {
  const { data, loading } = useJamesApolloQuery<{ getPerson: PersonT }>(getPersonTeamWithMembersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: favurUuid,
    },
  })

  return { person: data?.getPerson, loading }
}

export default usePersonTeams
