import React from 'react'
import { Box, Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { ArrowRightThinS } from 'icons'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

interface IAcceptedAbsenceRequestProps {
  absenceRequest: TaskT<AbsenceRequestTaskT>
  date: string
  onClick?: (task: TaskT<AbsenceRequestTaskT>) => void
}

const AcceptedAbsenceRequest: React.FC<IAcceptedAbsenceRequestProps> = ({ absenceRequest, date, onClick }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setBackLink } = useStateBackLink()

  return (
    <Box width="100%" display="flex" justifyContent="end">
      <Button
        variant="text"
        color="primary"
        disableRipple
        disableFocusRipple
        sx={classes.closedTaskCTA}
        onClick={() => {
          if (onClick) onClick(absenceRequest)
          else {
            setBackLink(routes.shifts)
            history.push(`${routes.absenceRequestDetail}/${absenceRequest.favurUuid}?dateref=${date}`)
          }
        }}
      >
        {t('page.shifts.shiftCard.acceptedAbsenceRequest.body')}
        <ArrowRightThinS />
      </Button>
    </Box>
  )
}

export default AcceptedAbsenceRequest
