import { NativeBiometric } from 'capacitor-native-biometric'
import { PIN_KEYCHAIN_SERVER } from 'constants/highSecurityConnection'
import { PIN_KEYS } from 'constants/localStorage'
import useIonicStorage from 'hooks/useIonicStorage'
import { isNativeNoOverride } from 'utils/platform'

const usePinKeysStorage = () => {
  const { getItem, setItem, removeItem } = useIonicStorage()

  const setBioPinDisabled = (value: boolean) => setItem(PIN_KEYS.BIO_PIN_DISABLED, `${value}`)
  const getBioPinDisabled = async () => (await getItem(PIN_KEYS.BIO_PIN_DISABLED)) === 'true'
  const removeBioPinDisabled = () => removeItem(PIN_KEYS.BIO_PIN_DISABLED)
  const setBioPinInUse = (value: boolean) => setItem(PIN_KEYS.BIO_PIN_IN_USE, `${value}`)
  const getBioPinInUse = async () => (await getItem(PIN_KEYS.BIO_PIN_IN_USE)) === 'true'
  const removeBioPinInUse = () => removeItem(PIN_KEYS.BIO_PIN_IN_USE)

  const storePinInKeychain = (pinValue: string) => {
    return getBioPinDisabled().then((isDisabled) => {
      if (isNativeNoOverride() && !isDisabled) {
        // Resolve directly if biometric is not available or type 0
        NativeBiometric.isAvailable().then(async ({ biometryType, isAvailable }) => {
          if (!isAvailable) return
          if (biometryType > 0) {
            await NativeBiometric.setCredentials({
              username: 'pin',
              password: pinValue,
              server: PIN_KEYCHAIN_SERVER,
            })
            setBioPinInUse(true)
          }

          return
        })
      } else {
        return
      }
    })
  }

  return {
    setBioPinDisabled,
    getBioPinDisabled,
    removeBioPinDisabled,
    setBioPinInUse,
    getBioPinInUse,
    removeBioPinInUse,
    storePinInKeychain,
  }
}

export default usePinKeysStorage
