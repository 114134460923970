import { useCallback, useEffect, useState } from 'react'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import { PERSON_STATUS } from 'constants/person'
import { addDays } from 'date-fns'
import usePackage from 'hooks/usePackage'
import useStateBackLink from 'hooks/useStateBackLink'
import useTenantsAndPermissions from 'hooks/useTenantsAndPermissions'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { taskTypes } from 'shared/constants'
import { CreateAbsenceRequestResultT, TenantNamesT } from 'types'
import { datePickersToString, isValidDate, newDateWithoutTime } from 'utils/date'
import { getSnakeCaseFromCamelCase } from 'utils/string'
import { useMutation } from '@apollo/client'
import { createAbsenceRequestMutation } from './mutations'
import { useNewAbsenceRequestForm } from './useNewAbsenceRequestForm'

const useNewAbsenceRequest = (onSuccess?: (result: CreateAbsenceRequestResultT) => void) => {
  const { absenceBetaWorkflowPersonIds: classicPersonIds } = usePackage()
  const { tenantsAndPermissions, status: tenantQueryStatus } = useTenantsAndPermissions()
  const history = useHistory()
  const [showDialogBlockedPeriod, setShowDialogBlockedPeriod] = useState(false)
  const [firstAvailableDate, setFirstAvailableDate] = useState<null | Date>(null)
  const [selectedPersonTenant, setSelectedPersonTenant] = useState<null | TenantNamesT>(null)
  const [defaultPersonUuid, setDefaultPersonUuid] = useState<string | undefined>(undefined)
  const [createAbsenceRequest, { loading: loadingMutation }] = useMutation<CreateAbsenceRequestResultT>(
    createAbsenceRequestMutation,
  )
  const { backLink, pushBackLinkOrDefault, setBackLink } = useStateBackLink()
  const { setLoadFilterTasks } = useFilterUtils()

  const { formFields, formErrors, validateFields, setValue } = useNewAbsenceRequestForm()

  const onSubmit = useCallback(async () => {
    // Absence blocked, do not submit. Show dialog
    if (firstAvailableDate) {
      setShowDialogBlockedPeriod(true)
      return
    }

    if (validateFields()) {
      const { comment, absencePeriod, fromDate, toDate, dateRange, personUuid } = formFields
      const mutationResult = await createAbsenceRequest({
        variables: {
          comment: comment.value ?? '',
          data: {
            absencePeriod: getSnakeCaseFromCamelCase(absencePeriod.value as string),
            dateFrom: datePickersToString(fromDate.value),
            dateTo:
              dateRange.value === 'multipleDays'
                ? datePickersToString(toDate.value)
                : datePickersToString(fromDate.value),
          },
          personUuid: personUuid.value,
          taskType: taskTypes.absenceRequest,
          triggeredByPersonUuid: personUuid.value,
        },
      })

      if (mutationResult.data?.createAbsence.success) {
        if (onSuccess) onSuccess(mutationResult.data)
        else {
          if (backLink !== routes.shifts) {
            setBackLink(routes.tasks)
          }
          history.push(`${routes.absenceRequestDetail}/${mutationResult.data?.createAbsence.taskUuid}`)
        }
      }
    }
  }, [backLink, createAbsenceRequest, firstAvailableDate, formFields, history, onSuccess, setBackLink, validateFields])

  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    pushBackLinkOrDefault(routes.tasks)
  }, [pushBackLinkOrDefault, setLoadFilterTasks])

  const handlePersonUuidChange = useCallback(
    (personUuid: string) => {
      const person = tenantsAndPermissions?.find((tenantPerson) => {
        return tenantPerson.favurUuid === personUuid
      })
      if (person) {
        setSelectedPersonTenant(person)
        setValue('personUuid', personUuid)
      }
    },
    [setValue, tenantsAndPermissions],
  )

  const getTenantOptions = useCallback(() => {
    const reducer = (acc: Record<string, string>, personId: string) => {
      const person = tenantsAndPermissions?.find((tenantPerson) => {
        return (
          tenantPerson.personId === personId &&
          tenantPerson.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high &&
          [PERSON_STATUS.ACTIVE, PERSON_STATUS.APPLICANT].includes(tenantPerson.status || '')
        )
      })
      return person ? { ...acc, [person.favurUuid]: person.tenantName } : acc
    }

    return classicPersonIds.reduce(reducer, {})
  }, [tenantsAndPermissions, classicPersonIds])

  // Validates the date against the tenant absence period
  useEffect(() => {
    const fromDate = formFields.fromDate.value as Date
    // invalid date, nothing to check
    if (!fromDate || !isValidDate(fromDate)) {
      return
    }

    // No blocking period, nothing to check
    if (!selectedPersonTenant?.tenantAbsencesBlockingPeriod) {
      setFirstAvailableDate(null)
      return
    }

    const firstAvailableDateToCheck = addDays(
      newDateWithoutTime().setHours(0, 0, 0, 0),
      // We don't count today, that's why we add +1. If it changes in the future
      // check and change also the backend
      selectedPersonTenant.tenantAbsencesBlockingPeriod + 1,
    )

    if (fromDate.getTime() < firstAvailableDateToCheck.getTime()) {
      setShowDialogBlockedPeriod(true)
      setFirstAvailableDate(firstAvailableDateToCheck)
    } else {
      setFirstAvailableDate(null)
    }
  }, [formFields.fromDate.value, selectedPersonTenant?.tenantAbsencesBlockingPeriod])

  /* Set the default person uuid.
    - '' if multi-tenant i.e. user needs to select it
    - set to the only person favur_uuid if single tenant */
  useEffect(() => {
    if (defaultPersonUuid === undefined && tenantsAndPermissions) {
      if (classicPersonIds.length > 1) {
        setDefaultPersonUuid('')
        return
      }

      const person = tenantsAndPermissions.find((tenantPerson) => {
        return (
          tenantPerson.personId === classicPersonIds[0] &&
          tenantPerson.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high &&
          [PERSON_STATUS.ACTIVE, PERSON_STATUS.APPLICANT].includes(tenantPerson.status || '')
        )
      })
      if (person) {
        setValue('personUuid', person.favurUuid)
        setDefaultPersonUuid(person.favurUuid)
        setSelectedPersonTenant(person)
      }
    }
  }, [classicPersonIds, defaultPersonUuid, setValue, tenantsAndPermissions])

  return {
    loading: !tenantsAndPermissions || tenantQueryStatus === 'loading',
    showDialogBlockedPeriod,
    setShowDialogBlockedPeriod,
    handlePersonUuidChange,
    getTenantOptions,
    selectedPersonTenant,
    firstAvailableDate,
    classicPersonIds,
    gotoBackLink,
    loadingMutation,
    formFields,
    formErrors,
    setValue,
    onSubmit,
  }
}

export default useNewAbsenceRequest
