import React from 'react'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FilterContainer from 'pages/AbsencePlanner/components/TaskBar/Filters/FilterContainer'
import { useSideMenuContext } from '../context'
import { classes } from '../styles'

const CostCentersBlock: React.FC = () => {
  const { t } = useFavurTranslation()
  const { costCenters } = useSideMenuContext()
  return (
    <FilterContainer title={t('page.shifts.sideMenu.myTeams')}>
      {costCenters.map((costCenter) => {
        return (
          <Box key={costCenter.name} alignItems="center" display="flex">
            <FormControlLabel
              control={<Checkbox sx={classes.checkBox} checked disabled />}
              sx={classes.checkListOption}
              label={costCenter.name}
              disabled
            />
          </Box>
        )
      })}
    </FilterContainer>
  )
}

export default CostCentersBlock
