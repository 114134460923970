import { seenTutorialBase } from 'constants/tutorials'
import routes, { classicRoutes } from 'services/RoutesProvider/routes'

type TutorialsT = {
  [key: string]: {
    url: string
    label: string
    goto: string
    showNew: boolean
  }
}

const Tutorials: TutorialsT = {
  shiftplan: {
    url: classicRoutes.shifts,
    label: 'component.tutorialEntryPoint.title.shiftPlan',
    goto: `${routes.tutorial}/shiftplan`,
    showNew: false,
  },
}

const getStorageId = (id: string): string => `${seenTutorialBase}${id.toUpperCase()}`

const shownBefore = (id: string): boolean => {
  const db = localStorage.getItem(getStorageId(id))
  return db === 'true'
}

export const showNewLabel = (id: string): boolean => {
  return Tutorials[id]?.showNew && !shownBefore(id)
}
