import theme from 'utils/theme'

export const headerClasses = {
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[700],
    minHeight: '69px',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: theme.spacing(0, 1, 0, 1),
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    color: theme.palette.common.white,
    display: 'flex',
    marginTop: '32px',
  },
  birdyAndName: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  cage: {
    width: '32px',
  },
}
