import { useState, useEffect } from 'react'
import useJamesQuery from 'hooks/useJamesQuery'
import { SecuritySetupProcessT } from 'pages/SecuritySetup/types'
import { SessionValidityT } from 'types'
import { getDateFromISOString } from 'utils/date'

export type TimeoutProcessT = SecuritySetupProcessT | 'securityTimeout'

export const TIMEOUTS_M: { [key in TimeoutProcessT]: number } = {
  securitySetup: 2000 * 60,
  pinReset: 2000 * 60,
  securityTimeout: 2000 * 60,
  pinChange: 2000 * 60,
}

export const useSessionTimeout = (securityProcess: TimeoutProcessT, callback: () => void) => {
  const [refetchIntervalByBE, setRefetchIntervalByBE] = useState(TIMEOUTS_M[securityProcess])
  const [rendered, setRendered] = useState(false)
  const { data: sessionValidityData } = useJamesQuery<{
    sessionValidity: SessionValidityT
  }>(
    'session_validity',
    `query{
      sessionValidity {
      highSecSessionValidUntil
      highSecSessionValidNow
      securitySetupValidUntil
      securitySetupValidNow
      pinResetValidUntil
      pinResetValidNow
      timestamp
    }
  }`,
    {
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
      refetchInterval: refetchIntervalByBE,
    },
  )

  useEffect(() => {
    const timeoutKeyToServerTimeout = ['pinChange', 'securityTimeout'].includes(securityProcess)
      ? 'highSecSession'
      : securityProcess
    const validUntilKey = `${timeoutKeyToServerTimeout}ValidUntil` as keyof SessionValidityT
    const validNowKey = `${timeoutKeyToServerTimeout}ValidNow` as keyof SessionValidityT
    const timestamp = sessionValidityData?.sessionValidity?.timestamp
    const validUntil = sessionValidityData?.sessionValidity?.[validUntilKey]
    const validNow = sessionValidityData?.sessionValidity?.[validNowKey]

    if (!validNow && rendered) {
      callback()
      return
    }
    if (validNow && !rendered) {
      setRendered(true)
    }
    if (validUntil) {
      const validUntilISO = getDateFromISOString(validUntil as string)
      const nowISO = getDateFromISOString(timestamp as string)
      const nextRefetchIn = validUntilISO.getTime() - nowISO.getTime() + 1000

      setRefetchIntervalByBE(nextRefetchIn)
    } else {
      setRefetchIntervalByBE(TIMEOUTS_M[securityProcess])
    }
  }, [callback, rendered, sessionValidityData, securityProcess])

  return sessionValidityData?.sessionValidity
}
