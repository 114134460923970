import React, { useCallback, useReducer, useEffect, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import ForgottenPin from 'components/ForgottenPin'
import { SimpleLoading } from 'components/LoadingIcon'
import { NumpadWithoutState } from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import WrongPinWarning from 'components/WrongPinWarning'
import { PIN_LENGTHS } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import usePinContext from 'hooks/usePinContext'
import usePinStatus from 'hooks/usePinStatus'
import { useRedirectIfPinNotSetOrBlocked } from 'pages/PinLogin/hooks'
import { queryCache } from 'react-query'
import { pinReducer, pinEntryInitialState } from '../../hooks'
import { classes as securityClasses } from '../../styles'
import { EnterPinResponseT, PinEntryT, PinEntryActionT } from '../../types'
import { pinEnterClasses as classes } from './styles'

const PinEnter: React.FC<{ goToStep: (stepName: string) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const { setPinContext } = usePinContext()
  const [pinStatus, statusP] = usePinStatus({ queryName: 'pin_status_pin_change' })

  const [pinState, dispatchPin] = useReducer<(state: PinEntryT, action: PinEntryActionT) => PinEntryT>(
    pinReducer,
    pinEntryInitialState,
  )

  const getPinStatusData = useMemo(() => {
    return {
      pinStatusData: pinStatus,
      status: statusP,
    }
  }, [pinStatus, statusP])

  useRedirectIfPinNotSetOrBlocked({
    pinStatus: getPinStatusData,
    dispatchPin,
  })

  const [enterPin] = useJamesMutation<{ pinCode: string }, EnterPinResponseT>(
    `enterPin(pinCode: "#pinCode") {
      success
      remainRetries
      pinBlocked
    }`,
    {
      onSuccess: (res) => {
        const {
          data: {
            data: { enterPin: enterPinRes },
          },
        } = res
        if (enterPinRes.success) {
          dispatchPin({ type: 'pinValid' })
          queryCache.invalidateQueries('session_validity')
          setPinContext({ pinChangePinConfirmed: true })
          goToStep('PIN_ENTER')
          return
        }
        if (enterPinRes.pinBlocked) {
          dispatchPin({ type: 'pinBlocked' })
          queryCache.invalidateQueries('pin_status_pin_change')
          return
        }
        dispatchPin({ type: 'pinInvalid', payload: { attemptsLeft: enterPinRes.remainRetries } })
      },
    },
  )
  useEffect(() => {
    dispatchPin({
      type: 'setPinLength',
      payload: { pinLength: pinStatus?.pinLength || PIN_LENGTHS.default },
    })
  }, [pinStatus])

  const startTyping = useCallback((newVal: string) => {
    dispatchPin({ type: 'pinEntry', payload: { pinValue: newVal } })
  }, [])

  if (statusP === 'loading') {
    return <SimpleLoading />
  }

  return (
    <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
      <WrongPinWarning pinState={pinState} dispatchPin={dispatchPin} />

      <Box>
        <Typography variant="h2" sx={securityClasses.title}>
          {t('securitySetup.pinChange.currentPin.title')}
        </Typography>
      </Box>
      <Box sx={classes.pinDisplayContainer}>
        <PinDisplay pinLength={pinState.pinLength} pin={pinState.pinValue} secret />
        <ForgottenPin />
      </Box>
      <Box sx={classes.numpadContainer}>
        <NumpadWithoutState
          numpadType="pin"
          numpadValue={pinState.pinValue}
          setNumpadValue={startTyping}
          onConfirm={() => enterPin({ pinCode: pinState.pinValue })}
          outputLength={pinState.pinLength}
        />
      </Box>
    </CenteredBox>
  )
}

export default PinEnter
