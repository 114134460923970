import theme from 'utils/theme'

export const classes = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    '& img': {
      width: '182px',
    },
  },
  title: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  text: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}
