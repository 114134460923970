import { useCallback, useContext } from 'react'
import { AxiosResponse } from 'axios'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FlashMessagesContext from 'services/FlashMessages/context'

interface IMessage {
  text?: string
}

interface IApiResponse {
  success?: boolean
  message?: string
  error?: string
}

const CODES_TO_SKIP_ON_FAIL = [
  'common.error.be.termsAndConditions',
  'invitation.error.be.noInvitationWithCode',
  'invitation.be.error.birthdateMismatch',
]

const getMessageOnSuccess = (data: IApiResponse): IMessage => {
  if (data?.success === true) {
    return {
      text: data?.message,
    }
  }

  return {}
}

const getMessageOnFailure = (data: IApiResponse): IMessage => {
  if (typeof data.message === 'string') {
    return {
      text: data.message,
    }
  }

  if (typeof data.error === 'string') {
    return {
      text: data.error,
    }
  }

  return {}
}

const useHandleFlashMessage = () => {
  const { t } = useFavurTranslation()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const showMessageOnFailure = useCallback(
    (response: AxiosResponse | { data: IApiResponse }) => {
      if (CODES_TO_SKIP_ON_FAIL.includes(response.data.error)) {
        return false
      }

      const alert = getMessageOnFailure(response.data)
      if (alert.text) {
        setFlashMessage(alert.text)
        return true
      }

      return false
    },
    [setFlashMessage],
  )

  const showMessageOnSuccess = useCallback(
    (response: AxiosResponse | { data: IApiResponse }) => {
      const alert = getMessageOnSuccess(response.data)
      if (alert.text) {
        setFlashMessage(alert.text)
        return true
      }
      return false
    },
    [setFlashMessage],
  )

  const onSuccess = useCallback(
    (response: AxiosResponse) => {
      showMessageOnSuccess(response)
      return response
    },
    [showMessageOnSuccess],
  )

  const getErrorCode = useCallback(
    (code?: string) => {
      // If both are equal means the key is not on the translations file
      if (code && code !== t(code)) {
        return code
      }
      return 'common.error.be.default'
    },
    [t],
  )

  const onReject = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any,
    ) => {
      if (error?.message === 'not logged in' || error?.message === 'Retrieval of data failed') {
        return Promise.reject(error)
      }

      const data = { error: getErrorCode(error?.code) }
      showMessageOnFailure({ data })

      return Promise.reject(error)
    },
    [getErrorCode, showMessageOnFailure],
  )

  return [onSuccess, onReject]
}

export default useHandleFlashMessage
