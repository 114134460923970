import React, { useCallback } from 'react'
import { Box, IconButton, MobileStepper, useMediaQuery } from '@mui/material'
import { classes } from 'components/Tutorial/styles'
import WhiteButton from 'components/WhiteButton'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowLeftS, ArrowRightS } from 'icons'
import SwipeableViews from 'react-swipeable-views'
import { isMobileWeb } from 'utils/platform'
import theme from 'utils/theme'
import { ITutorialProps } from './types'

const Tutorial: React.FC<ITutorialProps> = ({
  introPage,
  stepPages,
  step,
  setStep,
  onSwipeEnd,
  startButtonTextKey,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useFavurTranslation()

  const showIntroPage = Boolean(introPage)
  const showIntroButton = showIntroPage && step === 0
  const showStepper = !showIntroPage || step > 0
  const activeStep = !showIntroPage ? step : step - 1

  const shouldTriggerSwipeEnd = useCallback(
    (index: number) => (!showIntroPage ? index + 1 > stepPages.length : index > stepPages.length),
    [showIntroPage, stepPages.length],
  )

  return (
    <Box sx={[classes.swipeContainer(isSmallScreen), ...(isMobileWeb() ? [classes.swipeContainerFullWidth] : [])]}>
      {!isSmallScreen && (
        <Box sx={classes.buttonContainer}>
          {step > 0 ? ( // We don't show the left arrow in the first step
            <IconButton
              sx={[classes.arrowButtons, classes.arrowMarginRight]}
              onClick={() => {
                step > 0 && setStep(step - 1)
              }}
              data-testid="calendar__button-prev"
              size="large"
            >
              <ArrowLeftS />
            </IconButton>
          ) : (
            <Box sx={classes.arrowEmptySpace}></Box>
          )}
        </Box>
      )}
      <Box sx={classes.pagesContainer}>
        <Box sx={classes.swipeableWrapper}>
          <SwipeableViews
            containerStyle={{ height: '100%' }}
            enableMouseEvents
            onChangeIndex={(newIdx) => {
              // Diff condition is different!! this is for allowing the swipe redirection without the button click
              if (shouldTriggerSwipeEnd(newIdx)) {
                // If the swipe is the empty Box, redirect
                onSwipeEnd()
                return
              }

              setStep(newIdx)
            }}
            index={step}
          >
            {[introPage, ...stepPages].filter((page) => page).map((page) => page)}
            <>{/* This empty Box is use to allow swipping to the right in the last view, and close the tutorial */}</>
          </SwipeableViews>
        </Box>
        {showIntroButton && (
          <Box sx={classes.buttons}>
            <WhiteButton
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setStep(step + 1)
              }}
              sx={classes.buttonStart}
            >
              {t(startButtonTextKey ?? 'tutorial.shiftplan.intro.start')}
            </WhiteButton>
          </Box>
        )}
        {showStepper && (
          <MobileStepper
            variant="dots"
            steps={stepPages.length}
            activeStep={activeStep}
            backButton={null}
            nextButton={null}
            position="static"
            sx={classes.stepper}
          />
        )}
      </Box>
      {!isSmallScreen && (
        <Box sx={classes.buttonContainer}>
          <IconButton
            sx={[classes.arrowButtons, classes.arrowMarginLeft]}
            onClick={() => {
              if (shouldTriggerSwipeEnd(step + 1)) {
                //If we clicked right in the last step, redirect
                onSwipeEnd()
                return
              }

              setStep(step + 1)
            }}
            data-testid="calendar__button-next"
            size="large"
          >
            <ArrowRightS />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default Tutorial
