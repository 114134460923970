import React from 'react'
import { Box, Button, Fab } from '@mui/material'
import { isNative } from 'utils/platform'
import { actionCardButtonsClasses } from './styles'

interface IActionButtonsProps {
  cancelAction: () => void
  cancelLabel: string
  isFloating?: boolean
  isValidateSubmit?: boolean
  isValidateButtonActive?: boolean
  validateAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  validateLabel: string
  noNavigation?: boolean
}

// eslint-disable-next-line complexity
const ActionButtons: React.FC<IActionButtonsProps> = ({
  cancelAction,
  cancelLabel,
  isFloating = false,
  isValidateButtonActive = true,
  isValidateSubmit = false,
  validateAction,
  validateLabel,
  noNavigation = false,
}) => {
  return (
    <Box
      sx={[
        actionCardButtonsClasses.actionButtonsContainer,
        ...(isFloating ? [actionCardButtonsClasses.floatingContainer] : []),
      ]}
    >
      {isFloating ? (
        <>
          <Fab
            sx={[
              actionCardButtonsClasses.floatingButtonBase,
              ...(isNative() ? [actionCardButtonsClasses.floatingButtonNativeMargin] : []),
              ...(isNative() && noNavigation ? [actionCardButtonsClasses.floatingButtonNativeNoNavigation] : []),
              actionCardButtonsClasses.floatingButtonCancel,
            ]}
            data-testid="cancel"
            onClick={cancelAction}
            size="large"
            variant="extended"
          >
            <Box mr={0.375} display="flex" alignItems="center">
              {cancelLabel}
            </Box>
          </Fab>
          <Fab
            sx={[
              actionCardButtonsClasses.floatingButtonBase,
              actionCardButtonsClasses.floatingButtonSave,
              ...(isNative() ? [actionCardButtonsClasses.floatingButtonNativeMargin] : []),
              ...(isNative() && noNavigation ? [actionCardButtonsClasses.floatingButtonNativeNoNavigation] : []),
              ...(isValidateButtonActive
                ? [actionCardButtonsClasses.floatingValidateActive]
                : [actionCardButtonsClasses.floatingValidateInactive]),
            ]}
            data-testid="save"
            disabled={!isValidateButtonActive}
            onClick={validateAction}
            size="large"
            variant="extended"
            {...(isValidateSubmit ? { type: 'submit' } : {})}
          >
            <Box mr={0.375} display="flex" alignItems="center">
              {validateLabel}
            </Box>
          </Fab>
        </>
      ) : (
        <>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={cancelAction}
            tabIndex={-1}
            data-testid="cancel"
            sx={actionCardButtonsClasses.cancelButton}
          >
            {cancelLabel}
          </Button>
          <Button
            type={isValidateSubmit ? 'submit' : 'button'}
            size="large"
            variant="contained"
            color="secondary"
            onClick={validateAction}
            data-testid="save"
            disabled={!isValidateButtonActive}
            sx={actionCardButtonsClasses.saveButton}
          >
            {validateLabel}
          </Button>
        </>
      )}
    </Box>
  )
}

export default ActionButtons
