import React from 'react'
import { Box } from '@mui/material'
import { useSideMenuContext } from '../../context'
import { classes } from '../../styles'
import MyTeams from './MyTeams'
import PublicTeams from './PublicTeams'

const TeamsBlock: React.FC = () => {
  const { teams, publicTeams } = useSideMenuContext()

  return (
    <Box sx={classes.blockMenuContainer}>
      {teams.length > 0 && <MyTeams />}
      {publicTeams.length > 0 && <PublicTeams />}
    </Box>
  )
}

export default TeamsBlock
