import React, { memo } from 'react'
import { Box } from '@mui/material'
import { gridClasses } from '../styles'
import { teamNameRowClasses } from './styles'

interface IPlaceHolderRowProps {
  shownDays: string[]
  teamId: string
}

const PlaceHolderRow: React.FC<IPlaceHolderRowProps> = ({ shownDays, teamId }) => {
  return (
    <Box sx={[gridClasses.stickyLeft, gridClasses.backgroundTeamName]}>
      {shownDays.map((day) => (
        <Box key={`${teamId}_placeholder_${day}`} sx={teamNameRowClasses.placeholderRowCell} />
      ))}
    </Box>
  )
}

export default memo(PlaceHolderRow)
