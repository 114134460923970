import React, { useCallback, useContext } from 'react'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { validateRegisterQuery } from 'pages/EntryPoint/queries'
import FlashMessagesContext from 'services/FlashMessages/context'
import { useMutation } from '@apollo/client'
import EntryPointWithoutMirusLogo from '../../EntryPointWithoutMirusLogo'
import ValidateComponent from '../../components/ValidateComponent/AutofillSmsCode'
import { IInvitationStepProps } from '../types'

type TInvitationValidateResponse = {
  validateRegister: { success: boolean }
}

const SmsValidation = ({ setStep }: IInvitationStepProps) => {
  const { t } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [mutate, { loading }] = useMutation<TInvitationValidateResponse>(validateRegisterQuery)

  const goBack = () => setStep('INVITE')

  const onConfirm = useCallback(
    async (validationCode: string, _rememberMe: boolean) => {
      try {
        removeAll()
        await mutate({ variables: { smsCode: validationCode } })
        setStep('REGISTER')
      } catch (_error) {
        setFlashMessage('login.validate.error.validationCodeNoMatch')
      }
    },
    [mutate, removeAll, setFlashMessage, setStep],
  )

  if (loading) {
    return <SimpleLoading />
  }

  return (
    <EntryPointWithoutMirusLogo>
      <ValidateComponent
        onConfirm={onConfirm}
        backButton={goBack}
        title={t('invitation.validation.content.title')}
        description={t('invitation.validation.content.description')}
      />
    </EntryPointWithoutMirusLogo>
  )
}

export default SmsValidation
