import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePerformEventToTask from './usePerformEventToTask'

interface IAcceptProps {
  taskUuid: string
  onAction: () => void
}

const Accept: React.FC<IAcceptProps> = ({ taskUuid, onAction }) => {
  const { t } = useFavurTranslation()
  const { loading, accept } = usePerformEventToTask(taskUuid, onAction)

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        accept()
      }}
      disabled={loading}
      data-testid="monthly-sheet-detail_accept-sheet"
    >
      {t('workTimeControl.buttons.accept')}
    </Button>
  )
}

export default Accept
