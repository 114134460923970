import { gql } from '@apollo/client'

export const getAvatarUploadLinkQuery = gql`
  query getAvatarUploadLinks {
    getAvatarUploadLinks {
      profile
      avatar32
      avatar48
      avatar64
      avatar96
      avatar128
      avatar252
      avatar504
      imageUuid
    }
  }
`

export const deleteAvatarMutation = gql`
  mutation deleteAvatar {
    deleteAvatar {
      success
    }
  }
`

export const updateUserAvatarUrlMutation = gql`
  mutation updateUserAvatarUrlMutation($imageUuid: String!) {
    updateUserAvatarUrl(imageUuid: $imageUuid) {
      success
    }
  }
`
