import React, { useMemo, useState } from 'react'
import { Button, SxProps, Theme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePersonsWithTeamPermissions from 'hooks/usePersonsWithTeamPermissions'
import { BalanceObject } from 'types/balances'
import { newDateWithoutTime } from 'utils/date'
import { PersonTeamPermissionsQueryReturnT } from '../../../../shared/queries'
import EmployeeSaldoDrawer from '../components/EmployeeSaldoDrawer'
import { improvedBalancesQuery } from '../queries'
import { newAbsenceRequestClasses } from './styles'

interface IShowBalancesButtonProps {
  selectedPersonUuid: string
  drawerSx?: SxProps<Theme>
}

const getPersonIds = (
  personTeamPermissions: PersonTeamPermissionsQueryReturnT | undefined,
  selectedPersonUuid: string,
) => {
  const selectedPerson = personTeamPermissions?.find((person) => person.favurUuid === selectedPersonUuid)
  if (!selectedPerson) return []
  return [selectedPerson.id]
}

const ShowBalancesButton: React.FC<IShowBalancesButtonProps> = ({ selectedPersonUuid, drawerSx }) => {
  const { t } = useFavurTranslation()
  const [estimateFromDay, setEstimateFromDay] = useState<Date>(newDateWithoutTime())
  const [isOpen, setIsOpen] = useState(false)
  const { personTeamPermissions, loading: loadingPTP } = usePersonsWithTeamPermissions()

  const { loading, data } = useJamesApolloQuery<{ improvedBalances?: BalanceObject[] }>(improvedBalancesQuery, {
    variables: {
      estimateFromDay: estimateFromDay.toISOString(),
      personIds: getPersonIds(personTeamPermissions, selectedPersonUuid),
    },
    skip: !selectedPersonUuid || loadingPTP || !isOpen,
  })

  const selectedTenantName = useMemo(() => {
    if (!personTeamPermissions || personTeamPermissions.length < 2) return undefined
    const selectedPerson = personTeamPermissions.find((ptp) => ptp.favurUuid === selectedPersonUuid)
    return selectedPerson?.tenant.name
  }, [personTeamPermissions, selectedPersonUuid])

  return (
    <>
      <Button
        variant="contained"
        sx={newAbsenceRequestClasses.balanceButton}
        onClick={() => {
          setIsOpen(true)
          setEstimateFromDay(newDateWithoutTime())
        }}
        disabled={!selectedPersonUuid}
      >
        {t('absenceRequest.new.showBalances')}
      </Button>
      <EmployeeSaldoDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        loading={loading}
        balances={data?.improvedBalances}
        tenantName={selectedTenantName}
        drawerSx={drawerSx}
      />
    </>
  )
}

export default ShowBalancesButton
