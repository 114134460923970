import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { RoleViewT } from 'types'
import useRolesViews from './useRolesViews'

const useHistoryUtils = () => {
  const history = useHistory()
  const { setActiveView, activeView } = useRolesViews()

  const pushWithRole = useCallback(
    (newRoute: string, newRole?: RoleViewT) => {
      if (newRole && activeView !== newRole) {
        setActiveView(newRole)
      }
      if (history.location.pathname === newRoute) {
        history.go(0)
      } else {
        history.push(newRoute)
      }
    },
    [activeView, history, setActiveView],
  )

  return {
    pushWithRole,
  }
}

export default useHistoryUtils
