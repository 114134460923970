import React, { useState } from 'react'
import ShiftsContext, { IShiftsState } from './context'

const initialState: IShiftsState = {
  start: '',
  end: '',
}
interface Props {
  children?: React.ReactNode
}

const ShiftsService: React.FC<Props> = ({ children }) => {
  const [state, setShiftsContext] = useState(initialState)

  return <ShiftsContext.Provider value={{ ...state, setShiftsContext }}>{children}</ShiftsContext.Provider>
}

export default ShiftsService
