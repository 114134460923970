import { EventsOfTask } from 'pages/Tasks/types'
import { TFunction } from 'react-i18next'

export const getWorkflowSpecificProps = (event: EventsOfTask, t: TFunction) => {
  switch (event) {
    case EventsOfTask.absenceRequestSendCommentToFrontline:
    case EventsOfTask.absenceRequestSendCommentToManager:
      return {
        inputProps: {
          successButtonText: t('absenceRequest.sendComment.successButton'),
          textFieldPlaceHolder: t('absenceRequest.sendComment.inputPlaceholder'),
          testIdName: 'send_comment',
        },
        buttonProps: {
          'data-testid': 'absence-request_action-send_comment',
        },
        buttonText: t('absenceRequest.buttons.addComment'),
      }
    case EventsOfTask.monthlySheetSendCommentToFrontline:
    case EventsOfTask.monthlySheetSendCommentToManager:
    case EventsOfTask.monthlySheetAcceptThisMonth:
    case EventsOfTask.monthlySheetAcceptNextMonth:
      return {
        inputProps: {
          successButtonText: t('workTimeControl.requestChange.successButton'),
          textFieldPlaceHolder: t('workTimeControl.requestChange.inputPlaceholder'),
          testIdName: 'monthly-sheet-detail_comment',
        },
        buttonProps: {
          'data-testid': 'monthly-sheet-detail_add_comment',
        },
        buttonText: t('workTimeControl.buttons.addComment'),
      }
    case EventsOfTask.secureConnectionSendCommentToFrontline:
    case EventsOfTask.secureConnectionSendCommentToManager:
      return {
        inputProps: {
          successButtonText: t('secureConnection.sendComment.successButton'),
          textFieldPlaceHolder: t('secureConnection.sendComment.inputPlaceholder'),
          testIdName: 'send_comment',
        },
        buttonProps: {
          'data-testid': 'secure-connection_add_comment',
        },
        buttonText: t('secureConnection.buttons.addComment'),
      }
    case EventsOfTask.shareUserDataSendCommentToManager:
    case EventsOfTask.shareUserDataSendCommentToFrontline:
      return {
        inputProps: {
          successButtonText: t('shareUserData.sendComment.successButton'),
          textFieldPlaceHolder: t('shareUserData.sendComment.inputPlaceholder'),
          testIdName: 'send_comment',
        },
        buttonProps: {
          'data-testid': 'share-user-data-detail_add_comment',
        },
        buttonText: t('shareUserData.buttons.addComment'),
      }
    default:
      return {
        inputProps: {
          successButtonText: t('workTimeControl.requestChange.successButton'),
          textFieldPlaceHolder: t('workTimeControl.requestChange.inputPlaceholder'),
          testIdName: 'undefined-workflow_comment',
        },
        buttonProps: {
          'data-testid': 'undefined-workflow_add_comment',
        },
        buttonText: t('workTimeControl.buttons.addComment'),
      }
  }
}
