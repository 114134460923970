import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { headerClasses } from 'components/Page/Header/SubHeader/styles'
import { ArrowLeftThinM } from 'icons'
import { backwardLinkClasses } from './styles'

interface IBackwardLinkProps {
  title?: string
  onClickIcon?: () => void
}

const BackwardLink: React.FC<IBackwardLinkProps> = ({ title = '', onClickIcon }) => {
  return (
    <Box sx={headerClasses.subheaderContainer}>
      <IconButton
        onClick={onClickIcon}
        sx={backwardLinkClasses.icon}
        data-testid="monthly-sheet-detail_back"
        size="large"
      >
        <ArrowLeftThinM />
      </IconButton>

      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  )
}

export default BackwardLink
