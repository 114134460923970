import React, { useState } from 'react'
import { Box } from '@mui/material'
import Page from 'components/Page'
import useStepper from 'hooks/useStepper'
import routes from 'services/RoutesProvider/routes'
import type { IDefaultComponentWIthStepperProps } from 'types'
import { classes } from './styles'

const PhoneChangeProcess: React.FC<IDefaultComponentWIthStepperProps> = ({ steps, urlStep }) => {
  const { activeStep, goToStep, Stepper } = useStepper({ route: routes.phoneChange, step: urlStep, steps })
  const [stepperHidden, setStepperHidden] = useState(false)
  if (!activeStep) {
    return null
  }

  return (
    <Page hideNativeNavigation hideBackground noPadding flex hideHamburger>
      {stepperHidden ? null : <Stepper activeStep={activeStep.index} steps={steps} />}
      <Box sx={classes.componentContainer}>
        <activeStep.component goToStep={goToStep} hideStepper={setStepperHidden} />
      </Box>
    </Page>
  )
}

export default PhoneChangeProcess
