import React, { useState } from 'react'
import { NumpadWithoutState } from './numpad'
import { NumpadT } from './types'

export interface INumpad {
  numpadType: NumpadT
  outputLength?: number
  onNumpadValueChange?: (pin: string) => void
  onConfirm?: () => void
}

const Numpad: React.FC<INumpad> = ({ outputLength, onConfirm, numpadType, onNumpadValueChange }) => {
  const [numpadValue, setNumpadValue] = useState('')
  return (
    <NumpadWithoutState
      {...{ outputLength, onNumpadValueChange, onConfirm, numpadType, numpadValue, setNumpadValue }}
    />
  )
}

export { NumpadWithoutState }
export default Numpad
