import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const classes = {
  permissionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  teamNameTitle: {
    display: 'flex',
  },
  teamNameTitleText: {
    paddingLeft: theme.spacing(0.2),
    position: 'relative',
    top: theme.spacing(0.1),
  },
  caption: {
    textTransform: 'none',
    display: '',
    position: 'relative',
    bottom: theme.spacing(0.2),
    left: theme.spacing(0.2),
    marginTop: theme.spacing(0.25),
  },
  noAdminText: {
    color: theme.palette.grey[300],
  },
  removeAdminButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(0.25),
  },
  cardInsides: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noTeamText: {
    color: palette.text.disabled,
    fontWeight: 'bold',
  },
  addToTeamButton: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  addToTeamButtonMargin: {
    marginTop: theme.spacing(0.75),
  },
  userNoTeamText: {
    marginBottom: theme.spacing(0.75),
  },
  addText: {
    marginLeft: theme.spacing(0.25),
    letterSpacing: '0.7px',
  },
  removeButton: {
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.25),
    },
  },
  tenantDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  teamDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}
