import theme from 'utils/theme'
import favurTheme from 'utils/theme'
import palette from 'utils/theme/palette'

export const classes = {
  text: {
    position: 'relative',
    top: theme.spacing(0.5),
  },
  searchContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  noTeamsText: {
    color: palette.text.disabled,
    fontWeight: 600,
    padding: theme.spacing(1.0),
    paddingLeft: '22px',
    fontSize: theme.spacing(1.0),
    lineHeight: theme.spacing(1.5),
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginBottom: favurTheme.spacing(1.0),
  },
  cardContents: {
    justifyContent: 'normal',
    flexDirection: 'column',
  },
}
