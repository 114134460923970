import React, { useCallback, useEffect, useState } from 'react'
import featureFlags from 'constants/featureFlags'
import { seenTutorialBase } from 'constants/tutorials'
import useJamesMutation from 'hooks/useJamesMutation'
import { fetchInfo } from 'hooks/useJamesQuery'
import useUserLanguage from 'hooks/useUserLanguage'
import info from 'info.json'
import { sideMenuFiltersStorageKey } from 'pages/Shifts/useStoredFilters'
import { queryCaches, queryCache } from 'react-query'
import { isNativeNoOverride } from 'utils/platform'
import { useApolloClient } from '@apollo/client'
import { Filesystem, Directory, FileInfo } from '@capacitor/filesystem'
import { AuthenticationKind } from '../../constants/authentication'
import { MeObject, AuthPersonIdAndPermissions } from '../../types'
import SessionContext, { ApiMeResponseT } from './context'
import { getSessionQuery, logoutMutationParams, logoutDeviceMutationParams, RememberMeParams } from './queries'
import useRefreshPermissions from './useRefreshPermissions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: ApiMeResponseT = { auth: -1 as any, cmsActivated: false }
const initialPersons: AuthPersonIdAndPermissions[] = []

type AuthRefreshTokenResponse = {
  authRefreshToken?: MeObject
}
interface SessionProps {
  children?: React.ReactNode
}

const SessionService: React.FC<SessionProps> = ({ children }) => {
  const [session, setSession] = useState(initialState)
  const [registeredToken, setRegisteredToken] = useState<string | null>(null)

  const [persons, setPersons] = useState(initialPersons)
  const [rememberMeMutation] = useJamesMutation(RememberMeParams)
  const { setUserLanguage } = useUserLanguage()
  const client = useApolloClient()

  // Sets the user permissions if logged in
  const { refetch: refetchPersons } = useRefreshPermissions(setPersons, session.auth < 1)

  const clearLocalStorage = () => {
    const ignored = [...Object.keys(featureFlags), sideMenuFiltersStorageKey]
    const ignoredByStart = [seenTutorialBase]
    Object.keys(localStorage).forEach((key) => {
      if (ignored.includes(key) || ignoredByStart.some((value) => key.startsWith(value))) return
      localStorage.removeItem(key)
    })
  }

  const clearFilesInCache = async () => {
    if (isNativeNoOverride()) {
      await Filesystem.readdir({
        path: '/',
        directory: Directory.Cache,
      }).then((readdirResult) => {
        readdirResult.files.map(async (file: FileInfo) => {
          if (file.type === 'file') {
            await Filesystem.deleteFile({
              path: file.name,
              directory: Directory.Cache,
            })
          }
        })
      })
    }
  }

  const cleanSession = useCallback(
    (newSession?: ApiMeResponseT) => {
      // Clean storages
      clearLocalStorage()
      sessionStorage.clear()

      setSession(newSession || { auth: 0, cmsActivated: false })
      setRegisteredToken(null)
      setPersons(initialPersons)

      // Clear react-query cache:
      Object.values(queryCaches).map((cache) => {
        return cache.clear()
      })

      // Clear apollo cache
      client.resetStore()

      // Clear direcory ~/Librabry/Cache
      clearFilesInCache()
    },
    [client],
  )

  const logout = useCallback(() => {
    const logoutQuery = () => {
      if (isNativeNoOverride() && registeredToken) {
        return logoutDeviceMutationParams(registeredToken)
      }

      return logoutMutationParams
    }

    queryCache.fetchQuery(null, fetchInfo<{ logout: ApiMeResponseT }>(logoutQuery())).then((res) => {
      cleanSession(res?.logout)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredToken])

  const rememberMe = useCallback(() => {
    rememberMeMutation()
      .then((data: AuthRefreshTokenResponse) => {
        const sessionData: ApiMeResponseT = {
          auth: data?.authRefreshToken?.auth || AuthenticationKind.ANONYMOUS,
          cmsActivated: data?.authRefreshToken?.cmsActivated ?? false,
          phone: data?.authRefreshToken?.user?.phoneNumber,
          name: data?.authRefreshToken?.user
            ? `${data.authRefreshToken.user.firstName} ${data.authRefreshToken.user.lastName}`
            : '',
          personIds: data?.authRefreshToken?.personIds || [],
          personPackages: data?.authRefreshToken?.personPackages || {},
        }
        setSession(sessionData)
      })
      .catch((_err) => setSession({ auth: 0, cmsActivated: false }))
  }, [rememberMeMutation])

  const refresh = useCallback(() => {
    refetchPersons()
    queryCache
      .fetchQuery('serverSession', fetchInfo<{ me: MeObject }>(getSessionQuery({ buildVersionNumber: info.version })))
      .then((res) => {
        const me = res?.data?.data?.me

        if (me?.auth) {
          setUserLanguage(me?.user?.language)
        }

        return !me?.auth ? rememberMe() : setSession(me)
      })
      .catch((_err) => logout())
  }, [refetchPersons, setUserLanguage, rememberMe, logout])

  // Refetch session on didmount of service provider, basically happens only on hard reload.
  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <SessionContext.Provider
      value={{
        ...session,
        update: setSession,
        logout,
        cleanSession,
        refresh,
        registeredToken,
        setRegisteredToken,
        persons,
        setPersons,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default SessionService
