import React, { useMemo, useState } from 'react'
import AbsenceRequestConflictsCard from 'components/AbsenceRequestConflicts/AbsenceRequestConflictsCard'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import { CmsContentT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { getDatetimeRangeWithTimezone } from 'utils/date'
import { AbsenceRequestContext } from '../../AbsenceRequestContext'
import EventRestrictingAbsencesList from './EventRestrictingAbsencesList'

interface INewAbsenceRequestAlertListProps {
  startDate?: string
  endDate?: string
  onClickConflict?: () => void
  showAbsencePlannerPreview?: boolean
  selectedPersonUuid?: string
  onLoadRestrictingEvents?: (_contents: CmsContentT[]) => void
}

const NewAbsenceRequestAlertList: React.FC<INewAbsenceRequestAlertListProps> = ({
  startDate,
  endDate,
  onClickConflict,
  showAbsencePlannerPreview,
  selectedPersonUuid,
  onLoadRestrictingEvents,
}) => {
  const [showAbsencePlannerDrawer, setShowAbsencePlannerDrawer] = useState<boolean>(false)

  const validDates = startDate && endDate
  const eventsDateRange = useMemo(
    () => (validDates ? getDatetimeRangeWithTimezone({ start: startDate, end: endDate }) : undefined),
    [endDate, startDate, validDates],
  )
  const { list: listOfRestrictingEvents, loading } = useListCmsPaginatedContents({
    skip: !validDates,
    contentType: CONTENT_TYPES.event,
    ...eventsDateRange,
    isAbsenceRestricted: true,
    showAdmin: false,
    onLoad: onLoadRestrictingEvents,
  })

  const { persons } = usePersonsOfUser()
  const selectedPerson = useMemo(() => (persons ?? []).find(({ favurUuid }) => selectedPersonUuid === favurUuid), [
    persons,
    selectedPersonUuid,
  ])

  if (!validDates) return null

  const showRestrictingEvents = !loading && listOfRestrictingEvents.length > 0
  const showConflictsCard = !loading && listOfRestrictingEvents.length === 0

  if (!showRestrictingEvents && !showConflictsCard) return null

  return (
    <AbsenceRequestContext.Provider
      value={{
        showAbsencePlannerDrawer,
        setShowAbsencePlannerDrawer: setShowAbsencePlannerDrawer,
      }}
    >
      {showRestrictingEvents && <EventRestrictingAbsencesList events={listOfRestrictingEvents} />}
      {showConflictsCard && (
        <AbsenceRequestConflictsCard
          startDate={startDate}
          endDate={endDate}
          noPadding
          onClick={onClickConflict}
          previewPerson={selectedPerson}
          showAbsencePlannerPreview={showAbsencePlannerPreview}
          setShowAbsencePlannerDrawer={setShowAbsencePlannerDrawer}
          showAbsencePlannerDrawer={showAbsencePlannerDrawer}
        />
      )}
    </AbsenceRequestContext.Provider>
  )
}

export default NewAbsenceRequestAlertList
