import React, { useEffect } from 'react'
import { Box, SwipeableDrawer, Typography } from '@mui/material'
import Puller from 'components/BaseDrawer/Puller'
import { defaultSuccessPageTimeout } from 'components/Success'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CheckMarkCircleXL } from 'icons'
import { isNative } from 'utils/platform'
import theme from 'utils/theme'
import { successDrawerClasses } from './styles'

interface ISuccessDrawerProps {
  onClose: () => void
  open: boolean
}

const SuccessDrawer: React.FC<ISuccessDrawerProps> = ({ open, onClose }) => {
  const { t } = useFavurTranslation()

  useEffect(() => {
    if (!open) return () => {}

    const successTimeout = setTimeout(() => {
      onClose()
    }, defaultSuccessPageTimeout)

    return () => {
      clearTimeout(successTimeout)
    }
  }, [onClose, open])

  return (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      anchor="bottom"
      disableDiscovery
      disableAutoFocus
      PaperProps={{ sx: successDrawerClasses.paper }}
    >
      {isNative() && <Puller />}
      <Box sx={successDrawerClasses.bodyContainer}>
        <CheckMarkCircleXL fill={theme.palette.success.main} />
        <Typography variant="body1" fontWeight="bold">
          {t('shareUserData.successDrawer.title')}
        </Typography>
        <Typography variant="body2">{t('shareUserData.successDrawer.body')}</Typography>
      </Box>
    </SwipeableDrawer>
  )
}

export default SuccessDrawer
