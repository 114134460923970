import React, { useCallback, useState } from 'react'
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { MobileDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import Card from 'components/Basics/Card'
import useFilterContext from 'components/Filter/context'
import { DateRangeT } from 'components/Filter/types'
import { CalendarAppointmentS, CloseS } from 'icons'
import { getISOFirstDayOfMonth, getISOLastDayOfMonth, newDateWithoutTime } from 'utils/date'
import theme from 'utils/theme'
import { classes } from '../../styles'
import CustomActionBar from './ActionBar'

interface IDateRangeProps {
  configuration: DateRangeT
}
const DateRange: React.FC<IDateRangeProps> = ({ configuration }) => {
  const { changeValue, clearValue, getValue, validateFilters } = useFilterContext()
  const error = !validateFilters()
  const [openFromDate, setOpenFromDate] = useState(false)
  const [openToDate, setOpenToDate] = useState(false)
  const {
    label,
    maxDate: maxDateString,
    minDate: minDateString,
    fromDate: { name: fromDateName, label: fromDateLabel },
    toDate: { name: toDateName, label: toDateLabel },
  } = configuration

  const getInputAdornments = useCallback(
    (filterName: string) =>
      getValue(filterName) ? (
        <InputAdornment
          sx={classes.dateInputIcon}
          position="end"
          onClick={(event) => {
            event.stopPropagation()
            clearValue(filterName)
          }}
        >
          <CloseS fill={theme.palette.secondary.main} />
        </InputAdornment>
      ) : (
        <InputAdornment sx={classes.dateInputIcon} position="end">
          <CalendarAppointmentS fill={theme.palette.secondary.main} />
        </InputAdornment>
      ),
    [clearValue, getValue],
  )

  const fromDateValueString = getValue(fromDateName)
  const toDateValueString = getValue(toDateName)

  const maxDate = newDateWithoutTime(maxDateString)
  const minDate = newDateWithoutTime(minDateString)
  const fromDateValue = fromDateValueString ? newDateWithoutTime(fromDateValueString as string) : null
  const toDateValue = toDateValueString ? newDateWithoutTime(toDateValueString as string) : null

  return (
    <Box sx={classes.cardListItem}>
      <Card data-testid={`filter-card-${configuration.name}`}>
        <Typography variant="caption" sx={classes.cardTitle}>
          {label}
        </Typography>
        <Grid container spacing={2} sx={classes.datePickerContainer}>
          <Grid item xs={6} sx={fromDateValue ? classes.datePickerWithValue : classes.datePickerWithoutValue}>
            <MuiDatePicker
              sx={classes.datePickerFrom}
              label={fromDateValue ? '' : fromDateLabel}
              views={['year', 'month']}
              minDate={minDate}
              maxDate={maxDate}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(data: any, _v: any) => {
                if (data) changeValue(fromDateName, getISOFirstDayOfMonth(data as Date))
              }}
              value={fromDateValue}
              open={openFromDate}
              slots={{
                actionBar: CustomActionBar,
              }}
              slotProps={{
                layout: {
                  sx: {
                    display: 'block',
                  },
                },
                toolbar: {
                  hidden: true,
                },
                textField: {
                  variant: 'standard',
                  sx: { width: '100%' },
                  onFocus(e: React.FocusEvent<HTMLInputElement>) {
                    if (!e.relatedTarget) {
                      setOpenToDate(Boolean(false))
                    }
                  },
                  error: Boolean(error),
                  InputLabelProps: { shrink: false },
                  InputProps: {
                    //@ts-ignore
                    'data-testid': 'filter-date-from',
                    endAdornment: getInputAdornments(fromDateName),
                  },
                },
              }}
              onAccept={() => setOpenFromDate(false)}
              onOpen={() => setOpenFromDate(true)}
              onClose={() => setOpenFromDate(false)}
              data-testid="filter-date-from"
            />
          </Grid>
          <Grid item xs={6} sx={toDateValue ? classes.datePickerWithValue : classes.datePickerWithoutValue}>
            <MuiDatePicker
              sx={classes.datePickerTo}
              label={toDateValue ? '' : toDateLabel}
              views={['year', 'month']}
              minDate={minDate}
              maxDate={maxDate}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(data: any, _v: any) => {
                if (data) changeValue(toDateName, getISOLastDayOfMonth(data as Date))
              }}
              value={toDateValue}
              open={openToDate}
              slots={{
                actionBar: CustomActionBar,
              }}
              slotProps={{
                layout: {
                  sx: {
                    display: 'block',
                  },
                },
                toolbar: {
                  hidden: true,
                },
                textField: {
                  variant: 'standard',
                  sx: { width: '100%' },
                  onFocus(e: React.FocusEvent<HTMLInputElement>) {
                    if (!e.relatedTarget) {
                      setOpenToDate(Boolean(false))
                    }
                  },
                  error: Boolean(error),
                  InputLabelProps: { shrink: false },
                  InputProps: {
                    //@ts-ignore
                    'data-testid': 'filter-date-to',
                    endAdornment: getInputAdornments(toDateName),
                  },
                },
              }}
              onAccept={() => setOpenToDate(false)}
              onOpen={() => setOpenToDate(true)}
              onClose={() => setOpenToDate(false)}
              data-testid="filter-date-to"
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default DateRange
