import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortBy } from 'lodash/fp'
import FilterContainer from 'pages/AbsencePlanner/components/TaskBar/Filters/FilterContainer'
import FiltersCheckBox from 'pages/AbsencePlanner/components/TaskBar/Filters/FiltersCheckBox'
import useStoredFilters from 'pages/Shifts/useStoredFilters'
import { getFullTeamLabel } from 'utils/team'
import { useSideMenuContext } from '../../context'

const MyTeams: React.FC = () => {
  const { t, language } = useFavurTranslation()
  const { teams, selectedTeams, setSelectedTeams, isMultiTenant } = useSideMenuContext()
  const { updateStoredFilter } = useStoredFilters()

  const getChangedSelectedTeams = useCallback(
    (teamId: string, checked: boolean) => {
      if (!selectedTeams) return []
      const selectedTeamsList = (() => {
        if (checked) return [...selectedTeams, teamId]
        return selectedTeams.filter((id: string) => id !== teamId)
      })()

      updateStoredFilter('selectedTeams', selectedTeamsList)
      return selectedTeamsList
    },
    [selectedTeams, updateStoredFilter],
  )

  const onTeamCheckChange = useCallback(
    (teamId: string, checked: boolean) => setSelectedTeams(getChangedSelectedTeams(teamId, checked)),
    [getChangedSelectedTeams, setSelectedTeams],
  )

  const isTeamChecked = useCallback((teamId: string) => selectedTeams && selectedTeams.includes(teamId), [
    selectedTeams,
  ])

  const checkBoxOptions: { label: string; value: string }[] = useMemo(
    () =>
      sortBy(
        'label',
        teams.map((team) => ({ label: getFullTeamLabel(team, language, isMultiTenant), value: team.id })),
      ),
    [isMultiTenant, language, teams],
  )

  return (
    <FilterContainer title={t('page.shifts.sideMenu.myTeams')}>
      {checkBoxOptions.map((option) => {
        return (
          <Box key={option.label} alignItems="center" display="flex">
            <FiltersCheckBox
              key={option.label}
              label={option.label}
              checked={isTeamChecked(option.value)}
              onChange={(event) => {
                const { checked } = event.target
                onTeamCheckChange(option.value, checked)
              }}
            />
          </Box>
        )
      })}
    </FilterContainer>
  )
}

export default MyTeams
