import { performEventToTask } from 'pages/Tasks/queries'
import { EventsOfTask } from 'pages/Tasks/types'
import { MutationResult } from 'types'
import { useMutation } from '@apollo/client'

const usePerformEventToTask = (favurUuid: string, onCompleted?: () => void) => {
  const [performEventToTaskFunction, { loading }] = useMutation<{ performEventToTask: MutationResult }>(
    performEventToTask,
    {
      onCompleted: () => {
        if (onCompleted) onCompleted()
        else window.location.reload()
      },
    },
  )

  const sendCommentToManager = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.absenceRequestSendCommentToManager,
        comment,
      },
    })
  }

  const sendCommentToFrontline = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.absenceRequestSendCommentToFrontline,
        comment,
      },
    })
  }

  const sendDeletionRequest = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.absenceRequestRequestDeletion,
        comment,
      },
    })
  }

  const confirmDeletionRequest = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.absenceRequestAcceptDeletion,
        comment,
      },
    })
  }

  const rejectDeletionRequest = (comment: string) => {
    performEventToTaskFunction({
      variables: {
        favurUuid,
        event: EventsOfTask.absenceRequestRejectDeletion,
        comment,
      },
    })
  }

  return {
    loading,
    sendCommentToManager,
    sendCommentToFrontline,
    sendDeletionRequest,
    confirmDeletionRequest,
    rejectDeletionRequest,
  }
}

export default usePerformEventToTask
