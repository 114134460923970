import React, { useState } from 'react'
import { Box } from '@mui/material'
import useStepper from 'hooks/useStepper'
import routes from 'services/RoutesProvider/routes'
import type { IDefaultComponentWIthStepperProps } from 'types'
import { AdditionalStepsProps } from 'types'

interface IStepperSecureConnetionProps extends IDefaultComponentWIthStepperProps {
  additionalStepsProperties: AdditionalStepsProps
}
const StepperSecureConnection: React.FC<IStepperSecureConnetionProps> = ({
  steps,
  urlStep,
  additionalStepsProperties,
}) => {
  const { activeStep, goToStep, Stepper } = useStepper({ route: routes.securitySetup, step: urlStep, steps })
  const [stepperHidden, setStepperHidden] = useState(false)

  if (!activeStep) {
    return null
  }

  const additionalProperties = additionalStepsProperties[activeStep.name]
  return (
    <>
      {!stepperHidden && <Stepper activeStep={activeStep.index} steps={steps} />}
      <Box>
        <activeStep.component
          goToStep={goToStep}
          hideStepper={setStepperHidden}
          additionalProperties={additionalProperties}
        />
      </Box>
    </>
  )
}

export default StepperSecureConnection
