import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
}
export const headerClasses = {
  subHeader: {
    height: '48px',
    backgroundColor: `${theme.palette.common.white} !important`,
    // @ts-ignore
    color: `${theme.palette.primary[900]}  !important`,
  },
}

export const formClasses = {
  container: {
    marginBottom: `${theme.spacing(0.75)} !important`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  countryCode: {
    minWidth: theme.spacing(2),
    textAlign: 'right',
    paddingBottom: theme.spacing(0.25),
  },
}
