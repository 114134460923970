import { useState, useContext } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { teamWithPermissionsQuery } from 'pages/Teams/queries'
import { getPersonsByPermissions } from 'pages/Teams/utils'
import { useHistory } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { TeamT, PersonT } from 'types'
import { gql, ApolloError } from '@apollo/client'
import { PersonByPermissionsT } from '../types'

export const getTeamMembers = (team: TeamT | undefined) => {
  if (!team?.teamPermissions) {
    return []
  }

  return team.teamPermissions.map((tp) => tp.person as PersonT)
}

export const useTeam = (
  uuid: string,
): {
  team: TeamT | undefined
  personsByPermission: PersonByPermissionsT
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
} => {
  const [loading, setLoading] = useState(true)
  const [team, setTeam] = useState<TeamT | undefined>()
  const [personsByPermission, setPersonsByPermissions] = useState<Map<string, PersonT[]>>(new Map<string, PersonT[]>())
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const history = useHistory()

  const onCompleteTeams = (data: { getTeam: TeamT }) => {
    const fetchedTeam = data?.getTeam
    setTeam(fetchedTeam)

    const personTeamPermissions = fetchedTeam.teamPermissions ?? []

    const personByPermisions = getPersonsByPermissions(personTeamPermissions)
    setPersonsByPermissions(personByPermisions)
    setLoading(false)
  }

  const errorRedirect = () => {
    setFlashMessage('common.error.be.default')
    history.push(`${routes.teams}`)
  }

  const { error, refetch } = useJamesApolloQuery<{
    getTeam: TeamT
  }>(
    gql`
      ${teamWithPermissionsQuery}
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: uuid,
      },
      errorPolicy: 'all',
      onCompleted: onCompleteTeams,
      onError: errorRedirect,
    },
  )

  return { team, personsByPermission, loading, error, refetch }
}
