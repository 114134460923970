import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { taskContainerClasses } from './styles'

interface TaskContainerProps {
  children: ReactNode
  title: string
  divideBottom?: boolean
}

const FilterContainer: React.FC<TaskContainerProps> = ({ children, title, divideBottom = true }) => {
  return (
    <Box sx={taskContainerClasses.taskContainer}>
      <Typography variant="caption" sx={taskContainerClasses.titleTask}>
        {title}
      </Typography>
      {children}
      {divideBottom && <DivisionLine />}
    </Box>
  )
}

export default FilterContainer
