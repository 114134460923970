import favurTheme from 'utils/theme'

export const classes = {
  footer: {
    position: 'absolute',
    bottom: favurTheme.spacing(1),
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'row',
    paddingLeft: favurTheme.spacing(0.75),
  },
  paperContainer: {
    transition: 'height .25s ease-in-out',
    flexDirection: 'column',
    display: 'flex',
    padding: favurTheme.spacing(2, 1),
    paddingTop: favurTheme.spacing(1.25),
    flex: 1,
  },
  icon: {
    color: favurTheme.palette.common.white,
    filter: `drop-shadow( ${favurTheme.shadows[4]} )`,
  },
  paperMenu: {
    boxShadow: favurTheme.shadows[6],
    top: 'unset !important',
    left: `${favurTheme.spacing(0.75)} !important`,
    bottom: favurTheme.spacing(3),
  },
  listItem: {
    width: '100%',
    minHeight: favurTheme.spacing(1),
    padding: favurTheme.spacing(0.5, 1.25),
  },
  itemTypography: {
    color: favurTheme.palette.primary.main,
    fontSize: '12px',
    letterSpacing: '0.7px',
    lineHeight: 1.25,
  },
  text: {
    marginTop: favurTheme.spacing(0.5),
  },
  intro: {
    marginTop: favurTheme.spacing(1),
  },
  centeredBox: {
    textAlign: 'center',
  },
  header: {
    position: 'relative',
    marginBottom: favurTheme.spacing(2),
  },
  headerSmallDevice: {
    display: 'flex',
    marginBottom: favurTheme.spacing(2),
  },
  actions: {
    textAlign: 'left',
    marginBottom: favurTheme.spacing(1.25),
  },
  actionsNoMargin: {
    alignItems: 'start',
    textAlign: 'left',
    marginBottom: 0,
    display: 'flex',
    flex: 1,
  },
  imageContainer: {
    height: '109px',
    margin: '0 auto',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  smallImageContainer: {
    height: '61px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      height: '100%',
    },
  },
  heading: {
    marginBottom: favurTheme.spacing(2),
  },
  collaborationText: {
    color: favurTheme.palette.common.white,
  },
  outsideCentered: {
    paddingTop: favurTheme.spacing(0.75),
    textAlign: 'center',
  },
  autoFillForm: {
    display: 'flex',
    flexGrow: 1,
  },
}
