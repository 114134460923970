import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const FaceIdAndroidL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M16 29.8679c7.659 0 13.8679-6.2089 13.8679-13.8679C29.8679 8.34096 23.659 2.13208 16 2.13208 8.34096 2.13208 2.13208 8.34096 2.13208 16c0 7.659 6.20888 13.8679 13.86792 13.8679ZM16 31c8.2843 0 15-6.7157 15-15 0-8.28427-6.7157-15-15-15C7.71573 1 1 7.71573 1 16c0 8.2843 6.71573 15 15 15Z"
      />
      <path
        fillRule="evenodd"
        d="M18.5458 23.9454c.1449.3119.0094.6821-.3024.8269-1.4414.6693-2.8694.7922-4.0395.2551-1.1868-.5447-1.9605-1.6999-2.2147-3.2673l1.2292-.1993c.2054 1.2663.7854 2.0046 1.505 2.3349.7363.338 1.7672.3175 2.9955-.2528.3119-.1449.6821-.0094.8269.3025ZM16.8491 17.6982V15.151h1.132v3.6792h-3.1132v-1.132h1.9812Z"
      />
      <path d="M23.9246 12.3208c0 .6252-.5069 1.1321-1.1321 1.1321-.6253 0-1.1321-.5069-1.1321-1.1321 0-.6252.5068-1.1321 1.1321-1.1321.6252 0 1.1321.5069 1.1321 1.1321ZM10.3396 12.3208c0 .6252-.50686 1.1321-1.13209 1.1321-.62522 0-1.13207-.5069-1.13207-1.1321 0-.6252.50685-1.1321 1.13207-1.1321.62523 0 1.13209.5069 1.13209 1.1321Z" />
    </Svg>
  )
}

export default FaceIdAndroidL
