import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const DropdownDownThinS: React.FC<IconPropsT> = ({ fill = 'black', ...props }) => {
  return (
    <Svg size={ICON_SIZE_S} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1464 5.14646L12.8536 5.85356C13.0488 6.04882 13.0488 6.36541 12.8536 6.56067L8.35355 11.0607C8.15829 11.2559 7.84171 11.2559 7.64645 11.0607L3.14645 6.56067C2.95118 6.36541 2.95118 6.04882 3.14645 5.85356L3.85355 5.14646C4.02711 4.97288 4.29653 4.95359 4.4914 5.08859L4.56065 5.14647L8 8.58601L11.4394 5.14647C11.6129 4.97289 11.8823 4.95359 12.0772 5.08858L12.1464 5.14646Z"
        fill={fill}
      />
    </Svg>
  )
}

export default DropdownDownThinS
