import React, { useState } from 'react'
import { TextField, IconButton, Box } from '@mui/material'
import { CloseS, SearchS } from 'icons'
import { debounce } from 'lodash/fp'
import { classes } from './styles'

interface ISearchTextFieldProps {
  onChange: (text: string) => void
  label: string
}

const SearchTextField: React.FC<ISearchTextFieldProps> = ({ label, onChange }) => {
  const [searchText, setSearchText] = useState<string>('')
  const debouncedOnChange = debounce(600, onChange)

  const icon = (
    <Box sx={classes.icon}>
      {searchText ? (
        <IconButton
          sx={classes.iconClose}
          onClick={() => {
            setSearchText('')
            onChange('')
          }}
          size="large"
        >
          <CloseS />
        </IconButton>
      ) : (
        <Box sx={classes.iconSearch}>
          <SearchS fill="white" />
        </Box>
      )}
    </Box>
  )

  return (
    <TextField
      variant="standard"
      label={searchText.length > 0 ? '' : label}
      type="search"
      fullWidth
      sx={classes.searchTextField}
      value={searchText}
      onChange={(e) => {
        const text = e.target.value
        setSearchText(text)
        debouncedOnChange(text)
      }}
      InputProps={{
        endAdornment: icon,
      }}
      InputLabelProps={{ shrink: false }}
    />
  )
}

export default SearchTextField
