import theme from 'utils/theme'

export const classes = {
  root: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(1.5)})`,
    maxWidth: `calc(${theme.breakpoints.values.sm}px - ${theme.spacing(1.5)})`,
    margin: '0 auto',
    top: `calc(${theme.spacing(0.75)} + var(--ion-safe-area-top))`,
  },
  rootAuthenticated: {
    top: theme.spacing(4.25),
  },
  content: {
    borderRadius: 6,
    backgroundColor: theme.palette.grey[900],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: '6px 16px',
    color: theme.palette.common.white,
    width: '100%',
  },
  contentInfo: {
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'flex-end',
    padding: `${theme.spacing(0.375)} ${theme.spacing(1.0)}`,
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    width: '100%',
  },
  message: {},
  action: {
    paddingLeft: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.5),
  },
  deleteIconButton: {
    marginRight: theme.spacing(0.25),
    marginBottom: '2px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}
