import { navPointIds, NavPointIdT, NavPointT } from 'components/Page/Header/Toolbar/Menu/types'
import {
  Documents,
  TeamsL,
  NotificationsM,
  LogoutM,
  UserSettingsL,
  TaskCenterL,
  ManagerTasksL,
  HomeL,
  AbsencePlanL,
  NewCalendarL,
  TimebalanceL,
  CompanyNewsL,
  CompanyPagesL,
} from 'icons'
import routes from 'services/RoutesProvider/routes'
import { ROLE_VIEWS } from './roles'

const dashboard: NavPointT = {
  id: navPointIds.dashboard,
  label: 'navigation.main.menu.dashboard',
  to: routes.dashboard,
  icon: HomeL,
  testId: 'primary-navigation-dashboard',
}

const shifts: NavPointT = {
  id: navPointIds.shifts,
  label: 'navigation.main.menu.shifts',
  labelShort: 'navigation.main.menu.shiftsShort',
  to: routes.shifts,
  icon: NewCalendarL,
  testId: 'primary-navigation-shifts',
  role: ROLE_VIEWS.frontliner,
}

const balances: NavPointT = {
  id: navPointIds.balances,
  label: 'navigation.main.menu.balance',
  to: routes.balances,
  icon: TimebalanceL,
  testId: 'primary-navigation-balances',
  role: ROLE_VIEWS.frontliner,
}

const settings: NavPointT = {
  id: navPointIds.settings,
  label: 'navigation.main.menu.userSettings',
  labelShort: 'navigation.main.menu.settings',
  to: routes.userSettings,
  icon: UserSettingsL,
  nativeIcon: UserSettingsL,
  testId: 'secondary-navigation-settings',
  role: ROLE_VIEWS.frontliner,
}

const notifications: NavPointT = {
  id: navPointIds.notifications,
  label: 'page.notifications.header.title',
  to: routes.notifications,
  icon: NotificationsM,
  testId: 'secondary-navigation-notifications',
  role: ROLE_VIEWS.frontliner,
}

const documents: NavPointT = {
  id: navPointIds.documents,
  label: 'page.documents.page.title',
  to: routes.documents,
  icon: Documents,
  testId: 'primary-navigation-documents',
  role: ROLE_VIEWS.frontliner,
}

const tasks: NavPointT = {
  id: navPointIds.tasks,
  label: 'page.tasks.page.title',
  to: routes.tasks,
  icon: TaskCenterL,
  testId: 'primary-navigation-tasks',
  role: ROLE_VIEWS.frontliner,
}

const managerTasks: NavPointT = {
  id: navPointIds.managerTasks,
  labelShort: 'navigation.main.menu.managerTasksShort',
  label: 'navigation.main.menu.managerTasks',
  to: routes.tasks,
  icon: ManagerTasksL,
  testId: 'primary-navigation-manager-tasks',
  role: ROLE_VIEWS.office,
}

const personalTasks: NavPointT = {
  id: navPointIds.personalTasks,
  labelShort: 'navigation.main.menu.personalTasksShort',
  label: 'navigation.main.menu.personalTasks',
  to: routes.tasks,
  icon: TaskCenterL,
  testId: 'primary-navigation-personal-tasks',
  role: ROLE_VIEWS.frontliner,
}

const teams: NavPointT = {
  id: navPointIds.teams,
  label: 'navigation.main.menu.teams',
  labelShort: 'navigation.main.menu.teams',
  to: routes.teams,
  icon: TeamsL,
  testId: 'primary-navigation-teams',
  role: ROLE_VIEWS.office,
}

const logout: NavPointT = {
  id: navPointIds.logout,
  label: 'navigation.main.menu.logout',
  to: `#`,
  icon: LogoutM,
  testId: 'secondary-navigation-logout',
}
const contact: NavPointT = {
  id: navPointIds.contact,
  label: 'navigation.main.info.contact',
  to: '#',
  testId: 'tertiary-navigation-contact',
}

const appInfo: NavPointT = {
  id: navPointIds.appInfo,
  label: 'navigation.main.info.application',
  to: '#',
  testId: 'tertiary-navigation-app-info',
}

const privacy: NavPointT = {
  id: navPointIds.privacy,
  label: 'navigation.main.info.privacy',
  to: '#',
  testId: 'tertiary-navigation-privacy-policy',
}

const absencePlanner: NavPointT = {
  id: navPointIds.absencePlanner,
  label: 'navigation.main.info.absencePlanner',
  labelShort: 'navigation.main.info.absencePlannerShort',
  to: routes.absencePlanner,
  icon: AbsencePlanL,
  testId: 'primary-navigation-absence-planner',
  role: ROLE_VIEWS.frontliner,
}

const content: NavPointT = {
  id: navPointIds.content,
  label: 'navigation.main.menu.content',
  labelShort: 'navigation.main.menu.content',
  to: routes.cmsContentIndex,
  icon: CompanyPagesL,
  testId: 'primary-navigation-content',
  role: ROLE_VIEWS.frontliner,
}

const cmsOverview: NavPointT = {
  id: navPointIds.cmsOverview,
  label: 'navigation.main.menu.company.overview',
  labelShort: 'navigation.main.menu.company.overview',
  to: routes.cmsOverview,
  icon: CompanyNewsL,
  testId: 'primary-navigation-content',
  role: ROLE_VIEWS.frontliner,
}

const navigation: { [key in NavPointIdT]: NavPointT } = {
  balances,
  dashboard,
  documents,
  notifications,
  settings,
  shifts,
  tasks,
  managerTasks,
  personalTasks,
  teams,
  logout,
  contact,
  appInfo,
  privacy,
  absencePlanner,
  content,
  cmsOverview,
}

export default navigation
