import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { IActionButton } from 'pages/SecuritySetup/types'

const Confirm: React.FC<IActionButton> = ({ onClick, disabled }) => {
  const { t } = useFavurTranslation()

  return (
    <Button variant="contained" color="secondary" onClick={onClick} disabled={disabled} data-testid="set-pin-confirm">
      {t('secureConnection.setPin.buttons.confirm')}
    </Button>
  )
}

export default Confirm
