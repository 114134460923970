import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AlertS } from 'icons'
import { classes } from 'pages/Teams/styles'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import { getUserName } from 'utils/person'
import theme from 'utils/theme'

const isResigned = (person: PersonT) =>
  person.workWithdrawal && person.workWithdrawal < dateToISOStringWithoutTime(newDateWithoutTime())

interface ITeamPermissionProps {
  permissionName: string
  persons: PersonT[]
  teamUuid: string
}

const TeamPermission: React.FC<ITeamPermissionProps> = ({ permissionName, persons, teamUuid }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Card key={permissionName} marginBottom="16px" data-testid="team-workflow-manager-item">
      <Box sx={classes.settingsCard}>
        <Typography variant="subtitle1">{t(`teams.permissions.${permissionName}.title`)}</Typography>
        <Typography variant="body2">{t(`teams.permissions.${permissionName}.description`)}</Typography>
        <DivisionLine />

        <Box>
          {persons.length > 0 ? (
            <Box component="ul" sx={classes.workflowPersonsList}>
              {persons.map((person) => {
                return (
                  <li key={person.id}>
                    <Typography variant="body2" sx={classes.workflowPersonListItemText}>
                      {getUserName(person)}
                    </Typography>
                    {isResigned(person) && (
                      <Typography variant="caption" sx={classes.alertCaption}>
                        <span>{t('team.permissions.resignedManager')}</span>
                        <AlertS />
                      </Typography>
                    )}
                  </li>
                )
              })}
            </Box>
          ) : (
            <Box sx={classes.noPersonInWorkflow}>
              <Typography variant="caption" sx={classes.noPersonInWorkflowText}>
                {t('teams.permissions.noEmployee')}
              </Typography>
              <AlertS fill={theme.palette.secondary.main} />
            </Box>
          )}
        </Box>
        <Box sx={classes.teamPermissionEditButton}>
          <Button
            type="submit"
            size="small"
            variant="text"
            color="secondary"
            onClick={() =>
              history.push(`${routes.teams}/${teamUuid}/${routes.teamEditPermissionMembers}/${permissionName}`)
            }
          >
            {t(`teams.permissions.buttonEdit`)}
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default TeamPermission
