import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const SearchS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        d="M8.3123 10.314C10.4953 9.41249 11.5128 6.96208 10.5851 4.84085C9.65736 2.71962 7.13562 1.73083 4.95264 2.63233C2.76966 3.53384 1.75209 5.98425 2.67983 8.10548C3.60757 10.2267 6.12932 11.2155 8.3123 10.314Z"
        stroke="#FF007C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76929 9.76929L13.0001 13.0001"
        stroke="#FF007C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SearchS
