import React, { memo } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { gridClasses } from '../styles'

interface ISmallScreenGradientProps {
  isPreview?: boolean
}

const SmallScreenGradient: React.FC<ISmallScreenGradientProps> = ({ isPreview }) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  if (!isSmallScreen) return null
  return <Box sx={gridClasses.smallScreenGradient(isPreview)} />
}

export default memo(SmallScreenGradient)
