import React from 'react'
import { Box } from '@mui/material'
import ControlledRadioGroup from 'components/Forms/components/ControlledRadioGroup'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { ColorModesT, colorModes } from '../../types'
import FilterContainer from '../FilterContainer'
import { colorChangerClasses } from './styles'

const options = {
  [colorModes.colorful]: 'absencePlanner.taskbar.colorChanger.colorful',
  [colorModes.greyscale]: 'absencePlanner.taskbar.colorChanger.greyscale',
}

const ColorChanger: React.FC = () => {
  const { t } = useFavurTranslation()
  const { greyscaleMode, setGreyscaleMode } = useFilterContext()

  const onChange = (newValue: string) => setGreyscaleMode(newValue as ColorModesT)

  return (
    <FilterContainer title={t('absencePlanner.taskbar.colorChanger.title')}>
      <Box sx={colorChangerClasses.radioGroupContainer}>
        <ControlledRadioGroup
          value={`${greyscaleMode}`}
          options={options}
          name="colorChanger"
          defaultValue="true"
          onChange={onChange}
          column
        />
      </Box>
    </FilterContainer>
  )
}
export default ColorChanger
