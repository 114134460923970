import theme from 'utils/theme'

export const classes = {
  block: {
    display: 'flex',
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(1),
    paddingLeft: '2px',
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gutterBottom: {
    marginBottom: '6px',
  },
  noFlexGrow: {
    '&:last-child': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flexGrow: '0 !important' as any,
    },
  },
  bird: {
    marginTop: theme.spacing(0.25),
    marginRight: '2px !important',
    width: `${theme.spacing(2.25)} !important`,
  },
  sizeM: {
    marginLeft: '6px',
  },
  anonymous: {
    boxShadow: 'none !important',
    marginBottom: '0',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    width: theme.spacing(1.5),
  },
}
