import React, { memo, useMemo } from 'react'
import { Typography } from '@mui/material'
import { addMonths, format, Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getShortMonthFormat } from 'utils/date'
import { monthSwitcherBarClasses } from '../styles'

interface IMonthIndicatorProps {
  shownMonthIndex: number
  baseDate: Date
}

const MonthIndicator: React.FC<IMonthIndicatorProps> = ({ shownMonthIndex, baseDate }) => {
  const { locale } = useFavurTranslation()
  const currentDate = useMemo(() => (shownMonthIndex !== undefined ? addMonths(baseDate, shownMonthIndex) : baseDate), [
    baseDate,
    shownMonthIndex,
  ])

  return (
    <Typography variant="body2" sx={monthSwitcherBarClasses.dateText} data-testid="absence-planner-shown_month">
      {format(currentDate, getShortMonthFormat(locale as Locale), { locale })}
    </Typography>
  )
}

export default memo(MonthIndicator)
