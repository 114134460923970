import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { pinResetSteps } from '../constants'
import { InitialSetupStepName } from '../types'
import PinResetProcess from './PinResetProcess'

const SecuritySetup: React.FC<RouteComponentProps<{ step?: InitialSetupStepName }>> = ({ match }) => {
  const { step: urlStep } = match.params

  return <PinResetProcess steps={pinResetSteps} urlStep={urlStep} />
}

export default SecuritySetup
