import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledHourglassM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        d="M11.9992 12C14.7999 12 16 13.6 16 16H8c0-2.4 1.1988-4 3.9992-4ZM12.0008 12C9.2001 12 8 10.4 8 8h8c0 2.4-1.1988 4-3.9992 4Z"
      />
      <rect width="10" height="1" x="7" y="6" fill="#fff" rx=".5" />
      <rect width="10" height="1" x="7" y="17" fill="#fff" rx=".5" />
    </Svg>
  )
}

export default FilledHourglassM
