import { Locale } from 'date-fns'
import { getDateFormatByLocale, getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { getAPIURL } from '../../../constants/environment'
import { BLOCK_TYPE_PATH, PUBLISHER_URI_PATH } from '../constants'
import type { GetBlockSrc } from '../types'

export const getContentViewDate = ({ locale, date }: { locale?: Locale; date?: string }) => {
  const dateFormat = getDateFormatByLocale(locale as Locale, { withDayOfWeek: false, withYear: true })

  return getFormattedFromDate(newDateWithoutTime(date), dateFormat, locale)
}

export const getBlockSrc = ({ filename, type }: GetBlockSrc) =>
  filename ? `${getAPIURL()}${PUBLISHER_URI_PATH}${BLOCK_TYPE_PATH[type]}/${filename}` : ''
