import React from 'react'
import {
  ACTION,
  ActionDispatchT,
  CheckListT,
  FilterPageStateT,
  FilterStateT,
  FilterStateValueT,
  ReducerActionT,
  SetScrollToActionT,
  ToggleOptionActionT,
} from 'components/Filter/types'

const isCheckedOption = (filters: FilterStateT, checkListName: string, value: string): boolean => {
  if (!filters[checkListName]) return false

  const values = filters[checkListName] as string[]
  return values.some((v) => v === value)
}

const toggleOption = (filters: FilterStateT, checkListName: string, optionName: string): FilterStateT => {
  const { [checkListName]: values, ...filtersWithoutChecklist } = filters

  if (!values) {
    // filter element not found, we add the value
    return { ...filtersWithoutChecklist, [checkListName]: [optionName] }
  }

  const castedValues = values as string[]
  const containsValue = castedValues.some((v) => v === optionName)
  if (containsValue) {
    const withoutValue = castedValues.filter((v) => v !== optionName)
    if (!withoutValue.length) {
      // filter contains the value, but is empty after removing the value, so we remove the filter entirely
      return filtersWithoutChecklist
    }

    // filter contains the value, so we remove it and leave the others in place
    return { ...filtersWithoutChecklist, [checkListName]: withoutValue }
  }

  // filter does not contain the value, we add the value to the existing values
  return { ...filtersWithoutChecklist, [checkListName]: [optionName, ...castedValues] }
}

export const actions = (filterPageState: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => ({
  toggleOption: (filterName: string, optionName: string) => {
    dispatch({ type: ACTION.TOGGLE_OPTION, value: { filterName, optionName } })
  },
  isCheckedOption: (filterName: string, optionName: string) => {
    return isCheckedOption(filterPageState.dirtyFilters, filterName, optionName)
  },
  getScrollTo: () => filterPageState.scrollTo,
  setScrollTo: (scrollTo: string | undefined) => {
    dispatch({ type: ACTION.SET_SCROLL_TO, value: { destination: scrollTo } })
  },
})

export const reducers: ActionDispatchT = {
  [ACTION.TOGGLE_OPTION]: (state, action) => {
    const actionValue = action.value as ToggleOptionActionT
    const updatedFilters = toggleOption(state.dirtyFilters, actionValue.filterName, actionValue.optionName)

    return {
      ...state,
      dirtyFilters: updatedFilters,
    }
  },
  [ACTION.SET_SCROLL_TO]: (state, action) => {
    const actionValue = action.value as SetScrollToActionT
    return {
      ...state,
      scrollTo: actionValue.destination,
    }
  },
}

export const allCheckboxesSelected = (filterConfig: CheckListT, value: FilterStateValueT): boolean =>
  (value as string[]).length === filterConfig.options.length
