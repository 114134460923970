import React from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { taskBarStates } from '../../types'
import { absenceRequestListClasses } from '../styles'
import NewAbsenceButton from './NewAbsenceButton'

interface IAbsenceRequestListFooterProps {
  onNew: () => void
}

const AbsenceRequestListFooter: React.FC<IAbsenceRequestListFooterProps> = ({ onNew }) => {
  const { taskBarState } = useTaskBarStateContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()

  return (
    <Box sx={absenceRequestListClasses.footerContainer}>
      {taskBarState === taskBarStates.userTasks && !isSmallScreen && <NewAbsenceButton onNew={onNew} />}
    </Box>
  )
}

export default AbsenceRequestListFooter
