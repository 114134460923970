import { PersonT, TenantT } from 'types'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'

export const getFormattedLastSharedData = (person: PersonT) =>
  person.lastSharedData ? getFormattedFromISOString(person.lastSharedData, uiDateFormat, undefined) : '-'

export const getUserDataCollectionLastUpdate = (persons: PersonT[]) => {
  if (persons.length === 0) {
    return '-'
  }
  const { user } = persons[0]

  const date = user?.userDataCollectionLastUpdate
  return date ? getFormattedFromISOString(date, uiDateFormat, undefined) : '-'
}

export const getSelectedPersonIds = (selectedPersons: Map<number, boolean>): number[] => {
  return Array.from(selectedPersons.keys()).reduce((selected, personId) => {
    if (selectedPersons.get(personId)) {
      return [...selected, personId]
    }
    return selected
  }, [] as number[])
}

export const getSelectedTenants = (persons: PersonT[], selectedPersons: Map<number, boolean>): TenantT[] => {
  const selectedPersonIds = getSelectedPersonIds(selectedPersons)
  return persons.reduce((tenants, person) => {
    if (selectedPersonIds.includes(person.id as number)) {
      return [...tenants, person.tenant as TenantT]
    }
    return tenants
  }, [] as TenantT[])
}
