import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStepper from 'hooks/useStepper'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import type { IDefaultComponentWIthStepperProps } from 'types'
import { classes } from '../styles'

const PinChangeProcess: React.FC<IDefaultComponentWIthStepperProps> = ({ steps, urlStep }) => {
  const { t } = useFavurTranslation()
  const { activeStep, goToStep, Stepper } = useStepper({ route: routes.pinChange, step: urlStep, steps })
  const [stepperHidden, setStepperHidden] = useState(false)
  const history = useHistory()

  if (!activeStep) {
    return null
  }

  return (
    <Page hideNativeNavigation hideBackground noPadding flex hideHamburger>
      {stepperHidden ? null : <Stepper activeStep={activeStep.index} steps={steps} />}
      <Box sx={classes.componentContainer}>
        <activeStep.component goToStep={goToStep} hideStepper={setStepperHidden} />
      </Box>
      <Box sx={classes.cancelButtonContainer}>
        <Button
          onClick={() => history.push(routes.profile)}
          sx={classes.cancelButtonPinChange}
          data-testid="cancel-pin-change-process"
        >
          {t('securitySetup.intro.button.cancel')}
        </Button>
      </Box>
    </Page>
  )
}

export default PinChangeProcess
