import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'

interface IInfoDialogProps {
  open: boolean
  text: string
  title?: string
  action: () => void
}

const InfoDialog: React.FC<IInfoDialogProps> = ({ open, text, action, title }) => {
  const { t } = useFavurTranslation()
  return (
    <Dialog
      title={title}
      open={open}
      actions={
        <>
          <Button onClick={action} color="secondary" size="large">
            {t('common.dialog.button.close')}
          </Button>
        </>
      }
    >
      <Typography variant="body2">{text}</Typography>
    </Dialog>
  )
}

export default InfoDialog
