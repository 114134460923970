import theme from 'utils/theme'

export const classes = {
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    '& img': {
      width: theme.spacing(12.5),
      marginBottom: theme.spacing(1.25),
    },
  },
  title: {
    marginBottom: theme.spacing(0.625),
    color: theme.palette.primary.main,
  },
  text: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: theme.spacing(16),
    textAlign: 'center',
  },
  topText: {
    marginBottom: theme.spacing(1.5),
  },
  button: {
    marginTop: theme.spacing(3),
    textDecoration: 'none',
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    fontWeight: 'bold',
    padding: theme.spacing(0.5625, 1.5),
    borderRadius: theme.spacing(1.125),
    textTransform: 'uppercase',
    fontSize: theme.spacing(0.875),
    letterSpacing: '0.9px',
  },
  openMoreDetailsButton: {
    justifyContent: 'flex-start',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.7px',
    paddingLeft: 0,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    marginBottom: theme.spacing(0.75),
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  arrowIconContainer: { marginLeft: theme.spacing(0.25) },
  errorTextContainer: {
    borderRadius: '6px',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    width: '90%',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
  },
  errorMessageBlock: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorTextRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
