import React, { forwardRef } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { scrollerDivId } from '..'
import { gridClasses } from '../styles'

interface IMonthScrollerContainerProps {
  children: React.ReactNode
  onScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void
}

export const MonthScrollerContainer = forwardRef<unknown, IMonthScrollerContainerProps>(
  ({ children, onScroll }, ref) => {
    const { taskBarState } = useTaskBarStateContext()
    const { avatarBarExpanded } = useAvatarSidebarStateContext()
    const { isSmallScreen } = useAbsencePlannerStateContext()

    return (
      <Box
        ref={ref}
        id={scrollerDivId}
        sx={[gridClasses.monthScroller(Boolean(taskBarState), avatarBarExpanded, isSmallScreen)]}
        onScroll={(event) => onScroll(event)}
        data-testid="absence-planner-scroll_container"
      >
        {children}
      </Box>
    )
  },
)

export default MonthScrollerContainer
