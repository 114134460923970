import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CheckMarkCircleM, NoInfoM, RemoveM } from 'icons'
import { ValidationStatusT, validationStatuses } from 'pages/EmployeeCard/types'
import { timestampFormat } from 'utils/constants'
import { getFormattedFromDate } from 'utils/date'
import palette from 'utils/theme/palette'
import ValidationDivider from './Divider'
import { animationClasses, backgroundColors, classes, textColors } from './styles'

const TIME_TO_STATUS_CHANGE = 550

export type ValidationStatusConfigT = {
  color: CSSProperties
  label: string
  background: CSSProperties
  icon: React.JSX.Element
  showLastUpdated: boolean
  testId: string
}
const statusConfigMap: Record<ValidationStatusT, ValidationStatusConfigT> = {
  active: {
    color: textColors.active,
    label: 'employeeCard.validationCard.status.active',
    background: backgroundColors.active,
    icon: <CheckMarkCircleM fill={palette.success.dark} />,
    showLastUpdated: true,
    testId: 'active',
  },
  inactive: {
    color: textColors.inactive,
    label: 'employeeCard.validationCard.status.inactive',
    background: backgroundColors.inactive,
    icon: <RemoveM fill={palette.error.main} />,
    showLastUpdated: false,
    testId: 'inactive',
  },
  notValidated: {
    color: textColors.notValidated,
    label: 'employeeCard.validationCard.status.notValidated',
    background: backgroundColors.notValidated,
    icon: <NoInfoM fill={palette.primary.main} />,
    showLastUpdated: false,
    testId: 'notValidated',
  },
  noInfo: {
    color: textColors.noInfo,
    label: 'employeeCard.validationCard.status.noInfo',
    background: backgroundColors.noInfo,
    icon: <NoInfoM fill={palette.grey[600]} />,
    showLastUpdated: false,
    testId: 'noInfo',
  },
}

export interface IValidationCardProps {
  status: ValidationStatusT
  animating: boolean
  setAnimating: (animate: boolean) => void
  lastUpdated: Date
}

const ValidationCard: React.FC<IValidationCardProps> = ({ status, animating, setAnimating, lastUpdated }) => {
  const { t, locale } = useFavurTranslation()
  const [currentStatus, setCurrentStatus] = useState(validationStatuses.notValidated)
  const currentStatusConfig = useMemo(() => statusConfigMap[currentStatus], [currentStatus])

  const formattedLastUpdated = useMemo(() => getFormattedFromDate(lastUpdated, timestampFormat, locale), [
    lastUpdated,
    locale,
  ])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentStatus(status)
    }, TIME_TO_STATUS_CHANGE)

    return () => {
      clearTimeout(timeout)
    }
  }, [status])

  return (
    <Box
      sx={[classes.wrapper, currentStatusConfig.background]}
      data-testid={`employee-card_validation-status_${currentStatusConfig.testId}`}
    >
      <Box
        sx={[classes.slider, statusConfigMap[status].background, ...(animating ? [animationClasses.slider] : [])]}
        onAnimationEnd={() => {
          setAnimating(false)
        }}
      />
      <ValidationDivider status={currentStatus} />
      <Box sx={[classes.statusMessageWrapper, ...(animating ? [animationClasses.text] : [])]}>
        <Box sx={[classes.statusMessage, currentStatusConfig.color]}>
          <Typography variant="body1" sx={classes.bold}>
            {t(currentStatusConfig.label)}
          </Typography>
          <Box sx={[classes.iconContainer, ...(animating ? [animationClasses.icon] : [])]}>
            {currentStatusConfig.icon}
          </Box>
        </Box>
        <Box sx={[...(animating ? [animationClasses.updatedAt] : [])]}>
          <Typography
            data-testid="employee-card_validation-card_last-updated"
            variant="caption"
            sx={[classes.lastUpdated, ...(currentStatusConfig.showLastUpdated ? [] : [classes.noLastUpdate])]}
          >
            {`${t('employeeCard.validationCard.status.lastUpdated')} ${formattedLastUpdated}`}
          </Typography>
        </Box>
      </Box>
      <ValidationDivider status={currentStatus} />
    </Box>
  )
}
export default ValidationCard
