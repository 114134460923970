import React from 'react'
import { Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import ContactForm from 'components/Forms/Contact'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useParams } from 'react-router-dom'
import { UserDataUrlParamsT } from '../../types'
import { userDataFormClasses as classes } from './styles'

const Contact: React.FC<{ goToStep: (stepName: string, additionalUrlParams?: string[]) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const { favurUuid } = useParams<UserDataUrlParamsT>()

  return (
    <CenteredBox sx={classes.container}>
      <Typography variant="h2" sx={classes.heading}>
        {t('component.forms.contact.title')}
      </Typography>
      <Typography variant="caption" sx={classes.subHeading}>
        {t('component.forms.mandatoryField')}
      </Typography>
      <ContactForm onCancel={() => goToStep('family', [favurUuid])} accessPoint="shareUserDataWorkflow" />
    </CenteredBox>
  )
}

export default Contact
