import React from 'react'
import { Box } from '@mui/material'
import AssetCheckmark from 'icons/AssetCheckmarkSvg'
import { classes } from './styles'

export const defaultSuccessPageTimeout = 5000

interface ISuccessGenericProps {
  children?: React.ReactNode
  headerTitle?: React.ReactElement
  testId?: string
}

const SuccessGeneric: React.FC<ISuccessGenericProps> = ({ children, headerTitle = null, testId }) => {
  return (
    <Box sx={classes.successContainer} data-testid={testId}>
      {headerTitle}
      <AssetCheckmark />
      {children}
    </Box>
  )
}

export default SuccessGeneric
