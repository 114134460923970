import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_XL } from './constants'
import { IconPropsT } from './types'

const NotificationsXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M24 7.188a1 1 0 0 1 1 1v2.6a12.872 12.872 0 0 1 11.875 12.837c0 5.515.588 8.67 1.14 10.412.276.869.54 1.375.71 1.645.085.134.146.21.174.242l.009.01.004.004a1 1 0 0 1-.662 1.75H28.143a4.303 4.303 0 0 1-8.269.031l-.006-.02-.003-.011H9.75a1 1 0 0 1-.795-1.607l.004-.006a4.751 4.751 0 0 0 .269-.468c.194-.385.47-1.021.75-1.973.562-1.904 1.147-5.072 1.147-10.009A12.875 12.875 0 0 1 23 10.789V8.188a1 1 0 0 1 1-1Zm-7.69 8.747a10.875 10.875 0 0 1 18.565 7.69c0 5.641.6 9.017 1.234 11.017.127.4.256.746.382 1.045H11.383c.165-.406.34-.899.514-1.487.625-2.12 1.228-5.483 1.228-10.575 0-2.884 1.146-5.65 3.185-7.69Zm9.673 21.752h-3.958a2.306 2.306 0 0 0 3.958 0Zm2.068-21.686A1 1 0 0 0 27.95 18c.549.028.947.123 1.281.279.335.156.663.4 1.037.803.271.291.544.767.706 1.3.164.541.173 1.009.075 1.312a1 1 0 0 0 1.904.614c.267-.828.167-1.741-.065-2.506-.235-.775-.644-1.533-1.154-2.082-.501-.539-1.031-.962-1.657-1.254-.625-.292-1.29-.426-2.025-.464Z"
      />
    </Svg>
  )
}

export default NotificationsXL
