import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { NoPagesXL } from 'icons'
import { styles } from '../styles'
import { NO_PAGES_ICON_SIZE } from './constants'

const NoNewsletters: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={styles.noContentContainer}>
      <Box sx={styles.noContentIconText}>
        <NoPagesXL width={NO_PAGES_ICON_SIZE} height={NO_PAGES_ICON_SIZE} />
        <Typography sx={styles.noContentText}>{t('page.cms.newsletter.noPages')}</Typography>
      </Box>
    </Box>
  )
}

export default NoNewsletters
