import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import SectionTitle from 'components/SectionTitle'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import EmployersList from './EmployersList'
import { profileClasses as classes } from './styles'

const Deactivate: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Page
      growContent={false}
      header={
        <WithBackwardLink
          route={routes.profile}
          title={t('page.userSettings.profile.settingsSection.deleteUserAccount.title')}
          topTitle={t('page.userSettings.profile.header')}
        />
      }
    >
      <SectionTitle marginLeft={1.0}>{t('label.deleteAccount')}</SectionTitle>
      <Card>
        <Box sx={classes.noticeBlock}>
          <Typography sx={classes.noticeLabel} variant="subtitle1">
            {t('label.notice!')}
          </Typography>
          <Typography sx={classes.deleteAccountText} variant="body2">
            {t('text.deleteAccount')}
          </Typography>
          <Box sx={classes.deactivateButtonBlcok}>
            <Button
              sx={classes.deactivateButton}
              size="small"
              color="secondary"
              onClick={() => history.push(routes.profileConfirmDeactivateAccount)}
              data-testid="user-settings-profile-button-deactivate-account"
            >
              {t('button.deactivateAccount')}
            </Button>
          </Box>
        </Box>
      </Card>

      <Box sx={classes.employersSection}>
        <SectionTitle marginLeft={1.0}>
          <Box sx={classes.employersSectionTitle}>
            <Box component="span" sx={classes.employersTitleText}>
              {t('page.userSettings.button.employers')}
            </Box>
            <ArrowRightS />
          </Box>
        </SectionTitle>

        <EmployersList />
      </Box>
    </Page>
  )
}

export default Deactivate
