import React, { useCallback, useMemo } from 'react'
import { Box, IconButton } from '@mui/material'
import { addDays } from 'date-fns'
import { PinPointS } from 'icons'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { useAvatarSidebarStateDispatchContext } from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { PinPointingSearchValueT } from 'pages/AbsencePlanner/types'
import { getDaysRequested } from 'pages/AbsencePlanner/utils'
import TaskCard from 'pages/Tasks/TaskCard'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { newDateWithoutTime } from 'utils/date'
import { getDaysBefore } from '../utils'
import { pinPointableTaskCardClasses } from './styles'

interface IPinPointableTaskCardProps {
  task: TaskT<AbsenceRequestTaskT>
  office: boolean
}

const PinPointableTaskCard: React.FC<IPinPointableTaskCardProps> = ({ task, office }) => {
  const { selectTaskFromTaskBar } = useSelectedStatesActionsContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const daysBefore = useMemo(() => getDaysBefore(isSmallScreen), [isSmallScreen])

  const pinPointAndOpenTask = useCallback(() => {
    const firstTaskDay = newDateWithoutTime(task.taskData.dateFrom)
    const dayToScroll = addDays(firstTaskDay, daysBefore)
    const searchValue = {
      date: dayToScroll,
      personId: `${task?.person?.id}`,
    } as PinPointingSearchValueT

    selectTaskFromTaskBar(task.favurUuid, searchValue, getDaysRequested(task), !isSmallScreen)
  }, [daysBefore, isSmallScreen, selectTaskFromTaskBar, task])

  const pinPointTask = useCallback(() => {
    const firstTaskDay = newDateWithoutTime(task.taskData.dateFrom)
    const dayToScroll = addDays(firstTaskDay, daysBefore)
    const searchValue = {
      date: dayToScroll,
      personId: `${task?.person?.id}`,
    } as PinPointingSearchValueT

    selectTaskFromTaskBar(task.favurUuid, searchValue, getDaysRequested(task), true)
    setTaskBarState(null)
    setAvatarBarExpanded(false)
  }, [daysBefore, selectTaskFromTaskBar, setAvatarBarExpanded, setTaskBarState, task])

  return (
    <Box sx={pinPointableTaskCardClasses.container}>
      <TaskCard task={task} office={office} onDelete={() => {}} onClickDetails={pinPointAndOpenTask} />
      {isSmallScreen && (
        <IconButton
          onClick={pinPointTask}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={pinPointableTaskCardClasses.pinPointButton}
        >
          <PinPointS />
        </IconButton>
      )}
    </Box>
  )
}

export default PinPointableTaskCard
