import React, { useEffect } from 'react'
import { default as MuiTimeline } from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { StatusBallInactive, StatusBallActive } from 'components/StatusBall'
import { IonContent } from '@ionic/core/components/ion-content'
import ItemContent from './Items/ItemContent'
import ItemHeader from './Items/ItemHeader'
import { timelineClasses } from './styles'
import { TimelineItemComponentT } from './types'

interface ITimelineProps {
  items: TimelineItemComponentT[]
}

const Timeline: React.FC<ITimelineProps> = ({ items }) => {
  useEffect(() => {
    // @ts-ignore
    const mainParent = document.getElementById('ionContent') as IonContent
    mainParent?.scrollToBottom()
  }, [])
  return (
    <MuiTimeline sx={[timelineClasses.root, timelineClasses.noBottomSpacing]}>
      {items.map((item, index, list) => {
        const isLast = index === list.length - 1
        return (
          <TimelineItem key={item.header.id} sx={timelineClasses.item}>
            <TimelineSeparator>
              {item.header.isFlashing ? <StatusBallActive /> : <StatusBallInactive />}
              {!isLast && <TimelineConnector sx={timelineClasses.connector} />}
            </TimelineSeparator>
            <TimelineContent
              sx={[
                timelineClasses.content,
                ...(isLast ? [timelineClasses.lastContent] : []),
                timelineClasses.noBottomSpacing,
              ]}
            >
              <ItemHeader header={item.header} />
              <ItemContent content={item.content} />
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </MuiTimeline>
  )
}

export default Timeline
