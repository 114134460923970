import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const CloseL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M26.06 5.94c.55.549.584 1.418.104 2.007l-.103.114L18.12 16l7.94 7.94c.585.585.585 1.535 0 2.12-.55.55-1.419.584-2.008.104l-.114-.103L16 18.12l-7.94 7.94c-.585.585-1.535.585-2.12 0-.55-.55-.584-1.419-.104-2.008l.103-.114L13.88 16l-7.94-7.94c-.585-.585-.585-1.535 0-2.12.55-.55 1.419-.584 2.008-.104l.114.103L16 13.88l7.94-7.94c.585-.585 1.535-.585 2.12 0z"
      />
    </Svg>
  )
}

export default CloseL
