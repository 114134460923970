import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { LinkMessageNotificationT, NotificationT } from 'types'
import NotificationTemplate from '../Templates/Notification'

interface ILinkMessageNotification {
  notification: LinkMessageNotificationT
  dismiss: (notification: NotificationT) => void
}

const LinkMessageNotification: React.FC<ILinkMessageNotification> = (props) => {
  const { t } = useFavurTranslation()

  const { notification, dismiss } = props

  const handleRedirect = useCallback(() => {
    dismiss(notification)
    const win = window.open(t(notification.data.linkKey), '_blank')
    win?.focus()
  }, [dismiss, notification, t])

  const templateProps = {
    ...props,
    title: t(notification.data.titleKey),
    body: t(notification.data.bodyKey),
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t(notification.data.linkButtonKey),
  }

  return <NotificationTemplate {...templateProps} />
}

export default LinkMessageNotification
