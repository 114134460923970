import React, { useCallback, useEffect, useState } from 'react'
import CenteredBox from 'components/CenteredBox'
import { PIN_LENGTHS, SECURITY_PROCESS_TYPES } from 'constants/highSecurityConnection'
import { ROLE_VIEWS } from 'constants/roles'
import useDialogs from 'hooks/useDialogs'
import useHistoryUtils from 'hooks/useHistoryUtils'
import usePinContext from 'hooks/usePinContext'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { ExtendedStepProps, SetPinStepProps } from 'types'
import { classes } from '../styles'
import PinEnter from './PinEnter'
import PinRepeat from './PinRepeat'

interface ISetPinProps {
  additionalProperties?: ExtendedStepProps
}

const SetPin: React.FC<ISetPinProps> = ({ additionalProperties }) => {
  const props = additionalProperties as SetPinStepProps

  const securityProcessType = SECURITY_PROCESS_TYPES.securitySetup
  const [pinLength, setPinLength] = useState(PIN_LENGTHS.default)
  const { pushWithRole } = useHistoryUtils()

  const { pin, firstPin, secondPin, setFirstPin, setSecondPin, taskUuid } = props

  const onConfirmPin = props.onConfirmPin
  // TBD: Manage when show PinEnter or PinRepeat step
  const [isPinEntered, setIsPinEntered] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const history = useHistory()
  const { openDialog } = useDialogs()
  const { securitySetupTokenConfirmed, pinChangePinConfirmed, setPinContext } = usePinContext()

  // security timeout handler
  const timeoutAction = useCallback(() => {
    openDialog(securityProcessType)
    if (taskUuid) {
      pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.frontliner)
    }
    history.push(routes.dashboard)
  }, [history, openDialog, pushWithRole, securityProcessType, taskUuid])

  useSessionTimeout(securityProcessType, timeoutAction)

  // Pin enter
  const onContinue = useCallback(() => {
    if (firstPin?.length === pinLength) {
      setPinContext({ pin: firstPin, pinLength, securitySetupTokenConfirmed, pinChangePinConfirmed })
      setIsPinEntered(true)
    }
    return null
  }, [firstPin, pinLength, setPinContext, securitySetupTokenConfirmed, pinChangePinConfirmed])

  useEffect(() => {
    if (!securitySetupTokenConfirmed) {
      timeoutAction()
      return
    }
  }, [securitySetupTokenConfirmed, pinChangePinConfirmed, securityProcessType, timeoutAction])

  useEffect(() => {
    if (showSuccess) {
      history.push(`${routes.secureConnectionSuccess}/?taskUuid=${taskUuid}`)
    }
  }, [showSuccess, history, taskUuid])

  // Pin repeat
  const handleOnConfirm = useCallback(async () => {
    if (secondPin === pin) {
      const response = await onConfirmPin()

      if (response?.setPinAndEstablishSecureConnection?.success === true) {
        setShowSuccess(true)
      }
    }
    setSecondPin('')
  }, [onConfirmPin, pin, secondPin, setSecondPin])

  return (
    <CenteredBox sx={classes.root}>
      {isPinEntered ? (
        <PinRepeat onConfirm={handleOnConfirm} pin={secondPin} pinLength={pinLength} setPin={setSecondPin} />
      ) : (
        <PinEnter
          onContinue={onContinue}
          pin={firstPin}
          pinLength={pinLength}
          setPin={setFirstPin}
          setPinLength={setPinLength}
        />
      )}
    </CenteredBox>
  )
}

export default SetPin
