import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const AlertInfoS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7-5c.28 0 .5.22.5.5V9a.5.5 0 0 1-1 0V3.5c0-.28.22-.5.5-.5Zm-.53 7.72a.75.75 0 1 1 1.06 1.06.75.75 0 0 1-1.06-1.06Z" />
    </Svg>
  )
}

export default AlertInfoS
