import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { getAbsenceRequestConflictsQuery } from 'shared/queries'
import { AbsenceRequestConflictsT } from 'types'

const addParamTeamIds = (teamIds?: string[]) => (teamIds ? { teamIds: teamIds } : {})
const addParamIsManager = (isManager?: boolean) => (isManager ? { isManager: isManager } : {})

interface AbsenceRequestConflictsParams {
  startDate: string
  endDate: string
  teamIds?: string[]
  isManager?: boolean
  onCompleted?: (conflicts: AbsenceRequestConflictsT) => void
}

export const useAbsenceRequestConflicts = ({
  startDate,
  endDate,
  teamIds,
  isManager,
  onCompleted,
}: AbsenceRequestConflictsParams) => {
  const { data, loading, refetch } = useJamesApolloQuery<{ getAbsenceRequestConflicts: AbsenceRequestConflictsT }>(
    getAbsenceRequestConflictsQuery,
    {
      variables: {
        startDate: startDate,
        endDate: endDate,
        ...addParamTeamIds(teamIds),
        ...addParamIsManager(isManager),
      },
      onCompleted(newData) {
        onCompleted && onCompleted(newData.getAbsenceRequestConflicts)
      },
    },
  )

  return { conflicts: data?.getAbsenceRequestConflicts ?? undefined, loading, refetch }
}
