import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import EventCard from 'pages/Cms/EventsList/EventCard'
import { styles } from './styles'
import { EventGroupProps } from './types'

const EventGroup: React.FC<EventGroupProps> = ({ dateLabel, contents }) => {
  const { t } = useFavurTranslation()
  const { setSelectedEvent } = useSelectedStatesActionsContext()

  return (
    <Box sx={styles.eventGroup}>
      <Typography sx={styles.eventDateGroup} variant="body1">
        {t(dateLabel)}
      </Typography>
      <Box sx={styles.eventsGroupList}>
        {contents.map((content) => (
          <EventCard onClick={() => setSelectedEvent(content?.uuid ?? null)} key={content.uuid} content={content} />
        ))}
      </Box>
    </Box>
  )
}

export default EventGroup
