import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { getFormattedFromISOString } from 'utils/date'
import { acceptedSummaryClasses } from './styles'

const AcceptedSummary: React.FC<{ task: TaskT<AbsenceRequestTaskT> }> = ({ task }) => {
  const { t, locale } = useFavurTranslation()
  const { taskData } = task
  const isMultiDay = useMemo(() => taskData.dateFrom !== taskData.dateTo, [taskData.dateFrom, taskData.dateTo])
  const isSingleType = useMemo(() => {
    if (!isMultiDay) return true
    const typesWithoutDupes = taskData.dates
      .map((date) => date.type)
      .filter((type, index, array) => {
        return array.indexOf(type) === index
      })
    return typesWithoutDupes.length === 1
  }, [isMultiDay, taskData.dates])

  const firstDateType = taskData.dates[0].type

  return (
    <Box sx={acceptedSummaryClasses.typesContainer}>
      {!isMultiDay || isSingleType ? (
        <Typography variant="body2" sx={acceptedSummaryClasses.typeText}>
          <span>{t('absenceRequest.detail.type')}:</span>
          {t(`absenceRequest.type.${firstDateType}`)}
          {isMultiDay && ` (${t('absenceRequest.detail.allDays')})`}
        </Typography>
      ) : (
        taskData.dates.map(({ date, type }) => (
          <Typography variant="body2" sx={acceptedSummaryClasses.typeText} key={date}>
            <span>{getFormattedFromISOString(date, 'EEE, dd.MM.yyyy', locale)}:</span>
            {t(`absenceRequest.type.${type}`)}
          </Typography>
        ))
      )}
    </Box>
  )
}

export default AcceptedSummary
