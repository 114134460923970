import { Theme } from '@mui/material/styles'

const shadows: Theme['shadows'] = [
  'none',
  '0 0 4px rgba(0, 0, 0, 0.16)',
  '0 0 6px rgba(0, 0, 0, 0.2)',
  '0 -2px 10px rgba(0, 0, 0, 0.2)',
  '0 2px 10px rgba(0, 0, 0, 0.2)',
  '0 0 12px rgba(0, 0, 0, 0.32)',
  '0 0 12px rgba(0, 0, 0, 0.6)',
  '0 1px 1px rgba(0, 0, 0, 0.25);',
  '0 6px 6px -6px rgba(0, 0, 0, 0.2)',
  '0px -4px 16px 0px rgba(84, 109, 153, 0.18)',
  '0px 4px 16px 0px rgba(84, 109, 153, 0.24)',
  '-2px -4px 16px 0px rgba(84, 109, 153, 0.18)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
]

export default shadows
