import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledEditM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        d="M14.655 7a1.071 1.071 0 0 0-.764.32l-5.886 5.855a.358.358 0 0 0-.092.158l-.9 3.214a.357.357 0 0 0 .44.44l3.215-.9a.357.357 0 0 0 .158-.091l5.856-5.885h.002a1.072 1.072 0 0 0 0-1.52l-.002-.001-1.261-1.269A1.07 1.07 0 0 0 14.655 7Z"
      />
    </Svg>
  )
}

export default FilledEditM
