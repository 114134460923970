import React from 'react'
import { useHistory } from 'react-router-dom'

const Redirect: React.FC<{ route: string }> = ({ route }) => {
  const history = useHistory()

  history.push(route)

  return null
}

export default Redirect
