import React, { useMemo } from 'react'
import { differenceInMonths, format, max } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import { AccountSettingsM, BellM, EmployersM, PersonalDataFormM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import useLastUpdateDate from 'pages/UserSettings/hooks/useLastUpdateDate'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { newDateWithoutTime } from 'utils/date'
import { isNativeNoOverride } from 'utils/platform'
import palette from 'utils/theme/palette'

interface IPersonalSettings {
  showTitleSection?: boolean
}

const PersonalSettings: React.FC<IPersonalSettings> = ({ showTitleSection = true }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { lastUpdateDate } = useLastUpdateDate()
  const { persons, loading } = usePersonsOfUser({ hideInactive: true })

  const updatedSinceMonthsText = useMemo(() => {
    if (lastUpdateDate === undefined) return ''
    const updatedSinceMonths = differenceInMonths(newDateWithoutTime(), lastUpdateDate)
    return updatedSinceMonths === 0
      ? t('page.userSettings.personalDataForm.updated')
      : t('page.userSettings.personalDataForm.lastUpdated', { updatedSinceMonths })
  }, [t, lastUpdateDate])

  const maxLastSharedDate = useMemo(() => {
    if (loading || !persons) return undefined

    const dateArray = persons.reduce<Date[]>((acc, person) => {
      if (!person.lastSharedData) return acc
      return [...acc, newDateWithoutTime(person.lastSharedData)]
    }, [])
    return max(dateArray)
  }, [loading, persons])

  const monthsWithoutUpdate = useMemo(
    () => (maxLastSharedDate !== undefined ? Math.abs(differenceInMonths(maxLastSharedDate, newDateWithoutTime())) : 0),
    [maxLastSharedDate],
  )

  const hasUnsharedData = useMemo(
    () => lastUpdateDate !== undefined && maxLastSharedDate !== undefined && lastUpdateDate > maxLastSharedDate,
    [lastUpdateDate, maxLastSharedDate],
  )

  const titleProps = useMemo(() => {
    return showTitleSection
      ? {
          title: t('page.userSettings.personalSettings.title'),
          subtitle: t('page.userSettings.personalSettings.subtitle'),
        }
      : {}
  }, [t, showTitleSection])

  return (
    <UserSettingsSection {...titleProps}>
      <UserSettingsSectionCTA
        title={t('page.userSettings.accountSettings.title')}
        subtitle={t('page.userSettings.accountSettings.subtitle')}
        onClick={() => {
          history.push(routes.profile)
        }}
        icon={<AccountSettingsM fill={palette.primary.main} />}
        data-testid="profile"
      />
      <UserSettingsSectionCTA
        title={t('page.userSettings.personalDataForm.title')}
        subtitle={updatedSinceMonthsText}
        onClick={() => {
          history.push(routes.employmentData)
        }}
        icon={<PersonalDataFormM fill={palette.primary.main} />}
        alerts={[
          ...(monthsWithoutUpdate >= 6
            ? [
                t('alert.userSettings.personalDataForm.notSharedSince', {
                  date: format(maxLastSharedDate as Date, 'dd.mm.yyyy'),
                }),
              ]
            : []),
          ...(hasUnsharedData ? [t('alert.userSettings.personalDataForm.unsharedData')] : []),
        ]}
        data-testid="personal-data"
      />
      <UserSettingsSectionCTA
        title={t('page.userSettings.employers.title')}
        subtitle={t('page.userSettings.employers.subtitle')}
        onClick={() => {
          history.push(routes.employers)
        }}
        icon={<EmployersM fill={palette.primary.main} />}
        data-testid="employers"
      />
      {isNativeNoOverride() && (
        <UserSettingsSectionCTA
          title={t('page.userSettings.notifications.title')}
          subtitle={t('page.userSettings.notifications.subtitle')}
          onClick={() => {
            history.push(routes.notificationsSettings)
          }}
          icon={<BellM fill={palette.primary.main} />}
        />
      )}
    </UserSettingsSection>
  )
}

export default PersonalSettings
