import React from 'react'
import SecuritySetupPinRepeat from 'pages/SecuritySetup/Common/PinRepeat'

const PinRepeat: React.FC<{ goToStep: (stepName: string) => void; hideStepper: (hide: boolean) => void }> = ({
  goToStep,
  hideStepper,
}) => {
  return <SecuritySetupPinRepeat goToStep={goToStep} hideStepper={hideStepper} securityProcessType="pinChange" />
}

export default PinRepeat
