import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import ShiftCardAbsence from 'components/ShiftsList/components/ShiftCard/ShiftTypes/ShiftCardAbsence'
import { TeamMemberT } from 'components/ShiftsList/components/ShiftCard/TeamsSection/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsencePlanM } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import AvatarNameRow from '../AvatarNameRow'
import NoDataLabel from '../NoDataLabel'
import { classes } from '../styles'

const getAbsenceKey = (teamMember: TeamMemberT) => {
  return `${teamMember.personId}-${teamMember.avatarName}-${teamMember.avatarUrl}`
}

interface IAbsentTabProps {
  vacationAbsences: TeamMemberT[]
  otherAbsences: TeamMemberT[]
}

const AbsentTab: React.FC<IAbsentTabProps> = ({ vacationAbsences, otherAbsences }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const hasVacations = vacationAbsences.length > 0
  const hasOtherAbsences = otherAbsences.length > 0

  return (
    <Card>
      {hasVacations && (
        <>
          <Typography variant="subtitle1" sx={classes.subtitle}>
            {t('component.teamplanDetailPage.absences.vacation')}
          </Typography>
          <Box sx={classes.rowGroup}>
            {vacationAbsences.map((absence) => (
              <Box key={getAbsenceKey(absence)}>
                <AvatarNameRow teamMember={absence} />
                <Box sx={classes.shiftsContainer}>
                  <ShiftCardAbsence shift={absence.shifts[0]} hideAbsenceType />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {hasOtherAbsences && (
        <>
          <Typography variant="subtitle1" sx={classes.subtitle}>
            {t('component.teamplanDetailPage.absences.other')}
          </Typography>
          <Box sx={classes.rowGroup}>
            {otherAbsences.map((absence) => (
              <Box key={getAbsenceKey(absence)}>
                <AvatarNameRow teamMember={absence} />
                <Box sx={classes.shiftsContainer}>
                  <ShiftCardAbsence shift={absence.shifts[0]} hideAbsenceType />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {!hasVacations && !hasOtherAbsences && (
        <Box sx={classes.noDataLabelMargin}>
          <NoDataLabel />
        </Box>
      )}
      <DivisionLine />
      <Box sx={classes.ctaContainer}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            history.push(routes.absencePlanner)
          }}
          sx={classes.absencePlanCta}
          disableRipple
          disableFocusRipple
          data-testid="teamplan-absenceplan-link"
        >
          <AbsencePlanM />
          <span>{t('component.teamplanDetailPage.absences.absencePlannerLink')}</span>
        </Button>
      </Box>
    </Card>
  )
}

export default AbsentTab
