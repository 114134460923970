import React, { useState } from 'react'
import { InputAdornment, SxProps, Theme } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { MobileDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { CalendarAppointmentS } from 'icons'
import { uiDateFormat } from 'utils/constants'
import palette from 'utils/theme/palette'
import CustomActionBar from '../components/ActionBar'
import { datePickerClasses, classes } from '../styles'

interface IDatePickerProps {
  autoOpen?: boolean
  dataTestId: string
  value: Date | null
  onChange: (newValue: Date | null) => void
  minDate?: Date
  required?: boolean
  label?: string
  error?: boolean
  helperText?: string
  format?: string
  maxDate?: Date
  dialogSx?: SxProps<Theme>
  useDefaultValue?: boolean
}

const ControlledDatePicker: React.FC<IDatePickerProps> = ({
  autoOpen,
  dataTestId,
  value,
  minDate,
  maxDate,
  required = true,
  label,
  error,
  helperText,
  onChange,
  format,
  dialogSx,
  useDefaultValue = true,
}) => {
  const [open, setOpen] = useState(false)
  const [showActionBar, setShowActionBar] = useState(true)

  return (
    <MuiDatePicker
      label={label}
      showToolbar={false}
      minDate={minDate}
      maxDate={maxDate}
      format={format || uiDateFormat}
      onChange={(newValue) => onChange(newValue)}
      value={value}
      open={open}
      slots={{
        actionBar: (props) => (showActionBar ? <CustomActionBar {...props} /> : null),
      }}
      onAccept={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onViewChange={(view) => setShowActionBar(view === 'day')}
      defaultValue={useDefaultValue ? minDate : undefined}
      views={['year', 'month', 'day']}
      slotProps={{
        toolbar: { hidden: true },
        textField: {
          variant: 'standard',
          helperText: helperText,
          sx: { width: '100%' },
          required,
          onFocus(e: React.FocusEvent<HTMLInputElement>) {
            if (!e.relatedTarget) {
              setOpen(Boolean(autoOpen))
            }
          },
          error: Boolean(error),
          InputProps: {
            //@ts-ignore
            'data-testid': dataTestId,
            endAdornment: (
              <InputAdornment position="end" sx={classes.openPickerIcon}>
                <CalendarAppointmentS fill={palette.secondary.main} />
              </InputAdornment>
            ),
          },
        },

        dialog: {
          sx: {
            ...dialogSx,
            ...datePickerClasses.monthSwitcherIcons,
            ...datePickerClasses.baseDialog,
          },
        },
      }}
    />
  )
}

export default ControlledDatePicker
