import React, { PropsWithChildren } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { EditPencilM } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IUserSettingsSectionCTA {
  title: string
  subtitle: string
  icon: React.JSX.Element
  onClick: () => void
  annotation?: string
}

const UserSettingsSectionEditCTA: React.FC<PropsWithChildren<IUserSettingsSectionCTA>> = ({
  onClick,
  title,
  subtitle,
  icon,
  children,
  annotation,
  ...props
}) => {
  return (
    <Box sx={classes.root}>
      <Card>
        <Box sx={classes.content}>
          <Box sx={classes.innerContent}>
            {icon}
            <Box sx={classes.textContainer}>
              <Typography variant="body2" sx={classes.title}>
                {title}
              </Typography>
              <Typography variant="body1" sx={classes.subtitle}>
                {subtitle}
              </Typography>
              {annotation && (
                <Typography variant="body2" sx={classes.title}>
                  {annotation}
                </Typography>
              )}
            </Box>
          </Box>
          <IconButton
            onClick={onClick}
            sx={classes.icon}
            {...props}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <EditPencilM fill={palette.primary.main} />
          </IconButton>
        </Box>
        {children}
      </Card>
    </Box>
  )
}

export default UserSettingsSectionEditCTA
