import React from 'react'
import { Box, SxProps, Typography, Checkbox as MuiCheckbox } from '@mui/material'
import { checkboxClasses as classes } from './styles'

interface ICheckboxProps {
  checked: boolean
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  displayBlock?: boolean
  sx?: SxProps
  dataTestId?: string
}

const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  label,
  onChange,
  disabled = false,
  displayBlock,
  sx,
  dataTestId,
}) => {
  return (
    <Box alignItems="center" display={displayBlock ? 'block' : 'flex'}>
      <MuiCheckbox
        color="secondary"
        checked={checked}
        sx={[...(Array.isArray(sx) ? sx : [sx]), ...(disabled ? [classes.disabled] : []), classes.checkbox]}
        onChange={onChange}
        inputProps={{ 'aria-label': `checkbox_${label}`, type: 'checkbox' }}
        disabled={disabled}
        data-testid={dataTestId}
      />
      <Typography variant="body2">{label}</Typography>
    </Box>
  )
}

export default Checkbox
