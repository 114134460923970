import React from 'react'
import usePushNotifications from 'hooks/usePushNotifications'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { isNativeNoOverride } from 'utils/platform'

const ComponentWithPushNotification = ({
  component: Component,
  redirectTo,
  ...props
}: RouteProps &
  RouteComponentProps & {
    redirectTo: string
  }) => {
  // check push notifications on any protected route
  usePushNotifications({ refresh: true })
  return <>{Component ? <Component {...props} /> : <Redirect to={redirectTo} />}</>
}

const ProtectedRoute = ({
  component: Component,
  isAllowed,
  redirectTo = routes.login,
  noPushRegistration = false,
  classicModuleAccess,
  redirectLogin = false,
  ...rest
}: RouteProps & {
  isAllowed: boolean
  redirectTo?: string
  noPushRegistration?: boolean
  classicModuleAccess?: boolean
  redirectLogin?: boolean
}) => {
  const renderComponent = (props: RouteComponentProps) => {
    if (redirectLogin && !isAllowed) return <Route {...rest} render={renderComponent} />
    if (!isAllowed || !Component) return <Redirect to={redirectTo} />
    if (classicModuleAccess === false) return <Redirect to={routes.dashboard} />
    if (noPushRegistration || !isNativeNoOverride()) return <Component {...props} />
    return <ComponentWithPushNotification {...props} component={Component} redirectTo={redirectTo} />
  }
  return <Route {...rest} render={renderComponent} />
}

export default ProtectedRoute
