import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CalendarAppointmentWithCircleM: React.FC<IconPropsT & { fillCircle?: string }> = ({ fill, fillCircle }) => {
  return (
    <Svg size={ICON_SIZE_M} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25C8.41421 1.25 8.75 1.58579 8.75 2V4.25H15.25V2C15.25 1.58579 15.5858 1.25 16 1.25C16.4142 1.25 16.75 1.58579 16.75 2V4.25H19.8C20.9363 4.25 21.75 5.23324 21.75 6.30769V10.5V20.6923C21.75 21.7668 20.9363 22.75 19.8 22.75H4.2C3.06367 22.75 2.25 21.7668 2.25 20.6923V10.5V6.30769C2.25 5.23324 3.06367 4.25 4.2 4.25H7.25V2C7.25 1.58579 7.58579 1.25 8 1.25ZM15.25 5.75V6.5C15.25 6.91421 15.5858 7.25 16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5V5.75H19.8C19.9892 5.75 20.25 5.93771 20.25 6.30769V9.75H3.75V6.30769C3.75 5.93771 4.01085 5.75 4.2 5.75H7.25V6.5C7.25 6.91421 7.58579 7.25 8 7.25C8.41421 7.25 8.75 6.91421 8.75 6.5V5.75H15.25ZM20.25 11.25V20.6923C20.25 21.0623 19.9892 21.25 19.8 21.25H4.2C4.01085 21.25 3.75 21.0623 3.75 20.6923V11.25H20.25Z"
        fill={fill}
      />
      <circle cx="7" cy="14" r="2" fill={fillCircle} />
    </Svg>
  )
}

export default CalendarAppointmentWithCircleM
