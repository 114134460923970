import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const DropdownDownS: React.FC<IconPropsT> = ({ fill, ...props }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill} {...props}>
      <path
        fillRule="evenodd"
        d="M12.146 7.854l.708.707a.5.5 0 010 .707l-4.5 4.5a.502.502 0 01-.708 0l-4.5-4.5a.5.5 0 010-.707l.708-.707a.498.498 0 01.637-.058l.07.058L8 11.294l3.44-3.44a.498.498 0 01.637-.058l.07.058zm0-5.5l.708.707a.5.5 0 010 .707l-4.5 4.5a.502.502 0 01-.708 0l-4.5-4.5a.5.5 0 010-.707l.708-.707a.498.498 0 01.637-.058l.07.058L8 5.794l3.44-3.44a.498.498 0 01.637-.058l.07.058z"
      />
    </Svg>
  )
}

export default DropdownDownS
