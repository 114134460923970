import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { absenceRequestsQuery } from 'pages/Tasks/queries'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'

interface IUseShiftUpdateParams {
  personIds: number[]
  date?: string
  dateFrom?: string
  dateTo?: string
  statuses?: string[]
  skip?: boolean
  cacheFirst?: boolean
  onCompleted?: (newTasks: TaskT<AbsenceRequestTaskT>[]) => void
}

const useAbsenceRequests = ({
  personIds,
  date,
  dateFrom,
  dateTo,
  statuses,
  skip = false,
  cacheFirst = false,
  onCompleted,
}: IUseShiftUpdateParams) => {
  const { data, loading, refetch } = useJamesApolloQuery<{ getAbsenceRequests: TaskT<AbsenceRequestTaskT>[] }>(
    absenceRequestsQuery,
    {
      variables: { personIds, date, dateFrom, dateTo, statuses },
      fetchPolicy: cacheFirst ? 'cache-first' : 'cache-and-network',
      skip: skip || personIds.length === 0,
      onCompleted(newData) {
        onCompleted && onCompleted(newData.getAbsenceRequests)
      },
    },
  )

  return { tasks: data?.getAbsenceRequests ?? [], loading, refetch }
}

export default useAbsenceRequests
