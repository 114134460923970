import { useCallback, useEffect, useMemo, useState } from 'react'
import { TaskCountsT } from 'pages/AbsencePlanner/types'
import { mapTaskCounts } from 'pages/AbsencePlanner/utils'
import { taskTypes } from 'shared/constants'
import { ManagedTaskCountsReturnT, getManagedTaskCountsQuery } from 'shared/queries'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const useManagedTaskCounts = (teamIds?: string[]) => {
  const [taskCounts, setTaskCounts] = useState<TaskCountsT>({})
  const [taskCountTotal, setTaskCountTotal] = useState<number>(0)

  const emptyTeams = useMemo(() => {
    return !teamIds || teamIds.length === 0
  }, [teamIds])

  const { loading, refetch } = useJamesApolloQuery<{
    getManagedTaskCounts: ManagedTaskCountsReturnT
  }>(getManagedTaskCountsQuery, {
    variables: {
      taskTypes: [taskTypes.absenceRequest],
      teamIds,
    },
    skip: emptyTeams,
    onCompleted(data) {
      const { getManagedTaskCounts } = data
      setTaskCounts(mapTaskCounts(getManagedTaskCounts || []))
      setTaskCountTotal(
        getManagedTaskCounts.reduce((total, taskCount) => taskCount.pending + taskCount.todo + total, 0),
      )
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (emptyTeams) {
      setTaskCounts({})
      setTaskCountTotal(0)
    }
  }, [emptyTeams])

  const maybeRefetch = useCallback(() => {
    if (!emptyTeams) refetch()
  }, [emptyTeams, refetch])

  return { taskCounts, loading, taskCountTotal, refetch: maybeRefetch }
}

export default useManagedTaskCounts
