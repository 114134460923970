import React from 'react'
import { Box, Button } from '@mui/material'
import ArrowRightM from 'icons/ArrowRightM'
import { classes } from '../styles'

export interface INumpadDelete {
  onClick: () => void
}

const NumpadConfirm: React.FC<INumpadDelete> = ({ onClick }) => {
  return (
    <Button
      type="submit"
      aria-label="Numpad confirm"
      sx={classes.numpadConfirmButton}
      onClick={onClick}
      data-testid="numpad-confirm"
    >
      <Box sx={classes.confirmButtonIcon}>
        <ArrowRightM />
      </Box>
    </Button>
  )
}

export default NumpadConfirm
