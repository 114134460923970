import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sendCommentBodyClasses } from '../styles'

const DefaultHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={sendCommentBodyClasses.body}>
      <Typography variant="body2" sx={sendCommentBodyClasses.title}>
        {t('workTimeControl.comment.title')}
      </Typography>
    </Box>
  )
}

export default DefaultHeader
