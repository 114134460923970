import React from 'react'
import { Box, Typography } from '@mui/material'
import Badge from 'components/Badge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PlannedShiftT, ShiftTimeOfDayT, shiftTimesOfDay } from 'types'
import { getTodayFormatted } from 'utils/date'
import CollapsibleCommentSection from './CollapsibleCommentSection'
import CommentSection from './CommentSection'
import { classes } from './styles'

const getAbsencePeriodKey = (percent: number, timeOfDay?: ShiftTimeOfDayT) => {
  if (percent < 100) {
    if (!timeOfDay) return 'page.shifts.shiftCard.absencePeriod.halfDay'
    if (timeOfDay === shiftTimesOfDay.pm) return 'page.shifts.shiftCard.absencePeriod.afternoon'
    return 'page.shifts.shiftCard.absencePeriod.morning'
  }
  return 'page.shifts.shiftCard.absencePeriod.fullDay'
}

interface IShiftCardAbsence {
  shift: PlannedShiftT
  disabled?: boolean
  hideAbsenceType?: boolean
  hideComment?: boolean
}

const ShiftCardAbsence: React.FC<IShiftCardAbsence> = ({
  shift,
  disabled = false,
  hideAbsenceType = false,
  hideComment = false,
}) => {
  const { t } = useFavurTranslation()
  const comment = shift.note ?? ''
  const isPast = shift.date < getTodayFormatted()
  return (
    <Box sx={classes.columnContainer}>
      <Box sx={classes.badgeLine}>
        <Badge shift={shift} onClick={() => {}} disabled={disabled} />
      </Box>
      {!hideAbsenceType && (
        <Box sx={classes.typeLine}>
          <Typography variant="body2" sx={disabled ? classes.disabledText : undefined}>
            {t('page.shifts.shiftCard.absenceType')}
          </Typography>
          <Typography variant="body2" sx={disabled ? classes.disabledText : undefined}>
            {`${t(getAbsencePeriodKey(shift.percent as number, shift.timeOfDay))} | ${t(
              `page.shifts.content.${shift.timeType}`,
            )}`}
          </Typography>
        </Box>
      )}
      {!hideComment &&
        comment &&
        (isPast ? (
          <CollapsibleCommentSection comment={comment} disabled={disabled} />
        ) : (
          <CommentSection comment={comment} disabled={disabled} />
        ))}
    </Box>
  )
}

export default ShiftCardAbsence
