import theme from 'utils/theme'

export const useAvatarClasses = (size: number) => {
  const fontSize = Math.floor(size * 0.15 + 8)
  return {
    avatar: {
      width: `${size}px`,
      height: `${size}px`,
      fontSize: `${fontSize}px`,
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '0.3px',
      backgroundColor: theme.palette.primary.light,
    },
  }
}
