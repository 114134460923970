import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const styles = {
  closeButtonDialog: {
    color: palette.info.main,
    padding: '0px',
    justifyContent: 'right',
  },
  closeButtonDrawer: {
    color: palette.info.main,
    justifyContent: 'right',
    padding: '0px',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    gap: '8px',
  },
  dialog: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '28px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: '8px',
  },
  drawer: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '36px 24px 24px 24px',
    position: 'relative',
    gap: '8px',
  },
  iconButtonContainer: {
    position: 'sticky',
    top: 0,
    background: theme.palette.common.white,
    zIndex: 10,
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '8px',
  },
}
