import React, { useState } from 'react'
import { Box, Button, Collapse, Fade, Typography } from '@mui/material'
import Badge from 'components/Badge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowDownS, ArrowUpS } from 'icons'
import { PlannedShiftT } from 'types'
import { getTodayFormatted } from 'utils/date'
import { getFromUntil, getTimeType } from 'utils/shifts'
import CollapsibleCommentSection from './CollapsibleCommentSection'
import CommentSection from './CommentSection'
import { classes } from './styles'

interface IShiftCardPlannedShiftProps {
  shifts: PlannedShiftT[]
  costCenterName: string
  disabled?: boolean
  oldShifts?: PlannedShiftT[]
  hideComment?: boolean
  hideCostCenter?: boolean
}

const ShiftCardPlannedShift: React.FC<IShiftCardPlannedShiftProps> = ({
  shifts,
  costCenterName,
  disabled = false,
  oldShifts,
  hideComment = false,
  hideCostCenter = false,
}) => {
  const { t } = useFavurTranslation()
  const uniqueDifferentComments = Array.from(new Set(shifts.map((s) => s.note).filter((c) => Boolean(c))))
  const singleComment = uniqueDifferentComments.length === 1
  const comment = singleComment ? uniqueDifferentComments[0] : ''
  const isPast = shifts[0].date < getTodayFormatted()
  const [showOldShifts, setShowOldShifts] = useState(false)

  const getCommentSection = (commentElement: string, skipTitle: boolean) => {
    return isPast ? (
      <CollapsibleCommentSection comment={commentElement} disabled={disabled} skipTitle={skipTitle} />
    ) : (
      <CommentSection comment={commentElement} disabled={disabled} skipTitle={skipTitle} />
    )
  }

  const getShiftComponentList = (shiftList: PlannedShiftT[], isDisabled: boolean) => (
    <>
      {shiftList.map((shift) => (
        <Box key={shift.id}>
          <Box sx={classes.typeLine} data-testid="shift-card__shift">
            <Typography variant="body2" sx={isDisabled ? classes.disabledText : undefined}>
              {getTimeType(shift, t)}
            </Typography>
            <Typography variant="body2" sx={isDisabled ? classes.disabledText : undefined}>
              {getFromUntil(shift, t)}
            </Typography>
          </Box>
          {!hideComment && !singleComment && shift.note && <Box>{getCommentSection(shift.note, true)}</Box>}
        </Box>
      ))}
      {!hideCostCenter && (
        <Box sx={classes.typeLine}>
          <Typography variant="body2" sx={isDisabled ? classes.disabledText : undefined}>
            {t('component.shifthistory.costcenter')}
          </Typography>
          <Typography variant="body2" sx={isDisabled ? classes.disabledText : undefined}>
            {costCenterName}
          </Typography>
        </Box>
      )}
    </>
  )

  return (
    <Box sx={classes.columnContainer}>
      <Box sx={classes.badgeLine} data-testid="shift-card__badge">
        <Badge shift={shifts[0]} tooltip disabled={disabled} />
      </Box>
      {getShiftComponentList(shifts, disabled)}
      {oldShifts && oldShifts.length > 0 && (
        <>
          <Box sx={classes.oldBadgeLine} data-testid="shift-card__badge">
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowOldShifts(!showOldShifts)}
              sx={[classes.openMoreDetailsButton, classes.openOldShiftsButton]}
              disableFocusRipple
              disableRipple
            >
              {t('component.shifthistory.oldshifts')}
              <Box sx={classes.arrowIconContainer}>{showOldShifts ? <ArrowUpS /> : <ArrowDownS />}</Box>
            </Button>
            <Fade in={showOldShifts}>
              <Box>
                <Badge shift={oldShifts[0]} tooltip disabled />
              </Box>
            </Fade>
          </Box>
          <Collapse in={showOldShifts}>{getShiftComponentList(oldShifts, true)}</Collapse>
        </>
      )}
      {!hideComment && singleComment && comment && getCommentSection(comment, false)}
    </Box>
  )
}

export default ShiftCardPlannedShift
