import React from 'react'
import { Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

const NoTeamsFoundCard: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Card marginTop="8px">
      <Typography variant="body1" sx={classes.noTeamsText} data-testid="no-teams">
        {t('filter.teams.search.no_results')}
      </Typography>
    </Card>
  )
}

export default NoTeamsFoundCard
