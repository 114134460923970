import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const TeamM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12 13c3.242 0 5.883 2.57 5.996 5.785L18 19H6c0-3.314 2.686-6 6-6zm0 2c-1.395 0-2.624.714-3.34 1.797L8.536 17h6.93l-.092-.152c-.678-1.06-1.84-1.777-3.172-1.843L12 15zm5.5-5.143c2.431 0 4.412 1.714 4.497 3.857l.003.143h-4.109c-.101-.528-.273-1.032-.503-1.501l2.506.001-.061-.066c-.508-.526-1.269-.884-2.144-.929l-.189-.005c-.236 0-.464.023-.683.065-.338-.454-.74-.86-1.19-1.203.57-.232 1.204-.362 1.873-.362zm-11 0c.669 0 1.303.13 1.874.362-.452.343-.853.749-1.191 1.203-.146-.028-.296-.048-.45-.057l-.233-.008-.19.005c-.801.042-1.507.345-2.01.8l-.133.129-.061.066 2.506-.001c-.184.375-.33.773-.434 1.188l-.07.314H2l.003-.144C2.088 11.57 4.069 9.857 6.5 9.857zM12 6c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm5.5-4C18.88 4 20 5.12 20 6.5S18.88 9 17.5 9 15 7.88 15 6.5 16.12 4 17.5 4zm-11 0C7.88 4 9 5.12 9 6.5S7.88 9 6.5 9 4 7.88 4 6.5 5.12 4 6.5 4zm11 1.5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-11 0c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
      />
    </Svg>
  )
}

export default TeamM
