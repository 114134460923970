import {
  AvatarWithIconT,
  HrAvatarT,
  TimelineItemContentT,
  TimelineItemHeaderT,
  TimelineItemComponentT,
  TimelineAvatarTypeT,
} from './types'

export const createAvatarIcon = (name: string, url: string | undefined, userName: string) =>
  ({ avatarName: name, avatarUrl: url, userName: userName, type: TimelineAvatarTypeT.icon } as AvatarWithIconT)

export const createTimelineItemHeaderOnlyText = (
  id: string,
  text: string,
  isFlashing = false,
): TimelineItemHeaderT => ({
  id: id,
  text: text,
  isFlashing: isFlashing,
})

export const createTimelineItemHeaderWithAvatarIcon = (
  id: string,
  text: string,
  avatarData: AvatarWithIconT,
  isFlashing = false,
): TimelineItemHeaderT => ({
  id: id,
  text: text,
  isFlashing: isFlashing,
  avatarData: avatarData,
})

export const createTimelineItemHeaderWithHrAvatar = (
  id: string,
  text: string,
  userName: string,
  isFlashing = false,
): TimelineItemHeaderT => ({
  id: id,
  text: text,
  isFlashing: isFlashing,
  avatarData: { userName: userName, type: TimelineAvatarTypeT.hr } as HrAvatarT,
})

export const createTimeLineItem = (header: TimelineItemHeaderT, content?: TimelineItemContentT) =>
  ({ header: header, content: content } as TimelineItemComponentT)
