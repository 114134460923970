export const familyDataQueryName = 'familyDataForm'
export const familyDataQuery = `
  query{
    familyData {
      civilStatus
      civilStatusSince
      partner{
        firstName
        lastName
        gender
        birthDate
        socialInsuranceNumber
      }
      children{
        firstName
        lastName
        gender
        birthDate
        socialInsuranceNumber
      }
    }
  }
`
