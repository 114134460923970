import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortTeams } from 'pages/AbsencePlanner/utils'
import { TeamT } from 'types'
import { getFullTeamLabel } from 'utils/team'
import FilterContainer from '../FilterContainer'
import FiltersCheckBox from '../FiltersCheckBox'

interface ITeamsFrontlinerProps {
  ownerMemberTeams: TeamT[]
  ownerPublicTeams: TeamT[]
  shownTeams: string[]
  onChange: (team: TeamT, show: boolean) => void
  isMultiTenant?: boolean
}

const TeamsFrontliner: React.FC<ITeamsFrontlinerProps> = ({
  ownerMemberTeams,
  ownerPublicTeams,
  shownTeams,
  onChange,
  isMultiTenant,
}) => {
  const { t, language } = useFavurTranslation()
  const showPublicTeams = ownerPublicTeams && ownerPublicTeams.length > 0
  return (
    <>
      {ownerMemberTeams && (
        <FilterContainer title={t('absencePlanner.taskbar.filter.showTeams')} divideBottom={!showPublicTeams}>
          {sortTeams(ownerMemberTeams, language).map((team) => {
            return (
              <FiltersCheckBox
                key={team.id}
                dataTestId={`team-${team.id}`}
                checked={shownTeams.includes(team.id)}
                label={getFullTeamLabel(team, language, Boolean(isMultiTenant))}
                onChange={() => {
                  onChange(team, shownTeams.includes(team.id))
                }}
              />
            )
          })}
        </FilterContainer>
      )}
      {showPublicTeams && (
        <FilterContainer title={t('absencePlanner.taskbar.filter.showOtherTeams')}>
          {sortTeams(ownerPublicTeams, language).map((team) => {
            return (
              <FiltersCheckBox
                key={team.id}
                dataTestId={`team-${team.id}`}
                checked={shownTeams.includes(team.id)}
                label={getFullTeamLabel(team, language, Boolean(isMultiTenant))}
                onChange={() => {
                  onChange(team, shownTeams.includes(team.id))
                }}
              />
            )
          })}
        </FilterContainer>
      )}
    </>
  )
}

export default TeamsFrontliner
