import { Locale } from 'date-fns'
import { TeamplanPersonsT } from 'types/teamplan'
import { getDateFormatByLocale, getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { capitalize } from 'utils/string'

export const getCardDate = (date: string, locale: Locale) => {
  const dateFormat = getDateFormatByLocale(locale as Locale)

  return capitalize(getFormattedFromDate(newDateWithoutTime(date), dateFormat, locale))
}

export const getTitleFromTeamplanPersons = (teamplanPersons: TeamplanPersonsT | undefined) => {
  if (!teamplanPersons) return ''
  if (teamplanPersons.type === 'team') {
    return teamplanPersons.team?.tenant?.name ?? teamplanPersons.team?.owner?.name ?? ''
  }

  return teamplanPersons.tenantName
}
