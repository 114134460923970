import theme from 'utils/theme'

export const classes = {
  topMargin: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    width: '296px',
    textAlign: 'center',
  },
}
