import theme from 'utils/theme'

export const classes = {
  regularButton: {
    color: theme.palette.primary.main,
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    '&:hover, &:focus': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
      // @ts-ignore
      borderColor: theme.palette.primary[50],
    },
    '&:hover:before, &:before': {
      background: 'transparent',
    },
  },
  sendCommentBody: {
    margin: '24px 0',
  },
  sendCommentTitle: {
    color: theme.palette.grey[600],
    marginBottom: '8px',
  },
}
