import theme from 'utils/theme'

export const registerDialogClasses = {
  dialog: {
    margin: theme.spacing(0, 2),
  },
  registerDialogButton: {
    display: 'inline-block',
    textAlign: 'right',
    width: '100%',
  },
  content: {
    '&:last-of-type': {
      marginTop: theme.spacing(1),
    },
  },
}
