import theme from 'utils/theme'

export const classes = {
  noReminderBox: {
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    gap: '4px',
    border: '1px solid',
    //@ts-ignore
    borderColor: theme.palette.primary[50],
    color: theme.palette.grey[600],
    justifyContent: 'center',
    background: theme.palette.common.white,
    alignItems: 'center',
  },
}
