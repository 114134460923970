import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const OneEmployeeM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M6.5 5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 5 18.5v-13A1.5 1.5 0 0 1 6.5 4h7.086a1.5 1.5 0 0 1 1.06.439l3.915 3.915A1.5 1.5 0 0 1 19 9.414V10.5a.5.5 0 0 1-1 0V10h-3.5A1.5 1.5 0 0 1 13 8.5V5H6.5ZM14 7.683V5.207L17.793 9H14.5a.5.5 0 0 1-.5-.5v-.817Zm2 3.817a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm-2.475 2.025a3.5 3.5 0 1 1 4.95 4.95l-.003.003c-.443-.809-1.4-1.368-2.511-1.368-1.087 0-2.029.536-2.483 1.317a3.5 3.5 0 0 1 .047-4.902ZM14.333 15a1.667 1.667 0 1 0 3.334 0 1.667 1.667 0 0 0-3.334 0Z"
      />
    </Svg>
  )
}

export default OneEmployeeM
