import theme from 'utils/theme'

export default {
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  notVisible: {
    visibility: 'hidden',
  },
  logo: {
    flex: 1,
    display: 'flex',
    height: '37px',
    alignItems: 'center',
  },
  link: {
    height: theme.spacing(1.5),
  },
}
