import favurTheme from 'utils/theme'

export const fileSelectClasses = {
  selected: (progress: number) => ({
    // @ts-ignore
    background: `linear-gradient(to right, ${favurTheme.palette.primary[50]} ${progress}%, ${favurTheme.palette.primary[400]} ${progress}%)`,
    display: 'flex',
    width: '100%',
    color: favurTheme.palette.primary.main,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    '&:hover': {
      // @ts-ignore
      backgroundColor: favurTheme.palette.primary[50],
      color: favurTheme.palette.primary.main,
    },
    padding: `${favurTheme.spacing(0.5)};`,
  }),
  iconButton: {
    '&:hover': {
      // @ts-ignore
      backgroundColor: favurTheme.palette.primary[400],
    },
  },
  button: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    padding: 0,
    '&:focus': {
      border: 'none',
    },
    textTransform: 'none',
  },
  empty: {
    display: 'flex',
    width: '100%',
    background: 'none',
    color: favurTheme.palette.primary.main,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    padding: `${favurTheme.spacing(0.5)};`,
    '&:hover': {
      // @ts-ignore
      backgroundColor: favurTheme.palette.primary[400],
      color: 'white',
    },
  },
  listItemGrid: {
    display: 'flex',
    paddingBottom: `${favurTheme.spacing(0.75)} !important`,
    paddingTop: '0 !important',
    '&:first-of-type': {
      paddingTop: `${favurTheme.spacing(0.75)} !important`,
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  labelTypography: {
    display: 'flex',
  },
  labelTypographySelected: {
    textTransform: 'none',
  },
  labelDate: {
    color: favurTheme.palette.common.black,
    alignItems: 'center',
  },
}
