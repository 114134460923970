import theme from 'utils/theme'

export const classes = {
  tabsContainer: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tabsAppBarContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    boxShadow: theme.shadows[2],
    borderRadius: 0,
    padding: 0,
    height: '40px',
    width: '100vw',
    zIndex: 20,
  },
  tabsAppBar: {
    background: 'transparent',
    boxShadow: 'none',
    paddingBottom: 0,
    marginTop: theme.spacing(0.25),
    height: '32px',
  },
  styledTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      position: 'relative',
      zIndex: 10,
    },
  },
  contentContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(2.5),
  },
  noPaddingTop: {
    paddingTop: 0,
  },
}

export const tabsClasses = {
  '&.MuiTabs-root': {
    height: theme.spacing(1.5),
  },
  '& .MuiTabs-flexContainer': {
    display: 'block',
    textAlign: 'center',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: '32px',
    top: 0,
    borderRadius: '40px',
  },
  '& .MuiTabs-scrollButtons': {
    color: theme.palette.primary.main,
    width: theme.spacing(1.75),
  },
}

export const tabItemClasses = {
  '&.MuiTab-root': {
    padding: theme.spacing(0, 0.75),
    minHeight: 'unset',
    minWidth: 'unset',
    position: 'relative',
    top: '6px',
    overflow: 'visible',
  },
  '&.MuiTab-textColorPrimary': {
    color: theme.palette.primary.main,
  },
  '&.Mui-selected': {
    color: `${theme.palette.common.white} !important`,
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  wrapper: {
    fontSize: 12,
    lineHeight: '32px',
    minWidth: '80px',
  },
}
