import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const UndoS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M.49.04a.9.9 0 0 0-.43.38L0 .54v7.92l.06.12c.09.16.25.3.42.37L.6 9h7.76l.14-.05a.75.75 0 0 0-.12-1.44 70.3 70.3 0 0 0-3-.02c-1.57 0-2.86-.01-2.86-.02l.13-.27a10.47 10.47 0 0 1 10.2-5.67 10.63 10.63 0 0 1 6.68 3.16 10.44 10.44 0 0 1 1.42 12.8 10.5 10.5 0 0 1-8.63 5c-.58.02-.65.04-.84.2a.67.67 0 0 0-.23.52c-.02.31.13.57.42.72.15.07.48.09 1.06.05a12.02 12.02 0 0 0 11.23-10.84c.05-.45.04-1.97-.01-2.41A12.04 12.04 0 0 0 13.9.14 9.38 9.38 0 0 0 12 .01 12.1 12.1 0 0 0 1.66 5.9l-.15.25V3.36L1.48.58 1.4.42a.85.85 0 0 0-.18-.24A.8.8 0 0 0 .5.04Z"
      />
    </Svg>
  )
}

export default UndoS
