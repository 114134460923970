import palette from 'utils/theme/palette'

export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  text: {
    size: '12px',
    weight: 600,
    color: palette.primary.main,
    position: 'relative',
    top: '1px',
  },
}
