import React, { useCallback, useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { NativeBiometric } from 'capacitor-native-biometric'
import CenteredBox from 'components/CenteredBox'
import { SimpleLoading } from 'components/LoadingIcon'
import { NumpadWithoutState } from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import Snackbar from 'components/Snackbar'
import Success, { defaultSuccessPageTimeout } from 'components/Success'
import { PIN_KEYCHAIN_SERVER, PIN_LENGTHS } from 'constants/highSecurityConnection'
import useDialogs from 'hooks/useDialogs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinContext from 'hooks/usePinContext'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import usePinKeysStorage from 'pages/PinLogin/usePinKeysStorage'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { classes as securityClasses } from '../../styles'
import { SecuritySetupProcessT, ISecuritySetupMutationResult } from '../../types'
import useSecuritySetupMutation from '../../useSecuritySetupMutation'
import { classes } from './styles'

const PinRepeat: React.FC<{
  goToStep: (stepName: string) => void
  hideStepper: (hide: boolean) => void
  securityProcessType: SecuritySetupProcessT
}> = ({ goToStep, hideStepper, securityProcessType }) => {
  const { t } = useFavurTranslation()
  const { getBioPinInUse, storePinInKeychain } = usePinKeysStorage()
  const history = useHistory()
  const { openDialog } = useDialogs()
  const [openError, setOpenError] = useState(false)
  const { pin, pinLength, pinChangePinConfirmed, securitySetupTokenConfirmed, setPinContext } = usePinContext()
  const [secondPin, setSecondPin] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [showBird, setShowBird] = useState(false)
  const pinLengthDisplayed = pinLength || PIN_LENGTHS.default
  const [hasBioPin, setHasBioPin] = useState(false)

  useEffect(() => {
    const getHasBioPin = async () => {
      const value = await getBioPinInUse()
      setHasBioPin(value)
    }
    getHasBioPin()
  }, [getBioPinInUse])

  const timeoutAction = useCallback(() => {
    setPinContext({})
    openDialog(securityProcessType)
    history.push(routes.dashboard)
  }, [history, openDialog, securityProcessType, setPinContext])

  // security timeout handler
  useSessionTimeout(securityProcessType, timeoutAction)

  const [mutation] = useSecuritySetupMutation(securityProcessType)
  const doMutation = useCallback(() => {
    mutation({
      pinCode: pin,
    }).then(async (data: ISecuritySetupMutationResult) => {
      setShowBird(false)
      if (data?.setPinAndEstablishSecureConnection?.success === true || data?.changePin?.success === true) {
        if (hasBioPin && pin) {
          await NativeBiometric.setCredentials({
            username: 'pin',
            password: pin,
            server: PIN_KEYCHAIN_SERVER,
          })
        }
        hideStepper(true)
        setShowSuccess(true)
      }
    })
  }, [mutation, pin, hasBioPin, hideStepper])
  const onConfirm = async () => {
    if (secondPin === pin) {
      await storePinInKeychain(pin)
      setShowBird(true)
      doMutation()
      return
    }
    setSecondPin('')
    setOpenError(true)
  }
  const resetNewPin = () => {
    setPinContext({ pin: '', pinLength: PIN_LENGTHS.default, pinChangePinConfirmed, securitySetupTokenConfirmed })
    goToStep('PIN_ENTER')
  }

  const successText = () => {
    if (securityProcessType === 'securitySetup') {
      return t('securitySetup.pinRepeat.content.success')
    }

    return t('securitySetup.pinRepeat.content.pinChangeSuccess')
  }

  useEffect(() => {
    if (!showSuccess) return undefined
    const successTimeout = setTimeout(() => {
      history.push(routes.dashboard)
      setPinContext({})
    }, defaultSuccessPageTimeout)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, history, setPinContext])

  useEffect(() => {
    if (!securitySetupTokenConfirmed && ['securitySetup', 'pinReset'].includes(securityProcessType)) {
      timeoutAction()
      return
    }
    if (!pinChangePinConfirmed && securityProcessType === 'pinChange') {
      timeoutAction()
    }
  }, [securitySetupTokenConfirmed, pinChangePinConfirmed, timeoutAction, securityProcessType])

  if (showBird) {
    return (
      <CenteredBox sx={securityClasses.birdContainer}>
        <SimpleLoading />
      </CenteredBox>
    )
  }

  return (
    <>
      {!showSuccess && (
        <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
          <Box>
            <Typography variant="h2" sx={securityClasses.title}>
              {t('securitySetup.pinRepeat.title')}
            </Typography>
            <Snackbar
              open={openError}
              setOpen={setOpenError}
              flashText={{
                title: t('securitySetup.pinRepeat.flash.title'),
                message: t('securitySetup.pinRepeat.flash.text'),
              }}
            />
          </Box>
          <Box sx={classes.pinDisplayContainer}>
            <PinDisplay pinLength={pinLengthDisplayed} pin={secondPin} secret />
          </Box>
          <Box>
            <NumpadWithoutState
              numpadType="pin"
              numpadValue={secondPin}
              setNumpadValue={setSecondPin}
              onConfirm={onConfirm}
              outputLength={pinLengthDisplayed}
            />
            <Button sx={classes.changePinLengthButton} onClick={resetNewPin} data-testid="reset-new-pin">
              {t('securitySetup.pinRepeat.footer')}
            </Button>
          </Box>
        </CenteredBox>
      )}
      {showSuccess && <Success translationKey={successText()} dataTestId="pin-set-success-message" />}
    </>
  )
}

export default PinRepeat
