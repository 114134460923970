import { keyframes } from '@emotion/react'

export const top1 = keyframes`
  0% {
    width: 5px;
    opacity: 1;
  }

  16.66% {
    width: 58px;
    opacity: 1;
  }

  50.00% {
    width: 58px;
    opacity: 1;
  }

  50.01% {
    width: 58px;
    opacity: 0;
  }

  100% {
    width: 58px;
    opacity: 0;
  }
`

export const side1 = keyframes`
  0% {
    width: 5px;
    opacity: 0;
  }

  33.32% {
    width: 5px;
    opacity: 0;
  }

  33.33% {
    width: 5px;
    opacity: 1;
  }

  50.00% {
    width: 58px;
    opacity: 1;
  }

  83.33% {
    width: 58px;
    opacity: 1;
  }

  83.34% {
    width: 58px;
    opacity: 0;
  }

  100% {
    width: 58px;
    opacity: 0;
  }
`

export const diagonal1 = keyframes`
  0% {
    width: 5px;
    opacity: 0;
  }

  16.65% {
    width: 5px;
    opacity: 0;
  }

  16.66% {
    width: 5px;
    opacity: 1;
  }

  33.33% {
    width: 79.88px;
    opacity: 1;
  }

  66.66% {
    width: 79.88px;
    opacity: 1;
  }

  66.67% {
    width: 79.88px;
    opacity: 0;
  }

  100% {
    width: 79.88px;
    opacity: 0;
  }
`

export const top2 = keyframes`
  0% {
    width: 58px;
    opacity: 0;
  }

  49.99% {
    width: 58px;
    opacity: 0;
  }

  50% {
    width: 58px;
    opacity: 1;
  }

  66.66% {
    width: 5px;
    opacity: 1;
  }

  67% {
    width: 5px;
    opacity: 0;
  }

  100% {
    width: 5px;
    opacity: 0;
  }
`

export const side2 = keyframes`
  0% {
    width: 58px;
    opacity: 0;
  }

  83% {
    width: 58px;
    opacity: 0;
  }

  83.33% {
    width: 58px;
    opacity: 1;
  }

  100% {
    width: 5px;
    opacity: 1;
  }
`

export const diagonal2 = keyframes`
  0% {
    width: 79.88px;
    opacity: 0;
  }

  66.65% {
    width: 79.88px;
    opacity: 0;
  }

  66.66% {
    width: 79.88px;
    opacity: 1;
  }

  83.33% {
    width: 5px;
    opacity: 1;
  }

  84% {
    width: 5px;
    opacity: 0;
  }

  100% {
    width: 5px;
    opacity: 0;
  }
`
