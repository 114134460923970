import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const ReminderM: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_M} stroke={stroke}>
      <path fill="none" strokeWidth="1.5" d="M11.25 6.75a6 6 0 1 0 12 0 6 6 0 0 0-12 0Z" />
      <path
        fill="none"
        strokeWidth="1.5"
        d="M17.25 4.5v3.25h2.25M17.64 16.4a7 7 0 0 0 1.11 2.35h-18s1.5-2.33 1.5-8.25A7.5 7.5 0 0 1 8 3.2M7.75 21.75a2.08 2.08 0 0 0 4 0"
      />
    </Svg>
  )
}

export default ReminderM
