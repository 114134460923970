export type ContentTypeT = 'page' | 'event' | 'newsletter'
export type EventTimelineT = 'PAST' | 'UPCOMING'

export const CONTENT_TYPES = {
  page: 'page',
  event: 'event',
  newsletter: 'newsletter',
} as const

export const EVENT_TIMELINE_FILTERS = {
  PAST: 'PAST',
  UPCOMING: 'UPCOMING',
} as const

export type CmsContentStatsT = {
  ownerId?: number
  pages?: PagesStats
  events?: EventsStats
  newsletters?: NewslettersStats
}

export type PagesStats = {
  amount?: number
}
export type EventsStats = {
  upcoming?: number
}
export type NewslettersStats = {
  latestSent?: string
  amount?: number
}
