export enum ResolvedAbsenceRequestStates {
  approved = 'approved_office',
  rejected = 'rejected_office',
}

export enum ReminderTypes {
  // Do not add new types here, add them as regular notifications
  // IE reminder_monthly_sheet_approval
  monthlySheetApproval = 'monthly_sheet_approval',
}
