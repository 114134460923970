import React, { useState } from 'react'
import { Chip, ClickAwayListener, SxProps, Theme, Tooltip } from '@mui/material'
import { shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceFullS, AbsenceHalfAfternoonS, AbsenceHalfMorningS } from 'icons'
import { shiftTimesOfDay, PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import { getBadgeLabel } from './helpers'
import { badgeClasses, tooltipClasses } from './styles'

export const getBadgeIcon = (shift: PlannedShiftT) => {
  if (shift.type !== shiftTypes.absence) return undefined
  if (shift.percent && shift.percent < 100) {
    if (shift.timeOfDay === shiftTimesOfDay.pm) return <AbsenceHalfAfternoonS />
    return <AbsenceHalfMorningS />
  }
  return <AbsenceFullS />
}

const isClickable = (shift: PlannedShiftT) => shift.type !== shiftTypes.absence

interface IBadgeProps {
  onClick?: () => void
  shift: PlannedShiftT
  tooltip?: boolean
  mini?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const Badge: React.FC<IBadgeProps> = ({ onClick, shift, tooltip, mini, disabled = false, sx }) => {
  const { t } = useFavurTranslation()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleToolTip = () => setTooltipOpen(!tooltipOpen)
  const closeToolTip = () => setTooltipOpen(false)

  const chip = (
    <Chip
      data-testid={getShiftKey(shift, 'badge')}
      label={t(getBadgeLabel(shift, Boolean(mini)))}
      icon={getBadgeIcon(shift)}
      color="primary"
      clickable={isClickable(shift)}
      onClick={() => {
        if (tooltip) toggleToolTip()
        else onClick && onClick()
      }}
      sx={[badgeClasses(shift, disabled, mini), ...(Array.isArray(sx) ? sx : [sx])]}
    />
  )

  return tooltip ? (
    <ClickAwayListener onClickAway={closeToolTip}>
      <Tooltip
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
        onClose={closeToolTip}
        title={shift.description}
        placement="top-end"
        arrow
        sx={tooltipClasses}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          },
          tooltip: {
            sx: tooltipClasses.tooltip,
          },
          arrow: {
            sx: tooltipClasses.arrow,
          },
        }}
      >
        {chip}
      </Tooltip>
    </ClickAwayListener>
  ) : (
    chip
  )
}

export default Badge
