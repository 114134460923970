import theme from 'utils/theme'

export const dialogClasses = {
  dialog: {
    padding: theme.spacing(1.25, 1, 1),
  },
  title: {
    padding: theme.spacing(0, 0, 0.25),
  },
  content: {
    padding: 0,
    maxWidth: '500px',
  },
  actions: {
    padding: theme.spacing(1, 0, 0),
  },
}
