import theme from 'utils/theme'

export const classes = {
  swipeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.sm + 96,
  },
  webExtraStyles: {
    marginLeft: '-48px',
    marginRight: '-48px',
    flexDirection: 'row',
  },
  fullWidth: {
    width: '100%',
  },
  arrowButtons: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    boxShadow: theme.shadows[2],
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    top: '252px',
  },
  arrowMarginLeft: {
    marginLeft: theme.spacing(0.5),
  },
  arrowMarginRight: {
    marginRight: theme.spacing(0.5),
  },
  buttonContainer: {
    height: '300px',
    position: 'sticky' as const,
    top: '60px',
  },
}
