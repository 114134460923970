import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const BirdNegative: React.FC<IconPropsT> = () => {
  return (
    <Svg width={12} height={22} viewBox="0 0 12 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12305 7.78462V4.06154H11.8461L8.12305 7.78462Z"
        fill="#FF0062"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12305 5.75385V4.06154H9.81535L8.12305 5.75385Z"
        fill="#FFC300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12308 4.06154L4.06154 0L0 4.06154V22L8.12308 13.8769V4.06154Z"
        fill="#E6EAF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92959 20.9846L4.79946 16.7674L4.47251 16.855L5.57928 20.9846H4.06128V21.3231H8.79974V20.9846H5.92959Z"
        fill="#E6EAF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12308 13.8769V5.75385L0 13.8769V22L8.12308 13.8769Z"
        fill="#AFBDD7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76923 7.1077L0 13.8769V19.9692L6.76923 13.2V7.1077Z"
        fill="#0A275C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75376 4.73847C5.75376 5.6733 4.99628 6.43077 4.06145 6.43077C3.12662 6.43077 2.36914 5.6733 2.36914 4.73847C2.36914 3.80363 3.12662 3.04616 4.06145 3.04616C4.99628 3.04616 5.75376 3.80363 5.75376 4.73847Z"
        fill="#7187AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9924 4.73846C4.9924 5.25259 4.57575 5.66923 4.06163 5.66923C3.54751 5.66923 3.13086 5.25259 3.13086 4.73846C3.13086 4.22434 3.54751 3.80769 4.06163 3.80769C4.57575 3.80769 4.9924 4.22434 4.9924 4.73846Z"
        fill="#191919"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6297 4.10122L4.99205 4.7386H4.06128L5.25813 3.54175C5.41666 3.70027 5.54387 3.89012 5.6297 4.10122Z"
        fill="white"
      />
    </Svg>
  )
}

export default BirdNegative
