import React, { useState } from 'react'
import { AlertColor, AlertTitle, IconButton, Alert as MuiAlert } from '@mui/material'
import { AlertErrorS, AlertInfoS, AlertSuccessS, AlertWarningS, ArrowRightThinS, CloseS } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IAlertProps {
  severity: AlertColor
  content: string
  title?: string
  onClick?: () => void
  dismissable?: boolean
  isWholeClickable?: boolean
}

const Alert: React.FC<IAlertProps> = ({
  severity,
  content,
  dismissable = false,
  title,
  onClick,
  isWholeClickable = false,
}) => {
  const [show, setShow] = useState(true)
  const onClose = () => setShow(false)

  if (!show) return null

  const isValidWholeClickable = onClick !== undefined && isWholeClickable

  return (
    <MuiAlert
      severity={severity}
      sx={[classes.alert, isValidWholeClickable && classes.wholeClickable]}
      iconMapping={{
        success: <AlertSuccessS />,
        info: <AlertInfoS />,
        error: <AlertErrorS />,
        warning: <AlertWarningS />,
      }}
      {...(isValidWholeClickable ? { onClick: onClick } : {})}
      onClose={dismissable ? onClose : undefined}
      slots={{
        closeIcon: () => <CloseS fill={palette.alert.info.text} />,
      }}
      action={
        onClick ? (
          <IconButton
            {...(!isWholeClickable ? { onClick: onClick } : {})}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <ArrowRightThinS />
          </IconButton>
        ) : undefined
      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </MuiAlert>
  )
}

export default Alert
