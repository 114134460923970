export type AvatarIndexT =
  | 'profile'
  | 'avatar32'
  | 'avatar48'
  | 'avatar64'
  | 'avatar96'
  | 'avatar128'
  | 'avatar252'
  | 'avatar504'

export const avatarSizes: Record<AvatarIndexT, AvatarIndexT> = {
  profile: 'profile',
  avatar32: 'avatar32',
  avatar48: 'avatar48',
  avatar64: 'avatar64',
  avatar96: 'avatar96',
  avatar128: 'avatar128',
  avatar252: 'avatar252',
  avatar504: 'avatar504',
}

export type AvatarLinksT = {
  profile: string
  avatar32: string
  avatar48: string
  avatar64: string
  avatar96: string
  avatar128: string
  avatar252: string
  avatar504: string
  imageUuid: string
}
