import React from 'react'
import { Box } from '@mui/material'
import BaseDrawer from 'components/BaseDrawer'
import { CmsContentBlocksT } from 'types'
import AnnouncementContent from './AnnouncementContent'
import { styles } from './styles'

interface IAnnouncementDrawerProps {
  blocks: CmsContentBlocksT[]
  open: boolean
  onClose: () => void
}

const AnnouncementDrawer: React.FC<IAnnouncementDrawerProps> = ({ blocks, open, onClose }) => {
  return (
    <BaseDrawer open={open} onClose={() => onClose()} paperSx={styles.drawer}>
      <Box sx={styles.drawerContent}>
        <AnnouncementContent blocks={blocks} />
      </Box>
    </BaseDrawer>
  )
}

export default AnnouncementDrawer
