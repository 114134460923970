import { gql } from '@apollo/client'

export const getCmsContentStatsQuery = gql`
  query getCmsContentStatsQuery {
    cmsContentStats {
      ownerId
      pages {
        amount
      }
      events {
        upcoming
      }
      newsletters {
        latestSent
        amount
      }
    }
  }
`
