import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import classes from './styles'

interface ISendReminderSuccessDialogProps {
  onClose: () => void
  isOffice?: boolean
}

const SendReminderSuccessDialog: React.FC<ISendReminderSuccessDialogProps> = ({ onClose, isOffice = false }) => {
  const { t } = useFavurTranslation()

  const dialogActions = (
    <Box>
      <Button variant="text" onClick={() => onClose()} color="secondary">
        {t('common.close')}
      </Button>
    </Box>
  )
  return (
    <Dialog
      open
      title={t('tasks.new.sendReminder.dialog.title')}
      actions={dialogActions}
      data-testid="confirmation-dialog"
    >
      <Typography variant="body2" sx={classes.body1}>
        {t(isOffice ? 'tasks.new.sendReminder.dialog.body' : 'tasks.new.sendReminder.manager.dialog.body')}
      </Typography>
    </Dialog>
  )
}

export default SendReminderSuccessDialog
