import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const ArrowLeftThinM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72041 3.21893c.29331-.29249.76818-.29182 1.06066.00148L16.62 11.0814c.1204.1207.2162.2642.2813.4217.0652.1576.0987.3264.0987.4969 0 .1705-.0335.3393-.0987.4969a1.29953 1.29953 0 0 1-.2809.4214l-7.83933 7.8613c-.29248.2933-.76735.294-1.06066.0015-.2933-.2925-.29397-.7674-.00148-1.0607L15.4177 12 7.71893 4.27959c-.29249-.29331-.29182-.76818.00148-1.06066Z"
      />
    </Svg>
  )
}

export default ArrowLeftThinM
