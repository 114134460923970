import React from 'react'
import {
  ACTION,
  ActionDispatchT,
  ChangeValueActionT,
  ClearValueActionT,
  DateRangeT,
  FilterPageStateT,
  FilterStateT,
  ReducerActionT,
} from 'components/Filter/types'
import { changeValue, clearValue } from 'components/Filter/utils'

export const checkDateRangeFilter = (dateRangeConfig: DateRangeT, dirtyFilters: FilterStateT): boolean => {
  const {
    fromDate: { name: fromDateName },
    toDate: { name: toDateName },
  } = dateRangeConfig
  const { [fromDateName]: currentFromDate, [toDateName]: currentToDate } = dirtyFilters
  // The filter is valid if any of the dates is not inputed or from < to
  return !currentFromDate || !currentToDate || currentFromDate <= currentToDate
}

export const actions = (filterPageState: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => ({
  changeValue: (filterName: string, value: string) => {
    dispatch({ type: ACTION.CHANGE_VALUE, value: { filterName, value } })
  },
  clearValue: (filterName: string) => {
    dispatch({ type: ACTION.CLEAR_VALUE, value: { filterName } })
  },
  getValue: (filterName: string) => filterPageState.dirtyFilters[filterName] || null,
})

export const reducers: ActionDispatchT = {
  [ACTION.CHANGE_VALUE]: (state, action) => {
    const actionValue = action.value as ChangeValueActionT
    const updatedFilters = changeValue(state.dirtyFilters, actionValue.filterName, actionValue.value)

    return {
      ...state,
      dirtyFilters: updatedFilters,
    }
  },
  [ACTION.CLEAR_VALUE]: (state, action) => {
    const actionValue = action.value as ClearValueActionT
    return {
      ...state,
      dirtyFilters: clearValue(state.dirtyFilters, actionValue.filterName),
    }
  },
}
