import React from 'react'
import { AvatarGroup, Box, Typography } from '@mui/material'
import Avatar from 'components/Avatar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AvatarDataT, PersonT } from 'types'
import { getFullAvatarUrl } from 'utils/avatar'
import { getAvatarNameFromPerson } from 'utils/person'
import { classes } from './styles'

const MAX_AVATARS = 8
const AVATAR_SIZE = 40

interface IConflictTypeProps {
  label: string
  count?: number
  persons?: PersonT[]
  showAvatars?: boolean
  icon: React.ReactElement
}

const ConflictType: React.FC<IConflictTypeProps> = ({ label, count = 0, persons = [], showAvatars = false, icon }) => {
  const { t } = useFavurTranslation()

  if (!count) return null

  return (
    <Box sx={classes.vacationsContainer}>
      {showAvatars && (
        <AvatarGroup max={MAX_AVATARS} sx={classes.avatarGroup(AVATAR_SIZE)}>
          {persons.map((person) => {
            const avatarData = {
              avatarName: getAvatarNameFromPerson(person),
              avatarUrl: person?.user?.avatarUrl ? getFullAvatarUrl(person?.user?.avatarUrl) : undefined,
            } as AvatarDataT
            return <Avatar size={AVATAR_SIZE} key={person.id} avatar={avatarData} />
          })}
        </AvatarGroup>
      )}
      <Box sx={classes.conflictTextRow}>
        {icon}
        <Typography variant="caption" sx={classes.captionText}>
          {t(label, { numConflicts: count })}
        </Typography>
      </Box>
    </Box>
  )
}

export default ConflictType
