export const classes = {
  dialog: {
    margin: '16px',
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  firstDialogButton: {
    marginBottom: '12px',
  },
}
