import { gql } from '@apollo/client'

export const createShareUserDataWokflowsQuery = gql`
  mutation createShareUserDataRequest(
    $personIds: [ID]!
    $triggeredByPersonId: ID!
    $comment: String
    $userComment: String
  ) {
    createShareUserDataRequest(
      personIds: $personIds
      triggeredByPersonId: $triggeredByPersonId
      comment: $comment
      userComment: $userComment
    ) {
      success
      personsWithErrors
    }
  }
`
