import React from 'react'
import { Box } from '@mui/material'
import bannerClasses from './styles'

type BannerProps = {
  actions?: React.ReactElement | string
  children?: React.ReactNode
  open: boolean
}

const Banner: React.FC<BannerProps> = ({ actions, open, children }) => {
  if (!open) {
    return null
  }

  return (
    <Box sx={bannerClasses.banner}>
      <Box sx={bannerClasses.content}>
        {children}
        {actions && <Box sx={bannerClasses.actions}>{actions}</Box>}
      </Box>
    </Box>
  )
}

export default Banner
