import theme from 'utils/theme'

export const classes = {
  card: {
    padding: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(0.5),
  },
  list: {
    paddingLeft: theme.spacing(1.5),
  },
  buttonsContainer: {
    marginBottom: 0,
  },
}
