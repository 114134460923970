import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { classes } from './styles'

interface IToggleBarProps {
  currentToggleIndex: number
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  firstOption: React.ReactNode
  secondOption: React.ReactNode
}

const ToggleBar: React.FC<IToggleBarProps> = ({ currentToggleIndex, handleChange, firstOption, secondOption }) => {
  return (
    <Tabs
      sx={classes.tabs}
      TabIndicatorProps={{
        sx: classes.indicator,
      }}
      value={currentToggleIndex}
      onChange={handleChange}
      centered
    >
      <Tab sx={classes.tab} label={firstOption} disableRipple data-testid="toggle-bar-option-one" />
      <Tab sx={classes.tab} label={secondOption} disableRipple data-testid="toggle-bar-option-two" />
    </Tabs>
  )
}

export default ToggleBar
