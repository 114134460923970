import theme from 'utils/theme'
import favurTheme from 'utils/theme'

export const resendSMSDialogClasses = {
  dialog: {
    margin: favurTheme.spacing(0, 2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(0.75),
    '&:first-of-type': {
      marginTop: theme.spacing(0),
    },
  },
  content: {
    '&:last-of-type': {
      marginTop: theme.spacing(1),
    },
  },
}
