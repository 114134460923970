import { useReducer } from 'react'
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  isSameDay,
  startOfMonth,
  differenceInCalendarISOWeeks,
  differenceInCalendarMonths,
} from 'date-fns'
import { dateToCorrectTimeZone, dateToISOStringWithoutTime, getWeekDays, newDateWithoutTime } from 'utils/date'

const calculateIndexToDay = (today: Date, dayToGo: Date) => {
  const todayWithTz = dateToCorrectTimeZone(today)
  const dayToGoWithTz = dateToCorrectTimeZone(dayToGo)

  if (isSameDay(todayWithTz, dayToGoWithTz)) {
    return 0
  }

  return differenceInCalendarDays(dayToGoWithTz, todayWithTz)
}

export interface IShiftState {
  currentDay: Date
  calendarTitleDay: Date
  referenceDay: Date
  index: number
  weekIndex: number
  monthIndex: number
  start: string
  end: string
  animateTransition: boolean
  showDelay: boolean
  calendarOpen: boolean
}

type ShiftDatesT = {
  start: string
  end: string
}

type ActionTypeT = {
  type: string
  value?: {
    date?: Date
    shiftDates?: ShiftDatesT
    calendarOpen?: boolean
  }
}

const getInitialIndex = (today: string, currentDay: string) => {
  const todayDate = newDateWithoutTime(today)
  const currentDayDate = newDateWithoutTime(currentDay)
  if (isSameDay(todayDate, currentDayDate)) return 0

  return calculateIndexToDay(currentDayDate, todayDate)
}

const getWeekInitialIndex = (today: string, currentDay: string) => {
  const todayDate = newDateWithoutTime(today)
  const currentDayDate = newDateWithoutTime(currentDay)

  return differenceInCalendarISOWeeks(todayDate, currentDayDate)
}

const getMonthInitialIndex = (today: string, currentDay: string) => {
  const todayDate = newDateWithoutTime(today)
  const currentDayDate = newDateWithoutTime(currentDay)

  return differenceInCalendarMonths(todayDate, currentDayDate)
}

const getInitialState = (shiftDates: ShiftDatesT, currentDay: string): IShiftState => {
  const today = dateToISOStringWithoutTime(newDateWithoutTime())
  return {
    ...shiftDates,
    index: getInitialIndex(currentDay, today),
    weekIndex: getWeekInitialIndex(currentDay, today),
    monthIndex: getMonthInitialIndex(currentDay, today),
    currentDay: newDateWithoutTime(currentDay),
    calendarTitleDay: newDateWithoutTime(currentDay),
    referenceDay: startOfMonth(newDateWithoutTime(currentDay)),
    animateTransition: true,
    showDelay: false,
    calendarOpen: false,
  }
}

// eslint-disable-next-line complexity
const reducer = (state: IShiftState, action: ActionTypeT) => {
  switch (action.type) {
    case 'increase': {
      const newDay = addDays(state.currentDay, 1)
      return {
        ...state,
        index: state.index + 1,
        currentDay: newDay,
        calendarTitleDay: newDay,
        referenceDay: startOfMonth(newDay),
        monthIndex: 0,
        animateTransition: true,
        weekIndex: newDay.getDay() === 1 ? state.weekIndex + 1 : state.weekIndex,
        showDelay: true,
      }
    }
    case 'decrease': {
      const newDay = addDays(state.currentDay, -1)
      return {
        ...state,
        index: state.index - 1,
        currentDay: newDay,
        calendarTitleDay: newDay,
        referenceDay: startOfMonth(newDay),
        monthIndex: 0,
        animateTransition: true,
        weekIndex: newDay.getDay() === 0 ? state.weekIndex - 1 : state.weekIndex,
        showDelay: true,
      }
    }
    case 'increaseWeek': {
      const today = newDateWithoutTime()
      const todayString = dateToISOStringWithoutTime(today)
      const firstDateWeek = getWeekDays(state.currentDay)[0]
      const newWeekDay = addWeeks(firstDateWeek, 1)
      const weekIndex = differenceInCalendarISOWeeks(newWeekDay, today)
      return {
        ...state,
        weekIndex,
        currentDay: newWeekDay,
        calendarTitleDay: newWeekDay,
        referenceDay: startOfMonth(newWeekDay),
        monthIndex: 0,
        animateTransition: false,
        index: calculateIndexToDay(today, newWeekDay),
        showDelay: false,
        start: todayString,
        end: todayString,
      }
    }
    case 'decreaseWeek': {
      const today = newDateWithoutTime()
      const todayString = dateToISOStringWithoutTime(today)
      const firstDateWeek = getWeekDays(state.currentDay)[0]
      const newWeekDay = addWeeks(firstDateWeek, -1)
      const weekIndex = differenceInCalendarISOWeeks(newWeekDay, today)
      return {
        ...state,
        weekIndex,
        currentDay: newWeekDay,
        calendarTitleDay: newWeekDay,
        referenceDay: startOfMonth(newWeekDay),
        monthIndex: 0,
        animateTransition: false,
        index: calculateIndexToDay(today, newWeekDay),
        showDelay: false,
        start: todayString,
        end: todayString,
      }
    }
    case 'gotoToday': {
      const today = newDateWithoutTime()
      const todayString = dateToISOStringWithoutTime(today)
      return {
        ...state,
        index: 0,
        currentDay: today,
        calendarTitleDay: today,
        referenceDay: startOfMonth(today),
        monthIndex: 0,
        animateTransition: false,
        showDelay: true,
        weekIndex: 0,
        start: todayString,
        end: todayString,
      }
    }
    case 'gotoDay': {
      const today = newDateWithoutTime()
      const todayString = dateToISOStringWithoutTime(today)
      const dayToGo = action.value?.date as Date
      return {
        ...state,
        index: calculateIndexToDay(today, dayToGo),
        currentDay: dayToGo,
        calendarTitleDay: dayToGo,
        referenceDay: startOfMonth(dayToGo),
        monthIndex: 0,
        animateTransition: false,
        showDelay: false,
        start: todayString,
        end: todayString,
      }
    }
    case 'increaseMonth': {
      const newMonthIndex = state.monthIndex + 1
      return {
        ...state,
        calendarTitleDay: addMonths(state.referenceDay, newMonthIndex),
        monthIndex: newMonthIndex,
        animateTransition: false,
        showDelay: false,
      }
    }
    case 'decreaseMonth': {
      const newMonthIndex = state.monthIndex - 1
      return {
        ...state,
        calendarTitleDay: addMonths(state.referenceDay, newMonthIndex),
        monthIndex: newMonthIndex,
        animateTransition: false,
        showDelay: false,
      }
    }
    case 'setCalendarOpen': {
      return {
        ...state,
        calendarTitleDay: state.currentDay,
        calendarOpen: Boolean(action.value?.calendarOpen),
        monthIndex: 0,
        animateTransition: false,
        showDelay: false,
      }
    }
    default:
      throw new Error()
  }
}

const useShiftDaysReducer = (shiftDates: ShiftDatesT, currentDay: string) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(shiftDates, currentDay))
  const handleIndexChange = (value: number) => {
    dispatch({ type: value > 0 ? 'increase' : 'decrease' })
  }
  const handleIndexWeekChange = (value: number) => {
    dispatch({ type: value > 0 ? 'increaseWeek' : 'decreaseWeek', value: { shiftDates } })
  }
  const handleIndexMonthChange = (value: number) => {
    dispatch({ type: value > 0 ? 'increaseMonth' : 'decreaseMonth', value: { shiftDates } })
  }
  const goToToday = () => dispatch({ type: 'gotoToday', value: { shiftDates } })
  const goToDay = (date: Date) => dispatch({ type: 'gotoDay', value: { date, shiftDates } })

  const toggleCalendarOpen = () => dispatch({ type: 'setCalendarOpen', value: { calendarOpen: !state.calendarOpen } })
  const closeCalendar = () => dispatch({ type: 'setCalendarOpen', value: { calendarOpen: false } })

  return {
    state,
    handleIndexChange,
    handleIndexWeekChange,
    goToToday,
    goToDay,
    handleIndexMonthChange,
    toggleCalendarOpen,
    closeCalendar,
  }
}

export default useShiftDaysReducer
