import { taskTypes, TaskTypeT } from 'shared/constants'
import { TenantReminderT } from 'types'

export const getReminderKey = (number: number): keyof TenantReminderT => {
  switch (number) {
    case 1:
      return 'first'
    case 2:
      return 'second'
    case 3:
      return 'third'
    default:
      return 'first'
  }
}

export const getReminderFromKey = (key: keyof TenantReminderT): number => {
  switch (key) {
    case 'first':
      return 1
    case 'second':
      return 2
    case 'third':
      return 3
    default:
      return 1
  }
}

export const getGlossaryKeyFromType = (type: TaskTypeT): string => {
  switch (type) {
    case taskTypes.monthlySheet:
      return 'filter.task_type.monthly_sheet'
    case taskTypes.shareUserData:
      return 'filter.task_type.share_user_data'
    case taskTypes.shiftUpdate:
      return 'filter.task_type.shift_update'
    default:
      return ''
  }
}

export const getReminderAmount = (reminderRow: TenantReminderT) =>
  Number(reminderRow.first > 0) + Number(reminderRow.second > 0) + Number(reminderRow.third > 0)
