import React, { Context, createContext, useContext, useMemo, useReducer } from 'react'
import { selectedStatesReducer } from '../reducers/selectedStatesReducer'
import { SelectedStatesActionsContextT, SelectedStatesContextT, selectedStatesActions } from '../types'

export const initialState: SelectedStatesContextT = {
  selectedPerson: null,
  selectedTaskUuid: null,
  selectedEvent: null,
  selectedDays: [],
  pinPointingSearchValue: null,
}

const initialActionsState: SelectedStatesActionsContextT = {
  setPinPointingSearchValue: () => {},
  setSelectedTaskUuid: () => {},
  setSelectedEvent: () => {},
  setSelectedPerson: () => {},
  setSelectedDays: () => {},
  deselectPerson: () => {},
  deselectDays: () => {},
  selectNewPerson: () => {},
  updateSelectedDays: () => {},
  selectTaskFromGrid: () => {},
  selectTaskFromTaskBar: () => {},
  pinPointDateRange: () => {},
}

export const SelectedStatesContext: Context<SelectedStatesContextT> = createContext(initialState)
export const SelectedStatesActionsContext: Context<SelectedStatesActionsContextT> = createContext(initialActionsState)

export const SelectedStatesProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(selectedStatesReducer, initialState)
  const dispatchFunctions: SelectedStatesActionsContextT = useMemo(
    () => ({
      setPinPointingSearchValue: (pinPointingSearchValue) => {
        dispatch({ type: selectedStatesActions.setPinPointingSearchValue, value: { pinPointingSearchValue } })
      },
      setSelectedTaskUuid: (selectedTaskUuid) => {
        dispatch({ type: selectedStatesActions.setSelectedTaskUuid, value: { selectedTaskUuid } })
      },
      setSelectedEvent: (selectedEvent) => {
        dispatch({ type: selectedStatesActions.setSelectedEvent, value: { selectedEvent } })
      },
      setSelectedPerson: (selectedPerson) => {
        dispatch({ type: selectedStatesActions.setSelectedPerson, value: { selectedPerson } })
      },
      setSelectedDays: (selectedDays) => {
        dispatch({ type: selectedStatesActions.setSelectedDays, value: { selectedDays } })
      },
      deselectPerson: () => {
        dispatch({ type: selectedStatesActions.deselectPerson })
      },
      deselectDays: () => {
        dispatch({ type: selectedStatesActions.deselectDays })
      },
      selectNewPerson: (selectedPerson) => {
        dispatch({ type: selectedStatesActions.selectNewPerson, value: { selectedPerson } })
      },
      updateSelectedDays: (selectedDay) => {
        dispatch({ type: selectedStatesActions.updateSelectedDays, value: { selectedDay } })
      },
      selectTaskFromGrid: (selectedTaskUuid, selectedPerson, selectedDays) => {
        dispatch({
          type: selectedStatesActions.selectTaskFromGrid,
          value: { selectedTaskUuid, selectedPerson, selectedDays },
        })
      },
      selectTaskFromTaskBar: (selectedTaskUuid, pinPointingSearchValue, selectedDays, pinPoint) => {
        dispatch({
          type: selectedStatesActions.selectTaskFromTaskBar,
          value: { selectedTaskUuid, pinPointingSearchValue, selectedDays, pinPoint },
        })
      },
      pinPointDateRange: (pinPointingSearchValue, selectedDays) => {
        dispatch({
          type: selectedStatesActions.pinPointDateRange,
          value: { pinPointingSearchValue, selectedDays },
        })
      },
    }),
    [],
  )

  return (
    <SelectedStatesContext.Provider value={state}>
      <SelectedStatesActionsContext.Provider value={dispatchFunctions}>
        {children}
      </SelectedStatesActionsContext.Provider>
    </SelectedStatesContext.Provider>
  )
}

export const useSelectedStatesContext = () => {
  const context = useContext(SelectedStatesContext)

  return { ...context }
}

export const useSelectedStatesActionsContext = () => {
  const context = useContext(SelectedStatesActionsContext)

  return { ...context }
}

export default useSelectedStatesContext
