import { useEffect, useMemo, useState } from 'react'
import { ACTIVE_PERSON_STATUS, INACTIVE_PERSON_STATUS } from 'constants/person'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import { ownerPersonsQuery, ownerTenantsPersonsQuery } from 'pages/Teams/queries'
import { AuthPersonIdAndPermissions, OwnerT, PersonT, ROLES } from 'types'
import { userCanManageGlobalTeams } from 'utils/roles'

export const getTenants = (userPersons: AuthPersonIdAndPermissions[] | undefined): string[] | undefined =>
  userPersons
    ?.flatMap(
      (person) => person.roles?.filter((role) => role.role === ROLES.manageTeams).map((role) => role.tenantId) ?? [],
    )
    .filter((value, index, self) => self.indexOf(value) === index)

export const getPersons = (
  canManageGlobalTeams: boolean,
  dataOwner: { getOwner: OwnerT } | undefined,
  dataOwnerTenant: { getOwner: OwnerT } | undefined,
) =>
  ((canManageGlobalTeams
    ? dataOwner?.getOwner?.persons
    : dataOwnerTenant?.getOwner.tenants?.flatMap((tenant) => tenant.persons)) as PersonT[]) ?? []

export const usePersonsOwner = (ownerId: number) => {
  const [hasMultipleTenants, setHasMultipleTenants] = useState(false)
  const { persons: userPersons } = useRolesViews()

  const ownerFiltertedPersons = userPersons?.filter(
    (person) => person.ownerId === ownerId,
  ) as AuthPersonIdAndPermissions[]

  const canManageGlobalTeams = userCanManageGlobalTeams(ownerFiltertedPersons)
  const tenants = getTenants(ownerFiltertedPersons)
  const skipOwnerPersonsQuery = useMemo(() => !canManageGlobalTeams || ownerFiltertedPersons.length === 0, [
    canManageGlobalTeams,
    ownerFiltertedPersons.length,
  ])
  const skipOwnerTenantsPersonsQuery = useMemo(() => canManageGlobalTeams || ownerFiltertedPersons.length === 0, [
    canManageGlobalTeams,
    ownerFiltertedPersons.length,
  ])

  const [activePersons, setActivePersons] = useState<PersonT[]>([])
  const [inactivePersons, setInactivePersons] = useState<PersonT[]>([])

  const { data: dataOwner, loading: loadingOwner } = useJamesApolloQuery<{ getOwner: OwnerT }>(ownerPersonsQuery, {
    fetchPolicy: 'no-cache',
    variables: { ownerId },
  })

  const { data: dataOwnerTenant, loading: loadingOwnerTenant } = useJamesApolloQuery<{ getOwner: OwnerT }>(
    ownerTenantsPersonsQuery,
    {
      fetchPolicy: 'no-cache',
      skip: skipOwnerTenantsPersonsQuery,
      variables: { ownerId, tenantIds: tenants },
    },
  )

  useEffect(() => {
    if (loadingOwner || loadingOwnerTenant) return

    const personsOwnerOrTenant = getPersons(
      canManageGlobalTeams,
      skipOwnerPersonsQuery ? undefined : dataOwner,
      dataOwnerTenant,
    )
    setActivePersons(
      personsOwnerOrTenant.filter(
        (person) => person.status !== undefined && ACTIVE_PERSON_STATUS.includes(person.status),
      ),
    )
    setInactivePersons(
      personsOwnerOrTenant.filter(
        (person) => person.status !== undefined && INACTIVE_PERSON_STATUS.includes(person.status),
      ),
    )

    setHasMultipleTenants((dataOwner?.getOwner.tenants?.length ?? 0) > 1)
  }, [canManageGlobalTeams, dataOwner, dataOwnerTenant, loadingOwner, loadingOwnerTenant, skipOwnerPersonsQuery])

  return { activePersons, inactivePersons, loading: loadingOwner || loadingOwnerTenant, hasMultipleTenants }
}
