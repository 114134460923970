import React, { PropsWithChildren } from 'react'
import MaterialUIGrid, { Grid2Props as MaterialUiGridProps } from '@mui/material/Unstable_Grid2'

interface IGridProps extends MaterialUiGridProps {
  col?: 0 | 1 | 2 | 3 | 4 | undefined
}

type Xs = false | 3 | 6 | 9 | 12

const StyledGrid: React.FC<PropsWithChildren<IGridProps>> = ({ children, col, container, ...props }: IGridProps) => {
  const xs = col ? ((col * 3) as Xs) : false

  if (container) {
    return (
      <MaterialUIGrid container spacing={1} {...props}>
        {children}
      </MaterialUIGrid>
    )
  }

  return (
    <MaterialUIGrid {...props} xl={false} lg={false} md={false} sm={false} xs={xs}>
      {children}
    </MaterialUIGrid>
  )
}

export default StyledGrid
