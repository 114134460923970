import React from 'react'
import { Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import FamilyForm from 'components/Forms/Family'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useParams } from 'react-router-dom'
import { UserDataUrlParamsT } from '../../types'
import { userDataFormClasses as classes } from './styles'

const Family: React.FC<{ goToStep: (stepName: string, additionalUrlParams?: string[]) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const { favurUuid } = useParams<UserDataUrlParamsT>()

  return (
    <CenteredBox sx={classes.container}>
      <Typography variant="h2" sx={classes.heading}>
        {t('component.forms.family.title')}
      </Typography>
      <Typography variant="caption" sx={classes.subHeading}>
        {t('component.forms.mandatoryField')}
      </Typography>
      <FamilyForm onCancel={() => goToStep('personal', [favurUuid])} accessPoint="shareUserDataWorkflow" />
    </CenteredBox>
  )
}

export default Family
