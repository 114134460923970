import theme from 'utils/theme'

export const monthSwitcherBarClasses = {
  container: {
    backgroundColor: theme.palette.common.white,
    height: '48px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
  },
  dateContainer: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  dateText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    width: '72px',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  iconButtons: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.primary.main,
  },
  textToday: {
    marginLeft: '8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  flexContainer: {
    margin: '8px 12px',
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  },
  sideMenuIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
    position: 'relative',
    paddingTop: '50%',
  },
  emptyHeaderBlock: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      marginRight: '12px',
      display: 'block',
      width: '32px',
      paddingTop: '8px',
      paddingRight: '4px',
    },
  },
}
