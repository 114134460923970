import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { RemoveM } from 'icons'
import { PersonByPermissionsT } from 'pages/Teams/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import type { MutationResult, TeamT } from 'types'
import { getTeamNameByLanguage } from 'types/utils'
import { getUserName } from 'utils/person'
import { useMutation } from '@apollo/client'
import { classes } from '../../../styles'
import CardToggle from './CardToggle'
import DeleteDialog from './DeleteDialog'
import { deleteTeamMutation } from './queries'
import useUpdateTeamToggleStatus from './useUpdateTeamToggleStatus'

interface ISettingsTabProps {
  team: TeamT
  personsByPermission: PersonByPermissionsT
  refetch: () => void
}

const SettingsTab: React.FC<ISettingsTabProps> = ({ team, personsByPermission, refetch }) => {
  const history = useHistory()
  const { t, language } = useFavurTranslation()
  const { onStatusChange: onPublicStatusChange } = useUpdateTeamToggleStatus({
    team,
    fieldName: 'public',
    refetchTeam: refetch,
  })

  const { onStatusChange: onShowTeamplanCostCentersStatusChange } = useUpdateTeamToggleStatus({
    team,
    fieldName: 'showTeamplanCostCenters',
    refetchTeam: refetch,
  })
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [mutate] = useMutation<{ deleteTeam: MutationResult }>(deleteTeamMutation)

  const onDelete = useCallback(async () => {
    const result = await mutate({
      variables: {
        id: team.id,
      },
    })
    if (result.data?.deleteTeam?.success) {
      history.push(`${routes.teams}`)
    }
  }, [history, mutate, team.id])

  const teamName = team ? getTeamNameByLanguage(team, language) : '-'

  const persons = personsByPermission.get('supervisor')

  return (
    <>
      <Card marginBottom="16px">
        <Box sx={classes.settingsCard}>
          <Typography variant="subtitle1" sx={classes.supervisorCardTitle}>
            {t('teams.settings.supervisor.title')}
          </Typography>
          <Typography variant="body2" sx={classes.supervisorCardBodyText}>
            {t('teams.settings.supervisor.body')}
          </Typography>
          <DivisionLine />
          <Box>
            {persons && persons.length > 0 ? (
              <Box component="ul" sx={classes.workflowPersonsList}>
                {persons?.map((person) => {
                  return (
                    <li key={person.id}>
                      <Typography variant="body2" sx={classes.workflowPersonListItemText}>
                        {getUserName(person)}
                      </Typography>
                    </li>
                  )
                })}
              </Box>
            ) : (
              <Box sx={classes.noPersonInWorkflow}>
                <Typography variant="caption" sx={classes.noPersonSupervisorText}>
                  {t('teams.settings.supervisor.noemployee')}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={classes.supervisorEditButton}>
            <Button
              type="submit"
              size="small"
              variant="text"
              color="secondary"
              onClick={() => history.push(`${routes.teams}/${team.id}/${routes.teamEditPermissionMembers}/supervisor`)}
            >
              {t(`teams.settings.supervisor.select.button`)}
            </Button>
          </Box>
        </Box>
      </Card>
      <CardToggle
        title={t('teams.settings.public.title')}
        body={t('teams.settings.public.body')}
        name="publicTeam"
        currentStatus={Boolean(team.public)}
        onStatusChange={onPublicStatusChange}
      />
      <CardToggle
        title={t('teams.settings.showTeamplanCostCenters.title')}
        body={t('teams.settings.showTeamplanCostCenters.body')}
        name="showTeamplanCostCentersTeam"
        currentStatus={Boolean(team.showTeamplanCostCenters)}
        onStatusChange={onShowTeamplanCostCentersStatusChange}
      />
      <Card marginBottom="16px">
        <Box sx={classes.settingsCard}>
          <Typography variant="subtitle1" sx={classes.settingsCardTitle}>
            {t('teams.settings.label')}
          </Typography>
          <Box sx={classes.settingsNameContainer}>
            <Typography variant="body2">{teamName}</Typography>
            <Button
              type="submit"
              size="small"
              variant="text"
              color="secondary"
              onClick={() => {
                history.push(`${routes.teams}/${team.id}/${routes.teamEditSettings}`)
              }}
            >
              {t('teams.settings.button.editName')}
            </Button>
          </Box>
          <DivisionLine />
          <Box sx={classes.settingsButtonContainer}>
            <Button
              type="submit"
              size="small"
              variant="text"
              color="primary"
              startIcon={<RemoveM />}
              onClick={() => setOpenDeleteDialog(true)}
            >
              {t('common.delete')}
            </Button>
          </Box>
          <DeleteDialog
            open={openDeleteDialog}
            actionCancel={() => setOpenDeleteDialog(false)}
            actionDelete={onDelete}
          />
        </Box>
      </Card>
    </>
  )
}

export default SettingsTab
