export const classes = {
  closeContainer: {
    marginTop: '8px',
    textAlign: 'left',
    '& .MuiButton-root': {
      justifyContent: 'flex-start',
      paddingLeft: 0,
    },
  },
  contentContainer: {
    paddingBottom: 'calc(100px + var(--ion-safe-area-bottom))',
  },
}
