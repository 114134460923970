import theme from 'utils/theme'

export const classes = {
  hrCodeInput: {
    marginTop: theme.spacing(3),
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontSize: '33px',
      textAlign: 'center',
      width: '272px',
    },
  },
  hrCodeInputLabel: {
    transform: 'translate(0,0)',
    width: '100%',
    textAlign: 'center',
    fontSize: theme.spacing(0.75),
  },
}
