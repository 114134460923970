import theme from 'utils/theme'

export const classes = {
  settingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '8px',
    marginLeft: '4px',
  },
  settingTitle: {
    color: theme.palette.grey[900],
    fontWeight: 400,
  },
  bolderBody: {
    fontWeight: 600,
  },
  inAppContainer: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    marginRight: 0,
    marginLeft: 0,
    padding: '8px',
    paddingLeft: '16px',
    borderRadius: '6px',
    marginTop: '4px',
  },
}
