import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import Switch from 'components/Switch'
import { classes } from '../../../styles'

interface ICardToggle {
  body: string
  currentStatus: boolean
  onStatusChange: (newStatus: boolean) => void
  name: string
  title: string
}

const CardToggle: React.FC<ICardToggle> = ({ title, body, name, currentStatus, onStatusChange }) => {
  return (
    <Card marginBottom="16px">
      <Box sx={classes.settingsCard}>
        <Box sx={classes.toggleTitleRow}>
          <Typography variant="subtitle1" sx={classes.supervisorCardTitle}>
            {title}
          </Typography>
          <Switch
            checked={currentStatus}
            name={name}
            onChange={(e) => {
              onStatusChange(e.target.checked)
            }}
          />
        </Box>
        <Typography variant="body2" sx={classes.supervisorCardBodyText}>
          {body}
        </Typography>
      </Box>
    </Card>
  )
}

export default CardToggle
