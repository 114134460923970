import theme from 'utils/theme'

export const classes = {
  listItem: {
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flexGrow: '0 !important' as any,
    },
  },
  displayButton: {
    background: 'none',
    display: 'flex',
    textAlign: 'left',
    boxSizing: 'content-box',
    padding: 0,
    margin: 0,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    fontSize: 'inherit',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    // @ts-ignore
    color: theme.palette.grey[900],
    flexGrow: 1,
  },
  caption: {
    textTransform: 'none',
    display: '',
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    fontFamily: 'FiraSansCondensed, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.2px',
    color: theme.palette.grey[900],
  },
  subText: { paddingTop: theme.spacing(0.25) },
  warning: {
    position: 'relative',
    top: theme.spacing(0.25),
    right: theme.spacing(0.1875),
  },
  warningLeft: {
    marginLeft: theme.spacing(0.9375),
  },
  warningText: {
    color: theme.palette.secondary.main,
    position: 'relative',
    marginLeft: theme.spacing(0.25),
    bottom: theme.spacing(0.25),
    fontFamily: 'FiraSansCondensed, sans-serif',
    fontWeight: 400,
    fintSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.2px',
  },
  listItemTop: {
    marginTop: theme.spacing(0.75),
  },
  subTextBottom: {
    marginBottom: theme.spacing(0.5),
  },
  arrowIcon: {
    // @ts-ignore
    color: theme.palette.primary[700],
    alignSelf: 'start',
  },

  text: {
    fontSize: theme.spacing(1.0),
    fontFamily: 'FiraSans, sans-serif',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[900],
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  checkbox: {
    padding: 0,
  },
}
