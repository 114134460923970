import theme from 'utils/theme'

export const entryTypesClasses = {
  requestButton: {
    width: '100%',
    borderRadius: '18px',
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    padding: '9px 24px',
  },
  checkBox: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
    '& .MuiSvgIcon-root': { fontSize: 16 },
  },
}
