import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const TasksCenterL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M30.252 4.16a1 1 0 1 0-1.504-1.32L16.975 16.272a.66.66 0 0 1-.24.171.74.74 0 0 1-.62-.021.64.64 0 0 1-.222-.184l-3.594-4.782a1 1 0 1 0-1.598 1.202l3.593 4.781c.241.321.553.58.91.763a2.734 2.734 0 0 0 2.312.083 2.66 2.66 0 0 0 .963-.694L30.252 4.16Zm-6.91.786a13 13 0 1 0 4.979 5.644 1 1 0 0 0-1.819.832 11 11 0 1 1-4.213-4.776 1 1 0 0 0 1.052-1.7Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default TasksCenterL
