import sortBy from 'lodash/fp/sortBy'
import type { PlannedShiftT, TeamT } from 'types'

export type DateGetterT = {
  baseDate: string
  month: Date
  sliderIndex: number
}

export type DeadlinesT = '24h'

export const getTeamNameByLanguage = (team: TeamT, lang: string): string => {
  const names: Record<string, string> = {
    de: team.nameDe,
    en: team.nameEn,
    fr: team.nameFr,
    it: team.nameIt,
  }

  return names[lang] ?? '-'
}

export const orderTeamsByLanguage = (teams: TeamT[], lang: string): TeamT[] =>
  sortBy((team) => getTeamNameByLanguage(team, lang), teams)

export const getShiftKey = (shift: PlannedShiftT, prefix?: string) => {
  return `${prefix ? `${prefix}-` : ''}${shift.id}`
}
