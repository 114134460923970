import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFilterUtils from 'components/Filter/useFilterUtils'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { groupBy } from 'lodash/fp'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import { getFormattedFromISOString, stringToFavurDate } from 'utils/date'
import { getShortName } from 'utils/person'
import { taskStates } from '../constants'
import classes from '../styles'
import { ShiftUpdateTaskT, TaskCardDataT } from '../types'

const dateFormat = 'dd.MM.yyyy - HH:mm'
type TranslationValueT = { key: string; values: { [key: string]: string | number | undefined } }

const ShiftUpdateBody: React.FC<TaskCardDataT<ShiftUpdateTaskT>> = ({ office, task, onDelete, sendManualReminder }) => {
  const history = useHistory()
  const { t, locale } = useFavurTranslation()
  const { setLoadFilterTasks } = useFilterUtils()
  const { storeState } = useFilterContext()
  const { setBackLink } = useStateBackLink()
  const taskState = task.statusFe

  // eslint-disable-next-line fp/no-mutating-methods
  const shiftDatesHistory = useMemo(() => Object.keys(groupBy('date', task.shiftsUpdatedHistory ?? [])).sort(), [task])
  const shiftNumber = shiftDatesHistory.length

  const titleTranslation = useMemo(() => {
    const status = (() => {
      if (taskState === taskStates.todo) return taskStates.todo
      if (taskState === taskStates.pending) return taskStates.pending
      if (task.status === ShiftUpdateStatus.outdated) return ShiftUpdateStatus.outdated
      return taskStates.closed
    })()
    if (office) {
      const personName = getShortName(task.person as PersonT)
      if (shiftNumber > 1) {
        return t(`tasks.new.shiftUpdate.manager.${status}.headline.multiple`, { shifts: shiftNumber, personName })
      }
      return t(`tasks.new.shiftUpdate.manager.${status}.headline.single`, { personName })
    }
    if (shiftNumber > 1) {
      return t(`tasks.new.shiftUpdate.frontliner.${status}.headline.multiple`, { shifts: shiftNumber })
    }
    return t(`tasks.new.shiftUpdate.frontliner.${status}.headline.single`)
  }, [office, shiftNumber, t, task.person, task.status, taskState])

  const contentTranslations: TranslationValueT[] = useMemo(() => {
    const periodContent = (() => {
      if (shiftNumber === 0) return undefined

      const start = stringToFavurDate(shiftDatesHistory[0])
      if (shiftNumber > 1) {
        const end = stringToFavurDate(shiftDatesHistory[shiftNumber - 1])
        return { key: 'tasks.new.shiftUpdate.period.multiple', values: { start, end, shiftNumber } }
      }
      return { key: 'tasks.new.shiftUpdate.period.single', values: { start } }
    })()
    if (office) {
      if (taskState === taskStates.pending) {
        const publishedAt = task.taskData?.publishedAt
          ? getFormattedFromISOString(task.taskData.publishedAt, dateFormat, locale)
          : null
        return [
          ...(periodContent ? [periodContent] : []),
          ...(publishedAt ? [{ key: 'tasks.new.shiftUpdate.publishedAt', values: { publishedAt } }] : []),
        ]
      }
      const acknowledgedAt = (() => {
        if (task.status === ShiftUpdateStatus.outdated) return t('tasks.new.shiftUpdate.outdated')
        if (!task.taskData?.acknowledgedAt) return null
        return getFormattedFromISOString(task.taskData.acknowledgedAt, dateFormat, locale)
      })()
      return [
        ...(periodContent ? [periodContent] : []),
        ...(acknowledgedAt ? [{ key: 'tasks.new.shiftUpdate.acknowledgedAt', values: { acknowledgedAt } }] : []),
      ]
    }

    const tenant = { key: 'tasks.new.shiftUpdate.tenant', values: { tenantName: task.tenantName } }
    return [tenant, ...(periodContent ? [periodContent] : [])]
  }, [
    locale,
    office,
    shiftDatesHistory,
    shiftNumber,
    t,
    task.status,
    task.taskData.acknowledgedAt,
    task.taskData.publishedAt,
    task.tenantName,
    taskState,
  ])

  const detailsCallback = useCallback(() => {
    setLoadFilterTasks()
    storeState()
    setBackLink(`${routes.openTasks}`)
    history.push(`${routes.shiftUpdateDetail}/${task.favurUuid}/${task.statusFe}`)
  }, [history, setBackLink, setLoadFilterTasks, storeState, task.favurUuid, task.statusFe])

  const buttons = useMemo(() => {
    if (!office || (office && taskState === taskStates.closed)) {
      return (
        <Typography
          sx={classes.callToAction}
          variant="button"
          component="button"
          onClick={detailsCallback}
          data-testid="shift-update__view-details"
        >
          {t(taskState === taskStates.todo ? 'tasks.new.shiftUpdate.verify' : 'tasks.new.shiftUpdate.details')}
        </Typography>
      )
    }

    if (taskState === taskStates.pending) {
      return (
        <>
          {sendManualReminder && (
            <Typography
              sx={classes.callToAction}
              variant="button"
              component="button"
              onClick={() => sendManualReminder()}
            >
              {t(`tasks.new.sendReminder.button`)}
            </Typography>
          )}
          <Typography
            sx={classes.callToAction}
            variant="button"
            component="button"
            onClick={() => onDelete && onDelete(task)}
          >
            {t(`common.delete`)}
          </Typography>
        </>
      )
    }

    return null
  }, [office, taskState, detailsCallback, t, sendManualReminder, task, onDelete])

  return (
    <Box sx={classes.taskCardBody} data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-shift-update-body`}>
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle1">
        {titleTranslation}
      </Typography>
      <Box sx={[classes.taskCardContentWrapper, !buttons && classes.noButton]}>
        {contentTranslations.map((line) => (
          <Typography key={line.key} sx={classes.taskCardBodyContent} variant="body2">
            <Trans i18nKey={line.key} components={{ b: <b /> }} values={line.values} />
          </Typography>
        ))}
      </Box>
      {buttons && <Box sx={classes.taskCardCTAContainer}>{buttons}</Box>}
    </Box>
  )
}

export default ShiftUpdateBody
