import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const classes = {
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // 82 comes from 50px from desired margin + paddingX
    width: 'min(100% - 82px, 285px);',
    borderRadius: `${theme.spacing(0.5)} 0 0 0`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    top: `var(--ion-safe-area-top)`,
    height: `calc(100% - var(--ion-safe-area-top))`,
  },
  blockMenuContainer: {
    '& > :nth-of-type(2)': {
      marginTop: theme.spacing(1.25),
    },
  },
  menuContainerAbsence: {
    display: 'flex',
    flexDirection: 'column',
  },
  caption: {
    // @ts-ignore
    color: theme.palette.primary[900],
  },
  sideMenuButton: {
    // @ts-ignore
    color: theme.palette.primary[700],
    justifyContent: 'flex-start',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  absenceButton: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    borderRadius: '18px',
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    padding: '9px 22px',
    width: '100%',
    position: 'relative',
    right: '10px',
  },
  sideMenuButtonIcon: {
    marginRight: theme.spacing(0.5),
  },
  sideMenuButtonText: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
  },
  checkListOption: {
    paddingLeft: theme.spacing(0.5),
    fontWeight: 350,
  },
  checkBox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    '&:hover': { background: 'none' },
  },
  blocksContainer: {
    marginTop: `calc(${theme.spacing(2)} + var(--ion-safe-area-top))`,
    paddingBottom: 'var(--ion-safe-area-bottom)',
  },
  eventsBlock: {
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  eventsText: {
    marginLeft: '12px',
    marginRight: theme.spacing(1),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: palette.primary.main,
  },
  eventsArrow: {
    width: '16px',
    position: 'relative',
    top: '6px',
  },
  eventsMenu: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'space-between',
    marginRight: '16px',
    marginBottom: '6px',
  },
  eventsIcon: {
    position: 'relative',
    top: '6px',
  },
}
