import { createContext, Context } from 'react'
import { IAppStatesContext, StateValueT } from './types'

const initialState: IAppStatesContext = {
  set: (_key: string, _value: StateValueT) => {},
  get: (_key: string) => null,
  clear: (_key: string) => {},
  clearAll: () => {},
}

const AppStatesContext: Context<IAppStatesContext> = createContext(initialState)

export default AppStatesContext
