import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const MenuBurgerL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M5 5h22c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1zm0 9h22c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1zm0 9h22c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1z"
      />
    </Svg>
  )
}

export default MenuBurgerL
