import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const EventM: React.FC<IconPropsT> = ({ fill = '#505673', sx }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill} sx={sx}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.671.034a1.68 1.68 0 0 0-.903.557c-.093.113-.462.83-2.393 4.661l-.938 1.86-3.007.297c-1.653.163-3.088.311-3.187.33A1.587 1.587 0 0 0 .031 8.936c-.096.46.033.978.329 1.32.057.066 1.224 1.231 2.594 2.59 1.726 1.711 2.487 2.48 2.48 2.507-.081.273-1.84 6.698-1.86 6.79-.064.305-.007.7.143.996a1.574 1.574 0 0 0 2.038.718c.06-.026 1.484-.73 3.166-1.562a389.65 389.65 0 0 1 3.075-1.515c.009 0 1.425.697 3.146 1.55 1.721.852 3.194 1.572 3.273 1.599.323.111.807.079 1.134-.076.433-.205.771-.628.869-1.088.036-.172.037-.493.002-.65-.023-.103-1.835-6.706-1.86-6.773-.004-.015 1.12-1.144 2.5-2.508 1.379-1.365 2.556-2.547 2.616-2.626.15-.199.216-.337.284-.601.06-.233.081-.563.035-.563-.013 0-.024-.024-.024-.054 0-.11-.098-.366-.213-.558a1.845 1.845 0 0 0-.451-.457 1.338 1.338 0 0 0-.467-.212c-.123-.03-6.063-.639-6.24-.639-.03 0-.32-.554-1.053-2.01C13.714 1.477 13.317.706 13.222.59a1.649 1.649 0 0 0-.923-.558 1.525 1.525 0 0 0-.628.002Zm-1.407 4.828c-1.59 3.16-1.696 3.362-1.82 3.488a.628.628 0 0 1-.253.174c-.08.027-1.195.146-3.393.364l-3.283.324c-.07 0 .388.468 2.697 2.756 2.267 2.247 2.702 2.69 2.756 2.8.13.264.177.056-.92 4.041a303.659 303.659 0 0 0-.989 3.63c0 .026.017.045.04.045.022 0 1.483-.714 3.246-1.588a743.818 743.818 0 0 1 3.338-1.647.661.661 0 0 1 .315-.06l.183-.002.717.351c.395.194 1.905.94 3.357 1.66 2.074 1.027 2.647 1.302 2.67 1.279.023-.023-.182-.797-.967-3.641-.933-3.383-.996-3.623-.99-3.78.014-.327-.146-.151 2.826-3.094 1.48-1.464 2.69-2.677 2.69-2.695a.051.051 0 0 0-.03-.044c-.017-.006-1.494-.157-3.283-.335a179.18 179.18 0 0 1-3.341-.348.85.85 0 0 1-.356-.264c-.036-.046-.824-1.588-1.75-3.426-1.303-2.583-1.695-3.342-1.729-3.342-.033 0-.426.76-1.731 3.354ZM.004 9.26c0 .112.004.158.009.102a1.372 1.372 0 0 0 0-.204c-.005-.056-.01-.01-.01.102Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default EventM
