import React, { useCallback, useEffect, useState } from 'react'
import { BiometryType, NativeBiometric } from 'capacitor-native-biometric'
import { PIN_KEYCHAIN_SERVER } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinKeysStorage from 'pages/PinLogin/usePinKeysStorage'
import { isNativeNoOverride } from 'utils/platform'
import NumpadInputFaceId from './NumpadInputFaceId'
import NumpadInputTouchId from './NumpadInputTouchId'

export interface INumpadInputBiometric {
  setNumpadValue: (input: string) => void
}

const NumpadInputBiometric: React.FC<INumpadInputBiometric> = ({ setNumpadValue }) => {
  const { t } = useFavurTranslation()
  const { getBioPinDisabled, getBioPinInUse } = usePinKeysStorage()

  const [biometricIsAvailable, setBiometricIsAvailable] = useState(false)
  const [biometricTypeAvailable, setBiometricTypeAvailable] = useState(BiometryType.NONE)
  const [hasAvailableCredentials, setHasAvailableCredentials] = useState(false)

  const maybeSetHasAvailableCredentials = useCallback(async () => {
    const bioPinInUse = await getBioPinInUse()
    const bioPinDisabled = await getBioPinDisabled()
    setHasAvailableCredentials(bioPinInUse && !bioPinDisabled)
  }, [getBioPinDisabled, getBioPinInUse])

  useEffect(() => {
    if (isNativeNoOverride()) {
      NativeBiometric.isAvailable().then(({ biometryType, isAvailable }) => {
        setBiometricIsAvailable(isAvailable)
        setBiometricTypeAvailable(biometryType)
        if (isAvailable) {
          maybeSetHasAvailableCredentials()
        }
      })
    }
  }, [maybeSetHasAvailableCredentials])

  const handleKeychainPin = useCallback(async () => {
    const verified = await NativeBiometric.verifyIdentity({
      reason: t('component.pinLogin.info'),
      title: t('component.pinLogin.title'),
    })
      .then(() => true)
      .catch(() => false)
    if (!verified) return

    const credentials = await NativeBiometric.getCredentials({
      server: PIN_KEYCHAIN_SERVER,
    })

    setNumpadValue(credentials.password)
  }, [setNumpadValue, t])

  useEffect(() => {
    if (biometricIsAvailable && biometricTypeAvailable !== BiometryType.NONE && hasAvailableCredentials) {
      // we want to call automatically the face id when available
      handleKeychainPin()
    }
  }, [biometricIsAvailable, biometricTypeAvailable, handleKeychainPin, hasAvailableCredentials])

  if (biometricIsAvailable && biometricTypeAvailable !== BiometryType.NONE && hasAvailableCredentials) {
    switch (biometricTypeAvailable) {
      case BiometryType.TOUCH_ID:
      case BiometryType.FINGERPRINT: {
        return <NumpadInputTouchId onClick={handleKeychainPin} />
      }
      default:
        return <NumpadInputFaceId onClick={handleKeychainPin} />
    }
  } else {
    return null
  }
}

export default NumpadInputBiometric
