import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AcceptMultipleWtcDrawer from '../AcceptMultipleWtcDrawer'
import usePerformEventToTask from './usePerformEventToTask'

interface IAcceptProps {
  taskUuid: string
  prevMonthTaskUuid: string
  onAction: () => void
}

const AcceptMultiple: React.FC<IAcceptProps> = ({ taskUuid, prevMonthTaskUuid, onAction }) => {
  const { t } = useFavurTranslation()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { loading: loadingPrevious, accept: acceptPrevious } = usePerformEventToTask(prevMonthTaskUuid)
  const { loading: loadingCurrent, accept: acceptCurrent } = usePerformEventToTask(taskUuid, onAction)

  const acceptBoth = useCallback(() => {
    acceptPrevious().then(() => acceptCurrent())
  }, [acceptCurrent, acceptPrevious])

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setDrawerOpen(true)
        }}
        disabled={loadingPrevious || loadingCurrent}
        data-testid="monthly-sheet-detail_accept-sheet"
      >
        {t('workTimeControl.buttons.accept')}
      </Button>
      <AcceptMultipleWtcDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        acceptBoth={acceptBoth}
        acceptCurrent={() => {
          acceptCurrent()
        }}
        loading={{ previous: loadingPrevious, current: loadingCurrent }}
      />
    </>
  )
}

export default AcceptMultiple
