import theme from 'utils/theme'

export const classes = {
  card: {
    background: theme.palette.common.white,
    borderRadius: '40px',
    boxShadow: theme.shadows[10],
    display: 'flex',
    padding: '48px 24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    alignItems: 'center',
    color: theme.palette.grey[600],
    width: '100%',
  },
  name: {
    fontWeight: 600,
    lineHeight: 1.5,
  },
  divisionLineContainer: {
    width: '104px',
  },
}
