import React from 'react'
import { Box, Typography } from '@mui/material'
import SuccessGeneric from 'components/SuccessGeneric'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

const SuccessScreen: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <SuccessGeneric>
      <Box sx={classes.container}>
        <Typography sx={classes.topMargin} variant="h2" color="primary">
          {t('requestEmployeeData.success.title')}
        </Typography>
        <Typography sx={classes.topMargin} variant="body1" color="primary">
          {t('requestEmployeeData.success.body1')}
        </Typography>
        <Typography sx={classes.topMargin} variant="body1" color="primary">
          {t('requestEmployeeData.success.body2')}
        </Typography>
      </Box>
    </SuccessGeneric>
  )
}

export default SuccessScreen
