import React from 'react'
import {
  createTimeLineItem,
  createTimelineItemHeaderOnlyText,
  createTimelineItemHeaderWithAvatarIcon,
} from 'components/Timeline/factory'
import { TimelineItemComponentT, TimelineItemContentBadgeTypeT, TimelineItemContentT } from 'components/Timeline/types'
import { Locale } from 'date-fns'
import { TFunction } from 'i18next'
import { taskStates } from 'pages/Tasks/constants'
import { AbsenceRequestTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { getAvatarFromPerson, getAvatarFromTimeline, getItsMe } from 'pages/Tasks/utils/timeline'
import { PersonT } from 'types'
import { getDateFromISOString, getRelativeDate } from 'utils/date'
import { absenceRequestStatus } from '../constants'
import AcceptedSummary from './AcceptedSummary'
import AbsenceSummary from './Summary'

const getAbsenceRequestItem = (
  task: TaskT<AbsenceRequestTaskT>,
  locale: Locale,
  t: TFunction,
  itsMe: boolean,
): TimelineItemComponentT => {
  const avatar = getAvatarFromPerson(task.person as PersonT, t, itsMe)
  const date = getRelativeDate(getDateFromISOString(task.insertedAt), locale)
  const header = createTimelineItemHeaderWithAvatarIcon(
    'absenceRequest',
    date,
    avatar,
    task.status === absenceRequestStatus.reviewOfficeUnseen,
  )
  const content = {
    badge: TimelineItemContentBadgeTypeT.absenceRequested,
    textHeader: <AbsenceSummary task={task} />,
    text: task.comment,
  } as TimelineItemContentT
  return createTimeLineItem(header, content)
}

const mayAddDefaultClosedAction = (task: TaskT<AbsenceRequestTaskT>, t: TFunction, locale: Locale, itsMe: boolean) => {
  const date = getRelativeDate(getDateFromISOString(task.updatedAt), locale)
  const avatar = getAvatarFromPerson(task.personTriggeredBy as PersonT, t, itsMe)
  if (task.status === absenceRequestStatus.approvedOffice) {
    const header = createTimelineItemHeaderWithAvatarIcon('absenceRequestAccepted', date, avatar, false)
    const content = {
      badge: TimelineItemContentBadgeTypeT.absenceRequestAccepted,
      textHeader: <AbsenceSummary task={task} />,
      text: task.taskData.reviewerComment,
    } as TimelineItemContentT
    return [createTimeLineItem(header, content)]
  } else if (task.status === absenceRequestStatus.rejectedOffice) {
    const header = createTimelineItemHeaderWithAvatarIcon('absenceRequestRejected', date, avatar, false)
    const content = {
      badge: TimelineItemContentBadgeTypeT.absenceRequestRejected,
      text: task.taskData.reviewerComment,
    } as TimelineItemContentT
    return [createTimeLineItem(header, content)]
  }

  return []
}

const mayAddClosedElement = (task: TaskT<AbsenceRequestTaskT>, t: TFunction) => {
  if (task.status === absenceRequestStatus.approvedOffice || task.status === absenceRequestStatus.rejectedOffice) {
    const closedItem = createTimeLineItem(
      createTimelineItemHeaderOnlyText('absenceRequestStatusEnd', t('absenceRequest.timeline.closed'), true),
    )
    return [closedItem]
  }
  return []
}

const getDefaultTimeline = (
  task: TaskT<AbsenceRequestTaskT>,
  t: TFunction,
  locale: Locale,
  personIds: number[],
): TimelineItemComponentT[] => {
  return [
    getAbsenceRequestItem(task, locale, t, getItsMe(`${task.person?.id as number}`, personIds)),
    ...mayAddDefaultClosedAction(task, t, locale, getItsMe(`${task.personTriggeredBy?.id as number}`, personIds)),
    ...mayAddClosedElement(task, t),
  ] as TimelineItemComponentT[]
}

const getTimelineHeader = (
  timelineItem: TimelineItemT,
  t: TFunction,
  locale: Locale,
  itsMe: boolean,
  isFlashing: boolean,
) => {
  const id = `timelineItemHeader-${timelineItem.id}`

  const date = getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale)
  const avatar = getAvatarFromTimeline(timelineItem, t, itsMe)

  return createTimelineItemHeaderWithAvatarIcon(id, date, avatar, isFlashing)
}

const getTimelineContentHeader = (timelineItem: TimelineItemT, task: TaskT<AbsenceRequestTaskT>): React.ReactNode => {
  if (timelineItem.badge === TimelineItemContentBadgeTypeT.absenceRequested) {
    return <AbsenceSummary task={task} />
  }
  if (timelineItem.badge === TimelineItemContentBadgeTypeT.absenceRequestAccepted) {
    return <AcceptedSummary task={task} />
  }
  return null
}

const getTimelineContent = (timelineItem: TimelineItemT, task: TaskT<AbsenceRequestTaskT>): TimelineItemContentT => {
  return {
    textHeader: getTimelineContentHeader(timelineItem, task),
    badge: timelineItem.badge as TimelineItemContentBadgeTypeT,
    text: timelineItem.comment,
    datetime: timelineItem.insertedAt,
  }
}

const getTimeline = (
  task: TaskT<AbsenceRequestTaskT>,
  t: TFunction,
  locale: Locale,
  personIds: number[],
): TimelineItemComponentT[] => {
  if (!task.timeline) {
    return []
  }

  const timelineSize = task.timeline?.length
  const timeline = task.timeline?.map((timelineItem: TimelineItemT, index) => {
    const isFlashing = index + 1 === timelineSize && task.statusFe !== taskStates.closed
    const itsMe = getItsMe(timelineItem.authorId, personIds)
    const timelineHeader = getTimelineHeader(timelineItem, t, locale, itsMe, isFlashing)
    const timelineContent = getTimelineContent(timelineItem, task)

    return createTimeLineItem(timelineHeader, timelineContent)
  })

  return [...timeline, ...mayAddClosedElement(task, t)]
}

export const getTimelineForComponent = ({
  task,
  t,
  locale,
  personIds,
}: {
  task: TaskT<AbsenceRequestTaskT>
  t: TFunction
  locale: Locale
  personIds: number[]
}): TimelineItemComponentT[] => {
  if (task.timeline && task.timeline.length > 0) {
    return getTimeline(task, t, locale, personIds)
  }
  return getDefaultTimeline(task, t, locale, personIds)
}
