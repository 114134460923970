import { eachDayOfInterval, isSameDay } from 'date-fns'
import {
  ActionDispatchT,
  PinPointDateRangeActionT,
  ReducerActionT,
  SelectTaskFromGridActionT,
  SelectTaskFromTaskBarActionT,
  SelectedStatesContextT,
  SetPinPointingSearchValueActionT,
  SetSelectedDaysActionT,
  SetSelectedEventActionT,
  SetSelectedPersonActionT,
  SetSelectedTaskUuidActionT,
  UpdateSelectedDaysActionT,
  selectedStatesActions,
} from '../types'

const generateDatesArray = (selectedDays: Date[], date: Date) => {
  if (selectedDays.length !== 1) return [date]
  if (selectedDays.length === 1 && isSameDay(selectedDays[0], date)) return []

  const start = selectedDays[0] < date ? selectedDays[0] : date
  const end = selectedDays[0] > date ? selectedDays[0] : date
  return eachDayOfInterval({ start, end })
}

const selectedStatesReducers: ActionDispatchT = {
  [selectedStatesActions.setPinPointingSearchValue]: (state, action) => {
    const actionValue = action.value as SetPinPointingSearchValueActionT

    return {
      ...state,
      pinPointingSearchValue: actionValue.pinPointingSearchValue,
    }
  },
  [selectedStatesActions.setSelectedTaskUuid]: (state, action) => {
    const actionValue = action.value as SetSelectedTaskUuidActionT

    return {
      ...state,
      selectedTaskUuid: actionValue.selectedTaskUuid,
    }
  },
  [selectedStatesActions.setSelectedEvent]: (state, action) => {
    const actionValue = action.value as SetSelectedEventActionT

    return {
      ...state,
      selectedEvent: actionValue.selectedEvent,
    }
  },
  [selectedStatesActions.setSelectedPerson]: (state, action) => {
    const actionValue = action.value as SetSelectedPersonActionT

    return {
      ...state,
      selectedPerson: actionValue.selectedPerson,
    }
  },
  [selectedStatesActions.setSelectedDays]: (state, action) => {
    const actionValue = action.value as SetSelectedDaysActionT

    return {
      ...state,
      selectedDays: actionValue.selectedDays,
    }
  },
  [selectedStatesActions.deselectPerson]: (state) => {
    return {
      ...state,
      selectedPerson: null,
      selectedTaskUuid: null,
    }
  },
  [selectedStatesActions.deselectDays]: (state) => {
    return {
      ...state,
      selectedDays: [],
      selectedTaskUuid: null,
    }
  },
  [selectedStatesActions.selectNewPerson]: (state, action) => {
    const actionValue = action.value as SetSelectedPersonActionT

    return state.selectedTaskUuid
      ? {
          ...state,
          selectedTaskUuid: null,
          selectedEvent: null,
          selectedDays: [],
          selectedPerson: actionValue.selectedPerson,
        }
      : {
          ...state,
          selectedPerson: actionValue.selectedPerson,
        }
  },
  [selectedStatesActions.updateSelectedDays]: (state, action) => {
    const actionValue = action.value as UpdateSelectedDaysActionT
    return state.selectedTaskUuid
      ? {
          ...state,
          selectedTaskUuid: null,
          selectedEvent: null,
          selectedDays: [actionValue.selectedDay],
          selectedPerson: null,
        }
      : {
          ...state,
          selectedDays: generateDatesArray(state.selectedDays, actionValue.selectedDay),
        }
  },
  [selectedStatesActions.selectTaskFromGrid]: (state, action) => {
    const actionValue = action.value as SelectTaskFromGridActionT
    const isSelected = state.selectedTaskUuid === actionValue.selectedTaskUuid

    return isSelected
      ? {
          ...state,
          selectedTaskUuid: null,
          selectedDays: [],
          selectedPerson: null,
        }
      : {
          ...state,
          selectedTaskUuid: actionValue.selectedTaskUuid,
          selectedDays: actionValue.selectedDays,
          selectedPerson: actionValue.selectedPerson,
        }
  },
  [selectedStatesActions.selectTaskFromTaskBar]: (state, action) => {
    const actionValue = action.value as SelectTaskFromTaskBarActionT

    return {
      ...state,
      selectedTaskUuid: actionValue.selectedTaskUuid,
      selectedDays: actionValue.pinPoint ? actionValue.selectedDays : [],
      pinPointingSearchValue: actionValue.pinPoint ? actionValue.pinPointingSearchValue : null,
    }
  },
  [selectedStatesActions.pinPointDateRange]: (state, action) => {
    const actionValue = action.value as PinPointDateRangeActionT
    return {
      ...state,
      selectedDays: actionValue.selectedDays,
      pinPointingSearchValue: actionValue.pinPointingSearchValue,
    }
  },
}

export const selectedStatesReducer = (
  state: SelectedStatesContextT,
  action: ReducerActionT,
): SelectedStatesContextT => {
  const reducer = selectedStatesReducers[action.type]
  if (!reducer) {
    return state
  }
  return reducer(state, action)
}
