import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import EventCount from 'components/Cms/EventCount'
import { CmsContentT } from 'types'
import { getEventsFromTenantAndOwner } from 'utils/cms'
import { classes } from './styles'

interface IEventsProps {
  events: CmsContentT[]
  tenantId?: string
  ownerId?: string
}

const NextDayEvents: React.FC<IEventsProps> = ({ events, tenantId, ownerId }) => {
  const filteredEvents = useMemo(() => {
    if (!tenantId && !ownerId) return events

    return getEventsFromTenantAndOwner(events, {
      tenantId: tenantId,
      ownerId: ownerId,
    })
  }, [events, ownerId, tenantId])

  if (filteredEvents.length === 0) {
    return null
  }

  return (
    <Box sx={classes.eventCountContainer}>
      <EventCount numberOfEvents={filteredEvents.length} />
    </Box>
  )
}

export default NextDayEvents
