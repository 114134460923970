import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FilledCallM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10.9 7.724a1.57 1.57 0 0 1 .687-.386c.26-.068.534-.071.795-.008.26.064.5.192.694.372.195.18.338.405.415.653h-2.982c.074-.238.209-.455.391-.631Zm-1.754.631a2.762 2.762 0 0 1 .976-1.686A2.953 2.953 0 0 1 12 6c.689 0 1.355.237 1.878.67.524.431.87 1.03.976 1.685h2.48a.68.68 0 0 1 .47.189c.126.12.196.284.196.454 0 .17-.07.334-.195.455a.68.68 0 0 1-.472.188h-.889v7.072c0 .34-.14.668-.39.909-.25.24-.59.376-.943.376H8.89c-.354 0-.693-.135-.943-.376a1.263 1.263 0 0 1-.39-.91v-7.07h-.89a.688.688 0 0 1-.47-.19A.642.642 0 0 1 6 8.999a.623.623 0 0 1 .195-.454.669.669 0 0 1 .472-.189h2.479Zm1.076 2.68c.307 0 .556.24.556.536V15a.527.527 0 0 1-.163.379.566.566 0 0 1-.786 0 .526.526 0 0 1-.162-.38v-3.43c0-.295.249-.535.555-.535Zm4.111.536a.526.526 0 0 0-.162-.38.566.566 0 0 0-.786 0 .527.527 0 0 0-.163.38V15c0 .142.059.278.163.379a.566.566 0 0 0 .786 0 .526.526 0 0 0 .162-.38v-3.43Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6 6h12v12H6z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default FilledCallM
