import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { refreshHighSecTriggers } from 'pages/UserSettings/constants'
import { headerClasses } from 'pages/UserSettings/styles'
import { useHistory, useParams } from 'react-router-dom'
import useTenantPageData from '../../hooks/useTenantPageData'
import BasicInfo from './sections/BasicInfo'
import EmployeeTimeManagement from './sections/EmployeeTimeManagement'
import WorkflowSettings from './sections/WorkflowSettings'
import { classes } from './styles'

const TenantPageContent: React.FC = () => {
  const { tenantUuid } = useParams<{ tenantUuid: string }>()
  const history = useHistory()
  const { t } = useFavurTranslation()

  const {
    loading,
    tenant,
    onChangeTenantName,
    onChangeHideUnverifiedEffectiveTimes,
    onChangeAbsencesBlockingPeriod,
    loadingMutations,
  } = useTenantPageData(tenantUuid)

  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Page
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('page.userSettings.tenant.header')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !tenant ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.sectionsContainer} {...refreshHighSecTriggers}>
          <BasicInfo tenant={tenant} onChangeTenantName={onChangeTenantName} loadingMutations={loadingMutations} />
          <WorkflowSettings
            tenant={tenant}
            onChangeAbsencesBlockingPeriod={onChangeAbsencesBlockingPeriod}
            loadingMutations={loadingMutations}
          />
          <EmployeeTimeManagement
            tenant={tenant}
            onChangeHideUnverifiedEffectiveTimes={onChangeHideUnverifiedEffectiveTimes}
            loadingMutations={loadingMutations}
          />
        </Box>
      )}
    </Page>
  )
}

export default TenantPageContent
