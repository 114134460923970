import React from 'react'
import { Box, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import Numpad from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes as secureConnection } from '../styles'
import Confirm from './ActionButtons/Confirm'
import { classes } from './styles'

interface IPinRepeat {
  onConfirm: () => void
  pin: string
  pinLength: number
  setPin: (pin: string | ((prevPin: string) => string)) => void
}

const PinRepeat: React.FC<IPinRepeat> = ({ onConfirm, pin, pinLength, setPin }) => {
  const { t } = useFavurTranslation()

  return (
    <>
      <Typography variant="h2" sx={secureConnection.title}>
        {t('secureConnection.setPin.pinRepeat.title')}
      </Typography>

      <Box sx={classes.pinDisplayContainer}>
        <PinDisplay pinLength={pinLength} pin={pin} secret />
      </Box>
      <Box sx={classes.numpadContainerRepeat}>
        <Numpad numpadType="pinLogin" onNumpadValueChange={setPin} outputLength={pinLength} />
      </Box>

      <ActionCardButtons>
        <Confirm onClick={onConfirm} disabled={pin.length !== pinLength} />
      </ActionCardButtons>
    </>
  )
}

export default PinRepeat
