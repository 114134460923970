import React, { useCallback } from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import type { OwnerPersons } from 'hooks/useOwnersOfUser'
import { DropdownDownThinS } from 'icons'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { headerClasses } from './styles'

const OwnerFilter: React.FC = () => {
  const { owners, selectedOwnerId, setSelectedOwnerId, isSmallScreen } = useAbsencePlannerStateContext()

  const ownerObjToKey = useCallback((ownerObj: OwnerPersons) => `${ownerObj.owner.id}`, [])

  if (!owners || owners.length < 2 || !selectedOwnerId || isSmallScreen) {
    return <></>
  }

  return (
    <Box sx={headerClasses.ownerFilterContainer}>
      <Select
        variant="standard"
        value={`${selectedOwnerId}`}
        onChange={(e) => {
          const value = e.target.value as string
          setSelectedOwnerId(Number(value))
        }}
        IconComponent={() => <DropdownDownThinS fill="white" sx={headerClasses.selectIcon} />}
        sx={headerClasses.selectBox}
      >
        {owners.map((ownerData, _index) => (
          <MenuItem value={ownerObjToKey(ownerData)} key={ownerObjToKey(ownerData)}>
            {ownerData.owner.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default OwnerFilter
