export default {
  styleOverrides: {
    groupedHorizontal: {
      '&:not(:first-of-type)': {
        marginLeft: 'inherit',
        borderLeft: '',
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },
      '&:not(:last-child)': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        marginRight: 16,
      },
    },

    /* Styles applied to the children if `orientation="vertical"`. */
    groupedVertical: {
      '&:not(:first-of-type)': {
        marginTop: 'inherit',
        borderTop: 'inherit',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      },
      '&:not(:last-child)': {
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        marginBottom: 16,
      },
    },
  },
}
