import {
  createTimeLineItem,
  createTimelineItemHeaderOnlyText,
  createTimelineItemHeaderWithAvatarIcon,
  createTimelineItemHeaderWithHrAvatar,
} from 'components/Timeline/factory'
import {
  TimelineItemComponentT,
  TimelineItemContentBadgeTypeT,
  TimelineItemContentT,
  TimelineItemContentIdentificationT,
} from 'components/Timeline/types'
import { Locale } from 'date-fns'
import { taskStates } from 'pages/Tasks/constants'
import { ShareUserDataTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { getAvatarFromTimeline, getItsMe } from 'pages/Tasks/utils/timeline'
import { TFunction } from 'react-i18next'
import { getDateFromISOString, getRelativeDate } from 'utils/date'
import { secureConnectionStatus } from './constants'

const mayAddClosedElement = (task: TaskT<ShareUserDataTaskT>, t: TFunction) => {
  if (task.statusFe === taskStates.closed) {
    const shareUserDataClosed = createTimeLineItem(
      createTimelineItemHeaderOnlyText('shareUserDataStatusEnd', t('shareUserData.timeline.taskClosed'), true),
    )
    return [shareUserDataClosed]
  }
  return []
}

const getTimelineHeader = (
  timelineItem: TimelineItemT,
  t: TFunction,
  locale: Locale,
  itsMe: boolean,
  isFlashing: boolean,
) => {
  const id = `timelineItemHeader-${timelineItem.id}`
  if (timelineItem.authorIsSystem) {
    const date = getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale)
    return createTimelineItemHeaderWithHrAvatar(id, date, t('workTimeControl.timeline.HRManager'), isFlashing)
  }

  const date = getRelativeDate(getDateFromISOString(timelineItem.insertedAt), locale)
  const avatar = getAvatarFromTimeline(timelineItem, t, itsMe)

  return createTimelineItemHeaderWithAvatarIcon(id, date, avatar, isFlashing)
}

const getTimelineContent = (
  timelineItem: TimelineItemT,
  task: TaskT<ShareUserDataTaskT>,
  isOffice: boolean,
): TimelineItemContentT => {
  const replaceBadgeByIdentificationCard =
    task.statusFe === taskStates.todo &&
    !isOffice &&
    timelineItem.badge === TimelineItemContentBadgeTypeT.identificationStarted

  const baseContent = {
    badge: timelineItem.badge as TimelineItemContentBadgeTypeT,
    text: timelineItem.comment,
    datetime: timelineItem.insertedAt,
    formTaskUuid: timelineItem.isDataForm ? task.favurUuid : undefined,
    tenantName: timelineItem.tenantName,
    clickable: task.status === secureConnectionStatus.reviewFrontline && !isOffice,
    identificationCard: undefined,
    taskUuid: task.favurUuid,
  }

  if (replaceBadgeByIdentificationCard) {
    const identificationCard = { tenantName: timelineItem.tenantName } as TimelineItemContentIdentificationT
    return {
      ...baseContent,
      badge: undefined,
      identificationCard: identificationCard,
    }
  }
  return baseContent
}

const getTimeline = (
  task: TaskT<ShareUserDataTaskT>,
  t: TFunction,
  locale: Locale,
  personIds: number[],
  isOffice: boolean,
): TimelineItemComponentT[] => {
  if (!task.timeline) {
    return []
  }

  const timelineSize = task.timeline?.length
  const timeline = task.timeline?.map((timelineItem: TimelineItemT, index) => {
    const isFlashing = index + 1 === timelineSize && task.statusFe !== taskStates.closed
    const itsMe = getItsMe(timelineItem.authorId, personIds)
    const timelineHeader = getTimelineHeader(timelineItem, t, locale, itsMe, isFlashing)
    const timelineContent = getTimelineContent(timelineItem, task, isOffice)

    return createTimeLineItem(timelineHeader, timelineContent)
  })

  return [...timeline, ...mayAddClosedElement(task, t)]
}

interface IgetTimelineAttributes {
  task: TaskT<ShareUserDataTaskT>
  t: TFunction
  locale: Locale
  isOffice: boolean
  personIds: number[]
}
export const getTimelineForComponent = ({
  task,
  t,
  locale,
  isOffice = false,
  personIds,
}: IgetTimelineAttributes): TimelineItemComponentT[] => {
  if (task.timeline && task.timeline.length > 0) {
    return getTimeline(task, t, locale, personIds, isOffice)
  }
  return []
}
