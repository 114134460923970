import theme from 'utils/theme'

export const classes = {
  sectionTitle: {
    fontSize: '18px',
    lineHeight: '25px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: '8px',
    marginTop: '24px',
  },
  stickyContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerMain: {
    width: '100vw',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[8],
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
  },
  contentContainer: {
    padding: '0 12px 24px',
    maxWidth: '600px',
  },
  noDataLabelContainer: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
    color: theme.palette.grey[600],
  },
}
