import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Alert from 'components/Alert'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PersonalDataFormM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import useLastUpdateDate from 'pages/UserSettings/hooks/useLastUpdateDate'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import { dateToISOStringWithoutTime, getFormattedFromISOString } from 'utils/date'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IBasicInfoProps {
  person: PersonT
}

const PersonalDataSection: React.FC<IBasicInfoProps> = ({ person }) => {
  const { t, locale } = useFavurTranslation()
  const history = useHistory()
  const { lastUpdateDate } = useLastUpdateDate()

  const neverShared = useMemo(() => !person.lastSharedData, [person.lastSharedData])
  const isUpToDate = useMemo(
    () => lastUpdateDate && person.lastSharedData && dateToISOStringWithoutTime(lastUpdateDate) < person.lastSharedData,
    [lastUpdateDate, person.lastSharedData],
  )

  return (
    <UserSettingsSection title={t('page.userSettings.employer.personalData')}>
      <Box sx={classes.cardWithAlert}>
        <Card>
          <Box sx={classes.cardContent}>
            <Typography variant="body2">{t('page.userSettings.employer.shareDataExplanation')}</Typography>
            <DivisionLine />
            <Box sx={classes.lastSharedContent} data-testid="user-settings_employer-shared-data">
              <Typography variant="body1">{t('page.userSettings.employer.lastShared')}</Typography>
              <Typography variant="body1" sx={neverShared ? classes.neverShared : []}>
                {neverShared
                  ? t('page.userSettings.employer.neverShared')
                  : getFormattedFromISOString(person.lastSharedData as string, 'dd.MM.yyyy', locale)}
              </Typography>
            </Box>
          </Box>
        </Card>
        {neverShared && <Alert severity="warning" content={t('page.userSettings.employer.neverSharedAlert')} />}
        {isUpToDate && <Alert severity="success" content={t('page.userSettings.employer.upToDateAlert')} />}
      </Box>
      <UserSettingsSectionCTA
        title={t('page.userSettings.employer.shareData')}
        onClick={() => {
          history.push(routes.employmentData, { origin: `${history.location.pathname}` })
        }}
        icon={<PersonalDataFormM fill={palette.primary.main} />}
      />
    </UserSettingsSection>
  )
}

export default PersonalDataSection
