import React from 'react'
import { isNative } from 'utils/platform'
import Menu from './Menu'
import { IToolbarProps } from './types'

const TestToolbar: React.FC<IToolbarProps> = ({ auth, navigate, toggleDrawer, hideHamburger, hideWebToolbar }) => {
  if (!isNative() && hideWebToolbar) {
    return null
  }

  return <Menu auth={auth} navigate={navigate} toggleDrawer={toggleDrawer} hideHamburger={hideHamburger} />
}

export default TestToolbar
