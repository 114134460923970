import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const CheckMark: React.FC<IconPropsT> = ({ fill, size = ICON_SIZE_S }) => {
  return (
    <Svg size={size} fill={fill} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M20.646 5.646l.708.708a1 1 0 010 1.414L10.207 18.914a1 1 0 01-1.414 0l-6.147-6.146a1 1 0 010-1.414l.708-.708a1 1 0 011.32-.083l.094.083L9.5 15.38l9.732-9.733a1 1 0 011.32-.083l.094.083z"
      />
    </Svg>
  )
}

export default CheckMark
