import React from 'react'
import { Box } from '@mui/material'
import theme from 'utils/theme'

const divisionLineStyle = {
  // @ts-ignore
  borderBottom: `1px solid ${theme.palette.primary[50]}`,
  width: '100%',
}

const DivisionLine: React.FC = () => {
  return <Box sx={divisionLineStyle} />
}

export default DivisionLine
