import { alpha } from '@mui/material/styles'
import theme from 'utils/theme'

export const whiteButtonClasses = {
  root: {
    // @ts-ignore
    color: theme.palette.secondary[400],
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      // @ts-ignore
      backgroundColor: alpha(theme.palette.secondary[400], 0.04),
    },
    '&:focus': {
      // @ts-ignore
      backgroundColor: alpha(theme.palette.secondary[400], 0.08),
    },
  },
}
