import { shiftTypes, timeTypes } from 'constants/shift'
import { sortBy } from 'lodash/fp'
import { PlannedShiftT } from 'types'
import { TeamplanPersonsT } from 'types/teamplan'
import { getFullAvatarUrl } from 'utils/avatar'
import { getAvatarName } from 'utils/person'
import { TeamAvatarsT, TeamMemberT } from './types'

const getTeamAvatars = (
  teamplan: TeamplanPersonsT[],
  filterShiftPredicate: (shift: PlannedShiftT) => boolean,
): TeamAvatarsT =>
  teamplan.reduce((acc, tp) => {
    const members = tp.persons
      .map((p) => {
        const shifts = p.shifts.filter(filterShiftPredicate)
        if (shifts.length === 0) {
          return null
        }

        const orderedShifts = sortBy((shift) => shift.from, shifts)

        return {
          personId: p.id,
          avatarUrl: p.avatarImageUuid ? getFullAvatarUrl(p.avatarImageUuid) : undefined,
          avatarName: getAvatarName(p.firstName, p.lastName),
          lastName: p.lastName,
          firstName: p.firstName,
          shifts: orderedShifts,
        } as TeamMemberT
      })
      .filter((m) => m !== null)

    const teamId = tp.id
    return { [teamId]: members as TeamMemberT[], ...acc }
  }, {} as TeamAvatarsT)

export const getTeamAttendancesAvatars = (teamplan: TeamplanPersonsT[]): TeamAvatarsT =>
  getTeamAvatars(teamplan, (shift) => shift.type === shiftTypes.plannedShift)

export const getTeamVacationAbsencesAvatars = (teamplan: TeamplanPersonsT[]): TeamAvatarsT =>
  getTeamAvatars(teamplan, (shift) => shift.type === shiftTypes.absence && shift.timeType === timeTypes.vacation)

export const getTeamOtherAbsencesAvatars = (teamplan: TeamplanPersonsT[]): TeamAvatarsT =>
  getTeamAvatars(teamplan, (shift) => shift.type === shiftTypes.absence && shift.timeType !== timeTypes.vacation)

export const orderAttendances = (shifts: TeamMemberT[]): TeamMemberT[] => {
  return sortBy(
    [
      (member: TeamMemberT) => member.shifts[0].from,
      (member: TeamMemberT) => member.shifts[0].shortDescription,
      (member: TeamMemberT) => member.shifts.length,
      (member: TeamMemberT) => member.lastName,
    ],
    shifts,
  ) as TeamMemberT[]
}

export const orderAbsences = (absences: TeamMemberT[]): TeamMemberT[] => {
  return sortBy(
    [
      (member: TeamMemberT) => member.shifts.length,
      (member: TeamMemberT) => member.shifts[0].percent,
      (member: TeamMemberT) => member.lastName,
    ],
    absences,
  )
}

export const sortTeamPlanShifts = (teamplan?: TeamplanPersonsT[] | 'loading') => {
  if (!teamplan || teamplan === 'loading') {
    return { attendances: {}, otherAbsences: {}, vacationAbsences: {} }
  }

  return {
    attendances: getTeamAttendancesAvatars(teamplan),
    otherAbsences: getTeamOtherAbsencesAvatars(teamplan),
    vacationAbsences: getTeamVacationAbsencesAvatars(teamplan),
  }
}

export const getTeamMemberKey = (teamMember: TeamMemberT) => {
  return `${teamMember.personId}-${teamMember.avatarName}-${teamMember.avatarUrl}`
}
