import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Typography, Button } from '@mui/material'
import Dialog from 'components/Dialog'
import PageWithFilter from 'components/Filter/PageWithFilter'
import { FilterProvider } from 'components/Filter/context'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useStateBackLink from 'hooks/useStateBackLink'
import { useHistory } from 'react-router-dom'
import BannerMessagesContext from 'services/BannerMessages/context'
import type { IBannerMessage } from 'services/BannerMessages/types'
import routes from 'services/RoutesProvider/routes'
import { lowSecurityTenantQuery } from 'shared/queries'
import type { MutationResult, TenantNamesT } from 'types'
import { useMutation } from '@apollo/client'
import DocumentListContents from './DocumentListContents'
import { dismissBasicDocumentsMutation as mutation } from './queries'
import type { MonthlySheetDialogDataT } from './types'
import useFilterConfiguration from './useFilterConfiguration'

export const lowSecurityBannerDetails: IBannerMessage = {
  message: 'page.documents.banner.content',
  bannerActions: [
    {
      action: 'SECURITY_SETUP',
      text: 'page.documents.banner.button',
    },
  ],
}

const DocumentList: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setBannerMessage } = useContext(BannerMessagesContext)
  const { setBackLink } = useStateBackLink()
  const { configuration, loading: configLoading } = useFilterConfiguration()

  const { data: lowSecurityTenantNamesData, loading } = useJamesApolloQuery<{ lowSecurityTenantNames: TenantNamesT[] }>(
    lowSecurityTenantQuery,
  )

  const [dismissDocumentsMutation] = useMutation<MutationResult>(mutation)
  const defaultDialogState: MonthlySheetDialogDataT = { open: false, taskUuid: undefined }
  const [dialog, setDialog] = useState<MonthlySheetDialogDataT>(defaultDialogState)
  const acceptMonthlySheet = useCallback(() => {
    if (dialog.taskUuid) {
      setBackLink(routes.documents)
      history.push(routes.monthlySheetsWithTask(dialog.taskUuid))
    }
  }, [dialog.taskUuid, history, setBackLink])

  useEffect(() => {
    if (loading) return undefined
    if (lowSecurityTenantNamesData && lowSecurityTenantNamesData?.lowSecurityTenantNames?.length < 1) return undefined
    setBannerMessage(lowSecurityBannerDetails)
    return () => {
      setBannerMessage(undefined)
    }
  }, [setBannerMessage, lowSecurityTenantNamesData, loading])

  useEffect(() => {
    dismissDocumentsMutation()
  }, [dismissDocumentsMutation])

  if (configLoading || loading) {
    return (
      <PageWithFilter
        header={<Typography variant="h2">{t('page.documents.page.title')}</Typography>}
        filterPageTitle={t('page.documents.page.title')}
      >
        <SimpleLoading />
      </PageWithFilter>
    )
  }

  return (
    <FilterProvider configuration={configuration}>
      <PageWithFilter
        header={<Typography variant="h2">{t('page.documents.page.title')}</Typography>}
        filterPageTitle={t('page.documents.page.title')}
      >
        <DocumentListContents setDialog={setDialog} />
        <Dialog
          actions={
            <div>
              <Button color="secondary" onClick={() => setDialog(defaultDialogState)} size="large">
                {t('common.close')}
              </Button>
              <Button
                color="secondary"
                onClick={acceptMonthlySheet}
                size="large"
                data-testid="go-to-monthly-sheet-detail"
              >
                {t('page.documents.monthlySheetDialog.toTaskButton')}
              </Button>
            </div>
          }
          open={dialog.open}
          title={t('page.documents.monthlySheetDialog.title')}
        >
          <Typography component="p">{t('page.documents.monthlySheetDialog.content')}</Typography>
        </Dialog>
      </PageWithFilter>
    </FilterProvider>
  )
}

export default DocumentList
