import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import FloatingBottomButton from 'components/FloatingBottomButton'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { ROLE_VIEWS } from 'constants/roles'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import useStateBackLink from 'hooks/useStateBackLink'
import { RefreshS } from 'icons'
import { groupBy, uniq } from 'lodash/fp'
import { taskStates } from 'pages/Tasks/constants'
import useDismissTaskNotifications from 'pages/Tasks/hooks/useDismissTaskNotifications'
import { taskQueryApollo } from 'pages/Tasks/queries'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useAppStatesContext } from 'services/AppStates'
import FlashMessagesContext from 'services/FlashMessages/context'
import { FlashMessageCloseType, FlashMessageType } from 'services/FlashMessages/types'
import routes from 'services/RoutesProvider/routes'
import { MutationResult, PlannedShiftT } from 'types'
import { getFormattedFromISOString } from 'utils/date'
import { useMutation } from '@apollo/client'
import DayShiftUpdates from './DayShiftUpdates'
import { acknowledgedAtDateFormat } from './constants'
import { updateShiftUpdateStatusMutationApollo as mutation } from './mutations'
import { classes } from './styles'

const refShiftListDateParamName = 'dateref'

type ShiftUpdatesByDate = {
  [key: string]: PlannedShiftT[]
}

const groupShiftsByDate = (shifts: PlannedShiftT[]): { [key: string]: PlannedShiftT[] } =>
  Object.entries(groupBy('date', shifts)).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value }
  }, {})

const ShiftUpdateDetails: React.FC<RouteComponentProps<{ favurUuid: string; status: string }>> = ({ match }) => {
  const { t, locale } = useFavurTranslation()
  const { activeView } = useRolesViews()
  const history = useHistory()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const isOffice = activeView === ROLE_VIEWS.office
  const [task, setTask] = useState<TaskT<ShiftUpdateTaskT>>()
  const [shiftsUpdatedByDate, setShiftsUpdatedByDate] = useState<ShiftUpdatesByDate>({})
  const [shiftsUpdatedHistoryByDate, setShiftsUpdatedHistoryByDate] = useState<ShiftUpdatesByDate>({})
  const [pageTitle, setPageTitle] = useState<string>('')
  const [updatedDays, setUpdatedDays] = useState<string[]>([])
  const [showAll, setShowAll] = useState<boolean>(false)
  const [updateShiftUpdateStatus] = useMutation<{ updateShiftUpdateStatus: MutationResult }>(mutation)
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const refShiftListDate = urlParams.get(refShiftListDateParamName)
  const { set } = useAppStatesContext()

  const {
    params: { favurUuid, status: statusFe },
  } = match

  const { loading: loadingDismiss } = useDismissTaskNotifications({ taskUuid: favurUuid, isManager: isOffice })

  const showSeenLabel = statusFe === taskStates.closed

  const { loading } = useJamesApolloQuery<{ task: TaskT<ShiftUpdateTaskT> }>(taskQueryApollo, {
    variables: { favurUuid, office: isOffice },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { task: queriedTask } = data
      setTask(queriedTask)
    },
    onError() {
      history.push(routes.dashboard)
    },
  })

  const closeTask = useCallback(async () => {
    try {
      removeAll()
      const result = await updateShiftUpdateStatus({
        variables: { favurUuid: task?.favurUuid, status: ShiftUpdateStatus.reviewFrontlineSeen },
      })

      if (result.data?.updateShiftUpdateStatus?.success) {
        setFlashMessage(
          'page.tasks.shiftUpdate.detail.label.tasksClosed',
          2_000,
          FlashMessageType.INFO,
          FlashMessageCloseType.ICON,
        )
      } else {
        setFlashMessage('common.error.be.default')
      }
    } catch (_err) {
      setFlashMessage('common.error.be.default')
    }
  }, [removeAll, setFlashMessage, task?.favurUuid, updateShiftUpdateStatus])

  useEffect(() => {
    if (!task) return
    setShiftsUpdatedByDate(groupShiftsByDate(task.shiftsUpdated as PlannedShiftT[]))
  }, [task])

  useEffect(() => {
    if (!task) return
    setShiftsUpdatedHistoryByDate(groupShiftsByDate(task.shiftsUpdatedHistory as PlannedShiftT[]))
  }, [task])

  useEffect(() => {
    if (!showAll || task?.status !== ShiftUpdateStatus.reviewFrontlineUnseen) return
    closeTask()
  }, [closeTask, showAll, task?.status])

  useEffect(() => {
    const numberOfUpdatedDays = Object.keys(shiftsUpdatedByDate).length
    setPageTitle(
      numberOfUpdatedDays > 1
        ? t('page.tasks.shiftUpdate.detail.title.many', { numberOfDays: numberOfUpdatedDays })
        : t('page.tasks.shiftUpdate.detail.title.one'),
    )

    // eslint-disable-next-line fp/no-mutating-methods
    const dates = uniq([...Object.keys(shiftsUpdatedByDate), ...Object.keys(shiftsUpdatedHistoryByDate)].sort())
    setUpdatedDays(dates)
    setShowAll(Object.keys(dates).length === 1 || showSeenLabel)
  }, [shiftsUpdatedByDate, shiftsUpdatedHistoryByDate, showSeenLabel, t])

  const isOutdated = useMemo(() => task?.status === ShiftUpdateStatus.outdated, [task?.status])

  const gotoBackLink = useCallback(() => {
    if (refShiftListDate) {
      set(DASHBOARD_NEXT_SHIFT_DATE, refShiftListDate)
    }
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history, refShiftListDate, set])

  return (
    <Page
      hasFloatingButtons
      hideNativeNavigation
      header={<WithBackwardLink onClick={gotoBackLink} title={pageTitle} topTitle={task?.tenantName} />}
    >
      {loading || loadingDismiss ? (
        <SimpleLoading />
      ) : (
        <>
          {showSeenLabel && (
            <Typography variant="caption" sx={classes.seenLabel}>
              {t(isOutdated ? 'tasks.new.shiftUpdate.outdated' : 'page.tasks.shiftUpdate.detail.label.taskSeenOn', {
                acknowledgedAt: getFormattedFromISOString(
                  task?.taskData?.acknowledgedAt || '',
                  acknowledgedAtDateFormat,
                  locale,
                ),
              })}
            </Typography>
          )}
          <Box sx={classes.card}>
            <Card>
              <Box sx={[classes.dateShiftContainer, ...(showSeenLabel ? [classes.marginTopCardSeenLabel] : [])]}>
                {updatedDays.map((date, idx) => (
                  <Box key={`shift-day-${date}`}>
                    <Box
                      sx={[
                        ...(idx < updatedDays.length - 1 ? [classes.dateShiftElement] : []),
                        ...(idx === 0 ? [classes.hiddenShiftContainerFirst] : []),
                        ...(idx === updatedDays.length - 1 ? [classes.hiddenShiftContainerLast] : []),
                        ...(!showAll ? [classes.hiddenShiftContainer] : []),
                      ]}
                    >
                      <DayShiftUpdates
                        date={date}
                        newShifts={shiftsUpdatedByDate[date]}
                        oldShifts={shiftsUpdatedHistoryByDate[date]}
                        showDetails={showAll}
                      />
                    </Box>
                    {idx < updatedDays.length - 1 && <DivisionLine />}
                  </Box>
                ))}
              </Box>
            </Card>
          </Box>
          {!showAll && updatedDays.length > 1 && (
            <FloatingBottomButton
              Icon={RefreshS}
              label={t('page.tasks.shiftUpdate.detail.button.showAll')}
              onClick={() => setShowAll(true)}
              testId="shift-update-data-view-id"
              sx={classes.button}
            />
          )}

          {showAll && (
            <FloatingBottomButton
              label={t('page.tasks.shiftUpdate.detail.button.close')}
              onClick={gotoBackLink}
              testId="shift-update-data-close-id"
              sx={classes.button}
            />
          )}
        </>
      )}
    </Page>
  )
}

export default ShiftUpdateDetails
