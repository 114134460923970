import type { DeadlinesT } from 'types/utils'

export const uiDateFormat = 'dd.MM.yyyy'
export const uiDateFormatNoYear = 'dd.MM.'
export const absenceRequestDateFormat = 'E, dd.MM.yyyy'
export const timestampFormat = 'MMM d. yyyy, HH:mm'

export const deadlinesTranslationKeys: Record<DeadlinesT, string> = {
  '24h': 'deadline.24h',
}
