export enum FlashMessageType {
  INFO,
  ERROR,
}

export enum FlashMessageCloseType {
  ICON,
  TEXT,
}

export interface ISnackbarMessageReq {
  message: string
  key: number
  timeout?: number
  type?: FlashMessageType
  closeType?: FlashMessageCloseType
}

export interface ISnackbarMessage extends ISnackbarMessageReq {
  timeout: number
}

export interface IFlashMessagesContext {
  closeLatest: () => void
  removeAll: () => void
  setFlashMessage: (
    message: string,
    timeout?: number,
    type?: FlashMessageType,
    closeType?: FlashMessageCloseType,
  ) => void
}
