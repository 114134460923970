import React from 'react'
import { Box, IconButton } from '@mui/material'
import { ArrowLeftDoubleS, ArrowRightDoubleS } from 'icons'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext, {
  useAvatarSidebarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import theme from 'utils/theme'
import { avatarSideBarClasses } from './styles'

const TopBox: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { avatarBarExpanded: open } = useAvatarSidebarStateContext()
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  return (
    <Box sx={avatarSideBarClasses.topBox(isScrolled)}>
      <Box sx={avatarSideBarClasses.coverBox(open)}>
        {isSmallScreen && (
          <IconButton onClick={() => setAvatarBarExpanded(!open)} sx={avatarSideBarClasses.iconButton} disableRipple>
            {open ? (
              <ArrowLeftDoubleS fill={theme.palette.primary.main} />
            ) : (
              <ArrowRightDoubleS fill={theme.palette.primary.main} />
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default TopBox
