import { useJamesApolloLazyQuery } from 'hooks/useJamesApolloLazyQuery'
import { validateEmployeeCardQuery } from '../queries'
import { ValidationStatusReturnT } from '../types'

export const useEmployeeCardRevalidate = (uuid: string, complete: (status: string) => void) => {
  const [fetch, { loading }] = useJamesApolloLazyQuery<ValidationStatusReturnT>(validateEmployeeCardQuery, {
    variables: { favurUuid: uuid },
    fetchPolicy: 'no-cache',
    onCompleted(newData) {
      complete(newData.getPersonValidationStatus.status)
    },
  })
  return { fetch, loading }
}
