import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import Card from 'components/Basics/Card'
import { TeamMemberT } from 'components/ShiftsList/components/ShiftCard/TeamsSection/types'
import { orderAttendances } from 'components/ShiftsList/components/ShiftCard/TeamsSection/utils'
import { groupBy } from 'lodash/fp'
import { getShiftKey } from 'types/utils'
import NoDataLabel from '../NoDataLabel'
import { classes } from '../styles'
import ShiftTile from './ShiftTile'

interface IAtendantTabProps {
  attendances: TeamMemberT[]
  hideCostCenter: boolean
}

const AtendantTab: React.FC<IAtendantTabProps> = ({ attendances, hideCostCenter }) => {
  const extendedAndSortedAttendances = useMemo(() => {
    const newAttendances = attendances.flatMap((attendance) => {
      const groupedShifts = Object.values(groupBy('shortDescription', attendance.shifts))
      return groupedShifts.map((shifts) => {
        return { ...attendance, shifts }
      })
    })

    return orderAttendances(newAttendances)
  }, [attendances])

  return (
    <Box sx={classes.card}>
      <Card>
        {extendedAndSortedAttendances.length > 0 ? (
          <Box sx={classes.tileGroup}>
            {extendedAndSortedAttendances.map((attendance) => (
              <ShiftTile
                attendance={attendance}
                key={getShiftKey(attendance.shifts[0])}
                hideCostCenter={hideCostCenter}
              />
            ))}
          </Box>
        ) : (
          <NoDataLabel />
        )}
      </Card>
    </Box>
  )
}

export default AtendantTab
