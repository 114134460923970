import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EmployersM, TeamsM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import { getFormattedFromISOString } from 'utils/date'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IBasicInfoProps {
  person: PersonT
  displayTeamOverview: boolean
}

const BasicInfo: React.FC<IBasicInfoProps> = ({ person, displayTeamOverview }) => {
  const { t, locale } = useFavurTranslation()
  const history = useHistory()

  const getSecurityConectionText = useCallback(
    (connectionStatus: string | undefined) => {
      switch (connectionStatus) {
        case TENANT_CONNECTION_STATUS.high:
          return t('page.userSettings.employers.active')
        case TENANT_CONNECTION_STATUS.low:
          return t('page.userSettings.employers.deactivated')
        default:
          return '-'
      }
    },
    [t],
  )
  const gridData: { title: string; value: string; testid?: string }[] = useMemo(() => {
    return [
      {
        title: t('page.userSettings.employers.status'),
        value:
          person.status !== 'inactive'
            ? t('page.userSettings.employers.active')
            : t('page.userSettings.employers.terminated'),
        testid: 'user-settings_status-active',
      },
      {
        title: t('component.employeeDetail.secureConnection'),
        value: getSecurityConectionText(person.tenantConnectionStatus),
        testid: 'user-settings_security-connection-status',
      },
      {
        title: t('component.employeeDetail.activeSince'),
        value: getFormattedFromISOString(person.tenantConnectionEstablished as string, 'dd.MM.yyyy', locale),
      },
    ]
  }, [
    getSecurityConectionText,
    locale,
    person.status,
    person.tenantConnectionEstablished,
    person.tenantConnectionStatus,
    t,
  ])

  const personHasTeams = Boolean(person.teamPermissions?.length)

  return (
    <UserSettingsSection title={t('page.userSettings.employer.basicInfo')}>
      <Card data-testid="user-settings_tenant-name">
        <Box sx={classes.employerNameContainer}>
          <EmployersM fill={palette.primary.main} />
          <Typography variant="body1">{person.tenant?.name}</Typography>
        </Box>
      </Card>

      <Card data-testid="user-settings_employer-container">
        <Box sx={classes.gridData}>
          {gridData.map((data, i) => (
            <>
              <Box sx={classes.gridDataRow} key={data.title} data-testid={data.testid}>
                <Typography variant="body1" sx={classes.gridDataRowTitle}>
                  {data.title}
                </Typography>
                <Typography variant="body1">{data.value}</Typography>
              </Box>
              {i < gridData.length - 1 && <DivisionLine />}
            </>
          ))}
        </Box>
      </Card>

      {displayTeamOverview && personHasTeams && (
        <UserSettingsSectionCTA
          icon={<TeamsM fill={palette.primary.main} />}
          title={t('page.userSettings.employers.team.header')}
          onClick={() => {
            history.push(routes.employersTeams(person.favurUuid as string))
          }}
        />
      )}
    </UserSettingsSection>
  )
}

export default BasicInfo
