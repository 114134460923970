import { FilterStateT, FilterStateValueT } from './types'

export const clearValue = (filters: FilterStateT, filterName: string): FilterStateT => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { [filterName]: _attrValue, ...otherFields } = filters
  return otherFields
}

export const changeValue = (filters: FilterStateT, filterName: string, value: FilterStateValueT): FilterStateT => {
  return {
    ...filters,
    [filterName]: value,
  }
}
