import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const CompanyPagesM: React.FC<IconPropsT> = ({ stroke }) => (
  <Svg size={ICON_SIZE_M} viewBox="0 0 24 24" fill="none">
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 4.057v17.682M15.49 3.184c-1.004.17-1.99.431-2.947.782a1.6 1.6 0 0 1-1.085 0 13.88 13.88 0 0 0-9.587-.2A1.61 1.61 0 0 0 .75 5.3v14.337A1.607 1.607 0 0 0 2.756 21.2a13.66 13.66 0 0 1 8.7.448 1.6 1.6 0 0 0 1.085 0 13.66 13.66 0 0 1 8.7-.448 1.61 1.61 0 0 0 2.006-1.559V5.3a1.61 1.61 0 0 0-1.118-1.53 17 17 0 0 0-2.365-.576"
    />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.875 11.261-2.25-2.25-2.25 2.25v-7.5a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5z"
    />
  </Svg>
)

export default CompanyPagesM
