import React from 'react'
import { Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import RevalidateIcon from './RevalidateIcon'
import { classes } from './styles'

interface IValidationReloadProps {
  onClick: () => void
  validated: boolean
  loading: boolean
}

const ValidationReload: React.FC<IValidationReloadProps> = ({ onClick, validated, loading }) => {
  const { t } = useFavurTranslation()

  return (
    <Button
      sx={classes.wrapper}
      onClick={onClick}
      disabled={loading}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      data-testid="employee-card_validation-block_reload"
    >
      <Typography variant="subtitle1" sx={classes.validateText}>
        {validated
          ? t('employeeCard.validationReload.revalidate.button')
          : t('employeeCard.validationReload.validate.button')}
      </Typography>
      <RevalidateIcon loading={loading} />
    </Button>
  )
}
export default ValidationReload
