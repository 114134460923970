import React from 'react'
import { Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import { SimpleLoading } from 'components/LoadingIcon'
import ShiftPlaceholder from './Placeholders/ShiftPlaceholder'
import TeamsPlaceholder from './Placeholders/TeamsPlaceholder'
import { classes } from './styles'

const ShiftCardLoading: React.FC = () => {
  return (
    <Box sx={classes.card}>
      <Card minHeight="411px">
        <Box sx={classes.floatingLoading}>
          <SimpleLoading small />
        </Box>

        <ShiftPlaceholder />
        <DivisionLine />
        <TeamsPlaceholder />
      </Card>
    </Box>
  )
}

export default ShiftCardLoading
