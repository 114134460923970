import theme from 'utils/theme'

export const classes = {
  avatarContainer: {
    marginTop: theme.spacing(1.25),
    display: 'flex',
    alignItems: 'center',
  },
  avatarUsername: {
    paddingLeft: '16px',
  },
  avatarButton: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  absenceRequestCaption: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.grey[600],
    textTransform: 'none',
    flexGrow: 1,
  },
  toTaskCta: {
    width: '100%',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '8px',
    marginTop: '12px',
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    '& .MuiButton-root': {
      alignItems: 'flex-start',
    },
    '&:hover, &:focus': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[25],
    },
    '&:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  iconCircle: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeContainer: {
    backgroundColor: '#FF007C',
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    position: 'relative',
    bottom: '2px',
  },
  badgeIcon: {
    position: 'relative',
    right: '3px',
    bottom: '3px',
  },
  closedTaskCTA: {
    padding: '4px 0',
    gap: '4px',
    alignItems: 'center',
    letterSpacing: '0.7px',
    marginTop: '12px',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
}
