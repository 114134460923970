import { gql } from '@apollo/client'

export const personalDataQueryName = 'personalDataForm'
export const personalDataQuery = (includeDocuments: boolean) => gql`
  query{
    personalData(includeDocuments: ${includeDocuments}) {
      firstName
      lastName
      nickname
      birthDate
      gender
      nationality
      contactLanguage
      placeOfOrigin
      socialInsuranceNumber
      healthInsurance
      residenceCategoryType
      residenceCategoryValidUntil
      residenceCategoryZemisNumber
      residencePermitFront{
        filename
        createdAt
      }
      residencePermitBack{
        filename
        createdAt
      }
      passportFront{
        filename
        createdAt
      }
      passportBack{
        filename
        createdAt
      }
    }
  }
`
