import { gql } from '@apollo/client'

export const nativeVersionRequirementQuery = gql`
  query nativeVersionRequirement($nativeVersionNumber: Int!, $platform: String!) {
    nativeVersionRequirement(nativeVersionNumber: $nativeVersionNumber, platform: $platform) {
      requiredVersion
      requirementMet
    }
  }
`
