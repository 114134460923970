import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const InfoS: React.FC<IconPropsT> = ({ fill, ...props }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill} {...props}>
      <path
        fillRule="evenodd"
        d="M8 2a6 6 0 1 1 0 12A6 6 0 0 1 8 2zm.75 5h-1.5a.25.25 0 0 0-.24.2L7 7.24v.5c0 .12.08.22.2.24l.05.01h.25v2h-.25a.25.25 0 0 0-.24.2l-.01.05v.5c0 .12.08.22.2.24l.05.01h2c.12 0 .22-.08.24-.2l.01-.05v-.5a.25.25 0 0 0-.2-.24L9.26 10H9V7.25a.25.25 0 0 0-.2-.24L8.76 7zM8 4.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
      />
    </Svg>
  )
}

export default InfoS
