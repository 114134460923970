import { useState } from 'react'
import { CheckListOptionT, FilterType, OptionT } from 'components/Filter/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { personsOnlyTenant } from 'shared/queries'
import type { PersonT } from 'types'
import { getISOLastDayOfMonth, newDateWithoutTime } from 'utils/date'
import { sortArrayByKey } from 'utils/sort'

const useFilterConfiguration = () => {
  const { t } = useFavurTranslation()
  const [tenantOptionsList, setTenantOptionsList] = useState<CheckListOptionT[]>()
  const [tenantsLoading, setTenantsLoading] = useState(true)

  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsOnlyTenant, {
    fetchPolicy: 'no-cache',
    onCompleted(personsWithTenants) {
      const filteredTenantOptions = Array.from(
        personsWithTenants?.findAllPersons
          .filter((person) => person.tenantConnectionStatus === 'high') // filtering
          .reduce((map, obj) => (map.has(obj.tenant?.id) ? map : map.set(obj.tenant?.id, obj)), new Map()) // de-duplication of tenants
          .values(),
      ).map((person) => ({ name: person.tenant?.id, label: person.tenant?.name })) as CheckListOptionT[] // to checklistOptions

      const sortedTenants = sortArrayByKey(filteredTenantOptions, 'label')
      setTenantOptionsList(sortedTenants)
      setTenantsLoading(false)
    },
  })

  const configuration: OptionT[] = [
    {
      name: 'time_frame',
      type: FilterType.DATE_RANGE,
      label: t('filter.time_frame.label'),
      maxDate: getISOLastDayOfMonth(newDateWithoutTime()),
      minDate: '2015-01-01',
      fromDate: {
        name: 'start_date',
        label: t('filter.from_date.label'),
      },
      toDate: {
        name: 'end_date',
        label: t('filter.to_date.label'),
      },
    },
    {
      name: 'document_types',
      type: FilterType.MULTIPLE_CHOICE,
      label: t('filter.document_type.label'),
      options: sortArrayByKey(
        [
          {
            name: 'monthly_sheet',
            label: t('page.documents.documentType.monthly_sheet'),
          },
          {
            name: 'payslip',
            label: t('page.documents.documentType.payslip'),
          },
          {
            name: 'salary_statement',
            label: t('page.documents.documentType.salary_statement'),
          },
        ],
        'label',
      ),
    },
    ...(tenantOptionsList && tenantOptionsList.length > 1
      ? [
          {
            name: 'tenant_ids',
            type: FilterType.MULTIPLE_CHOICE,
            label: t('filter.tenants.label'),
            options: tenantOptionsList as CheckListOptionT[],
          } as OptionT,
        ]
      : []),
  ]

  return { configuration, loading: loading || tenantsLoading }
}

export default useFilterConfiguration
