import theme from 'utils/theme'

export const classes = {
  componentContainer: {
    padding: theme.spacing(0.75, 1.25),
    paddingBottom: theme.spacing(1),
    marginBottom: '0px !important',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    marginTop: theme.spacing(0.5),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  acceptButton: {
    width: '100%',
  },
  bottomButtons: {
    marginTop: theme.spacing(0.75),
  },
  bodyText: {
    marginTop: theme.spacing(1.5),
  },
  inputContainer: {
    width: '100%',
  },
  checkMark: {
    marginTop: theme.spacing(6),
  },
}
