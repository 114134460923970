import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { addDays, addMonths, differenceInCalendarMonths, eachDayOfInterval, endOfMonth, startOfMonth } from 'date-fns'
import useRolesViews from 'hooks/useRolesViews'
import { range, throttle } from 'lodash/fp'
import { newDateWithoutTime } from 'utils/date'
import { CELL_WIDTH } from '../components/Grid/constants'
import useAbsencePlannerStateContext from '../contexts/AbsencePlannerStateContext'
import useSelectedStatesContext, { useSelectedStatesActionsContext } from '../contexts/selectedStatesContext'
import { DateRangeT } from '../types'

const startDateParamName = 'startDate'
const endDateParamName = 'endDate'

const MAX_PERIOD_PRELOAD = 2

const getPreloadPeriods = (monthIndex: number): number[] => {
  return range(monthIndex, monthIndex + MAX_PERIOD_PRELOAD)
}

interface IUseCalendarActionsProps {
  badgesLoading: boolean
  filtersLoading: boolean
  scrollRef: React.RefObject<HTMLDivElement>
  checkIsScrolled: () => void
  updateDateRange: (newDateRange: DateRangeT) => void
}

// Hook responsible for calendar relevant state and actions
export const useCalendarActions = ({
  badgesLoading,
  filtersLoading,
  scrollRef,
  checkIsScrolled,
  updateDateRange,
}: IUseCalendarActionsProps) => {
  const queryString = window.location.search
  const urlParams = useMemo(() => new URLSearchParams(queryString), [queryString])
  const startDateQueryString = useMemo(() => urlParams.get(startDateParamName), [urlParams])
  const { personIds } = useRolesViews()

  const [currentMonthIndex, setCurrentMonthIndex] = useState(0)
  const [shownMonthsIndexes, setShownMonthsIndexes] = useState(getPreloadPeriods(currentMonthIndex))
  const [shownMonthIndex, setShownMonthIndex] = useState(0)

  const baseDate = useMemo(
    () => (startDateQueryString ? newDateWithoutTime(startDateQueryString) : newDateWithoutTime()),
    [startDateQueryString],
  )
  const firstDayShown = useMemo(() => addMonths(startOfMonth(baseDate), currentMonthIndex), [
    baseDate,
    currentMonthIndex,
  ])

  const isScrollContainerLoaded = Boolean(scrollRef.current)
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { pinPointingSearchValue } = useSelectedStatesContext()
  const { setPinPointingSearchValue, setSelectedDays } = useSelectedStatesActionsContext()

  const scrollToDay = useCallback(
    (date: Date) => {
      const dayOfMonth = date.getDate()
      if (!isScrollContainerLoaded) return
      const scrollContainer = scrollRef.current
      if (scrollContainer !== null) {
        scrollContainer.scrollLeft = CELL_WIDTH * (dayOfMonth - 1)
      }
    },
    [isScrollContainerLoaded, scrollRef],
  )

  const changeMonth = (newValue: number) => {
    setCurrentMonthIndex(newValue)
    setShownMonthIndex(0)
    setShownMonthsIndexes(getPreloadPeriods(newValue))
  }

  const scrollToToday = useCallback(() => {
    scrollToDay(newDateWithoutTime())
  }, [scrollToDay])

  const next = useCallback(() => {
    const totalMonthsShown = shownMonthsIndexes.length
    const lastIndex = shownMonthsIndexes[totalMonthsShown - 1]
    setShownMonthsIndexes([...shownMonthsIndexes, lastIndex + 1])
  }, [shownMonthsIndexes])

  const resetScrollX = useCallback(() => {
    if (!isScrollContainerLoaded) {
      return
    }

    const scrollContainer = scrollRef.current
    if (scrollContainer !== null) {
      scrollContainer.scrollLeft = 0
    }
  }, [isScrollContainerLoaded, scrollRef])

  const onScrollX = useCallback(
    (container: HTMLDivElement) => {
      checkIsScrolled()
      const amountScrolledX = container.scrollLeft

      const daysScrolled = Math.floor(amountScrolledX / CELL_WIDTH)
      const date = addDays(firstDayShown, daysScrolled)
      const scrolledMonthIndex = differenceInCalendarMonths(date, firstDayShown)
      if (scrolledMonthIndex !== shownMonthIndex) {
        setShownMonthIndex(scrolledMonthIndex)
      }

      const trueScrollWidth = container.scrollWidth - container.clientWidth - 10
      if (amountScrolledX >= trueScrollWidth) next()
    },
    [checkIsScrolled, firstDayShown, next, shownMonthIndex],
  )

  // We only call onScrollX every 250 ms
  const throttledOnScrollX = throttle(250, onScrollX)

  const onMonthScrollerScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const container = event.currentTarget as HTMLDivElement
      throttledOnScrollX(container)
    },
    [throttledOnScrollX],
  )

  const onNextMonthClick = useCallback(() => {
    changeMonth(currentMonthIndex + shownMonthIndex + 1)
    resetScrollX()
  }, [shownMonthIndex, currentMonthIndex, resetScrollX])

  const onPreviousMonthClick = useCallback(() => {
    changeMonth(currentMonthIndex + shownMonthIndex - 1)
    resetScrollX()
  }, [currentMonthIndex, resetScrollX, shownMonthIndex])

  const onMonthSelect = useCallback(
    (newDate: Date) => {
      const monthDifference = differenceInCalendarMonths(newDate, baseDate)
      if (monthDifference !== currentMonthIndex) {
        changeMonth(monthDifference)
        resetScrollX()
      }
    },
    [baseDate, currentMonthIndex, resetScrollX],
  )

  const onTodayClick = useCallback(() => {
    const today = newDateWithoutTime()
    const monthDifference = differenceInCalendarMonths(today, baseDate)
    if (monthDifference !== currentMonthIndex) {
      changeMonth(monthDifference)
    }
    scrollToToday()
  }, [baseDate, currentMonthIndex, scrollToToday])

  // Updates date range on month switch or loading more months to fetch more data and rows rendering
  useEffect(() => {
    const indexFrom = 0 // We will always show all the data we have. To be optimized
    const indexTo = Math.min(shownMonthIndex + MAX_PERIOD_PRELOAD, shownMonthsIndexes.length - 1)
    const startDate = startOfMonth(addMonths(baseDate, shownMonthsIndexes[indexFrom]))
    const shiftDateRange = {
      startDate: startDate,
      endDate: endOfMonth(addMonths(baseDate, shownMonthsIndexes[indexTo])),
    }
    updateDateRange(shiftDateRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen, shownMonthIndex, shownMonthsIndexes])

  // Handles scrolling to pinpointed task location on the calendar
  useEffect(() => {
    if (!pinPointingSearchValue || badgesLoading) return
    const { date, personId } = pinPointingSearchValue
    const shouldCheckMultiple = personIds.length > 1 && personIds.includes(Number(personId))

    const monthDifference = differenceInCalendarMonths(date, baseDate)
    if (monthDifference !== currentMonthIndex) changeMonth(monthDifference)
    else {
      scrollToDay(date)
      const searchId = shouldCheckMultiple
        ? `${personIds.map((id) => `[id^="${`${id}_sidebar-avatar`}"]`)}`
        : `[id^="${`${personId}_sidebar-avatar`}"]`
      const foundCells = document.querySelectorAll(searchId) as NodeListOf<HTMLDivElement>
      if (!foundCells.length || !isScrollContainerLoaded) return
      const scroller = scrollRef.current
      if (scroller !== null) {
        scroller.scrollTop = foundCells[0].offsetTop - scroller.clientHeight / 2
      }

      setPinPointingSearchValue(null)
    }
  }, [
    baseDate,
    currentMonthIndex,
    isScrollContainerLoaded,
    badgesLoading,
    pinPointingSearchValue,
    scrollToDay,
    setPinPointingSearchValue,
    scrollRef,
    personIds,
  ])

  // Sets scroll container position on first load
  useEffect(() => {
    if (filtersLoading) {
      return
    }

    const endDateQueryString = urlParams.get(endDateParamName)
    if (!startDateQueryString || !endDateQueryString) {
      if (isSmallScreen) {
        scrollToToday()
      }
      return
    }
    const startDate = newDateWithoutTime(startDateQueryString)
    const endDate = newDateWithoutTime(endDateQueryString)

    scrollToDay(startDate)
    setSelectedDays(eachDayOfInterval({ start: startDate, end: endDate }))
  }, [isSmallScreen, scrollToDay, scrollToToday, setSelectedDays, startDateQueryString, filtersLoading, urlParams])

  return {
    baseDate,
    shownMonthsIndexes,
    shownMonthIndex,
    onNextMonthClick,
    onPreviousMonthClick,
    onTodayClick,
    onMonthScrollerScroll,
    onMonthSelect,
  }
}
