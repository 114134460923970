import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AddM } from 'icons'
import { classes } from './styles'

interface IAddReminderButton {
  onClick: () => void
}

const AddReminderButton: React.FC<IAddReminderButton> = ({ onClick }) => {
  const { t } = useFavurTranslation()

  return (
    <Button color="primary" variant="plus" sx={classes.addReminderButton} onClick={onClick}>
      <AddM />
      {t('page.userSettings.tenantReminderList.addReminder')}
    </Button>
  )
}

export default AddReminderButton
