import palette from 'utils/theme/palette'

export const classes = {
  pickerStyles: {
    color: palette.grey[600],
    '.option-active': {
      color: palette.primary[700],
    },
    '--highlight-background': palette.primary[25],
    '--highlight-border-radius': '8px',
  },
}
