import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const HandKey: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={100} fill={fill}>
      <path
        fillRule="evenodd"
        d="M18 42v53h69v5H13V42h5ZM87 0v56h13v36l-34.66.07a2.63 2.63 0 0 1-.16-5.25h16.16l9.93-9.93-1.71-1.7-7.45 7.44L82 5H18v4h-5V0h74ZM64.64 30.31a3.34 3.34 0 0 1 4.57-.14l.15.14.14.15a3.34 3.34 0 0 1 0 4.43l-.14.15-1.98 1.98 3.98 3.97a2.55 2.55 0 0 1 .13 3.47l-.13.15-.15.13a2.55 2.55 0 0 1-3.32 0l-.15-.13-3.97-3.98-2.93 2.92 3.98 3.98a2.55 2.55 0 0 1 .13 3.46l-.13.15-.14.13c-.96.83-2.39.83-3.34 0l-.14-.13-3.97-3.97L45.9 58.49a8.9 8.9 0 1 1-4.9-4.93l.27.11 23.37-23.36ZM40.2 59.25a3.61 3.61 0 1 0-5.12 5.1 3.61 3.61 0 0 0 5.12-5.1ZM31 11c1.45 0 2.87 1.17 2.87 2.62 0 1.4-1.31 2.3-2.7 2.38H15l.04 2.66h21.6a2.62 2.62 0 0 1 .2 5.24h-21.8v2.41h25.13a2.62 2.62 0 0 1 .17 5.24h-25.3v2.42h21.6a2.62 2.62 0 0 1 .2 5.24l-23.16.02L8 39v2h3v6H0V11h31Z"
      />
    </Svg>
  )
}

export default HandKey
