import React, { useCallback } from 'react'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import type { NotificationPropsT } from 'components/Notification/types'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { AlertS } from 'icons'
import routes from 'services/RoutesProvider/routes'
import { notificationStatuses, ReminderShareUserDataNotificationT } from 'types'
import palette from 'utils/theme/palette'

interface IShareUserDataReminder extends NotificationPropsT {
  notification: ReminderShareUserDataNotificationT
}

const ShareUserDataReminder: React.FC<IShareUserDataReminder> = (props) => {
  const { pushWithRole } = useHistoryUtils()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props

  const redirectTaskShareUserData = useCallback(
    (taskUuid: string) => {
      return pushWithRole(`${routes.userDataDetails}/${taskUuid}`, ROLE_VIEWS.frontliner)
    },
    [pushWithRole],
  )

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data

    return redirectTaskShareUserData(taskUuid)
  }, [dismiss, notification, redirectTaskShareUserData])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.push.reminder.headline'),
    body: t('notification.sharedata.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default ShareUserDataReminder
