import { useReducer, useCallback, useEffect, Dispatch } from 'react'
import axios from 'axios'
import { isNative } from 'utils/platform'
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file'
import { Filesystem, Directory } from '@capacitor/filesystem'

export type StateT = {
  success: boolean
  error: boolean
  loading: boolean
  fileDownloaded: boolean
  fileError: boolean
  status: 'init' | 'success' | 'error' | 'loading' | 'fileDownloaded' | 'fileError'
  data: { data: Blob; filename: string } | null
}

type ActionT = {
  type: 'init' | 'success' | 'error' | 'loading' | 'fileDownloaded' | 'fileError'
  payload?: { data: Blob; filename: string }
}

const initialState: StateT = {
  success: false,
  error: false,
  loading: false,
  fileDownloaded: false,
  fileError: false,
  status: 'init',
  data: null,
}

const reducer = (state: StateT, action: ActionT) => {
  switch (action.type) {
    case 'init':
      return { ...state, success: false, data: null, status: 'init', loading: false, error: false } as StateT
    case 'loading':
      return { ...state, success: false, data: null, status: 'loading', loading: true, error: false } as StateT
    case 'success':
      return {
        ...state,
        success: true,
        data: action?.payload,
        status: 'success',
        loading: false,
        error: false,
      } as StateT
    case 'fileDownloaded':
      return {
        ...state,
        status: 'fileDownloaded',
        fileDownloaded: true,
        fileError: false,
        data: null,
        success: false,
      } as StateT
    case 'fileError':
      return {
        ...state,
        status: 'fileError',
        fileDownloaded: false,
        fileError: true,
        data: null,
        success: false,
      } as StateT
    case 'error':
      return { ...state, success: false, data: null, status: 'error', loading: false, error: true } as StateT
    default:
      throw new Error()
  }
}

const useGetFileFromServer = () => {
  const [state, dispatch] = useReducer<(state: StateT, action: ActionT) => StateT>(reducer, initialState)

  // we have to provide filename manually
  // https://stackoverflow.com/questions/37897523/axios-get-access-to-response-header-fields
  const getFileFromServer = useCallback(
    async ({ filename, url }: { filename: string; url: string }) => {
      dispatch({ type: 'loading' })
      try {
        const res = await axios.get<Blob>(url, {
          responseType: 'blob',
          withCredentials: true,
        })
        dispatch({ type: 'success', payload: { data: res.data, filename } })
      } catch (error) {
        dispatch({ type: 'error' })
      }
    },
    [dispatch],
  )

  return {
    getFileFromServer,
    dispatch,
    ...state,
  }
}

const storeToNativeLocalStorage = async (
  fileData: { data: Blob; filename: string },
  dispatch: Dispatch<ActionT>,
  onStore: (uri: string) => void,
) => {
  try {
    const reader = new FileReader()
    reader.onload = async () => {
      // https://forum.ionicframework.com/t/capacitor-writefile-saving-pdf-file-is-in-invalid-format/158633/4
      const result = await Filesystem.writeFile({
        path: fileData.filename.replace(/[^a-z0-9.]/gi, ''),
        data: reader.result as string,
        directory: Directory.Cache,
        recursive: true,
      })
      onStore(result.uri)
      dispatch({ type: 'fileDownloaded' })
    }
    reader.readAsDataURL(fileData.data)
  } catch (e) {
    dispatch({ type: 'fileError' })
  }
}

export const useFileDownload = (callback?: () => void) => {
  const { getFileFromServer, data, success, dispatch, ...props } = useGetFileFromServer()
  useEffect(() => {
    if (success && data) {
      if (isNative()) {
        // silence is golden
      } else {
        try {
          const url = window.URL.createObjectURL(data.data)
          const a = document.createElement('a')
          a.href = url
          a.download = data.filename
          a.click()
          dispatch({ type: 'fileDownloaded' })
        } catch (e) {
          dispatch({ type: 'fileError' })
        }
      }
      if (callback) {
        callback()
      }
    }
  }, [callback, data, dispatch, success])

  return {
    ...props,
    data,
    success,
    downloading: props.loading,
    downloadFile: getFileFromServer,
  }
}

export const useFileDisplay = (callback?: () => void) => {
  const { getFileFromServer, data, success, dispatch, ...props } = useGetFileFromServer()
  useEffect(() => {
    if (success && data) {
      if (isNative()) {
        storeToNativeLocalStorage(data, dispatch, (uri: string) => PreviewAnyFile.previewPath(uri).subscribe(() => {}))
      } else {
        try {
          const url = window.URL.createObjectURL(data.data)
          window.open(url)
          dispatch({ type: 'fileDownloaded' })
        } catch (e) {
          dispatch({ type: 'fileError' })
        }
      }
      if (callback) {
        callback()
      }
    }
  }, [callback, data, dispatch, success])
  return {
    ...props,
    data,
    success,
    displaying: props.loading,
    displayFile: getFileFromServer,
  }
}
