import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const AbsencePlanM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.508 21.138h.002a8.458 8.458 0 0 0 2.827-.482 8.166 8.166 0 0 1-.14-16.008l.309 1.456-.308-1.456.134-.027a8.506 8.506 0 0 0-4.914-.222 8.5 8.5 0 0 0 2.09 16.74ZM17.5 4.632a12.376 12.376 0 0 1 .33.26c.082.065.163.133.242.202a.625.625 0 0 1-.562 1.078 6.668 6.668 0 0 0-7.614 3.542 6.667 6.667 0 0 0 6.977 9.518l.063-.01a6.86 6.86 0 0 0 .574-.117.625.625 0 0 1 .563 1.076 9.731 9.731 0 0 1-.57.463 9.959 9.959 0 0 1-5.995 1.994A10.001 10.001 0 1 1 17.5 4.632Zm-.75 2.618a.75.75 0 0 1 .75.75v.5h.5a.75.75 0 0 1 0 1.5h-.5v.5a.75.75 0 0 1-1.5 0V10h-.5a.75.75 0 0 1 0-1.5h.5V8a.75.75 0 0 1 .75-.75Zm2.75 4a.75.75 0 0 1 .75.75v.5h.5a.75.75 0 0 1 0 1.5h-.5v.5a.75.75 0 0 1-1.5 0V14h-.5a.75.75 0 0 1 0-1.5h.5V12a.75.75 0 0 1 .75-.75Zm-4.25 3.25a.75.75 0 0 1 .75.75v.5h.5a.75.75 0 0 1 0 1.5H16v.5a.75.75 0 0 1-1.5 0v-.5H14a.75.75 0 0 1 0-1.5h.5v-.5a.75.75 0 0 1 .75-.75Z"
      />
    </Svg>
  )
}

export default AbsencePlanM
