import { gql } from '@apollo/client'

export const startDeletePersonMutation = gql`
  mutation startDeletePersonMutation($favurUuid: String!, $reason: String) {
    startDeletePerson(favurUuid: $favurUuid, reason: $reason) {
      success
    }
  }
`

export const deleteUserQuery = gql`
  mutation deleteUserQuery {
    deleteUser {
      auth
    }
  }
`
