import React from 'react'
import { TimelineItemContentIdentificationT } from 'components/Timeline/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Lock } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import TimelineCTA from '../TimelineCTA'

const TimeLineItemIdentification: React.FC<TimelineItemContentIdentificationT> = ({ tenantName, taskUuid }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <TimelineCTA
      onClick={() => {
        history.push(`${routes.securitySetup}?taskUuid=${taskUuid}`)
      }}
      icon={<Lock fill={theme.palette.primary.main} />}
      subtitle={tenantName}
      title={t('secureConnection.timeline.identificationCta')}
    />
  )
}

export default TimeLineItemIdentification
