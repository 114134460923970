import React from 'react'

export const capitalize = (word: string, removeSnakeCase?: boolean): string => {
  const string = removeSnakeCase ? word.replace(new RegExp('_', 'g'), ' ') : word
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getSnakeCaseFromId = (stringId: string): string => {
  const reducer = (acc: string, curr: string): string => (acc === '' ? curr : acc + capitalize(curr))
  return stringId.split('-').reduce(reducer, '')
}

export const getSnakeCaseFromCamelCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)

export const getCamelCaseFromSnakeCase = (str: string) =>
  str.replace(/_[a-z]/g, (letter: string) => {
    return `${letter[1].toUpperCase()}`
  })

export const getKebabCaseFromSnakeCase = (stringId: string): string => {
  return stringId
    .split('')
    .map((letter, index) => {
      return letter.toUpperCase() === letter ? `${index !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter
    })
    .join('')
}

export const replaceEscapedCharacters = (string: string): string => string.replace(/\\n/g, '\n').replace(/\\"/g, '"')

export const sanitizeQueryName = (queryName: string): string => queryName.replace(/-/g, '_')

export const escapeNonNumericalKeys = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (!e.key.match(/[0-9]+/)) {
    e.preventDefault()
    return false
  }

  return true
}

const removeDiacritics = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
export const normalizeText = (text: string) => {
  if (!text) return ''
  return removeDiacritics(text.toLowerCase())
}
