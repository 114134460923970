import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const InfoInvertedM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12c0-4.14 3.358-7.5 7.5-7.5a7.5 7.5 0 1 1 0 15A7.5 7.5 0 0 1 4.5 12ZM12 3c-4.97 0-9 4.031-9 9a9 9 0 0 0 9 9 9 9 0 0 0 9-9c0-4.969-4.03-9-9-9Zm0 5a1.206 1.206 0 1 1 0 2.412A1.206 1.206 0 0 1 12 8Zm1.608 7.293c0 .19-.155.344-.345.344h-2.526a.344.344 0 0 1-.345-.345v-.689c0-.19.154-.344.345-.344h.344V12.42h-.344a.345.345 0 0 1-.345-.344v-.69c0-.19.154-.344.345-.344h1.837c.19 0 .345.155.345.345v2.87h.344c.19 0 .345.155.345.345v.69Z"
        fill={fill}
      />
    </Svg>
  )
}
export default InfoInvertedM
