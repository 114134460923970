import React, { useCallback, useEffect, useState } from 'react'
import { NativeBiometric } from 'capacitor-native-biometric'
import { PIN_KEYCHAIN_SERVER } from 'constants/highSecurityConnection'
import usePinKeysStorage from 'pages/PinLogin/usePinKeysStorage'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { removeHighSecuritySessionMutation } from 'shared/queries'
import { isAndroidNative } from 'utils/platform'
import { MutationResult, useMutation } from '@apollo/client'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings'

const useBiometricOptionSettings = () => {
  const history = useHistory()
  const {
    getBioPinDisabled,
    getBioPinInUse,
    setBioPinDisabled,
    removeBioPinInUse,
    removeBioPinDisabled,
  } = usePinKeysStorage()

  const [removeHighSecuritySession] = useMutation<MutationResult>(removeHighSecuritySessionMutation, {
    errorPolicy: 'ignore',
  })

  const [isBioAvailable, setIsBioAvailable] = useState(false)
  const [hasAvailableCredentials, setHasAvailableCredentials] = useState(false)
  const [isBioPinEnabled, setIsBioPinEnabled] = useState(false)

  const engageBiometric = useCallback(async () => {
    if (isAndroidNative()) {
      OpenNativeSettings.open('biometric')
    } else {
      OpenNativeSettings.open('application_details')
    }
  }, [])

  const onChangeSwitch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        removeBioPinDisabled().then(() => setIsBioPinEnabled(true))
      } else {
        setBioPinDisabled(true).then(() => setIsBioPinEnabled(false))
      }
    },
    [removeBioPinDisabled, setBioPinDisabled],
  )

  const onClickDelete = useCallback(() => {
    NativeBiometric.deleteCredentials({ server: PIN_KEYCHAIN_SERVER })
      .then(() => removeBioPinInUse())
      .then(() => setHasAvailableCredentials(false))
  }, [removeBioPinInUse])

  const onClickLogin = useCallback(async () => {
    await removeHighSecuritySession()
    history.push(routes.profileForcePinLogin)
  }, [history, removeHighSecuritySession])

  useEffect(() => {
    const getPinInfo = async () => {
      const inUse = await getBioPinInUse()
      setHasAvailableCredentials(inUse)
      const isDisabled = await getBioPinDisabled()
      setIsBioPinEnabled(!isDisabled)
    }
    getPinInfo()
  }, [getBioPinDisabled, getBioPinInUse])

  useEffect(() => {
    NativeBiometric.isAvailable().then(({ isAvailable }) => {
      return setIsBioAvailable(isAvailable)
    })
  }, [])

  return {
    onChangeSwitch,
    onClickLogin,
    onClickDelete,
    isBioAvailable,
    hasAvailableCredentials,
    isBioPinEnabled,
    engageBiometric,
  }
}

export default useBiometricOptionSettings
