import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import Dialog from 'components/Dialog'
import useDialogs from 'hooks/useDialogs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import { DialogPathsT } from 'services/Dialogs/types'
import { classes } from './styles'

const SecurityTimeoutDialog: React.FC<{ restartPath: string; dialogId: DialogPathsT }> = ({
  restartPath,
  dialogId,
}) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { closeDialog, isOpen } = useDialogs()
  const handleDialogClose = () => {
    closeDialog(dialogId)
  }
  const handleSecuritySetupRedirect = () => {
    handleDialogClose()
    history.push(restartPath)
  }

  return (
    <Dialog
      open={isOpen(dialogId)}
      actions={
        <Box sx={classes.timeoutDialogButtons}>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            size="large"
            data-testid="close-security-timeout-dialog"
          >
            {t('common.dialog.button.close')}
          </Button>
          <Button
            onClick={handleSecuritySetupRedirect}
            color="secondary"
            size="large"
            data-testid="restart-security-setup"
          >
            {t('securitySetup.timeout.restartButton')}
          </Button>
        </Box>
      }
      title={t('securitySetup.timeout.title')}
    >
      <Typography variant="body2">{t('securitySetup.timeout.content')}</Typography>
    </Dialog>
  )
}

export default SecurityTimeoutDialog
