import React, { LegacyRef, Ref } from 'react'
import { Box, IconButton } from '@mui/material'
import { CloseL } from 'icons'
import { isNative } from 'utils/platform'
import PrimaryNavigation from '../PrimaryNavigation'
import SecondaryNavigation from '../SecondaryNavigation'
import TertiaryNavigation from '../TertiaryNavigation'
import { toolbarClasses } from '../styles'

interface NavigationGroupProps {
  toggleDrawer: () => void
  scrollRef: LegacyRef<HTMLDivElement>
}

const NavigationMenu: React.FC<NavigationGroupProps> = ({ toggleDrawer, scrollRef }) => {
  return (
    <Box sx={toolbarClasses.menu} data-testid="navigation-menu">
      <Box sx={toolbarClasses.scrollablePart} ref={scrollRef as Ref<HTMLDivElement>}>
        <PrimaryNavigation toggleDrawer={toggleDrawer} />
        <SecondaryNavigation toggleDrawer={toggleDrawer} />
        <TertiaryNavigation toggleDrawer={toggleDrawer} />
      </Box>
      {isNative() && (
        <Box sx={toolbarClasses.closeButton}>
          <IconButton onClick={toggleDrawer} size="large" disableRipple sx={toolbarClasses.closeIconButton}>
            <CloseL fill="white" />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default NavigationMenu
