import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const Bird20: React.FC<IconPropsT> = () => {
  return (
    <Svg size={20}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7 6.43V3.57h2.86L12.7 6.43Z" fill="#FF0062" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7 5V3.57h1.43L12.7 5Z" fill="#FFC300" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7 3.57 9.13 0 5.56 3.57V20l7.14-7.14V3.57Z" fill="#1F3867" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.98 18.93-1-3.93h-.42l1 3.93H9.13v.36h4.28v-.36h-2.43Z"
        fill="#0A275C"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7 12.86V5l-7.14 7.14V20l7.14-7.14Z" fill="#8EA1C3" />
      <path fillRule="evenodd" clipRule="evenodd" d="m11.27 6.43-5.71 5.71v5.72l5.71-5.72V6.43Z" fill="#0A275C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.79 4.3a1.54 1.54 0 1 1-3.08 0 1.54 1.54 0 0 1 3.08 0"
        fill="#FF0062"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1 4.3a.85.85 0 1 1-1.7 0 .85.85 0 0 1 1.7 0" fill="#191919" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.67 3.73-.58.58h-.84l1.09-1.09c.14.14.26.32.33.5Z"
        fill="#fff"
      />
    </Svg>
  )
}

export default Bird20
