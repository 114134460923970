import React from 'react'
import { Box } from '@mui/material'
import ShiftCardPlannedShift from 'components/ShiftsList/components/ShiftCard/ShiftTypes/ShiftCardPlannedShift'
import type { TeamMemberT } from 'components/ShiftsList/components/ShiftCard/TeamsSection/types'
import AvatarNameRow from '../AvatarNameRow'
import { classes } from '../styles'

interface IShiftTileProps {
  attendance: TeamMemberT
  hideCostCenter: boolean
}

const ShiftTile: React.FC<IShiftTileProps> = ({ attendance, hideCostCenter }) => {
  return (
    <Box data-testid="teamplan-detail__shift-tile">
      <AvatarNameRow teamMember={attendance} />
      <Box sx={classes.shiftsContainer}>
        <ShiftCardPlannedShift
          shifts={attendance.shifts}
          costCenterName={attendance.shifts[0].costCenterName ?? ''}
          hideCostCenter={hideCostCenter}
        />
      </Box>
    </Box>
  )
}

export default ShiftTile
