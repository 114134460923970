import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const HomeS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M7.99995 4.5L12.9999 8.5V13.5H8.99995V10H6.99995V13.5H2.99995V8.5L7.99995 4.5ZM7.99995 2L14.148 7.12335C14.2459 7.20496 14.2667 7.3472 14.1963 7.45347L13.834 8.00049C13.7577 8.1156 13.6026 8.14709 13.4875 8.07084L13.4655 8.05448L7.99995 3.5L2.5344 8.05448C2.42833 8.14287 2.27069 8.12854 2.1823 8.02247L2.16594 8.00049L1.80356 7.45347C1.73315 7.3472 1.75399 7.20496 1.85193 7.12335L7.99995 2Z"
      />
    </Svg>
  )
}

export default HomeS
