import theme from 'utils/theme'

const classes = {
  termsCheckboxContainer: {
    '&.MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  termsCheckbox: {
    '&.MuiCheckbox-root': {
      paddingTop: '0',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  },
  registerForm: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: `${theme.spacing(0.75)} !important`,
  },
  connectButton: {
    marginBottom: theme.spacing(1),
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  registerContainerPaper: {
    flexGrow: 1,
  },
}

export default classes
