import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface IDisableDialogProps {
  dialogOpen: boolean
  handleClose: () => void
  disableAnyway: () => void
}

const DisableDialog: React.FC<IDisableDialogProps> = ({ dialogOpen, disableAnyway, handleClose }) => {
  const { t } = useFavurTranslation()
  return (
    <Dialog
      sx={classes.dialog}
      open={dialogOpen}
      onClose={handleClose}
      title={t('page.notificationsSettings.dialog.title')}
      actions={
        <>
          <Button onClick={disableAnyway} color="secondary" size="large" sx={classes.firstDialogButton}>
            {t('page.notificationsSettings.dialog.disableAnyway')}
          </Button>
          <Button onClick={handleClose} color="secondary" size="large">
            {t('page.userSettings.personalData.actions.cancel')}
          </Button>
        </>
      }
    >
      <Typography variant="body2">{t('page.notificationsSettings.dialog.content')}</Typography>
    </Dialog>
  )
}

export default DisableDialog
