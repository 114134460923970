import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppStatesContext } from 'services/AppStates'
import { RoleViewT } from 'types'
import useRolesViews from './useRolesViews'

export const backLinkKey = 'STATE_BACK_LINK'

type DefaultActionT = string | (() => void)
const useStateBackLink = () => {
  const { get, clear, set } = useAppStatesContext()
  const history = useHistory()
  const { setActiveView, activeView } = useRolesViews()

  const backLink = useMemo(
    () => get(backLinkKey),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const originBackLink = useMemo(() => {
    // Path of the page from which the current page has been accessed
    const state = history?.location?.state as { origin?: string } | undefined

    if (state?.origin) {
      return state.origin
    }
    return null
  }, [history?.location?.state])

  const setBackLink = useCallback(
    (link: string) => {
      set(backLinkKey, link)
    },
    [set],
  )

  const clearBackLink = useCallback(() => {
    clear(backLinkKey)
  }, [clear])

  const pushWithCurrentPageBackLink = useCallback(
    (newRoute: string, roleChange?: RoleViewT) => {
      if (roleChange && activeView !== roleChange) {
        setActiveView(roleChange)
      }

      setBackLink(history.location.pathname)
      history.push(newRoute)
    },
    [activeView, history, setActiveView, setBackLink],
  )

  const pushBackLinkOrDefault = useCallback(
    (defaultAction: DefaultActionT) => {
      if (backLink) {
        clearBackLink()
        history.push(backLink)
        return
      }

      if (typeof defaultAction === 'function') {
        defaultAction()
      } else {
        history.push(defaultAction)
      }
    },
    [backLink, clearBackLink, history],
  )

  const pushOriginLinkOrDefault = useCallback(
    (defaultAction: DefaultActionT) => {
      // if a path origin exists, go back to it. Otherwise a back to history or an indicated default action
      if (originBackLink) {
        return history.push(originBackLink)
      }
      return pushBackLinkOrDefault(defaultAction)
    },
    [history, pushBackLinkOrDefault, originBackLink],
  )

  return {
    backLink,
    originBackLink,
    setBackLink,
    clearBackLink,
    pushWithCurrentPageBackLink,
    pushBackLinkOrDefault,
    pushOriginLinkOrDefault,
  }
}

export default useStateBackLink
