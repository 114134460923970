import React, { useMemo } from 'react'
import { FALLBACK_LANGUAGE } from 'constants/i18n'
import { FormHandlersT } from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import nationalities from 'i18n-nationality'
import UncontrolledSelect from '../UncontrolledSelect'

nationalities.registerLocale(require('i18n-nationality/langs/en.json'))
nationalities.registerLocale(require('i18n-nationality/langs/de.json'))
nationalities.registerLocale(require('i18n-nationality/langs/fr.json'))
nationalities.registerLocale(require('i18n-nationality/langs/it.json'))

const SUPPORTED_NATIONALITY_LANGUAGES = ['en', 'fr', 'de', 'it']
const DEFAULT_NATIONALITY_LANGUAGE = FALLBACK_LANGUAGE

interface INationalitySelectProps {
  formHandlers: FormHandlersT
  inputName: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  defaultValue?: string | null
  sortOptions?: boolean
}

const NationalitySelect: React.FC<INationalitySelectProps> = ({
  formHandlers,
  inputName,
  onChange,
  defaultValue,
  sortOptions,
}) => {
  const { locale } = useFavurTranslation()
  const natValues = useMemo(() => {
    const lang = SUPPORTED_NATIONALITY_LANGUAGES.includes(locale?.code || '')
      ? locale?.code
      : DEFAULT_NATIONALITY_LANGUAGE
    return nationalities.getNames(lang || DEFAULT_NATIONALITY_LANGUAGE)
  }, [locale])

  return (
    <UncontrolledSelect
      required
      formHandlers={formHandlers}
      name={inputName}
      options={natValues}
      onChange={onChange}
      sortOptions={sortOptions}
      defaultValue={defaultValue}
      translate
    />
  )
}

export default NationalitySelect
