import theme from 'utils/theme'

export const classes = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  paragraph2: {
    marginTop: theme.spacing(1.5),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.25),
  },
  button: {
    marginBottom: theme.spacing(0.5),
  },
  link: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.25),
    // @ts-ignore
    color: theme.palette.primary[700],
  },
}
