import React, { useCallback, useContext, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import { NumpadWithoutState } from 'components/Numpad'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import useSessionContext from 'hooks/useSessionContext'
import { useHistory } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { newPhoneMutation } from './queries'
import { classes } from './styles'

const NewPhone: React.FC<{ goToStep: (stepName: string) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { phone: currentPhone } = useSessionContext()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const { updatePhoneNumber, phone, setPhone } = usePhoneNumber('')
  const fullPhoneNumber = `${phone.countryCode ? `+${phone.countryCode}` : ''}${phone.phoneNumber}`

  const [error, setError] = useState(false)
  const getHelperText = () => {
    return error ? t('login.start.error.phoneInvalid') : t('login.start.content.phoneHelperText')
  }
  const currentPhoneTranslation = t('page.changePhoneNumber.newNumber.currentPhoneNumber')
  const newPhoneTranslation = t('page.changePhoneNumber.newNumber.title')

  const [changePhoneMutation] = useJamesMutation(newPhoneMutation)
  const onSubmit = useCallback(() => {
    setError(!phone.isValidNumber)
    if (!phone.isValidNumber) {
      return
    }

    changePhoneMutation({ phoneNumber: fullPhoneNumber }).then(
      (data) => {
        if (data.initUserPhoneNumberUpdate.success) {
          removeAll()
          goToStep('CONFIRM_SMS')
        } else {
          setFlashMessage('login.start.error.phoneInvalid')
        }
      },
      () => {},
    )
  }, [fullPhoneNumber, setFlashMessage, changePhoneMutation, removeAll, goToStep, phone.isValidNumber])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePhoneNumber(e.target.value)
  }

  return (
    <CenteredBox sx={[classes.root, classes.spaceBetween]}>
      <Box>
        <Typography aria-label="New phone title" variant="h2" sx={classes.title}>
          {newPhoneTranslation}
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="standard"
          fullWidth
          sx={classes.formInput}
          name="currentPhone"
          label={`${currentPhoneTranslation}`}
          inputProps={{ 'data-testid': 'user-personal-data_current-phone' }}
          disabled
          value={currentPhone?.replace('+', '00')}
        />
        <PhoneNumberInput
          label={t('login.start.content.phoneNumber')}
          phone={phone}
          onChange={onChange}
          setPhone={setPhone}
          error={error}
          helperText={getHelperText()}
        />
      </Box>
      <Box>
        <Box>
          <NumpadWithoutState
            numpadType="pin"
            numpadValue={phone.phoneNumber}
            setNumpadValue={updatePhoneNumber}
            onConfirm={onSubmit}
            usedWithPhoneNumberValidation
          />
        </Box>
        <Button
          onClick={() => history.push(routes.profile)}
          sx={classes.cancelButton}
          data-testid="change-phone_cancel"
        >
          {t('securitySetup.intro.button.cancel')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default NewPhone
