import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { RefreshM } from 'icons'
import { classes } from '../styles'

interface IRevalidateIconProps {
  loading: boolean
}

const RevalidateIcon: React.FC<IRevalidateIconProps> = ({ loading }) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (loading) {
      setAnimate(true)
    }
  }, [loading])

  return (
    <Box
      sx={[classes.circle, ...(animate ? [classes.spinning] : [])]}
      onAnimationIteration={() => {
        if (!loading) {
          setAnimate(false)
        }
      }}
    >
      <RefreshM />
    </Box>
  )
}

export default RevalidateIcon
