import React from 'react'
import { Box, Typography } from '@mui/material'
import Avatar from 'components/Avatar'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getAvatarFromPerson } from 'pages/Tasks/utils/timeline'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import { PersonT, PersonTeamPermissionT } from 'types'
import { getUserName } from 'utils/person'
import { classes } from './styles'

interface IPermissionsSectionProps {
  memberList: PersonTeamPermissionT[]
}

const MembersSection: React.FC<IPermissionsSectionProps> = ({ memberList }) => {
  const { t } = useFavurTranslation()
  return (
    <UserSettingsSection title={t('page.userSettings.team.members')}>
      <Card>
        <Box sx={classes.contentContainer}>
          {!memberList.length && (
            <Typography variant="body1" sx={classes.noOtherMembers}>
              {t('page.userSettings.team.noOtherMembers')}
            </Typography>
          )}
          {memberList.map((ptp) => (
            <Box key={ptp.person?.id} sx={classes.contentRow}>
              <Avatar size={28} avatar={getAvatarFromPerson(ptp.person as PersonT, t, false)} />
              <Typography variant="body1">{getUserName(ptp.person as PersonT, true)}</Typography>
            </Box>
          ))}
        </Box>
      </Card>
    </UserSettingsSection>
  )
}

export default MembersSection
