import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: theme.spacing(2.6875),
  },
  pinDigitUnderline: {
    width: theme.spacing(2),
    height: theme.spacing(0.125),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  pinDigitEmpty: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[300],
  },
  pinDigitSelected: {
    // @ts-ignore
    backgroundColor: theme.palette.secondary[400],
  },
  pinDigitFilled: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[700],
  },
  digit: {
    // @ts-ignore
    color: theme.palette.primary[900],
    fontFamily: 'FiraSansCondensed',
  },
}
