import React from 'react'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { TimelineItemContentBadgeTypeT } from 'components/Timeline/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TFunction } from 'i18next'
import DeleteWorkflow from 'pages/Tasks/components/ActionButtons/DeleteWorkflow'
import Plus from 'pages/Tasks/components/ActionButtons/Plus'
import SendComment from 'pages/Tasks/components/ActionButtons/SendComment'
import SendReminder from 'pages/Tasks/components/ActionButtons/SendReminder'
import { taskStates } from 'pages/Tasks/constants'
import { EventsOfTask, MonthlySheetTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { MonthlySheetStatus } from '../../types'
import Accept from './Accept'
import AcceptManager from './AcceptManager'
import AcceptMultiple from './AcceptMultiple'
import AcceptNextMonth from './AcceptNextMonth'
import AcceptThisMonth from './AcceptThisMonth'
import Call from './Call'
import RequestChange from './RequestChange'

const plusDataTestId = 'monthly-sheet-detail_options'

interface IActionButtonsProps {
  task: TaskT<MonthlySheetTaskT>
  isOffice: boolean
  onAction: () => void
  prevMonthTask?: TaskT<MonthlySheetTaskT>
}

type IActionButtonsPropsWithT = IActionButtonsProps & { t: TFunction }

const getRequestChangeButton = (task: TaskT<MonthlySheetTaskT>, onAction: () => void) => {
  if (task.status === MonthlySheetStatus.markedChangeNextMonth) {
    return null
  }

  const taskHasTimeline = Boolean(task.timeline?.length)
  if (!taskHasTimeline) {
    return null
  }

  const taskHasRequestChange = Boolean(
    task.timeline?.find((timelineItem: TimelineItemT) => {
      return timelineItem.badge === TimelineItemContentBadgeTypeT.wtcChangeRequest
    }),
  )

  const taskUuid = task.favurUuid

  if (taskHasRequestChange) {
    return (
      <SendComment
        variant="text"
        event={EventsOfTask.monthlySheetSendCommentToManager}
        taskUuid={taskUuid}
        onAction={onAction}
      />
    )
  }

  return <RequestChange taskUuid={taskUuid} onAction={onAction} />
}

const getButtonsFromStateFrontline = (
  task: TaskT<MonthlySheetTaskT>,
  onAction: () => void,
  t: TFunction,
  prevMonthTask?: TaskT<MonthlySheetTaskT>,
) => {
  const taskUuid = task.favurUuid
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        {prevMonthTask ? (
          <AcceptMultiple taskUuid={taskUuid} prevMonthTaskUuid={prevMonthTask?.favurUuid} onAction={onAction} />
        ) : (
          <Accept taskUuid={taskUuid} onAction={onAction} />
        )}
        {getRequestChangeButton(task, onAction)}
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Accept taskUuid={taskUuid} onAction={onAction} />
        <Plus dataTestId={plusDataTestId}>
          <SendComment event={EventsOfTask.monthlySheetSendCommentToManager} taskUuid={taskUuid} onAction={onAction} />
          <SendReminder
            taskUuid={taskUuid}
            onAction={onAction}
            label={t('workTimeControl.buttons.sendReminder')}
            dataTestId="monthly-sheet-detail_send_reminder"
          />
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromStateOffice = (task: TaskT<MonthlySheetTaskT>, onAction: () => void, t: TFunction) => {
  const taskUuid = task.favurUuid
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <AcceptManager>
          <AcceptThisMonth taskUuid={taskUuid} onAction={onAction} />
          <AcceptNextMonth taskUuid={taskUuid} onAction={onAction} />
        </AcceptManager>
        <Plus dataTestId={plusDataTestId}>
          <SendComment
            event={EventsOfTask.monthlySheetSendCommentToFrontline}
            taskUuid={taskUuid}
            onAction={onAction}
          />
          <Call />
          <DeleteWorkflow task={task} label={t('workTimeControl.buttons.deleteWorkflow')} />
        </Plus>
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus dataTestId={plusDataTestId}>
          <SendComment
            event={EventsOfTask.monthlySheetSendCommentToFrontline}
            taskUuid={taskUuid}
            onAction={onAction}
          />
          <SendReminder
            taskUuid={taskUuid}
            onAction={onAction}
            isOffice
            label={t('workTimeControl.buttons.sendReminder')}
            dataTestId="monthly-sheet-detail_send_reminder"
          />
          <DeleteWorkflow task={task} label={t('workTimeControl.buttons.deleteWorkflow')} />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}

const getButtonsFromTaskAndView = ({ task, isOffice, prevMonthTask, onAction, t }: IActionButtonsPropsWithT) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }

  if (isOffice) {
    return getButtonsFromStateOffice(task, onAction, t)
  }

  return getButtonsFromStateFrontline(task, onAction, t, prevMonthTask)
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ task, isOffice, prevMonthTask, onAction }) => {
  const { t } = useFavurTranslation()

  return <>{getButtonsFromTaskAndView({ task, isOffice, prevMonthTask, onAction, t })}</>
}

export default ActionButtons
