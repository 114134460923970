import React, { PropsWithChildren, useState } from 'react'
import { Box, Button } from '@mui/material'
import BottomDrawer from 'components/Basics/BottomDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

const AcceptManager: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useFavurTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setIsOpen(true)}
        data-testid="monthly-sheet-detail_accept-manager"
      >
        {t('workTimeControl.buttons.accept')}
      </Button>
      <BottomDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={classes.buttonsContainer}>{children}</Box>
      </BottomDrawer>
    </>
  )
}

export default AcceptManager
