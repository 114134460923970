import theme from 'utils/theme'

export const classes = {
  header: {
    textAlign: 'center',
  },
  paper: {
    display: 'flex',
  },
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  connected: {
    color: theme.palette.primary.main,
  },
  assetCheckmark: {
    paddingTop: theme.spacing(3),
    // @ts-ignore
    color: theme.palette.primary[500],
  },
  description: {
    flex: 1,
    padding: theme.spacing(0.75, 0.25),
    textAlign: 'center',
  },
  closeButton: {
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
  },
}
