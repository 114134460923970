import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const SuccessS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.646 3.646l.708.708c.195.195.195.511 0 .707l-7.5 7.5c-.196.195-.512.195-.708 0l-4.5-4.5c-.195-.196-.195-.512 0-.707l.708-.708c.195-.195.511-.195.707 0L6.5 10.085l6.44-6.439c.195-.195.511-.195.706 0z"
      />
    </Svg>
  )
}

export default SuccessS
