import React from 'react'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import BottomDrawer from 'components/Basics/BottomDrawer'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { BalanceObject } from 'types/balances'
import ContentTable from './ContentTable'
import { classes } from './styles'

interface IEmployeeSaldoDrawerProps {
  balances?: BalanceObject[]
  loading: boolean
  isOpen: boolean
  onClose: () => void
  frontlinerFullName?: string
  tenantName?: string
  drawerSx?: SxProps<Theme>
}

const EmployeeSaldoDrawer: React.FC<IEmployeeSaldoDrawerProps> = ({
  balances,
  loading,
  isOpen,
  onClose,
  frontlinerFullName,
  tenantName,
  drawerSx,
}) => {
  const { t } = useFavurTranslation()
  return (
    <BottomDrawer isOpen={isOpen} onClose={onClose} paperSx={drawerSx}>
      {loading && (
        <Box sx={classes.loadingBox}>
          <SimpleLoading />
        </Box>
      )}
      {!loading && balances?.length === 0 && (
        <Box sx={classes.buttonsContainer}>
          <Typography variant="subtitle1">{t('absenceRequest.components.employeeSaldo.noData')}</Typography>
        </Box>
      )}
      {!loading && balances && (
        <Box sx={classes.buttonsContainer}>
          <ContentTable balance={balances[0]} frontlinerFullName={frontlinerFullName} tenantName={tenantName} />
        </Box>
      )}
    </BottomDrawer>
  )
}

export default EmployeeSaldoDrawer
