import theme from 'utils/theme'

export const classes = {
  gridForFormField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    paddingBottom: '0 !important',
    alignItems: 'center',
  },
  gridForTitle: {
    paddingTop: `${theme.spacing(1)} !important`,
  },
  gridForSubtitle: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    paddingBottom: '0 !important',
    alignItems: 'center',
  },
  gridForFileTitle: {
    paddingTop: theme.spacing(0.75),
  },
  divider: {
    //@ts-ignore
    borderBottom: `2px solid ${theme.palette.primary[50]}`,
    padding: '0 !important',
  },
  fileFieldErrorText: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(1),
  },
  additionalAddressesBox: {
    //@ts-ignore
    backgroundColor: theme.palette.primary[50],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
    display: 'flex',
    flexFirection: 'column',
    alignItems: 'start',
  },
  pinkDeleteButton: {
    color: theme.palette.secondary.main,
    marginLeft: 'auto',
  },
  pinkAddButton: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.secondary.main,
    display: 'flex',
    '& .MuiButton-root.MuiButton-startIcon': {
      marginLeft: 0,
    },
  },
}

export const selectClasses = {
  root: {
    width: '100%',
    '& .MuiNativeSelect-select, & .MuiSelect-select': {
      textAlign: 'left',
      '&:hover, &:focus, &:hover:before': {
        backgroundColor: 'transparent',
      },
    },
  },
}

export const addressClasses = {
  formButtonsSpacing: {
    marginTop: theme.spacing(1.5),
  },
}
