import React from 'react'
import { Typography } from '@mui/material'
import theme from 'utils/theme'
import { classes } from './styles'

interface ISectionTitleProps {
  children?: React.ReactNode
  textColor?: string
  gutter?: number
  marginLeft?: number
  testId?: string
}

const SectionTitle: React.FC<ISectionTitleProps> = ({
  marginLeft = 0.0,
  gutter = 0.25,
  children,
  textColor = theme.palette.primary.main,
  testId,
}) => {
  return (
    <Typography variant="h3" sx={classes.title(textColor, gutter, marginLeft)} data-testid={testId}>
      {children}
    </Typography>
  )
}

export default SectionTitle
