import React from 'react'
import Tutorial from 'components/Tutorial'
import IntroPage from 'components/Tutorial/IntroPage'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import routes from 'services/RoutesProvider/routes'
import { ShiftPlanTutorialProps } from './types'

const Shiftplan: React.FC<ShiftPlanTutorialProps> = ({
  tutorialName,
  t,
  step,
  setStep,
  history,
  hasUserAvatar,
}: ShiftPlanTutorialProps) => {
  const goToShifts = () => {
    history.push(routes.shifts)
  }

  const goToProfile = () => {
    history.push(routes.profile)
  }

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.shiftplan.step1.titleText`),
      mainText: t(`tutorial.shiftplan.step1.text`),
    },
    {
      key: 'step2',
      titleText: t(`tutorial.shiftplan.step2.titleText`),
      mainText: t(`tutorial.shiftplan.step2.text`),
    },
    {
      key: 'step3',
      titleText: t(`tutorial.shiftplan.step3.titleText`),
      mainText: t(`tutorial.shiftplan.step3.text`),
    },
    {
      key: 'step4',
      titleText: t(`tutorial.shiftplan.step4.titleText`),
      mainText: t(`tutorial.shiftplan.step4.text`),
    },
    {
      key: 'step5',
      titleText: t(`tutorial.shiftplan.step5.titleText`),
      mainText: t(`tutorial.shiftplan.step5.text`),
    },
    {
      key: 'step6',
      close: () => goToShifts(),
      goTo: hasUserAvatar ? () => goToShifts() : () => goToProfile(),
      goToTitle: t('tutorial.shiftplan.step6.goToProfile'),
      titleText: t(`tutorial.shiftplan.step6.titleText`),
      mainText: t(`tutorial.shiftplan.step6.text`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  const introPage = <IntroPage tutorialName={tutorialName} stepName="intro" close={goToShifts} />

  return (
    <Tutorial
      introPage={introPage}
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => (hasUserAvatar ? goToShifts() : goToProfile())}
    />
  )
}

export default Shiftplan
