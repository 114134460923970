import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const EditS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.69 2.313a2.778 2.778 0 0 1-.022 3.943l-7.085 7.085a1 1 0 0 1-.459.262l-3.376.866a1 1 0 0 1-1.217-1.217l.866-3.376a1 1 0 0 1 .261-.459l7.085-7.085a2.78 2.78 0 0 1 3.944-.022l.003.003Zm-3.615 1.296 2.413 2.413-6.333 6.333-2.416-2.412a.5.5 0 0 0-.11-.083l6.362-6.362a.5.5 0 0 0 .084.11Zm-6.885 7.2L2.5 13.5l2.694-.691-2.004-2Zm10.168-5.84c-.049.116-.11.225-.182.327l-2.394-2.394a.502.502 0 0 0-.09-.071 1.775 1.775 0 0 1 2.666 2.138Z"
      />
    </Svg>
  )
}

export default EditS
