import React from 'react'
import { Typography } from '@mui/material'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { teamListClasses as classes } from './styles'

interface PersonListHeaderPropsI {
  ownerId: string
  title: string
  subtitle: string
}

const PersonListHeader: React.FC<PersonListHeaderPropsI> = ({ ownerId, title, subtitle }) => {
  const { t } = useFavurTranslation()

  return (
    <WithBackwardLink route={routes.teamsWithOwner(ownerId)} title={title} topTitle={t('page.team.header.title')}>
      <Typography variant="body2" sx={classes.text}>
        <strong>{subtitle}</strong>
      </Typography>
    </WithBackwardLink>
  )
}

export default PersonListHeader
