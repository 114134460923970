import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'

const MenuBurgerFavurXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg width={37} height={37} fill={fill} viewBox="0 0 37 37">
      <g transform="translate(-326 -9)">
        <path
          fill="#FFF"
          d="M352 38a1 1 0 011 1v2a1 1 0 01-1 1h-25a1 1 0 01-1-1v-2a1 1 0 011-1h25zm-2-10v4h-23a1 1 0 01-1-1v-2a1 1 0 011-1h23zm-23-10h17l4 4h-21a1 1 0 01-1-1v-2a1 1 0 011-1zM357.987 13L363 18v24l-10-10V18z"
        />
        <path fill="#546D99" d="M355.5 22.5L363 30v9l-7.5-7.5z" />
        <path fill="#FF0062" d="M353 23v-3l-2-2h-3z" />
        <path fill="#FFC300" d="M353 20v-2h-2z" />
        <path
          fill="#FF0062"
          d="M358 16.5a2.5 2.5 0 010 5 2.5 2.5 0 01-2.234-3.624L356.75 19H358l-1.646-1.881A2.485 2.485 0 01358 16.5z"
        />
        <path
          fill="#191919"
          d="M358 17.75a1.25 1.25 0 11-1.244 1.378L356.75 19H358l-.823-.94c.22-.193.508-.31.823-.31z"
        />
      </g>
    </Svg>
  )
}

export default MenuBurgerFavurXL
