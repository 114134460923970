import React from 'react'
import PinLogin from 'pages/PinLogin'
import TenantRemindersPageContent from './TenantRemindersContent'

const TenantRemindersPage: React.FC = () => {
  return (
    <PinLogin>
      <TenantRemindersPageContent />
    </PinLogin>
  )
}

export default TenantRemindersPage
