import React, { useCallback } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { ShareUserDataAcceptedNotificationT } from 'types'
import NotificationTemplate from '../Templates/Notification'
import type { NotificationPropsT } from '../types'

interface IShareUserDataAcceptedProps extends NotificationPropsT {
  notification: ShareUserDataAcceptedNotificationT
}

const ShareUserDataAccepted: React.FC<IShareUserDataAcceptedProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.userDataDetails}/${notification.data.taskUuid}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.new.sharedata.accepted.title'),
    body: t('notification.new.sharedata.tenant', { tenantName: notification.data.tenantName }),
    dismissText: t('component.notification.button.dismiss'),
    detailsText: t('component.notification.button.viewDetails'),
    details: handleDetails,
  }
  return <NotificationTemplate {...templateProps} />
}

export default ShareUserDataAccepted
