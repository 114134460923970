import React, { useContext } from 'react'
import { Box, Button } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { ArrowLeftThinM, ArrowRightThinM } from 'icons'
import AddComment from 'pages/Tasks/ShareUserData/Details/ActionButtons/AddComment'
import { EventsOfTask } from 'pages/Tasks/types'
import theme from 'utils/theme'
import { ShareUserDataFormContext } from '..'
import { classes } from './styles'

interface IFormActionButtons {
  onNext: () => void
  onPrevious: () => void
  lastStep: boolean
  showCommentButton: boolean
}

const FormActionButtons: React.FC<IFormActionButtons> = ({ onPrevious, onNext, lastStep, showCommentButton }) => {
  const { favurUuid, refetch } = useContext(ShareUserDataFormContext)
  return (
    <ActionCardButtons>
      {!lastStep && showCommentButton && (
        <AddComment
          taskUuid={favurUuid}
          event={EventsOfTask.shareUserDataSendCommentToManager}
          onAction={() => {
            refetch()
          }}
          showSuccessDrawer
        />
      )}
      <Box sx={classes.actionButtonArrowContainer}>
        <Button
          sx={classes.actionButton}
          variant="contained"
          onClick={onPrevious}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          <ArrowLeftThinM fill={theme.palette.primary.main} />
        </Button>
        <Button
          sx={classes.actionButton}
          variant="contained"
          onClick={onNext}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          disabled={lastStep}
        >
          <ArrowRightThinM fill={theme.palette.primary.main} />
        </Button>
      </Box>
    </ActionCardButtons>
  )
}

export default FormActionButtons
