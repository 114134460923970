import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const HourglassS: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_S}>
      <path
        fill="none"
        stroke={stroke || theme.palette.grey[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.52 4.2C4.85 6.07 6 7.35 8 8c2.01-.65 3.15-1.93 3.48-3.8a.87.87 0 0 0-.87-1.01H5.39a.88.88 0 0 0-.87 1ZM3 1.5h10M5.4 12.81a.87.87 0 0 1-.88-1C4.85 9.93 6 8.65 8 8c2.01.65 3.15 1.93 3.48 3.8a.88.88 0 0 1-.87 1.01H5.39ZM3 14.5h10"
      />
    </Svg>
  )
}

export default HourglassS
