import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const PhoneM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.108.036c-.422.067-.726.18-1.041.389-.622.41-1.035 1.114-1.153 1.96-.037.267-.036 19.021 0 19.323.067.542.304 1.065.658 1.45.237.259.695.543 1.083.673.508.17-.072.154 6.25.163 4.033.006 5.759 0 5.903-.018.48-.062.906-.23 1.26-.497.23-.173.326-.271.498-.513.255-.36.417-.76.5-1.242.045-.267.046-.462.046-9.744 0-10.298.007-9.74-.133-10.18-.126-.398-.388-.82-.663-1.066A2.979 2.979 0 0 0 18.24.135c-.47-.141-.146-.135-6.3-.132-4.447.002-5.68.009-5.832.033Zm.132 1.503c-.393.077-.67.375-.793.854-.046.18-.047.346-.047 9.654 0 8.424.004 9.487.037 9.614.109.424.343.644.829.778.176.05.206.05 5.76.05 3.64 0 5.629-.009 5.713-.025.189-.035.408-.151.525-.278.121-.132.248-.394.3-.623.033-.15.036-1.112.03-9.683l-.006-9.516-.051-.134c-.138-.36-.36-.546-.802-.668-.217-.06-.67-.072-.67-.017 0 .114-.276 1.857-.315 1.988-.176.597-.737 1.11-1.347 1.23-.278.056-6.527.056-6.806 0-.61-.12-1.17-.633-1.348-1.231-.039-.13-.313-1.87-.313-1.987 0-.026-.059-.032-.294-.03a3.23 3.23 0 0 0-.402.024Zm2.218.04c.007.036.057.368.11.737.053.37.108.713.121.762a.302.302 0 0 0 .104.15l.08.06h6.255l.08-.06a.304.304 0 0 0 .103-.15c.014-.05.068-.392.121-.762.053-.37.103-.701.11-.738l.014-.066H8.444l.014.066Z"
      />
    </Svg>
  )
}

export default PhoneM
