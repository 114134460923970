import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import TeamplanDatePicker from 'components/DatePickers/TeamplanDatePicker'
import { classes } from './styles'

interface IDateHeaderProps {
  baseDate: Date
  goToDay: (date: Date) => void
}

const DateHeader: React.FC<IDateHeaderProps> = ({ baseDate, goToDay }) => {
  const [datePickerDate, setDatePickerDate] = useState(baseDate)

  useEffect(() => {
    setDatePickerDate(baseDate)
  }, [baseDate])

  return (
    <Box sx={classes.stickyContainer}>
      <Box sx={classes.headerMain}>
        <TeamplanDatePicker
          value={datePickerDate}
          onChange={(date) => setDatePickerDate(date)}
          onAccept={(date) => goToDay(date)}
          dataTestId="teamplan-datepicker"
        />
      </Box>
    </Box>
  )
}

export default DateHeader
