import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const PersonM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12 13c4.852 0 8.808 3.413 8.993 7.686L21 21H3c0-4.418 4.03-8 9-8zm0 2c-2.968 0-5.46 1.576-6.49 3.758L5.401 19h13.195l-.016-.042c-.945-2.224-3.39-3.865-6.328-3.954L12 15zm0-12c2.485 0 4.5 2.015 4.5 4.5S14.485 12 12 12 7.5 9.985 7.5 7.5 9.515 3 12 3zm0 2c-1.38 0-2.5 1.12-2.5 2.5S10.62 10 12 10s2.5-1.12 2.5-2.5S13.38 5 12 5z"
      />
    </Svg>
  )
}

export default PersonM
