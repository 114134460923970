import theme from 'utils/theme'

export const classes = {
  select: {
    color: theme.palette.common.white,
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none',
    },
    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
  },
}
