import React from 'react'
import { SxProps } from '@mui/material'
import Svg from 'components/Svg'
import theme from 'utils/theme'

const UserIdentificationPin: React.FC<{ sx: SxProps }> = ({ sx }) => {
  return (
    // @ts-ignore
    <Svg size={100} fill={theme.palette.primary[100]} sx={sx}>
      <path
        fillRule="evenodd"
        d="M20 64.5a80.7 80.7 0 0 0 23.9 28.3l.6.5 1-.7C47.7 91 50 89 52.2 87l.8-.8 3.5 4a85.8 85.8 0 0 1-8 6.8l-1 .7-3 2.3-3.2-2.3a86.4 86.4 0 0 1-27-32.3l-.3-.8H20zM75.5 44c2.5 0 4.5 2 4.5 4.5v21a.5.5 0 0 0 1 0V69c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v.5a.5.5 0 0 0 1 0V69c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v.5a.5.5 0 0 0 1 0V69c0-.6.4-1 1-1h3c.5 0 1 .4 1 .9v.1l-.1 26.2a2 2 0 0 1-2 2h-27a4 4 0 0 1-3.3-1.9l-11-17.5a4 4 0 0 1 1.6-5.8 5.2 5.2 0 0 1 6.6 1.8l5.5 8.5.7-.3V48.5c0-2.5 2-4.5 4.5-4.5zM89 33v26h-7V47.5a6.5 6.5 0 0 0-13-.2V59H0V33h89zm-74 5.3h-2.3v5.5l-5-2.6-1 2 4.7 2.5-4.8 2.5 1.1 2 5-2.5v5.5h2.4v-5.5l5 2.6 1-2-4.7-2.6 4.8-2.4-1.1-2.1-5 2.6v-5.5zm20 0h-2.4v5.5l-5-2.6-1 2 4.7 2.5-4.8 2.5 1.1 2 5-2.5v5.5H35v-5.5l5 2.6 1-2-4.7-2.6 4.8-2.4-1-2.1-5.1 2.6v-5.5zm19.9 0h-2.4v5.5l-5-2.6-1 2 4.7 2.5-4.8 2.5 1.1 2 5-2.5v5.5H55v-5.5l5 2.6 1.1-2-4.7-2.6 4.7-2.4-1-2.1-5.1 2.6v-5.5zM44.5 0L83 17v10h-5v-7L44.5 5.8 11 20l.2 7H6V17L44.5 0z"
      />
    </Svg>
  )
}

export default UserIdentificationPin
