import theme from 'utils/theme'

export const classes = {
  clickable: {
    cursor: 'pointer',
  },
  titleAdornment: {
    paddingLeft: theme.spacing(0.125),
  },
  selectAdornment: {
    paddingRight: theme.spacing(1.5),
  },
}
