import { useForm } from 'react-hook-form'
import { favurDateToString } from 'utils/date'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export type Inputs = {
  firstName: string
  lastName: string
  birthDate: string
  termsAndConditions?: boolean
}

const schema = yup.object().shape({
  firstName: yup.string().required('page.userSettings.personalData.firstNameError.required'),
  lastName: yup.string().required('page.userSettings.personalData.lastNameError.required'),
  birthDate: yup
    .string()
    .required('page.userSettings.personalData.birthDateError.required')
    .matches(/^\d{1,2}\.\d{1,2}\.\d{4}$/, 'page.userSettings.personalData.birthDateError.invalid'),

  termsAndConditions: yup.bool().oneOf([true], 'page.userSettings.personalData.termsAndConditions.required'),
})

const useUserDataForm = ({ defaultValues, onSubmit }: { defaultValues?: Inputs; onSubmit: (data: Inputs) => void }) => {
  const form = useForm<Inputs>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const { handleSubmit } = form

  return {
    ...form,
    onSubmit: handleSubmit((data) => {
      onSubmit({ ...data, birthDate: favurDateToString(data.birthDate) })
    }),
  }
}

export default useUserDataForm
