import React, { useState } from 'react'
import { MenuItem, Select } from '@mui/material'
import { ArrowDownS } from 'icons'
import { TenantT } from 'types'
import { classes } from './styles'

interface ITenantsListProps {
  tenants: TenantT[]
  onChange: (tenantid: string) => void
}

const TenantsList: React.FC<ITenantsListProps> = ({ tenants, onChange }) => {
  const [selectedTenant, setSelectedTenant] = useState(tenants.length > 0 ? tenants[0].id : '')

  return (
    <Select
      variant="standard"
      value={selectedTenant}
      sx={classes.select}
      IconComponent={() => <ArrowDownS />}
      onChange={(e) => {
        const tenantId = e.target.value as string
        setSelectedTenant(tenantId)
        onChange(tenantId)
      }}
      data-testid="tenant-list"
    >
      {tenants.map((tenant) => (
        <MenuItem key={`tenant_${tenant.id}`} value={tenant.id} data-testid="tenant-list-option">
          {tenant.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default TenantsList
