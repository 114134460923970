import React from 'react'
import Avatar from 'components/Avatar'
import { HrAvatarT } from 'components/Timeline/types'
import { TeamProfilePicL } from 'icons'
import { classes } from './styles'

const HrAvatar: React.FC<{ avatar: HrAvatarT }> = ({ avatar }) => {
  return (
    <Avatar sx={classes.hrAvatar} avatar={{ ...avatar, avatarName: avatar.userName }}>
      <TeamProfilePicL />
    </Avatar>
  )
}

export default HrAvatar
