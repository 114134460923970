import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const DataProtectionL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path d="M15.1 4.2c.5-.2 1-.3 1.6-.1h.2L26 8c.8.4 1.4 1.2 1.4 2.1a19 19 0 01-10.6 17.8c-.6.3-1.2.3-1.8 0C10 25.8 4.5 19.3 4.5 10c0-.9.5-1.6 1.3-2h.1l9.2-3.8zM16 8h-.2a4 4 0 00-3.8 3.8V14h-1a1 1 0 00-1 .9V21c0 .5.4 1 .9 1H21c.5 0 1-.4 1-.9V15c0-.5-.4-1-.9-1H20v-2.2A4 4 0 0016.2 8H16zm0 8a1 1 0 01.7 1.7L17 20h-2l.4-2.2-.1-.1A1 1 0 0116 16zm0-6h.1c1 0 1.8.9 1.9 1.8V14h-4v-2.1a2 2 0 011.8-1.9h.2z" />
    </Svg>
  )
}

export default DataProtectionL
