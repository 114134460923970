import React from 'react'
import { SvgIconProps } from '@mui/material'
import Svg from 'components/Svg'

const Checkmark: React.FC<SvgIconProps> = (props) => {
  return (
    <Svg viewBox="0 0 80 80" size={80} role="img" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M71.554 12.57l5.005 4.894c1.185 1.158 1.206 3.057.048 4.242l-44.73 45.747c-.773.79-2.04.804-2.83.032l-.015-.016L2.586 41.023c-.781-.78-.781-2.047 0-2.828l5.657-5.657c.78-.781 2.047-.781 2.828 0l19.294 19.294 38.36-39.23c.773-.79 2.04-.804 2.83-.032z"
      />
    </Svg>
  )
}

export default Checkmark
