import React from 'react'
import { SxProps, Theme } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TFunction } from 'i18next'
import DeleteWorkflow from 'pages/Tasks/components/ActionButtons/DeleteWorkflow'
import Plus from 'pages/Tasks/components/ActionButtons/Plus'
import SendComment from 'pages/Tasks/components/ActionButtons/SendComment'
import SendReminder from 'pages/Tasks/components/ActionButtons/SendReminder'
import { taskStates } from 'pages/Tasks/constants'
import { AbsenceRequestTaskT, EventsOfTask, TaskT } from 'pages/Tasks/types'
import { absenceRequestStatus } from '../../constants'
import Accept from './Accept'
import CheckEmployeeSaldo from './CheckEmployeeSaldo'
import ConfirmDeletion from './ConfirmDeletion'
import Reject from './Reject'
import RejectDeletion from './RejectDeletion'
import RequestDeletion from './RequestDeletion'
import ShowAbsencePlan from './ShowAbsencePlan'

interface IActionButtonsProps {
  task: TaskT<AbsenceRequestTaskT>
  isOffice: boolean
  onAction: () => void
  fullScreen?: boolean
  drawerSx?: SxProps<Theme>
  onDelete?: () => void
  hasOverflow?: boolean
  isAtBottom?: boolean
  showAbsencePlanButton?: boolean
}

type IActionButtonsPropsWithT = IActionButtonsProps & { t: TFunction }

const dataTestIdPlus = 'absence-request_action-plus'

const getButtonsFromStateFrontline = ({
  task,
  onAction,
  fullScreen,
  drawerSx,
  onDelete,
  hasOverflow,
  isAtBottom,
  showAbsencePlanButton,
  t,
}: IActionButtonsPropsWithT) => {
  const { favurUuid: taskUuid } = task
  const taskHasTimeline = Boolean(task.timeline?.length)
  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <Plus dataTestId={dataTestIdPlus} drawerSx={drawerSx}>
          {taskHasTimeline && (
            <>
              <SendReminder
                taskUuid={taskUuid}
                onAction={onAction}
                label={t('absenceRequest.buttons.sendReminder')}
                dataTestId="absence-request_action-send_reminder"
              />
              {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
              <SendComment
                taskUuid={taskUuid}
                event={EventsOfTask.absenceRequestSendCommentToManager}
                onAction={onAction}
                drawerSx={drawerSx}
              />
            </>
          )}
          {task.status !== absenceRequestStatus.deletionRequested && (
            <DeleteWorkflow task={task} onAction={onDelete} label={t('absenceRequest.buttons.deleteWorkflow')} />
          )}
        </Plus>
      </ActionCardButtons>
    )
  }
  if (task.status === absenceRequestStatus.approvedOffice) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <RequestDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
      </ActionCardButtons>
    )
  }
  return <></>
}

const getButtonsFromStateOffice = ({
  task,
  onAction,
  fullScreen,
  drawerSx,
  hasOverflow,
  isAtBottom,
  showAbsencePlanButton,
}: IActionButtonsProps) => {
  const { favurUuid: taskUuid } = task
  const taskHasTimeline = Boolean(task.timeline?.length)
  if (task.status === absenceRequestStatus.deletionRequested) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <ConfirmDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
        <Plus dataTestId={dataTestIdPlus} drawerSx={drawerSx}>
          <RejectDeletion task={task} onAction={onAction} drawerSx={drawerSx} />
          {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
          <SendComment
            taskUuid={taskUuid}
            event={EventsOfTask.absenceRequestSendCommentToFrontline}
            onAction={onAction}
            drawerSx={drawerSx}
          />
        </Plus>
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons fullScreen={fullScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom}>
        <Accept />
        <Plus dataTestId={dataTestIdPlus} drawerSx={drawerSx}>
          <Reject task={task} onAction={onAction} drawerSx={drawerSx} />
          {showAbsencePlanButton && <ShowAbsencePlan task={task} />}
          <CheckEmployeeSaldo task={task} drawerSx={drawerSx} />
          {taskHasTimeline && (
            <SendComment
              taskUuid={taskUuid}
              event={EventsOfTask.absenceRequestSendCommentToFrontline}
              onAction={onAction}
              drawerSx={drawerSx}
            />
          )}
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromTaskAndView = (props: IActionButtonsPropsWithT) => {
  const { isOffice, task } = props
  if (isOffice) {
    if (task.statusFe === taskStates.closed) {
      return <></>
    }
    return getButtonsFromStateOffice(props)
  }

  return getButtonsFromStateFrontline(props)
}

const ActionButtons: React.FC<IActionButtonsProps> = (props) => {
  const { t } = useFavurTranslation()

  return <>{getButtonsFromTaskAndView({ ...props, t })}</>
}

export default ActionButtons
