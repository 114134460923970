import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import ActionButtons from 'components/Buttons/ActionButtons'
import EmployeeList from 'components/EmployeeList'
import useFilterContext from 'components/Filter/context'
import { PersonsT } from 'components/Filter/types'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortBy } from 'lodash/fp'
import { getSortingLastName } from 'utils/person'
import { classes } from '../../styles'

interface IPersonListProps {
  option: PersonsT
}

const PersonsList: React.FC<IPersonListProps> = ({ option }) => {
  const { t } = useFavurTranslation()
  const { cancelPersonsList, confirmPersonsList, getValue } = useFilterContext()
  const [currentSelectedPersons, setCurrentSelectedPersons] = useState<number[]>([])

  useEffect(() => {
    const selectedOptions = getValue(option.name)
    if (selectedOptions !== null) {
      setCurrentSelectedPersons(selectedOptions as number[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      header={
        <WithBackwardLink
          title={t('page.teams.ownerMembers')}
          topTitle={t('page.tasks.page.title')}
          onClick={() => cancelPersonsList(option.name)}
        />
      }
      hideNativeNavigation
      hasFloatingButtons
    >
      <Box sx={classes.filterContainer}>
        <EmployeeList
          employees={sortBy((p) => getSortingLastName(p), option.options)}
          checkbox
          itemIsChecked={(person) => currentSelectedPersons.includes(person.id as number)}
          onClickItem={(person) => {
            if (currentSelectedPersons.includes(person.id as number)) {
              setCurrentSelectedPersons(currentSelectedPersons.filter((pId) => pId !== person.id))
            } else {
              setCurrentSelectedPersons([...currentSelectedPersons, person.id as number])
            }
          }}
          search
        />

        <ActionButtons
          cancelAction={() => cancelPersonsList(option.name)}
          cancelLabel={t('filter.persons.list.cancel')}
          validateLabel={t('filter.persons.list.confirm')}
          validateAction={() => confirmPersonsList(option.name, currentSelectedPersons)}
          isValidateButtonActive={currentSelectedPersons.length > 0}
          isFloating
          noNavigation
        />
      </Box>
    </Page>
  )
}

export default PersonsList
