import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Cropper from 'react-easy-crop'
import useCropImage from '../../hooks/useCropImage'
import { classes } from './styles'

export interface ICropImageProps {
  base64Image: string
  onCancel: () => void
}

const CropImage: React.FC<ICropImageProps> = ({ base64Image, onCancel }) => {
  const { t } = useFavurTranslation()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const { links, imageSize, uploadingAvatars, onCropComplete, submitImage } = useCropImage({ base64Image, onCancel })

  return (
    <Page hideNativeNavigation hideWebToolbar hideBackground hideHamburger noPadding removeLastMargin>
      <Box sx={classes.mainContainer}>
        {!links || !imageSize ? (
          <Box sx={classes.loadingContainer}>
            <SimpleLoading />
          </Box>
        ) : (
          <>
            {uploadingAvatars && (
              <Box sx={classes.loadingContainer}>
                <SimpleLoading />
              </Box>
            )}
            <Typography variant="h2" sx={classes.title}>
              {t('page.userSettings.profile.settingsSection.profileImage.crop')}
            </Typography>
            <Box sx={classes.cropperContainer(base64Image)}>
              <Cropper
                image={base64Image}
                crop={crop}
                zoom={zoom}
                cropShape="round"
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                showGrid={false}
                style={{
                  containerStyle: classes.cropContainer(imageSize),
                }}
              />
            </Box>
            <Box sx={classes.buttonContainer}>
              <Button variant="text" onClick={onCancel} sx={classes.buttonCancel} disabled={uploadingAvatars}>
                {t('common.cancel')}
              </Button>
              <Button
                variant="text"
                onClick={submitImage}
                sx={classes.buttonSave}
                disabled={uploadingAvatars}
                data-testid="user-settings-save-profile-picture"
              >
                {t('page.userSettings.profile.settingsSection.profileImage.save')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Page>
  )
}

export default CropImage
