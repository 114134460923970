import React from 'react'
import { Box } from '@mui/material'
import { ValidationStatusT } from 'pages/EmployeeCard/types'
import { classes } from './styles'

interface IDividerProps {
  status: ValidationStatusT
}

const ValidationDivider: React.FC<IDividerProps> = ({ status }) => {
  return (
    <Box
      sx={[
        classes.divider,
        ...(status === 'active' ? [classes.dividerActive] : []),
        ...(status === 'notValidated' ? [classes.dividerNotValidated] : []),
        ...(status === 'inactive' ? [classes.dividerInactive] : []),
        ...(status === 'noInfo' ? [classes.dividerNoInfo] : []),
      ]}
    />
  )
}
export default ValidationDivider
