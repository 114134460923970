import { createContext, useContext, Context, Dispatch, SetStateAction } from 'react'
import type { TeamPlanTeamDataT } from './types'

export type TeamplanDetailContextT = {
  selectedTeamData: TeamPlanTeamDataT | undefined
  setSelectedTeamData: Dispatch<SetStateAction<TeamPlanTeamDataT | undefined>>
}

const initialState: TeamplanDetailContextT = {
  selectedTeamData: undefined,
  setSelectedTeamData: () => {},
}

export const TeamplanDetailContext: Context<TeamplanDetailContextT> = createContext(initialState)

export const useTeamplanDetailContext = () => {
  const context = useContext(TeamplanDetailContext)

  return { ...context }
}

export default useTeamplanDetailContext
