import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const CalendarShiftS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M11 9H5V8h6v1zm-3 2H5v-1h3v1zm4.5 3h-9a1 1 0 0 1-1-1V4.5a1 1 0 0 1 1-1H5V2h1.5v1.5h3V2H11v1.5h1.5a1 1 0 0 1 1 1V13a1 1 0 0 1-1 1zm-.5-2V6.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5V12a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5z"
      />
    </Svg>
  )
}

export default CalendarShiftS
