import theme from 'utils/theme'

export const classes = {
  layout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '20px',
    paddingTop: '14px',
  },
  text: {
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.3,
  },
  checkBox: {
    '& .MuiIconButton-label': {
      // @ts-ignore
      color: theme.palette.primary[300],
      // @ts-ignore
      backgroundColor: theme.palette.primary[700],
      // @ts-ignore
      border: `2px solid ${theme.palette.primary[100]}`,
      borderRadius: '3px',
      height: 14,
      width: 14,
    },
  },
}
