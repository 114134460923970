import { FF_PACKAGE } from 'constants/featureFlags'
import { PACKAGES } from 'constants/packages'
import useSessionContext from 'hooks/useSessionContext'
import type { PackageBackendT, PackageUtilT } from 'types'
import useFeatureFlag from './useFeatureFlag'

const isPackageFFValid = ({ packageFF }: Pick<PackageUtilT, 'packageFF'>): boolean =>
  packageFF === PACKAGES.basic ||
  packageFF === PACKAGES.classic ||
  packageFF === PACKAGES.betaWorkflowAbsencesClassic ||
  packageFF === PACKAGES.classicLight ||
  packageFF === PACKAGES.favurPublisher

const checkHasClassicAccess = ({ packageFF, personPackages }: PackageUtilT): boolean => {
  if (isPackageFFValid({ packageFF })) {
    return (
      packageFF === PACKAGES.classic ||
      packageFF === PACKAGES.betaWorkflowAbsencesClassic ||
      packageFF === PACKAGES.favurPublisher
    )
  }

  if (personPackages === undefined) {
    return true
  }

  return (
    Object.values(personPackages).includes(PACKAGES.classic) ||
    Object.values(personPackages).includes(PACKAGES.betaWorkflowAbsencesClassic) ||
    Object.values(personPackages).includes(PACKAGES.development) ||
    Object.values(personPackages).includes(PACKAGES.favurPublisher)
  )
}

const checkHasClassicLightAccess = ({ packageFF, personPackages }: PackageUtilT): boolean => {
  if (isPackageFFValid({ packageFF })) {
    return (
      packageFF === PACKAGES.classic ||
      packageFF === PACKAGES.classicLight ||
      packageFF === PACKAGES.betaWorkflowAbsencesClassic ||
      packageFF === PACKAGES.favurPublisher
    )
  }

  if (personPackages === undefined) {
    return true
  }

  return (
    Object.values(personPackages).includes(PACKAGES.classic) ||
    Object.values(personPackages).includes(PACKAGES.classicLight) ||
    Object.values(personPackages).includes(PACKAGES.betaWorkflowAbsencesClassic) ||
    Object.values(personPackages).includes(PACKAGES.development) ||
    Object.values(personPackages).includes(PACKAGES.favurPublisher)
  )
}

const checkHasAbsenceBetaWorkflow = ({ packageFF, personPackages }: PackageUtilT): boolean => {
  if (isPackageFFValid({ packageFF })) {
    return packageFF === PACKAGES.betaWorkflowAbsencesClassic
  }

  if (personPackages === undefined) {
    return false
  }

  return (
    Object.values(personPackages).includes(PACKAGES.betaWorkflowAbsencesClassic) ||
    Object.values(personPackages).includes(PACKAGES.development)
  )
}

const getClassicPersonIds = ({ personPackages }: Pick<PackageUtilT, 'personPackages'>): string[] =>
  personPackages === undefined
    ? []
    : Object.entries(personPackages)
        .map((personPackage) =>
          [
            PACKAGES.classic,
            PACKAGES.classicLight,
            PACKAGES.betaWorkflowAbsencesClassic,
            PACKAGES.development,
            PACKAGES.favurPublisher,
          ].includes(personPackage[1])
            ? personPackage[0]
            : '',
        )
        .filter((id) => id)

const getAbsenceBetaWorkflowPersonIds = ({ personPackages }: Pick<PackageUtilT, 'personPackages'>): string[] =>
  personPackages === undefined
    ? []
    : Object.entries(personPackages)
        .map((personPackage) => {
          if ([PACKAGES.betaWorkflowAbsencesClassic, PACKAGES.development].includes(personPackage[1])) {
            return personPackage[0]
          }
          return ''
        })
        .filter((id) => id)

const usePackage = () => {
  const { personPackages } = useSessionContext()
  const packageFF = useFeatureFlag(FF_PACKAGE)

  const hasClassicAccess = checkHasClassicAccess({ packageFF, personPackages })
  const hasClassicLightAccess = checkHasClassicLightAccess({ packageFF, personPackages })
  const hasAbsenceBetaWorkflow = checkHasAbsenceBetaWorkflow({ packageFF, personPackages })

  const classicPersonIds = getClassicPersonIds({ personPackages })
  const absenceBetaWorkflowPersonIds = getAbsenceBetaWorkflowPersonIds({ personPackages })
  const checkPersonHasPackage = (personId: number | undefined, packageName: PackageBackendT) => {
    if (!personPackages || !personId) return false
    if (!Object.keys(personPackages).includes(`${personId}`)) return false

    return personPackages[personId] === packageName
  }

  return {
    absenceBetaWorkflowPersonIds,
    checkHasClassicAccess,
    checkPersonHasPackage,
    classicPersonIds,
    hasAbsenceBetaWorkflow,
    hasClassicAccess,
    hasClassicLightAccess,
  }
}

export default usePackage
