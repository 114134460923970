import { createContext, Dispatch, SetStateAction, Context } from 'react'

export interface IShiftsState {
  start: string
  end: string
}

export interface IShiftsContext {
  start: string
  end: string
  setShiftsContext: Dispatch<SetStateAction<IShiftsState>>
}

const initialState: IShiftsContext = {
  start: '',
  end: '',
  setShiftsContext: () => {},
}

const ShiftsContext: Context<IShiftsContext> = createContext(initialState)

export default ShiftsContext
