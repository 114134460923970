import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const ArrowRightDoubleS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6416 13.8486C7.83411 14.0466 8.15067 14.051 8.34864 13.8585L11.0433 11.2381L13.7379 8.61782C13.8199 8.53809 13.8861 8.44242 13.9313 8.33636C13.9766 8.23017 14.0001 8.11582 14.0001 8C14.0001 7.88418 13.9766 7.76983 13.9313 7.66364C13.8861 7.55758 13.8202 7.46216 13.7382 7.38243L8.34864 2.14154C8.15067 1.94903 7.83411 1.95345 7.6416 2.15143C7.44909 2.3494 7.45351 2.66595 7.65149 2.85846L12.9388 8L7.65149 13.1415C7.45351 13.3341 7.44909 13.6506 7.6416 13.8486Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6416 13.8486C2.83411 14.0466 3.15067 14.051 3.34864 13.8585L6.04327 11.2381L8.7379 8.61782C8.81994 8.53809 8.88606 8.44242 8.93128 8.33636C8.97656 8.23017 9.00006 8.11582 9.00006 8C9.00006 7.88418 8.97656 7.76983 8.93128 7.66364C8.88606 7.55758 8.8202 7.46216 8.73816 7.38243L3.34864 2.14154C3.15067 1.94903 2.83411 1.95345 2.6416 2.15143C2.44909 2.3494 2.45351 2.66595 2.65149 2.85846L7.93884 8L2.65149 13.1415C2.45351 13.3341 2.44909 13.6506 2.6416 13.8486Z"
      />
    </Svg>
  )
}

export default ArrowRightDoubleS
