import React, { PropsWithChildren } from 'react'
import { Badge, BadgeOrigin } from '@mui/material'

interface IAvatarBadgeProps {
  badge?: React.ReactNode
  anchorOrigin?: BadgeOrigin
}

const AvatarBadge: React.FC<PropsWithChildren<IAvatarBadgeProps>> = ({
  badge = undefined,
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
}) => {
  return badge ? (
    <Badge overlap="circular" anchorOrigin={anchorOrigin} badgeContent={badge}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  )
}

export default AvatarBadge
