import React from 'react'

export interface ITabContentProps {
  children?: React.ReactNode
  index: number
  currentTabIndex: number
}

const TabContent: React.FC<ITabContentProps> = ({ children, index, currentTabIndex }) => {
  return index === currentTabIndex ? <>{children}</> : null
}

export default TabContent
