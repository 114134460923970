import React from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import NewAbsenceRequest from '.'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import { absenceRequestListClasses as classes } from '../styles'

interface INewAbsenceRequestPageProps {
  refetchAll: () => void
}

const NewAbsenceRequestPage: React.FC<INewAbsenceRequestPageProps> = ({ refetchAll }) => {
  const { t } = useFavurTranslation()
  const setTaskBarState = useTaskBarStateDispatchContext()

  return (
    <Box sx={classes.page}>
      <SmallScreenPageHeader title={t('absencePlanner.taskbar.newAbsenceRequest.header')} />
      <NewAbsenceRequest
        goBack={() => {
          setTaskBarState(null)
        }}
        refetchAll={refetchAll}
        subtitle=""
        title=""
        hideHeader
        drawerFullScreen
      />
    </Box>
  )
}

export default NewAbsenceRequestPage
