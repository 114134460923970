import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import SendComment from '../SendComment'
import RequestChangeHeader from '../SendComment/RequestChangeHeader'

interface IRequestChangeProps {
  taskUuid: string
  onAction: () => void
}

const RequestChange: React.FC<IRequestChangeProps> = ({ taskUuid, onAction }) => {
  const { t } = useFavurTranslation()

  const [openDrawer, setOpenDrawer] = useState(false)
  const onCompleted = useCallback(() => {
    setOpenDrawer(false)
    onAction()
  }, [onAction])

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => setOpenDrawer(true)}
        data-testid="monthly-sheet-detail_claim"
      >
        {t('workTimeControl.buttons.requestChange')}
      </Button>
      <SendComment
        isOpen={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        taskUuid={taskUuid}
        eventCompleted={onCompleted}
        eventOnSuccess={EventsOfTask.monthlySheetSendCommentToManager}
        required
      >
        <RequestChangeHeader />
      </SendComment>
    </>
  )
}

export default RequestChange
