import React, { useState } from 'react'
import { Typography } from '@mui/material'
import TextInputDrawer from 'components/TextInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TenantsM } from 'icons'
import UserSettingsSection from 'pages/UserSettings/components/Section'
import UserSettingsSectionEditCTA from 'pages/UserSettings/components/SectionEditCTA'
import { TenantT } from 'types'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IBasicInfoProps {
  tenant: TenantT
  onChangeTenantName: (newName: string) => void
  loadingMutations: boolean
}

const BasicInfo: React.FC<IBasicInfoProps> = ({ tenant, onChangeTenantName, loadingMutations }) => {
  const { t } = useFavurTranslation()
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <UserSettingsSection title={t('page.userSettings.tenant.basicInfo')}>
      <UserSettingsSectionEditCTA
        icon={<TenantsM fill={palette.primary.main} />}
        title={t('page.userSettings.tenant.tenantName')}
        subtitle={tenant.name}
        onClick={() => {
          setDrawerOpen(true)
        }}
      />
      <TextInputDrawer
        isOpen={drawerOpen}
        onCancel={() => {
          setDrawerOpen(false)
        }}
        onSuccess={(newName: string) => {
          onChangeTenantName(newName)
          setDrawerOpen(false)
        }}
        successButtonText={t('personalData.button.save')}
        textFieldLabel={t('page.userSettings.tenant.tenantName')}
        defaultValue={tenant.name}
        successButtonDisabled={loadingMutations}
        paperSx={classes.drawerPaper}
      >
        <Typography variant="subtitle1" sx={classes.drawerTitle}>
          {t('page.userSettings.tenant.editTenantName')}
        </Typography>
      </TextInputDrawer>
    </UserSettingsSection>
  )
}

export default BasicInfo
