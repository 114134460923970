import React, { useCallback, useMemo } from 'react'
import { Typography, CardActionArea, Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { getTeamplanPersonsName } from 'components/ShiftsList/utils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import { ArrowRightThinS } from 'icons'
import { useTeamplanDetailContext } from 'pages/Shifts/TeamplanDetailPage/context'
import { getTitleFromTeamplanPersons } from 'pages/Shifts/utils'
import { TeamplanPersonsT } from 'types/teamplan'
import theme from 'utils/theme'
import { IonContent } from '@ionic/core/components/ion-content'
import { classes } from '../styles'
import TeamAvatars from './TeamAvatars'
import { TeamMemberT } from './types'
import { orderAttendances, orderAbsences } from './utils'

interface ITeamProps {
  teamplanPersons: TeamplanPersonsT
  attendances: TeamMemberT[]
  vacationAbsences: TeamMemberT[]
  showTopSeparator?: boolean
  reducedMargin?: boolean
}

const TeamBlock: React.FC<ITeamProps> = ({
  teamplanPersons,
  attendances,
  vacationAbsences,
  showTopSeparator = true,
  reducedMargin,
}) => {
  const { t, language } = useFavurTranslation()
  const { persons } = useSessionContext()
  const { setSelectedTeamData } = useTeamplanDetailContext()

  const showTeamplanCostCenters = useMemo(
    () => teamplanPersons.type === 'team' && teamplanPersons.team?.showTeamplanCostCenters,
    [teamplanPersons],
  )

  const personIds = useMemo(() => persons?.map((person) => `${person.id}`), [persons])

  const emptyOrOnlyMeInMembers = useCallback(
    (teamMemberList: TeamMemberT[]) =>
      teamMemberList.length === 0 ||
      (personIds && teamMemberList.length === 1 && personIds.includes(teamMemberList[0].personId)),

    [personIds],
  )

  const onlyVacations = useMemo(() => {
    return emptyOrOnlyMeInMembers(attendances) && !emptyOrOnlyMeInMembers(vacationAbsences)
  }, [attendances, emptyOrOnlyMeInMembers, vacationAbsences])

  const teamTitle = useMemo(() => {
    const teamplanPersonsName = getTeamplanPersonsName(teamplanPersons, language)
    return onlyVacations
      ? t('component.shiftCard.teams.title.vacations', { teamName: teamplanPersonsName })
      : t('component.shiftCard.teams.title.attendances', { teamName: teamplanPersonsName })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, onlyVacations, t, teamplanPersons.id])

  if (emptyOrOnlyMeInMembers(attendances) && emptyOrOnlyMeInMembers(vacationAbsences)) {
    return null
  }

  return (
    <Box sx={showTopSeparator ? (reducedMargin ? classes.reducedMargin : classes.fullMargin) : undefined}>
      {showTopSeparator && <DivisionLine />}
      <CardActionArea
        key={`attendant_${teamplanPersons.id}`}
        onClick={() => {
          setSelectedTeamData({
            id: teamplanPersons.id,
            tenantId: teamplanPersons.tenantId,
            headerTopTitle: getTitleFromTeamplanPersons(teamplanPersons),
            headerTitle: getTeamplanPersonsName(teamplanPersons, language),
            showTeamplanCostCenters: showTeamplanCostCenters,
          })
          const ionContent = document.getElementById('ionContent') as IonContent
          if (ionContent) {
            ionContent.scrollToTop()
          }
        }}
        data-testid="teamplan__button--attendant"
        disableRipple
      >
        <Box sx={classes.teamHeader}>
          <Typography sx={classes.teamHeaderTitle} variant="subtitle2">
            {teamTitle}
          </Typography>
          <Box sx={classes.teamHeaderArrow}>
            <ArrowRightThinS fill={theme.palette.primary.main} />
          </Box>
        </Box>
        {!emptyOrOnlyMeInMembers(attendances) && (
          <TeamAvatars avatars={orderAttendances(attendances)} max={20} showShifts />
        )}
        {!emptyOrOnlyMeInMembers(vacationAbsences) && (
          <Box sx={[classes.vacationsSectionTitleContainer, ...(onlyVacations ? [classes.vacationsTitleMargin] : [])]}>
            {!onlyVacations && (
              <Typography sx={classes.vacationsSectionTitle} variant="body2">
                {t('component.shiftCard.teams.vacationsSection.title')}
              </Typography>
            )}

            <TeamAvatars avatars={orderAbsences(vacationAbsences)} max={10} />
          </Box>
        )}
      </CardActionArea>
    </Box>
  )
}

export default TeamBlock
