import React from 'react'
import AbsenceBadge from './Absence'
import CmsEventBadge from './CmsEvent'
import HiddenBadge from './Hidden'
import RequestBadge from './Request'
import ShiftBadge from './Shift'
import { IBadgeProps } from './types'

const Badge: React.FC<IBadgeProps> = (props) => {
  const { badge } = props

  if (badge.shiftUpdateUuid) {
    return <HiddenBadge {...props} />
  }

  switch (badge.type) {
    case 'absence':
      return <AbsenceBadge {...props} />
    case 'shift':
      return <ShiftBadge {...props} />
    case 'absenceRequest':
      return <RequestBadge {...props} />
    case 'cmsEvent':
      return <CmsEventBadge {...props} />
    default:
      return <></>
  }
}

export default Badge
