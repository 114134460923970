import theme from 'utils/theme'
import favurTheme from 'utils/theme'

export const confirmClasses = {
  tenantNameTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  tenantNameTitleText: {
    paddingLeft: '9px',
  },
  homeIcon: {
    color: theme.palette.primary.main,
    height: '16px',
  },
  flexAndColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  toDocumentsButton: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
    letterSpacing: '0.9px',
    alignSelf: 'center',
    paddingBottom: theme.spacing(1.0),
  },
  deactivateButton: {
    alignSelf: 'center',
    paddingTop: '12px',
  },
  confirmationTextTop: {
    paddingTop: theme.spacing(0.5),
  },
  disabledColor: {
    color: theme.palette.grey[300],
  },
  subtitle: {
    marginTop: '4px',
  },
  documentTypesList: {
    marginBottom: '10px',
  },
  dialog: {
    padding: favurTheme.spacing(1.0),
  },
  dialogText: {
    whiteSpace: 'pre-wrap',
  },
  dialogButtons: {
    paddingTop: '8px',
  },
}
