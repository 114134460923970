import theme from 'utils/theme'

export const classes = {
  checkBoxContainer: {
    paddingTop: theme.spacing(1.0),
    paddingLeft: '0px',
  },
  checkListOption: {
    fontWeight: 350,
    paddingLeft: '8px',
  },
  checkBox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    '&:hover': { background: 'none' },
    fontWeight: 350,
    lineHeight: '20px',
  },
  checkboxStatus: {
    position: 'relative',
    left: '40px',
  },
  tenantListSection: {
    marginTop: theme.spacing(1.5),
  },
  bodyText1: {
    fontWeight: 350,
    lineHeight: '24px',
  },
  tenantHeaderText: {
    fontSize: theme.spacing(1.0),
    fontWeight: 500,
    lineHeight: '20px',
  },
  tenantStatusText: {
    fontWeight: 350,
    lineHeight: '15px',
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: '10px',
  },
  confirmShareWithText: {
    marginTop: theme.spacing(1.0),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  confirmTenantList: {
    marginTop: '4px',
  },
  confirmTenantText: {
    marginTop: '4px',
    fontWeight: 350,
    lineHeight: '22px',
  },
  confirmButtonsBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
  confirmBodyText: {
    fontWeight: 350,
    lineHeight: '22px',
  },
  successContainer: {
    marginTop: '60px',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    width: '296px',
    textAlign: 'center',
  },
  successTitle: {
    marginTop: theme.spacing(2),
    marginBottom: '40px',
  },
  successSharedWithText: {
    fontWeight: 350,
    color: theme.palette.text.primary,
    fontSize: theme.spacing(1),
    lineHeight: '24px',
  },
  commentSection: {
    marginTop: '30px',
  },
  commentDescription: {
    position: 'relative',
    left: '12px',
  },
}
