import React, { useState } from 'react'
import BannerMessagesContext from './context'
import { IBannerMessage } from './types'

const BannerMessagesService = ({ children }: { children?: React.ReactNode }) => {
  const [bannerMessage, setBannerMessage] = useState<IBannerMessage | undefined>(undefined)

  return (
    <BannerMessagesContext.Provider value={{ setBannerMessage, bannerMessage }}>
      {children}
    </BannerMessagesContext.Provider>
  )
}

export default BannerMessagesService
