import React, { useCallback } from 'react'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import palette from 'utils/theme/palette'
import { styles } from '../styles'
import NewsletterList from './NewsletterList'

const NewsletterIndexPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const history = useHistory()

  const {
    params: { ownerId },
  } = match
  const owner = parseInt(ownerId)

  const gotoBackLink = useCallback(() => {
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history])

  return (
    <Page
      backgroundColor={palette.primary[15]}
      header={
        <WithBackwardLink
          onClick={gotoBackLink}
          title={t('page.cms.newsletter.header')}
          iconSx={styles.subHeaderIcon}
          iconThin
        />
      }
      sxHeader={styles.subHeader}
    >
      <NewsletterList ownerId={owner} />
    </Page>
  )
}

export default NewsletterIndexPage
