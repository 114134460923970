import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventL } from 'icons'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import palette from 'utils/theme/palette'
import { taskBarStates } from '../../types'
import TabButton from '../TabButton'

const EventsButton: React.FC = () => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()

  return (
    <TabButton tabOption={taskBarStates.events} toolTipText={t('absencePlanner.taskbar.toolTip.events')}>
      <EventL fill={taskBarState === taskBarStates.events ? palette.secondary.main : palette.primary[700]} />
    </TabButton>
  )
}

export default EventsButton
