import theme from 'utils/theme'

export const toolbarClasses = {
  menu: {
    boxSizing: 'border-box',
    maxWidth: theme.breakpoints.values.sm,
    margin: '0 auto',
    padding: theme.spacing(0, 0.75, 0.75, 0.75),
    color: theme.palette.common.white,
    width: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  scrollablePart: {
    flex: 1,
    overflow: 'auto',
  },
  main: {
    flex: '1 0 auto',
  },
  footer: {
    flex: '0 0 auto',
  },
  item: {
    padding: 0,
    margin: 0,
    height: theme.spacing(2),
    minHeight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.52),
  },
  menuItemActive: {
    color: theme.palette.secondary.main,
  },
  listSubItem: {
    height: theme.spacing(1.5),
    minHeight: theme.spacing(1.5),
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0.75, 0),
    marginLeft: theme.spacing(1.25),
  },
  dashboard: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.spacing(2.6875),
    minHeight: theme.spacing(2.6875),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.625),
    marginBottom: theme.spacing(1.1875),
  },
  icon: {
    width: theme.spacing(2),
    minWidth: theme.spacing(2),
    marginRight: theme.spacing(0.75),
  },
  mobileWeb: {
    paddingBottom: theme.spacing(3.5),
  },

  toolbar: {
    color: theme.palette.common.white,
    position: 'relative',
    zIndex: 4,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.dark,
  },
  content: {
    margin: '0 auto',
    maxWidth: theme.breakpoints.values.sm,
    padding: `9px ${theme.spacing(0.75)} 10px`,
    boxSizing: 'border-box',
    userSelect: 'none',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  navigation: {
    position: 'absolute',
    right: 0,
    left: 0,
    display: 'none',
    flex: 1,
    height: `calc(100vh - var(--ion-safe-area-bottom) - var(--ion-safe-area-top))`,
    zIndex: 4,
  },
  // Because of the absolute, we need to take into account the logo
  navigationWeb: {
    height: 'calc(100% - 37px)',
  },
  navigationVisible: {
    display: 'flex',
    flexDirection: 'column',
  },
  native: {
    padding: 0,
    minHeight: 'var(--ion-safe-area-top)',
  },
  bottomScrollRectangle: {
    position: 'absolute',
    width: '100%',
    height: '36px',
    background: 'linear-gradient(180deg, rgba(24, 51, 101, 0) 0%, rgba(255, 255, 255, 0.28) 100%)',
    // This takes into account size of tertiaryNavigation
    // 10px is part of paddingBottom with the calc formula
    // 12px is the paddingTop
    // 32px is the size of the logo
    // 12px is the paddingBottom of the menu container
    bottom: `calc(max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)}) + 10px + 12px + 32px + 12px)`,
  },
  closeButton: {
    borderTop: `1px solid ${theme.palette.primary.light}`,
    textAlign: 'end',
    minWidth: '55px',
    flex: 0,
  },
  closeIconButton: {
    marginTop: '12px',
    '&:hover, &:focus, &:hover:before': {
      backgroundColor: 'transparent',
    },
  },
}
