import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { SimpleLoading } from 'components/LoadingIcon'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TaskCenterL } from 'icons'
import { RoleViewT, TaskCountsT } from 'types'
import { classes } from './styles'

interface IDashboardTasksFrontliner {
  loading: boolean
  onClick: (role: RoleViewT) => void
  counts?: TaskCountsT
}

const DashboardTasksFrontliner: React.FC<IDashboardTasksFrontliner> = ({ loading, onClick, counts }) => {
  const { t } = useFavurTranslation()

  return (
    <Box
      sx={[classes.taskBox]}
      onClick={() => {
        onClick(ROLE_VIEWS.frontliner)
      }}
    >
      <Card>
        {loading || !counts ? (
          <SimpleLoading small smallContainer />
        ) : (
          <Box sx={classes.horizontalContainer}>
            <Box sx={classes.flexContainer}>
              <TaskCenterL />
              <Typography variant="h3">{counts.todo}</Typography>
              <Typography variant="body2">{t('page.dashboard.tasks.frontliner')}</Typography>
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  )
}

export default DashboardTasksFrontliner
