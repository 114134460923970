import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const NotificationsM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path d="M14.6251 18.375C14.6251 19.8247 13.4498 21 12.0001 21C10.5986 21 9.45372 19.9018 9.37895 18.519L9.37506 18.375H14.6251ZM12.0001 3C12.5667 3 13.0307 3.45082 13.0683 4.02112L13.0708 4.09615V4.81002C15.4857 5.32325 17.2879 7.38646 17.3527 9.98036L17.3545 10.125C17.3545 12.2016 17.7795 13.4233 18.2604 14.2221L18.3512 14.367L18.4427 14.5026L18.5573 14.6601L18.6713 14.8053L18.7612 14.9134L18.8929 15.0638L19.2117 15.4102C19.4126 15.6308 19.5016 15.8952 19.5001 16.1538C19.4968 16.6922 19.1006 17.2054 18.5041 17.2473L18.4252 17.25H5.57491C4.93474 17.25 4.50383 16.7156 4.50002 16.1538C4.49848 15.8952 4.58754 15.6311 4.78843 15.4102L5.10726 15.0638L5.23889 14.9134L5.32883 14.8053L5.4199 14.6901L5.51155 14.5671L5.60325 14.4359L5.69444 14.2957C6.17851 13.523 6.61822 12.3408 6.64443 10.3172L6.64566 10.125C6.64566 7.51179 8.40402 5.41041 10.7958 4.84012L10.9293 4.81002V4.09615C10.9293 3.49087 11.4088 3 12.0001 3ZM11.9783 6.111L11.1437 6.29922C9.43281 6.70715 8.22558 8.16049 8.14945 9.95416L8.14563 10.1346L8.1443 10.3366C8.11914 12.2796 7.75101 13.744 7.0685 14.9202L6.95184 15.1136L6.83285 15.295L6.71403 15.4638L6.59665 15.6202L6.49281 15.75L17.5073 15.7507L17.3447 15.543L17.1992 15.3415L17.0798 15.163L16.9754 14.9958C16.2827 13.8454 15.8964 12.3869 15.8578 10.4678L15.8531 10.0178C15.8083 8.22257 14.6197 6.75319 12.9296 6.31737L12.759 6.27725L11.9783 6.111Z" />
    </Svg>
  )
}

export default NotificationsM
