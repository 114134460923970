import React, { useCallback, useMemo } from 'react'
import { Box, Typography, SxProps } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import { getTimelineMonthlySheetBadgeTextKey } from 'components/TaskBadge/helpers'
import { TimelineItemContentBadgeTypeT } from 'components/Timeline/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { getShortName } from 'utils/person'
import { MonthlySheetStatus } from '../MonthlySheet/types'
import { taskStates } from '../constants'
import classes from '../styles'
import { MonthlySheetTaskT, TaskCardDataT } from '../types'

const getClosedCardTitle = (taskStatus: string, isOffice: boolean) => {
  if (taskStatus === MonthlySheetStatus.markedChangeThisMonth) {
    return isOffice
      ? 'tasks.new.timeapproval.manager.closed.headline_accepted_manager'
      : 'tasks.new.timeapproval.frontliner.closed.headline_accepted_manager'
  }
  return isOffice
    ? 'tasks.new.timeapproval.manager.closed.headline_accepted'
    : 'tasks.new.timeapproval.frontliner.closed.headline_accepted'
}

const getOpenCardTitle = (taskStatus: string, isOffice: boolean) => {
  if (isOffice) return 'tasks.new.timeapproval.manager.open.headline'
  return taskStatus === MonthlySheetStatus.markedChangeNextMonth
    ? 'tasks.new.timeapproval.frontliner.open.headline_accepted_manager'
    : 'tasks.new.timeapproval.frontliner.open.headline'
}

const getPendingCardTitle = (taskStatus: string, isOffice: boolean) => {
  if (!isOffice) return 'tasks.new.timeapproval.frontliner.pending.headline'
  return taskStatus === MonthlySheetStatus.markedChangeNextMonth
    ? 'tasks.new.timeapproval.manager.pending.headline_accepted_manager'
    : 'tasks.new.timeapproval.manager.pending.headline'
}

const MonthlySheetBody: React.FC<TaskCardDataT<MonthlySheetTaskT>> = ({ office, task }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { storeState } = useFilterContext()
  const { setBackLink } = useStateBackLink()
  const taskState = task.statusFe
  const monthlySheetsUrl = routes.monthlySheetsWithTask(task.favurUuid)
  const redirectToApprovalPage = useCallback(() => {
    setBackLink(routes.tasks)
    storeState()
    history.push(monthlySheetsUrl)
  }, [setBackLink, storeState, history, monthlySheetsUrl])
  const taskData: {
    buttonLabel?: string
    callback?: () => void
    sx?: SxProps
    title: string
    content: string
    dataTestId?: string
  } = useMemo(() => {
    switch (taskState) {
      case taskStates.todo:
        return {
          buttonLabel: 'component.notification.officeMonthlySheetApproved.details',
          callback: redirectToApprovalPage,
          sx: classes.callToAction as SxProps,
          title: getOpenCardTitle(task.status, office),
          content: 'tasks.new.timeapproval.dates',
          dataTestId: 'tasks-monthly-sheet-frontliner-open-button',
        }
      case taskStates.pending:
        return {
          title: getPendingCardTitle(task.status, office),
          content: 'tasks.new.timeapproval.dates',
          buttonLabel: 'component.notification.officeMonthlySheetApproved.details',
          sx: classes.callToAction as SxProps,
          callback: redirectToApprovalPage,
        }

      default:
        return {
          buttonLabel: 'component.notification.officeMonthlySheetApproved.details',
          callback: redirectToApprovalPage,
          sx: classes.callToAction as SxProps,
          title: getClosedCardTitle(task.status, office),
          content: 'tasks.new.timeapproval.dates',
          dataTestId: 'tasks-monthly-sheet-approved-office-details',
        }
    }
  }, [taskState, redirectToApprovalPage, office, task.status])

  const commentOrAction = useMemo(() => {
    if (!task.timeline || task.timeline.length === 0) return ''
    const lastTimelineItem = task.timeline[task.timeline.length - 1]
    const comment = lastTimelineItem.comment
    if (comment) return comment
    return t(getTimelineMonthlySheetBadgeTextKey(lastTimelineItem.badge as TimelineItemContentBadgeTypeT))
  }, [t, task.timeline])

  return (
    <Box sx={classes.taskCardBody} data-testid={`${taskState}-${office ? 'office' : 'frontliner'}-monthly-sheet-body`}>
      <Typography sx={classes.taskCardBodyTitle} variant="subtitle1">
        {t(taskData.title, {
          frontlinerNameShort: task.person ? getShortName(task.person) : '-',
          managerNameShort: task.personTriggeredBy ? getShortName(task.personTriggeredBy) : '-',
        })}
      </Typography>
      <Box sx={classes.taskCardContentWrapper}>
        <Typography sx={classes.taskCardBodyContent} variant="body2">
          <Trans
            i18nKey={taskData.content}
            components={{ b: <b /> }}
            values={{
              from: getFormattedFromISOString(task.taskData.startDate, uiDateFormat, undefined),
              to: getFormattedFromISOString(task.taskData.endDate, uiDateFormat, undefined),
            }}
          />
        </Typography>
        {commentOrAction && (
          <Typography sx={classes.taskCardBodyContent} variant="body2">
            <b>{t('tasks.new.timeapproval.message')}</b> {commentOrAction}
          </Typography>
        )}
      </Box>
      <Box sx={classes.taskCardCTAContainer}>
        <Typography
          variant="button"
          component="button"
          sx={taskData.sx}
          onClick={taskData.callback}
          data-testid={taskData.dataTestId}
        >
          {t(taskData?.buttonLabel ?? '')}
        </Typography>
      </Box>
    </Box>
  )
}

export default MonthlySheetBody
