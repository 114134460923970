import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const BellM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12.75 1.75a.75.75 0 0 0-1.5 0v1.533a8.557 8.557 0 0 0-5.287 2.455 8.48 8.48 0 0 0-2.502 6.012v6.297a.7.7 0 0 1-.207.496.714.714 0 0 1-.504.207.75.75 0 0 0 0 1.5h6.09a3.972 3.972 0 0 0 1.015 1.826c.582.581 1.338.924 2.145.924.807 0 1.563-.343 2.145-.924a3.972 3.972 0 0 0 1.016-1.826h6.089a.75.75 0 0 0 0-1.5.714.714 0 0 1-.504-.207.7.7 0 0 1-.206-.496V11.75a8.48 8.48 0 0 0-2.503-6.012 8.557 8.557 0 0 0-5.287-2.455V1.75Zm.834 18.5h-3.168c.125.302.297.562.5.765.33.33.714.485 1.084.485.37 0 .755-.155 1.084-.485.204-.203.375-.463.5-.765ZM7.02 6.802A7.06 7.06 0 0 1 12 4.75a7.06 7.06 0 0 1 4.979 2.052 6.98 6.98 0 0 1 2.06 4.948v6.297c0 .241.04.479.116.703H4.845c.076-.224.116-.462.116-.703V11.75a6.98 6.98 0 0 1 2.06-4.948Zm6.48.172a.5.5 0 0 1 .525-.473c.367.018.7.086 1.012.232.313.146.578.357.828.627.255.274.46.653.577 1.04.116.383.167.84.033 1.254a.5.5 0 0 1-.952-.308c.05-.151.045-.385-.038-.656a1.736 1.736 0 0 0-.352-.65 1.677 1.677 0 0 0-.519-.401 1.677 1.677 0 0 0-.64-.14.5.5 0 0 1-.474-.525Z"
      />
    </Svg>
  )
}

export default BellM
