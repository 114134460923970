import theme from 'utils/theme'

export const classes = {
  pinDisplayContainer: {
    paddingTop: theme.spacing(7),
  },
  changePinLengthButton: {
    marginTop: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.primary[900],
  },
}
