import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const LogoutM: React.FC<IconPropsT> = ({ stroke = theme.palette.common.white }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none">
      <path d="M9.5 11L18.5 11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17 14.0801L20 11.0801L17 8.08008"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.999 15.0086V18.4372C14.0104 18.7264 13.9212 19.009 13.7508 19.2232C13.5804 19.4373 13.3428 19.5657 13.0899 19.5801H4.90949C4.65668 19.5655 4.4192 19.4371 4.24897 19.2229C4.07873 19.0088 3.98959 18.7263 4.00101 18.4372V4.72294C3.98941 4.43382 4.0785 4.15123 4.24877 3.93704C4.41904 3.72284 4.65662 3.59449 4.90949 3.58008H13.0899C13.3428 3.59449 13.5804 3.72282 13.7508 3.93699C13.9212 4.15116 14.0104 4.43375 13.999 4.72294V7.62109"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default LogoutM
