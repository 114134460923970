import { createContext, Context } from 'react'
import { FeatureFlagType } from 'constants/featureFlags'
import settings from 'utils/settings'

export type FeatureFlagsType = {
  [key in FeatureFlagType]?: boolean | string
}

export interface IFeatureFlagContextType extends FeatureFlagsType {
  settings: (name: FeatureFlagType, value: boolean | string | void) => boolean | string | void
}

const FeatureFlagContext: Context<IFeatureFlagContextType> = createContext({
  settings,
} as IFeatureFlagContextType)

export default FeatureFlagContext
