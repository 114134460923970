import { absencePeriods, AbsencePeriodsT, absencePeriodsTranslationMap } from 'shared/constants'
import { newDateWithoutTime } from 'utils/date'
import { TaskT, AbsenceRequestTaskT } from '../types'
import { absenceRequestStatus } from './constants'

export const canBeApprovedOrRejected = (status: string) => {
  return [absenceRequestStatus.reviewOfficeUnseen, absenceRequestStatus.reviewOfficeSeen].includes(status)
}

export const approvedOrRejected = (status: string) => {
  return [absenceRequestStatus.approvedOffice, absenceRequestStatus.rejectedOffice].includes(status)
}

const getPeriodValue = (queryTask: TaskT<AbsenceRequestTaskT>) => {
  if (!queryTask?.taskData?.dates?.length) {
    return 'undefined'
  }

  const { period } = queryTask.taskData.dates[0]
  return period
}

export const getPeriodTranslationKey = (periodTask: TaskT<AbsenceRequestTaskT>) => {
  const key = getPeriodValue(periodTask)
  const absenceType = absencePeriodsTranslationMap[key as AbsencePeriodsT]
  const tranlationKey = absencePeriods[absenceType]
  return tranlationKey !== undefined ? tranlationKey : absencePeriods.fullDay
}

export const dateIsBetweenLastMonth = (date: Date): boolean => {
  const compareDate = newDateWithoutTime()
  compareDate.setDate(compareDate.getDate() - 30)
  return date >= compareDate
}
