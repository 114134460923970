import React, { useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import AbsenceRequestConflictsCard from 'components/AbsenceRequestConflicts/AbsenceRequestConflictsCard'
import Timeline from 'components/Timeline'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { taskStates } from 'pages/Tasks/constants'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { AbsenceRequestContext } from '../AbsenceRequestContext'
import ActionButtons from './ActionButtons'
import { classes } from './styles'
import { getTimelineForComponent } from './utils'

interface IContentProps {
  task: TaskT<AbsenceRequestTaskT>
  isOffice: boolean
  refetchTask: () => void
  fullScreen?: boolean
  drawerSx?: SxProps<Theme>
  onDelete?: () => void
  hasOverflow?: boolean
  isAtBottom?: boolean
  showAbsencePlannerPreview?: boolean
  showConflicts: boolean
  isSmallScreen?: boolean
}

const Content: React.FC<IContentProps> = ({
  task,
  isOffice,
  refetchTask,
  fullScreen = false,
  drawerSx,
  onDelete,
  hasOverflow,
  isAtBottom,
  showAbsencePlannerPreview,
  showConflicts,
  isSmallScreen,
}) => {
  const { t, locale } = useFavurTranslation()
  const { personIds } = useRolesViews()
  const timelineItems = getTimelineForComponent({ task, t, locale: locale as Locale, personIds })
  const taskClosed = task.statusFe === taskStates.closed
  const [showAbsencePlannerDrawer, setShowAbsencePlannerDrawer] = useState<boolean>(false)

  return (
    <AbsenceRequestContext.Provider
      value={{
        showAbsencePlannerDrawer,
        setShowAbsencePlannerDrawer: setShowAbsencePlannerDrawer,
      }}
    >
      <Box sx={[...(!fullScreen ? [classes.bottomSpacingRoot] : [classes.noBottomSpacingRoot])]}>
        <Box>
          <Timeline items={timelineItems} />
          {showConflicts && (
            <AbsenceRequestConflictsCard
              startDate={task.taskData.dateFrom}
              endDate={task.taskData.dateTo}
              isManager={isOffice}
              small={taskClosed}
              showAbsencePlannerPreview={showAbsencePlannerPreview}
              previewPerson={task.person}
              showAbsencePlannerDrawer={showAbsencePlannerDrawer}
              setShowAbsencePlannerDrawer={setShowAbsencePlannerDrawer}
            />
          )}
        </Box>
        <ActionButtons
          task={task}
          isOffice={isOffice}
          onAction={refetchTask}
          fullScreen={fullScreen || isSmallScreen}
          hasOverflow={hasOverflow}
          isAtBottom={isAtBottom}
          drawerSx={drawerSx}
          onDelete={onDelete}
          showAbsencePlanButton={showAbsencePlannerPreview}
        />
      </Box>
    </AbsenceRequestContext.Provider>
  )
}

export default Content
