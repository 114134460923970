import React, { useCallback, useContext, useReducer } from 'react'
import AppStatesContext from './context'
import { ActionT, reducer } from './reducer'
import { IAppStatesContext, StateT, StateValueT } from './types'

interface Props {
  children?: React.ReactNode
}

const AppStatesService: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer<(state: StateT, action: ActionT) => StateT>(reducer, {})

  const getValue = useCallback(
    (key: string) => {
      return state[key] ?? null
    },
    [state],
  )

  const providerValue: IAppStatesContext = {
    clear: (name: string) => {
      dispatch({ type: 'clear', payload: { key: name } })
    },
    clearAll: () => {
      dispatch({ type: 'clearAll' })
    },
    get: getValue,
    set: (key: string, value: StateValueT) => {
      dispatch({ type: 'set', payload: { key, value } })
    },
  }
  return <AppStatesContext.Provider value={providerValue}>{children}</AppStatesContext.Provider>
}

export const useAppStatesContext = () => {
  return useContext(AppStatesContext)
}

export default AppStatesService
