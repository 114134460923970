import React from 'react'
import { Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'

interface IOldNativeVersionDialogDialogProps {
  open: boolean
  onClose: () => void
  platform: string
}

const OldNativeVersionDialogDialog: React.FC<IOldNativeVersionDialogDialogProps> = ({ open, onClose, platform }) => {
  const { t } = useFavurTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('dialog.oldNativeVersion.title.text', { platform })}
      actions={
        <Button variant="text" color="secondary" onClick={onClose}>
          {t('common.close')}
        </Button>
      }
    >
      <Typography variant="body2">{t('dialog.oldNativeVersion.body.text', { platform })}</Typography>
    </Dialog>
  )
}

export default OldNativeVersionDialogDialog
