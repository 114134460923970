import theme from 'utils/theme'

export const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  subtitle: {
    lineHeight: '22px',
    color: theme.palette.grey[600],
  },
}
