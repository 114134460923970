import theme from 'utils/theme'

export const classes = {
  root: {
    width: '100%',
    display: 'block',
    // @ts-ignore
    backgroundColor: theme.palette.primary[900],
  },
  background: {
    position: 'absolute',
    zIndex: 0,
    transition: 'opacity .4s ease-in-out',
    opacity: 0.08,
  },
  logo: {
    flex: 1,
    height: '100%',
  },
  heading: {
    // @ts-ignore
    color: theme.palette.primary[500],
    textTransform: 'uppercase',
  },
  subHeader: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    height: theme.spacing(4.3125),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subHeaderBackground: {
    height: 'inherit',
    width: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    margin: '0 auto',
  },
  growSubheader: {
    height: 'unset',
    maxHeight: theme.spacing(6.89),
  },
  subHeaderContent: {
    textAlign: 'center',
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(1.25, 0.75),
    boxSizing: 'border-box',
  },
  bannerUnauthenticated: {
    position: 'static',
    width: '100%',
    zIndex: 1,
  },
}
