import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const ClockL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M16 3a13 13 0 1 1 0 26 13 13 0 0 1 0-26zm1 2.04V7h-2V5.04A11 11 0 0 0 5.04 15H7v2H5.04A11 11 0 0 0 15 26.96V25h2v1.96A11 11 0 0 0 26.96 17H25v-2h1.96A11 11 0 0 0 17 5.04zM17 10v5l3.5 3.5L19 20l-4-4v-6h2z"
      />
    </Svg>
  )
}

export default ClockL
