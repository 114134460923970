import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FilledHourglassM } from 'icons'
import theme from 'utils/theme'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice
    ? `secureConnection.identificationStarted.tooltipManager`
    : `secureConnection.identificationStarted.tooltipFrontliner`
}

const IdentificationStarted: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)

  return (
    <TaskBadge
      text={t('timeline.badge.identificationStarted')}
      sx={getClasses(withTooltip)}
      icon={<FilledHourglassM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
    />
  )
}

export default IdentificationStarted
