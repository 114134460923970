// eslint-disable-next-line lodash-fp/use-fp
import { throttle } from 'lodash'
import { refreshHighSecSession } from 'utils/refreshHighSecSession'

const delay = 30_000

export const throttledRefreshHighSecSession = () =>
  // eslint-disable-next-line lodash-fp/no-extraneous-args
  throttle(
    () => {
      refreshHighSecSession()
    },
    delay,
    { trailing: false },
  )
