import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { ArrowRightThinS, HourglassS } from 'icons'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { classes } from './styles'

interface IPendingAbsenceRequestProps {
  absenceRequest: TaskT<AbsenceRequestTaskT>
  date: string
  onClick?: (task: TaskT<AbsenceRequestTaskT>) => void
}

const PendingAbsenceRequest: React.FC<IPendingAbsenceRequestProps> = ({ absenceRequest, date, onClick }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setBackLink } = useStateBackLink()

  return (
    <Button
      variant="text"
      color="primary"
      disableRipple
      disableFocusRipple
      sx={classes.toTaskCta}
      onClick={() => {
        if (onClick) onClick(absenceRequest)
        else {
          setBackLink(routes.shifts)
          history.push(`${routes.absenceRequestDetail}/${absenceRequest.favurUuid}?dateref=${date}`)
        }
      }}
    >
      <Box sx={classes.absenceRequestCaption}>
        <Box sx={classes.iconCircle}>
          <HourglassS stroke={theme.palette.primary.main} />
        </Box>
        <Typography variant="caption">{t('page.shifts.shiftCard.pendingAbsenceRequest.body')}</Typography>
      </Box>
      <ArrowRightThinS />
    </Button>
  )
}

export default PendingAbsenceRequest
