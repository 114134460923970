import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'

const AssetCheckmark: React.FC<IconPropsT> = () => {
  return (
    <Svg size={68} fill={theme.palette.success.main}>
      <path
        fillRule="evenodd"
        d="M61 10.9l3.8 3.6a3 3 0 0 1 0 4.3L27.3 57a2 2 0 0 1-2.8 0l-22-22a2 2 0 0 1 0-2.8l4.3-4.4a2 2 0 0 1 2.8 0L25.8 44l32.4-33.2a2 2 0 0 1 2.8 0z"
      />
    </Svg>
  )
}

export default AssetCheckmark
