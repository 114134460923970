import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { CloseThinM } from 'icons'
import theme from 'utils/theme'
import { taskBarClasses } from '../../styles'
import { EventHeaderProps } from './types'

const EventHeader: React.FC<EventHeaderProps> = ({ title, onClick }) => {
  return (
    <Box>
      <Box sx={taskBarClasses.headerContainer}>
        <IconButton sx={taskBarClasses.closeIcon} size="large" onClick={onClick}>
          <CloseThinM fill={theme.palette.primary.main} />
        </IconButton>
        <Typography variant="subtitle1" sx={taskBarClasses.headerTitle}>
          {title}
        </Typography>
      </Box>
      <DivisionLine />
    </Box>
  )
}

export default EventHeader
