import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_L } from './constants'
import { IconPropsT } from './types'

const OfficeFilePdfL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill="none">
      <g stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" clipPath="url(#a)">
        <path d="M3.75 16.248v-6.25M3.75 10h.625a1.875 1.875 0 1 1 0 3.75H3.75M8.75 16.25a2.5 2.5 0 0 0 2.5-2.5V12.5a2.5 2.5 0 0 0-2.5-2.5v6.25ZM13.75 16.25v-5A1.25 1.25 0 0 1 15 10h1.25M13.75 13.748h1.875" />
        <path d="M19.008 4.632c.235.235.367.552.367.884V17.5a1.875 1.875 0 0 1-1.874 1.873h-15A1.875 1.875 0 0 1 .625 17.5v-15A1.875 1.875 0 0 1 2.501.623h11.98a1.25 1.25 0 0 1 .885.366l3.642 3.643Z" />
        <path d="M14.375.623v3.75a1.25 1.25 0 0 0 1.25 1.25h3.75" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default OfficeFilePdfL
