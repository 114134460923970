import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { taskBarStates } from 'pages/AbsencePlanner/components/TaskBar/types'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import EventCard from 'pages/Cms/EventsList/EventCard'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { CmsContentT } from 'types'
import classes from './styles'
import type { EventNotificationProps } from './types'

const EventNotification: React.FC<EventNotificationProps> = ({ date, events, showEventsInTaskBar = false }) => {
  const { setSelectedEvent } = useSelectedStatesActionsContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const history = useHistory()
  const handleOnClick = useCallback(
    ({ event }: { event: CmsContentT }) => {
      if (showEventsInTaskBar) {
        setTaskBarState(taskBarStates.events)
        setSelectedEvent(event.uuid ?? null)

        return
      }

      history.push(`${routes.cmsContentView}/${event.uuid}${date ? `?dateref=${date}` : ''}`)
    },
    [date, history, showEventsInTaskBar, setSelectedEvent, setTaskBarState],
  )

  if (!events.length) {
    return null
  }

  return (
    <Box sx={classes.container}>
      {events?.map((event) => (
        <EventCard content={event} onClick={() => handleOnClick({ event })} key={event.uuid} />
      ))}
    </Box>
  )
}

export default EventNotification
