import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const PlusS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8.5 2c.276 0 .5.224.5.5v4.499L13.5 7c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5l-4.501-.001L9 13.5c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5l-.001-4.501L2.5 9c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5L7 6.999V2.5c0-.276.224-.5.5-.5h1z"
        transform="translate(-31 -1089) translate(23 1085) translate(8 4)"
      />
    </Svg>
  )
}

export default PlusS
