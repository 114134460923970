import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_S } from './constants'
import { IconPropsT } from './types'

const TeamS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8 9a4 4 0 0 1 4 3.9v.1H4a4 4 0 0 1 4-4zm0 1a3 3 0 0 0-2.8 1.8v.2h5.6a3 3 0 0 0-2.6-2H8zm4-3a3 3 0 0 1 3 2.9v.1h-2.4l-.8-1h2-.1a2 2 0 0 0-1.5-1H12a2 2 0 0 0-1.1.3l-1-.5A3 3 0 0 1 12 7zM4 7a3 3 0 0 1 2 .8l-.9.5a2 2 0 0 0-1-.3H3.9a2 2 0 0 0-1.4.8l-.1.2h2l-.7.7-.2.3H1v-.1A3 3 0 0 1 4 7zm4-3a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4-2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM4 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm8 1a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zM4 4a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z"
      />
    </Svg>
  )
}

export default TeamS
