import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CalendarNoDataM } from 'icons'
import { classes } from '../styles'

const NoDataLabel: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.noDataLabelContainer}>
      <CalendarNoDataM />
      <Typography variant="body2">{t('component.teamplanDetailPage.noData.label')}</Typography>
    </Box>
  )
}

export default NoDataLabel
