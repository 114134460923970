import React, { useCallback, useRef } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Alert from 'components/Alert'
import Avatar from 'components/Avatar'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { DeleteTrashRemoveM, EditPencilM } from 'icons'
import { User } from 'types'
import { getFullAvatarUrl } from 'utils/avatar'
import { getAvatarName } from 'utils/person'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IProfilePictures {
  onFileChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickDeleteAvatar: () => void
  user: User
}
const ProfilePicture: React.FC<IProfilePictures> = ({ onFileChanged, onClickDeleteAvatar, user }) => {
  const { t } = useFavurTranslation()
  const { firstName, lastName, avatarUrl } = user
  const avatarName = getAvatarName(firstName, lastName)
  const fileInputRef = useRef(null)

  const selectFileClick = useCallback(() => {
    // @ts-ignore
    fileInputRef.current.click()
  }, [fileInputRef])

  return (
    <Box sx={classes.profileContainer}>
      <Card>
        <Box sx={classes.cardContainer}>
          <Avatar size={40} avatar={{ avatarName, avatarUrl: avatarUrl ? getFullAvatarUrl(avatarUrl) : undefined }} />
          <Box sx={classes.textContainer}>
            <Typography variant="body1">{t('page.userSettings.profile.settingsSection.profileImage.title')}</Typography>
          </Box>
          {avatarUrl && (
            <IconButton disableRipple onClick={onClickDeleteAvatar} data-testid="user-settings-delete-profile-picture">
              <DeleteTrashRemoveM fill={palette.primary.main} />
            </IconButton>
          )}
          <IconButton disableRipple onClick={selectFileClick} data-testid="user-settings-edit-profile-picture">
            <EditPencilM fill={palette.primary.main} />
            <input
              ref={fileInputRef}
              data-testid="input-profile-picture"
              multiple={false}
              hidden
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={async (e) => {
                onFileChanged(e)
              }}
            />
          </IconButton>
        </Box>
      </Card>
      {!avatarUrl && <Alert severity="info" content={t('page.userSettings.profile.alertNoPicture')} />}
    </Box>
  )
}

export default ProfilePicture
