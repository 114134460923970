export const classes = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  LogoWrapper: {
    height: '78px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}
