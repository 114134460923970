import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { ANDROID_PLAY_STORE_LINK, IOS_APP_STORE_LINK } from 'constants/appStores'
import { cardVariants } from 'constants/componentsBasics'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { isAndroidNative, isIphoneNative, isNativeNoOverride } from 'utils/platform'
import { classes } from '../Templates/Notification/styles'

const EmptyNotification: React.FC = () => {
  const { t } = useFavurTranslation()

  const goToAppStore = () => {
    if (isNativeNoOverride()) {
      if (isIphoneNative()) {
        window.location.href = IOS_APP_STORE_LINK
      }
      if (isAndroidNative()) {
        window.location.href = ANDROID_PLAY_STORE_LINK
      }
    }
  }

  return (
    <Box sx={classes.root} onClick={goToAppStore}>
      <Card variant={cardVariants.highlight}>
        <Box sx={classes.container}>
          <Box sx={classes.bodyContainer}>
            <Box sx={classes.titleContainer}>
              <Typography variant="subtitle2" sx={classes.title}>
                {t('notification.empty.title')}
              </Typography>
            </Box>
            <Typography variant="body2" sx={classes.body}>
              {t('notification.empty.body')}
            </Typography>
            <Typography variant="caption" sx={classes.date}>
              {getFormattedFromDate(newDateWithoutTime(), uiDateFormat, undefined)}
            </Typography>
          </Box>
          <Box sx={classes.dotContainer}>
            <Box sx={classes.newDot} />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default EmptyNotification
