import theme from 'utils/theme'

export const classes = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: '100%',
    height: '64px',
    borderRadius: '16px',
    transition: '0.5s ease-out',
    backgroundPosition: 'right',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
  },
  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 0,
    zIndex: 10,
  },
  divider: {
    position: 'relative',
    width: '33%',
    height: '1px',
    borderRadius: '1px',
    // @ts-ignore
    backgroundColor: theme.palette.grey[200],
  },
  dividerActive: {
    // @ts-ignore
    backgroundColor: theme.palette.success[200],
  },
  dividerInactive: {
    backgroundColor: '#ffb9b4',
  },
  dividerNotValidated: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[100],
  },
  dividerNoInfo: {
    // @ts-ignore
    backgroundColor: theme.palette.grey[200],
  },
  statusMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    marginLeft: '28px',
  },
  bold: {
    fontWeight: 600,
  },
  lastUpdated: {
    marginTop: '4px',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.3px',
    color: theme.palette.grey[600],
  },
  iconContainer: {
    display: 'flex',
  },
  noLastUpdate: {
    display: 'none',
  },
  statusMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}

export const backgroundColors = {
  active: {
    //@ts-ignore
    background: theme.palette.success[100],
  },
  inactive: {
    //@ts-ignore
    background: theme.palette.error[25],
  },
  notValidated: {
    //@ts-ignore
    background: theme.palette.primary[25],
  },
  noInfo: {
    background: theme.palette.grey[50],
  },
}

export const textColors = {
  active: {
    color: theme.palette.success.dark,
  },
  inactive: {
    color: theme.palette.error.main,
  },
  notValidated: {
    color: theme.palette.primary.main,
  },
  noInfo: {
    color: theme.palette.grey[600],
  },
}

export const ANIMATION_TIME = 2000
export const animationClasses = {
  text: {
    // @ts-ignore
    '@keyframes textAnimation': {
      '0%': { transform: 'translate(0,0)', opacity: 1 },
      '22.5%': { opacity: 0 },
      '30%': { transform: 'translate(0,100px)' },
      '30.1%': { transform: 'translate(0px,-100px)' },
      '41.25%': { opacity: 0 },
      '63.75%': { transform: 'translate(0,0)', opacity: 1 },
    },
    animation: `textAnimation ${ANIMATION_TIME}ms linear`,
  },
  icon: {
    // @ts-ignore
    '@keyframes iconAnimation': {
      '0%': { transform: 'scale(1)', opacity: 1 },
      '30.0%': { transform: 'scale(1)', opacity: 1 },
      '30.1%': { transform: 'scale(0)', opacity: 0 },
      '63.75%': { transform: 'scale(0)', opacity: 0 },
      '71.25%': { transform: 'scale(1.2)', opacity: 1 },
      '75%': { transform: 'scale(1)', opacity: 1 },
    },
    animation: `iconAnimation ${ANIMATION_TIME}ms linear`,
  },
  updatedAt: {
    // @ts-ignore
    '@keyframes updatedAtAnimation': {
      '0%': { opacity: 1 },
      '30%': { opacity: 1 },
      '30.1%': { opacity: 0 },
      '80%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    animation: `updatedAtAnimation ${ANIMATION_TIME}ms linear`,
  },
  slider: {
    // @ts-ignore
    '@keyframes sliderAnimation': {
      '0%': { height: 0 },
      '26.25%': { height: 0 },
      '48.75%': { height: '100%' },
      '52.4%': { height: '100%', visibility: 'visible' },
      '52.5%': { visibility: 'hidden' },
      '75%': { visibility: 'hidden', height: '100%' },
    },
    animation: `sliderAnimation ${ANIMATION_TIME}ms linear`,
  },
}
